// Copyright 2020 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { IAppointment } from '../../appointment-details.screen.query';
import { useTranslation } from 'react-i18next';

export interface IPatientProfileSectionProps {
  appointment?: IAppointment;
}

export const PatientProfileSection = ({
  appointment,
}: IPatientProfileSectionProps): ReactElement => {
  const { t } = useTranslation();

  const missingPageBreakSection = !appointment?.procedure.results;

  const theme = useTheme();
  return appointment?.questions && appointment?.questions?.length > 0 ? (
    <Box component='section' display='grid' gap={theme.spacing(4)}>
      <Typography variant='h2' component='h2'>
        {t('appointmentDetails.patientProfile.sectionHeading')}
      </Typography>
      <Box
        display='grid'
        gridTemplateColumns='repeat(auto-fill, minmax(49%, 1fr))'
        gap={theme.spacing(4)}
        className='print-questions'
      >
        {appointment?.questions?.map((qa, idx) => (
          <Box
            key={qa.questionId}
            display='grid'
            gap={theme.spacing(2)}
            className={
              !missingPageBreakSection && idx === 10 ? 'page-break' : idx === 13 ? 'page-break' : ''
            }
          >
            <Typography>{qa.text}</Typography>
            <Typography data-heap-redact-text='true' style={{ fontWeight: 'bold' }}>
              {qa.answer}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  ) : (
    <Box component='section' display='grid' gap={theme.spacing(4)}>
      <Typography variant='h2' component='h2'>
        {t('appointmentDetails.patientProfile.sectionHeading')}
      </Typography>
      <Typography>{t('appointmentDetails.patientProfile.noQuestions')}</Typography>
    </Box>
  );
};
