// Copyright 2021 Prescryptive Health, Inc.

import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { logApiError } from '../../../api/errors/log-api-error';
import { NonFatalError } from '../../../api/errors/non-fatal.error';
import { locationIsTestUpdate } from '../../../api/v1/location-is-test-update/location-is-test-update';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { ProviderDispatch } from '../../provider/dispatch/provider.dispatch';
import { locationIsTestUpdateDispatch } from '../../provider/dispatch/store-location-is-test-update.dispatch';

export interface ILocationIsTestUpdatedAsyncActionArgs extends IApiAsyncActionArgs {
  locationId: string;
  isTest: boolean;
  providerDispatch: ProviderDispatch;
}

export const locationIsTestUpdateAsyncAction = async (
  args: ILocationIsTestUpdatedAsyncActionArgs
): Promise<void> => {
  const {
    authProvider,
    telemetryService,
    configState,
    locationId,
    isTest,
    providerDispatch,
    getAuthToken,
  } = args;

  try {
    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    telemetryService.trackEvent('location-is-test-update', { locationId, isTest });
    await locationIsTestUpdate(configState.apis.pharmacyPortal, accessToken, isTest, locationId);
    locationIsTestUpdateDispatch(providerDispatch, isTest, locationId);
  } catch (error) {
    if (!(error instanceof NonFatalError)) {
      fatalErrorDispatch(args.errorDispatch);
    }
    logApiError(telemetryService, error);
    return Promise.reject();
  }
};
