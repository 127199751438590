// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useState } from 'react';
import { IWaitlistInvitation } from '../../../../../model/waitlist-invitation';
import { endOfDay } from '../../../../../helpers/dates/date-helper/date.helper';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { getNewDate } from '../../../../../helpers/dates/get-new-date/get-new-date';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

interface IInviteModalProps {
  isOpen?: boolean;
  selectedIds: string[];
  onCancelPress: () => void;
  onSendPress: (invitations: IWaitlistInvitation[]) => Promise<void>;
}

export const InviteModal = ({
  isOpen = false,
  selectedIds = [],
  onCancelPress,
  onSendPress,
}: IInviteModalProps): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<Date>(getNewDate());
  const [endDate, setEndDate] = useState<Date>(getNewDate());

  const [isValidStartDate, setIsValidStartDate] = useState(true);
  const [isValidEndDate, setIsValidEndDate] = useState(true);

  const hasDateError = startDate && endDate && startDate > endDate;

  const onSendButtonPress = async () => {
    if (!startDate || !endDate) {
      return;
    }

    const invitiations: IWaitlistInvitation[] = selectedIds.map((id) => ({
      waitlistItemId: id,
      startDate,
      endDate: endOfDay(endDate),
    }));

    await onSendPress(invitiations);
  };

  const isSendDisabled = !isValidStartDate || !isValidEndDate || hasDateError;

  const onCalendarStartDateChange = (value: unknown) => {
    const date = value as DateTime | undefined;
    if (date?.isValid) {
      setIsValidStartDate(true);
      setStartDate(date.toJSDate());
    } else {
      setIsValidStartDate(false);
    }
  };

  const onCalendarEndDateChange = (value: unknown) => {
    const date = value as DateTime | undefined;
    if (date?.isValid) {
      setIsValidEndDate(true);
      setEndDate(date.toJSDate());
    } else {
      setIsValidEndDate(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onCancelPress}>
      <DialogTitle>{t('waitlist.waitlistInviteModal.title')}</DialogTitle>
      <DialogContent>
        <Box component='section' display='grid' gap={theme.spacing(6)}>
          <Typography>{t('waitlist.waitlistInviteModal.description')}</Typography>
          <Typography variant='h3'>
            {t('waitlist.waitlistInviteModal.appointmentAvailability')}
          </Typography>
          <Box display='flex' flexDirection='row' gap={theme.spacing(6)}>
            <DatePicker
              label={t('waitlist.waitlistInviteModal.startDateLabel')}
              value={startDate}
              onChange={onCalendarStartDateChange}
              inputFormat='MM/dd/yyyy'
              disablePast={true}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              renderInput={(props) => <TextField {...props} variant='filled' fullWidth />}
            />
            <DatePicker
              label={t('waitlist.waitlistInviteModal.endDateLabel')}
              value={endDate}
              onChange={onCalendarEndDateChange}
              inputFormat='MM/dd/yyyy'
              disablePast={true}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              renderInput={(props) => <TextField {...props} variant='filled' fullWidth />}
            />
          </Box>
          <Box display={hasDateError ? 'flex' : 'none'}>
            <Typography color='error'>
              {t('waitlist.waitlistInviteModal.startAfterEndErrorMessage')}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSendDisabled}
          onClick={onSendButtonPress}
          color='primary'
          variant='contained'
        >
          {t('waitlist.waitlistInviteModal.sendButtonLabel')}
        </Button>
        <Button onClick={onCancelPress} color='primary' variant='outlined'>
          {t('waitlist.waitlistInviteModal.cancelButtonLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
