// Copyright 2022 Prescryptive Health, Inc.

export const callAfterIdle = (idleTimeInMinutes: number, func: () => void): void => {
  let timeoutReference: NodeJS.Timeout | undefined;
  window.onload = resetTimer;
  document.onmousemove = resetTimer;
  document.onkeydown = resetTimer;
  document.onmousedown = resetTimer;
  document.onclick = resetTimer;
  document.addEventListener('scroll', resetTimer, true);

  window.onbeforeunload = () => {
    if (!timeoutReference) {
      return;
    }
    clearTimeout(timeoutReference);
  };

  function resetTimer() {
    if (timeoutReference) {
      clearTimeout(timeoutReference);
    }
    timeoutReference = setTimeout(func, idleTimeInMinutes * 1000 * 60);
  }
};
