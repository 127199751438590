import { Box, Link, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PrescryptiveLogo } from '../../../prescryptive-logo.tsx/prescryptive-logo';

const PRESCRYPTIVE_LINK = 'https://prescryptive.com/';
const CONTACT_US_LINK = 'https://prescryptive.com/contact-2/';
const TERMS_OF_USE_LINK = 'https://prescryptive.com/terms-of-use/';
const PRIVACY_POLICY_LINK = 'https://prescryptive.com/privacy-policy/';

export const DrugSearchScreenFooter = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      width='100%'
      marginTop='auto'
      sx={{
        background: 'linear-gradient(109.61deg, #6240A3 26.88%, #88498F 73.73%)',
      }}
    >
      <Box
        display='inherit'
        flexDirection='inherit'
        justifyContent='space-between'
        alignItems='center'
        width='100%'
        margin={theme.spacing(6)}
      >
        <Typography variant='body2'>
          <Link
            sx={{ display: 'flex', alignItems: 'center' }}
            href={PRESCRYPTIVE_LINK}
            target={'_blank'}
          >
            <PrescryptiveLogo />
          </Link>
        </Typography>
        <Box display='flex'>
          <Typography variant='body2'>
            <Link
              sx={{ color: 'white', display: 'flex', alignItems: 'center' }}
              href={CONTACT_US_LINK}
              target={'_blank'}
            >
              {t('lander.highFrequencyLandingPage.contactUsText')}
            </Link>
          </Typography>
          <Typography marginLeft={theme.spacing(4)} marginRight={theme.spacing(4)} color='white'>
            {'/'}
          </Typography>
          <Typography variant='body2'>
            <Link
              sx={{ color: 'white', display: 'flex', alignItems: 'center' }}
              href={TERMS_OF_USE_LINK}
              target={'_blank'}
            >
              {t('lander.highFrequencyLandingPage.termsOfUseText')}
            </Link>
          </Typography>
          <Typography marginLeft={theme.spacing(4)} marginRight={theme.spacing(4)} color='white'>
            {'/'}
          </Typography>
          <Typography variant='body2'>
            <Link
              sx={{ color: 'white', display: 'flex', alignItems: 'center' }}
              href={PRIVACY_POLICY_LINK}
              target={'_blank'}
            >
              {t('lander.highFrequencyLandingPage.privacyPolicyText')}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
