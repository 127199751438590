// Copyright 2020 Prescryptive Health, Inc.

import {
  getPolicy,
  IDefaultPolicyTemplates,
  IRetryPolicy,
} from './retry-policies/retry-policy.helper';
import { HttpStatusCodesEnum } from './http-status-codes';
import { pause } from './timing/pause';

export const fetchRetry = async (
  url: string,
  options: Partial<RequestInit>,
  fetcher: (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>,
  retryPolicy?: IRetryPolicy
): Promise<Response> => {
  const method = options.method as keyof IDefaultPolicyTemplates;
  retryPolicy = retryPolicy || getPolicy(method);

  let response;
  do {
    try {
      response = await fetcher(url, options);
      if (response.ok) {
        return Promise.resolve(response);
      }

      await pause(retryPolicy.pause);

      retryPolicy = retryPolicy.getNextRetry(response, retryPolicy);
    } catch (error) {
      if (retryPolicy.remaining <= 1) {
        response = new Response(undefined, {
          status: HttpStatusCodesEnum.INTERNAL_SERVER_ERROR,
          statusText: 'Internal Error',
        });
        return Promise.resolve(response);
      }

      await pause(retryPolicy.pause);
      retryPolicy = retryPolicy.getNextRetry(error as Response, retryPolicy);
      return await fetchRetry(url, options, fetcher, retryPolicy);
    }
  } while (retryPolicy.remaining > 0);

  return Promise.resolve(response);
};
