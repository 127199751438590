// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  LabeledDetailContentMUITypography,
  LabeledDetailDescriptionMUITypography,
  LabeledDetailMUIButton,
  LabeledDetailMUITypography,
  LabeledDetailMUIContainer,
} from './labeled-detail.styled-components';
import { useTranslation } from 'react-i18next';

interface ILabeldDetailMUIProps {
  label?: string;
  detail?: string;
  description?: string;
  additionalDescription?: string;
}

export const LabeledDetail = ({
  label,
  detail,
  description,
  additionalDescription,
}: ILabeldDetailMUIProps): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const additionalDescriptionViewStyle = isExpanded
    ? { marginTop: theme.spacing(3) }
    : { display: 'none' };
  const buttonLabel = isExpanded
    ? t('labeledDetail.labeledDetailMUI.lessLabel')
    : t('labeledDetail.labeledDetailMUI.moreLabel');
  const buttonIcon = isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />;

  const onExpandPress = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <LabeledDetailMUIContainer>
      {label && <LabeledDetailMUITypography>{label}</LabeledDetailMUITypography>}
      {detail && <LabeledDetailContentMUITypography>{detail}</LabeledDetailContentMUITypography>}
      {description && (
        <LabeledDetailDescriptionMUITypography variant='caption'>
          {description}
        </LabeledDetailDescriptionMUITypography>
      )}
      {description && additionalDescription ? (
        <Box>
          <div style={additionalDescriptionViewStyle}>
            <LabeledDetailDescriptionMUITypography variant='caption'>
              {additionalDescription}
            </LabeledDetailDescriptionMUITypography>
          </div>
          <LabeledDetailMUIButton
            onClick={onExpandPress}
            color='primary'
            size='small'
            endIcon={buttonIcon}
          >
            {buttonLabel}
          </LabeledDetailMUIButton>
        </Box>
      ) : null}
    </LabeledDetailMUIContainer>
  );
};
