// Copyright 2020 Prescryptive Health, Inc.

import React, { ReactElement, useState, useEffect } from 'react';
import { getNewDate } from '../../../helpers/dates/get-new-date/get-new-date';
import { Box, MenuItem, Select, SelectChangeEvent, Typography, useTheme } from '@mui/material';

export type DayPeriod = 'AM' | 'PM';

export interface ITimeSelectProps {
  defaultValue?: Date;
  onSelectTime: (time: Date) => void;
  minuteGranularity?: number;
  isDisabled?: boolean;
  label?: string;
  startHourPickerOptions?: number;
  startMinutesPickerOptions?: number;
  singleDayPeriodPickerOption?: DayPeriod;
}

export const TimeSelect = (props: ITimeSelectProps): ReactElement => {
  const {
    onSelectTime,
    defaultValue,
    isDisabled,
    label,
    minuteGranularity,
    startHourPickerOptions,
    startMinutesPickerOptions,
    singleDayPeriodPickerOption,
  } = props;

  const theme = useTheme();

  const getMinutesString = (minutes: number | undefined) => {
    if (minutes) {
      if (minuteGranularity && minutes % minuteGranularity === 0) {
        return minutes < 9 ? `0${minutes}` : minutes.toString();
      }
      return minuteGranularity ? roundToCeiling(minutes, minuteGranularity) : minutes.toString();
    } else {
      return '00';
    }
  };

  const roundToCeiling = (x: number, granurality: number) => {
    return (Math.ceil(x / granurality) * granurality).toString();
  };

  const getHourStringFromDate = (date: Date | undefined) => {
    if (date) {
      const dateHours = date.getHours();
      const hours = dateHours > 12 ? dateHours - 12 : dateHours;
      if (hours === 0) {
        return '12';
      } else {
        return hours.toString();
      }
    } else {
      return '12';
    }
  };

  const getDayPeriodFromDate = (date: Date | undefined) => {
    if (date) {
      return date.getHours() >= 12 ? 'PM' : 'AM';
    } else {
      return 'AM';
    }
  };

  const [selectedHours, setSelectedHours] = useState<string>(getHourStringFromDate(defaultValue));

  const [selectedMinutes, setSelectedMinutes] = useState<string>(
    getMinutesString(defaultValue?.getMinutes())
  );

  const [selectedDayPeriod, setSelectedDayPeriod] = useState<DayPeriod>(
    getDayPeriodFromDate(defaultValue)
  );

  const onHandleSelectTime = () => {
    if (onSelectTime) {
      const hoursNumber = parseInt(selectedHours, 10);
      const isMidnight = selectedDayPeriod === 'AM' && hoursNumber === 12;
      const hoursToAdd = selectedDayPeriod === 'PM' && hoursNumber < 12 ? 12 : 0;

      const hours = isMidnight ? 0 : hoursNumber + hoursToAdd;

      const componentDate = defaultValue ? defaultValue : getNewDate();
      const selectedDateTimeWithHourMinutes = new Date(
        componentDate.getFullYear(),
        componentDate.getMonth(),
        componentDate.getDate(),
        hours,
        parseInt(selectedMinutes, 10)
      );

      onSelectTime(selectedDateTimeWithHourMinutes);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedHours(getHourStringFromDate(defaultValue));
      setSelectedMinutes(getMinutesString(defaultValue.getMinutes()));
      setSelectedDayPeriod(getDayPeriodFromDate(defaultValue));
    }
  }, [defaultValue]);

  useEffect(() => {
    onHandleSelectTime();
  }, [selectedHours, selectedMinutes, selectedDayPeriod]);

  const onChangeHours = (event: SelectChangeEvent<string>) => {
    setSelectedHours(event.target.value);
  };

  const onChangeMinutes = (event: SelectChangeEvent<string>) => {
    setSelectedMinutes(event.target.value);
  };

  const onChangeDay = (event: SelectChangeEvent<DayPeriod>) => {
    setSelectedDayPeriod(event.target.value as DayPeriod);
  };

  const hoursOptions = (): Map<string, string> => {
    if (isDisabled) {
      return new Map([['', '--']]);
    }

    const hoursMap = new Map<string, string>();
    Array.from(new Array(12)).forEach((_, index) => {
      const hour = index + 1;
      const startHour = startHourPickerOptions ?? 0;
      if (startHour <= hour) {
        hoursMap.set(hour.toString(), hour.toString());
      }
    });

    return hoursMap;
  };

  const minutesOptions = (): Map<string, string> => {
    if (isDisabled) {
      return new Map([['', '--']]);
    }

    const minutesMap = new Map<string, string>();
    minutesMap.set('00', '00');
    Array.from(new Array(59)).forEach((_, index) => {
      const granurality = minuteGranularity ?? 15;
      const minute = index + 1;
      if (minute % granurality === 0) {
        const minuteString = minute < 10 ? '0' + minute.toString() : minute.toString();
        const initialMinute = startMinutesPickerOptions ?? 0;
        if (initialMinute <= minute) {
          minutesMap.set(minuteString, minuteString);
        }
      }
    });
    return minutesMap;
  };

  const mappedHours = hoursOptions();
  const mappedMinutes = minutesOptions();
  const dayPeriodOptions = isDisabled
    ? new Map([['', '--']])
    : singleDayPeriodPickerOption
    ? new Map([[singleDayPeriodPickerOption, singleDayPeriodPickerOption]])
    : new Map([
        ['AM', 'AM'],
        ['PM', 'PM'],
      ]);

  return (
    <Box width='285px'>
      <Box marginBottom={theme.spacing(1.5)}>
        <Typography>{label}</Typography>
      </Box>
      <Box display='flex' justifyContent='space-between' alignItems='baseline'>
        <Select
          id='time-select-hours'
          value={selectedHours}
          onChange={onChangeHours}
          disabled={isDisabled}
          variant='standard'
        >
          {Array.from(mappedHours.keys()).map((key: string, index: number) => {
            const value = mappedHours.get(key) ?? '';
            return (
              <MenuItem key={`${index}${value}`} value={key}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
        <Typography>{':'}</Typography>
        <Select
          id='time-select-minutes'
          value={selectedMinutes}
          onChange={onChangeMinutes}
          disabled={isDisabled}
          variant='standard'
        >
          {Array.from(mappedMinutes.keys()).map((key: string, index: number) => {
            const value = mappedMinutes.get(key) ?? '';
            return (
              <MenuItem key={`${index}${value}`} value={key}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
        <Typography>{':'}</Typography>
        <Select
          id='time-select-day'
          value={selectedDayPeriod}
          onChange={onChangeDay}
          disabled={isDisabled}
          variant='standard'
        >
          {Array.from(dayPeriodOptions.keys()).map((key: string, index: number) => {
            const value = dayPeriodOptions.get(key) ?? '';
            return (
              <MenuItem key={`${index}${value}`} value={key}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    </Box>
  );
};
