// Copyright 2021 Prescryptive Health, Inc.

import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { LocationAvailabilityDispatch } from '../dispatch/location-availability.dispatch';
import { locationAvailabilityGet } from '../../../api/v1/location-availability-get/location-availability-get';
import { locationAvailabilitySetDispatch } from '../dispatch/location-availability-set.dispatch';
import { logApiError } from '../../../api/errors/log-api-error';

export interface ILocationAvailabilityGetAsyncActionArgs extends IApiAsyncActionArgs {
  locationAvailabilityDispatch: LocationAvailabilityDispatch;
  providerId: string;
}

export const locationAvailabilityGetAsyncAction = async (
  args: ILocationAvailabilityGetAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: ILocationAvailabilityGetAsyncActionArgs): Promise<void> => {
  const {
    locationAvailabilityDispatch,
    providerId,
    telemetryService,
    configState,
    authProvider,
    getAuthToken,
  } = args;

  try {
    telemetryService.trackEvent('location-availability-get', { providerId });

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    const result = await locationAvailabilityGet(
      configState.apis.pharmacyPortal,
      accessToken,
      providerId
    );
    locationAvailabilitySetDispatch(locationAvailabilityDispatch, result.locationAvailabilityMap);
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
  }
};
