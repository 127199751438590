// Copyright 2021 Prescryptive Health, Inc.

import {
  ILocationAvailability,
  LocationAvailabilityMap,
} from '../../model/location-availability';

export interface ILocationAvailabilityState {
  isStale: boolean;
  locationAvailabilityMap: LocationAvailabilityMap;
}

export const defaultLocationAvailabilityState: ILocationAvailabilityState = {
  isStale: false,
  locationAvailabilityMap: new Map<string, ILocationAvailability>(),
};
