import { gql } from '@apollo/client';

export const INVITE_FOR_APPOINTMENT_MUTATION = gql`
  mutation inviteToAppointment(
    $locationId: ID
    $inviteToAppointmentInput: InviteToAppointmentInput
  ) {
    inviteToAppointment(locationId: $locationId, invite: $inviteToAppointmentInput) {
      locationId
      phoneNumber
      serviceType
    }
  }
`;

export interface IInviteToAppointmentVariables {
  locationId: string;
  inviteToAppointmentInput: {
    phoneNumber: string;
    serviceType: string;
  };
}
