// Copyright 2021 Prescryptive Health, Inc.

import { useQuery } from '@apollo/client';
import React, { ReactElement } from 'react';
import { usePostAuthScreenContext } from '../../../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { ProviderService } from '../../../../../model/strapi/strapi-models';
import { useLocationContext } from '../../../../../providers/location/use-location-context.hook';
import { useProviderContext } from '../../../../../providers/provider/use-provider-context.hook';
import {
  IProviderServiceAddAsyncActionArgs,
  providerServiceAddAsyncAction,
} from '../../../../../state/post-auth/async-actions/provider-service-add.async-action';
import { PostAuthPrimaryScreenContainer } from '../../../../screen-containers/post-auth-primary/post-auth-primary.screen-container';
import { ServiceDetailsScreen } from '../screen/service-details.screen';
import { GET_SERVICE_DETAILS_QUERY } from './get-service-details.query';
import { Button } from '@mui/material';
import { EmptyViewMessage } from '../../../../text/messages/empty-view.message';
import { EnrollmentStatus } from '../../../../../model/enrolled-service-type';
import { authenticatedRouteConfig } from '../../../../../navigation/authenticated-route-config';
import { ErrorView } from '../../../../error-view/error-view';
import {
  IProviderServiceSetActiveAsyncActionArgs,
  providerServiceSetActiveAsyncAction,
} from '../../../../../state/provider-support/async-actions/provider-service-set-active.async-action';
import { useParams, useNavigate } from 'react-router-dom';
import { getToken } from '../../../../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

export const ServiceDetailsScreenContainer = (): ReactElement => {
  const { t } = useTranslation();
  const { serviceId } = useParams();
  const navigate = useNavigate();
  const { authProvider, busyDispatch, configState, errorDispatch, telemetryService } =
    usePostAuthScreenContext({ defaultContent: {} });

  const {
    providerState: { currentProvider, enrolledServiceTypes },
    providerDispatch,
  } = useProviderContext();

  const { locationDispatch } = useLocationContext();

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const { loading, error, data } = useQuery<{
    providerService: ProviderService;
  }>(GET_SERVICE_DETAILS_QUERY, {
    variables: { id: serviceId },
    onError: ({ networkError }) => {
      const e = networkError as Error;
      telemetryService.trackException(e);
    },
  });

  document.title = t('addService.serviceDetailsScreenContainer.pageTitle', {
    serviceName: data?.providerService?.Title ?? '',
  });

  const serviceType = data?.providerService?.ServiceType;
  const enrollmentStatus: EnrollmentStatus | undefined = enrolledServiceTypes.find(
    (service) => service.serviceType === serviceType
  )?.enrollmentStatus;

  const providerName = currentProvider?.name ?? '';

  const goBack = () => {
    void navigate(authenticatedRouteConfig.addServices.createUrl());
  };

  const onManage = () => {
    if (serviceType) {
      void navigate(authenticatedRouteConfig.myServices.createUrl(serviceType));
    }
  };

  const onEnrollPress = async () => {
    if (providerName && serviceType) {
      const providerServiceAddArgs: IProviderServiceAddAsyncActionArgs = {
        authProvider,
        busyDispatch,
        configState,
        errorDispatch,
        telemetryService,
        providerDispatch,
        locationDispatch,
        serviceType,
        providerName,
        getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
      };

      await providerServiceAddAsyncAction(providerServiceAddArgs);
    }
  };

  const onActivatePress = async () => {
    if (providerName && serviceType) {
      const providerServiceSetActiveArgs: IProviderServiceSetActiveAsyncActionArgs = {
        authProvider,
        busyDispatch,
        configState,
        errorDispatch,
        telemetryService,
        providerDispatch,
        locationDispatch,
        serviceType,
        providerName,
        getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
      };

      await providerServiceSetActiveAsyncAction(providerServiceSetActiveArgs);
    }
  };

  if (loading) {
    return (
      <PostAuthPrimaryScreenContainer>
        <EmptyViewMessage content={t('addService.serviceDetailsScreenContainer.loading')} />
      </PostAuthPrimaryScreenContainer>
    );
  }

  if (error || !data) {
    return (
      <PostAuthPrimaryScreenContainer>
        <Button onClick={goBack}>Back</Button>
        <ErrorView />
      </PostAuthPrimaryScreenContainer>
    );
  }

  return (
    <PostAuthPrimaryScreenContainer style={{ padding: 0 }}>
      <ServiceDetailsScreen
        onGoBack={goBack}
        onEnroll={onEnrollPress}
        onManage={onManage}
        onActivate={onActivatePress}
        enrollmentStatus={enrollmentStatus}
        data={data.providerService}
      />
    </PostAuthPrimaryScreenContainer>
  );
};
