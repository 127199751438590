// Copyright 2021 Prescryptive Health, Inc.
import { FunctionComponent } from 'react';
import userFormatter from '../../../formatters/user-formatter/user-formatter';
import { PaperProps, Tooltip, Typography, useTheme } from '@mui/material';
import { AppointmentCard } from './appointment-event-card.styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';

export interface IAppointmentEventCardProps extends PaperProps {
  firstName: string;
  lastName: string;
  serviceName: string;
  isPast: boolean;
  onClick: () => void;
  isRecorded: boolean;
}

export const AppointmentEventCard: FunctionComponent<IAppointmentEventCardProps> = ({
  onClick = () => undefined,
  firstName,
  lastName,
  serviceName,
  isPast,
  isRecorded,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullName = userFormatter.formatFullName(firstName, lastName);

  const getStatus = () => {
    if (isRecorded) {
      return (
        <Tooltip title={t('appointments.appointmentEventCard.recordedToolTip')} placement='top'>
          <CheckCircleIcon sx={{ fill: theme.palette.success.main, marginLeft: 'auto' }} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={t('appointments.appointmentEventCard.unrecordedToolTip')} placement='top'>
          <Typography
            sx={{ color: theme.palette.primary.main, marginLeft: 'auto', fontWeight: 'bold' }}
          >
            {t('appointments.appointmentEventCard.unrecordedLabel')}
          </Typography>
        </Tooltip>
      );
    }
  };

  return (
    <AppointmentCard onClick={onClick} elevation={0} variant='outlined' $isPast={isPast}>
      <Typography
        style={{ fontWeight: 'bold' }}
        data-heap-redact-text='true'
        data-testid='appointment-event-card-fullName'
      >
        {fullName}
      </Typography>
      <Typography data-heap-redact-text='true' data-testid='appointment-event-card-serviceName'>
        {serviceName}
      </Typography>
      {getStatus()}
    </AppointmentCard>
  );
};
