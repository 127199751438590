// Copyright 2020 Prescryptive Health, Inc.

import { HttpStatusCodesEnum } from '../../http-status-codes';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { call } from '../../call';
import { ApiErrors } from '../../errors/api-errors';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export async function blockedTimeUnblock(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  blockedTimeId: string
): Promise<void> {
  const url = buildUrl(apiConfig, 'blockedTimeDelete', {
    ':blockedTimeId': blockedTimeId,
  });
  const headers = buildBearerAuthHeaders(authToken);
  const response: Response = await call(url, undefined, 'DELETE', headers);
  if (response.ok) {
    return;
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.BLOCK_TIME_UNBLOCK,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  throw new PharmacyPortalError(
    ApiErrors.blockedTimeUnblock(blockedTimeId, response.statusText),
    ApiErrorCode.BLOCK_TIME_UNBLOCK,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}
