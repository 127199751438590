// Copyright 2021 Prescryptive Health, Inc.

import { LocationDispatch } from './location.dispatch';
import { locationSelectedDateSetAction } from '../actions/location-selected-date-set';

export const locationSelectedDateSetDispatch = (
  dispatch: LocationDispatch,
  selectedDate: Date
): void => {
  dispatch(locationSelectedDateSetAction(selectedDate));
};
