// Copyright 2022 Prescryptive Health, Inc.

import { Box, Button } from '@mui/material';
import { ReactElement, useCallback, useRef } from 'react';
import { IAppointment } from '../screens/appointment-details/appointment-details.screen.query';
import PrintIcon from '@mui/icons-material/Print';
import { useTranslation } from 'react-i18next';
import ReactToPrint from 'react-to-print';
import { IPharmacyService } from '../../model/pharmacy-service';
import { PrintAppointment } from './print-appointment';

interface IPrintAppointmentButtonProps {
  appointment?: IAppointment;
  isDisabled: boolean;
  services?: IPharmacyService[];
  clia?: string;
}

export const PrintAppointmentButton = ({
  appointment,
  isDisabled,
  services,
  clia,
}: IPrintAppointmentButtonProps): ReactElement => {
  const { t } = useTranslation();
  const componentRef = useRef(null);

  const content = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const printTrigger = () => {
    return (
      <Button
        data-testid='print-button'
        startIcon={<PrintIcon />}
        disabled={isDisabled}
        color='primary'
        size='large'
      >
        {t('appointmentDetails.appointmentDetailsScreen.printLabel')}
      </Button>
    );
  };

  return (
    <>
      <ReactToPrint content={content} trigger={printTrigger} copyStyles={true} />
      <Box display='none'>
        <PrintAppointment
          ref={componentRef}
          title={t('appointmentDetails.appointmentDetailsScreen.title')}
          appointment={appointment}
          services={services}
          clia={clia}
        />
      </Box>
    </>
  );
};
