// Copyright 2020 Prescryptive Health, Inc.

import { AuthenticationProvider } from '../../authentication/authentication-provider';
import { sessionLoadedAction } from '../actions/session-loaded.action';
import { SessionDispatch } from './session.dispatch';

export const sessionLoadedDispatch = (
  dispatch: SessionDispatch,
  authenticationProvider: AuthenticationProvider
): void => {
  dispatch(sessionLoadedAction(authenticationProvider));
};
