// Copyright 2020 Prescryptive Health, Inc.

import { useContext } from 'react';
import {
  IConfigContext,
  ConfigContext,
} from '../../providers/config/config.context';

export const useConfigContext = (): IConfigContext =>
  useContext<IConfigContext>(ConfigContext);
