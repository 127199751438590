// Copyright 2021 Prescryptive Health, Inc.

import { AccordionSummary, styled } from '@mui/material';

export const LanesAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: 'transparent',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: 0,
}));
