// Copyright 2021 Prescryptive Health, Inc.

import { getNewDate } from '../../helpers/dates/get-new-date/get-new-date';
import { IPharmacyService } from '../../model/pharmacy-service';
import { IStoreLocation } from '../../model/store-location';

export interface ILocationState {
  locationSelectedDate: Date;
  location?: IStoreLocation;
  services: IPharmacyService[];
  areServicesStale: boolean;
}

export const defaultLocationState: ILocationState = {
  locationSelectedDate: getNewDate(),
  services: [],
  areServicesStale: false,
};
