import { gql } from '@apollo/client';

export const TERMS_OF_USE_MUTATION = gql`
  mutation updateTermsOfUse($updateAuth0UserInput: UpdateAuth0UserInput) {
    updateAuth0User(updateAuth0UserInput: $updateAuth0UserInput) {
      id
      userMetadata {
        pharmacyPortalService {
          termsOfUseAccepted
        }
      }
    }
  }
`;

export interface ITermsOfUseResponse {
  updateAuth0User: {
    id: string;
    userMetadata: {
      pharmacyPortalService: {
        termsOfUseAccepted: boolean;
      };
    };
  };
}
