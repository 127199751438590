// Copyright 2021 Prescryptive Health, Inc.

import { Button } from '@mui/material';
import { styled as muiStyled } from '@mui/material';

export const StyledSignInButton = muiStyled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  color: theme.palette.common.white,
}));
