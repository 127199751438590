// Copyright 2021 Prescryptive Health, Inc.

import { ILocationAction } from "./location.action";

export type ILocationClearAction = ILocationAction<'LOCATION_CLEAR'>;

export const locationClearAction = (): ILocationClearAction => ({
  type: 'LOCATION_CLEAR',
  payload: {
    location: undefined,
    services: [],
    areServicesStale: false,
  },
})