// Copyright 2020 Prescryptive Health, Inc.

import { useScreenContext, IScreenContext } from '../use-screen-context.hook';
import { IContentMap } from '../../../providers/locale/get-content';

export type IPreAuthScreenContext<TContent> = IScreenContext<TContent>;

export function usePreAuthScreenContext<TContent>(
  contentMap: IContentMap<TContent>
): IPreAuthScreenContext<TContent> {
  const screenContext = useScreenContext<TContent>(contentMap);

  return screenContext;
}
