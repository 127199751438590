// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useEffect, useState } from 'react';
import { FilterOptionsState } from '@mui/material/useAutocomplete';
import { TextField } from '@mui/material';
import { getTierSupportLevel } from '../../../helpers/tier-support-level-helper/tier-support-level-helper';
import { AddProviderModal } from './modal/add-provider.modal';
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

export interface ProviderPickerOption {
  title: string;
  inputValue?: string;
}

export interface IProviderPickerProps {
  providers: ProviderPickerOption[];
  isDisabled?: boolean;
  selectedProvider?: string;
  onSelect: (providerName: string) => void;
}

export const ProviderPicker = ({
  isDisabled,
  providers,
  onSelect,
  selectedProvider = '',
}: IProviderPickerProps): ReactElement => {
  const { t } = useTranslation();
  const defaultState: ProviderPickerOption = {
    title: selectedProvider || '',
  };
  const [value, setValue] = useState<ProviderPickerOption | null>(
    selectedProvider.length > 0 ? defaultState : null
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogProviderName, setDialogProviderName] = useState('');

  useEffect(() => {
    setValue(defaultState);
  }, [selectedProvider]);

  const filter = createFilterOptions<ProviderPickerOption>();
  const tierSupportLevel = getTierSupportLevel();
  const isAtLeastTier2Support = tierSupportLevel !== null && tierSupportLevel > 1;
  const isPickerEnabled = providers.length > 1;
  if (!isPickerEnabled || !!isDisabled) return <></>;

  const handleDialogClose = () => {
    setDialogProviderName('');
    setIsDialogOpen(false);
  };

  const onChangeProvider = (
    _event: React.ChangeEvent<unknown>,
    newValue: string | ProviderPickerOption | null,
    _reason: AutocompleteChangeReason
  ) => {
    if (typeof newValue === 'string') {
      if (isAtLeastTier2Support) {
        setTimeout(() => {
          setDialogProviderName(newValue.toUpperCase());
          setIsDialogOpen(true);
        });
      }
    } else if (newValue && newValue.inputValue) {
      // For when the user hits 'enter'
      if (isAtLeastTier2Support) {
        setDialogProviderName(newValue.inputValue.toUpperCase());
        setIsDialogOpen(true);
      }
    } else {
      setValue(newValue);
      onSelect(newValue !== null ? newValue.title : '');
    }
  };

  const filterOptions = (
    options: ProviderPickerOption[],
    state: FilterOptionsState<ProviderPickerOption>
  ) => {
    const filtered = filter(options, state);

    const isExisting = options.some((option) => state.inputValue === option.title);

    if (state.inputValue !== '' && !isExisting && isAtLeastTier2Support) {
      filtered.push({
        title: `Add "${state.inputValue.toUpperCase()}"`,
        inputValue: state.inputValue.toUpperCase(),
      });
    }

    return filtered;
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const textFieldParams: AutocompleteRenderInputParams = {
      ...params,
      inputProps: { ...params.inputProps, style: { textTransform: 'uppercase' } },
    };
    return (
      <TextField
        {...textFieldParams}
        label={t('pickers.providerPicker.pickerLabel')}
        variant='standard'
      />
    );
  };

  const onBlur = (_event: React.FocusEvent<HTMLDivElement>) => {
    setValue(defaultState);
  };

  const getOptionLabel = (option: string | ProviderPickerOption) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.title;
  };

  return (
    <>
      <Autocomplete
        value={value}
        options={providers}
        renderInput={renderInput}
        onChange={onChangeProvider}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        onBlur={onBlur}
        selectOnFocus={true}
        handleHomeEndKeys={true}
        id={`free-solo-providers-auto-complete`}
        freeSolo={true}
      />
      <AddProviderModal
        isOpen={isDialogOpen}
        providerName={dialogProviderName}
        onClosePress={handleDialogClose}
      />
    </>
  );
};
