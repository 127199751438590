// Copyright 2020 Prescryptive Health, Inc.

import { DateTime } from 'luxon';

export interface IDurationFormatContent {
  oneMinute: string;
  minutes: string;
  oneHour: string;
  hours: string;
}

const twelveHourFormat = 't';
const twentyFourHourFormat = 'T';

const formatToMDY = (date: Date, separator = '/'): string => {
  const month = formatMonth(date.getMonth());
  const day = formatDay(date.getDate());
  const year = date.getFullYear();

  return `${month}${separator}${day}${separator}${year}`;
};

const formatToYMD = (date: Date, separator = '-'): string => {
  const month = formatMonth(date.getMonth());
  const day = formatDay(date.getDate());
  const year = date.getFullYear();

  return `${year}${separator}${month}${separator}${day}`;
};

const formatMonth = (month: number): string => {
  month += 1;
  if (month > 9) {
    return month.toString();
  }
  return `0${month}`;
};

const formatToLongDate = (date: Date): string =>
  date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

const formatToTime = (utcDate: Date, clockType: '12-hour' | '24-hour' = '12-hour'): string => {
  const timeFormat = clockType === '12-hour' ? twelveHourFormat : twentyFourHourFormat;

  return DateTime.fromJSDate(utcDate).toFormat(timeFormat);
};

/**
 * Formats time w/o doubling the timezone offset that happens
 * when formatting an already offset time in Luxon
 */
const formatTime = (time: Date): string => {
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours ? hours % 12 : 12;
  const formattedMinutes = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

const formatDateToTime = (date: Date): string => DateTime.fromJSDate(date).toFormat('t');

const timeZone = (utcDate: Date): string => DateTime.fromJSDate(utcDate).offsetNameShort;

const formatToTimeRange = (startTime: Date, durationMinutes: number): string => {
  const endTime = new Date(startTime);
  endTime.setMinutes(startTime.getMinutes() + durationMinutes);

  return `${formatDateToTime(startTime)} - ${formatDateToTime(endTime)}`;
};

const formatDay = (day: number): string => {
  if (day > 9) {
    return day.toString();
  }
  return `0${day}`;
};

const formatStringDate = (day: string, month: string, year: string): string => {
  // MM-DD-YYYY
  const monthIndex = parseInt(month, 10);
  const dayIndex = parseInt(day, 10);
  const yearIndex = parseInt(year, 10);
  const formatedDate =
    (monthIndex > 9 ? monthIndex.toString() : '0' + monthIndex.toString()) +
    '-' +
    (dayIndex > 9 ? dayIndex.toString() : '0' + dayIndex.toString()) +
    '-' +
    yearIndex.toString();

  return formatedDate;
};

const formatToHourHA = (integer: number): string =>
  DateTime.now().set({ hour: integer }).toFormat('h a');

const formatTimeUnit = (timeNumber: number, singleUnit: string, unit: string): string => {
  if (timeNumber === 1) {
    return `1 ${singleUnit}`;
  }

  return `${timeNumber.toString()} ${unit}`;
};

const formatMinutesDuration = (timeNumber: number, content: IDurationFormatContent): string => {
  if (timeNumber === 60) {
    return `1 ${content.oneHour}`;
  }

  if (timeNumber < 60) {
    return formatTimeUnit(timeNumber, content.oneMinute, content.minutes);
  }

  const durationHours = Math.trunc(timeNumber / 60);
  const durationMinutes = timeNumber % 60;

  return `${formatTimeUnit(durationHours, content.oneHour, content.hours)}, ${formatTimeUnit(
    durationMinutes,
    content.oneMinute,
    content.minutes
  )}`;
};

export default {
  formatToLongDate,
  formatToMDY,
  formatToYMD,
  formatStringDate,
  formatToTime,
  formatTime,
  formatDateToTime,
  formatToTimeRange,
  formatToHourHA,
  formatTimeUnit,
  formatMinutesDuration,
  timeZone,
};
