// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useState, useEffect } from 'react';
import { usePostAuthScreenContext } from '../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { PostAuthPrimaryScreenContainer } from '../../screen-containers/post-auth-primary/post-auth-primary.screen-container';
import { Waitlist } from './waitlist';
import { AddToWaitlistModal } from './modals/add-to-waitlist/add-to-waitlist.modal';
import { IWaitlistItem } from '../../../model/waitlist-item';
import { notificationSetDispatch } from '../../../state/notification/dispatch/notification-set.dispatch';
import { useNotificationContext } from '../../../hooks/use-notification-context/use-notification-context.hook';
import { ImportToWaitlistModal } from './modals/import-to-waitlist/import-to-waitlist.modal';
import { InviteModal } from './modals/invite/invite.modal';
import { IWaitlistInvitation } from '../../../model/waitlist-invitation';
import { IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import { useWaitlistContext } from '../../../providers/waitlist/use-waitlist-context.hook';
import { useLocationContext } from '../../../providers/location/use-location-context.hook';
import { WaitlistErrorContent } from '../../modals/error/modal-content/waitlist-error/waitlist-error.modal-content';
import { IWaitlistAddError } from '../../../model/waitlist-add';
import { RemoveModal } from './modals/remove/remove.modal';
import { useProviderContext } from '../../../providers/provider/use-provider-context.hook';
import { getNewDate } from '../../../helpers/dates/get-new-date/get-new-date';
import { Typography } from '@mui/material';
import {
  IWaitlistRemoveAsyncActionArgs,
  waitlistRemoveAsyncAction,
} from '../../../state/customers/waitlist/async-actions/waitlist-remove.async-action';
import {
  IWaitlistAddAsyncActionArgs,
  waitlistAddAsyncAction,
} from '../../../state/customers/waitlist/async-actions/waitlist-add.async-action';
import {
  IWaitlistInviteAsyncActionArgs,
  waitlistInviteAsyncAction,
} from '../../../state/customers/waitlist/async-actions/waitlist-invite.async-action';
import { getToken } from '../../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

export const WaitlistScreen = (): ReactElement => {
  const { t } = useTranslation();
  const { authProvider, telemetryService, busyDispatch, configState, errorDispatch } =
    usePostAuthScreenContext({ defaultContent: {} });

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  document.title = t('waitlist.waitlistScreen.pageTitle');

  const {
    locationState: { location, services },
  } = useLocationContext();

  const {
    providerState: { currentProvider },
  } = useProviderContext();

  const { notificationDispatch } = useNotificationContext();
  const { waitlistDispatch } = useWaitlistContext();

  const [isImportToWaitlistModalOpen, setIsImportCustomerModalOpen] = useState(false);
  const [isAddToWaitlistModalOpen, setIsAddToWaitlistModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isRemoveConfirmationModalOpen, setIsRemoveConfirmationModalOpen] = useState(false);

  const [selectedWaitlistItemIds, setSelectedWaitlistItemIds] = useState<string[]>([]);

  const [waitlistItemToRemove, setWaitlistItemToRemove] = useState<IWaitlistItem>();

  useEffect(() => {
    const telemetryPayload: IPageViewTelemetry = {
      name: 'customer-waitlist',
      properties: {
        provider: currentProvider?.name,
      },
    };
    telemetryService.trackPageView(telemetryPayload);
  }, []);

  const onAddPress = async (itemToAdd: IWaitlistItem[]) => {
    if (!location) {
      return;
    }

    const args: IWaitlistAddAsyncActionArgs = {
      authProvider,
      busyDispatch,
      configState,
      errorDispatch,
      telemetryService,
      waitlistDispatch,
      locationId: location.id,
      waitlistItems: itemToAdd,
      nonFatalErrorTitle: t('waitlist.waitlistScreen.waitlistAddErrorTitle'),
      nonFatalErrorContent,
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
    };

    try {
      await waitlistAddAsyncAction(args);
      notificationSetDispatch(
        notificationDispatch,
        t('waitlist.waitlistScreen.addCustomerNotificationSuccessMessage')
      );
      setIsAddToWaitlistModalOpen(false);
    } catch {
      return;
    }
  };

  const onAddCustomerCancelPress = () => {
    setIsAddToWaitlistModalOpen(false);
  };

  const onCancelImportToWaitlistPress = () => {
    setIsImportCustomerModalOpen(false);
  };

  const onOpenAddToWaitlistModalPress = () => {
    setIsAddToWaitlistModalOpen(true);
  };

  const onOpenImportToWaitlistModalPress = () => {
    setIsImportCustomerModalOpen(true);
  };

  const onInvitePress = (selectectedIds: string[]) => {
    setSelectedWaitlistItemIds(selectectedIds);
    setIsInviteModalOpen(true);
  };

  const nonFatalErrorContent = (failedItems: IWaitlistAddError[]) => {
    return <WaitlistErrorContent services={services} failedItems={failedItems} />;
  };

  const onInviteSendPress = async (invitations: IWaitlistInvitation[]) => {
    if (!location) {
      return;
    }

    const args: IWaitlistInviteAsyncActionArgs = {
      authProvider,
      busyDispatch,
      configState,
      errorDispatch,
      telemetryService,
      waitlistDispatch,
      locationId: location.id,
      invitations,
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
    };

    try {
      await waitlistInviteAsyncAction(args);
      notificationSetDispatch(
        notificationDispatch,
        t('waitlist.waitlistScreen.inviteNotificationSuccessMessage')
      );
      setIsInviteModalOpen(false);
    } catch {
      return;
    }
  };

  const onInviteCancelPress = () => {
    setIsInviteModalOpen(false);
  };

  const onRemoveFromWaitlist = (waitlistItem: IWaitlistItem) => {
    setWaitlistItemToRemove(waitlistItem);
    setIsRemoveConfirmationModalOpen(true);
  };

  const isModalOpen = isAddToWaitlistModalOpen || isImportToWaitlistModalOpen || isInviteModalOpen;

  const timestamp = getNewDate().valueOf().toString();

  const onRemoveConfirmedPress = async (waitlistItem: IWaitlistItem) => {
    if (!location) {
      return;
    }

    const args: IWaitlistRemoveAsyncActionArgs = {
      authProvider,
      busyDispatch,
      configState,
      errorDispatch,
      telemetryService,
      waitlistDispatch,
      locationId: location.id,
      waitlistItemId: waitlistItem.id,
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
    };

    try {
      await waitlistRemoveAsyncAction(args);
      notificationSetDispatch(
        notificationDispatch,
        t('waitlist.waitlistScreen.waitlistRemoveNotificationSuccessMessage')
      );
      setIsRemoveConfirmationModalOpen(false);
    } catch {
      return;
    }
  };

  const onRemoveCancelledPress = () => {
    setIsRemoveConfirmationModalOpen(false);
  };

  return (
    <PostAuthPrimaryScreenContainer>
      <Typography variant='h1'>{t('waitlist.waitlistScreen.title')}</Typography>
      <Waitlist
        selectedLocation={location}
        isModalOpen={isModalOpen}
        onAddToWaitlistPress={onOpenAddToWaitlistModalPress}
        onImportToWaitlistPress={onOpenImportToWaitlistModalPress}
        onInvitePress={onInvitePress}
        onRemoveFromWaitlistPress={onRemoveFromWaitlist}
        services={services}
      />
      <AddToWaitlistModal
        key={`add-to-waitlist-modal-${timestamp}`}
        isOpen={isAddToWaitlistModalOpen}
        onAddPress={onAddPress}
        onCancelPress={onAddCustomerCancelPress}
        services={services}
      />
      <ImportToWaitlistModal
        key='import-to-waitlist-modal'
        isOpen={isImportToWaitlistModalOpen}
        onImportPress={onAddPress}
        onCancelPress={onCancelImportToWaitlistPress}
      />
      <InviteModal
        key='invite-modal'
        isOpen={isInviteModalOpen}
        selectedIds={selectedWaitlistItemIds}
        onSendPress={onInviteSendPress}
        onCancelPress={onInviteCancelPress}
      />
      <RemoveModal
        key='remove-modal'
        isOpen={isRemoveConfirmationModalOpen}
        onConfirmedPress={onRemoveConfirmedPress}
        onKeepPress={onRemoveCancelledPress}
        waitlistItem={waitlistItemToRemove}
        services={services}
      />
    </PostAuthPrimaryScreenContainer>
  );
};
