// Copyright 2020 Prescryptive Health, Inc.

import { IBusyState } from './busy.state';
import { BusyAction } from './actions/busy.action';
import { Reducer } from 'react';

export type BusyReducer = Reducer<IBusyState, BusyAction>;

export const busyReducer: BusyReducer = (
  state: IBusyState,
  action: BusyAction
): IBusyState => {
  switch (action.type) {
    case 'BUSY_PUSH': {
      const busyCount = state.busyCount + 1;
      return { ...state, busyCount };
    }

    case 'BUSY_POP': {
      const busyCount = state.busyCount - 1;
      return { ...state, busyCount };
    }
  }
};
