import { ReactElement, useCallback, useRef } from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import ReactToPrint from 'react-to-print';
import { AppointmentPrintOut } from './appointment-print-out';
import { usePostAuthContext } from '../../../providers/post-auth/use-post-auth-context.hook';
import { IProvider } from '../../../model/provider';
import { IAppointment } from '../../screens/appointment-details/appointment-details.screen.query';
import { Science } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export interface IAppointmentPrintOutButtonProps {
  appointment?: IAppointment;
  currentProvider?: IProvider;
  clia?: string;
  isDisabled?: boolean;
}

export const AppointmentPrintOutButton = ({
  appointment,
  currentProvider,
  clia,
  isDisabled,
}: IAppointmentPrintOutButtonProps): ReactElement => {
  const { t } = useTranslation();
  const componentRef = useRef<HTMLDivElement>(null);
  const {
    authState: { services },
  } = usePostAuthContext();

  const serviceType = appointment?.service?.serviceType ?? '';
  const service = services.find((service) => service.serviceType === serviceType);
  const claimOptions = service?.claimOptions;
  const administrationMethod = service?.administrationMethod;
  const testType = service?.testType;

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = () => {
    // Prop onClick is passed to root node of returned component. It will be overwritten if onClick is passed here
    return (
      <Button
        startIcon={<Science />}
        color='primary'
        size='large'
        id='print-button'
        disabled={isDisabled}
      >
        {t('appointments.appointmentPrintOut.printTestResultsLabel')}
      </Button>
    );
  };

  return (
    <>
      <Tooltip
        title={t('appointments.appointmentPrintOut.toolTipLabel')}
        placement='top'
        leaveDelay={2000}
        disableHoverListener={!isDisabled}
      >
        <span>
          <ReactToPrint
            content={reactToPrintContent}
            trigger={reactToPrintTrigger}
            copyStyles={true}
          />
        </span>
      </Tooltip>
      <Box display='none'>
        <AppointmentPrintOut
          ref={componentRef}
          appointment={appointment}
          currentProvider={currentProvider}
          claimOptions={claimOptions}
          administrationMethod={administrationMethod}
          testType={testType}
          clia={clia}
        />
      </Box>
    </>
  );
};
