// Copyright 2020 Prescryptive Health, Inc.

import { ProviderDispatch } from './provider.dispatch';
import { currentProviderSetAction } from '../actions/current-provider-set.action';
import { IProvider } from '../../../model/provider';

export const currentProviderSetDispatch = (
  dispatch: ProviderDispatch,
  provider: IProvider
): void => {
  dispatch(currentProviderSetAction(provider));
};
