// Copyright 2020 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { StyledSecondaryHeaderContainer } from './secondary-header.styled-components';
import { PrescryptiveProLogo } from '../prescryptivepro-logo/prescryptivepro-logo';
export interface ISecondaryHeaderProps {
  onBackButtonPress?: () => void;
}

export const SecondaryHeader = ({ onBackButtonPress }: ISecondaryHeaderProps): ReactElement => {
  const theme = useTheme();

  const backButton = onBackButtonPress ? (
    <IconButton
      disableRipple={true}
      aria-label='back'
      onClick={onBackButtonPress}
      style={{
        color: theme.palette.primary.main,
        marginRight: theme.spacing(6),
      }}
      size='large'
    >
      <ArrowBackIosIcon />
    </IconButton>
  ) : null;

  return (
    <StyledSecondaryHeaderContainer>
      {backButton}
      <Box>
        <PrescryptiveProLogo />
      </Box>
    </StyledSecondaryHeaderContainer>
  );
};
