// Copyright 2021 Prescryptive Health, Inc.

import { IStoreLocation } from '../../../model/store-location';
import { IProviderAction } from './provider.action';

export type ILocationUpdateAction = IProviderAction<
  'STORE_LOCATION_UPDATE',
  IStoreLocation
>;

export const locationUpdateAction = (
  storeLocation: IStoreLocation
): ILocationUpdateAction => {
  return {
    type: 'STORE_LOCATION_UPDATE',
    payload: storeLocation,
  };
};
