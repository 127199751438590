// Copyright 2020 Prescryptive Health, Inc.

import { IAppointmentsAction } from './appointments.action';

export type IAppointmentsAreStaleAction = IAppointmentsAction<'APPOINTMENTS_ARE_STALE'>;

export const appointmentsAreStaleAction = (): IAppointmentsAreStaleAction => ({
  type: 'APPOINTMENTS_ARE_STALE',
  payload: { appointmentsAreStale: true },
});
