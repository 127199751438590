// Copyright 2021 Prescryptive Health, Inc.

import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material';

export const StyledServiceCardGroupBox = styled(Box)(({ theme }) => ({
  padding: 0,
  [theme.breakpoints.up('tablet')]: {
    padding: theme.spacing(4),
  },
}));

export const StyledTitleAndCarouselButtonsTypography = styled(Box)({
  flexDirection: 'row',
});

export const StyledCarouselTitleTypography = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

export const StyledCarouselNavigationNextButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
  '&:hover:none': {
    backgroundColor: theme.palette.grey[100],
  },
  height: 'fit-content',
  backgroundColor: theme.palette.grey[100],
}));

export const StyledCarouselNavigationPreviousButton = styled(StyledCarouselNavigationNextButton)(
  ({ theme }) => ({
    marginRight: theme.spacing(4),
  })
);

export const StyledCarouselNavigationButtonBox = styled(Box)(({ theme }) => ({
  alignItems: 'unset',
  [theme.breakpoints.up('tablet')]: {
    alignItems: 'center',
  },
}));
