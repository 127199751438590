// Copyright 2021 Prescryptive Health, Inc.

import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { getMetaEnvironmentVariable } from './config/config.helper';

export const initApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    uri: `${getMetaEnvironmentVariable('STRAPI_HOST_URL')}/graphql`,
    cache: new InMemoryCache(),
  });
};
