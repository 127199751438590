// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useState } from 'react';
import { usePostAuthScreenContext } from '../../../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { useProviderContext } from '../../../../../providers/provider/use-provider-context.hook';
import { LabeledDetail } from '../../../../layout/labeled-detail/labeled-detail';
import { notificationSetDispatch } from '../../../../../state/notification/dispatch/notification-set.dispatch';
import { useNotificationContext } from '../../../../../hooks/use-notification-context/use-notification-context.hook';
import { useLocationContext } from '../../../../../providers/location/use-location-context.hook';
import { serviceNameFromType } from '../../../../../selectors/service-name';
import { EditableSection } from '../../../../sections/editable/editable.section';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IPaymentSetting } from '../../../../../model/pharmacy-service';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { cloneDeep } from '@apollo/client/utilities';
import {
  IPharmacyServicePriceUpdateAsyncActionArgs,
  pharmacyServicePriceUpdateAsyncAction,
} from '../../../../../state/location/async-actions/pharmacy-service-price-update.async-action';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  IPharmacyServicePaymentUpdateAsyncActionArgs,
  pharmacyServicePaymentUpdateAsyncAction,
} from '../../../../../state/location/async-actions/pharmacy-service-payment-update.async-action';
import { PaymentTypesInformationModal } from './payment-types-info/payment-types-information.modal';
import { useTranslation } from 'react-i18next';
import { getToken } from '../../../../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';

const DONT_ASK_PAYMENT = 'DontAskPayment';
const CREDIT_DEBIT_CARD = 'CreditDebitCard';
const PAY_AT_PHARMACY = 'PayAtPharmacy';

// TODO: When removing the feature flag, remove paymentSettings check from condtions labeled with (REMOVE_AFTER_FEATURE_FLAG)
export interface IPricingSection {
  paymentSettings?: IPaymentSetting[]; // (REMOVE_AFTER_FEATURE_FLAG): Make this not conditional
  currentPrice: number;
  serviceType: string;
}

export const PricingSection = ({
  currentPrice,
  paymentSettings,
  serviceType,
}: IPricingSection): ReactElement => {
  const { t } = useTranslation();
  const {
    authProvider,
    busyDispatch,
    configState,
    errorDispatch,
    telemetryService,
    authState: { services: allServices },
  } = usePostAuthScreenContext({ defaultContent: {} });

  const { getAccessTokenSilently } = useAuth0();

  const theme = useTheme();
  const { paymentSettingsFlag, auth0Migration } = useFlags();
  const {
    locationState: { services },
    locationDispatch,
  } = useLocationContext();
  const {
    providerState: { currentProvider },
  } = useProviderContext();

  const { notificationDispatch } = useNotificationContext();

  const arePaymentSettingsAvailable = // (REMOVE_AFTER_FEATURE_FLAG)
    paymentSettingsFlag && paymentSettings && paymentSettings.length > 0;

  const paymentSettingsDictionary =
    paymentSettings &&
    paymentSettings.length > 0 &&
    Object.assign({}, ...paymentSettings.map((setting) => ({ [setting.paymentType]: setting })));

  const [price, setPrice] = useState<number | null>(currentPrice / 100);
  const [isEditMode, setIsEditMode] = useState(false);
  const [priceIsValidWithPaymentType, setPriceIsValidWithPaymentType] = useState(true);
  const [isInformationModalOpen, setIsInformationModalOpen] = useState(false);
  const [isPaymentTypeError, setIsPaymentTypeError] = useState(false);
  const [settings, setSettings] = useState<{ [key: string]: IPaymentSetting }>(
    cloneDeep(paymentSettingsDictionary)
  );

  const formattedPrice = '$' + price;
  const isDontAskPaymentAccepted =
    arePaymentSettingsAvailable && settings[DONT_ASK_PAYMENT].accepted; // (REMOVE_AFTER_FEATURE_FLAG)
  const isCreditDebitAccepted = arePaymentSettingsAvailable && settings[CREDIT_DEBIT_CARD].accepted; // (REMOVE_AFTER_FEATURE_FLAG)
  const isPayAtPharmacyAccepted = arePaymentSettingsAvailable && settings[PAY_AT_PHARMACY].accepted; // (REMOVE_AFTER_FEATURE_FLAG)

  const isPriceNotAvailableForPaymentTypes =
    isDontAskPaymentAccepted || (!isCreditDebitAccepted && !isPayAtPharmacyAccepted);

  const isPriceErrorAvailable =
    !priceIsValidWithPaymentType && (isPayAtPharmacyAccepted || isCreditDebitAccepted);

  const isEditDisabled = !allServices.find((s) => s.serviceType === serviceType)
    ?.canUseDynamicPrice;

  const isPaymentTypeSelected = () => {
    if (arePaymentSettingsAvailable) {
      for (const key of Object.keys(settings)) {
        if (settings[key].accepted) {
          return true;
        }
      }
    }
    return false;
  };

  const onEditPress = () => setIsEditMode(true);

  const onCancelPress = () => {
    setIsEditMode(false);
    setIsPaymentTypeError(false);
    setPriceIsValidWithPaymentType(true);
    setPrice(currentPrice / 100);

    const newSettings = cloneDeep(paymentSettingsDictionary);
    setSettings(newSettings);
  };

  const onOpenInformationModal = () => {
    setIsInformationModalOpen(true);
  };

  const onCloseInformationModal = () => {
    setIsInformationModalOpen(false);
  };

  const onSavePress = async () => {
    if (!currentProvider) {
      return;
    }

    const args: IPharmacyServicePriceUpdateAsyncActionArgs = {
      authProvider,
      busyDispatch,
      configState,
      errorDispatch,
      locationDispatch,
      telemetryService,
      providerId: currentProvider.name,
      serviceType,
      price: price === null ? 0 : price * 100,
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently)
    };

    try {
      await pharmacyServicePriceUpdateAsyncAction(args);

      setIsEditMode(false);

      notificationSetDispatch(
        notificationDispatch,
        t('services.pricing.updateSuccessMessage', {
          serviceName: serviceNameFromType(serviceType, services),
        })
      );
    } catch {
      return;
    }
  };

  const onSavePaymentSettings = async () => {
    if (!currentProvider) {
      return;
    }

    if (!isPaymentTypeSelected()) {
      setIsPaymentTypeError(true);
      return;
    }

    if (isCreditDebitAccepted && (price === 0 || price == null)) {
      setPriceIsValidWithPaymentType(false);
      return;
    }

    if (isPayAtPharmacyAccepted && price === null) {
      setPriceIsValidWithPaymentType(false);
      return;
    }

    const isPriceNullForPaymentTypes =
      isDontAskPaymentAccepted || (!isCreditDebitAccepted && !isPayAtPharmacyAccepted);

    const args: IPharmacyServicePaymentUpdateAsyncActionArgs = {
      authProvider,
      busyDispatch,
      configState,
      errorDispatch,
      locationDispatch,
      telemetryService,
      providerId: currentProvider.name,
      serviceType,
      price: isPriceNullForPaymentTypes || price === null ? null : price * 100,
      paymentSettings: Object.values(settings),
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently)
    };

    try {
      await pharmacyServicePaymentUpdateAsyncAction(args);

      setIsEditMode(false);

      notificationSetDispatch(
        notificationDispatch,
        t('services.pricing.updateSuccessMessage', {
          serviceName: serviceNameFromType(serviceType, services),
        })
      );
      setPriceIsValidWithPaymentType(true);
      setIsPaymentTypeError(false);
    } catch (error) {
      return;
    }
  };

  const onPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const priceRegex = /^(0|[1-9][0-9]{0,2})$/;
    const value = event.target.value;

    if (value.length === 0) {
      setPrice(null);
      return;
    }

    if (!priceRegex.test(value)) {
      return;
    }

    setPrice(parseInt(value, 10));
  };

  const readModeContent = isEditDisabled ? (
    <>
      <LabeledDetail
        label={t('services.pricing.customerPriceLabel')}
        detail={formattedPrice}
        description={t('services.pricing.pricingNonEditDescription')}
      />
    </>
  ) : (
    <>
      <LabeledDetail
        label={t('services.pricing.customerPriceLabel')}
        detail={formattedPrice}
        description={t('services.pricing.pricingDescription')}
        additionalDescription={t('services.pricing.pricingAdditionalDescription')}
      />
    </>
  );

  const editModeContent = (
    <>
      <Box display='flex' flexGrow={1}>
        <TextField
          type='number'
          value={price}
          onChange={onPriceChange}
          style={{ flexGrow: 1, flexShrink: 1, maxWidth: '596px' }}
        />
      </Box>
      <LabeledDetail
        description={t('services.pricing.pricingDescription')}
        additionalDescription={t('services.pricing.pricingAdditionalDescription')}
      />
    </>
  );

  const sectionContent = isEditMode ? editModeContent : readModeContent;

  const description = (
    <Box display='flex' flexDirection='column'>
      <Typography variant='subtitle2' component='p'>
        {t('services.pricing.paymentDescription1')}
      </Typography>
      <Typography variant='subtitle2' component='p'>
        {t('services.pricing.paymentDescription2')}
      </Typography>
    </Box>
  );

  const renderCheckboxes =
    arePaymentSettingsAvailable && // (REMOVE_AFTER_FEATURE_FLAG)
    Object.keys(settings).map((paymentType) => {
      const isDisabledForDontAsk =
        paymentType !== DONT_ASK_PAYMENT && settings[DONT_ASK_PAYMENT].accepted;

      const onChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (paymentType === DONT_ASK_PAYMENT) {
          if (checked) {
            const newSettings = { ...settings };

            Object.keys(newSettings).map(function (key) {
              newSettings[key].accepted = false;
            });

            newSettings[paymentType].accepted = checked;

            setSettings(newSettings);
            setPrice(0);
          } else {
            const newSettings = cloneDeep(paymentSettingsDictionary);
            newSettings[DONT_ASK_PAYMENT].accepted = false;

            setSettings(newSettings);
          }
        } else {
          const newSettings = { ...settings };
          newSettings[paymentType].accepted = checked;
          setSettings(newSettings);
        }
      };

      return (
        <FormControlLabel
          key={paymentType}
          label={settings[paymentType].displayText}
          control={
            <Checkbox
              data-testid={paymentType}
              checked={settings[paymentType].accepted}
              onChange={onChange}
              disabled={isDisabledForDontAsk || !isEditMode}
            />
          }
        />
      );
    });

  const renderPricing = (
    <Box display='flex' flexDirection='column' flex={1} gap={theme.spacing(4)}>
      <Box display='flex' flexDirection='row'>
        <Typography sx={{ fontWeight: 'bold' }}>{t('services.pricing.pricingHeader')}</Typography>
        <Tooltip
          title={t('services.pricing.servicePricingToolTip')}
          placement='top'
          sx={{ marginLeft: theme.spacing(2) }}
        >
          <InfoOutlinedIcon color='primary' />
        </Tooltip>
      </Box>
      <Typography>{t('services.pricing.customerPriceLabel')}</Typography>
      <TextField
        variant='filled'
        disabled={isPriceNotAvailableForPaymentTypes || !isEditMode}
        value={price}
        onChange={onPriceChange}
        sx={{ maxWidth: '50%' }}
        size='small'
        error={isPriceErrorAvailable}
        helperText={
          isPriceErrorAvailable ? t('services.pricing.priceIsInvalidWithPaymentType') : null
        }
      />
    </Box>
  );

  return (
    <EditableSection
      heading={
        paymentSettingsFlag
          ? t('services.pricing.paymentHeader')
          : t('services.pricing.pricingHeader')
      }
      description={paymentSettingsFlag ? description : null}
      isEditMode={isEditMode}
      isEditDisabled={isEditDisabled}
      onEditPress={onEditPress}
      onCancelPress={onCancelPress}
      onSavePress={paymentSettingsFlag ? onSavePaymentSettings : onSavePress}
    >
      {paymentSettingsFlag ? (
        <>
          <Box display='flex' flex={1} flexDirection='column'>
            {isPaymentTypeError && (
              <Typography color='error' id='payment-type-error' sx={{ fontWeight: 'bold' }}>
                {t('services.pricing.paymentTypesSelectionError')}
              </Typography>
            )}
            {renderCheckboxes}
            <Link
              component='button'
              variant='body1'
              onClick={onOpenInformationModal}
              alignSelf='baseline'
            >
              {t('services.pricing.informationModalButtonContent')}
            </Link>
          </Box>
          {renderPricing}
        </>
      ) : (
        <Box display='flex' flexWrap='wrap' flexShrink={1} alignItems='center'>
          {sectionContent}
        </Box>
      )}
      {paymentSettingsFlag && (
        <PaymentTypesInformationModal
          open={isInformationModalOpen}
          onClose={onCloseInformationModal}
        />
      )}
    </EditableSection>
  );
};
