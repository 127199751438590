// Copyright 2020 Prescryptive Health, Inc.

import { useContext } from 'react';
import {
  INotificationContext,
  NotificationContext,
} from '../../providers/notification/notification.context';

export const useNotificationContext = (): INotificationContext =>
  useContext<INotificationContext>(NotificationContext);
