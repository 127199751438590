// Copyright 2020 Prescryptive Health, Inc.

import { ReactNode } from 'react';

export enum ErrorStatus {
  none,
  fatal,
  nonFatal,
}

export interface IErrorState {
  readonly status: ErrorStatus;
  readonly title?: string;
  readonly content?: ReactNode;
}
