// Copyright 2021 Prescryptive Health, Inc.

import { Maybe } from 'graphql/jsutils/Maybe';
import React, { ReactElement } from 'react';
import { ProviderProgramThingsToConsiderDynamicZone, ProviderServiceThingsToConsiderDynamicZone } from '../../../../../model/strapi/strapi-models';
import { SectionHeading } from '../../../../strapi/section-heading/section-heading';
import { TitleDescription } from '../../../../strapi/title-description/title-description';
import { StyledSectionContainer } from '../styled-components/section-container.styled-component';
import { TitleDescriptionBox } from '../styled-components/title-description/title-description-containers.styled-components';

interface IThingsToConsiderProps {
  thingsToConsiderData:
    | Maybe<ProviderServiceThingsToConsiderDynamicZone | ProviderProgramThingsToConsiderDynamicZone>[]
    | null
    | undefined;
}

export const ThingsToConsider = ({
  thingsToConsiderData,
}: IThingsToConsiderProps): ReactElement => {

  const costPerPharmacySection = thingsToConsiderData?.map((elem) => {
    switch (elem?.__typename) {
      case 'ComponentProviderServiceContentTypesSectionHeading': {
        return <SectionHeading key={elem.id} headingText={elem.HeadingText} />;
      }

      default:
        return null;
    }
  });

  const titleDescriptions = thingsToConsiderData?.map((elem) => {
    switch (elem?.__typename) {
      case 'ComponentProviderServiceContentTypesTitleDescription': {
        return (
          <TitleDescription
            key={elem.id}
            title={elem.Title}
            description={elem.Description}
            url={elem.TitleDescriptionUrl}
          />
        );
      }

      default:
        return null;
    }
  });

  return (
    <StyledSectionContainer>
      {costPerPharmacySection}
      <TitleDescriptionBox>{titleDescriptions}</TitleDescriptionBox>
    </StyledSectionContainer>
  );
};
