// Copyright 2020 Prescryptive Health, Inc.

export enum HttpStatusCodesEnum {
  OK = 200,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  MULTI_STATUS = 207,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,
  PAYLOAD_TOO_LARGE = 413,
  REQUEST_TIMEOUT = 408,
}
