// Copyright 2021 Prescryptive Health, Inc.

import React, { useState, ReactElement, useEffect } from 'react';
import styled from 'styled-components';

interface AspectRatioBoxProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  $aspectRatio?: number;
}

const AspectRatioBox = styled.div<AspectRatioBoxProps>`
  ::before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(100% / (${(p) => p.$aspectRatio || 0}));
  }
  ::after {
    content: '';
    display: table;
    clear: both;
  }
`;

interface ImageLoadProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  aspectRatio?: number;
}

export const ImageLoad = (props: ImageLoadProps): ReactElement => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.src) {
      const imageToLoad = new Image();
      imageToLoad.src = props.src;
      imageToLoad.onload = () => {
        setLoading(false);
      };
    }
  }, [props.src]);

  const { style, aspectRatio, ...otherProps } = props;

  return (
    <AspectRatioBox $aspectRatio={aspectRatio}>
      <img
        {...otherProps}
        style={{
          opacity: loading ? 0 : 1,
          transition: 'opacity .15s linear',
          width: '100%',
          height: 'auto',
          ...style,
        }}
      />
    </AspectRatioBox>
  );
};
