// Copyright 2020 Prescryptive Health, Inc.

import { Typography, TypographyProps } from '@mui/material';
import React, { ReactElement } from 'react';
import { EmptyViewMessageContainer } from './empty-view.message.styled-components';

export interface IEmptyViewMessageProps extends TypographyProps {
  content?: string;
}

export const EmptyViewMessage = ({
  content = 'Please select a site address.',
  ...otherProps
}: IEmptyViewMessageProps): ReactElement => {
  return (
    <EmptyViewMessageContainer>
      <Typography {...otherProps}>{content}</Typography>
    </EmptyViewMessageContainer>
  );
};
