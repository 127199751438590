// Copyright 2021 Prescryptive Health, Inc.

import { IBatchStatus } from '../../../model/batch-status';
import { IWaitlistItem } from '../../../model/waitlist-item';

export interface IWaitlistState {
  waitlist: IWaitlistItem[];
  isStale: boolean;
  batchStatus?: IBatchStatus;
}

export const defaultWaitlistState: IWaitlistState = {
  waitlist: [],
  isStale: false,
};
