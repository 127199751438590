import { Typography, Box, useTheme, Avatar } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export const LearnMoreScreen = (): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box display='flex' flexDirection='column' gap={8} px={6} py={8}>
      <Typography variant='h1'>{t('learnMore.title')}</Typography>

      <Box>
        <Typography fontWeight='bold'>{t('learnMore.updatedDate')}</Typography>
        <Typography>{t('learnMore.publishedDate')}</Typography>
      </Box>

      <Typography variant='body1'>{t('learnMore.descriptionP1')}</Typography>
      <Typography variant='body1'>{t('learnMore.descriptionP2')}</Typography>
      <Typography variant='body1'>{t('learnMore.descriptionP3')}</Typography>

      <Box display='flex' flexDirection='column' gap={4}>
        <Box display='flex' gap={4}>
          <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
            1.
          </Typography>
          <Typography>{t('learnMore.steps.step1')}</Typography>
        </Box>
        <Box display='flex' gap={4}>
          <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
            2.
          </Typography>
          <Typography>{t('learnMore.steps.step2')}</Typography>
        </Box>
        <Box display='flex' gap={4}>
          <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
            3.
          </Typography>
          <Typography>{t('learnMore.steps.step3')}</Typography>
        </Box>
        <Box display='flex' gap={4}>
          <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
            4.
          </Typography>
          <Typography>{t('learnMore.steps.step4')}</Typography>
        </Box>
        <Box display='flex' gap={4}>
          <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
            5.
          </Typography>
          <Typography>{t('learnMore.steps.step5')}</Typography>
        </Box>
      </Box>

      <Typography variant='h2'>{t('learnMore.questionsAndAnswersHeading')}</Typography>
      <Box display='flex' flexDirection='column' gap={8}>
        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question1')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>{t('learnMore.answers.answer1')}</Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question2')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>{t('learnMore.answers.answer2')}</Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question3')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>{t('learnMore.answers.answer3.answer')}</Typography>
          </Box>
          <Box display='flex' flexDirection='column' pl={8} gap={4}>
            <Typography>{t('learnMore.answers.answer3.step1')}</Typography>
            <Typography>{t('learnMore.answers.answer3.step2')}</Typography>
            <Typography>{t('learnMore.answers.answer3.step3')}</Typography>
            <Typography>{t('learnMore.answers.answer3.step4')}</Typography>
            <Typography>{t('learnMore.answers.answer3.step5')}</Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question4')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>{t('learnMore.answers.answer4')}</Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question5')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>{t('learnMore.answers.answer5')}</Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question6')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>{t('learnMore.answers.answer6')}</Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question7')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>{t('learnMore.answers.answer7')}</Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question8')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>{t('learnMore.answers.answer8')}</Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question9')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>
              {t('learnMore.answers.answer9')}{' '}
              <a href='mailto:pharmacyrelations@prescryptive.com'>
                pharmacyrelations@prescryptive.com
              </a>
              {'.'}
            </Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question10')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>{t('learnMore.answers.answer10')} </Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question11')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>{t('learnMore.answers.answer11')}</Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question12')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>{t('learnMore.answers.answer12')}</Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question13')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>{t('learnMore.answers.answer13')}</Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question14')}{' '}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>{t('learnMore.answers.answer14')}</Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question15')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>{t('learnMore.answers.answer15.answer')}</Typography>
          </Box>
          <Box display='flex' flexDirection='column' pl={8} gap={4}>
            <Typography>
              <a href='https://support.google.com/mail/answer/56256?hl=en'>
                {t('learnMore.answers.answer15.step1')}
              </a>
            </Typography>
            <Typography>
              <a href='https://ca.help.yahoo.com/kb/SLN2056.html'>
                {t('learnMore.answers.answer15.step2')}
              </a>
            </Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question16')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>
              {t('learnMore.answers.answer16')}{' '}
              <a href='mailto:pharmacyrelations@prescryptive.com'>
                pharmacyrelations@prescryptive.com
              </a>
              {'.'}
            </Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question17')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>
              {t('learnMore.answers.answer17.answer')}{' '}
              <a href='mailto:noreply@prescryptive.com'>noreply@prescryptive.com</a>{' '}
              {t('learnMore.answers.answer17.answerAfterEmail')}{' '}
              <a href='mailto:pharmacyrelations@prescryptive.com'>
                pharmacyrelations@prescryptive.com
              </a>
              {'.'}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' pl={8} gap={4}>
            <Avatar
              src={process.env.PUBLIC_URL + '/media/password-reset-email.4f8e3519.png'}
              variant='square'
              alt='Password Reset Email'
              style={{ width: '60%', height: 'auto' }}
            />
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question18')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>{t('learnMore.answers.answer18')}</Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              Q:
            </Typography>
            <Typography color={theme.palette.purpleScale[300]} fontWeight='700'>
              {t('learnMore.questions.question19')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row' gap={4}>
            <Typography>A:</Typography>
            <Typography>
              {t('learnMore.answers.answer19')}{' '}
              <a href='mailto:pharmacyrelations@prescryptive.com'>
                pharmacyrelations@prescryptive.com
              </a>
              {'.'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
