import { FunctionComponent } from 'react';
import { getMetaEnvironmentVariable } from '../../config/config.helper';
import {
  defaultDrugSearchConfigState,
  IDrugSearchConfigState,
} from '../../state/drug-search/drug-search.state';
import { DrugSearchConfigContext } from './drug-search-config.context';

export const DrugSearchConfigContextProvider: FunctionComponent = ({ children }) => {
  const drugSearchConfigState = stateLoad();

  return (
    <DrugSearchConfigContext.Provider
      value={{ drugSearchConfigState }}
      data-testid='drug-search-config-context-provider'
    >
      {children}
    </DrugSearchConfigContext.Provider>
  );
};

function stateLoad(): IDrugSearchConfigState {
  const state = { ...defaultDrugSearchConfigState };

  state.apis.authentication.subscriptionKey = getMetaEnvironmentVariable(
    'GEARS_API_SUBSCRIPTION_KEY'
  );

  state.apis.drugSearchApiConfig.env.host = getMetaEnvironmentVariable('PLATFORM_GEARS_API_URL');
  state.apis.drugSearchApiConfig.env.port = getMetaEnvironmentVariable('PLATFORM_GEARS_PORT');

  return state;
}
