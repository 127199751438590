// Copyright 2021 Prescryptive Health, Inc.

import { buildBearerAuthHeaders, buildUrl } from '../../api.helper';
import { call } from '../../call';
import { HttpStatusCodesEnum } from '../../http-status-codes';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { ApiErrors } from '../../errors/api-errors';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export async function providerServiceAdd(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  serviceType: string,
  providerName: string
): Promise<void> {
  const url = buildUrl(apiConfig, 'providerServiceAdd', {
    ':providerName': providerName,
  });
  const headers = buildBearerAuthHeaders(authToken);

  const body: string = serviceType;

  const response: Response = await call(url, body, 'POST', headers);
  if (response.ok) {
    return;
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.PROVIDER_SERVICE_ADD,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  throw new PharmacyPortalError(
    ApiErrors.providerServiceAdd(response.statusText),
    ApiErrorCode.PROVIDER_SERVICE_ADD,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}
