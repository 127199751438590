// Copyright 2021 Prescryptive Health, Inc.

import { ReactElement, useState } from 'react';
import { ServiceStatus } from '../../../../../model/pharmacy-service';
import { Box, useTheme } from '@mui/material';
import dateFormatter from '../../../../../formatters/dates/date.formatter';
import { ServiceStatusPicker } from './service-status/service-status.picker';
import { notificationSetDispatch } from '../../../../../state/notification/dispatch/notification-set.dispatch';
import { usePostAuthScreenContext } from '../../../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { useLocationContext } from '../../../../../providers/location/use-location-context.hook';
import { useNotificationContext } from '../../../../../hooks/use-notification-context/use-notification-context.hook';
import { serviceNameFromType } from '../../../../../selectors/service-name';
import { LabeledDetail } from '../../../../layout/labeled-detail/labeled-detail';
import {
  ILocationServiceUpdateAsyncActionArgs,
  locationServiceUpdateAsyncAction,
} from '../../../../../state/location/async-actions/location-service-update.async-action';
import { NumberPickerDetail } from '../../../../pickers/number/number.picker-detail';
import { EditableSection } from '../../../../sections/editable/editable.section';
import { TFunction, useTranslation } from 'react-i18next';
import { getToken } from '../../../../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
export interface IGeneralSectionProps {
  durationMinutes?: number;
  status: ServiceStatus;
  availableQuantity?: number;
  serviceType: string;
  enrollmentStatus?: string;
  minLeadHours?: number;
}

export const GeneralSection = ({
  durationMinutes = 0,
  status,
  serviceType,
  enrollmentStatus,
  minLeadHours = 0,
}: IGeneralSectionProps): ReactElement => {
  const { t } = useTranslation();
  const { authProvider, busyDispatch, configState, errorDispatch, telemetryService } =
    usePostAuthScreenContext({ defaultContent: {} });
  const theme = useTheme();
  const {
    locationDispatch,
    locationState: { location, services },
  } = useLocationContext();

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const { notificationDispatch } = useNotificationContext();

  const [isEditMode, setIsEditMode] = useState(false);
  const [updatedDurationMinutes, setUpdatedDurationMinutes] = useState<number>();
  const [updatedStatus, setUpdatedStatus] = useState<ServiceStatus>();
  const [quantityAdjustment, setQuantityAdjustment] = useState<number>();
  const [updatedMinLeadHours, setUpdatedMinLeadHours] = useState<number>();
  const formattedDuration = dateFormatter.formatMinutesDuration(durationMinutes, {
    oneMinute: t('timeContent.short.oneMinute'),
    oneHour: t('timeContent.short.oneHour'),
    minutes: t('timeContent.short.minutes'),
    hours: t('timeContent.short.hours'),
  });

  const onCancelPress = () => setIsEditMode(false);

  const onEditPress = () => {
    setIsEditMode(true);
    setUpdatedDurationMinutes(durationMinutes);
    setUpdatedStatus(status);
    setQuantityAdjustment(undefined);
    setUpdatedMinLeadHours(minLeadHours);
  };

  const onSavePress = async () => {
    if (
      !location ||
      !updatedDurationMinutes ||
      !updatedStatus ||
      updatedMinLeadHours === undefined
    ) {
      return;
    }

    const args: ILocationServiceUpdateAsyncActionArgs = {
      authProvider,
      busyDispatch,
      configState,
      errorDispatch,
      locationDispatch,
      telemetryService,
      locationId: location.id,
      serviceType,
      durationMinutes: updatedDurationMinutes,
      status: updatedStatus,
      quantityAdjustment: quantityAdjustment ?? 0,
      minLeadHours: updatedMinLeadHours ?? 0,
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently)
    };

    try {
      await locationServiceUpdateAsyncAction(args);

      setIsEditMode(false);

      notificationSetDispatch(
        notificationDispatch,
        t('services.general.updateSuccessMessageText', {
          serviceName: serviceNameFromType(serviceType, services),
        })
      );
    } catch {
      return;
    }
  };

  const formattedStatus = getStatusText(status, t);

  const readModeContent = (
    <Box display='flex' alignItems='baseline'>
      <LabeledDetail
        label={t('services.general.statusLabel')}
        detail={formattedStatus}
        description={t('services.general.statusDescription')}
        additionalDescription={t('services.general.statusAdditionalDescription')}
      />
      <LabeledDetail
        label={t('services.general.durationLabel')}
        detail={formattedDuration}
        description={t('services.general.durationDescription')}
      />
      <LabeledDetail
        label={t('services.general.minLeadTimeLabel')}
        detail={`${minLeadHours.toString()} hour`}
        description={t('services.general.minLeadTimeDescription')}
        additionalDescription={t('services.general.minLeadTimeAdditionalDescription')}
      />
    </Box>
  );

  const editModeContent = (
    <Box display='flex' gap={theme.spacing(4)} flexWrap='wrap'>
      <ServiceStatusPicker
        label={t('services.general.statusLabel')}
        onSelect={setUpdatedStatus}
        initialValue={updatedStatus}
        isDisabled={enrollmentStatus === 'pending'}
      />
      <NumberPickerDetail
        label={t('services.general.durationLabel')}
        minimum={5}
        maximum={60}
        interval={5}
        initialValue={updatedDurationMinutes}
        unitText={t('timeContent.short.minutes')}
        onSelect={setUpdatedDurationMinutes}
        description={t('services.general.durationDescription')}
      />
      <NumberPickerDetail
        label={t('services.general.minLeadTimeLabel')}
        minimum={0}
        maximum={24}
        interval={1}
        initialValue={updatedMinLeadHours}
        unitText={t('timeContent.short.hours')}
        onSelect={setUpdatedMinLeadHours}
        description={t('services.general.minLeadTimeDescription')}
        additionalDescription={t('services.general.minLeadTimeAdditionalDescription')}
      />
    </Box>
  );

  const sectionContent = isEditMode ? editModeContent : readModeContent;

  return (
    <EditableSection
      heading={t('services.general.heading')}
      isEditMode={isEditMode}
      onEditPress={onEditPress}
      onCancelPress={onCancelPress}
      onSavePress={onSavePress}
    >
      <Box display='flex' flexWrap='wrap' flexShrink={1} alignItems='center'>
        {sectionContent}
      </Box>
    </EditableSection>
  );
};

const getStatusText = (status: ServiceStatus, t: TFunction<'translation', undefined>): string => {
  switch (status) {
    case 'everyone':
      return t('services.general.statusEveryone');

    case 'invite-only':
      return t('services.general.statusWaitlist');

    default:
      return t('services.general.statusOff');
  }
};
