// Copyright 2021 Prescryptive Health, Inc.

import { createContext } from 'react';
import {
  IWaitlistState,
  defaultWaitlistState,
} from '../../state/customers/waitlist/waitlist.state';
import { WaitlistDispatch } from '../../state/customers/waitlist/dispatch/waitlist.dispatch';

export interface IWaitlistContext {
  readonly waitlistState: IWaitlistState;
  readonly waitlistDispatch: WaitlistDispatch;
}

export const WaitlistContext = createContext<IWaitlistContext>({
  waitlistState: defaultWaitlistState,
  waitlistDispatch: () => {
    return;
  },
});
