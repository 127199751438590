// Copyright 2021 Prescryptive Health, Inc.

import { styled, Box, Typography } from '@mui/material';

export const ProgramEnrollmentBarContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('xl')]: {
    display: 'none',
  },
}));

export const ProgramEnrollmentShortTextTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    paddingTop: theme.spacing(5),
  },
}));

export const ProgramEnrollmentButtonContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    width: '100%',
  },
}));
