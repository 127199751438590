// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

export interface ICopyText {
  textToCopy: string;
}

export const CopyText = ({ textToCopy }: ICopyText): ReactElement => {
  const { t } = useTranslation();

  const onCopyClick = async () => {
    await navigator.clipboard.writeText(textToCopy);
  };
  const theme = useTheme();
  return (
    <Paper elevation={0}>
      <Box
        display='flex'
        bgcolor={theme.palette.grey['50']}
        padding={theme.spacing(2)}
        justifyContent='space-between'
        alignItems='center'
      >
        <Box textOverflow='clip' overflow='auto' whiteSpace='nowrap'>
          <Typography>{textToCopy}</Typography>
        </Box>
        <Box>
          <Button variant='text' onClick={onCopyClick} startIcon={<FileCopyIcon />} color='primary'>
            {t('copy.copyText.copyLabel')}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};
