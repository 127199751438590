// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  ProviderLanderSectionContentZoneDynamicZone,
} from '../../../../model/strapi/strapi-models';
import {
  StyledSingleColumnContainer,
  StyledSingleColumnDescriptionTypography,
  StyledSingleColumnHeaderTypography,
} from './single-column.styled-components';
import { strapiComponentBuilder } from '../../../strapi/helpers/strapi-component-builder';
import { Box } from '@mui/material';

type ISingleColumnProps = {
  singleColumnData: Maybe<Maybe<ProviderLanderSectionContentZoneDynamicZone>[]>;
};

export const SingleColumn = ({
  singleColumnData,
}: ISingleColumnProps): ReactElement => {
  const singleColumnContent = singleColumnData?.map((elem) => {
    if (
      elem?.__typename ===
      'ComponentProviderServiceContentTypesTitleDescription'
    ) {
      return (
        <Box textAlign='center' key={elem?.id}>
          <StyledSingleColumnHeaderTypography variant='h4'>
            {elem?.Title}
          </StyledSingleColumnHeaderTypography>
          <StyledSingleColumnDescriptionTypography>
            {elem?.Description}
          </StyledSingleColumnDescriptionTypography>
        </Box>
      );
    }

    return (
      <Box key={elem?.id}>
        {strapiComponentBuilder(elem)}
      </Box>
    );
  });

  return (
    <StyledSingleColumnContainer>
      {singleColumnContent}
    </StyledSingleColumnContainer>
  );
};
