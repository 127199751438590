// Copyright 2021 Prescryptive Health, Inc.

import { Maybe } from 'graphql/jsutils/Maybe';
import React, { ReactElement } from 'react';
import {
  ProviderProgramPharmacyCostsDynamicZone,
  ProviderServicePharmacyCostsDynamicZone,
} from '../../../../../model/strapi/strapi-models';
import { SectionHeading } from '../../../../strapi/section-heading/section-heading';
import { TitleDescription } from '../../../../strapi/title-description/title-description';
import { TitleDescriptionBox } from '../styled-components/title-description/title-description-containers.styled-components';
import { CostsPerPharmacyContainer } from './costs-per-pharmacy.styled-components';

interface ICostsPerPharmacyProps {
  costsPerPharmacyData:
    | Maybe<
        | ProviderServicePharmacyCostsDynamicZone
        | ProviderProgramPharmacyCostsDynamicZone
      >[]
    | null
    | undefined;
}

export const CostsPerPharmacy = ({
  costsPerPharmacyData,
}: ICostsPerPharmacyProps): ReactElement => {
  const costPerPharmacySectionHeading = costsPerPharmacyData?.map((elem) => {
    switch (elem?.__typename) {
      case 'ComponentProviderServiceContentTypesSectionHeading': {
        return <SectionHeading key={elem.id} headingText={elem.HeadingText} />;
      }

      default:
        return null;
    }
  });

  const titleDescriptions = costsPerPharmacyData?.map((elem) => {
    switch (elem?.__typename) {
      case 'ComponentProviderServiceContentTypesTitleDescription': {
        return (
          <TitleDescription
            key={elem.id}
            title={elem.Title}
            description={elem.Description}
            url={elem.TitleDescriptionUrl}
          />
        );
      }

      default:
        return null;
    }
  });

  return (
    <CostsPerPharmacyContainer>
      {costPerPharmacySectionHeading}
      <TitleDescriptionBox>{titleDescriptions}</TitleDescriptionBox>
    </CostsPerPharmacyContainer>
  );
};
