// Copyright 2020 Prescryptive Health, Inc.

import { ApplicationInsights, IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import { ICustomProperties, ITelemetryItem } from '@microsoft/applicationinsights-core-js';
import { History } from 'history';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

export interface ITelemetryServiceConfig {
  instrumentationKey?: string;
  serviceName?: string;
  browserHistory?: History;
  authenticationApiHost?: string;
}

export class TelemetryService<TEvent extends string = ''> {
  private readonly config: ITelemetryServiceConfig;
  private initializedAppInsights?: ApplicationInsights;

  constructor(config: ITelemetryServiceConfig = {}) {
    this.config = config;
  }

  public trackException = (error: Error, customProperties = {} as ICustomProperties): void => {
    try {
      this.appInsights.trackException({ exception: error }, customProperties);
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error((ex as Error).message);
    }
  };

  public trackEvent = (event: TEvent, payload: string | ICustomProperties): void => {
    const properties = typeof payload === 'string' ? { message: payload } : payload;

    try {
      this.appInsights.trackEvent({ name: event }, properties);
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error((ex as Error).message);
    }
  };

  public trackPageView = (payload: IPageViewTelemetry): void => {
    try {
      this.appInsights.trackPageView(payload);
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error((ex as Error).message);
    }
  };

  public trackMetric = (name: string, value: number, payload: string | ICustomProperties): void => {
    const properties = typeof payload === 'string' ? { message: payload } : payload;

    try {
      this.appInsights.trackMetric({ name, average: value }, properties);
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error((ex as Error).message);
    }
  };

  public get appInsights(): ApplicationInsights {
    if (!this.initializedAppInsights) {
      this.initializedAppInsights = this.initialize();
    }

    return this.initializedAppInsights;
  }

  private initialize(): ApplicationInsights {
    const { browserHistory, instrumentationKey } = this.config;
    const reactPlugIn = new ReactPlugin();

    if (!instrumentationKey) {
      throw new Error('Telemetry instrumentation key not provided!');
    }

    const appInsights = new ApplicationInsights({
      config: {
        disableAjaxTracking: false, // This is needed so that AAD login requests are tracked.
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        extensionConfig: {
          [reactPlugIn.identifier]: {
            history: browserHistory,
          },
        },
        extensions: [reactPlugIn],
        instrumentationKey,
        maxBatchInterval: 0,
        correlationHeaderExcludedDomains: ['*.launchdarkly.com'],
      },
    });

    appInsights.loadAppInsights();

    this.serviceNameSet(appInsights);
    this.authenticationAjaxFilterSet(appInsights);

    return appInsights;
  }

  private serviceNameSet(appInsights: ApplicationInsights): void {
    const { serviceName } = this.config;
    if (!serviceName) {
      throw new Error('Telemetry service name not provided!');
    }

    appInsights.addTelemetryInitializer((telemetryItem) => {
      const tags = telemetryItem.tags;
      if (tags) {
        tags['ai.cloud.role'] = serviceName;
      }
    });
  }

  private authenticationAjaxFilterSet(appInsights: ApplicationInsights) {
    const { authenticationApiHost } = this.config;
    if (!authenticationApiHost) {
      return;
    }

    function ajaxFilter(telemetryItem: ITelemetryItem): boolean {
      const baseData = telemetryItem.baseData;

      if (baseData && baseData.type === 'Ajax') {
        const target: string = baseData.target as string;
        const isAuthenticationApiHost = target.search(authenticationApiHost ?? '') !== -1;
        return isAuthenticationApiHost;
      }

      return true;
    }

    appInsights.addTelemetryInitializer(ajaxFilter);
  }
}
