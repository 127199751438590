// Copyright 2021 Prescryptive Health, Inc.

import { Typography } from '@mui/material';

import { styled } from '@mui/material';

export const EnrolledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 700,
  marginLeft: theme.spacing(1),
}));
