// Copyright 2021 Prescryptive Health, Inc.
import React, { ReactElement } from 'react';
import { ComponentProviderServiceContentTypesTestimonial } from '../../../model/strapi/strapi-models';
import { buildResourceUrl } from '../helpers/build-resource-url';
import { ImageLoad } from '../image-link/image-load';
import {
  StyledInnerTestimonialContainer,
  StyledQuotename,
  StyledQuoteText,
  StyledQuoteTitle,
} from './testimonials.styled-components';
import ENV from '../../../helpers/environment-variables';

interface ITestimonialProps {
  carouselItem: ComponentProviderServiceContentTypesTestimonial;
}

export const Testimonial = ({ carouselItem }: ITestimonialProps): ReactElement => {
  const image = carouselItem.TestimonialImage ? (
    <ImageLoad
      style={{ borderRadius: 50 }}
      src={buildResourceUrl(ENV.STRAPI_HOST_URL, carouselItem.TestimonialImage?.url ?? '')}
      alt={carouselItem.TestimonialImage?.alternativeText || undefined}
      aspectRatio={
        (carouselItem.TestimonialImage?.width &&
          carouselItem.TestimonialImage.height &&
          carouselItem.TestimonialImage.width / carouselItem.TestimonialImage.height) ||
        0
      }
    />
  ) : null;
  return (
    <StyledInnerTestimonialContainer key={carouselItem?.id}>
      {image}
      <StyledQuoteText>{carouselItem.QuoteText}</StyledQuoteText>
      <StyledQuotename>{carouselItem.QuotedName}</StyledQuotename>
      <StyledQuoteTitle>{carouselItem.QuotedTitle}</StyledQuoteTitle>
    </StyledInnerTestimonialContainer>
  );
};
