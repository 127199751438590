// Copyright 2021 Prescryptive Health, Inc.

import { Typography, CardMedia } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { ReactElement } from 'react';
import {
  ProviderLanderSectionContentZoneDynamicZone,
  UploadFile,
} from '../../../../model/strapi/strapi-models';
import { buildResourceUrl } from '../../../strapi/helpers/build-resource-url';
import {
  StyledHeroContainer,
  StyledMainContentBox,
  StyledTitleDescriptionBox,
} from './hero-section.styled-components';
import ENV from '../../../../helpers/environment-variables';

interface IHeroSectionProps {
  beforeBackgroundColor?: string | null;
  contentZones: Maybe<ProviderLanderSectionContentZoneDynamicZone>[] | null | undefined;
}

export const HeroSection = ({
  beforeBackgroundColor,
  contentZones,
}: IHeroSectionProps): ReactElement => {
  const sectionContents = contentZones?.map((elem) => {
    switch (elem?.__typename) {
      case 'ComponentProviderServiceContentTypesImageLink': {
        const imageFile = elem?.ImageFile ?? ({} as UploadFile);

        const link = imageFile.url;
        const width = imageFile.width ?? 0;
        const height = imageFile.height ?? 0;

        const imageHeight = `${height}px`;
        const imageWidth = `${width}px`;

        return (
          <CardMedia
            key={elem.id}
            alt={imageFile.name ?? ''}
            component='img'
            style={{ height: imageHeight, width: imageWidth }}
            src={buildResourceUrl(ENV.STRAPI_HOST_URL, link)}
          />
        );
      }

      case 'ComponentProviderServiceContentTypesTitleDescription': {
        return (
          <StyledTitleDescriptionBox key={elem.id}>
            <Typography variant='h3' fontWeight='400' style={{ fontSize: '40px'}}>{elem.Title}</Typography>
            <Typography variant='body1'>{elem.Description}</Typography>
          </StyledTitleDescriptionBox>
        );
      }

      default: {
        return null;
      }
    }
  });

  return (
    <StyledHeroContainer
      display='flex'
      flexDirection='column'
      flex='1'
      zIndex='1'
      $beforeBackgroundColor={beforeBackgroundColor}
      position='relative'
    >
      <StyledMainContentBox>{sectionContents}</StyledMainContentBox>
    </StyledHeroContainer>
  );
};
