// Copyright 2021 Prescryptive Health, Inc.

import React, { FunctionComponent, useReducer } from 'react';
import { ScreenReducer, screenReducer } from '../../state/screen/screen.reducer';
import { defaultScreenState } from '../../state/screen/screen.state';
import { ScreenContext } from './screen.context';

export const ScreenContextProvider: FunctionComponent = ({ children }) => {
  const [screenState, screenDispatch] = useReducer<ScreenReducer>(
    screenReducer,
    defaultScreenState
  );
  return (
    <ScreenContext.Provider
      value={{
        screenState,
        screenDispatch,
      }}
    >
      {children}
    </ScreenContext.Provider>
  );
};
