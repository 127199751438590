// Copyright 2020 Prescryptive Health, Inc.

import { createContext } from 'react';
import { IBusyState } from '../../state/busy/busy.state';
import { BusyDispatch } from '../../state/busy/dispatch/busy.dispatch';

export interface IBusyContext {
  readonly busyState: IBusyState;
  readonly busyDispatch: BusyDispatch;
}

export const BusyContext = createContext<IBusyContext>({
  busyState: { busyCount: 0 },
  busyDispatch: () => ({}),
});
