// Copyright 2021 Prescryptive Health, Inc.

import { Box } from '@mui/material';
import { styled } from '@mui/material';

export const StyledImportToWaitlistErrorContainer = styled(Box)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey.A100,
  },
}));
