// Copyright 2020 Prescryptive Health, Inc.

import { BusyDispatch } from './busy.dispatch';
import { busyPushDispatch } from './busy-push.dispatch';
import { busyPopDispatch } from './busy-pop.dispatch';

export async function showBusyWhile(
  busyDispatch: BusyDispatch,
  action: () => Promise<void>
): Promise<void> {
  try {
    busyPushDispatch(busyDispatch);
    await action();
  } finally {
    busyPopDispatch(busyDispatch);
  }
}
