// Copyright 2021 Prescryptive Health, Inc.

import { ILocationAction } from './location.action';

export type ILocationSelectedDateSetAction =
  ILocationAction<'LOCATION_SELECTED_DATE_SET'>;

export const locationSelectedDateSetAction = (
  selectedDate: Date
): ILocationSelectedDateSetAction => ({
  type: 'LOCATION_SELECTED_DATE_SET',
  payload: {
    locationSelectedDate: selectedDate,
  },
});
