// Copyright 2021 Prescryptive Health, Inc.

import { Typography } from '@mui/material';
import React, { ReactElement } from 'react';

interface ILongTextProps {
  longText: string | null | undefined;
}

export const LongText = ({ longText }: ILongTextProps): ReactElement => {

  return <Typography>{longText}</Typography>;
};