// Copyright 2021 Prescryptive Health, Inc.

import { HttpStatusCodesEnum } from '../../http-status-codes';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { IStoreLocation } from '../../../model/store-location';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { call } from '../../call';
import { ApiErrors } from '../../errors/api-errors';
import { IProviderDetailsResponse } from './provider-details-get.response';
import { IProvider } from '../../../model/provider';
import { IEnrolledServiceType } from '../../../model/enrolled-service-type';
import { IEnrolledProgram } from '../../../model/enrolled-program';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export interface IProviderDetailsGetResult {
  currentProvider: IProvider;
  locations: IStoreLocation[];
  enrolledServiceTypes: IEnrolledServiceType[];
  enrolledPrograms: IEnrolledProgram[];
}

export async function providerDetailsGet(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  providerId: string
): Promise<IProviderDetailsGetResult> {
  const url = buildUrl(apiConfig, 'providerDetailsGet', {
    ':providerId': providerId,
  });
  const headers = buildBearerAuthHeaders(authToken);

  const response: Response = await call(url, undefined, 'GET', headers);
  if (response.ok) {
    if (response.status === HttpStatusCodesEnum.NO_CONTENT) {
      return {
        currentProvider: {
          cliaNumber: '',
          npiNumber: '',
          providerTaxId: '',
          deaNumber: '',
          name: '',
          phoneNumber: '',
          email: '',
        },
        locations: [],
        enrolledServiceTypes: [],
        enrolledPrograms: [],
      };
    }

    return await resultFromResponse(providerId, response);
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.PROVIDER_DETAILS_GET,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  throw new PharmacyPortalError(
    ApiErrors.providerGet(providerId, response.statusText),
    ApiErrorCode.PROVIDER_DETAILS_GET,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}

async function resultFromResponse(
  providerId: string,
  response: Response
): Promise<IProviderDetailsGetResult> {
  const responseData = (await response.json()) as IProviderDetailsResponse;
  if (!responseData) {
    throw new Error(ApiErrors.providerGet(providerId, ApiErrors.unexpectedResponseDataFormat));
  }

  const {
    locations: responseLocations = [],
    services: responseServices = [],
    programs: responsePrograms = [],
  } = responseData;

  const locations = responseLocations.map((responseLocation) => {
    const location: IStoreLocation = {
      id: responseLocation.identifier ?? '',
      name: responseLocation.locationName ?? '',
      phoneNumber: responseLocation.phoneNumber ?? '',
      timezone: responseLocation.timezone ?? '',
      lanes: responseLocation.lanes ?? [],
      isTest: responseLocation.isTest ?? true,
      address: {
        line1: responseLocation.address ?? '',
        city: responseLocation.city ?? '',
        state: responseLocation.state ?? '',
        zip: responseLocation.zip ?? '',
      },
    };
    return location;
  });

  const enrolledServiceTypes = responseServices.map((responseEnrolledServiceType) => {
    const enrolledServiceType: IEnrolledServiceType = {
      serviceType: responseEnrolledServiceType.serviceType ?? '',
      enrollmentStatus: responseEnrolledServiceType.enrollmentStatus ?? '',
    };
    return enrolledServiceType;
  });

  const enrolledPrograms: IEnrolledProgram[] = responsePrograms.map((responseEnrolledProgram) => ({
    programIdentifier: responseEnrolledProgram?.identifier ?? '',
    enrollmentStatus: responseEnrolledProgram?.enrollmentStatus ?? '',
  }));

  const currentProvider: IProvider = {
    name: providerId,
    npiNumber: responseData.npiNumber ?? '',
    providerTaxId: responseData.providerTaxId ?? '',
    cliaNumber: responseData.cliaNumber ?? '',
    deaNumber: responseData.deaNumber ?? '',
    phoneNumber: responseData.phoneNumber ?? '',
    email: responseData.email ?? '',
  };

  return {
    currentProvider,
    locations,
    enrolledServiceTypes,
    enrolledPrograms,
  };
}
