// Copyright 2021 Prescryptive Health, Inc.

import { Box, Button, Typography } from '@mui/material';
import { styled as muiStyled } from '@mui/material';

const LabeledDetailBox = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: theme.spacing(6),
}));

export const LabeledDetailMUIContainer = muiStyled(LabeledDetailBox)({
  width: '100%',
});

export const LabeledDetailMUITypography = muiStyled(Typography)({});

export const LabeledDetailContentMUITypography = muiStyled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(6),
  fontWeight: 'bold',
}));

export const LabeledDetailDescriptionMUITypography = muiStyled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(6),
  color: theme.palette.grey[700],
}));

export const LabeledDetailMUIButton = muiStyled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  minWidth: 'unset',
}));
