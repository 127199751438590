// Copyright 2021 Prescryptive Health, Inc.

import { Box, useTheme } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { ReactElement } from 'react';
import {
  ProviderProgramMarketingResourcesDynamicZone,
  ProviderServiceMarketingResourcesDynamicZone,
} from '../../../../../model/strapi/strapi-models';
import { useProviderContext } from '../../../../../providers/provider/use-provider-context.hook';
import { useTelemetryContext } from '../../../../../providers/telemetry/use-telemetry-context.hook';
import { strapiComponentBuilder } from '../../../../strapi/helpers/strapi-component-builder';
import { SectionHeading } from '../../../../strapi/section-heading/section-heading';

interface IMarketingResourcesProps {
  marketingResourcesData:
    | Maybe<
        | ProviderServiceMarketingResourcesDynamicZone
        | ProviderProgramMarketingResourcesDynamicZone
      >[]
    | null
    | undefined;
}

export const MarketingResources = ({
  marketingResourcesData,
}: IMarketingResourcesProps): ReactElement => {
  const { telemetryService } = useTelemetryContext();
  const {
    providerState: { currentProvider },
  } = useProviderContext();

  const theme = useTheme();

  const onLinkPress = () => {
    telemetryService.trackEvent('click', {
      name: 'add-services-detail-downloadMarketingDoc',
      provider: currentProvider?.name,
    });
  };

  const sections = marketingResourcesData?.map((elem, index) => {
    const returnedComponent = strapiComponentBuilder(elem, onLinkPress);

    if (returnedComponent.type === SectionHeading) {
      return returnedComponent;
    }

    const boxPadding = index === 1 ? theme.spacing(0) : theme.spacing(6);

    return (
      <Box key={elem?.id} paddingTop={boxPadding}>
        {returnedComponent}
      </Box>
    );
  });

  return (
    <Box display='flex' flexDirection='column' paddingBottom={theme.spacing(6)}>
      {sections}
    </Box>
  );
};
