// Copyright 2020 Prescryptive Health, Inc.

import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { blockedTimeUnblock } from '../../../api/v1/blocked-time-unblock/blocked-time-unblock';
import { appointmentsAreStaleDispatch } from '../../appointments/dispatch/appointments-are-stale.dispatch';
import { AppointmentsDispatch } from '../../appointments/dispatch/appointments.dispatch';
import { logApiError } from '../../../api/errors/log-api-error';

export interface IBlockedTimeUnblockAsyncActionArgs extends IApiAsyncActionArgs {
  blockedTimeId: string;
  appointmentsDispatch: AppointmentsDispatch;
}

export const blockedTimeUnblockAsyncAction = async (
  args: IBlockedTimeUnblockAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IBlockedTimeUnblockAsyncActionArgs): Promise<void> => {
  const {
    blockedTimeId,
    telemetryService,
    appointmentsDispatch,
    configState,
    authProvider,
    getAuthToken,
  } = args;

  try {
    telemetryService.trackEvent('blocked-time-unblock', { blockedTimeId });

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }
    const accessToken = await getAuthToken;

    await blockedTimeUnblock(configState.apis.pharmacyPortal, accessToken, blockedTimeId);

    appointmentsAreStaleDispatch(appointmentsDispatch);
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
    return Promise.reject();
  }
};
