// Copyright 2021 Prescryptive Health, Inc.

import { HttpStatusCodesEnum } from '../../http-status-codes';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { call } from '../../call';
import { ApiErrors } from '../../errors/api-errors';
import { IPharmacyServiceQuestionUpsertRequest } from './pharmacy-service-question-upsert.request';
import {
  IServiceQuestion,
  IServiceQuestionSelectOption,
  ServiceQuestionType,
} from '../../../model/pharmacy-service';
import { IPharmacyServiceResponseQuestionOption } from '../pharmacy-services-get/pharmacy-services-get.response';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export async function pharmacyServiceQuestionUpsert(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  locationId: string,
  serviceType: string,
  question: IServiceQuestion
): Promise<void> {
  const questionId = question.id;

  const url = buildUrl(apiConfig, 'pharmacyServiceQuestionUpsert', {
    ':locationId': locationId,
    ':serviceType': serviceType,
    ':questionId': questionId,
  });
  const headers = buildBearerAuthHeaders(authToken);

  const { id, questionText, isRequired, type, selectOptions = [] } = question;
  const body: IPharmacyServiceQuestionUpsertRequest = {
    id,
    questionText,
    isRequired,
    type: getRequestQuestionType(type),
    selectOptions: selectOptions.map(mapSelectOptionToRequest),
  };
  const response: Response = await call(url, body, 'PUT', headers);
  if (response.ok) {
    return;
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.PHARMACY_SERVICE_QUESTION_UPSERT,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  throw new PharmacyPortalError(
    ApiErrors.pharmacyServiceQuestionUpsert(
      locationId,
      serviceType,
      questionId,
      response.statusText
    ),
    ApiErrorCode.PHARMACY_SERVICE_QUESTION_UPSERT,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}

const getRequestQuestionType = (questionType: ServiceQuestionType): string => {
  switch (questionType) {
    case 'text':
      return 'text';

    case 'datepicker':
      return 'datepicker';

    case 'single-select':
      return 'single-select';

    case 'multi-select':
      return 'multi-select';

    default:
      return 'text';
  }
};

const mapSelectOptionToRequest = ({
  id,
  text,
}: IServiceQuestionSelectOption): IPharmacyServiceResponseQuestionOption => ({
  id,
  text,
});
