// Copyright 2021 Prescryptive Health, Inc.

import { gql } from '@apollo/client';

export const GET_LANDER_PAGE_QUERY = gql`
  query landerPageContent {
    providerLander {
      id
      Logo {
        id
        ImageFile {
          __typename
          id
          name
          alternativeText
          ext
          url
          mime
          height
          width
        }
        ImageLinkUrl {
          DisplayText
          URLAddress
          Target
        }
      }
      SignInDisplayText
    }
    providerLanderSections {
      id
      SectionName
      SortOrder
      Layout
      PublishStartDateTime
      VisibleGroup
      BackgroundColor
      lander_carousel_items {
        id
        CarouselItemName
        CarouselItemContent {
          __typename
          ... on ComponentProviderServiceContentTypesImageLink {
            __typename
            id
            ImageLinkUrl {
              __typename
              id
              Target
              DisplayText
              URLAddress
            }
            ImageFile {
              __typename
              id
              name
              alternativeText
              ext
              url
              mime
              height
              width
            }
          }
          ... on ComponentProviderServiceContentTypesMediaFile {
            __typename
            id
            IsLink
            LinkDisplayText
            ImageFile {
              __typename
              id
              name
              alternativeText
              ext
              url
              mime
              height
              width
            }
          }
          ... on ComponentProviderServiceContentTypesUrl {
            __typename
            id
            DisplayText
            URLAddress
          }
          ... on ComponentProviderServiceContentTypesLongText {
            __typename
            id
            longText
          }
          ... on ComponentProviderServiceContentTypesShortText {
            __typename
            id
            shortText
          }
          ... on ComponentProviderServiceContentTypesValueProp {
            __typename
            id
            valuePropIcon {
              id
              alternativeText
              caption
              ext
              height
              mime
              name
              previewUrl
              url
              width
            }
            valuePropTitle
            valuePropText
          }
          ... on ComponentProviderServiceContentTypesTitleDescription {
            __typename
            id
            Description
            Title
            TitleDescriptionUrl {
              id
              Target
              DisplayText
              URLAddress
            }
          }
          ... on ComponentProviderServiceContentTypesSectionHeading {
            __typename
            id
            HeadingText
          }
          ... on ComponentProviderServiceContentTypesInternalLink {
            __typename
            id
            linkText
            isDetails
          }
          ... on ComponentProviderServiceContentTypesTestimonial {
            __typename
            id
            TestimonialImage {
              __typename
              id
              name
              alternativeText
              ext
              url
              mime
              height
              width
            }
            QuoteText
            QuotedName
            QuotedTitle
          }
        }
      }
      ContentZone {
        __typename
        ... on ComponentProviderServiceContentTypesImageLink {
          __typename
          id
          ImageLinkUrl {
            __typename
            id
            Target
            DisplayText
            URLAddress
          }
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesTestimonial {
          __typename
          id
          TestimonialImage {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
          QuoteText
          QuotedName
          QuotedTitle
        }
        ... on ComponentProviderServiceContentTypesMediaFile {
          __typename
          id
          IsLink
          LinkDisplayText
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesUrl {
          __typename
          id
          DisplayText
          URLAddress
        }
        ... on ComponentProviderServiceContentTypesLongText {
          __typename
          id
          longText
        }
        ... on ComponentProviderServiceContentTypesShortText {
          __typename
          id
          shortText
        }
        ... on ComponentProviderServiceContentTypesValueProp {
          __typename
          id
          valuePropIcon {
            id
            alternativeText
            caption
            ext
            height
            mime
            name
            previewUrl
            url
            width
          }
          valuePropTitle
          valuePropText
        }
        ... on ComponentProviderServiceContentTypesTitleDescription {
          __typename
          id
          Description
          Title
          TitleDescriptionUrl {
            id
            Target
            DisplayText
            URLAddress
          }
        }
        ... on ComponentProviderServiceContentTypesSectionHeading {
          __typename
          id
          HeadingText
        }
        ... on ComponentProviderServiceContentTypesInternalLink {
          __typename
          id
          linkText
          isDetails
        }
      }
    }
    providerLanderFormOptions {
      __typename
      id
      OptionText
      OptionValue
    }
  }
`;
