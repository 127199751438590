// Copyright 2021 Prescryptive Health, Inc.

import { Box, DialogTitle, TextField, styled, Typography } from '@mui/material';

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(6),
  '& > *:not(:last-child)': { marginBottom: theme.spacing(6) },
}));

export const StyledTextField = styled(TextField)(() => ({
  display: 'flex',
}));

export const StyledBoxForTextFields = styled(Box)(() => ({
  display: 'flex',
}));

export const StyledUserNameTextField = styled(StyledTextField)(({ theme }) => ({
  marginBottom: theme.spacing(6),
}));

export const StyledCopyTextsContainerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(6),
  '& > *:not(:last-child)': { marginBottom: theme.spacing(6) },
}));

export const StyledCopyTextBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.palette.grey.A100,
  justifyContent: 'space-between',
}));

export const StyledCopyText = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));
