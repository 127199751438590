// Copyright 2021 Prescryptive Health, Inc.
import { ReactElement, useState, useEffect, lazy } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CssBaseline from '@mui/material/CssBaseline';
import { usePostAuthContext } from '../../../providers/post-auth/use-post-auth-context.hook';
import { useProviderContext } from '../../../providers/provider/use-provider-context.hook';
import { useLocationContext } from '../../../providers/location/use-location-context.hook';
import { useAppointmentsContext } from '../../../providers/appointments/use-appointments-context.hook';
import { appointmentsClearDispatch } from '../../../state/appointments/dispatch/appointments-clear.dispatch';
import { locationClearDispatch } from '../../../state/location/dispatch/location-clear.dispatch';
import { SecondaryHeader } from '../../../components/secondary-header/secondary-header';
import { AppointmentsScreen } from '../../../components/screens/appointments/appointments.screen';
import { BlockedTimeDetailsScreen } from '../../../components/screens/blocked-time-details/blocked-time-details.screen';
import { AppointmentDetailsScreen } from '../../../components/screens/appointment-details/appointment-details.screen';
import { AddServiceScreen } from '../../../components/screens/add-service/add-service.screen';
import { ServiceDetailsScreenContainer } from '../../../components/screens/add-service/service-details/screen-container/service-details.screen-container';
import { SettingsScreen } from '../../../components/screens/settings/settings.screen';
import { ServiceHoursScreen } from '../../../components/screens/service-hours/service-hours.screen';
import { ServicesScreen } from '../../../components/screens/services/services.screen';
import { ReportsScreen } from '../../../components/screens/reports/reports.screen';
import { WaitlistScreen } from '../../../components/screens/waitlist/waitlist.screen';
import { ProgramDetailsScreenContainer } from '../../../components/screens/add-service/program-details/screen-container/program-details.screen-container';
import { NotFoundScreen } from '../../../components/screens/not-found-page/not-found-page.screen';
import PostAuthDrawer from './drawer-v2/post-auth-drawer';
import { AppointmentsContextProvider } from '../../../providers/appointments/appointments.context-provider';
import { topBarHeight } from './styles';
import { authenticatedRouteConfig } from '../../authenticated-route-config';
import { Box, Typography } from '@mui/material';
import { Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { callAfterIdle } from '../../../helpers/callAfterIdle/callAfterIdle';
import { Main } from './post-auth.drawer-navigator.styled-components';
import { useSessionContext } from '../../../providers/session/use-session-context.hook';
import { PaymentsAndBillingScreen } from '../../../components/screens/pricing-and-billing/payments-and-billing.screen';
import { TermsOfUseModal } from '../../../components/modals/terms-of-use/terms-of-use.modal';
import { useAuth0 } from '@auth0/auth0-react';
import { RequireAcceptTermsOfUse } from '../../require-accept-terms-of-use';
import { Banner } from '../../../components/banner/banner';
import { LearnMoreScreen } from '../../../components/screens/learn-more/learn-more.screen';
import { LoginWithAuth0Modal } from '../../../components/modals/login-with-auth0/login-with-auth0.modal';
import { Auth0IdentityCreationContainer } from '../../../components/modals/auth0-identity-creation-modals/auth0-identity-creation.container';
import { localStorageKeys } from '../../../constants/local-storage-keys';
import { PricingScreen } from '../../../components/screens/pricing/pricing.screen';
import { DrugSearchScreen } from '../../../components/screens/drug-search/drug-search.screen';
import { DrugSearchConfigContextProvider } from '../../../providers/drug-search-config/drug-search-config.context-provider';
import { PricingReportsDownloadScreen } from '../../../components/screens/reports/pricing-report-download/pricing-reports-download.screen';

const OrderQScreenContainerLazy = lazy(
  () => import('../../../components/screens/orderq/orderq.screen-container')
);

const OrderProcessingScreenContainterLazy = lazy(
  () => import('../../../components/screens/order-processing/order-processing.screen-container')
);

export const PostAuthDrawerNavigator = (): ReactElement => {
  const { pathname } = useLocation();
  const { user } = useAuth0();
  const navigate = useNavigate();
  const {
    authState: { userAccess },
  } = usePostAuthContext();

  const {
    providerState: { currentProvider },
  } = useProviderContext();

  const {
    sessionState: { authProvider },
  } = useSessionContext();

  const {
    auth0Migration,
    auth0MigrationBanner,
    autoSignOut,
    pharmacySettingsPageWave1,
    swordfishV1,
    downloadPricingReport,
    clinicalServicesDisabled,
  } = useFlags();

  const isBannerOpen = auth0MigrationBanner && isAADUser(user?.sub, authProvider?.isLoggedIn());

  const currentProviderRules = userAccess?.rules.find(
    (rule) => rule.name === currentProvider?.name
  );

  const canAccessAdminScreens = currentProviderRules && currentProviderRules.role === 'Admin';

  const canAccessUserScreens =
    currentProviderRules &&
    (currentProviderRules.role === 'User' || currentProviderRules.role === 'Admin');

  const [isTermsOfUseDialogOpen, setIsTermsOfUseModalOpen] = useState<boolean>(true);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(
    auth0Migration
      ? user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}/userMetadata`]?.pharmacyPortalService
          ?.termsOfUseAccepted ||
          JSON.parse(localStorage.getItem(user?.sub as string) as string) ||
          false
      : true
  );

  const handleCloseTermsOfUseDialog = () => setIsTermsOfUseModalOpen(false);

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const closeDrawer = () => {
    setMobileOpen(false);
  };

  const { locationDispatch } = useLocationContext();
  const { appointmentsDispatch } = useAppointmentsContext();

  useEffect(() => {
    appointmentsClearDispatch(appointmentsDispatch);
    locationClearDispatch(locationDispatch);
  }, [currentProvider?.name]);

  const currentAccount = authProvider?.getAccount();
  useEffect(() => {
    if (user?.email) {
      localStorage.setItem(localStorageKeys.loginHint, user.email);
      return;
    }

    if (currentAccount) {
      localStorage.setItem(localStorageKeys.loginHint, currentAccount.userName);
    }
  }, [user?.email, currentAccount]);

  useEffect(() => {
    if (termsAccepted) {
      setIsTermsOfUseModalOpen(false);
    }

    if (
      user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}/userMetadata`]?.pharmacyPortalService
        ?.termsOfUseAccepted
    ) {
      localStorage.removeItem(user?.sub as string);
    }
  }, [termsAccepted]);

  useEffect(() => {
    if (!authProvider?.logout) return;
    if (autoSignOut) {
      const idleTime = process.env.REACT_APP_IDLE_LOGOUT_TIME as unknown as number;
      callAfterIdle(idleTime, authProvider.logout);
    }
  }, []);

  const routesWithoutDrawerRegex = new RegExp('(/appointments/.*)|(/blocked-times/.*)');

  const isCurrentPageOnRouteWithoutDrawer = routesWithoutDrawerRegex.test(pathname);
  const mainOverrideStyle = isCurrentPageOnRouteWithoutDrawer
    ? {
        marginLeft: 0,
        maxWidth: '100%',
        height: `calc(100vh - ${topBarHeight}px)`,
      }
    : {};

  const onBackButtonPress = () => {
    void navigate(authenticatedRouteConfig.appointments.createUrl());
  };

  return (
    <Box display='flex' flexDirection='column' width='1'>
      <CssBaseline />
      {isCurrentPageOnRouteWithoutDrawer && (
        <SecondaryHeader onBackButtonPress={onBackButtonPress} />
      )}
      {!isCurrentPageOnRouteWithoutDrawer && (
        <PostAuthDrawer
          isMobileOpen={mobileOpen}
          onHandleDrawerToggle={handleDrawerToggle}
          closeDrawer={closeDrawer}
          canAccessAdminScreens={!!canAccessAdminScreens}
          canAccessUserScreens={!!canAccessUserScreens}
        />
      )}
      <Main style={mainOverrideStyle}>
        {isBannerOpen && currentProvider && <Banner />}
        {!currentProvider ? (
          <Box display='flex' justifyContent='center' alignItems='center' height='inherit'>
            {/* useTranslation hook breaks the router for some reason and serves a blank screen on refresh. We will use a hard coded string for now */}
            <Typography>{'Please select a provider'}</Typography>
          </Box>
        ) : (
          <AppointmentsContextProvider>
            {/* TODO: Make a loading component */}
            <Routes>
              <Route
                path={authenticatedRouteConfig.root.route}
                element={
                  <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                    <Navigate
                      to={
                        clinicalServicesDisabled
                          ? authenticatedRouteConfig.reports.route
                          : authenticatedRouteConfig.appointments.route
                      }
                    />
                  </RequireAcceptTermsOfUse>
                }
              />
              <Route
                path={authenticatedRouteConfig.signin.route}
                element={
                  <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                    <Navigate to={authenticatedRouteConfig.root.route} />
                  </RequireAcceptTermsOfUse>
                }
              />
              <Route
                path={authenticatedRouteConfig.appointments.route}
                element={
                  <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                    <AppointmentsScreen />
                  </RequireAcceptTermsOfUse>
                }
              />
              <Route
                path={authenticatedRouteConfig.appointmentDetails.route}
                element={
                  <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                    <AppointmentDetailsScreen />
                  </RequireAcceptTermsOfUse>
                }
              />
              <Route
                path={authenticatedRouteConfig.blockedTimeDetails.route}
                element={
                  <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                    <BlockedTimeDetailsScreen />
                  </RequireAcceptTermsOfUse>
                }
              />
              <Route
                path={authenticatedRouteConfig.serviceHours.route}
                element={
                  <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                    <ServiceHoursScreen />
                  </RequireAcceptTermsOfUse>
                }
              />
              <Route
                path={authenticatedRouteConfig.learnMore.route}
                element={
                  <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                    <LearnMoreScreen />
                  </RequireAcceptTermsOfUse>
                }
              />
              <Route
                path={authenticatedRouteConfig.drugSearch.route}
                element={
                  <DrugSearchConfigContextProvider>
                    <DrugSearchScreen />
                  </DrugSearchConfigContextProvider>
                }
              />
              {canAccessAdminScreens && (
                <Route
                  path={authenticatedRouteConfig.myServices.route}
                  element={
                    <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                      <ServicesScreen />
                    </RequireAcceptTermsOfUse>
                  }
                />
              )}
              {canAccessAdminScreens && (
                <Route
                  path={authenticatedRouteConfig.reports.route}
                  element={
                    <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                      <ReportsScreen />
                    </RequireAcceptTermsOfUse>
                  }
                />
              )}
              {canAccessAdminScreens && (
                <Route
                  path={authenticatedRouteConfig.paymentsBilling.route}
                  element={
                    <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                      <PaymentsAndBillingScreen />
                    </RequireAcceptTermsOfUse>
                  }
                />
              )}
              {canAccessUserScreens && (
                <Route
                  path={authenticatedRouteConfig.waitlist.route}
                  element={
                    <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                      <WaitlistScreen />
                    </RequireAcceptTermsOfUse>
                  }
                />
              )}
              {canAccessAdminScreens && (
                <Route
                  path={authenticatedRouteConfig.pricing.route}
                  element={
                    <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                      <PricingScreen />
                    </RequireAcceptTermsOfUse>
                  }
                />
              )}
              {canAccessAdminScreens && downloadPricingReport && (
                <Route
                  path={authenticatedRouteConfig.downloadReports.route}
                  element={
                    <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                      <PricingReportsDownloadScreen />
                    </RequireAcceptTermsOfUse>
                  }
                />
              )}
              {canAccessAdminScreens && (
                <Route
                  path={authenticatedRouteConfig.addServices.route}
                  element={
                    <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                      <AddServiceScreen />
                    </RequireAcceptTermsOfUse>
                  }
                />
              )}
              {canAccessAdminScreens && (
                <Route
                  path={authenticatedRouteConfig.addServiceDetails.route}
                  element={
                    <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                      <ServiceDetailsScreenContainer />
                    </RequireAcceptTermsOfUse>
                  }
                />
              )}
              {canAccessAdminScreens && (
                <Route
                  path={authenticatedRouteConfig.addProgramDetails.route}
                  element={
                    <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                      <ProgramDetailsScreenContainer />
                    </RequireAcceptTermsOfUse>
                  }
                />
              )}
              {pharmacySettingsPageWave1 && canAccessAdminScreens && (
                <Route
                  path={authenticatedRouteConfig.settings.route}
                  element={
                    <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                      <SettingsScreen />
                    </RequireAcceptTermsOfUse>
                  }
                />
              )}
              {swordfishV1 && (
                <Route
                  path={authenticatedRouteConfig.swordfishOrderQ.route}
                  element={
                    <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                      <OrderQScreenContainerLazy />
                    </RequireAcceptTermsOfUse>
                  }
                />
              )}
              {swordfishV1 && (
                <Route
                  path={authenticatedRouteConfig.swordfishOrderProcessing.route}
                  element={
                    <RequireAcceptTermsOfUse areTermsAccepted={termsAccepted}>
                      <OrderProcessingScreenContainterLazy />
                    </RequireAcceptTermsOfUse>
                  }
                />
              )}
              {!isTermsOfUseDialogOpen && <Route path='/*' element={<NotFoundScreen />} />}
            </Routes>
          </AppointmentsContextProvider>
        )}
      </Main>
      {auth0Migration && <Auth0IdentityCreationContainer />}
      {auth0Migration && (
        <TermsOfUseModal
          isOpen={isTermsOfUseDialogOpen}
          handleClose={handleCloseTermsOfUseDialog}
          setTermsAccepted={setTermsAccepted}
        />
      )}
      {auth0Migration && <LoginWithAuth0Modal />}
    </Box>
  );
};

function isAADUser(sub: string | undefined, isLoggedInAAD: boolean | undefined): boolean {
  if (isLoggedInAAD) return true;
  if (sub === undefined) return false;
  return 'waad' === sub.slice(0, 4);
}
