// Copyright 2020 Prescryptive Health, Inc.

import React, { FunctionComponent } from 'react';
import { loadConfig, getMetaEnvironmentVariable } from '../../config/config.helper';
import { IConfigState, defaultConfigState } from '../../state/config/config.state';
import { ConfigContext } from './config.context';

export const ConfigContextProvider: FunctionComponent = ({ children }) => {
  const configState = stateLoad();

  return <ConfigContext.Provider value={{ configState }}>{children}</ConfigContext.Provider>;
};

const pharmacyPortalApiConfigMap = new Map<string, string>([
  ['host', 'PHARMACY_PORTAL_API_HOST_URL'],
  ['port', 'PHARMACY_PORTAL_API_PORT'],
]);

function stateLoad(): IConfigState {
  const state = { ...defaultConfigState };

  state.authentication.clientId = getMetaEnvironmentVariable('AUTHENTICATION_CLIENT_ID');
  state.authentication.tenantId = getMetaEnvironmentVariable('AUTHENTICATION_TENANT_ID');

  state.apis.pharmacyPortal.env.host = getMetaEnvironmentVariable('PHARMACY_PORTAL_API_HOST_URL');
  state.apis.pharmacyPortal.env.port = getMetaEnvironmentVariable('PHARMACY_PORTAL_API_PORT');
  loadConfig(state.apis.pharmacyPortal.env, pharmacyPortalApiConfigMap);

  state.supportEmail = getMetaEnvironmentVariable('SUPPORT_EMAIL');

  state.launchDarklyKey = getMetaEnvironmentVariable('LAUNCH_DARKLY_KEY');

  state.myrxDomain = getMetaEnvironmentVariable('MYRX_DOMAIN');

  state.strapiHostUrl = getMetaEnvironmentVariable('STRAPI_HOST_URL');

  state.newUserDomain = getMetaEnvironmentVariable('NEW_USER_DOMAIN');

  return state;
}
