// Copyright 2021 Prescryptive Health, Inc.
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { getMetaEnvironmentVariable } from './config/config.helper';

// TODO: remove apollo link rest package

const httpLink = createHttpLink({
  uri: `https://${getMetaEnvironmentVariable(
    'PHARMACY_PORTAL_API_HOST_URL'
  )}:${getMetaEnvironmentVariable('PHARMACY_PORTAL_API_PORT')}/api/provider/graphql`,
});

export default new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});
