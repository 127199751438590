// Copyright 2021 Prescryptive Health, Inc.

import React from 'react';
import { ReactElement } from 'react';
import { Box, useTheme } from '@mui/material';
import { NumberedBullet } from './direction-bullet.styled-components';

interface DirectionBulletProps {
  bulletText: string;
  children?: React.ReactChild | React.ReactChild[];
}
export const DirectionBullet = ({ bulletText, children }: DirectionBulletProps): ReactElement => {
  const theme = useTheme();

  return (
    <Box display='flex' flexDirection='row' alignItems='center' gap={theme.spacing(6)}>
      <Box
        justifyContent='center'
        alignItems='center'
        borderRadius="25px"
        borderColor={theme.palette.grey.A200}
        border={1}
      >
        <NumberedBullet variant='h3'>{bulletText}</NumberedBullet>
      </Box>
      <Box flexShrink={1}>{children}</Box>
    </Box>
  );
};
