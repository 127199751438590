// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { LabeledDetail } from '../../layout/labeled-detail/labeled-detail';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Box } from '@mui/material';

export interface INumberPickerDetailProps {
  label?: string;
  initialValue?: number;
  interval?: number;
  minimum: number;
  maximum: number;
  unitText?: string;
  onSelect: (value: number) => void;
  description?: string;
  additionalDescription?: string;
}

export const NumberPickerDetail = ({
  label,
  initialValue,
  interval = 1,
  minimum,
  maximum,
  unitText,
  onSelect,
  description,
  additionalDescription,
}: INumberPickerDetailProps): ReactElement => {
  const options = optionsMap(minimum, maximum, interval, unitText);

  const onChange = (event: SelectChangeEvent<number>) => {
    onSelect(parseInt(event.target.value as string, 10));
  };

  return (
    <Box>
      <FormControl fullWidth={true}>
        <InputLabel>{label}</InputLabel>
        <Select onChange={onChange} defaultValue={initialValue}>
          {Array.from(options.keys()).map((key: string, index: number) => {
            const value = options.get(key) ?? '';
            return (
              <MenuItem key={`${index}${value}`} value={key}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <LabeledDetail description={description} additionalDescription={additionalDescription} />
    </Box>
  );
};

const optionsMap = (
  minimum: number,
  maximum: number,
  interval: number,
  unitText?: string
): Map<string, string> => {
  const options = new Map<string, string>();

  for (let value = minimum; value <= maximum; value += interval) {
    const text = unitText ? `${value} ${unitText}` : value.toString();
    options.set(value.toString(), text);
  }

  return options;
};
