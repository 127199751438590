// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { AppBar, Button } from '@mui/material';
import { useTelemetryContext } from '../../../../providers/telemetry/use-telemetry-context.hook';
import { useProviderContext } from '../../../../providers/provider/use-provider-context.hook';
import { Toolbar } from './service-top-bar.styled-components';
import { authenticatedRouteConfig } from '../../../../navigation/authenticated-route-config';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ServiceTopBar = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { telemetryService } = useTelemetryContext();
  const {
    providerState: { currentProvider },
  } = useProviderContext();

  const onMyServicesClick = () => {
    telemetryService.trackEvent('click', {
      name: 'add-services-list-topNav-myServicesLink',
      provider: currentProvider?.name,
    });
    void navigate(authenticatedRouteConfig.myServices.createUrl());
  };

  return (
    <>
      <AppBar position='sticky' color='transparent' elevation={0}>
        <Toolbar disableGutters={true} variant='dense'>
          <Button onClick={onMyServicesClick} variant='text' size='small'>
            {t('addService.serviceTopBar.myServiceLabel')}
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
};
