// Copyright 2021 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import dateFormatter from '../../../../../formatters/dates/date.formatter';
import { LabelDetail } from '../../label-detail';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import { IAppointment } from '../../appointment-details.screen.query';
import { useTranslation } from 'react-i18next';
export interface IProcedureInfoSectionProps {
  appointment?: IAppointment;
}

export const ProcedureInfoSection = ({
  appointment,
}: IProcedureInfoSectionProps): ReactElement | null => {
  const { t } = useTranslation();

  const theme = useTheme();

  if (!appointment?.procedure.results || appointment?.procedure.results.length === 0) {
    return <></>;
  }

  return (
    <>
      <Divider className='print-section' />
      <Box
        component='section'
        display='grid'
        gap={theme.spacing(4)}
        className={appointment?.procedure?.results ? 'page-break' : ''}
      >
        <Typography variant='h2' component='h2'>
          {t('appointmentDetails.procedureInfo.sectionHeading')}
        </Typography>
        <Box display='grid' gap={theme.spacing(2)}>
          {appointment.procedure.results.map((procedureQuestionAndAnswer) => (
            <LabelDetail
              key={procedureQuestionAndAnswer.procedureResultId}
              label={procedureQuestionAndAnswer.text}
              detail={
                (procedureQuestionAndAnswer.type ?? 'text') === 'date'
                  ? dateFormatter.formatToMDY(new Date(procedureQuestionAndAnswer.answerText ?? ''))
                  : procedureQuestionAndAnswer.answerText ?? ''
              }
            />
          ))}
        </Box>
      </Box>
    </>
  );
};
