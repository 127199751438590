// Copyright 2022 Prescryptive Health, Inc.

import { Box } from '@mui/material';
import { styled as muiStyled } from '@mui/material';

export const PostAuthPrimaryScreenChildContainer = muiStyled(Box)(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 1,
  height: '100%',
  padding: theme.spacing(6),
  [theme.breakpoints.up('lg')]: {
    marginTop: 0,
    padding: theme.spacing(12),
  },
}));
