// Copyright 2021 Prescryptive Health, Inc.

import { IAvailabilityTime, ITimeRangeAndTypes } from '../../../src/model/availability-time';

const availabilityTimeRangeToString = (availabilityRange: ITimeRangeAndTypes): string => {
  const { startTime, endTime } = availabilityRange;

  return `${availabilityTimeToString(startTime)} - ${availabilityTimeToString(endTime)}`;
};

const availabilityTimeToString = (availabilityTime: IAvailabilityTime): string => {
  const { hour, minute } = availabilityTime;
  const formattedHour = hourAsString(hour);
  const formattedMinute = minuteAsString(minute);
  const isAm = hour < 12;

  return `${formattedHour}:${formattedMinute} ${isAm ? 'AM' : 'PM'}`;
};

const hourAsString = (hour: number): string => {
  if (hour < 1) {
    return '12';
  }

  return `${hour > 12 ? hour - 12 : hour}`;
};

const minuteAsString = (minute: number): string => (minute < 10 ? `0${minute}` : minute.toString());

export { availabilityTimeRangeToString, availabilityTimeToString };
