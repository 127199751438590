// Copyright 2021 Prescryptive Health, Inc.

import React, { useEffect } from 'react';
import { ReactElement } from 'react';
import { PostAuthPrimaryScreenContainer } from '../../screen-containers/post-auth-primary/post-auth-primary.screen-container';
import { useLocationContext } from '../../../providers/location/use-location-context.hook';
import { EmptyViewMessage } from '../../text/messages/empty-view.message';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { DateTime } from 'luxon';
import { ReportsSummaryTable } from './table/summary/reports-summary-table';
import { useProviderContext } from '../../../providers/provider/use-provider-context.hook';
import { ReportsRejectionTable } from './table/rejections/reports-rejection-table';
import { AllAppointmentsDownloadButton } from './all-appointments-download-button/all-appointments-download-button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import { authenticatedRouteConfig } from '../../../navigation/authenticated-route-config';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { PricingReportButtons } from './reports.screen.styled-components';
import { PricingReportsDownloadButton } from './pricing-reports-download-button/pricing-reports-download-button';

type ReportType = 'SUMMARY' | 'REJECTIONS' | 'ALL_APPOINTMENTS';

export type ReportPagination = {
  pages: ReportPage[];
  totalCount?: number;
};
type ReportPage = {
  pageNumber: number;
  nextCursor?: string;
};

const defaultPaginationState: ReportPagination = {
  pages: [],
  totalCount: undefined,
};
export const ReportsScreen = (): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showRejectReport, downloadPricingReport } = useFlags();

  const {
    locationState: { location },
  } = useLocationContext();
  const {
    providerState: { enrolledPrograms },
  } = useProviderContext();

  const isEnrolledInMyRxPricing =
    enrolledPrograms.find(
      (e) =>
        e.enrollmentStatus === 'active' &&
        (e.programIdentifier === 'program-myrx-pricing' ||
          e.programIdentifier === 'program-myrx-ai-pricing')
    ) !== undefined;
  const [reportType, setReportType] = React.useState<ReportType>(
    isEnrolledInMyRxPricing ? 'SUMMARY' : 'ALL_APPOINTMENTS'
  );

  useEffect(() => {
    if (isEnrolledInMyRxPricing && reportType !== 'SUMMARY') {
      setReportType('SUMMARY');
    }
  }, [isEnrolledInMyRxPricing]);

  const [paginationState, setPaginationState] =
    React.useState<ReportPagination>(defaultPaginationState);
  const [dateRange, setDateRange] = React.useState<DateRange<DateTime>>([
    DateTime.now().minus({ days: 7 }),
    DateTime.now(),
  ]);

  document.title = t('reports.reportsScreen.pageTitle');

  const handleChange = (event: SelectChangeEvent) => {
    setReportType(event.target.value as ReportType);
    setPaginationState(defaultPaginationState);
  };
  const navigateToPricingPage = () => {
    void navigate(authenticatedRouteConfig.pricing.createUrl());
  };

  const navigateToDownloadPricingReportsPage = () => {
    void navigate(authenticatedRouteConfig.downloadReports.createUrl());
  };

  const onPaginationStateChange = (
    currentPage: number,
    nextCursor?: string,
    totalCount?: number
  ) => {
    const newPaginationState = { ...paginationState };
    newPaginationState.pages = [...paginationState.pages];

    const page = { pageNumber: currentPage, nextCursor };
    newPaginationState.pages[currentPage] = page;
    if (currentPage === 0) {
      newPaginationState.totalCount = totalCount;
    }
    setPaginationState(newPaginationState);
  };

  const renderReportTables = () => {
    if (reportType === 'ALL_APPOINTMENTS') {
      return (
        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          height='calc(85vh - 100px)'
          justifyContent='center'
          alignItems='center'
          gap={6}
          data-testid='reports-all-appointments-text'>
          <Box display='inherit' flexDirection='inherit' alignItems='center'>
            <Typography fontWeight='bold' color={theme.palette.primary.main}>
              {t('reports.reportsScreen.downloadReport')}
            </Typography>
            <Typography>
              {t('reports.reportsScreen.csvText')}{' '}
              <Link href='https://prescryptive.jotform.com/230675102935858' target='_blank'>
                {t('reports.reportsScreen.linkText')}
              </Link>
              {'.'}
            </Typography>
          </Box>
        </Box>
      );
    }

    if (!isEnrolledInMyRxPricing) {
      return (
        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          height='calc(85vh - 100px)'
          justifyContent='center'
          alignItems='center'
          gap={6}
          data-testid='reports-enrollment-text'>
          <Avatar
            src={process.env.PUBLIC_URL + '/media/icons/sign-up-to-myrx-pricing.svg'}
            variant='square'
            alt='myrxio logo'
            style={{ width: '160px', height: 'auto' }}
          />
          <Box display='inherit' flexDirection='inherit' alignItems='center'>
            <Typography fontWeight='bold' color={theme.palette.purpleScale[300]}>
              {t('reports.reportsScreen.prescryptiveEnrollText')}
            </Typography>
            <Typography>{t('reports.reportsScreen.insightsText')}</Typography>
          </Box>
          <Button
            data-testid='button-navigate-pricing'
            startIcon={<ExitToAppIcon />}
            onClick={navigateToPricingPage}>
            {t('reports.reportsScreen.goToPricing')}
          </Button>
        </Box>
      );
    }

    if (reportType === 'SUMMARY' || reportType === 'REJECTIONS') {
      const startDate = dateRange[0];
      const endDate = dateRange[1];

      if (location?.id && startDate && endDate) {
        return reportType === 'SUMMARY' ? (
          <ReportsSummaryTable
            locationId={location.id}
            startDate={startDate}
            endDate={endDate}
            paginationState={paginationState}
            onPaginationStateChange={onPaginationStateChange}
          />
        ) : (
          <ReportsRejectionTable
            locationId={location.id}
            startDate={startDate}
            endDate={endDate}
            paginationState={paginationState}
            onPaginationStateChange={onPaginationStateChange}
          />
        );
      }
    }

    return null;
  };

  const renderAppointmentDownloadButton =
    reportType === 'ALL_APPOINTMENTS' ? (
      <Box display='flex' flexDirection='row' justifyContent='flex-end' flex={1}>
        <AllAppointmentsDownloadButton
          isDisabled={false}
          startDate={dateRange[0]?.toJSDate()}
          endDate={dateRange[1]?.toJSDate()}
        />
      </Box>
    ) : null;

  const renderAvailablePricingReportsButton =
    downloadPricingReport && reportType !== 'ALL_APPOINTMENTS' ? (
      <Box display='flex' flexDirection='row' justifyContent='flex-end' flex={1}>
        <Tooltip
          title={t('reports.reportsScreen.goToDownloadPricingReportsTooltip') as string}
          placement='right-end'>
          <PricingReportButtons
            data-testid='button-navigate-available-reports'
            onClick={navigateToDownloadPricingReportsPage}>
            {t('reports.reportsScreen.goToDownloadPricingReports')}
          </PricingReportButtons>
        </Tooltip>
      </Box>
    ) : null;

  const renderPricingReportDownloadButton =
    downloadPricingReport &&
    reportType !== 'ALL_APPOINTMENTS' &&
    location !== undefined &&
    dateRange[0] != null &&
    dateRange[1] != null ? (
      <PricingReportsDownloadButton
        reportType={reportType === 'SUMMARY' ? 'summary' : 'rejection'}
        locationId={location.id}
        startDate={dateRange[0]}
        endDate={dateRange[1]}
      />
    ) : null;

  return location ? (
    <PostAuthPrimaryScreenContainer>
      <Typography variant='h1'>{t('reports.reportsScreen.title')}</Typography>
      <Box display='flex' flexDirection='row' width='100%' marginTop={theme.spacing(4)}>
        <Box display='flex' flex={1.5} gap={6}>
          <Box flex={1}>
            <FormControl variant='outlined' fullWidth>
              <InputLabel id='report-select'>{t('reports.reportsScreen.selectAReport')}</InputLabel>
              <Select
                labelId='report-select-label'
                id='report-select'
                value={reportType}
                label={t('reports.reportsScreen.selectAReport')}
                onChange={handleChange}>
                <MenuItem value={'SUMMARY'} data-testid='report-select-summary-option'>
                  {t('reports.reportsScreen.pricingSummary')}
                </MenuItem>
                {showRejectReport ? (
                  <MenuItem value={'REJECTIONS'} data-testid='report-select-rejection-option'>
                    {t('reports.reportsScreen.pricingRejections')}
                  </MenuItem>
                ) : null}
                <MenuItem
                  value={'ALL_APPOINTMENTS'}
                  data-testid='report-select-all-appointments-option'>
                  {t('reports.reportsScreen.pricingAllAppointments')}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box flex={1.5}>
            <LocalizationProvider
              dateAdapter={AdapterLuxon}
              localeText={{ start: 'Start date', end: 'End date' }}>
              <DateRangePicker
                value={dateRange}
                onChange={(newValue) => {
                  setDateRange(newValue);
                  setPaginationState(defaultPaginationState);
                }}
                disableMaskedInput={true}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} />
                    <Box mx={2}> {t('reports.reportsScreen.to')}</Box>
                    <TextField {...endProps} />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        {renderAppointmentDownloadButton}
        {renderPricingReportDownloadButton}
        {renderAvailablePricingReportsButton}
      </Box>
      {renderReportTables()}
    </PostAuthPrimaryScreenContainer>
  ) : (
    <EmptyViewMessage />
  );
};
