// Copyright 2021 Prescryptive Health, Inc.

import { Theme, Chip, AccordionSummary as MuiAccordionSummary } from '@mui/material';
import { styled as muiStyled } from '@mui/material';
import styled from 'styled-components';

export const PendingChip = muiStyled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.blueScale['100'],
  color: 'white',
}));

export const AccordionSummary = styled(MuiAccordionSummary)`
  background-color: ${({ theme }: { theme: Theme }) => theme.palette.grey.A100};
`;
