// Copyright 2021 Prescryptive Health, Inc.

import { Box, Link, useTheme } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import React, { ReactElement } from 'react';
import { UploadFile } from '../../../model/strapi/strapi-models';
import { buildResourceUrl } from '../helpers/build-resource-url';
import { StyledMediaFileTypography } from './media-file.styled.components';
import ENV from '../../../helpers/environment-variables';

interface IMediaFileProps {
  isLink: boolean | null | undefined;
  linkDisplayText: string | null | undefined;
  imageFile: UploadFile | null | undefined;
  onClick?: () => void;
}

export const MediaFile = ({
  isLink,
  linkDisplayText,
  imageFile,
  onClick,
}: IMediaFileProps): ReactElement => {
  const theme = useTheme();

  const renderedElements = isLink ? (
    <StyledMediaFileTypography>
      <GetAppIcon
        style={{
          fontSize: theme.spacing(6),
          color: theme.palette.primary.main,
        }}
      />
      <Link
        href={buildResourceUrl(ENV.STRAPI_HOST_URL, imageFile?.url ?? '')}
        target={'_blank'}
        download={linkDisplayText}
        onClick={onClick}
        style={{ paddingLeft: theme.spacing(2) }}
        underline="hover">
        {linkDisplayText}
      </Link>
    </StyledMediaFileTypography>
  ) : (
    <img
      src={buildResourceUrl(ENV.STRAPI_HOST_URL, imageFile?.url ?? '')}
      style={{ width: '100%', height: 'auto' }}
    />
  );

  return <Box>{renderedElements}</Box>;
};
