// Copyright 2020 Prescryptive Health, Inc.

import { useScreenContext, IScreenContext } from '../use-screen-context.hook';
import { IContentMap } from '../../../providers/locale/get-content';
import { usePostAuthContext } from '../../../providers/post-auth/use-post-auth-context.hook';
import { IPostAuthContext } from '../../../providers/post-auth/post-auth.context';

export type IPostAuthScreenContext<TContent> = IScreenContext<TContent> & IPostAuthContext;

export function usePostAuthScreenContext<TContent>(
  contentMap: IContentMap<TContent>
): IPostAuthScreenContext<TContent> {
  const screenContext = useScreenContext<TContent>(contentMap);

  const postAuthContext = usePostAuthContext();

  return {
    ...screenContext,
    ...postAuthContext,
  };
}
