import { IPharmacyByNpi } from '../../../model/pharmacy-by-coordinates';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { TelemetryEvent } from '../../../providers/telemetry/telemetry.context';
import { TelemetryService } from '../../../providers/telemetry/telemetry.service';
import { IDrugSearchApiConfig } from '../../../state/drug-search/drug-search.state';
import { call } from '../../call';
import { logApiError } from '../../errors/log-api-error';
import { buildDrugSearchUrl } from '../api.helper';
import { DrugSearchApiErrorCode } from '../errors/drug-search-api-error-code';
import { DrugSearchApiErrors } from '../errors/drug-search-api-errors';
import { IPharmacyByNpiResponse } from './get-pharmacy-by-npi-response';

export async function getPharmacyByNpi(
  apiConfig: IDrugSearchApiConfig,
  subscriptionKey: string,
  npi: string,
  telemetryService: TelemetryService<TelemetryEvent>
): Promise<IPharmacyByNpi> {
  const url = buildDrugSearchUrl(apiConfig, 'npiSearch', {
    ':npi': npi,
  });

  const response: Response = await call(url, undefined, 'GET', {
    ['Ocp-Apim-Subscription-Key']: subscriptionKey,
  });

  if (response.ok) {
    return await getPharmacyByNpiFromResponse(response);
  }

  logApiError(
    telemetryService,
    new PharmacyPortalError(
      DrugSearchApiErrors.getPharmacyByNpi(npi),
      DrugSearchApiErrorCode.GET_PHARMACY_BY_NPI,
      PharmacyPortalErrorType.API_ERROR,
      response.status
    )
  );
  return { error: true } as IPharmacyByNpi;
}

async function getPharmacyByNpiFromResponse(response: Response): Promise<IPharmacyByNpi> {
  const responseData = (await response.json()) as IPharmacyByNpiResponse;

  if (responseData.ncpdp) {
    const pharmacyResult: IPharmacyByNpi = {
      address: {
        line1: responseData.address.lineOne,
        line2: responseData.address.lineTwo,
        city: responseData.address.city,
        state: responseData.address.state,
        zip: responseData.address.zip,
      },
      brand: responseData.brand,
      ncpdp: responseData.ncpdp,
      phone: responseData.phone,
      distance: responseData.distanceFromSearchPointInMiles,
      error: false,
    };
    return pharmacyResult;
  }

  return { error: true } as IPharmacyByNpi;
}
