// Copyright 2021 Prescryptive Health, Inc.

import { Box, Button, Link, Typography, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
export interface IErrorViewProps {
  onRetry?: () => void;
  retryText?: string;
}

export const ErrorView = ({ onRetry, retryText = 'Retry' }: IErrorViewProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      height={1}
      padding={theme.spacing(8)}
    >
      <Box padding={theme.spacing(3)}>
        <Typography variant='h4' component='div' color='primary' align='center'>
          {t('errorView.errorView.title')}
        </Typography>
      </Box>
      <Box p={theme.spacing(3)}>
        <Typography component='div' color='primary' align='center'>
          {t('errorView.errorView.actionText')}
        </Typography>
      </Box>
      <Box p={theme.spacing(3)}>
        <Typography component='div' color='primary' align='center'>
          {t('errorView.errorView.helpText')}
          <Link
            component='a'
            href={`mailto:${t('errorView.errorView.helpEmail')}`}
            underline='always'
          >
            {t('errorView.errorView.helpEmail')}
          </Link>
        </Typography>
      </Box>
      {onRetry && (
        <Box padding={theme.spacing(4)}>
          <Button onClick={onRetry} color='primary' variant='outlined'>
            {retryText}
          </Button>
        </Box>
      )}
    </Box>
  );
};
