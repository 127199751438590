// Copyright 2021 Prescryptive Health, Inc.

import { Toolbar } from '@mui/material';
import { styled as muiStyled } from '@mui/material';

export const StyledBlockedTimeDetailsToolBar = muiStyled(Toolbar)(({ theme }) => ({
  display: 'inline-table',
  padding: `${theme.spacing(3)} ${theme.spacing(10)}`,
  background: theme.palette.grey.A100,
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('tablet')]: { display: 'flex' },
}));
