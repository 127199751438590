// Copyright 2021 Prescryptive Health, Inc.

import { Maybe } from 'graphql/jsutils/Maybe';
import React, { ReactElement } from 'react';
import { EnrollmentStatus } from '../../../../../model/enrolled-service-type';
import {
  ProviderProgramRightRailDynamicZone,
  ProviderServiceRightRailDynamicZone,
} from '../../../../../model/strapi/strapi-models';
import { buildResourceUrl } from '../../../../strapi/helpers/build-resource-url';
import {
  ActionButton,
  EnrollBox,
  EnrollContentBox,
  EnrollmentContainer,
  EnrollmentTitleTypography,
  PendingButton,
} from '../../details-screens-sections/styled-components/enrollment-bar/enrollment-bar.styled-components';
import { Hidden, Typography } from '@mui/material';
import {
  ProgramEnrollmentBarContentContainer,
  ProgramEnrollmentButtonContainer,
  ProgramEnrollmentShortTextTypography,
} from './program-enrollment-bar.styled-components';
import ENV from '../../../../../helpers/environment-variables';
import { getTierSupportLevel } from '../../../../../helpers/tier-support-level-helper/tier-support-level-helper';
import { useTranslation } from 'react-i18next';

export interface IProgramEnrollmentBarProps {
  title: string;
  onEnroll: () => void;
  onActivate: () => void;
  onDownload: () => void;
  enrollmentStatus: EnrollmentStatus | undefined;
  rightRailData:
    | Maybe<ProviderServiceRightRailDynamicZone | ProviderProgramRightRailDynamicZone>[]
    | undefined
    | null;
  enrollWidget: ReactElement | null;
}

export const ProgramEnrollmentBar = ({
  title,
  onEnroll,
  onActivate,
  onDownload,
  enrollmentStatus,
  rightRailData,
  enrollWidget,
}: IProgramEnrollmentBarProps): ReactElement => {
  const { t } = useTranslation();
  const tierLevel = getTierSupportLevel();

  const canUserActivateProgram =
    enrollmentStatus === 'pending' && tierLevel !== null && tierLevel >= 2;
  const isDisabled = enrollmentStatus === 'pending';
  const buttonLink = rightRailData?.find(
    (elem) => elem?.__typename === 'ComponentProviderServiceContentTypesMediaFile'
  );

  const shortTextContentType = rightRailData?.find(
    (elem) => elem?.__typename === 'ComponentProviderServiceContentTypesShortText'
  );

  const shortText =
    shortTextContentType?.__typename === 'ComponentProviderServiceContentTypesShortText'
      ? shortTextContentType?.shortText
      : '';

  const url =
    buttonLink?.__typename === 'ComponentProviderServiceContentTypesMediaFile'
      ? buttonLink?.ImageFile?.url
      : '';

  const onDownloadInstructions = () => {
    onDownload();
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: buildResourceUrl(ENV.STRAPI_HOST_URL, url ?? ''),
    }).click();
  };

  const shortTextDisplay = enrollmentStatus === 'active' ? 'inherit' : 'none';

  const enrollmentButton = () => {
    if (enrollmentStatus === 'active') {
      return (
        <ActionButton
          onClick={onDownloadInstructions}
          disabled={isDisabled}
          variant='contained'
          color='primary'
        >
          {t('addService.programEnrollmentBar.downloadInstructionsButtonText')}
        </ActionButton>
      );
    } else if (canUserActivateProgram) {
      return (
        <ActionButton onClick={onActivate} variant='contained' color='primary'>
          {t('addService.programEnrollmentBar.activateButtonText')}
        </ActionButton>
      );
    } else if (enrollmentStatus === 'pending') {
      return (
        <PendingButton onClick={onEnroll} disabled={isDisabled}>
          {t('addService.programEnrollmentBar.pendingEnrollmentText')}
        </PendingButton>
      );
    } else {
      return (
        <ActionButton onClick={onEnroll} disabled={isDisabled} variant='contained' color='primary'>
          {t('addService.programEnrollmentBar.enrollNowButtonText')}
        </ActionButton>
      );
    }
  };

  return (
    <EnrollmentContainer>
      <EnrollBox>
        <EnrollContentBox>
          <ProgramEnrollmentBarContentContainer>
            <EnrollmentTitleTypography>{title}</EnrollmentTitleTypography>
            <Typography variant='body1' style={{ display: shortTextDisplay }}>
              {shortText}
            </Typography>
          </ProgramEnrollmentBarContentContainer>
          <ProgramEnrollmentButtonContainer>
            {enrollmentButton()}
            <Hidden xlDown={true}>
              <ProgramEnrollmentShortTextTypography
                variant='body1'
                style={{ display: shortTextDisplay }}
              >
                {shortText}
              </ProgramEnrollmentShortTextTypography>
            </Hidden>
          </ProgramEnrollmentButtonContainer>
        </EnrollContentBox>
      </EnrollBox>
      {enrollWidget}
    </EnrollmentContainer>
  );
};
