// Copyright 2021 Prescryptive Health, Inc.

import { HttpStatusCodesEnum } from '../../http-status-codes';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { call } from '../../call';
import { ApiErrors } from '../../errors/api-errors';
import DateFormatter from '../../../formatters/dates/date.formatter';
import { IBlockedTimeEventResponse, IScheduleGetResponse } from './schedule-get.response';
import { IAppointmentGetResponse } from '../appointment-get/appointment-get.response';
import { ICalendarAppointmentItem } from '../../../model/calendar-appointment-item';
import { ICalendarBlockedTimeItem } from '../../../model/calendar-blocked-time-item';
import {
  responseClaimInformationMap,
  responseProcedureResultsQuestionAndAnswerMap,
} from '../appointment-get/appointment-get.response-helper';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export interface ICalendarScheduleGetResult {
  appointments: ICalendarAppointmentItem[];
  blockedSlots: ICalendarBlockedTimeItem[];
}

export async function scheduleGet(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  locationId: string,
  date: Date,
  serviceType = '',
  bookingStatus = ''
): Promise<ICalendarScheduleGetResult> {
  const formattedDate = DateFormatter.formatToYMD(date);

  const url = buildUrl(apiConfig, 'schedule', {
    ':locationId': locationId,
    ':date': formattedDate,
    ':serviceType': serviceType,
    ':bookingStatus': bookingStatus,
  });

  const headers = buildBearerAuthHeaders(authToken);

  const response: Response = await call(url, undefined, 'GET', headers);
  if (response.ok) {
    const responseData = (await response.json()) as IScheduleGetResponse;
    if (!responseData) {
      throw new PharmacyPortalError(
        ApiErrors.scheduleGet(ApiErrors.unexpectedResponseDataFormat),
        ApiErrorCode.SCHEDULE_GET,
        PharmacyPortalErrorType.API_ERROR,
        response.status
      );
    }

    return calendarScheduleGetResult(responseData);
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.SCHEDULE_GET,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  throw new PharmacyPortalError(
    ApiErrors.appointmentsGet(response.statusText),
    ApiErrorCode.SCHEDULE_GET,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}

const calendarScheduleGetResult = (
  responseData: IScheduleGetResponse
): ICalendarScheduleGetResult => {
  const appointments = responseAppointmentsMap(responseData.appointments);
  const blockedSlots = responseBlockedTimeSlotsMap(responseData.blockedSlots);

  return { appointments, blockedSlots };
};

function responseAppointmentsMap(
  responseAppointments: Partial<IAppointmentGetResponse>[]
): ICalendarAppointmentItem[] {
  return responseAppointments.map((eventItem) => {
    const appointment: ICalendarAppointmentItem = {
      appointmentId: eventItem.appointmentId ?? '',
      date: eventItem.scheduledStart ? new Date(eventItem.scheduledStart) : new Date(0),
      durationMinutes: eventItem.duration ?? 0,
      firstName: eventItem.firstName ?? '',
      lastName: eventItem.lastName ?? '',
      orderNumber: eventItem.orderNumber ?? '',
      serviceType: eventItem.serviceType ?? '',
      claimInformation: responseClaimInformationMap(eventItem),
      procedureResults: responseProcedureResultsQuestionAndAnswerMap(eventItem.procedureResults),
    };
    return appointment;
  });
}

function responseBlockedTimeSlotsMap(
  responseBlockedTimes: Partial<IBlockedTimeEventResponse>[]
): ICalendarBlockedTimeItem[] {
  return responseBlockedTimes.map((responseBlockedTime) => {
    const blockedTime: ICalendarBlockedTimeItem = {
      date: responseBlockedTime.scheduledStart
        ? new Date(responseBlockedTime.scheduledStart)
        : new Date(0),
      durationMinutes: responseBlockedTime.duration ?? 0,
      blockedTimeId: responseBlockedTime.blockedTimeId ?? '',
      reason: responseBlockedTime.reason ?? '',
    };
    return blockedTime;
  });
}
