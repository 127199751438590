// Copyright 2021 Prescryptive Health, Inc.

import { createContext } from "react";
import { LocationDispatch } from "../../state/location/dispatch/location.dispatch";
import { defaultLocationState, ILocationState } from "../../state/location/location.state";

export interface ILocationContext {
  readonly locationState: ILocationState;
  readonly locationDispatch: LocationDispatch;
}

export const LocationContext = createContext<ILocationContext>({
  locationState: defaultLocationState,
  locationDispatch: () => {
    return;
  },
});