// Copyright 2020 Prescryptive Health, Inc.

import { ApiErrors } from './api-errors';

export class AuthenticationProviderUndefinedError extends Error {
  constructor() {
    super();
    Object.setPrototypeOf(this, AuthenticationProviderUndefinedError.prototype);
    this.message = ApiErrors.authenticationProviderUndefined;
  }
}
