// Copyright 2020 Prescryptive Health, Inc.

import React, { ReactElement, FunctionComponent } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, useTheme } from '@mui/material';
import { StyledPortalTopBarContainer } from './portal-top-bar.styled-components';
import { PrescryptiveProLogo } from '../prescryptivepro-logo/prescryptivepro-logo';

export interface IPortalTopBarProps {
  onMenuClick?: () => void;
}

export const PortalTopBar: FunctionComponent<IPortalTopBarProps> = ({
  onMenuClick = () => ({}),
}): ReactElement => {
  const theme = useTheme();
  return (
    <StyledPortalTopBarContainer>
      <Box style={{ position: 'absolute', left: '32px' }}>
        <IconButton
          disableRipple={true}
          aria-label='toggle menu'
          onClick={onMenuClick}
          style={{
            color: theme.palette.primary.main,
          }}
          size='large'
        >
          <MenuIcon />
        </IconButton>
      </Box>
      <PrescryptiveProLogo />
    </StyledPortalTopBarContainer>
  );
};
