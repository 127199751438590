// Copyright 2021 Prescryptive Health, Inc.

import { ILocationAvailabilityAction } from './location-availability.action';

export type ILocationAvailabilityIsStaleAction = ILocationAvailabilityAction<'LOCATION_AVAILABILITY_IS_STALE'>;

export const locationAvailabilityIsStaleAction = (): ILocationAvailabilityIsStaleAction => ({
  type: 'LOCATION_AVAILABILITY_IS_STALE',
  payload: { isStale: true },
});
