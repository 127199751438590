// Copyright 2021 Prescryptive Health, Inc.

import { IPaymentReportsAction } from './payment-reports.action';
import { IPaymentReport } from '../../../model/payment-report';

export type IPaymentReportsSetAction = IPaymentReportsAction<'PAYMENT_REPORTS_SET'>;

export const paymentReportsSetAction = (
  paymentReports: IPaymentReport[]
): IPaymentReportsSetAction => ({
  type: 'PAYMENT_REPORTS_SET',
  payload: { paymentReports },
});
