// Copyright 2020 Prescryptive Health, Inc.

import { AuthError } from 'msal';
import { ClientAuthErrorMessage } from 'msal/lib-commonjs/error/ClientAuthError';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { AuthenticationProvider } from '../../../state/authentication/authentication-provider';
import { ApiErrorCode } from '../../errors/api-error-code';
import { ApiErrors } from '../../errors/api-errors';
import { LoginCancelledError } from '../../errors/login-cancelled.error';
import { LoginInProgressError } from '../../errors/login-in-progress.error';

export async function login(authProvider: AuthenticationProvider): Promise<void> {
  try {
    await authProvider.login();
  } catch (ex) {
    const { errorCode, message } = ex as AuthError;

    if (errorCode === ClientAuthErrorMessage.userCancelledError.code) {
      throw new LoginCancelledError();
    }

    if (errorCode === ClientAuthErrorMessage.loginProgressError.code) {
      throw new LoginInProgressError();
    }

    throw new PharmacyPortalError(
      ApiErrors.login(message),
      ApiErrorCode.LOGIN_ERROR,
      PharmacyPortalErrorType.API_ERROR
    );
  }
}
