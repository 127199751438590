// Copyright 2020 Prescryptive Health, Inc.

import { IAppointmentDetails } from '../../model/appointment-details';
import { ICalendarAppointmentItem } from '../../model/calendar-appointment-item';
import { ICalendarBlockedTimeItem } from '../../model/calendar-blocked-time-item';

export interface IAppointmentsState {
  appointments: ICalendarAppointmentItem[];
  blockedSlots: ICalendarBlockedTimeItem[];
  appointmentsAreStale: boolean;
  appointmentDetailsMap: {
    [appointmentId: string]: IAppointmentDetails | null | undefined;
  };
}

export const defaultAppointmentsState: IAppointmentsState = {
  appointments: [],
  blockedSlots: [],
  appointmentsAreStale: false,
  appointmentDetailsMap: {},
};
