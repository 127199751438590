// Copyright 2021 Prescryptive Health, Inc.

import { Box, BoxProps, useTheme } from '@mui/material';
import React, { FunctionComponent, ReactElement } from 'react';

interface ITabPanelProps extends BoxProps {
  style?: React.CSSProperties;
}

export const TabPanel: FunctionComponent<ITabPanelProps> = ({
  children,
  style,
  hidden,
  ...other
}): ReactElement => {
  const theme = useTheme();

  return (
    <Box
      paddingTop={theme.spacing(6)}
      overflow='auto'
      style={style}
      role='tabpanel'
      hidden={hidden}
      height='100%'
      {...other}
    >
      {!hidden && children}
    </Box>
  );
};
