// Copyright 2020 Prescryptive Health, Inc.

import React, { FunctionComponent, useReducer } from 'react';
import { ErrorStatus, IErrorState } from '../../../src/state/error/error.state';
import { errorReducer, ErrorReducer } from '../../../src/state/error/error.reducer';
import { ErrorContext } from './error.context';

const initialState: IErrorState = {
  status: ErrorStatus.none,
  title: undefined,
  content: undefined,
};

export const ErrorContextProvider: FunctionComponent = ({ children }) => {
  const [errorState, errorDispatch] = useReducer<ErrorReducer>(errorReducer, initialState);

  return (
    <ErrorContext.Provider value={{ errorState, errorDispatch }}>{children}</ErrorContext.Provider>
  );
};
