// Copyright 2021 Prescryptive Health, Inc.

import { locationServicesAreStaleAction } from '../actions/location-services-are-stale.action';
import { LocationDispatch } from './location.dispatch';

export const locationServicesAreStaleDispatch = (
  dispatch: LocationDispatch
): void => {
  dispatch(locationServicesAreStaleAction());
};
