// Copyright 2020 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { useCurrentContent } from '../../../hooks/use-current-content/use-current-content.hook';
import {
  ISessionLoadingScreenContent,
  sessionLoadingScreenContent,
} from './session-loading.screen.content';

export const SessionLoadingScreen = (): ReactElement => {
  const { content } = useCurrentContent<ISessionLoadingScreenContent>(sessionLoadingScreenContent);

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      flex={1}
      height='100vh'
      bgcolor='white'
    >
      <Typography>{content.loading}</Typography>
    </Box>
  );
};
