// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { IPharmacyService } from '../../../model/pharmacy-service';
import { StyledWaitlistFilterPanelContainer } from './waitlist-filter-panel.styled-components';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { TFunction, useTranslation } from 'react-i18next';

type ServicesFilter = string | 'All';

interface IWaitlistFilterPanelProps {
  onSelectService: (service: ServicesFilter) => void;
  isDisabled: boolean;
  services: IPharmacyService[];
}

export const WaitlistFilterPanel = ({
  onSelectService,
  isDisabled,
  services,
}: IWaitlistFilterPanelProps): ReactElement => {
  const { t } = useTranslation();

  const serviceFilterOptions = ServicesPickerOptionsMap(services, t);
  const handleOnChange = (event: SelectChangeEvent<{ value: unknown }>) => {
    onSelectService(event.target.value as string);
  };

  return (
    <StyledWaitlistFilterPanelContainer>
      <FormControl fullWidth={true}>
        <InputLabel id='waitlist-filter-panel-label'>
          {t('waitlist.waitlistFilterPanel.serviceRequestedLabel')}
        </InputLabel>
        <Select id='waitlist-filter-panel-select' onChange={handleOnChange} disabled={isDisabled}>
          {Array.from(serviceFilterOptions.keys()).map((key: string, index: number) => {
            const value = serviceFilterOptions.get(key) ?? '';
            return (
              <MenuItem key={`${index}${value}`} value={key}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </StyledWaitlistFilterPanelContainer>
  );
};

function ServicesPickerOptionsMap(
  services: IPharmacyService[],
  t: TFunction<'translation', undefined>
): Map<string, string> {
  const options = new Map<string, string>();

  if (services.length === 1) {
    return options.set(services[0].serviceType, services[0].name);
  }

  if (services.length === 0) {
    return options.set('', t('waitlist.waitlistFilterPanel.noServicesAvailable'));
  }

  options.set('', t('waitlist.waitlistFilterPanel.allServices'));

  const sortedServices = services.sort((a, b) => a.name.localeCompare(b.name));

  sortedServices.forEach((service) => {
    options.set(service.serviceType, service.name);
  });

  return options;
}
