import { gql } from '@apollo/client';

export const GET_PROVIDER = gql`
  query getProvider($id: ID) {
    provider(id: $id) {
      id
      programs {
        id
        name
        enrollmentStatus
        isEnabled
      }
    }
  }
`;

export interface IProviderEnrollment {
  id: string;
  programs: IPrograms[];
}

interface IPrograms {
  id: string;
  name: string;
  enrollmentStatus: string;
  isEnabled: boolean;
}
