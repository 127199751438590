// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { LanesAccordionSummary } from './lanes.section.styled-components';
import { ILaneInfo } from '../../../../../model/location-availability';
import { LanesEmailAddress } from './add-edit-location.modal';
import { useTranslation } from 'react-i18next';

export type ILaneMetaInfo = Omit<ILaneInfo, 'serviceHours'>;
export interface ILanesSectionProps {
  lanes: ILaneMetaInfo[];
  lanesErrors: LanesEmailAddress[];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  addLane: () => void;
}

export const LanesSection = (props: ILanesSectionProps): ReactElement => {
  const { t } = useTranslation();

  const { lanes, lanesErrors, handleChange, addLane } = props;

  const theme = useTheme();

  return (
    <Box marginTop={theme.spacing(6)}>
      <Typography variant='subtitle1'>{t('settings.lanesSection.title')}</Typography>
      <Typography variant='subtitle2' style={{ marginTop: theme.spacing(4) }}>
        {t('settings.lanesSection.subTitle')}
      </Typography>
      <Typography variant='body2' style={{ marginTop: theme.spacing(4) }}>
        {t('settings.lanesSection.description')}
      </Typography>
      <Accordion
        style={{ marginTop: 0 }}
        TransitionProps={{ unmountOnExit: true }}
        defaultExpanded={true}
      >
        <LanesAccordionSummary
          aria-controls={`accordian-title-lanes`}
          sx={{ '& .MuiAccordionSummary-content': { flexGrow: 0 } }}
        >
          <Box display='flex' justifyContent='space-between' flexGrow='1' alignContent='center'>
            <Typography variant='subtitle2'>{t('settings.lanesSection.accordionText')}</Typography>
          </Box>
        </LanesAccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          <Box>
            {lanes.map((lane, index) => {
              const label = t('settings.lanesSection.getLanesTextFieldLabel', { order: index + 1 });
              const isError = lanesErrors.some((x) => x.emailAddress === lane.emailAddress);
              return (
                <TextField
                  key={lane.emailAddress}
                  label={label}
                  name={lane.emailAddress}
                  type='text'
                  fullWidth={true}
                  value={lane.displayName}
                  onChange={handleChange}
                  error={isError}
                  helperText={
                    isError
                      ? t('settings.lanesSection.getErrorText', { textFieldLabel: label })
                      : ''
                  }
                  variant='filled'
                  margin='dense'
                />
              );
            })}
            <Button
              variant='text'
              color='primary'
              onClick={addLane}
              startIcon={<AddCircleIcon />}
              style={{ marginTop: theme.spacing(6) }}
            >
              {t('settings.lanesSection.addLaneButtonText')}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
