// Copyright 2020 Prescryptive Health, Inc.

import React, { Component, ReactNode } from 'react';
import { TelemetryEvent } from '../../providers/telemetry/telemetry.context';
import { TelemetryService } from '../../providers/telemetry/telemetry.service';
import { ErrorScreen } from '../screens/error/error.screen';

export interface IErrorBoundaryCatchProps {
  children: ReactNode;
  telemetryService: TelemetryService<TelemetryEvent>;
}

export interface IErrorBoundaryCatchState {
  hasError: boolean;
}

export class ErrorBoundaryCatch extends Component<
  IErrorBoundaryCatchProps,
  IErrorBoundaryCatchState
> {
  state: IErrorBoundaryCatchState = { hasError: false };

  public componentDidCatch(error: Error): void {
    const { telemetryService } = this.props;

    this.setState({ hasError: true });
    telemetryService.trackException(error);
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return <ErrorScreen />;
    }

    return this.props.children;
  }
}
