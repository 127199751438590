// Copyright 2020 Prescryptive Health, Inc.

import { IAllowedAccessResource } from '../../api/v1/user-access-get/user-access-get.response';
import { IProgram } from '../../model/program';
import { IService } from '../../model/service';
import { IServiceDetail } from '../../model/service-detail';

export interface IServiceDetails {
  [serviceType: string]: IServiceDetail;
}

export interface IPostAuthState {
  readonly userAccess?: {
    rules: IAllowedAccessResource[];
  };
  readonly services: IService[];
  readonly serviceDetails: IServiceDetails;
  readonly programs: IProgram[];
}

export const defaultPostAuthState: IPostAuthState = {
  userAccess: {
    rules: [],
  },
  services: [],
  serviceDetails: {},
  programs: [],
};
