// Copyright 2021 Prescryptive Health, Inc.

import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { servicesGet } from '../../../api/v1/services-get/services-get';
import { servicesSetDispatch } from '../dispatch/services-set.dispatch';
import { PostAuthDispatch } from '../dispatch/post-auth.dispatch';
import { logApiError } from '../../../api/errors/log-api-error';

export interface IServicesGetAsyncActionArgs extends IApiAsyncActionArgs {
  postAuthDispatch: PostAuthDispatch;
}

export const servicesGetAsyncAction = async (args: IServicesGetAsyncActionArgs): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IServicesGetAsyncActionArgs): Promise<void> => {
  const { configState, authProvider, postAuthDispatch, telemetryService, getAuthToken } = args;

  try {
    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    telemetryService.trackEvent('services-get', {});
    const result = await servicesGet(configState.apis.pharmacyPortal, accessToken);
    servicesSetDispatch(postAuthDispatch, result.services);
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
  }
};
