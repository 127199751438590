import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { ServerError, useMutation } from '@apollo/client';
import { ReactElement, useState } from 'react';
import { useLocationContext } from '../../../providers/location/use-location-context.hook';
import { isValidPhoneNumber } from '../../../validators/phone-number.validator/phone-number.validator';
import { PhoneMaskInput } from '../../inputs/mask-inputs/phone-mask-input/phone-mask-input';

import { PhoneNumberFormatter } from '../../../formatters/phone-number/phone-number.formatter';
import { LoadingButton } from '@mui/lab';
import { notificationSetDispatch } from '../../../state/notification/dispatch/notification-set.dispatch';
import { useNotificationContext } from '../../../hooks/use-notification-context/use-notification-context.hook';
import {
  IInviteToAppointmentVariables,
  INVITE_FOR_APPOINTMENT_MUTATION,
} from './invite-for-appointment.mutation';
import { useTranslation } from 'react-i18next';
import pharmacyPortalServiceGraphQLApolloClient from '../../../init-pharmacy-portal-service-graphql-client';
import pharmacyPortalServiceGraphQLApolloClientAAD from '../../../init-pharmacy-portal-service-graphql-client-aad';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface IInviteForAppointmentModal {
  isOpen: boolean;
  onCloseDialog: () => void;
  onInviteCancelPress: () => void;
}

export const InviteForAppointmentModal = (props: IInviteForAppointmentModal): ReactElement => {
  const { t } = useTranslation();
  const { isOpen, onCloseDialog, onInviteCancelPress } = props;

  const { auth0Migration } = useFlags();

  const theme = useTheme();

  const { notificationDispatch } = useNotificationContext();

  const {
    locationState: { services, location },
  } = useLocationContext();

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [serviceRequested, setServiceRequested] = useState<string>('');
  const [isServiceRequestedError, setIsServiceRequestedError] = useState<boolean>(false);
  const [isPhoneMaskInputBlurred, setIsPhoneMaskInputBlurred] = useState<boolean>(false);
  const [isMouseOverSendInviteButton, setIsMouseOverSendInviteButton] = useState<boolean>(false);

  const onPhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
  };

  const onServiceRequestedChange = (event: SelectChangeEvent<string>) => {
    setIsServiceRequestedError(!event.target.value);
    setServiceRequested(event.target.value as string);
  };

  const [SendInvite, { error: sendInviteError, loading: sendInviteLoading }] = useMutation(
    INVITE_FOR_APPOINTMENT_MUTATION,
    {
      client: auth0Migration
        ? pharmacyPortalServiceGraphQLApolloClient
        : pharmacyPortalServiceGraphQLApolloClientAAD,
    }
  );

  const handleSentInvitation = async () => {
    const inviteData: IInviteToAppointmentVariables = {
      locationId: location?.id ?? '',
      inviteToAppointmentInput: {
        phoneNumber: PhoneNumberFormatter.formatForApi(phoneNumber),
        serviceType: serviceRequested,
      },
    };
    await SendInvite({
      variables: inviteData,
    });
    notificationSetDispatch(
      notificationDispatch,
      t('appointments.inviteForAppointmentModal.inviteForAppointmentSuccessMessage')
    );
    onCloseDialog();
  };

  const serverError = sendInviteError?.networkError as unknown as ServerError;
  const serverErrorMessage = serverError?.result.messageType as string;

  const phoneCannotReceiveTexts = serverErrorMessage == 'TwilioLookupError';

  const allFieldsAreValid = isValidPhoneNumber(phoneNumber) && serviceRequested;

  return (
    <Dialog open={isOpen} onClose={onCloseDialog}>
      <DialogTitle>{t('appointments.inviteForAppointmentModal.title')}</DialogTitle>
      <DialogContent sx={{ display: 'grid', gap: 6 }}>
        <DialogContentText>
          {t('appointments.inviteForAppointmentModal.subtitle')}
        </DialogContentText>
        <Box display='grid' gridTemplateColumns='1fr 1fr' gap={6} sx={{ minHeight: '5em' }}>
          <PhoneMaskInput
            onPhoneNumberChange={onPhoneNumberChange}
            placeholder={t('appointments.inviteForAppointmentModal.phoneNumberPlaceholder')}
            isError={
              isPhoneMaskInputBlurred
                ? !isValidPhoneNumber(phoneNumber) || phoneCannotReceiveTexts
                : false
            }
            errorMessage={
              sendInviteError && phoneCannotReceiveTexts
                ? t(
                    'appointments.inviteForAppointmentModal.phoneNumberCannotReceiveMessagesErrorMessage'
                  )
                : t('appointments.inviteForAppointmentModal.phoneNumberDefaultErrorMessage')
            }
            label='Mobile phone number'
            required={true}
            onBlur={() => setIsPhoneMaskInputBlurred(true)}
            data-heap-redact-text='true'
          />
          <FormControl
            variant='filled'
            error={isServiceRequestedError}
            fullWidth={true}
            required={true}
          >
            <InputLabel id={`invite-for-appointment-service-requested-label`}>
              {t('appointments.inviteForAppointmentModal.serviceRequestedDefaultLabel')}
            </InputLabel>
            <Select
              labelId={`invite-for-appointment-service-requested-label`}
              onChange={onServiceRequestedChange}
              value={serviceRequested}
              label={t('appointments.inviteForAppointmentModal.serviceRequestedLabel')}
            >
              {services.map((s) => (
                <MenuItem value={s.serviceType} key={s.serviceType}>
                  {s.name}
                </MenuItem>
              ))}
            </Select>
            {isServiceRequestedError && (
              <FormHelperText>
                {t('appointments.inviteForAppointmentModal.serviceRequestedErrorMessage')}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
        <DialogContentText>
          {t('appointments.inviteForAppointmentModal.warningMessage')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!!sendInviteError && (
          <Box mx={4}>
            <Typography id='dialog-error-message' color='error'>
              {t('appointments.inviteForAppointmentModal.genericError')}
            </Typography>
          </Box>
        )}
        <Button id='cancel-button' onClick={onInviteCancelPress} variant='outlined'>
          {t('appointments.inviteForAppointmentModal.cancelButton')}
        </Button>
        <Tooltip
          title={t('appointments.inviteForAppointmentModal.tooltipTitle')}
          open={isMouseOverSendInviteButton && !allFieldsAreValid}
          onMouseOver={() => setIsMouseOverSendInviteButton(true)}
          onMouseLeave={() => setIsMouseOverSendInviteButton(false)}
        >
          <span
            style={{
              margin: theme.spacing(2),
            }}
          >
            <LoadingButton
              id='send-invite-for-appointment-button'
              color='primary'
              variant='contained'
              onClick={handleSentInvitation}
              disabled={!allFieldsAreValid}
              loading={sendInviteLoading && !sendInviteError}
            >
              {t('appointments.inviteForAppointmentModal.sendInviteButton')}
            </LoadingButton>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
