// Copyright 2021 Prescryptive Health, Inc.

import { ReactElement, useState, useEffect } from 'react';
import { CheckboxList, ICheckboxItem } from '../checkbox-list/checkbox-list';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ISelectAllCheckboxListProps {
  items: ICheckboxItem[];
  onSelect: (updatedItems: ICheckboxItem[]) => void;
  onChange?: () => void;
  label?: string;
}

export const SelectAllCheckboxList = ({
  items,
  onSelect,
  onChange = () => ({}),
  label,
}: ISelectAllCheckboxListProps): ReactElement => {
  const { t } = useTranslation();
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    if (isSelectAllValid) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [items]);

  const isSelectAllValid = !items.find((item) => !item.isSelected);

  const changeAllSelected = (changeTo: boolean) => {
    return items.map((item) => {
      return { ...item, isSelected: changeTo };
    });
  };

  const handleAllSelected = () => {
    onSelect(changeAllSelected(!allSelected));
    setAllSelected(!allSelected);
    onChange();
  };

  return (
    <Box>
      <Typography>{label}</Typography>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={allSelected} onChange={handleAllSelected} color='primary' />}
          label={t('serviceHours.selectAllCheckboxList.selectAllLabel')}
        />
        <CheckboxList items={items} onSelect={onSelect} onChange={onChange} />
      </FormGroup>
    </Box>
  );
};
