// Copyright 2021 Prescryptive Health, Inc.

import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { paymentReportsGet } from '../../../api/v1/payment-reports-get/payment-reports-get';
import { paymentReportsSetDispatch } from '../dispatch/payment-reports-set.dispatch';
import { PaymentReportsDispatch } from '../dispatch/payment-reports.dispatch';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { logApiError } from '../../../api/errors/log-api-error';

export interface IPaymentReportsGetAsyncActionArgs extends IApiAsyncActionArgs {
  paymentReportsDispatch: PaymentReportsDispatch;
  locationId: string;
}

export const paymentReportsGetAsyncAction = async (
  args: IPaymentReportsGetAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IPaymentReportsGetAsyncActionArgs): Promise<void> => {
  const {
    configState,
    authProvider,
    paymentReportsDispatch,
    locationId,
    telemetryService,
    getAuthToken,
  } = args;

  try {
    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    telemetryService.trackEvent('payment-report-get', { locationId });
    const result = await paymentReportsGet(
      configState.apis.pharmacyPortal,
      accessToken,
      locationId
    );
    paymentReportsSetDispatch(paymentReportsDispatch, result);
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
  }
};
