// Copyright 2021 Prescryptive Health, Inc.

import { ListItemProps, ListItem, Theme, List, ListItemIcon } from '@mui/material';
import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material';
import { drawerWidth } from '../styles';

interface NavItemProps extends ListItemProps {
  $isActive?: boolean;
}

export const NavItemIcon = styled(ListItemIcon)<NavItemProps>`
  color: ${(props: { theme: Theme; $isActive?: boolean }) =>
    props.$isActive ? props.theme.palette.common.white : props.theme.palette.text.secondary};
`;

export const NavItem = styled(ListItem)<NavItemProps>`
  color: ${(props: { theme: Theme; $isActive?: boolean }) =>
    props.$isActive
      ? props.theme.palette.getContrastText(props.theme.palette.primary.main)
      : props.theme.palette.text.secondary};

  &:hover {
    background-color: ${(props: { theme: Theme }) => props.theme.palette.primary.dark};
    color: ${(props: { theme: Theme }) => props.theme.palette.common.white};
    ${NavItemIcon} {
      color: ${(props: { theme: Theme }) => props.theme.palette.common.white};
    }
  }

  border-radius: 8px;
  background-color: ${(props: { theme: Theme; $isActive?: boolean }) =>
    props.$isActive ? props.theme.palette.primary.main : 'transparent'};
`;

export const DrawerContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: ${(props: { theme: Theme }) => props.theme.spacing(4)};
  padding-bottom: ${(props: { theme: Theme }) => props.theme.spacing(4)};
  padding-left: ${(props: { theme: Theme }) => props.theme.spacing(2)};
  padding-right: ${(props: { theme: Theme }) => props.theme.spacing(2)};
  height: 100%;
`;

export const NavLinksList = styled(List)`
  display: grid;
  gap: ${(props: { theme: Theme }) => props.theme.spacing(4)};
`;

export const Nav = muiStyled('nav')(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    width: drawerWidth,
    flexShrink: 0,
  },
}));
