// Copyright 2021 Prescryptive Health, Inc.

import { IService } from '../../../model/service';
import { IPostAuthAction } from './post-auth.action';

export type IServicesSetAction = IPostAuthAction<'SERVICES_SET'>;

export const servicesSetAction = (
  services: IService[]
): IServicesSetAction => ({
  type: 'SERVICES_SET',
  payload: { services },
});
