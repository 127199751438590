// Copyright 2021 Prescryptive Health, Inc.

import { IStoreLocation } from '../../../model/store-location';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { buildBearerAuthHeaders, buildUrl } from '../../api.helper';
import { call } from '../../call';
import { IErrorResponse } from '../../error-response';
import { ApiErrorCode } from '../../errors/api-error-code';
import { ApiErrors } from '../../errors/api-errors';
import { HttpStatusCodesEnum } from '../../http-status-codes';
import { IProviderAddRequest } from './provider-add.request';
import { IProviderAddResponse } from './provider-add.response';

export async function providerAdd(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  addProviderRequest: IProviderAddRequest
): Promise<IStoreLocation> {
  const url = buildUrl(apiConfig, 'providerAdd', {});
  const headers = buildBearerAuthHeaders(authToken);

  const response: Response = await call(url, addProviderRequest, 'POST', headers);

  if (response.ok) {
    return await resultFromResponse(addProviderRequest.providerName, response);
  }
  if (response.status === HttpStatusCodesEnum.BAD_REQUEST) {
    const errorResponse = (await response.json()) as IErrorResponse;
    errorResponse.status = response.status;
    throw errorResponse;
  }
  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.PROVIDER_ADD,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }
  throw new PharmacyPortalError(
    ApiErrors.providerAdd(response.statusText),
    ApiErrorCode.PROVIDER_ADD,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}

async function resultFromResponse(
  providerName: string,
  response: Response
): Promise<IStoreLocation> {
  const responseData = (await response.json()) as IProviderAddResponse;
  if (!responseData) {
    throw new Error(
      ApiErrors.providerAddResponse(providerName, ApiErrors.unexpectedResponseDataFormat)
    );
  }

  const result: IStoreLocation = {
    id: responseData.id ?? '',
    name: providerName,
    phoneNumber: responseData.phoneNumber ?? '',
    address: {
      line1: responseData.address ?? '',
      city: responseData.city ?? '',
      state: responseData.state ?? '',
      zip: responseData.zipCode ?? '',
    },
    timezone: responseData.timezone,
  };

  return result;
}
