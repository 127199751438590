// Copyright 2020 Prescryptive Health, Inc.

import { createContext } from 'react';
import {
  IConfigState,
  defaultConfigState,
} from '../../state/config/config.state';

export interface IConfigContext {
  readonly configState: IConfigState;
}

export const ConfigContext = createContext<IConfigContext>({
  configState: defaultConfigState,
});
