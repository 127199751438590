// Copyright 2021 Prescryptive Health, Inc.

import { Theme, Box, BoxProps } from '@mui/material';
import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material';
interface StyledHeroContainerProps extends BoxProps {
  $beforeBackgroundColor?: string | null;
}

export const StyledHeroContainer = styled(Box)<StyledHeroContainerProps>`
  padding: ${(p: { theme: Theme }) => p.theme.spacing(8)};
  ::before {
    content: '';
    position: absolute;
    display: block;
    top: -100em;
    left: -100em;
    background: ${(p: StyledHeroContainerProps) => p.$beforeBackgroundColor};
    bottom: 0;
    right: 0;
    z-index: -1;
  }
`;

export const StyledMainContentBox = muiStyled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    paddingLeft: theme.spacing(8),
  },
}));

export const StyledTitleDescriptionBox = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
  paddingBottom: theme.spacing(10),
}));
