// Copyright 2021 Prescryptive Health, Inc.

import React, { ChangeEvent, ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, IconButton, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { IServiceQuestionSelectOption } from '../../../../../model/pharmacy-service';
import { useTranslation } from 'react-i18next';

interface IQuestionEditListProps {
  answerList: IServiceQuestionSelectOption[];
  onEdit: (values: IServiceQuestionSelectOption[]) => void;
  isRemoveDisabled?: boolean;
}

export const QuestionCardEditList = (props: IQuestionEditListProps): ReactElement => {
  const { t } = useTranslation();

  const theme = useTheme();

  const { answerList, onEdit, isRemoveDisabled } = props;

  const removeItemFromList = (id: string) => {
    const newList = answerList.filter((answer) => answer.id !== id);
    onEdit(newList);
  };

  const addItemToList = () => {
    const newList = [...answerList, { id: uuidv4(), text: '' }];
    onEdit(newList);
  };

  const updateItemInList = (id: string, text: string) => {
    const newList = answerList.map((answer) => {
      if (answer.id === id) {
        return { ...answer, text };
      }
      return answer;
    });
    onEdit(newList);
  };

  const renderList = () => {
    return answerList.map((answer) => {
      const key = answer.id;

      const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        updateItemInList(answer.id, event.target.value);
      };

      const handleRemove = () => {
        removeItemFromList(answer.id);
      };

      return (
        <Box key={key} display='flex' gap={theme.spacing(2)} alignItems='center'>
          <TextField
            value={answer.text}
            onChange={handleInputChange}
            style={{ flexGrow: 1, flexShrink: 1, maxWidth: '596px' }}
          />
          <IconButton
            aria-label={t('services.questionCardEditList.removeValueAccessibilityLabel')}
            color='primary'
            onClick={handleRemove}
            disabled={isRemoveDisabled}
            size='large'
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      );
    });
  };

  return (
    <Box display='flex' flexDirection='column' gap={theme.spacing(2)}>
      {renderList()}
      <Box>
        <Button
          variant='text'
          color='primary'
          startIcon={<AddCircleIcon />}
          onClick={addItemToList}
        >
          {t('services.questionCardEditList.addLabel')}
        </Button>
      </Box>
    </Box>
  );
};
