// Copyright 2021 Prescryptive Health, Inc.

import { Box } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { ReactElement } from 'react';
import {
  ComponentProviderServiceContentTypesUrl,
  ProviderLanderSectionContentZoneDynamicZone,
} from '../../../../model/strapi/strapi-models';
import {
  StyledSignUpFooterContainer,
  StyledFooterLink,
  StyledSignUpFooterMainLink,
  StyledFooterSeparator,
} from './sign-up-footer.styled-components';

type ISignUpFooterProps = {
  signUpFooterData: Maybe<Maybe<ProviderLanderSectionContentZoneDynamicZone>[]>;
};

export const SignUpFooter = ({ signUpFooterData }: ISignUpFooterProps): ReactElement => {
  const separator = <StyledFooterSeparator>{'|'}</StyledFooterSeparator>;

  const renderMainLink = signUpFooterData &&
    signUpFooterData[0]?.__typename === 'ComponentProviderServiceContentTypesUrl' && (
      <StyledSignUpFooterMainLink
        href={signUpFooterData[0]?.URLAddress ?? ''}
        target={(signUpFooterData[0]?.Target as string) ?? '_blank'}
      >
        {signUpFooterData[0]?.DisplayText ?? ''}
      </StyledSignUpFooterMainLink>
    );

  const renderOtherLinks = () => {
    const otherLinks = signUpFooterData?.slice(1) as
      | ComponentProviderServiceContentTypesUrl[]
      | undefined;

    const otherLinkComponents = otherLinks?.map((data, index: number) => {
      if (data) {
        return (
          <Box key={index} display='flex'>
            <StyledFooterLink href={data?.URLAddress} target={'_blank'}>
              {data?.DisplayText}
            </StyledFooterLink>
            {index !== otherLinks?.length - 1 && separator}
          </Box>
        );
      }
      return null;
    });

    return (
      <Box display='flex' flexDirection='row' alignItems='center'>
        {otherLinkComponents}
      </Box>
    );
  };

  return (
    <StyledSignUpFooterContainer>
      {renderMainLink}
      {renderOtherLinks()}
    </StyledSignUpFooterContainer>
  );
};
