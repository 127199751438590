// Copyright 2020 Prescryptive Health, Inc.

import { INotificationAction } from './notification.action';

export type INotificationClearAction = INotificationAction<'NOTIFICATION_CLEAR'>;

export const notificationClearAction = (): INotificationClearAction => ({
  type: 'NOTIFICATION_CLEAR',
  payload: {
    message: undefined,
    severity: undefined,
  },
});
