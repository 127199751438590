// Copyright 2020 Prescryptive Health, Inc.

import { ReactElement, useState } from 'react';
import { SecondaryScreenContainer } from '../../screen-containers/secondary/secondary.screen-container';
import { usePostAuthScreenContext } from '../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { SummarySection } from './sections/summary/summary.section';
import { UnblockTimeModal } from './unblock/unblock-time.modal';
import {
  blockedTimeUnblockAsyncAction,
  IBlockedTimeUnblockAsyncActionArgs,
} from '../../../state/blocked-time-details/async-actions/blocked-time-unblock.async-action';
import { useAppointmentsContext } from '../../../providers/appointments/use-appointments-context.hook';
import { notificationSetDispatch } from '../../../state/notification/dispatch/notification-set.dispatch';
import { useNotificationContext } from '../../../hooks/use-notification-context/use-notification-context.hook';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useLocationContext } from '../../../providers/location/use-location-context.hook';
import { useProviderContext } from '../../../providers/provider/use-provider-context.hook';
import { ICalendarBlockedTimeItem } from '../../../model/calendar-blocked-time-item';
import { authenticatedRouteConfig } from '../../../navigation/authenticated-route-config';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { StyledBlockedTimeDetailsToolBar } from './blocked-time-details.screen.styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getToken } from '../../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const BlockedTimeDetailsScreen = (): ReactElement => {
  const { t } = useTranslation();
  const { blockedTimeId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { busyDispatch, configState, errorDispatch, telemetryService, authProvider, authState } =
    usePostAuthScreenContext({ defaultContent: {} });

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const [isUnblockConfirmationOpen, setIsUnblockConfirmationOpen] = useState(false);

  const {
    providerState: { currentProvider },
  } = useProviderContext();

  const {
    locationState: { location },
  } = useLocationContext();

  const currentProviderRules = authState.userAccess?.rules.find(
    (rule) => rule.name === currentProvider?.name
  );

  const canDeleteBlockTime = currentProviderRules && currentProviderRules.role === 'Admin';

  if (!currentProvider || !location || !blockedTimeId) {
    void navigate(authenticatedRouteConfig.root.createUrl());
  }

  const {
    appointmentsDispatch,
    appointmentsState: { blockedSlots },
  } = useAppointmentsContext();
  const { notificationDispatch } = useNotificationContext();

  const blockedSlot: ICalendarBlockedTimeItem | undefined = blockedSlots.find(
    (slot) => slot.blockedTimeId === blockedTimeId
  );

  if (!blockedSlot) {
    void navigate(authenticatedRouteConfig.appointments.createUrl());
    return <></>;
  }
  const { date, durationMinutes, reason } = blockedSlot;

  const onUnblockButtonPress = () => setIsUnblockConfirmationOpen(true);

  const onConfirmedPress = () => {
    if (!blockedTimeId) {
      return;
    }
    const args: IBlockedTimeUnblockAsyncActionArgs = {
      appointmentsDispatch,
      authProvider,
      blockedTimeId,
      busyDispatch,
      configState,
      errorDispatch,
      telemetryService,
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
    };
    const onSuccess = () => {
      notificationSetDispatch(
        notificationDispatch,
        t('blockedTimeDetails.blockedTimeDetailsScreen.unblockSuccessMessage')
      );
      void navigate(authenticatedRouteConfig.appointments.createUrl());
    };
    const onFailureReturn = () => true;

    blockedTimeUnblockAsyncAction(args).then(onSuccess, onFailureReturn);
  };

  const onKeepPress = () => setIsUnblockConfirmationOpen(false);

  const unblockModal = (
    <UnblockTimeModal
      isOpen={isUnblockConfirmationOpen}
      durationMinutes={durationMinutes}
      reason={reason}
      startDate={date}
      onKeepPress={onKeepPress}
      onUnblockPress={onConfirmedPress}
    />
  );

  const isUnblockDisabled = isUnblockConfirmationOpen || !canDeleteBlockTime;

  return (
    <SecondaryScreenContainer dialogModal={unblockModal}>
      <Box paddingTop={theme.spacing(5)}>
        <Typography variant='h1'>
          {t('blockedTimeDetails.blockedTimeDetailsScreen.title')}
        </Typography>
      </Box>
      <StyledBlockedTimeDetailsToolBar>
        <Box display='flex' flexDirection='row' flexGrow={1}>
          <Button
            data-testid='unblock-button'
            startIcon={<LockOpenIcon />}
            onClick={onUnblockButtonPress}
            color='primary'
            size='large'
            disabled={isUnblockDisabled}
          >
            {t('blockedTimeDetails.blockedTimeDetailsScreen.unblockButtonLabel')}
          </Button>
        </Box>
      </StyledBlockedTimeDetailsToolBar>
      <SummarySection startDate={date} durationMinutes={durationMinutes} reason={reason} />
    </SecondaryScreenContainer>
  );
};
