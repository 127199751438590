// Copyright 2020 Prescryptive Health, Inc.

import { IAuthenticationApiConfig, IAutheticationConfig } from '../../config/authentication.config';
import { ITelemetryConfig } from '../../config/telemetry.config';
import { IApiConfig } from '../../api/api.helper';

const authenticationApiConfig: IAuthenticationApiConfig = {
  env: {
    host: 'login.microsoftonline.com',
    port: '',
    protocol: 'https',
    version: '',
  },
  paths: {
    login: '/:tenantId',
  },
};

type PharmacyPortalApiPathKey =
  | 'appointment'
  | 'appointmentDelete'
  | 'appointments'
  | 'appointmentsOfType'
  | 'blockedTimeDelete'
  | 'blockedTimes'
  | 'laneAvailabilityUpdate'
  | 'locationAvailability'
  | 'locationAvailabilityUpdate'
  | 'npiResultGet'
  | 'locationAdd'
  | 'locationUpdate'
  | 'locationDelete'
  | 'locationIsTestUpdate'
  | 'paymentReports'
  | 'pharmacyServicePriceUpdate'
  | 'pharmacyServicePaymentUpdate'
  | 'pharmacyServicesGet'
  | 'pharmacyServicesGetForLocation'
  | 'pharmacyServiceQuestionDelete'
  | 'pharmacyServiceQuestionUpsert'
  | 'pharmacyServiceUpdate'
  | 'procedureResults'
  | 'providerAdd'
  | 'providerDetailsGet'
  | 'providerDetailsUpdate'
  | 'providerLocations'
  | 'providerServiceAdd'
  | 'providerServiceSetActive'
  | 'providerProgramSetActive'
  | 'providerProgramAdd'
  | 'providerUsersAdd'
  | 'providerUsersGet'
  | 'providerUsersUpdate'
  | 'schedule'
  | 'serviceDetailsGet'
  | 'servicesGet'
  | 'programsGet'
  | 'userAccess'
  | 'waitlist'
  | 'waitlistAdd'
  | 'waitlistInvite'
  | 'waitlistRemove';
export type IPharmacyPortalApiConfig = IApiConfig<PharmacyPortalApiPathKey>;
const pharmacyPortalApiConfig: IPharmacyPortalApiConfig = {
  env: {
    host: '<undefined>',
    port: '<undefined>',
    protocol: 'https',
    version: '/api/v1',
  },
  paths: {
    appointment: '/appointments/:appointmentId',
    appointmentDelete: '/appointments/:appointmentId',
    appointments: '/appointments?locationId=:locationId&startDate=:startDate&endDate=:endDate',
    appointmentsOfType:
      '/appointments?locationId=:locationId&startDate=:startDate&endDate=:endDate&type=:type',
    blockedTimeDelete: '/blocked-times/:blockedTimeId',
    blockedTimes: '/blocked-times',
    laneAvailabilityUpdate: '/lane-availability/:availabilityId',
    locationAvailability: '/location-availability?providerName=:providerId',
    locationAvailabilityUpdate: '/location-availability/:availabilityId',
    locationAdd: '/locations',
    locationUpdate: '/locations/:locationIdentifier',
    locationDelete: '/locations/:locationIdentifier',
    locationIsTestUpdate: '/provider-support/locations/:locationIdentifier/isTest',
    paymentReports: '/payment-reports?locationId=:locationId',
    pharmacyServicePriceUpdate: '/providers/:providerId/services/:serviceType/price',
    pharmacyServicePaymentUpdate: '/providers/:providerId/services/:serviceType/paymentSettings',
    pharmacyServicesGet: '/locations/:locationId/services',
    pharmacyServicesGetForLocation: '/services?identifier=:locationId',
    pharmacyServiceQuestionDelete:
      '/locations/:locationId/services/:serviceType/questions/:questionId',
    pharmacyServiceQuestionUpsert:
      '/locations/:locationId/services/:serviceType/questions/:questionId',
    pharmacyServiceUpdate: '/locations/:locationId/services/:serviceType',
    procedureResults: '/appointments/:appointmentId/procedure-results',
    providerAdd: '/provider-support/provider',
    providerDetailsGet: '/providers/:providerId',
    providerDetailsUpdate: '/providers/:providerId',
    providerLocations: '/provider-locations?providerName=:providerId',
    providerServiceAdd: '/providers/:providerName/services',
    providerServiceSetActive: '/provider-support/:providerName/services/:serviceType/enrollment',
    providerProgramSetActive: '/provider-support/:providerName/programs/:programId/enrollment',
    providerProgramAdd: '/providers/:providerName/programs',
    providerUsersAdd: '/providers/:providerName/users',
    providerUsersGet: '/providers/:providerName/users',
    providerUsersUpdate: '/providers/:providerName/users/:userId',
    schedule:
      '/locations/:locationId/schedule?date=:date&serviceType=:serviceType&bookingStatus=:bookingStatus',
    serviceDetailsGet: '/services/:serviceType',
    servicesGet: '/services',
    programsGet: '/programs',
    userAccess: '/user-access',
    npiResultGet: '/public/providers?npi=:npi',
    waitlist:
      '/locations/:locationId/waitlist?pageNumber=:pageNumber&pageSize=:pageSize&serviceType=:serviceType',
    waitlistAdd: '/locations/:locationId/waitlist',
    waitlistInvite: '/locations/:locationId/waitlist/invite',
    waitlistRemove: '/locations/:locationId/waitlist/:waitlistItemId',
  },
  retryPolicy: {
    pause: 2000,
    remaining: 3,
  },
};

export interface IConfigState {
  readonly apis: {
    readonly authentication: IAuthenticationApiConfig;
    readonly pharmacyPortal: IPharmacyPortalApiConfig;
  };
  readonly authentication: IAutheticationConfig;
  readonly supportEmail: string;
  readonly telemetry: ITelemetryConfig;
  readonly launchDarklyKey: string;
  readonly myrxDomain: string;
  readonly strapiHostUrl: string;
  readonly newUserDomain: string;
}

export const defaultConfigState: IConfigState = {
  apis: {
    authentication: authenticationApiConfig,
    pharmacyPortal: pharmacyPortalApiConfig,
  },
  authentication: {
    clientId: '<undefined>',
    tenantId: '<undefined>',
  },
  supportEmail: 'myrx@prescryptive.com',
  telemetry: {
    instrumentationKey: '<undefined>',
    serviceName: 'pharmacy-portal-web',
  },
  launchDarklyKey: '<undefined>',
  myrxDomain: 'myrx.io',
  strapiHostUrl: '<undefined>',
  newUserDomain: '<undefined>',
};
