// Copyright 2020 Prescryptive Health, Inc.

import { IAddress } from '../../model/address';

export class AddressFormatter {
  public static format(address: IAddress): string {
    const formattedAddress = [address.line1, address.line2].filter(Boolean).join(' ');

    return `${formattedAddress}, ${address.city}, ${address.state} ${address.zip}`.trim();
  }
}
