// Copyright 2020 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import dateFormatter from '../../../../../formatters/dates/date.formatter';
import { Box, useTheme } from '@mui/material';
import {
  SummarySectionColumnContainer,
  SummarySectionDetailTypography,
  SummarySectionLabelTypography,
} from './summary.section.styled-components';
import { useTranslation } from 'react-i18next';

export interface ISummarySectionProps {
  startDate: Date;
  durationMinutes: number;
  reason: string;
}

export const SummarySection = ({
  startDate,
  durationMinutes,
  reason,
}: ISummarySectionProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  const hasStartDate = startDate !== new Date(0);
  const formattedStartDate = hasStartDate ? dateFormatter.formatToLongDate(startDate) : '';
  const formattedTimeRange = hasStartDate
    ? dateFormatter.formatToTimeRange(startDate, durationMinutes)
    : '';

  return (
    <Box
      bgcolor={theme.palette.primary.light}
      padding={theme.spacing(10)}
      marginBottom={theme.spacing(12)}
    >
      <SummarySectionColumnContainer>
        <Box display='flex' flexDirection='column' flex={1}>
          <Box display='flex'>
            <SummarySectionLabelTypography>
              {t('blockedTimeDetails.summarySection.dateLabel')}
            </SummarySectionLabelTypography>
            <SummarySectionDetailTypography>{formattedStartDate}</SummarySectionDetailTypography>
          </Box>
          <Box display='flex'>
            <SummarySectionLabelTypography>
              {t('blockedTimeDetails.summarySection.timeLabel')}
            </SummarySectionLabelTypography>
            <SummarySectionDetailTypography>{formattedTimeRange}</SummarySectionDetailTypography>
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' flex={1}>
          <Box display='flex'>
            <SummarySectionLabelTypography>
              {t('blockedTimeDetails.summarySection.reasonLabel')}
            </SummarySectionLabelTypography>
            <SummarySectionDetailTypography>{reason}</SummarySectionDetailTypography>
          </Box>
        </Box>
      </SummarySectionColumnContainer>
    </Box>
  );
};
