// Copyright 2021 Prescryptive Health, Inc.

import { Paper, Theme } from '@mui/material';
import styled from 'styled-components';

const white = ({ theme }: { theme: Theme }) => theme.palette.common.white;
const dark = ({ theme }: { theme: Theme }) => theme.palette.primary.main + '14'; // primary color at 8% transparency
const darkBar = ({ theme }: { theme: Theme }) => theme.palette.primary.dark;
const mainBar = ({ theme }: { theme: Theme }) => theme.palette.primary.main;

export const AppointmentCard = styled(Paper)`
  position: relative;
  background-color: ${(props: { $isPast: boolean }) => (props.$isPast ? dark : white)};
  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: ${(props: { $isPast: boolean }) => (props.$isPast ? darkBar : mainBar)};
    width: 6px;
    border-top-left-radius: ${({ theme }: { theme: Theme }) => theme.shape.borderRadius}px;
    border-bottom-left-radius: ${({ theme }: { theme: Theme }) => theme.shape.borderRadius}px;
  }
  padding: ${({ theme }: { theme: Theme }) => `${theme.spacing(2)} ${theme.spacing(3)}`};
  display: flex;
  align-items: center;
  gap: ${({ theme }: { theme: Theme }) => theme.spacing(3)};
`;
