// Copyright 2021 Prescryptive Health, Inc.

import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { programsGet } from '../../../api/v1/programs-get/programs-get';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { PostAuthDispatch } from '../dispatch/post-auth.dispatch';
import { programsSetDispatch } from '../dispatch/programs-set.dispatch';
import { logApiError } from '../../../api/errors/log-api-error';

export interface IProgramsGetAsyncActionArgs extends IApiAsyncActionArgs {
  postAuthDispatch: PostAuthDispatch;
}

export const programsGetAsyncAction = async (args: IProgramsGetAsyncActionArgs): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IProgramsGetAsyncActionArgs): Promise<void> => {
  const { configState, authProvider, postAuthDispatch, telemetryService, getAuthToken } = args;

  try {
    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    telemetryService.trackEvent('programs-get', {});
    const result = await programsGet(configState.apis.pharmacyPortal, accessToken);
    programsSetDispatch(postAuthDispatch, result.programs);
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
  }
};
