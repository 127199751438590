// Copyright 2021 Prescryptive Health, Inc.

import { createContext } from 'react';
import { ScreenDispatch } from '../../state/screen/dispatch/screen.dispatch';
import { IScreenState, defaultScreenState } from '../../state/screen/screen.state';

export interface IScreenContext {
  readonly screenState: IScreenState;
  readonly screenDispatch: ScreenDispatch;
}

export const ScreenContext = createContext<IScreenContext>({
  screenState: defaultScreenState,
  screenDispatch: () => {
    return;
  },
});
