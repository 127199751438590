// Copyright 2021 Prescryptive Health, Inc.

import { Box, Typography } from '@mui/material';
import { styled as muiStyled } from '@mui/material';

export const StyledContentWithPicBox = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(8),
  [theme.breakpoints.up('desktop')]: {
    flexDirection: 'row',
  },
}));

export const StyledButtonsGroupBox = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: theme.spacing(6),
}));

export const StyledEditSectionDescriptionTypography = muiStyled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(6),
  color: theme.palette.grey[700],
}));
