// Copyright 2021 Prescryptive Health, Inc.

import { Maybe } from 'graphql/jsutils/Maybe';
import React, { ReactElement } from 'react';
import { ProviderProgramFaqDynamicZone, ProviderServiceFaqDynamicZone } from '../../../../../model/strapi/strapi-models';
import { SectionHeading } from '../../../../strapi/section-heading/section-heading';
import { TitleDescription } from '../../../../strapi/title-description/title-description';
import { StyledSectionContainer } from '../styled-components/section-container.styled-component';

interface IFaqsProps {
  faqData: Maybe<ProviderServiceFaqDynamicZone | ProviderProgramFaqDynamicZone>[] | null | undefined;
}

export const Faqs = ({ faqData }: IFaqsProps): ReactElement => {
  const faqSection = faqData?.map((elem) => {
    switch (elem?.__typename) {
      case 'ComponentProviderServiceContentTypesSectionHeading': {
        return <SectionHeading key={elem.id} headingText={elem.HeadingText} />;
      }
      case 'ComponentProviderServiceContentTypesTitleDescription': {
        return (
          <TitleDescription
            key={elem.id}
            title={elem.Title}
            description={elem.Description}
            url={elem.TitleDescriptionUrl}
          />
        );
      }

      default:
        return null;
    }
  });

  return <StyledSectionContainer>{faqSection}</StyledSectionContainer>;
};
