// Copyright 2020 Prescryptive Health, Inc.

import { ReactNode } from 'react';
import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { login } from '../../../api/v1/login/login';
import { SessionDispatch } from '../dispatch/session.dispatch';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { loginCompleteDispatch } from '../dispatch/login-complete.dispatch';
import { IAuthenticationTelemetry } from '../../../providers/telemetry/autentication.telemetry';
import { LoginCancelledError } from '../../../api/errors/login-cancelled.error';
import { LoginInProgressError } from '../../../api/errors/login-in-progress.error';
import { postAuthTelemetry } from '../../../providers/telemetry/post-auth.telemetry';
import { nonFatalErrorDispatch } from '../../error/dispatch/non-fatal-error.dispatch';
import { logApiError } from '../../../api/errors/log-api-error';
import { TelemetryEvent } from '../../../providers/telemetry/telemetry.context';
import { TelemetryService } from '../../../providers/telemetry/telemetry.service';
import { BusyDispatch } from '../../busy/dispatch/busy.dispatch';
import { ErrorDispatch } from '../../error/dispatch/error.dispatch';
import { AuthenticationProvider } from '../../authentication/authentication-provider';
import { IConfigState } from '../../config/config.state';

export interface ILoginAsyncActionArgs {
  sessionDispatch: SessionDispatch;
  nonFatalErrorTitle: string;
  nonFatalErrorContent: ReactNode;
  telemetryService: TelemetryService<TelemetryEvent>;
  busyDispatch: BusyDispatch;
  configState: IConfigState;
  errorDispatch: ErrorDispatch;
  authProvider?: AuthenticationProvider;
}

export const loginAsyncAction = async (args: ILoginAsyncActionArgs): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: ILoginAsyncActionArgs): Promise<void> => {
  const { sessionDispatch, authProvider, telemetryService } = args;
  const telemetryAction = 'login';

  try {
    const loginRequestTelemetry: IAuthenticationTelemetry = {
      action: telemetryAction,
      status: 'requested',
    };
    telemetryService.trackEvent('authentication', loginRequestTelemetry);

    if (!authProvider) {
      throw new AuthenticationProviderUndefinedError();
    }

    if (!authProvider.isLoggedIn()) {
      await login(authProvider);
    }

    loginCompleteDispatch(sessionDispatch);

    const loginSuccessfulTelemetry: IAuthenticationTelemetry = {
      action: telemetryAction,
      status: 'successful',
      ...postAuthTelemetry(authProvider),
    };
    telemetryService.trackEvent('authentication', loginSuccessfulTelemetry);
  } catch (error) {
    if (error instanceof LoginCancelledError) {
      const loginCancelledTelemetry: IAuthenticationTelemetry = {
        action: telemetryAction,
        status: 'cancelled',
      };
      telemetryService.trackEvent('authentication', loginCancelledTelemetry);
    } else {
      const { errorDispatch, nonFatalErrorTitle, nonFatalErrorContent } = args;

      if (error instanceof LoginInProgressError) {
        nonFatalErrorDispatch(errorDispatch, nonFatalErrorTitle, nonFatalErrorContent);
      } else {
        fatalErrorDispatch(errorDispatch);
      }

      logApiError(telemetryService, error);

      const loginFailedTelemetry: IAuthenticationTelemetry = {
        action: telemetryAction,
        status: 'failed',
        ...postAuthTelemetry(authProvider),
      };
      telemetryService.trackEvent('authentication', loginFailedTelemetry);
    }
  }
};
