import { IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import { useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostAuthScreenContext } from '../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { useLocationContext } from '../../../providers/location/use-location-context.hook';
import { useProviderContext } from '../../../providers/provider/use-provider-context.hook';
import dateFormatter from '../../../formatters/dates/date.formatter';
import {
  IPaymentReportsGetAsyncActionArgs,
  paymentReportsGetAsyncAction,
} from '../../../state/payment-reports/async-actions/payment-reports-get.async-action';
import {
  PaymentReportsReducer,
  paymentReportsReducer,
} from '../../../state/payment-reports/payment-reports.reducer';
import { defaultPaymentReportsState } from '../../../state/payment-reports/payment-reports.state';
import { PostAuthPrimaryScreenContainer } from '../../screen-containers/post-auth-primary/post-auth-primary.screen-container';
import { Typography, Link } from '@mui/material';
import { IPaymentReport } from '../../../model/payment-report';
import { StyledPaymentsCard } from './payments-and-billing.screen.styled-components';
import { getToken } from '../../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const PaymentsAndBillingScreen = () => {
  const { t } = useTranslation();
  const { authProvider, busyDispatch, configState, errorDispatch, telemetryService } =
    usePostAuthScreenContext({ defaultContent: {} });

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  document.title = t('paymentsAndBilling.pageTitle');

  const {
    locationState: { location },
  } = useLocationContext();

  const {
    providerState: { currentProvider },
  } = useProviderContext();

  // TODO - future enhancement: move into a provider to minimize re-fetching on navigation
  const [{ paymentReports = [] }, paymentReportsDispatch] = useReducer<PaymentReportsReducer>(
    paymentReportsReducer,
    defaultPaymentReportsState
  );

  useEffect(() => {
    if (!location) return;

    const actionArgs: IPaymentReportsGetAsyncActionArgs = {
      locationId: location.id,
      paymentReportsDispatch,
      authProvider,
      busyDispatch,
      configState,
      errorDispatch,
      telemetryService,
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
    };

    void paymentReportsGetAsyncAction(actionArgs);

    const telemetryPayload: IPageViewTelemetry = {
      name: 'reports-payment',
      properties: {
        provider: currentProvider?.name,
      },
    };
    telemetryService.trackPageView(telemetryPayload);
  }, []);

  return (
    <PostAuthPrimaryScreenContainer>
      <Typography variant='h1' marginBottom={6}>
        {t('paymentsAndBilling.title')}
      </Typography>
      <Typography>{t('paymentsAndBilling.paymentsDescription')}</Typography>
      {sortLatestFirst(paymentReports).map((report) => (
        <Link
          href={report.downloadUri}
          target='_blank'
          download={true}
          style={{ textDecoration: 'none' }}
          key={report.fileName}
        >
          <StyledPaymentsCard>
            <Typography variant='h2' component='h2'>
              {dateFormatter.formatToLongDate(report.createdOn)}
            </Typography>
            <Typography variant='body2' color='textSecondary'>
              {report.fileName}
            </Typography>
          </StyledPaymentsCard>
        </Link>
      ))}
    </PostAuthPrimaryScreenContainer>
  );
};

const sortLatestFirst = (reports: IPaymentReport[]): IPaymentReport[] => {
  const reportsCopy = [...reports];
  reportsCopy.sort((a: IPaymentReport, b: IPaymentReport) => (a.createdOn > b.createdOn ? -1 : 1));
  return reportsCopy;
};
