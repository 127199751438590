// Copyright 2021 Prescryptive Health, Inc.
import { Link, Typography, Box } from '@mui/material';
import { styled } from '@mui/material';

export const StyledSignUpFooterContainer = styled(Box)(({ theme }) => ({
  // NOTE: this width is a hack to deal with an overflow problem
  width: '96%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(6),
  [theme.breakpoints.up('tablet')]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(6),
  },
}));

export const StyledFooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.blueScale[300],
  display: 'flex',
  alignItems: 'center',
}));

export const StyledSignUpFooterMainLink = styled(StyledFooterLink)(({ theme }) => ({
  marginBottom: theme.spacing(8),
  [theme.breakpoints.up('tablet')]: { marginBottom: theme.spacing(0) },
}));

export const StyledFooterSeparator = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
}));
