import {
  IDrugResultNdcPackageDrugResultForm,
  IDrugResultPackageSearchResult,
} from '../../../model/drug-search';
import {
  IDrugResultNdcPackageDrugResultFormResponse,
  IDrugResultNdcPackageDrugResultVariantResponse,
  IDrugResultPackageSearchResultResponse,
} from './drug-search-response';

export function drugDetailsFromResponse(
  responseData: IDrugResultPackageSearchResultResponse[]
): IDrugResultPackageSearchResult[] {
  const mappedSearchResults = responseData.map((drug: IDrugResultPackageSearchResultResponse) => {
    const mappedDrug: IDrugResultPackageSearchResult = {
      displayName: drug.displayName ?? null,
      representativeNdc: drug.representativeNdc ?? null,
      modeQuantity: drug.modeQuantity ?? null,
      modeDaySupply: drug.modeDaySupply ?? null,
      drugForms: drug.drugForms ?? [],
      forms: formsFromResponse(drug.variants ?? []),
    };
    return mappedDrug;
  });
  return mappedSearchResults;
}

function formsFromResponse(
  variants: IDrugResultNdcPackageDrugResultVariantResponse[]
): IDrugResultNdcPackageDrugResultForm[] {
  if (variants.length > 0 && variants[0].forms && variants[0].forms[0] != null) {
    return variants[0].forms.map((form: IDrugResultNdcPackageDrugResultFormResponse) => {
      const mappedForm: IDrugResultNdcPackageDrugResultForm = {
        formCode: form.formCode ?? null,
        formValue: form.formValue ?? null,
        strength: form.strength ?? null,
        strengthUnit: form.strengthUnit ?? null,
        representativeNdc: form.representativeNdc ?? null,
        modeQuantity: form.modeQuantity ?? null,
        modeDaysSupply: form.modeDaysSupply ?? null,
      };
      return mappedForm;
    });
  }

  return [];
}
