// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { useProviderContext } from '../../../../../providers/provider/use-provider-context.hook';
import { ProviderInfoDetail } from './provider-info-detail/provider-info-detail';
import {
  IProviderDetailsUpdateAsyncActionArgs,
  providerDetailsUpdateAsyncAction,
} from '../../../../../state/provider/async-actions/provider-details-update.async-action';
import { usePostAuthContext } from '../../../../../providers/post-auth/use-post-auth-context.hook';
import { InfoDetailContainer } from './pharmacy-summary.styled-components';
import { usePostAuthScreenContext } from '../../../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { PhoneNumberFormatter } from '../../../../../formatters/phone-number/phone-number.formatter';
import { isValidPhoneNumber } from '../../../../../validators/phone-number.validator/phone-number.validator';
import { getToken } from '../../../../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

export interface IPharmacySummaryProps {
  providerName: string;
  npi: string;
  cliaNumber: string;
  taxId: string;
  deaNumber: string;
  contactPhoneNumber: string;
  contactEmail: string;
}

export const PharmacySummary = ({
  providerName,
  npi,
  cliaNumber,
  taxId,
  deaNumber,
  contactPhoneNumber,
  contactEmail,
}: IPharmacySummaryProps): ReactElement => {
  const { t } = useTranslation();
  const { busyDispatch, errorDispatch, telemetryService, authProvider, configState } =
    usePostAuthScreenContext({ defaultContent: {} });

  const theme = useTheme();
  const { providerDispatch } = useProviderContext();
  const { authDispatch } = usePostAuthContext();

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isPhoneNumberIncorrect, setIsPhoneNumberIncorrect] = useState(false);
  const [providerNameChange, setProviderNameChange] = useState(providerName);
  const [contactPhoneNumberChange, setContactPhoneNumberChange] = useState(contactPhoneNumber);
  const [contactEmailChange, setContactEmailChange] = useState(contactEmail);
  const [cliaNumberChange, setCliaNumberChange] = useState(cliaNumber);

  const contactPhoneNumberHelperText = t(
    'settings.pharmacySummaryContent.contactPhoneNumberHelperText'
  );

  useEffect(() => {
    setProviderNameChange(providerName);
    setContactPhoneNumberChange(contactPhoneNumber);
    setContactEmailChange(contactEmail);
    setCliaNumberChange(cliaNumber);
  }, [providerName, cliaNumber, contactPhoneNumber, contactEmail]);

  const onEditSave = async () => {
    const args: IProviderDetailsUpdateAsyncActionArgs = {
      currentProviderName: providerName,
      newProviderName: providerNameChange,
      contactPhoneNumber: PhoneNumberFormatter.formatForApi(contactPhoneNumberChange),
      contactEmail: contactEmailChange,
      cliaNumber: cliaNumberChange,
      telemetryService,
      configState,
      errorDispatch,
      busyDispatch,
      authProvider,
      providerDispatch,
      postAuthDispatch: authDispatch,
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
    };

    try {
      await providerDetailsUpdateAsyncAction(args);
      setIsEditMode(false);
    } catch {
      setIsEditMode(true);
      return;
    }
  };

  const onProviderNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProviderNameChange(event.target.value.toLocaleUpperCase());
  };

  const onContactPhoneNumberChange = (value: string) => {
    if (value.length < 10 || !isValidPhoneNumber(value)) {
      setIsPhoneNumberIncorrect(true);
    } else {
      setIsPhoneNumberIncorrect(false);
    }
    setContactPhoneNumberChange(value);
  };

  const onContactEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactEmailChange(event.target.value);
  };

  const onCliaNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCliaNumberChange(event.target.value);
  };

  const onEditButtonClick = () => {
    setProviderNameChange(providerNameChange);
    setContactPhoneNumberChange(contactPhoneNumberChange);
    setContactEmailChange(contactEmailChange);
    setCliaNumberChange(cliaNumberChange);
    setIsEditMode(true);
  };

  const onSaveButtonClick = async () => {
    await onEditSave();
  };

  const onCancelButtonClick = () => {
    setIsEditMode(false);
    setProviderNameChange(providerName);
    setContactPhoneNumberChange(contactPhoneNumber);
    setContactEmailChange(contactEmail);
    setCliaNumberChange(cliaNumber);
  };

  const buttons = isEditMode ? (
    <>
      <Button
        startIcon={<CheckCircleIcon />}
        onClick={onSaveButtonClick}
        color='primary'
        disabled={isPhoneNumberIncorrect}
      >
        {t('settings.pharmacySummaryContent.saveButtonText')}
      </Button>
      <Button startIcon={<CloseIcon />} onClick={onCancelButtonClick}>
        {t('settings.pharmacySummaryContent.cancelButtonText')}
      </Button>
    </>
  ) : (
    <Button startIcon={<EditIcon />} onClick={onEditButtonClick} color='primary'>
      {t('settings.pharmacySummaryContent.editButtonText')}
    </Button>
  );

  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        paddingBottom={theme.spacing(8)}
      >
        <Typography variant='h5'>
          {t('settings.pharmacySummaryContent.storeInformationTitle')}
        </Typography>
        <Box>{buttons}</Box>
      </Box>
      <InfoDetailContainer>
        <ProviderInfoDetail
          label={t('settings.pharmacySummaryContent.storeNameLabel')}
          value={providerNameChange}
          isEditMode={isEditMode}
          helperText={t('settings.pharmacySummaryContent.providerNameHelperText')}
          onChange={onProviderNameChange}
        />
        <ProviderInfoDetail
          maxLength={14}
          label={t('settings.pharmacySummaryContent.contactPhoneNumberLabel')}
          value={contactPhoneNumberChange}
          isEditMode={isEditMode}
          helperText={
            isPhoneNumberIncorrect === false
              ? contactPhoneNumberHelperText
              : t('settings.pharmacySummaryContent.contactPhoneNumberHelperTextError')
          }
          phoneNumberOnChange={onContactPhoneNumberChange}
          error={isPhoneNumberIncorrect}
          isPhoneNumber={true}
        />
        <ProviderInfoDetail
          label={t('settings.pharmacySummaryContent.contactEmailLabel')}
          value={contactEmailChange}
          isEditMode={isEditMode}
          helperText={t('settings.pharmacySummaryContent.contactEmailHelperText')}
          onChange={onContactEmailChange}
        />
        <ProviderInfoDetail
          label={t('settings.pharmacySummaryContent.npiLabel')}
          value={npi}
          isEditMode={isEditMode}
          disabled={true}
        />
        <ProviderInfoDetail
          label={t('settings.pharmacySummaryContent.deaNumberLabel')}
          value={deaNumber}
          isEditMode={isEditMode}
          disabled={true}
        />
        <ProviderInfoDetail
          label={t('settings.pharmacySummaryContent.taxIdLabel')}
          value={taxId}
          isEditMode={isEditMode}
          disabled={true}
        />
        <ProviderInfoDetail
          label={t('settings.pharmacySummaryContent.cliaNumberLabel')}
          value={cliaNumberChange}
          isEditMode={isEditMode}
          helperText={t('settings.pharmacySummaryContent.cliaNumberHelperText')}
          onChange={onCliaNumberChange}
          maxLength={10}
        />
      </InfoDetailContainer>
    </>
  );
};
