// Copyright 2021 Prescryptive Health, Inc.
import React, { ReactElement, useState, useEffect } from 'react';
import { addDaysToDate } from '../../../../../helpers/dates/date-helper/date.helper';
import { localStorageKeys } from '../../../../../constants/local-storage-keys';
import { getNewDate } from '../../../../../helpers/dates/get-new-date/get-new-date';
import {
  IProcedureQuestion,
  IProcedureQuestionAndAnswer,
  IProcedureQuestionOption,
} from '../../../../../model/procedure-question';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { LabelDetail } from '../../label-detail';
import { DatePicker } from '@mui/x-date-pickers';
import Autocomplete, {
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

interface IRecordProcedureModal {
  serviceName: string;
  isOpen: boolean;
  isLoading: boolean;
  appointmentProcedureQuestions: IProcedureQuestion[];
  questionAndAnswers?: IProcedureQuestionAndAnswer[];
  onSavePress: (
    procedureQuestionandAnswers: IProcedureQuestionAndAnswer[],
    isSecondDoseScheduled: boolean,
    appointmentStartDate?: Date,
    appointmentEndDate?: Date
  ) => Promise<void>;
  onClosePress: () => void;
}

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: IProcedureQuestionOption) => option.text + (option.groupId || ''),
});

export const RecordProcedureModal = ({
  serviceName,
  isOpen,
  isLoading,
  appointmentProcedureQuestions,
  questionAndAnswers,
  onSavePress,
  onClosePress,
}: IRecordProcedureModal): ReactElement => {
  const { t } = useTranslation();

  const theme = useTheme();

  const mappedProcedureQuestionAndAnswers: IProcedureQuestionAndAnswer[] =
    appointmentProcedureQuestions.map((procedureQuestion) => {
      if (procedureQuestion.type === 'date') {
        return {
          questionId: procedureQuestion.procedureQuestionId,
          question: procedureQuestion.text,
          answerId: '',
          answer: getNewDate().toISOString(),
        };
      }
      return {
        questionId: procedureQuestion.procedureQuestionId,
        question: procedureQuestion.text,
        answerId: '',
        answer: '',
      };
    });
  const isEditingProcedure = !!(questionAndAnswers && questionAndAnswers.length > 0);
  const [procedureQuestionAndAnswers, setProcedureQuestionAndAnswers] = useState(
    isEditingProcedure && questionAndAnswers
      ? questionAndAnswers
      : mappedProcedureQuestionAndAnswers
  );
  const [isSecondDoseScheduled, setIsSecondDoseScheduled] = useState(false);
  const [isValidDate, setIsValidDate] = useState(true);

  const [selectedStartDate, setSelectedStartDate] = useState<Date>();
  const [selectedEndDate, setSelectedEndDate] = useState<Date>();
  const currDate = getNewDate();
  const onSave = async () => {
    await onSavePress(
      procedureQuestionAndAnswers,
      isEditingProcedure ? false : isSecondDoseScheduled,
      selectedStartDate,
      selectedEndDate
    );
  };

  useEffect(() => {
    // TODO: update this so that the answer is found by a question type of immunization rather than assuming it is the first question in the list
    if (isEditingProcedure) {
      return;
    }
    if (procedureQuestionAndAnswers[0].answerId === '0001A') {
      setIsSecondDoseScheduled(true);
      const newStartDate = addDaysToDate(21, currDate);
      setSelectedStartDate(newStartDate);
      const newEndDate = addDaysToDate(42, newStartDate);
      setSelectedEndDate(newEndDate);
    } else if (
      procedureQuestionAndAnswers[0].answerId === '0011A' ||
      procedureQuestionAndAnswers[0].answerId === '0021A'
    ) {
      setIsSecondDoseScheduled(true);
      const newStartDate = addDaysToDate(28, currDate);
      setSelectedStartDate(newStartDate);
      const newEndDate = addDaysToDate(42, newStartDate);
      setSelectedEndDate(newEndDate);
    } else {
      setIsSecondDoseScheduled(false);
    }
  }, [procedureQuestionAndAnswers[0].answerId]);

  useEffect(() => {
    if (isEditingProcedure) {
      return;
    }
    const updatedQuestionsAndAnswers = [...procedureQuestionAndAnswers];
    const sessionNpi = localStorage.getItem(localStorageKeys.sessionNpi);
    const sessionLotNumber = localStorage.getItem(localStorageKeys.sessionLotNumber);

    const lotNumberQuestionIndex = updatedQuestionsAndAnswers.findIndex(
      (element) => element.questionId === 'lot-number'
    );
    const npiQuestionIndex = updatedQuestionsAndAnswers.findIndex(
      (element) => element.questionId === 'provider-npi'
    );

    if (npiQuestionIndex !== -1 && sessionNpi) {
      updatedQuestionsAndAnswers[npiQuestionIndex].answer = sessionNpi;
    }
    if (lotNumberQuestionIndex !== -1 && sessionLotNumber) {
      updatedQuestionsAndAnswers[lotNumberQuestionIndex].answer = sessionLotNumber;
    }

    setProcedureQuestionAndAnswers(updatedQuestionsAndAnswers);
  }, []);

  const onSecondDoseChecked = (_: React.ChangeEvent<HTMLInputElement>) => {
    setIsSecondDoseScheduled(!isSecondDoseScheduled);
  };

  const onStartDateChange = (date: unknown) => {
    const luxonDate = date as DateTime | undefined;
    if (luxonDate?.isValid) {
      setSelectedStartDate(luxonDate.toJSDate());
    }
  };

  const onEndDateChange = (date: unknown) => {
    const luxonDate = date as DateTime | undefined;
    if (luxonDate?.isValid) {
      setSelectedEndDate(luxonDate.toJSDate());
    }
  };

  const createInputsFromProcedureQuestions = () => {
    const inputs = appointmentProcedureQuestions.map((procedureQuestion, index) => {
      if (procedureQuestion.type === 'text') {
        const onTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const updatedState = [...procedureQuestionAndAnswers];
          updatedState[index].answer = event.target.value;
          setProcedureQuestionAndAnswers(updatedState);
        };
        return (
          <TextField
            key={procedureQuestion.procedureQuestionId}
            id={procedureQuestion.procedureQuestionId}
            variant='filled'
            label={procedureQuestion.text}
            placeholder={procedureQuestion.placeholder}
            onChange={onTextInputChange}
            value={procedureQuestionAndAnswers[index].answer}
            data-heap-redact-text='true'
          />
        );
      } else if (procedureQuestion.type === 'select') {
        const onSelect = (
          _event: React.ChangeEvent<unknown>,
          newValue: IProcedureQuestionOption | null
        ) => {
          const updatedState = [...procedureQuestionAndAnswers];

          updatedState[index].answerId = newValue?.id || '';
          updatedState[index].answer = newValue?.text || '';

          setProcedureQuestionAndAnswers(updatedState);
        };
        const comboBoxInputs = (params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            label={procedureQuestion.text}
            variant='filled'
            data-heap-redact-text='true'
          />
        );
        const getOptionLabel = (option: IProcedureQuestionOption) => option.text;

        const selectedValue =
          procedureQuestion.options?.find(
            (o) => o.id && o.id === procedureQuestionAndAnswers[index].answerId
          ) || null;

        const groupBy = (option: IProcedureQuestionOption) => option.groupId || '';
        const options = (procedureQuestion.options || [])
          .slice()
          .sort(
            (a, b) => a.groupId?.localeCompare(b.groupId || '') || a.text.localeCompare(b.text)
          );
        return (
          <Autocomplete
            key={procedureQuestion.procedureQuestionId}
            id={procedureQuestion.procedureQuestionId}
            onChange={onSelect}
            value={selectedValue}
            renderInput={comboBoxInputs}
            options={options}
            getOptionLabel={getOptionLabel}
            groupBy={groupBy}
            filterOptions={filterOptions}
          />
        );
      } else if (procedureQuestion.type === 'date') {
        const onChangeDate = (date: unknown, _keyboardInputValue?: string | undefined) => {
          const luxonDate = date as DateTime | undefined;
          if (luxonDate?.isValid) {
            setIsValidDate(true);
            const updatedState = [...procedureQuestionAndAnswers];
            updatedState[index].answer = luxonDate.toJSDate().toISOString();
            setProcedureQuestionAndAnswers(updatedState);
          } else {
            setIsValidDate(false);
          }
        };

        return (
          <Box
            key={procedureQuestion.procedureQuestionId}
            id={procedureQuestion.procedureQuestionId}
            data-heap-redact-text='true'
          >
            <DatePicker
              label={procedureQuestion.text}
              value={procedureQuestionAndAnswers[index].answer}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              renderInput={(props) => <TextField {...props} variant='filled' fullWidth />}
              inputFormat='MM/dd/yyyy'
              onChange={onChangeDate}
            />
          </Box>
        );
      }
      return null;
    });

    return inputs;
  };

  const isFirstDoseAppointmentSelected =
    procedureQuestionAndAnswers[0].answerId === '0001A' ||
    procedureQuestionAndAnswers[0].answerId === '0011A' ||
    procedureQuestionAndAnswers[0].answerId === '0021A';

  const getIsSaveButtonDisabled = () => {
    if (isValidDate) {
      if (!procedureQuestionAndAnswers.find((qa) => !qa.answer)) {
        return false;
      }
      return true;
    }
    return true;
  };

  const isSaveButtonDisabled = getIsSaveButtonDisabled();

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        {isEditingProcedure
          ? t('appointmentDetails.recordProcedureModal.editRecordedProcedureTitle')
          : t('appointmentDetails.recordProcedureModal.recordProcedureTitle')}
      </DialogTitle>
      <DialogContent>
        <Box display='grid' gap={theme.spacing(8)}>
          <Box>
            <Typography>{t('appointmentDetails.recordProcedureModal.subtitle')}</Typography>
            <Typography style={{ fontWeight: 'bold' }}>
              {t('appointmentDetails.recordProcedureModal.boldSubtitle')}
            </Typography>
          </Box>
          <Divider />
          <LabelDetail
            label={t('appointmentDetails.recordProcedureModal.serviceLabel')}
            detail={serviceName}
          />
          {createInputsFromProcedureQuestions()}
          {!isEditingProcedure && isFirstDoseAppointmentSelected && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSecondDoseScheduled}
                    onChange={onSecondDoseChecked}
                    color='primary'
                  />
                }
                label={t('appointmentDetails.recordProcedureModal.secondDoseAppointment')}
                id='schedule-second-appointment-checkbox-form-control-label'
              />
              {isSecondDoseScheduled && (
                <Box
                  display='flex'
                  flexWrap='wrap'
                  gap={theme.spacing(8)}
                  id='schedule-second-dose-inputs'
                >
                  <DatePicker
                    label={t('appointmentDetails.recordProcedureModal.startDateLabel')}
                    value={selectedStartDate}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    renderInput={(props) => <TextField {...props} variant='filled' fullWidth />}
                    inputFormat='MM/dd/yyyy'
                    onChange={onStartDateChange}
                  />
                  <DatePicker
                    label={t('appointmentDetails.recordProcedureModal.endDateLabel')}
                    value={selectedEndDate}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    renderInput={(props) => <TextField {...props} variant='filled' fullWidth />}
                    inputFormat='MM/dd/yyyy'
                    onChange={onEndDateChange}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={onSave}
          color='primary'
          variant='contained'
          disabled={isSaveButtonDisabled}
          loading={isLoading}
        >
          {t('appointmentDetails.recordProcedureModal.saveChangesButton')}
        </LoadingButton>
        <Button onClick={onClosePress} color='primary' variant='outlined'>
          {t('appointmentDetails.recordProcedureModal.closeButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
