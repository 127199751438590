// Copyright 2021 Prescryptive Health, Inc.
import { Box, Button } from '@mui/material';
import React, { ReactElement } from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  StyledCopyTextBox,
  StyledCopyText,
} from './invite-user-dialog.styled-components';

export interface ICopyText {
  textToCopy: string;
  buttonLabelText: string;
}

export const CopyText = ({
  textToCopy,
  buttonLabelText,
}: ICopyText): ReactElement => {
  const onCopyPress = async () => {
    await navigator.clipboard.writeText(textToCopy);
  };

  return (
    <StyledCopyTextBox>
      <Box
        display='flex'
        alignItems='center'
        textOverflow='clip'
        overflow='auto'
        whiteSpace='nowrap'
        maxHeight={48}
      >
        <StyledCopyText variant='body2'>{textToCopy}</StyledCopyText>
      </Box>
      <Button
        color='primary'
        startIcon={<FileCopyIcon />}
        onClick={onCopyPress}
      >
        {buttonLabelText}
      </Button>
    </StyledCopyTextBox>
  );
};
