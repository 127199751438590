// Copyright 2021 Prescryptive Health, Inc.

import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { ApiErrors } from '../../../api/errors/api-errors';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { waitlistAdd } from '../../../api/v1/waitlist-add/waitlist-add';
import { waitlistInvite } from '../../../api/v1/waitlist-invite/waitlist-invite';
import { IWaitlistInvitation } from '../../../model/waitlist-invitation';
import { IWaitlistItem } from '../../../model/waitlist-item';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { logApiError } from '../../../api/errors/log-api-error';

export interface IScheduleSecondAppointmentAsyncActionArgs extends IApiAsyncActionArgs {
  locationId: string;
  waitlistItem: IWaitlistItem;
  invitationItem: IWaitlistInvitation;
}

export const scheduleSecondAppointmentAsyncAction = async (
  args: IScheduleSecondAppointmentAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IScheduleSecondAppointmentAsyncActionArgs): Promise<void> => {
  const {
    locationId,
    waitlistItem,
    invitationItem,
    telemetryService,
    configState,
    authProvider,
    getAuthToken,
  } = args;

  try {
    telemetryService.trackEvent('schedule-second-appointment', {
      locationId,
      waitlistItem,
      invitationItem,
    });

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    const responseAdd = await waitlistAdd(
      configState.apis.pharmacyPortal,
      accessToken,
      locationId,
      [waitlistItem]
    );

    if (responseAdd.failed.length > 0) {
      throw new Error(ApiErrors.scheduleSecondAppointment(responseAdd.failed[0].reason));
    }

    const responseInvite = await waitlistInvite(
      configState.apis.pharmacyPortal,
      accessToken,
      locationId,
      [invitationItem]
    );

    if (responseInvite.failures.length > 0) {
      throw new Error(ApiErrors.scheduleSecondAppointment(responseInvite.failures[0].reason));
    }
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
    return Promise.reject();
  }
};
