// Copyright 2021 Prescryptive Health, Inc.

import { useBusyContext } from '../../hooks/use-busy-context/use-busy-context.hook';
import { useErrorContext } from '../../hooks/use-error-context/use-error-context.hook';
import React, { FunctionComponent, useEffect, useReducer } from 'react';
import { useConfigContext } from '../../hooks/use-config-context/use-config-context.hook';
import { useSessionContext } from '../../providers/session/use-session-context.hook';
import { useTelemetryContext } from '../../providers/telemetry/use-telemetry-context.hook';
import { IStoreLocation } from '../../model/store-location';
import {
  IPharmacyServicesGetAsyncActionArgs,
  pharmacyServicesGetAsyncAction,
} from '../../state/location/async-actions/pharmacy-services-get.async-action';
import { locationClearDispatch } from '../../state/location/dispatch/location-clear.dispatch';
import { locationReducer, LocationReducer } from '../../state/location/location.reducer';
import { defaultLocationState } from '../../state/location/location.state';
import { LocationContext } from './location.context';
import { getToken } from '../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const LocationContextProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer<LocationReducer>(locationReducer, defaultLocationState);

  const { errorDispatch } = useErrorContext();
  const { configState } = useConfigContext();
  const { busyDispatch } = useBusyContext();
  const { telemetryService } = useTelemetryContext();
  const { sessionState } = useSessionContext();

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  useEffect(() => {
    if (!state.location) {
      locationClearDispatch(dispatch);
      return;
    }

    const locationServicesGet = async (location: IStoreLocation) => {
      const actionArgs: IPharmacyServicesGetAsyncActionArgs = {
        busyDispatch,
        errorDispatch,
        telemetryService,
        configState,
        authProvider: sessionState.authProvider,
        locationDispatch: dispatch,
        location,
        getAuthToken: getToken(auth0Migration, getAccessTokenSilently)
      };
      return await pharmacyServicesGetAsyncAction(actionArgs);
    };

    void locationServicesGet(state.location);
  }, [state.location, state.areServicesStale]);

  useEffect(() => {
    if (window.heap) {
      window.heap.addUserProperties({ Location: state.location?.id });
    }
  }, [state.location]);

  return (
    <LocationContext.Provider
      value={{
        locationState: state,
        locationDispatch: dispatch,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
