// Copyright 2021 Prescryptive Health, Inc.
import { ThemeOptions, createTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    blueScale: {
      '50': '#388BC8';
      '100': '#2196F3';
      '200': '#2EB7E2';
      '300': '#324A75';
    };
    purpleScale: {
      '300': '#6340A3';
    };
  }
  interface PaletteOptions {
    blueScale: {
      '50': '#388BC8';
      '100': '#2196F3';
      '200': '#2EB7E2';
      '300': '#324A75';
    };
    purpleScale: {
      '300': '#6340A3';
    };
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    phone: true;
    tablet: true;
    desktop: true;
  }
}

const scalars = createTheme({
  spacing: (factor: number) => `${factor * 4}px`,
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: 'roboto-regular, sans-serif',
    h1: {
      fontSize: 28,
      fontFamily: 'roboto-bold, sans-serif',
      lineHeight: 1.5,
      fontWeight: 'bold',
    },
    h2: {
      fontSize: 22,
      fontFamily: 'roboto-bold, sans-serif',
      fontWeight: 'bold',
      lineHeight: 1.5,
    },
    h3: {
      fontSize: 18,
      fontFamily: 'roboto-bold, sans-serif',
      fontWeight: 'bold',
      lineHeight: 1.5,
    },
  },
  palette: {
    common: {
      black: '#131320',
      white: '#FFFFFF',
    },
    text: {
      primary: '#0B0B0F',
      secondary: '#4A4D5C',
      disabled: '#B2B5C1',
    },
    primary: {
      light: '#A5C7E5',
      main: '#1C52A9',
      dark: '#000958',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#FF737F',
      main: '#FF505F',
      dark: '#B23842',
      contrastText: '#131320',
    },
    grey: {
      '50': '#F6F7F8',
      '100': '#D0D1DA',
      '200': '#B2B5C1',
      '300': '#9698A8',
      '400': '#7A7D8E',
      '500': '#606374',
      '600': '#4A4D5C',
      '700': '#343643',
      '800': '#1F2129',
      '900': '#0B0B0F',
      A100: '#EDF5F7',
      A200: '#D2E8EE',
      A400: '#80DDF8',
      A700: '#1E9ACC',
    },
    blueScale: {
      '50': '#388BC8',
      '100': '#2196F3',
      '200': '#2EB7E2',
      '300': '#324A75',
    },
    purpleScale: {
      '300': '#6340A3',
    },
    success: {
      main: '#4CAF50',
    },
    error: {
      main: '#AA241E',
      dark: '#801B1E',
    },
  },
  breakpoints: {
    values: {
      xs: 360,
      sm: 360,
      phone: 360,
      md: 600,
      tablet: 600,
      lg: 900,
      desktop: 900,
      xl: 1440,
    },
  },
});

const componentOverrides: ThemeOptions = {
  components: {
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          marginBottom: scalars.spacing(2),
          '&::before': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiDialog: {
      defaultProps: { fullWidth: true, maxWidth: 'md' },
    },
    MuiAccordionSummary: {
      defaultProps: { expandIcon: <ExpandMoreIcon color='primary' /> },
      styleOverrides: {
        root: {
          backgroundColor: scalars.palette.grey.A100,
          padding: scalars.spacing(5),
          minHeight: 0,
        },
        content: {
          margin: 0,
        },
        expandIconWrapper: {
          padding: 0,
          marginRight: 0,
          marginLeft: scalars.spacing(2),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: scalars.spacing(2),
          borderRadius: 4,
          minWidth: 95,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid ${scalars.palette.grey.A700}`,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: 'roboto-bold, sans-serif',
          fontWeight: '600',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: scalars.spacing(4),
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: scalars.palette.grey[300],
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: 292,
          backgroundColor: scalars.palette.grey[50],
        },
      },
    },
  },
};
export const theme = createTheme(scalars, componentOverrides);
