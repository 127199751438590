// Copyright 2021 Prescryptive Health, Inc.

import { Box, Theme, Typography, TypographyProps } from '@mui/material';
import { styled as muiStyled } from '@mui/material';
import styled from 'styled-components';

interface ErrorMessageTypographyProps extends TypographyProps {
  $hasErrorMessage: boolean;
}

export const StyledBlockTimeSectionContainer = muiStyled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(12),
}));

export const ErrorMessageTypography = styled(Typography)<ErrorMessageTypographyProps>`
  display: ${(props: { $hasErrorMessage: boolean }) =>
    props.$hasErrorMessage ? 'inherit' : 'none'};
  color: ${({ theme }: { theme: Theme }) => theme.palette.error.main};
`;
