// Copyright 2021 Prescryptive Health, Inc.

import React, { useState, FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';
import { StyledThemeContext, IStyledThemeContext } from './styled-theme.context';
import { theme } from './theme';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';

export type ThemeMode = 'default' | 'dark';

export const StyledThemeContextProvider: FunctionComponent = ({ children }) => {
  const [themeMode, setThemeMode] = useState<ThemeMode>('default');

  const themeContext: IStyledThemeContext = {
    themeMode,
    setThemeMode,
  };

  return (
    <StyledThemeContext.Provider value={themeContext}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </StyledThemeContext.Provider>
  );
};
