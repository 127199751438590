// Copyright 2021 Prescryptive Health, Inc.

import { Reducer } from 'react';
import { ReportsAction } from './actions/reports.action';
import { IReportsState } from './reports.state';

export type ReportsReducer = Reducer<IReportsState, ReportsAction>;

export const reportsReducer: ReportsReducer = (
  state: IReportsState,
  action: ReportsAction
): IReportsState => {
  const payload = action.payload;
  return { ...state, ...payload };
};
