import { gql } from '@apollo/client';
import { IAddress } from '../../../model/address';
import { IClaimInformation } from '../../../model/claim-information';
import { IPatientRxInfo } from '../../../model/patient-rx-info';
import { IProcedureQuestion, IProcedureResult } from '../../../model/procedure-question';
import { IQuestion } from '../../../model/question';

export const APPOINTMENT_DETAILS_QUERY = gql`
  query getAppointmentDetails($id: ID, $providerId: ID) {
    appointment(id: $id) {
      id
      bookingStatus
      startDateISO
      duration
      orderNumber
      claim {
        id
        authorizationNumber
        prescriberNationalProviderId
        processedDate
        productOrServiceId
        providerLegalName
      }
      patient {
        id
        firstName
        lastName
        phoneNumber
        dateOfBirth
        email
        address {
          line1
          line2
          city
          county
          state
          zip
        }
        personCode
        memberId
        rx {
          group
          bin
          pcn
        }
      }
      service {
        serviceType
      }
      location {
        id
        name
        phone
        address {
          line1
          line2
          city
          state
          zip
        }
      }
      questions {
        questionId
        text
        answer
      }
      procedure {
        questions {
          procedureQuestionId
          text
          type
          placeholder
          options {
            id
            text
            groupId
          }
        }
        results {
          procedureResultId
          text
          answerId
          answerText
          type
        }
      }
    }
    provider(id: $providerId) {
      cliaNumber
    }
  }
`;

export interface IAppointmentDetailsResponse {
  appointment: IAppointment;
  provider: { cliaNumber: string };
}

export interface IAppointment {
  id: string;
  bookingStatus: string;
  orderNumber: string;
  duration: number;
  startDateISO: string;
  claim?: IClaimInformation;
  patient: {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    dateOfBirth: string;
    email: string;
    address: IAddress;
    personCode: string;
    memberId: string;
    rx: IPatientRxInfo;
  };
  service: {
    serviceType: string;
  };
  location: {
    id: string;
    name: string;
    phone: string;
    address: IAddress;
  };
  questions: IQuestion[];
  procedure: {
    questions: IProcedureQuestion[];
    results: IProcedureResult[];
  };
}
