// Copyright 2020 Prescryptive Health, Inc.

import { AlertColor } from '@mui/material';

export interface INotificationState {
  readonly message?: string;
  readonly severity?: AlertColor;
}

export const defaultNotificationState: INotificationState = {};
