// Copyright 2020 Prescryptive Health, Inc.

import { IAllowedAccessResource } from '../../../api/v1/user-access-get/user-access-get.response';
import { userAccessSetAction } from '../actions/user-access-set.action';
import { PostAuthDispatch } from './post-auth.dispatch';

export const userAccessSetDispatch = (
  dispatch: PostAuthDispatch,
  userAccess: IAllowedAccessResource[]
): void => {
  dispatch(userAccessSetAction(userAccess));
};
