// Copyright 2020 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import icoMoonConfig from './selection.json';
import IcoMoon from 'react-icomoon';

interface IPrescryptiveIconProps {
  icon: string;
  color?: string;
  size?: string | number;
  className?: string;
  style?: React.CSSProperties;
}

export const PrescryptiveIcon = (props: IPrescryptiveIconProps): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  return <IcoMoon iconSet={icoMoonConfig} {...props} />;
};
