// Copyright 2021 Prescryptive Health, Inc.

import { IProviderAction } from './provider.action';

export type IProviderDetailsUpdateAction = IProviderAction<'DETAILS_UPDATE'>;

export const providerDetailsUpdateAction = (
  name: string,
  phoneNumber: string,
  email: string,
  cliaNumber: string,
): IProviderDetailsUpdateAction => ({
  type: 'DETAILS_UPDATE',
  payload: { name, phoneNumber, email, cliaNumber },
});
