// Copyright 2021 Prescryptive Health, Inc.

import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { ProviderDispatch } from '../dispatch/provider.dispatch';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { providerDetailsGet } from '../../../api/v1/provider-details-get/provider-details-get';
import { providerDetailsSetDispatch } from '../dispatch/provider-details-set.dispatch';
import { logApiError } from '../../../api/errors/log-api-error';

export interface IProviderDetailsGetAsyncActionArgs extends IApiAsyncActionArgs {
  providerDispatch: ProviderDispatch;
  providerId: string;
}

export const providerDetailsGetAsyncAction = async (
  args: IProviderDetailsGetAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IProviderDetailsGetAsyncActionArgs): Promise<void> => {
  const {
    configState,
    authProvider,
    providerDispatch,
    providerId,
    telemetryService,
    getAuthToken,
  } = args;

  try {
    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    telemetryService.trackEvent('provider-details-get', { providerId });

    const result = await providerDetailsGet(
      configState.apis.pharmacyPortal,
      accessToken,
      providerId
    );
    const { currentProvider, locations, enrolledServiceTypes, enrolledPrograms } = result;

    providerDetailsSetDispatch(
      providerDispatch,
      currentProvider,
      locations,
      enrolledServiceTypes,
      enrolledPrograms
    );
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
  }
};
