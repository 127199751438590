// Copyright 2021 Prescryptive Health, Inc.

import { usePostAuthContext } from '../../providers/post-auth/use-post-auth-context.hook';

const getTierSupportLevel = (): number | null => {
  const {
    authState: { userAccess },
  } = usePostAuthContext();
  const tierNSupportNameRegex = new RegExp('Tier .* Support');

  const userAccessRules = userAccess?.rules.find(
    (e) => e.type === 'role' && tierNSupportNameRegex.test(e.name)
  );

  if (userAccessRules) {
    return parseInt(userAccessRules.name.replace(/\D/g, ''), 10);
  } else {
    return null;
  }
};

export { getTierSupportLevel };
