// Copyright 2020 Prescryptive Health, Inc.

import { IContentMap } from '../../providers/locale/get-content';
import { IConfigState } from '../../state/config/config.state';
import { useErrorContext } from '../../hooks/use-error-context/use-error-context.hook';
import { ErrorDispatch } from '../../state/error/dispatch/error.dispatch';
import { useConfigContext } from '../use-config-context/use-config-context.hook';
import { BusyDispatch } from '../../state/busy/dispatch/busy.dispatch';
import { useBusyContext } from '../../hooks/use-busy-context/use-busy-context.hook';
import { IBusyState } from '../../state/busy/busy.state';
import { TelemetryEvent } from '../../providers/telemetry/telemetry.context';
import { useTelemetryContext } from '../../providers/telemetry/use-telemetry-context.hook';
import { useSessionContext } from '../../providers/session/use-session-context.hook';
import { useCurrentContent } from '../use-current-content/use-current-content.hook';
import { AuthenticationProvider } from '../../state/authentication/authentication-provider';
import { TelemetryService } from '../../providers/telemetry/telemetry.service';

export interface IScreenContext<TContent> {
  authProvider?: AuthenticationProvider;
  busyDispatch: BusyDispatch;
  busyState: IBusyState;
  configState: IConfigState;
  content: TContent;
  errorDispatch: ErrorDispatch;
  telemetryService: TelemetryService<TelemetryEvent>;
}

export function useScreenContext<TContent>(
  contentMap: IContentMap<TContent>
): IScreenContext<TContent> {
  const { configState } = useConfigContext();
  const { content } = useCurrentContent<TContent>(contentMap);
  const { busyDispatch, busyState } = useBusyContext();
  const { errorDispatch } = useErrorContext();
  const { telemetryService } = useTelemetryContext();
  const { sessionState } = useSessionContext();

  return {
    authProvider: sessionState.authProvider,
    busyDispatch,
    busyState,
    configState,
    content,
    errorDispatch,
    telemetryService,
  };
}
