// Copyright 2021 Prescryptive Health, Inc.
import { locationDeleteAction } from '../actions/store-location-delete.action';
import { ProviderDispatch } from './provider.dispatch';

export const locationDeleteDispatch = (
  dispatch: ProviderDispatch,
  locationId: string
): void => {
  dispatch(locationDeleteAction(locationId));
};
