// Copyright 2021 Prescryptive Health, Inc.
import { useEffect, ReactElement } from 'react';
import {
  AppBar,
  Divider,
  ListItemText,
  Box,
  useTheme,
  Typography,
  Theme,
  ListItemButton,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PaidIcon from '@mui/icons-material/Paid';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SettingsIcon from '@mui/icons-material/Settings';
import EventIcon from '@mui/icons-material/Event';
import PaymentIcon from '@mui/icons-material/Payment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { topBarHeight } from '../styles';
import { NavLink } from './nav-link';
import { Settings } from 'luxon';
import { StoreLocationPicker } from '../../../../components/pickers/store-location/store-location.picker';
import { useProviderContext } from '../../../../providers/provider/use-provider-context.hook';
import { useLocationContext } from '../../../../providers/location/use-location-context.hook';
import { useSessionContext } from '../../../../providers/session/use-session-context.hook';
import { currentProviderSetDispatch } from '../../../../state/provider/dispatch/current-provider-set.dispatch';
import { locationClearDispatch } from '../../../../state/location/dispatch/location-clear.dispatch';
import { locationSetDispatch } from '../../../../state/location/dispatch/location-set.dispatch';
import { PortalTopBar } from '../../../../components/portal-top-bar/portal-top-bar';
import { getCountryCityTimezone } from '../../../../helpers/dates/date-helper/date.helper';
import { authenticatedRouteConfig } from '../../../authenticated-route-config';
import { localStorageKeys } from '../../../../constants/local-storage-keys';
import { usePostAuthContext } from '../../../../providers/post-auth/use-post-auth-context.hook';
import { useTelemetryContext } from '../../../../providers/telemetry/use-telemetry-context.hook';
import {
  ProviderPicker,
  ProviderPickerOption,
} from '../../../../components/pickers/provider/provider.picker';
import {
  DrawerContentsContainer,
  NavItemIcon,
  NavLinksList,
  NavItem,
  Nav,
} from './post-auth-drawer.styled-components';
import { PrescryptiveIcon } from '../../../../components/prescryptive-icons/prescryptive-icon';
import { useEventListener } from '../../../../hooks/use-event-listener/use-event-listener';
import { onLogoutEventHandler } from '../../../../event-handlers/logout-event-handler';
import { PrescryptiveProLogo } from '../../../../components/prescryptivepro-logo/prescryptivepro-logo';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
  isMobileOpen: boolean;
  onHandleDrawerToggle: () => void;
  closeDrawer: () => void;
  canAccessAdminScreens: boolean;
  canAccessUserScreens: boolean;
}

export default function PostAuthDrawer({
  isMobileOpen,
  onHandleDrawerToggle,
  closeDrawer,
  canAccessAdminScreens,
  canAccessUserScreens,
}: Props): ReactElement {
  const theme = useTheme();
  const { authState } = usePostAuthContext();
  const { telemetryService } = useTelemetryContext();
  const { sessionState } = useSessionContext();
  const { logout } = useAuth0();
  const {
    providerDispatch,
    providerState: { currentProvider, locations },
  } = useProviderContext();
  const {
    locationDispatch,
    locationState: { location, services, locationSelectedDate },
  } = useLocationContext();
  const { pharmacySettingsPageWave1, auth0Migration, clinicalServicesDisabled } = useFlags();

  const isDesktopUp = useMediaQuery((t: Theme) => t.breakpoints.up('desktop'));
  const providersAvailableForUsers: ProviderPickerOption[] =
    authState.userAccess?.rules
      ?.filter(
        (provider, i, a) =>
          provider.type === 'provider' && a.findIndex((o) => o.name === provider.name) === i
      )
      .map((o) => {
        return { title: o.name };
      }) ?? [];

  const selectLocationId = location?.id ?? '';

  useEffect(() => {
    if (currentProvider) {
      if (locations.length === 1) {
        onLocationSelect(locations[0].id);
        return;
      }
      const localStorageLocationId = localStorage.getItem(localStorageKeys.currentLocation);
      onLocationSelect(localStorageLocationId ?? '');
    }
  }, [currentProvider, locations]);

  const onSelectProvider = (name: string) => {
    const provider = authState.userAccess?.rules?.find((x) => x.name === name);
    if (provider) {
      currentProviderSetDispatch(providerDispatch, {
        name,
      });
      closeDrawer();
      localStorage.setItem(localStorageKeys.currentProvider, name);
    }
  };

  const onLocationSelect = (locationId: string) => {
    if (locationId === '') {
      locationClearDispatch(locationDispatch);
    }

    const matchedLocation = locations.find((locationToMatch) => locationToMatch.id === locationId);

    if (!matchedLocation) {
      return;
    }

    localStorage.setItem(localStorageKeys.currentLocation, locationId);
    Settings.defaultZone = getCountryCityTimezone(matchedLocation.timezone);

    locationSetDispatch(locationDispatch, matchedLocation, services, locationSelectedDate);
    closeDrawer();
    telemetryService.trackEvent('click', {
      name: 'nav-location-change-dropdown',
      locationId,
      provider: currentProvider?.name,
    });
  };

  useEventListener(
    'storage',
    onLogoutEventHandler(logout, sessionState?.authProvider, auth0Migration)
  );

  const onSignoutPress = () => {
    if (auth0Migration) {
      logout({ returnTo: window.location.origin, federated: true });
      return;
    }
    if (sessionState && sessionState.authProvider) {
      sessionState.authProvider.logout();
    }
  };

  return (
    <>
      {!isDesktopUp && (
        <>
          <AppBar>
            <PortalTopBar onMenuClick={onHandleDrawerToggle} />
          </AppBar>
          <Box id='app-bar-spacer' height={topBarHeight} width={1} />
        </>
      )}
      <Nav aria-label='nav items'>
        <Drawer
          variant={isDesktopUp ? 'permanent' : 'temporary'}
          open={isDesktopUp ? true : isMobileOpen}
          onClose={isDesktopUp ? undefined : closeDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerContentsContainer>
            <Box display='grid' gap={theme.spacing(4)} alignContent='center'>
              <Box display='flex' justifyContent='center' alignItems='center' py={theme.spacing(2)}>
                <PrescryptiveProLogo />
              </Box>
              <ProviderPicker
                providers={providersAvailableForUsers || []}
                selectedProvider={currentProvider?.name}
                onSelect={onSelectProvider}
              />
              <StoreLocationPicker
                locations={locations}
                selectedLocationId={selectLocationId}
                onSelect={onLocationSelect}
              />
              {!clinicalServicesDisabled && (
                <NavLinksList>
                  <NavLink
                    key={authenticatedRouteConfig.appointments.route}
                    route={authenticatedRouteConfig.appointments.route}
                    onClick={closeDrawer}
                    Icon={<EventIcon />}
                    linkText={'Appointments'}
                  />
                  <NavLink
                    key={authenticatedRouteConfig.serviceHours.route}
                    route={authenticatedRouteConfig.serviceHours.route}
                    onClick={closeDrawer}
                    Icon={<WatchLaterIcon />}
                    linkText={'Service Hours'}
                  />
                  {canAccessAdminScreens && (
                    <NavLink
                      key={authenticatedRouteConfig.myServices.route}
                      route={authenticatedRouteConfig.myServices.route}
                      onClick={closeDrawer}
                      Icon={<AssignmentIcon />}
                      linkText={'My Services'}
                    />
                  )}
                  {canAccessAdminScreens && (
                    <NavLink
                      key={authenticatedRouteConfig.paymentsBilling.route}
                      route={authenticatedRouteConfig.paymentsBilling.route}
                      onClick={closeDrawer}
                      Icon={<PaymentIcon />}
                      linkText={'Billing and Payments'}
                    />
                  )}
                  {canAccessUserScreens && (
                    <NavLink
                      key={authenticatedRouteConfig.waitlist.route}
                      route={authenticatedRouteConfig.waitlist.route}
                      onClick={closeDrawer}
                      Icon={<AssignmentIndIcon />}
                      linkText={'Waitlist'}
                    />
                  )}
                </NavLinksList>
              )}
              {!clinicalServicesDisabled && canAccessAdminScreens && (
                <Divider
                  id='provider-section-divider'
                  variant='fullWidth'
                  style={{
                    marginTop: theme.spacing(3),
                    marginBottom: theme.spacing(3),
                  }}
                />
              )}
              <NavLinksList>
                {canAccessAdminScreens && (
                  <NavLink
                    key={authenticatedRouteConfig.reports.route}
                    route={authenticatedRouteConfig.reports.route}
                    onClick={closeDrawer}
                    Icon={<AssessmentIcon />}
                    linkText={'Reports'}
                  />
                )}
                {canAccessAdminScreens && (
                  <NavLink
                    key={authenticatedRouteConfig.pricing.route}
                    route={authenticatedRouteConfig.pricing.route}
                    onClick={closeDrawer}
                    Icon={<PaidIcon />}
                    linkText={'Pricing'}
                  />
                )}
                {canAccessAdminScreens && (
                  <NavLink
                    key={authenticatedRouteConfig.addServices.route}
                    route={authenticatedRouteConfig.addServices.route}
                    onClick={closeDrawer}
                    Icon={<AssignmentIcon />}
                    linkText={'Add Services'}
                  />
                )}
                {pharmacySettingsPageWave1 && canAccessAdminScreens && (
                  <NavLink
                    key={authenticatedRouteConfig.settings.route}
                    route={authenticatedRouteConfig.settings.route}
                    onClick={closeDrawer}
                    Icon={<SettingsIcon />}
                    linkText={'Settings'}
                  />
                )}
              </NavLinksList>
            </Box>
            <NavLinksList>
              <NavItem onClick={onSignoutPress} id='sign-out-button'>
                <ListItemButton>
                  <NavItemIcon>
                    <PrescryptiveIcon icon='exit' size={24} color={theme.palette.text.secondary} />
                  </NavItemIcon>
                  <ListItemText
                    disableTypography={true}
                    primary={
                      <Typography variant='h3' component='span'>
                        Sign Out
                      </Typography>
                    }
                  />
                </ListItemButton>
              </NavItem>
            </NavLinksList>
          </DrawerContentsContainer>
        </Drawer>
      </Nav>
    </>
  );
}
