// Copyright 2020 Prescryptive Health, Inc.

import { HttpStatusCodesEnum } from '../../http-status-codes';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { call } from '../../call';
import { ApiErrors } from '../../errors/api-errors';
import { IAllowedAccessResource } from './user-access-get.response';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export async function userAccessGet(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string
): Promise<IAllowedAccessResource[]> {
  const url = buildUrl(apiConfig, 'userAccess', {});
  const headers = buildBearerAuthHeaders(authToken);

  const response: Response = await call(url, undefined, 'GET', headers);
  if (response.ok) {
    if (response.status === HttpStatusCodesEnum.NO_CONTENT) {
      return [];
    }

    return await userAccessResultFromResponse(response);
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.USER_ACCESS_GET,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  throw new PharmacyPortalError(
    ApiErrors.userAccessGet(response.statusText),
    ApiErrorCode.USER_ACCESS_GET,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}

async function userAccessResultFromResponse(response: Response): Promise<IAllowedAccessResource[]> {
  const responseData = (await response.json()) as IAllowedAccessResource[];
  if (!responseData) {
    throw new PharmacyPortalError(
      ApiErrors.userAccessGet(ApiErrors.unexpectedResponseDataFormat),
      ApiErrorCode.USER_ACCESS_GET,
      PharmacyPortalErrorType.API_ERROR,
      response.status
    );
  }

  return responseData;
}
