// Copyright 2021 Prescryptive Health, Inc.
import {
  AuthenticationParameters,
  AuthError,
  AuthResponse,
  Configuration,
  UserAgentApplication,
  Account,
} from 'msal';
import { localStorageKeys } from '../../constants/local-storage-keys';

export class AuthenticationProvider {
  public static isAdmin(response: AuthResponse): boolean {
    return AuthenticationProvider.hasRole(response, 'Admin');
  }

  public static hasRole(response: AuthResponse, role: string): boolean {
    const roles = response.idTokenClaims.roles || '';
    return roles.includes(role);
  }

  private static requiresInteraction(errorCode: string) {
    if (!errorCode) {
      return false;
    }

    return (
      errorCode.indexOf('consent_required') > -1 ||
      errorCode.indexOf('interaction_required') > -1 ||
      errorCode.indexOf('login_required') > -1
    );
  }

  private static acquireTokenInProgress(errorCode: string) {
    if (!errorCode) {
      return false;
    }

    return errorCode.indexOf('acquiretoken_progress_error') > -1;
  }

  private static getAuthenticationParameters(clientId: string): AuthenticationParameters {
    return {
      redirectUri: window.location.origin,
      scopes: [`api://${clientId}/access_usermanagement`],
    };
  }

  private app: UserAgentApplication;
  private clientId: string;

  constructor(authorityUrl: string, clientId: string, isIE = false) {
    const config: Configuration = {
      auth: {
        authority: authorityUrl,
        clientId,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: isIE,
      },
    };

    this.app = new UserAgentApplication(config);
    this.clientId = clientId;
  }

  public async login(): Promise<AuthResponse> {
    const request = AuthenticationProvider.getAuthenticationParameters(this.clientId);

    return this.app.loginPopup(request);
  }

  public async acquireToken(): Promise<AuthResponse> {
    const request = AuthenticationProvider.getAuthenticationParameters(this.clientId);

    try {
      return await this.app.acquireTokenSilent(request);
    } catch (ex) {
      if (ex instanceof AuthError && AuthenticationProvider.requiresInteraction(ex.errorCode)) {
        try {
          void this.app.acquireTokenRedirect(request);
          return await this.app.acquireTokenPopup(request);
        } catch (ex1) {
          if (
            ex1 instanceof AuthError &&
            AuthenticationProvider.acquireTokenInProgress(ex1.errorCode)
          ) {
            // eslint-disable-next-line no-constant-condition
            while (true) {
              await new Promise((resolve) => setTimeout(resolve, 100));
              try {
                return await this.app.acquireTokenSilent(request);
              } catch (_) {
                continue;
              }
            }
          } else {
            throw ex1;
          }
        }
      } else {
        throw ex;
      }
    }
  }

  public logout = (): void => {
    this.app.logout();
    localStorage.setItem(localStorageKeys.isLogout, 'true');
  };

  public getAccountName(): string {
    const account = this.app.getAccount();
    return account ? account.name : '';
  }

  public getAccount(): Account {
    return this.app.getAccount();
  }

  public isLoggedIn(): boolean {
    return !!this.app.getAccount();
  }

  public getRoles(): string {
    const account = this.app.getAccount();
    return account ? account.idTokenClaims.roles : '';
  }
}
