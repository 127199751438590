// Copyright 2021 Prescryptive Health, Inc.
import { locationIsTestUpdateAction } from '../actions/store-location-is-test-update.action';
import { ProviderDispatch } from './provider.dispatch';

export const locationIsTestUpdateDispatch = (
  dispatch: ProviderDispatch,
  isTest: boolean,
  locationId: string
): void => {
  dispatch(locationIsTestUpdateAction(locationId, isTest));
};
