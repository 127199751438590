// Copyright 2021 Prescryptive Health, Inc.

import { Box, Button } from '@mui/material';
import { styled as muiStyled } from '@mui/material';

export const StyledViewAllButton = muiStyled(Button)(({ theme }) => ({
  background: theme.palette.grey[50],
  color: theme.palette.primary.main,
  borderRadius: theme.spacing(1),
  borderColor: theme.palette.primary.main,
  borderStyle: 'solid',
  border: theme.palette.primary.main,
  borderWidth: '1px',
  marginTop: theme.spacing(8),
}));

export const StyledFlexBox = muiStyled(Box)(({ theme }) => ({
  [theme.breakpoints.up('tablet')]: {
    flex: '45%',
    marginRight: theme.spacing(8),
  },
}));

export const StyledGrayBox = muiStyled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  paddingRight: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(6),
}));
