// Copyright 2021 Prescryptive Health, Inc.

import { Box } from '@mui/material';
import React, { ReactElement } from 'react';

interface ILandingSectionContainerProps {
  children: ReactElement | ReactElement[];
  backgroundColor?: string;
}

export const LandingSectionContainer = ({
  children,
  backgroundColor,
}: ILandingSectionContainerProps): ReactElement => {
  return (
    <Box
      style={{
        backgroundColor,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box maxWidth='1200px' flex={1}>
        {children}
      </Box>
    </Box>
  );
};
