// Copyright 2021 Prescryptive Health, Inc.

import { Box, Typography, TypographyProps, useTheme } from '@mui/material';
import React, { ReactElement, ReactNode } from 'react';
import { InlineEditButtonBar } from '../../button-bars/inline-edit/inline-edit.button-bar';

export interface IEditableSectionProps {
  heading: string;
  description?: ReactNode;
  isEditMode?: boolean;
  isEditDisabled?: boolean;
  onEditPress: () => void;
  onCancelPress: () => void;
  onSavePress: () => void;
  isSaveDisabled?: boolean;
  children: ReactNode;
  headingProps?: TypographyProps;
}

export const EditableSection = ({
  heading,
  description = null,
  isEditMode,
  isEditDisabled,
  onEditPress,
  onCancelPress,
  onSavePress,
  isSaveDisabled,
  children,
  headingProps = { variant: 'h3', component: 'h3' } as TypographyProps,
}: IEditableSectionProps): ReactElement => {
  const theme = useTheme();
  return (
    <Box component='section'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box display='flex' flexDirection='column' gap={theme.spacing(6)}>
          <Typography {...headingProps}>{heading}</Typography>
          {description}
        </Box>
        <InlineEditButtonBar
          isEditMode={isEditMode}
          isEditDisabled={isEditDisabled}
          onEditPress={onEditPress}
          onCancelPress={onCancelPress}
          onSavePress={onSavePress}
          isSaveDisabled={isSaveDisabled}
        />
      </Box>
      <Box display='flex' marginTop={theme.spacing(6)}>
        {children}
      </Box>
    </Box>
  );
};
