// Copyright 2021 Prescryptive Health, Inc.

import { Box, BoxProps, Theme } from '@mui/material';
import styled from 'styled-components';

export const StyledSecondaryHeaderContainer = styled(Box)<BoxProps>`
  display: flex;
  flex-direction: row;
  background-color: ${(props: { theme: Theme }) => props.theme.palette.grey[50]};
  justify-content: flex-start;
  align-items: center;
  height: ${(props: { theme: Theme }) => props.theme.spacing(22.5)};
  padding-left: ${(props: { theme: Theme }) => props.theme.spacing(8)};
`;
