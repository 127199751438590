// Copyright 2021 Prescryptive Health, Inc.

import { Box, styled, Typography } from '@mui/material';

export const StyledSingleColumnContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  flexWrap: 'wrap',
  padding: theme.spacing(6),
  [theme.breakpoints.up('tablet')]: {
    padding: theme.spacing(12),
  },
}));

export const StyledSingleColumnHeaderTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(8),
}));

export const StyledSingleColumnDescriptionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  marginBottom: theme.spacing(8),
}));
