import { FunctionComponent, useReducer, useEffect, useCallback, useContext } from 'react';
import { ThemeContext } from '../theme/theme.context';
import { SessionContext } from './session.context';
import { sessionReducer, SessionReducer } from '../../state/session/session.reducer';
import { ISessionState, SessionPhase } from '../../state/session/session.state';
import { sessionLoadedDispatch } from '../../state/session/dispatch/session-loaded.dispatch';
import { sessionLoggedInDispatch } from '../../state/session/dispatch/session-logged-in.dispatch';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { Account } from 'msal';
import { useTelemetryContext } from '../telemetry/use-telemetry-context.hook';
import { authenticationProvider } from '../../authentication-initialize';
import { useAuth0, User } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';
import { LDClient } from 'launchdarkly-js-client-sdk';

const initialState: ISessionState = {
  phase: SessionPhase.preSession,
};

export const SessionContextProvider: FunctionComponent = ({ children }) => {
  const { setThemeName } = useContext(ThemeContext);
  const { telemetryService } = useTelemetryContext();
  const { auth0Migration } = useFlags();
  const { isAuthenticated, user: auth0User } = useAuth0();

  const [sessionState, initialDispatch] = useReducer<SessionReducer>(sessionReducer, initialState);
  const sessionDispatch = useCallback(initialDispatch, []);
  const aadAccount = sessionState.authProvider?.getAccount();
  const ldClient = useLDClient();

  const loader = useCallback(async () => {
    const isLoggedIn = auth0Migration ? isAuthenticated : authenticationProvider.isLoggedIn();
    if (isLoggedIn) {
      sessionLoggedInDispatch(sessionDispatch, authenticationProvider);
    } else {
      setTimeout(() => sessionLoadedDispatch(sessionDispatch, authenticationProvider), 2000);
    }
  }, []);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!ldClient) {
      return;
    }
    const customLdId = searchParams.get('LD_BETA_USER') === 'true';
    if (customLdId) {
      void ldClient.identify(createLdBetaUser());
      return;
    }
    if (auth0Migration) {
      if (!auth0User) {
        return;
      }
      const ldUser = createLDUser(auth0User);
      const previousUser = ldClient.getUser();
      void ldClient.identify(ldUser).then(() => {
        if (!ldUser.anonymous) {
          if (ldUser.key !== previousUser.key) {
            ldClient.alias(ldUser, previousUser);
          }
          aliasLinkedAccounts(auth0User, ldUser, ldClient);
        }
      });
    } else {
      if (!aadAccount) {
        return;
      }

      const aadUser = createLDUserAAD(aadAccount);
      const previousUser = ldClient.getUser();
      void ldClient.identify(aadUser).then(() => {
        if (!aadUser.anonymous) {
          ldClient.alias(aadUser, previousUser);
        }
      });
    }
  }, [ldClient, aadAccount, auth0Migration, auth0User]);

  useEffect(() => {
    if (auth0Migration) {
      if (window.heap && auth0User) {
        window.heap.identify(auth0User.email ?? '');
        window.heap.addUserProperties({ Name: auth0User.name });
      }
    } else {
      if (window.heap && aadAccount) {
        window.heap.identify(aadAccount.userName);
        window.heap.addUserProperties({ Name: aadAccount.name });
      }
    }
  }, [auth0User, aadAccount, auth0Migration]);

  useEffect(() => {
    if (!telemetryService) {
      return;
    }
    if (auth0Migration) {
      if (!auth0User) {
        return;
      }

      telemetryService.appInsights.setAuthenticatedUserContext(
        auth0User?.email ?? '',
        auth0User?.email ?? '',
        true
      );
      return;
    } else {
      if (!aadAccount) {
        return;
      }
      telemetryService.appInsights.setAuthenticatedUserContext(
        aadAccount.accountIdentifier,
        aadAccount.accountIdentifier,
        true
      );
    }
  }, [telemetryService, aadAccount, auth0User, auth0Migration]);

  useEffect(() => {
    if (sessionState.phase === SessionPhase.preSession) {
      setThemeName('pharmacy-portal');
      void loader();
    }
  }, [sessionState.phase]);

  return (
    <SessionContext.Provider value={{ sessionState, sessionDispatch }}>
      {children}
    </SessionContext.Provider>
  );
};

function aliasLinkedAccounts(
  auth0User: User,
  ldUser: {
    key?: string | undefined;
    name?: string | undefined;
    email?: string | undefined;
    anonymous?: boolean | undefined;
  },
  ldClient: LDClient
) {
  const ldKeysClaim = `${process.env.REACT_APP_AUTH0_NAMESPACE}/ldKeys`;
  if (auth0User[ldKeysClaim]) {
    const ldKeys = auth0User[ldKeysClaim];
    ldKeys?.forEach((key) => {
      if (ldUser.key !== key) {
        ldClient.alias(ldUser, { key });
      }
    });
  }
}

function createLDUserAAD(account: Account | undefined): {
  key?: string;
  name?: string;
  email?: string;
  anonymous?: boolean;
} {
  if (account) {
    return {
      key: account.accountIdentifier,
      name: account.name,
      email: account.userName,
    };
  }
  return {
    anonymous: true,
  };
}

function createLDUser(user: User | undefined): {
  key?: string;
  name?: string;
  email?: string;
  anonymous?: boolean;
} {
  if (user) {
    return {
      key: user.email,
      name: user.name,
      email: user.email,
    };
  }
  return {
    anonymous: true,
  };
}

function createLdBetaUser() {
  return {
    key: 'BETA-USER',
    name: 'BETA-USER',
  };
}
