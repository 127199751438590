// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

export interface ICheckboxItem {
  label: string;
  value: string;
  isSelected?: boolean;
}

interface ICheckboxListProps {
  items: ICheckboxItem[];
  onSelect: (updatedItems: ICheckboxItem[]) => void;
  onChange?: () => void;
}

export const CheckboxList = ({
  items,
  onSelect,
  onChange,
}: ICheckboxListProps): ReactElement => {
  const setIsItemSelected = (itemValue: string, isSelected: boolean) => {
    const updatedIsSelected = items.map((item) => {
      if (item.value === itemValue) {
        return { ...item, isSelected };
      }
      return item;
    });
    onSelect(updatedIsSelected);
    if (onChange) onChange();
  };

  const checkboxes = items.map(({ label, value, isSelected }) => {
    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) =>
      setIsItemSelected(value, event.target.checked);

    return (
      <FormControlLabel
        key={value}
        control={
          <Checkbox
            checked={isSelected}
            onChange={onCheckboxChange}
            color='primary'
          />
        }
        label={label}
      />
    );
  });

  return <>{checkboxes}</>;
};
