// Copyright 2020 Prescryptive Health, Inc.

import { IEnrolledProgram } from '../../model/enrolled-program';
import { IEnrolledServiceType } from '../../model/enrolled-service-type';
import { IProvider } from '../../model/provider';
import { ProviderUser } from '../../model/provider-user';
import { IStoreLocation } from '../../model/store-location';

export interface IProviderState {
  readonly currentProvider?: IProvider;
  readonly locations: IStoreLocation[];
  readonly enrolledServiceTypes: IEnrolledServiceType[];
  readonly enrolledPrograms: IEnrolledProgram[];
  readonly providerUserIdsMap: { [providerName: string]: string[] };
  readonly users: { [userId: string]: ProviderUser };
}

export const defaultProviderState: IProviderState = {
  locations: [],
  enrolledServiceTypes: [],
  enrolledPrograms: [],
  providerUserIdsMap: {},
  users: {},
};
