// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useEffect, useState, useCallback } from 'react';
import { useNotificationContext } from '../../../hooks/use-notification-context/use-notification-context.hook';
import { notificationSetDispatch } from '../../../state/notification/dispatch/notification-set.dispatch';
import { useLocationAvailabilityContext } from '../../../providers/location-availability/use-location-availability-context.hook';
import { useProviderContext } from '../../../providers/provider/use-provider-context.hook';
import { ILaneInfo, ILocationAvailability } from '../../../model/location-availability';
import {
  ILaneAvailabilityUpdateAsyncActionArgs,
  laneAvailabilityUpdateAsyncAction,
} from '../../../state/lane-availability/lane-availability-update.async-action';
import {
  ILocationAvailabilityGetAsyncActionArgs,
  locationAvailabilityGetAsyncAction,
} from '../../../state/location-availability/async-actions/location-availability-get.async-action';
import { LocationAvailabilityCard } from '../../cards/location-availability/location-availability.card';
import { PostAuthPrimaryScreenContainer } from '../../screen-containers/post-auth-primary/post-auth-primary.screen-container';
import { EditAvailabilityModal } from './modals/edit-availability/edit-availability.modal';

import { useLocationContext } from '../../../providers/location/use-location-context.hook';
import { IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import { Box, Typography } from '@mui/material';
import { EmptyViewMessage } from '../../text/messages/empty-view.message';
import { useConfigContext } from '../../../hooks/use-config-context/use-config-context.hook';
import { useBusyContext } from '../../../hooks/use-busy-context/use-busy-context.hook';
import { useErrorContext } from '../../../hooks/use-error-context/use-error-context.hook';
import { useTelemetryContext } from '../../../providers/telemetry/use-telemetry-context.hook';
import { useSessionContext } from '../../../providers/session/use-session-context.hook';
import { AddressFormatter } from '../../../formatters/address-formatter/address.formatter';
import { usePostAuthContext } from '../../../providers/post-auth/use-post-auth-context.hook';
import { useTranslation } from 'react-i18next';
import { getToken } from '../../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const ServiceHoursScreen = (): ReactElement => {
  const { t } = useTranslation();

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  document.title = t('serviceHours.serviceHoursScreen.pageTitle');

  const {
    sessionState: { authProvider },
  } = useSessionContext();

  const {
    authState: { userAccess },
  } = usePostAuthContext();

  const {
    providerState: { currentProvider },
  } = useProviderContext();

  const {
    locationAvailabilityState: { locationAvailabilityMap, isStale },
    locationAvailabilityDispatch,
  } = useLocationAvailabilityContext();

  const {
    locationState: { location },
  } = useLocationContext();

  const { configState } = useConfigContext();
  const { busyDispatch } = useBusyContext();
  const { errorDispatch } = useErrorContext();
  const { telemetryService } = useTelemetryContext();
  const { notificationDispatch } = useNotificationContext();

  const [selectedLocationId, setSelectedLocationId] = useState<string>();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const selectedLocationAvailability = !selectedLocationId
    ? undefined
    : locationAvailabilityMap.get(selectedLocationId);
  const selectedAddress = selectedLocationAvailability?.address;

  const locationAvailabilityGet = useCallback(async () => {
    const actionArgs: ILocationAvailabilityGetAsyncActionArgs = {
      busyDispatch,
      errorDispatch,
      telemetryService,
      configState,
      authProvider,
      locationAvailabilityDispatch,
      providerId: currentProvider?.name ?? '',
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
    };
    await locationAvailabilityGetAsyncAction(actionArgs);
  }, [currentProvider]);

  useEffect(() => {
    const telemetryPayload: IPageViewTelemetry = {
      name: 'service-hours',
      properties: {
        provider: currentProvider?.name,
      },
    };
    telemetryService.trackPageView(telemetryPayload);
    if (currentProvider) {
      void locationAvailabilityGet();
    }
  }, [isStale, currentProvider]);

  const onEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const onEditSave = async (updatedLanes: ILaneInfo[]) => {
    if (!selectedLocationId) {
      return;
    }

    const args: ILaneAvailabilityUpdateAsyncActionArgs = {
      locationAvailabilityDispatch,
      authProvider,
      availabilityId: selectedLocationId,
      busyDispatch,
      configState,
      errorDispatch,
      lanes: updatedLanes,
      telemetryService,
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
    };

    const formattedAddress = selectedAddress ? AddressFormatter.format(selectedAddress) : '?';

    try {
      await laneAvailabilityUpdateAsyncAction(args);
      setIsEditModalOpen(false);
      notificationSetDispatch(
        notificationDispatch,
        t('serviceHours.serviceHoursScreen.updateSuccessMessage', { formattedAddress })
      );
    } catch {
      return;
    }
  };

  const locationAvailabilityArray =
    Array.from<[string, ILocationAvailability]>(locationAvailabilityMap);

  const currentProviderRules = userAccess?.rules.find(
    (rule) => rule.name === currentProvider?.name
  );

  const isEditServiceHoursDisabled = isEditModalOpen || currentProviderRules?.role === 'User';

  const locationAvailabilityComponent = () => {
    if (!location) {
      return <EmptyViewMessage />;
    }

    const filteredLocationAvailability = locationAvailabilityArray.filter(
      ([availabilityId]) => availabilityId === location.id
    );

    return filteredLocationAvailability.map(([availabilityId, availability]) => {
      const { address, lanes, serviceList } = availability;
      const onEditPress = () => {
        setIsEditModalOpen(true);
        setSelectedLocationId(availabilityId);
      };

      return (
        <LocationAvailabilityCard
          key={availabilityId}
          address={address}
          lanes={lanes}
          serviceList={serviceList}
          isDisabled={isEditServiceHoursDisabled}
          onEditPress={onEditPress}
        />
      );
    });
  };

  const locationAvailabilityContent = locationAvailabilityComponent();

  return (
    <PostAuthPrimaryScreenContainer>
      <Typography variant='h1'>{t('serviceHours.serviceHoursScreen.title')}</Typography>
      <Box overflow='auto'>{locationAvailabilityContent}</Box>
      <EditAvailabilityModal
        key={selectedLocationId}
        isOpen={isEditModalOpen}
        address={selectedAddress}
        lanes={selectedLocationAvailability?.lanes}
        serviceList={selectedLocationAvailability?.serviceList}
        onCancel={onEditCancel}
        onSave={onEditSave}
      />
    </PostAuthPrimaryScreenContainer>
  );
};
