// Copyright 2021 Prescryptive Health, Inc.

import { ReactElement, useEffect, useRef } from 'react';
import { Accordion, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useLocationContext } from '../../../providers/location/use-location-context.hook';
import { PostAuthPrimaryScreenContainer } from '../../screen-containers/post-auth-primary/post-auth-primary.screen-container';
import { ServiceAccordionPanel } from './accordion-panels/service/service.accordion-panel';
import { EmptyViewMessage } from '../../text/messages/empty-view.message';
import { AccordionSummary, PendingChip } from './services.screen.styled-components';
import { parse } from 'query-string';
import { useTranslation } from 'react-i18next';

export const ServicesScreen = (): ReactElement => {
  const {
    locationState: { location, services },
  } = useLocationContext();
  const { t } = useTranslation();

  document.title = t('services.pageTitle');

  const openedServices = getOpenedServices();
  const routedServiceType = openedServices.length > 0 ? openedServices[0] : undefined;
  const scrollToRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (routedServiceType) {
      scrollToRef?.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [routedServiceType]);

  const ServiceAccordions = services.map((service) => {
    const shouldAutoOpen = openedServices.includes(service.serviceType);
    const shouldAutoScroll = routedServiceType === service.serviceType;
    const key = `${service.serviceType}-${String(shouldAutoOpen)}-${String(shouldAutoScroll)}`;
    return (
      <Accordion
        key={key}
        TransitionProps={{ unmountOnExit: true }}
        ref={shouldAutoScroll ? scrollToRef : null}
        defaultExpanded={shouldAutoOpen}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color='primary' />}
          aria-controls={`accordian-title-${service.serviceType}`}
          id={`accordian-title-${service.serviceType}`}
        >
          <Box display='flex' justifyContent='space-between' flexGrow='1' alignContent='center'>
            <Typography color='primary' variant='h3' component='h2'>
              {service.name}
            </Typography>
            {service.enrollmentStatus === 'pending' && <PendingChip size='small' label='Pending' />}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <ServiceAccordionPanel service={service} />
        </AccordionDetails>
      </Accordion>
    );
  });
  const servicesContent = () => ServiceAccordions;
  const scrollViewContent = location ? servicesContent() : <EmptyViewMessage />;

  return (
    <PostAuthPrimaryScreenContainer width='100%'>
      <Typography variant='h1'>{t('services.title')}</Typography>
      <Box style={{ overflowY: 'scroll' }}>{scrollViewContent}</Box>
    </PostAuthPrimaryScreenContainer>
  );
};

function getOpenedServices(): (string | null)[] {
  const { openedServices } = parse(window.location.search, {
    arrayFormat: 'comma',
  });
  if (!openedServices) {
    return [];
  }
  if (typeof openedServices === 'string') {
    return [openedServices];
  }
  return openedServices;
}
