// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { usePostAuthScreenContext } from '../../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { useProviderContext } from '../../../../providers/provider/use-provider-context.hook';
import { notificationSetDispatch } from '../../../../state/notification/dispatch/notification-set.dispatch';
import { useNotificationContext } from '../../../../hooks/use-notification-context/use-notification-context.hook';
import { HttpStatusCodesEnum } from '../../../../api/http-status-codes';
import { LoadingSpinner } from '../../../loading/loading-spinner';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  DialogActions,
  useTheme,
  Typography,
} from '@mui/material';
import {
  IProviderAddAsyncActionArgs,
  providerAddAsyncAction,
} from '../../../../state/provider-support/async-actions/provider-add.async-action';
import { IErrorResponse } from '../../../../api/error-response';
import { getToken } from '../../../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

export interface IAddProviderModalProps {
  providerName: string;
  isOpen: boolean;
  onClosePress: () => void;
}

export const AddProviderModal = (props: IAddProviderModalProps): ReactElement => {
  const { t } = useTranslation();
  const { providerName = '', isOpen, onClosePress } = props;
  const theme = useTheme();
  const {
    authState,
    authProvider,
    configState,
    telemetryService,
    authDispatch,
    busyDispatch,
    errorDispatch,
  } = usePostAuthScreenContext({ defaultContent: {} });
  const { providerDispatch } = useProviderContext();
  const { notificationDispatch } = useNotificationContext();

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const [newProvider, setNewProvider] = useState({
    providerName,
    pharmacyNpiNumber: '',
  });
  const [newProviderError, setNewProviderError] = useState({
    providerName: false,
    pharmacyNpiNumber: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [guid] = useState(uuidv4());
  const [apiResponseMessage, setApiResponseMessage] = useState('');

  useEffect(() => {
    setNewProvider({ ...newProvider, providerName });
  }, [providerName]);

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const name = event.target.name;
    const newValue = event.target.value;

    const npiRegex = new RegExp('^[0-9]*$');
    const isValueNumber = npiRegex.test(newValue);

    setNewProviderError({ ...newProviderError, [name]: false });

    if (name === 'pharmacyNpiNumber' && isValueNumber) {
      if (!(newValue.length > 10)) {
        setNewProvider({
          ...newProvider,
          [name]: newValue,
        });
      }
    }

    if (name !== 'pharmacyNpiNumber') {
      setNewProvider({
        ...newProvider,
        [name]: newValue.toUpperCase(),
      });
    }
  };

  const handleDialogClose = () => {
    setNewProvider({ providerName: '', pharmacyNpiNumber: '' });
    setNewProviderError({ providerName: false, pharmacyNpiNumber: false });
    setApiResponseMessage('');
    onClosePress();
  };

  const handleDialogSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    let isNewProviderError = false;
    const updatedNewProviderError = { ...newProviderError };

    if (newProvider.providerName === undefined || newProvider.providerName.length === 0) {
      updatedNewProviderError.providerName = true;
      isNewProviderError = true;
    }

    if (newProvider.pharmacyNpiNumber.length !== 10) {
      updatedNewProviderError.pharmacyNpiNumber = true;
      isNewProviderError = true;
    }

    if (!isNewProviderError) {
      try {
        setIsLoading(true);
        const args: IProviderAddAsyncActionArgs = {
          providerName: newProvider.providerName,
          pharmacyNpiNumber: newProvider.pharmacyNpiNumber,
          providerDispatch,
          postAuthDispatch: authDispatch,
          userAccessRules: authState.userAccess?.rules ?? [],
          authProvider,
          configState,
          telemetryService,
          busyDispatch,
          errorDispatch,
          getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
        };

        await providerAddAsyncAction(args);
        setIsLoading(false);
        onClosePress();
        notificationSetDispatch(
          notificationDispatch,
          t('addProviderModal.addProviderSuccessMessage')
        );
      } catch (error) {
        setIsLoading(false);
        const errorResponse = error as IErrorResponse;
        if (
          errorResponse.message &&
          errorResponse.status &&
          errorResponse.status === HttpStatusCodesEnum.BAD_REQUEST
        ) {
          setApiResponseMessage(errorResponse.message);
        } else {
          setApiResponseMessage(t('addProviderModal.unknownErrorMessage'));
        }
      }
    } else {
      setNewProviderError(updatedNewProviderError);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleDialogClose}
      maxWidth='xl'
      fullWidth={true}
      id={`add-provider-dialog-${guid}`}
    >
      <form onSubmit={handleDialogSubmit}>
        <DialogTitle>{t('addProviderModal.dialogTitleLabel')}</DialogTitle>
        <DialogContent>
          <Box display='flex' flexDirection='column' gap={theme.spacing(2)}>
            <TextField
              autoFocus={true}
              fullWidth={true}
              margin='dense'
              name='providerName'
              value={newProvider.providerName}
              onChange={handleTextFieldChange}
              label={t('addProviderModal.dialogProviderNameLabel')}
              type='text'
              variant='standard'
              id={`add-provider-dialog-providerName-text-input-${guid}`}
              error={newProviderError.providerName}
              helperText={
                newProviderError.providerName ? t('addProviderModal.providerNameErrorText') : ''
              }
            />
            <TextField
              margin='dense'
              fullWidth={true}
              name='pharmacyNpiNumber'
              value={newProvider.pharmacyNpiNumber}
              onChange={handleTextFieldChange}
              label={t('addProviderModal.dialogPharmacyNpiNumberLabel')}
              type='text'
              variant='standard'
              id={`add-provider-dialog-npi-text-input-${guid}`}
              error={newProviderError.pharmacyNpiNumber}
              helperText={
                newProviderError.pharmacyNpiNumber
                  ? t('addProviderModal.pharmacyNpiNumberErrorText')
                  : ''
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display='flex' flexDirection='row' width='600px'>
            {apiResponseMessage && <Typography color='error'>{apiResponseMessage}</Typography>}
            <Box
              display='flex'
              flexDirection='row'
              gap={theme.spacing(1)}
              marginLeft='auto'
              height='fit-content'
              alignSelf='center'
            >
              <Button
                type='submit'
                color='primary'
                variant='contained'
                id={`add-provider-dialog-save-button-${guid}`}
              >
                {isLoading ? <LoadingSpinner /> : t('addProviderModal.dialogAddButtonLabel')}
              </Button>
              <Button
                onClick={handleDialogClose}
                color='primary'
                variant='outlined'
                id={`add-provider-dialog-close-button-${guid}`}
              >
                {t('addProviderModal.dialogCancelButtonLabel')}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};
