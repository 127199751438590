// Copyright 2021 Prescryptive Health, Inc.

import { IProgram } from '../../../model/program';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { call } from '../../call';
import { ApiErrorCode } from '../../errors/api-error-code';
import { ApiErrors } from '../../errors/api-errors';

export interface IProgramsGetResult {
  programs: IProgram[];
}

export async function programsGet(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string
): Promise<IProgramsGetResult> {
  const url = buildUrl(apiConfig, 'programsGet', {});
  const headers = buildBearerAuthHeaders(authToken);

  const response: Response = await call(url, undefined, 'GET', headers);

  if (response.ok) {
    return await resultFromResponse(response);
  }
  throw new PharmacyPortalError(
    ApiErrors.programsGet(response.statusText),
    ApiErrorCode.PROGRAMS_GET,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}

async function resultFromResponse(response: Response): Promise<IProgramsGetResult> {
  const responseData = (await response.json()) as IProgram[];
  if (!responseData) {
    throw new Error(ApiErrors.programsGet(ApiErrors.unexpectedResponseDataFormat));
  }

  const result: IProgramsGetResult = {
    programs: responseData,
  };

  return result;
}
