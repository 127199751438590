import { Alert, Box, CardContent, Link, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PostAuthPrimaryScreenContainer } from '../../screen-containers/post-auth-primary/post-auth-primary.screen-container';
import {
  BannerButtons,
  BannerLabelButtonBox,
  PricingBanner,
  PricingButton,
  PricingCard,
  PricingSubTitles,
} from './pricing.screen.styled-components';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { useNavigate } from 'react-router-dom';
import { authenticatedRouteConfig } from '../../../navigation/authenticated-route-config';
import { useQuery } from '@apollo/client';
import pharmacyPortalServiceGraphQLApolloClientAAD from '../../../init-pharmacy-portal-service-graphql-client-aad';
import pharmacyPortalServiceGraphQLApolloClient from '../../../init-pharmacy-portal-service-graphql-client';
import { GET_PROVIDER, IProviderEnrollment } from './get-provider-programs.query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useProviderContext } from '../../../providers/provider/use-provider-context.hook';
import { ReactElement } from 'react';
import { GET_PROVIDER_PROGRAM_IDENTIFIER_QUERY } from './get-provider-service-groups.query';
import { ProviderServiceGroup } from '../../../model/strapi/strapi-models';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const PricingScreen = (): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth0Migration } = useFlags();
  const {
    providerState: { currentProvider },
  } = useProviderContext();

  const { data: strapiData, loading: strapiLoading } = useQuery<{
    providerServiceGroups: ProviderServiceGroup[];
  }>(GET_PROVIDER_PROGRAM_IDENTIFIER_QUERY, {});

  const aiPricingIdentifier = 'program-myrx-ai-pricing';
  const standardPricingIdentifier = 'program-myrx-pricing';

  document.title = t('pricing.pageTitle');
  const aiPricingProgram = strapiData?.providerServiceGroups
    .map(
      (group) =>
        group.provider_programs?.find(
          (program) => program?.ProgramIdentifier === aiPricingIdentifier
        )?.id
    )
    .find((element) => element !== undefined);

  const standardPricingProgram = strapiData?.providerServiceGroups
    .map(
      (group) =>
        group.provider_programs?.find(
          (program) => program?.ProgramIdentifier === standardPricingIdentifier
        )?.id
    )
    .find((element) => element !== undefined);

  const { data: providerData, loading: providerLoading } = useQuery<{
    provider: IProviderEnrollment;
  }>(GET_PROVIDER, {
    variables: {
      id: currentProvider?.name,
    },
    fetchPolicy: 'cache-and-network',
    client: auth0Migration
      ? pharmacyPortalServiceGraphQLApolloClient
      : pharmacyPortalServiceGraphQLApolloClientAAD,
  });

  const aiPricingEnrollmentStatus = providerData?.provider.programs?.find(
    (program) => program.id === aiPricingIdentifier
  )?.enrollmentStatus;

  const standardPricingEnrollmentStatus = providerData?.provider.programs?.find(
    (program) => program.id === standardPricingIdentifier
  )?.enrollmentStatus;

  const handlePricingClick = () => {
    standardPricingProgram
      ? void navigate(authenticatedRouteConfig.addProgramDetails.createUrl(standardPricingProgram))
      : jest.fn();
  };

  const handleAIClick = () => {
    aiPricingProgram
      ? void navigate(authenticatedRouteConfig.addProgramDetails.createUrl(aiPricingProgram))
      : jest.fn();
  };

  const aiStartIcon =
    aiPricingEnrollmentStatus === 'pending' ? (
      <AccessTimeIcon color='primary' />
    ) : aiPricingEnrollmentStatus === 'active' ? (
      <CheckCircleIcon color='primary' />
    ) : null;

  const standardStartIcon =
    standardPricingEnrollmentStatus === 'pending' ? (
      <AccessTimeIcon color='primary' />
    ) : standardPricingEnrollmentStatus === 'active' ? (
      <CheckCircleIcon color='primary' />
    ) : null;

  const isSubmitPriceButtonDisplayed: boolean =
    aiPricingEnrollmentStatus === 'pending' || aiPricingEnrollmentStatus === 'active';

  const isStandardPriceOrAIPriceActive: boolean =
    aiPricingEnrollmentStatus === 'active' ||
    aiPricingEnrollmentStatus === 'pending' ||
    standardPricingEnrollmentStatus === 'active' ||
    standardPricingEnrollmentStatus === 'pending';

  const aiPricingBanner = () => {
    return (
      <Alert severity='warning'>
        <Typography variant='subtitle2'> {t('pricing.alert.banner.title')}</Typography>
        <Box display={'flex'}>
          <Typography variant='body2'>{t('pricing.alert.banner.body')}</Typography>{' '}
          <Typography variant='body2'>
            <Link href='https://forms.office.com/r/GSGxjMaKr3' target='_blank'>
              {' '}
              {t('pricing.alert.banner.link')}
            </Link>
          </Typography>
        </Box>
      </Alert>
    );
  };

  const standardPricingBanner = () => {
    return (
      <Alert severity='warning'>
        <Typography variant='subtitle2'> {t('pricing.alert.standard.banner.title')}</Typography>
        <Box display={'flex'}>
          <Typography variant='body2'>{t('pricing.alert.standard.banner.body')}</Typography>{' '}
        </Box>
      </Alert>
    );
  };

  return (
    <Box>
      {isStandardPriceOrAIPriceActive ? null : (
        <PricingBanner>
          <Box display='flex' alignItems='center'>
            <WorkspacePremiumIcon sx={{ color: theme.palette.primary.dark }} />
            <Typography marginLeft={theme.spacing(4)} variant='h3'>
              {t('pricing.banner.title')}
            </Typography>
          </Box>

          <BannerLabelButtonBox>
            <Typography sx={{ color: theme.palette.text.primary }} margin={theme.spacing(1)}>
              {t('pricing.banner.button.context')}
            </Typography>
            <BannerButtons
              data-testid='standard-pricing-button'
              size='small'
              variant={isStandardPriceOrAIPriceActive ? 'outlined' : 'contained'}
              loading={strapiLoading}
              onClick={handlePricingClick}
              startIcon={standardStartIcon}
            >
              {t('pricing.banner.button.standardPricing')}
            </BannerButtons>
            <BannerButtons
              data-testid='ai-pricing-button'
              size='small'
              variant={isStandardPriceOrAIPriceActive ? 'outlined' : 'contained'}
              loading={strapiLoading}
              onClick={handleAIClick}
              startIcon={aiStartIcon}
            >
              {t('pricing.banner.button.aiPricing')}
            </BannerButtons>
          </BannerLabelButtonBox>
        </PricingBanner>
      )}

      <PostAuthPrimaryScreenContainer width='100%'>
        {isStandardPriceOrAIPriceActive ? (
          <Box display='flex' width={'100%'}>
            <Typography variant='h1' paddingBottom={theme.spacing(9)}>
              {t('pricing.title')}
            </Typography>
            <Box display={'flex'} sx={{ alignItems: 'center' }} marginLeft={'auto'}>
              <Typography sx={{ color: theme.palette.text.primary }} margin={theme.spacing(1)}>
                {t('pricing.banner.button.pricingContext')}
              </Typography>
              <BannerButtons
                data-testid='standard-pricing-button'
                size='small'
                variant={'outlined'}
                loading={strapiLoading}
                onClick={handlePricingClick}
                startIcon={standardStartIcon}
              >
                {t('pricing.banner.button.standardPricing')}
              </BannerButtons>
              <BannerButtons
                data-testid='ai-pricing-button'
                size='small'
                variant={'outlined'}
                loading={strapiLoading}
                onClick={handleAIClick}
                startIcon={aiStartIcon}
              >
                {t('pricing.banner.button.aiPricing')}
              </BannerButtons>
            </Box>
          </Box>
        ) : (
          <Typography variant='h1' paddingBottom={theme.spacing(9)}>
            {t('pricing.title')}
          </Typography>
        )}

        {aiPricingEnrollmentStatus === 'pending' ? (
          <Box marginBottom={theme.spacing(6)}> {aiPricingBanner()} </Box>
        ) : null}
        {standardPricingEnrollmentStatus === 'pending' ? (
          <Box marginBottom={theme.spacing(6)}> {standardPricingBanner()} </Box>
        ) : null}

        <PricingSubTitles>
          <Typography variant='h5' paddingRight={theme.spacing(5)}>
            {t('pricing.insights.title')}
          </Typography>
          {!isStandardPriceOrAIPriceActive ? (
            <>
              <WorkspacePremiumIcon
                fontSize='small'
                sx={{ color: theme.palette.blueScale['50'], marginRight: theme.spacing(2) }}
              />
              <Typography variant='body1' sx={{ color: theme.palette.primary.dark }}>
                {t('pricing.insights.subtitle')}
              </Typography>
            </>
          ) : null}
        </PricingSubTitles>

        <PricingCard variant='outlined'>
          <CardContent>
            <Box margin={theme.spacing(3)}>
              <Box
                display={'-webkit-inline-box'}
                alignItems='center'
                marginBottom={theme.spacing(3)}
              >
                <EqualizerIcon
                  sx={{
                    marginRight: theme.spacing(6),
                    color: theme.palette.text.secondary,
                  }}
                />
                <Typography variant='h6' gutterBottom>
                  {t('pricing.insights.pricingReports')}
                </Typography>
              </Box>
              <Box marginLeft={theme.spacing(15)}>
                <Typography sx={{ color: theme.palette.text.secondary }} variant='body1'>
                  {t('pricing.insights.body')}
                </Typography>
                <PricingButton
                  data-testid='reports-button'
                  onClick={() => {
                    void navigate(authenticatedRouteConfig.reports.createUrl());
                  }}
                  variant='outlined'
                >
                  {t('pricing.insights.button')}
                </PricingButton>
              </Box>
            </Box>
          </CardContent>
        </PricingCard>

        <Box marginTop={theme.spacing(14)}>
          <PricingSubTitles>
            <Typography variant='h5' paddingRight={theme.spacing(5)}>
              {t('pricing.tools.title')}
            </Typography>
            {aiPricingEnrollmentStatus !== 'pending' && aiPricingEnrollmentStatus !== 'active' ? (
              <>
                <WorkspacePremiumIcon
                  fontSize='small'
                  sx={{ color: theme.palette.blueScale[50], marginRight: theme.spacing(2) }}
                />
                <Typography variant='body1' sx={{ color: theme.palette.primary.dark }}>
                  {t('pricing.tools.subtitle')}
                </Typography>
              </>
            ) : null}
          </PricingSubTitles>

          <Box display='flex' alignItems='center'>
            <PricingCard variant='outlined'>
              <CardContent>
                <Box margin={theme.spacing(3)}>
                  <Box
                    display={'-webkit-inline-box'}
                    alignItems='center'
                    marginBottom={theme.spacing(3)}
                  >
                    <PriceCheckIcon
                      sx={{
                        marginRight: theme.spacing(6),
                        color: theme.palette.text.secondary,
                      }}
                    />
                    <Typography variant='h6' gutterBottom>
                      {t('pricing.tools.pricingGoals')}
                    </Typography>
                  </Box>
                  <Box marginLeft={theme.spacing(15)}>
                    <Typography sx={{ color: theme.palette.text.secondary }} variant='body1'>
                      {t('pricing.tools.body')}
                    </Typography>
                    <PricingButton
                      data-testid='submit-price-button'
                      onClick={() => {
                        window.open('https://forms.office.com/r/GSGxjMaKr3', '_blank');
                      }}
                      variant={aiPricingEnrollmentStatus !== 'active' ? 'contained' : 'outlined'}
                      disabled={!isSubmitPriceButtonDisplayed}
                      loading={providerLoading}
                    >
                      {aiPricingEnrollmentStatus === 'active'
                        ? t('pricing.tools.editButton')
                        : t('pricing.tools.button')}
                    </PricingButton>
                  </Box>
                </Box>
              </CardContent>
            </PricingCard>

            <PricingCard variant='outlined' sx={{ marginLeft: 'auto' }}>
              <CardContent>
                <Box margin={theme.spacing(3)}>
                  <Box
                    display={'-webkit-inline-box'}
                    alignItems='center'
                    marginBottom={theme.spacing(3)}
                  >
                    <CurrencyExchangeIcon
                      sx={{
                        marginRight: theme.spacing(6),
                        color: theme.palette.text.secondary,
                      }}
                    />
                    <Typography variant='h6' gutterBottom>
                      {t('pricing.tools.pricingFeedback')}
                    </Typography>
                  </Box>
                  <Box marginLeft={theme.spacing(15)}>
                    <Typography sx={{ color: theme.palette.text.secondary }} variant='body1'>
                      {t('pricing.tools.feedBackBody')}
                    </Typography>
                    <PricingButton
                      disabled={aiPricingEnrollmentStatus !== 'active'}
                      onClick={() => {
                        window.open(' https://forms.office.com/r/xnN3JFuTYR', '_blank');
                      }}
                      data-testid='send-feedback-button'
                      variant='outlined'
                      loading={providerLoading}
                    >
                      {t('pricing.tools.feedBackButton')}
                    </PricingButton>
                  </Box>
                </Box>
              </CardContent>
            </PricingCard>
          </Box>
        </Box>
      </PostAuthPrimaryScreenContainer>
    </Box>
  );
};
