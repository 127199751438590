// Copyright 2020 Prescryptive Health, Inc.

import React, { ReactElement, useEffect } from 'react';
import { Box } from '@mui/material';
import JsBarcode from 'jsbarcode';

interface IBarcodeGeneratorProps {
  barcodeValue: string;
}

export const Barcode = (props: IBarcodeGeneratorProps): ReactElement => {
  const { barcodeValue } = props;

  useEffect(() => {
    JsBarcode('#barcode').init();
  }, []);

  return (
    <Box id='barcode-print-id'>
      <svg
        id='barcode'
        jsbarcode-format='CODE128'
        jsbarcode-value={barcodeValue}
        jsbarcode-font='roboto-regular, sans-serif'
      />
    </Box>
  );
};
