// Copyright 2020 Prescryptive Health, Inc.

import React, { ChangeEvent, ReactElement, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface IPhoneMaskInputProps {
  onPhoneNumberChange?: (phoneNumber: string) => void;
  initialValue?: string;
  placeholder?: string;
  isError?: boolean;
  errorMessage?: string;
}

export const PhoneMaskInput = (props: IPhoneMaskInputProps & TextFieldProps): ReactElement => {
  const { t } = useTranslation();
  const {
    onPhoneNumberChange,
    initialValue = '',
    placeholder,
    isError: error,
    errorMessage: errorText,
    ...otherProps
  } = props;

  const extractFromMask = (value: string) => {
    const PhoneNumberMask = [
      /['(']/,
      /[1-9]/,
      /\d/,
      /\d/,
      /[')']/,
      /[' ']/,
      /\d/,
      /\d/,
      /\d/,
      /['\-']/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];

    const arrayValue = value.split('');

    const unMasked = arrayValue
      .map((substring, i) => {
        if (i > PhoneNumberMask.length - 1) {
          return null;
        }

        const isMatch = PhoneNumberMask[i].exec(substring);

        if (isMatch) {
          if (isNaN(parseInt(substring, 10))) {
            return null;
          }
          return substring;
        } else {
          if (!isNaN(parseInt(substring, 10))) {
            return substring;
          }

          return null;
        }
      })
      .filter((n) => n !== null)
      .join('');

    return { unMasked, masked: maskValue(unMasked) };
  };

  const maskValue = (unMasked: string) => {
    const splited = unMasked.split('');
    const length = splited.length;

    let masked = '';

    if (length === 0) {
      return '';
    }

    splited.forEach((s, i) => {
      if (i === 0) {
        masked += `(${s}`;
      } else if (i === 2 && i !== length - 1) {
        masked += `${s}) `;
      } else if (i === 5 && i !== length - 1) {
        masked += `${s}-`;
      } else {
        masked += s;
      }
    });

    return masked;
  };

  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState<string>(
    maskValue(initialValue.substring(2) ?? initialValue)
  );

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { unMasked, masked } = extractFromMask(event.target.value);
    if (onPhoneNumberChange) {
      onPhoneNumberChange(unMasked);
    }
    setMaskedPhoneNumber(masked);
  };

  const inputPlaceholder = placeholder ? placeholder : t('inputs.maskInputs.placeholder');

  return (
    <TextField
      placeholder={inputPlaceholder}
      value={maskedPhoneNumber}
      onChange={onChangeHandler}
      label={t('inputs.maskInputs.label')}
      fullWidth={true}
      variant='filled'
      error={error}
      helperText={error ? errorText : ''}
      {...otherProps}
    />
  );
};
