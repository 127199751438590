// Copyright 2021 Prescryptive Health, Inc.
import styled from 'styled-components';
import AutoRenewIcon from '@mui/icons-material/Autorenew';

export const LoadingSpinner = styled(AutoRenewIcon)`
  animation: rotate 2s linear infinite;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;
