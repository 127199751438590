// Copyright 2021 Prescryptive Health, Inc.

import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { ProviderDispatch } from '../dispatch/provider.dispatch';
import { providerLocationDelete } from '../../../api/v1/provider-location-delete/provider-location-delete';
import { locationDeleteDispatch } from '../dispatch/store-location-delete.dispatch';
import { logApiError } from '../../../api/errors/log-api-error';

export interface ILocationDeleteAsyncActionArgs extends IApiAsyncActionArgs {
  locationId: string;
  providerDispatch: ProviderDispatch;
}

export const locationDeleteAsyncAction = async (
  args: ILocationDeleteAsyncActionArgs
): Promise<void> => {
  const { locationId, providerDispatch, telemetryService, getAuthToken } = args;

  try {
    telemetryService.trackEvent('store-location-delete', locationId);
    const { configState, authProvider } = args;

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    await providerLocationDelete(configState.apis.pharmacyPortal, accessToken, locationId);
    locationDeleteDispatch(providerDispatch, locationId);
  } catch (error) {
    logApiError(telemetryService, error);
    return Promise.reject(error);
  }
};
