// Copyright 2021 Prescryptive Health, Inc.

import { Box, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/material';

export const ValuePropBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingBottom: theme.spacing(6),
}));

export const ValuePropTextBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const IconCardMedia = styled(CardMedia)({
  height: '100%',
  width: '100%',
});

export const SecondaryTextTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
