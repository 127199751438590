// Copyright 2021 Prescryptive Health, Inc.

import { PaymentReportsDispatch } from './payment-reports.dispatch';
import { paymentReportsSetAction } from '../actions/payment-reports-set.action';
import { IPaymentReport } from '../../../model/payment-report';

export const paymentReportsSetDispatch = (
  dispatch: PaymentReportsDispatch,
  paymentReports: IPaymentReport[]
): void => {
  dispatch(paymentReportsSetAction(paymentReports));
};
