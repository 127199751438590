// Copyright 2021 Prescryptive Health, Inc.

import React, { FunctionComponent, useEffect, useReducer } from 'react';
import { defaultLocationAvailabilityState } from '../../state/location-availability/location-availability.state';
import {
  locationAvailabilityReducer,
  LocationAvailabilityReducer,
} from '../../state/location-availability/location-availability.reducer';
import { LocationAvailabilityContext } from './location-availability.context';
import {
  ILocationAvailabilityGetAsyncActionArgs,
  locationAvailabilityGetAsyncAction,
} from '../../state/location-availability/async-actions/location-availability-get.async-action';
import { useSessionContext } from '../../providers/session/use-session-context.hook';
import { useTelemetryContext } from '../../providers/telemetry/use-telemetry-context.hook';
import { useBusyContext } from '../../hooks/use-busy-context/use-busy-context.hook';
import { useErrorContext } from '../../hooks/use-error-context/use-error-context.hook';
import { useConfigContext } from '../../hooks/use-config-context/use-config-context.hook';
import { useProviderContext } from '../provider/use-provider-context.hook';
import { getToken } from '../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const LocationAvailabilityContextProvider: FunctionComponent = ({
  children,
}) => {
  const [state, dispatch] = useReducer<LocationAvailabilityReducer>(
    locationAvailabilityReducer,
    defaultLocationAvailabilityState
  );

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const {
    providerState: { currentProvider },
  } = useProviderContext();

  const { errorDispatch } = useErrorContext();
  const { configState } = useConfigContext();
  const { busyDispatch } = useBusyContext();
  const { telemetryService } = useTelemetryContext();
  const { sessionState } = useSessionContext();

  useEffect(() => {
    if (!currentProvider?.name) {
      return;
    }

    const locationAvailabilityGet = async () => {
      const actionArgs: ILocationAvailabilityGetAsyncActionArgs = {
        busyDispatch,
        errorDispatch,
        telemetryService,
        configState,
        authProvider: sessionState.authProvider,
        locationAvailabilityDispatch: dispatch,
        providerId: currentProvider?.name ?? '',
        getAuthToken: getToken(auth0Migration, getAccessTokenSilently)
      };
      await locationAvailabilityGetAsyncAction(actionArgs);
    };

    void locationAvailabilityGet();
  }, [currentProvider]);

  return (
    <LocationAvailabilityContext.Provider
      value={{
        locationAvailabilityState: state,
        locationAvailabilityDispatch: dispatch,
      }}
    >
      {children}
    </LocationAvailabilityContext.Provider>
  );
};
