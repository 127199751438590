import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Navigate } from 'react-router';

interface IRequireAcceptTermsOfUseProps {
  children: ReactJSXElement;
  areTermsAccepted: boolean;
}

export const RequireAcceptTermsOfUse = ({
  areTermsAccepted,
  children,
}: IRequireAcceptTermsOfUseProps) => {
  return areTermsAccepted ? children : <Navigate to='/terms-of-use' />;
};
