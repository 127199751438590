// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, forwardRef } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import dateFormatter from '../../../formatters/dates/date.formatter';
import { PhoneNumberFormatter } from '../../../formatters/phone-number/phone-number.formatter';
import { IProvider } from '../../../model/provider';
import { IClaimOption } from '../../../model/service';
import { MyPrescryptiveLogo } from '../../myprescryptive-logo/myprescryptive-logo';
import {
  AppointmentPrintOutDetailTypography,
  AppointmentPrintOutInformationRowContainer,
  AppointmentPrintOutLabelTypography,
  AppointmentPrintOutProviderLabelTypography,
  AppointmentPrintOutSection,
  AppointmentPrintOutSectionTypography,
  AppointmentPrintOutTestInfoContainer,
  AppointmentPrintOutTestResultContainer,
  PharmacyInformationTypography,
} from './appointment-print-out.styled-components';
import { AddressFormatter } from '../../../formatters/address-formatter/address.formatter';
import { IAppointment } from '../../screens/appointment-details/appointment-details.screen.query';
import {
  isoDateStringToLocalDate,
  isoDateTimeStringToDate,
} from '../../../helpers/dates/date-helper/date.helper';
import { useTranslation } from 'react-i18next';

type TestResult = {
  testResult: string;
  diagnosisCode: string;
  testDescription: string;
};

type AppointmentPrintOutProps = {
  claimOptions: IClaimOption[] | undefined;
  currentProvider?: IProvider;
  appointment?: IAppointment;
  administrationMethod: string | null | undefined;
  testType: string | null | undefined;
  clia?: string;
};

export const AppointmentPrintOut = forwardRef<HTMLDivElement, AppointmentPrintOutProps>(
  (props: AppointmentPrintOutProps, ref) => {
    const { t } = useTranslation();
    const { appointment, currentProvider, claimOptions, administrationMethod, testType, clia } =
      props;

    const cliaNumber = clia ?? '';
    const phoneNumber = appointment
      ? PhoneNumberFormatter.formatForUI(appointment?.location.phone)
      : '';
    const pharmacyAddress = appointment
      ? AddressFormatter.format({
          line1: appointment?.location.address.line1 ?? '',
          city: appointment?.location.address.city ?? '',
          state: appointment?.location.address.state ?? '',
          zip: appointment?.location.address.zip ?? '',
        })
      : '';

    const appointmentDate = isoDateTimeStringToDate(appointment?.startDateISO ?? '');

    const dateOfService = appointment ? dateFormatter.formatToMDY(appointmentDate) : '';
    const patientFirstName = appointment?.patient.firstName ?? '';
    const patientLastName = appointment?.patient.lastName ?? '';
    const dateOfBirth = appointment
      ? dateFormatter.formatToMDY(isoDateStringToLocalDate(appointment.patient.dateOfBirth))
      : '';
    const orderNumber = appointment?.orderNumber ?? '';
    const sourceOfSwab = t('appointments.appointmentPrintOut.sourceOfSwabDetail');
    const resultDate = dateOfService;
    const timeOfService = appointment
      ? dateFormatter.formatToTimeRange(appointmentDate, appointment.duration)
      : '';
    const testResult = appointment?.procedure?.results?.find(
      (result) => result.procedureResultId === 'test-result'
    );
    const testInformation: TestResult = {
      testResult: t('appointments.appointmentPrintOut.unavailableDetail'),
      diagnosisCode: t('appointments.appointmentPrintOut.unavailableDetail'),
      testDescription: t('appointments.appointmentPrintOut.unavailableDetail'),
    };

    if (testResult) {
      const claim = claimOptions?.find(
        (claimOption) => claimOption.claimId === testResult.answerId
      );

      if (claim && claim.icd10Code) {
        testInformation.testResult = claim.icd10Code.result;
        testInformation.diagnosisCode = claim.icd10Code.code;
        testInformation.testDescription = claim.icd10Code.description;
      }
    }

    const prescriberInformation = {
      prescriberFirstName: '',
      prescriberLastName: '',
    };

    const prescriberFirstName = appointment?.procedure?.results?.find(
      (result) => result.procedureResultId === 'prescriber-first-name'
    );
    const prescriberLastName = appointment?.procedure?.results?.find(
      (result) => result.procedureResultId === 'prescriber-last-name'
    );

    if (prescriberFirstName) {
      prescriberInformation.prescriberFirstName = prescriberFirstName?.answerText ?? '';
    }
    if (prescriberLastName) {
      prescriberInformation.prescriberLastName = prescriberLastName?.answerText ?? '';
    }

    return (
      <Box display='flex' flexDirection='column' width='100%' margin='40px' ref={ref}>
        {/* SECTION 1 - Pharmacy name and info */}
        <AppointmentPrintOutSection justifyContent='space-between'>
          <Box maxWidth='240px'>
            <Typography variant='h2'>{currentProvider?.name ?? ''}</Typography>
          </Box>
          <Box display='flex' flexDirection='column' alignItems='flex-end'>
            <PharmacyInformationTypography variant='caption'>
              {pharmacyAddress}
            </PharmacyInformationTypography>
            <PharmacyInformationTypography variant='caption'>
              {t('appointments.appointmentPrintOut.phoneNumberLabel', { phoneNumber })}
            </PharmacyInformationTypography>
            <PharmacyInformationTypography variant='caption'>
              {t('appointments.appointmentPrintOut.cliaLabel', { clia: cliaNumber })}
            </PharmacyInformationTypography>
          </Box>
        </AppointmentPrintOutSection>
        <Box marginBottom='24px'>
          <Divider />
        </Box>
        {/* SECTION 2 - Test information, patient results, and notes */}
        <AppointmentPrintOutSection>
          <Box display='flex' flexDirection='column' flex={1}>
            {renderTestInformationRow(
              t('appointments.appointmentPrintOut.orderNumberLabel'),
              orderNumber,
              'appointment-print-out-order-number'
            )}
            {renderTestInformationRow(
              t('appointments.appointmentPrintOut.testTypeLabel'),
              testType ?? 'N/A',
              'appointment-print-out-test-type'
            )}
            {renderTestInformationRow(
              t('appointments.appointmentPrintOut.dateOfServiceLabel'),
              dateOfService,
              'appointment-print-out-date-of-service'
            )}
            {renderTestInformationRow(
              t('appointments.appointmentPrintOut.timeOfServiceLabel'),
              timeOfService,
              'appointment-print-out-time-of-service'
            )}
          </Box>
          <Box display='flex' flexDirection='column' flex={1}>
            {renderTestInformationRow(
              t('appointments.appointmentPrintOut.diagnosisCodeLabel'),
              testInformation.diagnosisCode,
              'appointment-print-out-diagnosis-code'
            )}
            {renderTestInformationRow(
              t('appointments.appointmentPrintOut.administrationMethodLabel'),
              administrationMethod ?? 'N/A',
              'appointment-print-out-administered-method'
            )}
            {appointment?.service.serviceType !== 'c19-antibody-test'
              ? renderTestInformationRow(
                  t('appointments.appointmentPrintOut.sourceOfSwabLabel'),
                  sourceOfSwab,
                  'appointment-print-out-source-of-swab'
                )
              : null}
            {renderTestInformationRow(
              t('appointments.appointmentPrintOut.resultDateLabel'),
              resultDate,
              'appointment-print-out-result-date'
            )}
          </Box>
        </AppointmentPrintOutSection>
        <AppointmentPrintOutSection>
          <AppointmentPrintOutTestInfoContainer border={1}>
            <Box
              display='flex'
              flexDirection='column'
              marginRight='auto'
              id='appointment-print-out-patient-name'
            >
              <Typography variant='body1'>
                {t('appointments.appointmentPrintOut.patientNameLabel')}
              </Typography>
              <Typography variant='h3'>{patientFirstName + ' ' + patientLastName}</Typography>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              marginRight='32px'
              id='appointment-print-out-date-of-birth'
            >
              <Typography variant='body1'>
                {t('appointments.appointmentPrintOut.dateOfBirthLabel')}
              </Typography>
              <Typography variant='h3'>{dateOfBirth}</Typography>
            </Box>
            <Box display='flex' flexDirection='column' id='appointment-print-out-service-name'>
              <Typography variant='body1'>
                {t('appointments.appointmentPrintOut.serviceNameLabel')}
              </Typography>
              <Typography variant='h3'>
                {t('appointments.appointmentPrintOut.serviceNameDetail')}
              </Typography>
            </Box>
          </AppointmentPrintOutTestInfoContainer>
          <AppointmentPrintOutTestResultContainer>
            <Box display='flex' flexDirection='column' id='appointment-print-out-test-result'>
              <Typography variant='body1'>
                {t('appointments.appointmentPrintOut.resultLabel')}
              </Typography>
              <Typography variant='h3'>{testInformation.testResult}</Typography>
            </Box>
          </AppointmentPrintOutTestResultContainer>
        </AppointmentPrintOutSection>
        <AppointmentPrintOutSection>
          <Box display='flex' flexDirection='column'>
            <AppointmentPrintOutSectionTypography variant='body1'>
              {t('appointments.appointmentPrintOut.testNotesLabel')}
            </AppointmentPrintOutSectionTypography>
            <Typography variant='body2'>{testInformation.testDescription}</Typography>
          </Box>
        </AppointmentPrintOutSection>
        <Box marginBottom='24px'>
          <Divider />
        </Box>
        {/* SECTION 3 - Pharmacist provider information */}
        <Box
          display='flex'
          flexDirection='column'
          marginBottom='auto'
          id='appointment-print-out-provider-information'
        >
          <AppointmentPrintOutSectionTypography variant='body1'>
            {t('appointments.appointmentPrintOut.pharmacyProviderInfoLabel')}
          </AppointmentPrintOutSectionTypography>
          {renderProviderInformationRow(
            t('appointments.appointmentPrintOut.testProviderLabel'),
            currentProvider?.name ?? '',
            'appointment-print-out-provider-name'
          )}
          {renderProviderInformationRow(
            t('appointments.appointmentPrintOut.pharmacyNpiLabel'),
            currentProvider?.npiNumber ?? '',
            'appointment-print-out-npi-number'
          )}
          {renderProviderInformationRow(
            t('appointments.appointmentPrintOut.prescriberNameLabel'),
            prescriberInformation.prescriberFirstName +
              ' ' +
              prescriberInformation.prescriberLastName,
            'appointment-print-out-prescriber-name'
          )}
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h3'>{t('appointments.appointmentPrintOut.accessLabel')}</Typography>
          <MyPrescryptiveLogo />
        </Box>
      </Box>
    );
  }
);

AppointmentPrintOut.displayName = 'AppointmentPrintOut';

function renderTestInformationRow(label: string, detail: string, id: string): ReactElement {
  return (
    <AppointmentPrintOutInformationRowContainer id={id}>
      <AppointmentPrintOutLabelTypography variant='body2'>
        {label}
      </AppointmentPrintOutLabelTypography>
      <AppointmentPrintOutDetailTypography variant='body2'>
        {detail}
      </AppointmentPrintOutDetailTypography>
    </AppointmentPrintOutInformationRowContainer>
  );
}

function renderProviderInformationRow(label: string, detail: string, id: string): ReactElement {
  return (
    <AppointmentPrintOutInformationRowContainer id={id}>
      <AppointmentPrintOutProviderLabelTypography variant='body2'>
        {label}
      </AppointmentPrintOutProviderLabelTypography>
      <AppointmentPrintOutDetailTypography variant='body2'>
        {detail}
      </AppointmentPrintOutDetailTypography>
    </AppointmentPrintOutInformationRowContainer>
  );
}
