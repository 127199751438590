// Copyright 2021 Prescryptive Health, Inc.

import { IContentMap } from '../../../../../providers/locale/get-content';

export interface IQuestionCardListContent {
  numericLabel: (index: number, value: string) => string;
}

const defaultContent: IQuestionCardListContent = {
  numericLabel: (index: number, value: string) => `${index + 1}. ${value}`,
};

export const questionCardListContent: IContentMap<
  Readonly<IQuestionCardListContent>
> = {
  defaultContent,
};
