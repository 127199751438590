// Copyright 2021 Prescryptive Health, Inc.

import { Maybe } from 'graphql/jsutils/Maybe';
import React, { ReactElement } from 'react';
import { EnrollmentStatus } from '../../../../../model/enrolled-service-type';
import {
  ProviderProgramRightRailDynamicZone,
  ProviderServiceRightRailDynamicZone,
} from '../../../../../model/strapi/strapi-models';
import {
  EnrollBox,
  EnrollContentBox,
  ActionButton,
  PendingButton,
  ManageButton,
  EnrollmentTitleTypography,
  EnrollmentContainer,
} from '../../details-screens-sections/styled-components/enrollment-bar/enrollment-bar.styled-components';
import { TitleTypography } from '../../../../strapi/title-description/title-description.styled-components';
import { Box, useTheme, useMediaQuery, Typography } from '@mui/material';
import { getTierSupportLevel } from '../../../../../helpers/tier-support-level-helper/tier-support-level-helper';
import { useTranslation } from 'react-i18next';

interface IServiceEnrollmentBarProps {
  title: string;
  onEnroll: () => void;
  onManage: () => void;
  onActivate: () => void;
  enrollmentStatus: EnrollmentStatus | undefined;
  rightRailData:
    | Maybe<ProviderServiceRightRailDynamicZone | ProviderProgramRightRailDynamicZone>[]
    | undefined
    | null;
  enrollWidget: ReactElement | null;
}

export const ServiceEnrollmentBar = ({
  title,
  onEnroll,
  onManage,
  onActivate,
  enrollmentStatus,
  rightRailData,
  enrollWidget,
}: IServiceEnrollmentBarProps): ReactElement => {
  const { t } = useTranslation();

  const isDisabled = enrollmentStatus === 'pending';
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const tierLevel = getTierSupportLevel();
  const canUserActivateService =
    enrollmentStatus === 'pending' && tierLevel !== null && tierLevel >= 2;

  const buttonText = () => {
    switch (enrollmentStatus) {
      case 'pending':
        return canUserActivateService
          ? t('addService.serviceEnrollmentBar.activateButtonText')
          : t('addService.serviceEnrollmentBar.pendingEnrollmentText');
      case 'active':
        return t('addService.serviceEnrollmentBar.manageServiceText');
      default:
        return t('addService.serviceEnrollmentBar.addButtonText');
    }
  };

  const enrollmentButton =
    enrollmentStatus === 'active' ? (
      <ManageButton onClick={onManage} disabled={isDisabled} variant='outlined' color='primary'>
        {buttonText()}
      </ManageButton>
    ) : canUserActivateService ? (
      <ActionButton onClick={onActivate} variant='contained' color='primary'>
        {buttonText()}
      </ActionButton>
    ) : enrollmentStatus === 'pending' ? (
      <PendingButton disabled={isDisabled}>{buttonText()}</PendingButton>
    ) : (
      <ActionButton onClick={onEnroll} disabled={isDisabled} variant='contained' color='primary'>
        {buttonText()}
      </ActionButton>
    );

  const costPerPharmacySectionHeading = isDesktop
    ? rightRailData?.map((elem) => {
        switch (elem?.__typename) {
          case 'ComponentProviderServiceContentTypesSectionHeading': {
            return <TitleTypography key={elem.id}>{elem.HeadingText}</TitleTypography>;
          }
          default:
            return null;
        }
      })
    : null;

  const costPerPharmacyShortText = isDesktop
    ? rightRailData?.map((elem) => {
        switch (elem?.__typename) {
          case 'ComponentProviderServiceContentTypesShortText': {
            return (
              <Box key={elem.id} marginBottom={theme.spacing(3)}>
                <Typography>{elem.shortText}</Typography>
              </Box>
            );
          }
          default:
            return null;
        }
      })
    : null;

  const enrollmentWidget = isDesktop ? enrollWidget : null;

  return (
    <EnrollmentContainer>
      <EnrollBox>
        <EnrollContentBox>
          <EnrollmentTitleTypography>{title}</EnrollmentTitleTypography>
          {enrollmentButton}
        </EnrollContentBox>
      </EnrollBox>
      <Box marginTop={theme.spacing(1)}>
        {costPerPharmacySectionHeading}
        {costPerPharmacyShortText}
        {enrollmentWidget}
      </Box>
    </EnrollmentContainer>
  );
};
