// Copyright 2021 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import { IPharmacyService } from '../../../../../model/pharmacy-service';
import { IWaitlistAddError } from '../../../../../model/waitlist-add';
import { serviceNameFromType } from '../../../../../selectors/service-name';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface IWaitlistErrorModalProps {
  failedItems: IWaitlistAddError[];
  services: IPharmacyService[];
}

export const WaitlistErrorContent = ({
  failedItems,
  services,
}: IWaitlistErrorModalProps): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation();

  const renderWaitlistErrorCard = (
    key: string,
    name: string,
    birthDate: string,
    phoneNumber: string,
    serviceRequested: string,
    reason: string
  ) => {
    return (
      <Box key={key} margin={theme.spacing(2)} display='flex' flexDirection='column'>
        <Typography style={{ fontWeight: 600 }}>{name}</Typography>
        <Typography>{birthDate}</Typography>
        <Typography>{phoneNumber}</Typography>
        <Typography>{serviceRequested}</Typography>
        <Typography>{reason}</Typography>
      </Box>
    );
  };

  const renderCards = () => {
    return failedItems.map((failedItem) => {
      const item = failedItem.item;
      return renderWaitlistErrorCard(
        item.id,
        item.firstName + ' ' + item.lastName,
        t('waitlist.waitlistErrorModal.birthDateHeader', { date: item.dateOfBirth }),
        t('waitlist.waitlistErrorModal.phoneNumberHeader', { phoneNumber: item.phoneNumber }),
        t('waitlist.waitlistErrorModal.serviceRequestedHeader', {
          serviceRequested: serviceNameFromType(item.serviceType, services),
        }),
        t('waitlist.waitlistErrorModal.issueHeader', { issue: failedItem.reason })
      );
    });
  };

  return (
    <Box display='flex' flexDirection='column' flexShrink={1}>
      {renderCards()}
    </Box>
  );
};
