// Copyright 2020 Prescryptive Health, Inc.

export interface IDefaultPolicyTemplates {
  GET: IRetryPolicy;
  PUT: IRetryPolicy;
  POST: IRetryPolicy;
  DELETE: IRetryPolicy;
  PATCH: IRetryPolicy;
}

export interface IRetryPolicy {
  remaining: number;
  pause: number;
  getNextRetry: (response: Response, policy: IRetryPolicy) => IRetryPolicy;
}

export const DefaultRetryPolicyTemplate: IRetryPolicy = {
  getNextRetry: (
    // tslint:disable-next-line:variable-name
    _response: Response,
    policy: IRetryPolicy
  ) => ({ ...policy }),
  pause: 0,
  remaining: 0,
};

export const DefaultPolicyTemplates: IDefaultPolicyTemplates = {
  DELETE: DefaultRetryPolicyTemplate,
  GET: DefaultRetryPolicyTemplate,
  POST: DefaultRetryPolicyTemplate,
  PUT: DefaultRetryPolicyTemplate,
  PATCH: DefaultRetryPolicyTemplate,
};

export const getPolicy = (key: keyof IDefaultPolicyTemplates): IRetryPolicy => {
  return DefaultPolicyTemplates[key];
};
