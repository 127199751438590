// Copyright 2021 Prescryptive Health, Inc.

import { Box } from '@mui/material';
import React, { ReactElement } from 'react';
import { HeadingTypography } from './section-heading.styled-components';

interface ISectionHeadingProps {
  headingText: string | null | undefined;
}

export const SectionHeading = ({
  headingText,
}: ISectionHeadingProps): ReactElement => {
  return (
    <Box>
      <HeadingTypography variant='h6'>{headingText}</HeadingTypography>
    </Box>
  );
};
