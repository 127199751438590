import { useAuth0 } from '@auth0/auth0-react';
import { Button, Tooltip } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportType } from '../../../../api/v1/appointments-get/appointments-get';
import { getToken } from '../../../../helpers/http-client/get-token';
import { useNotificationContext } from '../../../../hooks/use-notification-context/use-notification-context.hook';
import { usePostAuthScreenContext } from '../../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { useLocationContext } from '../../../../providers/location/use-location-context.hook';
import { useProviderContext } from '../../../../providers/provider/use-provider-context.hook';
import { notificationSetDispatch } from '../../../../state/notification/dispatch/notification-set.dispatch';
import {
  csvAppointmentsGetAsyncAction,
  ICsvAppointmentsGetAsyncActionArgs,
} from '../../../../state/reports/async-actions/csv-appointments-get.async-action';
import { reportsClearDispatch } from '../../../../state/reports/dispatch/reports-clear.dispatch';
import { reportsReducer } from '../../../../state/reports/reports.reducer';
import { defaultReportsState } from '../../../../state/reports/reports.state';
import { csvDownloader } from '../../../csv-downloader/csv-downloader';
import { buildReportFileName } from './all-appointments-download.helper';

interface IAllAppointementsDownloadButtonsProps {
  isDisabled: boolean;
  startDate: Date | undefined;
  endDate: Date | undefined;
}

export const AllAppointmentsDownloadButton = ({
  isDisabled,
  startDate,
  endDate,
}: IAllAppointementsDownloadButtonsProps) => {
  const { t } = useTranslation();
  const { authProvider, busyDispatch, busyState, configState, errorDispatch, telemetryService } =
    usePostAuthScreenContext({ defaultContent: {} });

  const {
    providerState: { locations, currentProvider },
  } = useProviderContext();

  const {
    locationState: { location, services },
  } = useLocationContext();

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const [{ isReportReady, reportCsv, reportFileName }, reportsDispatch] = useReducer(
    reportsReducer,
    defaultReportsState
  );

  const { notificationDispatch } = useNotificationContext();

  useEffect(() => {
    if (!isReportReady) {
      return;
    }

    const recordCount = reportCsv.length - 1;

    if (recordCount > 0) {
      csvDownloader(reportCsv, reportFileName ?? 'report.csv');
    }

    reportsClearDispatch(reportsDispatch);

    notificationSetDispatch(
      notificationDispatch,
      recordCount > 0
        ? t('reports.appointmentsDownload.reportCompleteMessage', { recordCount })
        : t('reports.appointmentsDownload.noRecordsFound')
    );
  }, [isReportReady]);

  const isBusy = busyState.busyCount > 0;
  const reportOptionsMap = new Map<ReportType | '', string>([
    ['all', t('reports.appointmentsDownload.allAppointmentsReport')],
  ]);

  const onDownloadClick = async () => {
    if (startDate === undefined || endDate === undefined || !location) {
      return;
    }

    const fileName = buildReportFileName(
      'all',
      reportOptionsMap,
      currentProvider,
      location.id,
      locations,
      startDate
    );

    const args: ICsvAppointmentsGetAsyncActionArgs = {
      locationId: location.id,
      reportType: 'all',
      startDate: startDate,
      endDate: endDate,
      reportFileName: fileName,
      services,
      authProvider,
      busyDispatch,
      configState,
      errorDispatch,
      reportsDispatch,
      telemetryService,
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
    };

    await csvAppointmentsGetAsyncAction(args);
  };

  return (
    <Tooltip title={t('reports.appointmentsDownload.downloadTooltip')}>
      <Button
        data-testid='all-appointments-download-button'
        variant='contained'
        onClick={onDownloadClick}
        color='primary'
        disabled={isDisabled || isBusy}
      >
        {t('reports.appointmentsDownload.downloadButtonLabel')}
      </Button>
    </Tooltip>
  );
};
