// Copyright 2021 Prescryptive Health, Inc.

import { Typography, useTheme, useMediaQuery } from '@mui/material';
import React, { ReactElement } from 'react';
import { useEffect } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ProviderProgram } from '../../../../../model/strapi/strapi-models';
import { IPageViewTelemetry } from '@microsoft/applicationinsights-web';

import { EnrollmentStatus } from '../../../../../model/enrolled-service-type';
import { useTelemetryContext } from '../../../../../providers/telemetry/use-telemetry-context.hook';
import {
  ScrollableBox,
  ProgramDetailsScreenWrapper,
  TitleBox,
} from './program-details.screen.styled-components';
import { useProviderContext } from '../../../../../providers/provider/use-provider-context.hook';
import { EnrollWidget } from '../../details-screens-sections/enroll-widget/enroll-widget';
import { CostsPerPharmacy } from '../../details-screens-sections/costs-per-pharmacy/costs-per-pharmacy';
import { BackButtonBreadcrumb } from '../../details-screens-sections/back-button-breadcrumb/back-button-breadcrumb';
import { Intro } from '../../details-screens-sections/intro/intro';
import { WhatYouWillGet } from '../../details-screens-sections/what-you-will-get/what-you-will-get';
import { ThingsToConsider } from '../../details-screens-sections/things-to-consider/things-to-consider';
import { MarketingResources } from '../../details-screens-sections/marketing-resources/marketing-resources';
import { HowItWorks } from '../../details-screens-sections/how-it-works/how-it-works';
import { Faqs } from '../../details-screens-sections/faqs/faqs';
import { ProgramEnrollmentBar } from '../program-enrollment-bar/program-enrollment-bar';
import { useTranslation } from 'react-i18next';

interface IProgramDetailsScreenProps {
  onGoBack: () => void;
  onEnroll: () => void;
  onActivate: () => void;
  enrollmentStatus: EnrollmentStatus | undefined;
  data: Maybe<ProviderProgram>;
}

export const ProgramDetailsScreen = ({
  onGoBack,
  onEnroll,
  onActivate,
  enrollmentStatus,
  data,
}: IProgramDetailsScreenProps): ReactElement => {
  const { t } = useTranslation();
  const { telemetryService } = useTelemetryContext();
  const {
    providerState: { currentProvider },
  } = useProviderContext();

  const title = data?.Title ?? '';
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

  useEffect(() => {
    const telemetryPayload: IPageViewTelemetry = {
      name: 'add-programs-detail-page',
      properties: {
        programIdentifier: data?.ProgramIdentifier as string,
        provider: currentProvider?.name,
      },
    };

    telemetryService.trackPageView(telemetryPayload);
  }, []);

  const onEnrollPress = () => {
    telemetryService.trackEvent('click', {
      name: 'add-programs-detail-addProgramButton',
      programIdentifier: data?.ProgramIdentifier as string,
      provider: currentProvider?.name,
    });
    onEnroll();
  };

  const onGoBackPress = () => {
    telemetryService.trackEvent('click', {
      name: 'add-programs-detail-nav-breadscrumb',
      programIdentifier: data?.ProgramIdentifier as string,
      provider: currentProvider?.name,
    });

    onGoBack();
  };

  const onDownloadPress = () => {
    telemetryService.trackEvent('click', {
      name: 'add-programs-detail-downloadInstructionsButton',
      programIdentifier: data?.ProgramIdentifier as string,
      provider: currentProvider?.name,
    });
  };

  const dektopEnrollmentWidget = isDesktop ? (
    <EnrollWidget
      enrollmentData={data?.EnrollProcess}
      helpData={data?.HelpSection}
      enrollmentStatus={enrollmentStatus}
    />
  ) : null;

  const enrollWidget = !isDesktop ? (
    <EnrollWidget
      enrollmentData={data?.EnrollProcess}
      helpData={data?.HelpSection}
      enrollmentStatus={enrollmentStatus}
    />
  ) : null;

  return (
    <ProgramDetailsScreenWrapper>
      <ScrollableBox>
        <BackButtonBreadcrumb
          onGoBack={onGoBackPress}
          backButtonText={t('addService.ProgramDetailsScreen.backButtonText')}
        />
        <TitleBox>
          <Typography variant='h1'>{title}</Typography>
        </TitleBox>
        <Intro introData={data?.Intro} />
        {enrollWidget}
        <HowItWorks howItWorksData={data?.HowItWorks} />
        <CostsPerPharmacy costsPerPharmacyData={data?.PharmacyCosts} />
        <WhatYouWillGet whatYouGetData={data?.WhatYouGet} />
        <ThingsToConsider thingsToConsiderData={data?.ThingsToConsider} />
        <MarketingResources marketingResourcesData={data?.MarketingResources} />
        <Faqs faqData={data?.Faq} />
      </ScrollableBox>
      <ProgramEnrollmentBar
        title={title}
        onEnroll={onEnrollPress}
        onActivate={onActivate}
        onDownload={onDownloadPress}
        enrollmentStatus={enrollmentStatus}
        rightRailData={data?.RightRail}
        enrollWidget={dektopEnrollmentWidget}
      />
    </ProgramDetailsScreenWrapper>
  );
};
