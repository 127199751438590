// Copyright 2020 Prescryptive Health, Inc.

import React, { FunctionComponent, ReactElement } from 'react';
import { useTelemetryContext } from '../../providers/telemetry/use-telemetry-context.hook';
import { ErrorBoundaryCatch } from './error-boundary-catch';

export const ErrorBoundary: FunctionComponent = ({
  children,
}): ReactElement => {
  const { telemetryService } = useTelemetryContext();

  return (
    <ErrorBoundaryCatch telemetryService={telemetryService}>
      {children}
    </ErrorBoundaryCatch>
  );
};
