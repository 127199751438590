// Copyright 2020 Prescryptive Health, Inc.

import { ErrorStatus } from '../error.state';
import { IErrorAction } from './error.action';

export type IErrorResetAction = IErrorAction<'ERROR_RESET'>;

export const errorResetAction = (): IErrorResetAction => ({
  type: 'ERROR_RESET',
  payload: { status: ErrorStatus.none, title: undefined, content: undefined },
});
