// Copyright 2021 Prescryptive Health, Inc.

import { Avatar } from '@mui/material';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { authenticatedRouteConfig } from '../../navigation/authenticated-route-config';

interface IPrescryptiveLogoProps {
  width?: string;
}

export const PrescryptiveLogo = ({ width }: IPrescryptiveLogoProps): ReactElement => {
  const logoWidth = width ? width : '160px';

  const logoURI = process.env.PUBLIC_URL + '/media/logo/Prescryptive-white.svg';

  return (
    <Link to={authenticatedRouteConfig.root.createUrl()}>
      <Avatar
        src={logoURI}
        variant='square'
        alt='prescryptive logo'
        style={{ width: logoWidth, height: 'auto' }}
      />
    </Link>
  );
};
