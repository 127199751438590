// Copyright 2021 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import { ITimeRangeAndTypes } from '../../../model/availability-time';
import { IServiceHours } from '../../../model/location-availability';
import { IPharmacyService } from '../../../model/pharmacy-service';
import { Box, Chip, Typography, useTheme } from '@mui/material';
import { availabilityTimeRangeToString } from '../../../formatters/availability-time/availability-time.formatters';
import { useTranslation } from 'react-i18next';
import { weekdays } from '../../../content/weekdays.content';

interface IServiceHoursViewProps {
  serviceHours: IServiceHours[];
  serviceList: IPharmacyService[];
}

export const ServiceHoursView = ({
  serviceHours,
  serviceList,
}: IServiceHoursViewProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  const servicesMap = serviceTypesMap(serviceList ?? []);

  const renderHoursAndServices = (serviceHourRanges: ITimeRangeAndTypes[]) => {
    if (serviceHourRanges.length === 0) {
      return (
        <Typography id='not-available'>
          {t('serviceHours.serviceHoursView.notAvailable')}
        </Typography>
      );
    }

    return serviceHourRanges.map((serviceHourRange) => {
      const { startTime, endTime, serviceTypes } = serviceHourRange;
      const key = `${startTime.hour}${startTime.minute}${endTime.hour}${endTime.minute}`;
      const formattedTimeRange = availabilityTimeRangeToString(serviceHourRange);
      return (
        <Box display='flex' flexDirection='column' key={key}>
          <Typography>{formattedTimeRange}</Typography>
          <Box display='flex' flexWrap='wrap' gap={theme.spacing(1)}>
            {serviceTypes.map(
              (service) =>
                servicesMap.get(service) && (
                  <Chip key={service} label={servicesMap.get(service)} variant='outlined' />
                )
            )}
          </Box>
        </Box>
      );
    });
  };

  const renderServiceHoursContent = () => {
    if (serviceHours.length === 0) {
      return <Typography>{t('serviceHours.serviceHoursView.notSet')}</Typography>;
    }
    return weekdays.map((weekday, dayIndex) => {
      const timeRangesAndServices = serviceHours[dayIndex];
      const dayServiceHourRanges = renderHoursAndServices(timeRangesAndServices.timeRanges);
      return (
        <Box key={weekday} display='flex' flexWrap='wrap' mb={theme.spacing(4)}>
          <Typography style={{ width: '11ch' }}>{weekday}</Typography>
          <Box flex='1'>{dayServiceHourRanges}</Box>
        </Box>
      );
    });
  };

  return (
    <Box display='flex' flexDirection='column'>
      {renderServiceHoursContent()}
    </Box>
  );
};

function serviceTypesMap(services: IPharmacyService[]): Map<string, string> {
  const serviceTypesMapResult = new Map<string, string>();

  services.map((service) => {
    serviceTypesMapResult.set(service.serviceType, service.name);
  });

  return serviceTypesMapResult;
}
