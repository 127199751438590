import React, { useState, useEffect, ReactElement, useMemo, Fragment } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useProviderContext } from '../../../../../../providers/provider/use-provider-context.hook';
import { ProviderUser } from '../../../../../../model/provider-user';
import { ErrorView } from '../../../../../error-view/error-view';
import { EmptyViewMessage } from '../../../../../text/messages/empty-view.message';
import { IActionButtonOption, UserManagementActionButton } from './user-management-action-button';
import { getEmailParts } from '../../../../../../helpers/string-helper/string-helper';
import { InviteUserDialog } from './invite-user-dialog/invite-user-dialog';
import { Box, BoxProps } from '@mui/material';
import { TabPanel } from '../../../../../tabs/tab-panel/tab-panel';
import { PhoneNumberFormatter } from '../../../../../../formatters/phone-number/phone-number.formatter';
import { IErrorResponse } from '../../../../../../api/error-response';
import { HttpStatusCodesEnum } from '../../../../../../api/http-status-codes';
import { usePostAuthScreenContext } from '../../../../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { Button, TablePagination, TableSortLabel, Typography } from '@mui/material';
import { ActiveChip, InactiveChip, Toolbar } from './user-management.tab-panel.styled-components';
import {
  IProviderUsersGetAsyncActionArgs,
  providerUsersGetAsyncAction,
} from '../../../../../../state/provider/async-actions/provider-users-get.async-action';
import {
  ModifyUserDialog,
  ModifyUserDialogTypes,
  SubmittedUserDataForAdd,
  SubmittedUserDataForUpdate,
} from './modify-user-dialog/modify-user-dialog';
import {
  IProviderUsersAddAsyncActionArgs,
  providerUsersAddAsyncAction,
} from '../../../../../../state/provider/async-actions/provider-users-add.async-action';
import {
  IProviderUsersUpdateAsyncActionArgs,
  providerUsersUpdateAsyncAction,
} from '../../../../../../state/provider/async-actions/provider-users-update.async-action';
import { AddExistingUser } from './add-existing-user/add-existing-user';
import { getTierSupportLevel } from '../../../../../../helpers/tier-support-level-helper/tier-support-level-helper';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getToken } from '../../../../../../helpers/http-client/get-token';
import { useTranslation } from 'react-i18next';
import { ConfirmPasswordResetDialog } from './confirm-password-reset-dialog/confirm-password-reset-dialog';
import { useLazyQuery } from '@apollo/client';
import { GET_PASSWORD_RESET_LINK } from './get-password-reset-link.query';
import pharmacyPortalServiceGraphQLApolloClient from '../../../../../../../src/init-pharmacy-portal-service-graphql-client';
import pharmacyPortalServiceGraphQLApolloClientAAD from '../../../../../../../src/init-pharmacy-portal-service-graphql-client-aad';
interface ModifyUserDialogState {
  dialogType: ModifyUserDialogTypes;
  doRenderDialog: boolean;
  isOpen: boolean;
}

export interface ApiResponseMessageState {
  message: string;
  messageType: string | undefined;
}

const defaultModifyUserDialogState = {
  dialogType: 'addUser',
  doRenderDialog: false,
  isOpen: false,
} as ModifyUserDialogState;

const defaultApiResponseMessageState = {
  message: '',
  messageType: '',
} as ApiResponseMessageState;

export interface IUserManagementTabPanelProps extends BoxProps {
  providerName?: string;
}

const DEFAULT_PAGE_SIZE = 5;
const DEFAULT_ORDER_BY: keyof ProviderUser = 'fullName';
const DEFAULT_PAGE = 0;
const DEFAULT_ORDER: Order = 'asc';
const PAGE_SIZE_OPTIONS = [5, 10, 25, 50];

export const UserManagementTabPanel = ({
  providerName,
  ...other
}: IUserManagementTabPanelProps): ReactElement => {
  const { t } = useTranslation();
  const { auth0Migration } = useFlags();

  const [
    fetchPasswordResetLink,
    { data: getPasswordResetLinkData, loading: getPasswordResetLinkLoading },
  ] = useLazyQuery(GET_PASSWORD_RESET_LINK, {
    client: auth0Migration
      ? pharmacyPortalServiceGraphQLApolloClient
      : pharmacyPortalServiceGraphQLApolloClientAAD,
    onError: () => {
      setGetPasswordError(true);
    },
  });

  const { busyDispatch, errorDispatch, telemetryService, authProvider, configState } =
    usePostAuthScreenContext({ defaultContent: {} });

  const { getAccessTokenSilently } = useAuth0();

  const [order, setOrder] = useState<Order>(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = useState<keyof ProviderUser>(DEFAULT_ORDER_BY);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE);

  const handleChangePage = (_e: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (_e: React.MouseEvent<unknown>, property: keyof ProviderUser) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler =
    (property: keyof ProviderUser) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const [usersAreLoading, setUsersAreLoading] = useState(false);
  const [usersFailedToLoad, setUsersFailedToLoad] = useState(false);
  const [userDialog, setUserDialog] = useState<ModifyUserDialogState>(defaultModifyUserDialogState);
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<ProviderUser>();
  const [apiResponseMessage, setApiResponseMessage] = useState(defaultApiResponseMessageState);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmPasswordRestModalOpen, setConfirmPasswordRestModalOpen] = useState(false);
  const [passwordResetLink, setPasswordResetLink] = useState<string>('');
  const [getPasswordError, setGetPasswordError] = useState<boolean>(false);

  const {
    providerDispatch,
    providerState: { providerUserIdsMap, users },
  } = useProviderContext();

  const fetchUsersForProvider = () => {
    if (!providerName) {
      return;
    }
    const args: IProviderUsersGetAsyncActionArgs = {
      authProvider,
      busyDispatch,
      configState,
      errorDispatch,
      telemetryService,
      providerDispatch,
      providerName,
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
    };
    setUsersFailedToLoad(false);
    setUsersAreLoading(true);
    void providerUsersGetAsyncAction(args)
      .catch(() => {
        setUsersFailedToLoad(true);
      })
      .finally(() => setUsersAreLoading(false));
  };

  useEffect(() => {
    fetchUsersForProvider();
    setRowsPerPage(DEFAULT_PAGE_SIZE);
    setPage(DEFAULT_PAGE);
    setOrder(DEFAULT_ORDER);
    setOrderBy(DEFAULT_ORDER_BY);
    telemetryService.trackPageView({
      name: 'settings-user-management',
      properties: {
        provider: providerName,
      },
    });
  }, [providerName]);

  useEffect(() => {
    if (getPasswordResetLinkData?.passwordChangeTicket) {
      setPasswordResetLink(getPasswordResetLinkData?.passwordChangeTicket);
    }
  }, [getPasswordResetLinkData]);

  const closeModifyUserDialog = () => {
    setApiResponseMessage(defaultApiResponseMessageState);
    setUserDialog(defaultModifyUserDialogState);
  };

  const closeInviteUserDialog = () => {
    setIsInviteDialogOpen(false);
    closeModifyUserDialog();
  };

  const providerUsers = useMemo<ProviderUser[]>(() => {
    if (!providerName) {
      return [];
    }
    const providerUserIds = providerUserIdsMap[providerName];
    return providerUserIds?.map((id) => users[id]) || [];
  }, [providerName, providerUserIdsMap]);

  const openAddUserDialog = () => {
    setUserDialog({
      dialogType: 'addUser',
      doRenderDialog: true,
      isOpen: true,
    });
  };
  const openUpdateUserDialog = (userId: string) => {
    setSelectedUser(providerUsers.find((x) => x.id === userId));
    setUserDialog({
      dialogType: 'updateUser',
      doRenderDialog: true,
      isOpen: true,
    });
  };
  const openInviteDialog = (userId: string) => {
    setSelectedUser(providerUsers.find((x) => x.id === userId));
    setIsInviteDialogOpen(true);
  };

  const openGetPasswordResetDialog = (userId: string) => {
    setSelectedUser(providerUsers.find((x) => x.id === userId));
    setConfirmPasswordRestModalOpen(true);
  };

  const getPasswordResetLink = async () => {
    void (await fetchPasswordResetLink({
      variables: {
        email: selectedUser?.principalName,
      },
    }));
    setPasswordResetLink('');
  };

  const onModifyUserConfirm = async (
    userData: SubmittedUserDataForAdd | SubmittedUserDataForUpdate
  ) => {
    setApiResponseMessage(defaultApiResponseMessageState);
    if (!providerName) {
      return;
    }
    const providerUser = {
      ...userData,
      principalName: 'userName' in userData ? userData.userName : undefined,
      id: selectedUser?.id,
      phoneNumber: PhoneNumberFormatter.formatForApi(userData?.phoneNumber ?? ''),
    } as ProviderUser;

    try {
      setIsLoading(true);
      if (userDialog.dialogType === 'addUser') {
        const args: IProviderUsersAddAsyncActionArgs = {
          authProvider,
          busyDispatch,
          configState,
          errorDispatch,
          telemetryService,
          providerDispatch,
          providerName,
          providerUser,
          getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
        };

        const addedUser = await providerUsersAddAsyncAction(args);

        setUserDialog({
          ...userDialog,
          doRenderDialog: true,
          isOpen: false,
        });
        setSelectedUser(addedUser);
        setIsInviteDialogOpen(true);
      } else if (userDialog.dialogType === 'updateUser') {
        const args: IProviderUsersUpdateAsyncActionArgs = {
          authProvider,
          busyDispatch,
          configState,
          errorDispatch,
          telemetryService,
          providerDispatch,
          providerName,
          providerUser,
          getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
        };
        await providerUsersUpdateAsyncAction(args);

        setUserDialog({
          ...userDialog,
          doRenderDialog: true,
          isOpen: false,
        });
        closeModifyUserDialog();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const errorResponse = error as IErrorResponse;
      if (
        errorResponse.message &&
        errorResponse.status &&
        errorResponse.status === HttpStatusCodesEnum.BAD_REQUEST
      ) {
        setApiResponseMessage({
          message: errorResponse.message,
          messageType: errorResponse.messageType,
        });
      } else {
        setApiResponseMessage({
          message: t('settings.userManagementTab.unknownErrorMessage'),
          messageType: '',
        });
      }
    }
  };

  if (usersFailedToLoad) {
    return (
      <TabPanel {...other}>
        <ErrorView onRetry={fetchUsersForProvider} />
      </TabPanel>
    );
  }

  if (usersAreLoading) {
    return (
      <TabPanel {...other}>
        <EmptyViewMessage content={t('settings.userManagementTab.loading')} />
      </TabPanel>
    );
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, providerUsers.length - page * rowsPerPage);
  const emptyRowHeight = 53;
  const headCells = [
    {
      id: 'fullName',
      label: t('settings.userManagementTab.nameTableHeader'),
    },
    {
      id: 'isActive',
      label: t('settings.userManagementTab.statusTableHeader'),
    },
    {
      id: 'role',
      label: t('settings.userManagementTab.roleTableHeader'),
    },
    {
      id: 'principalName',
      label: t('settings.userManagementTab.userNameTableHeader'),
    },
    {
      id: 'npi',
      label: t('settings.userManagementTab.npiTableHeader'),
    },
    {
      id: 'phoneNumber',
      label: t('settings.userManagementTab.phoneNumberTableHeader'),
    },
  ];

  const actionButtonOptions = [
    { name: 'Edit', onClick: openUpdateUserDialog },
    { name: 'Invite', onClick: openInviteDialog },
    { name: 'Get password reset link', onClick: openGetPasswordResetDialog },
  ] as IActionButtonOption[];

  const tierSupportLevel = getTierSupportLevel();
  const isAtLeastTier2Support = tierSupportLevel !== null && tierSupportLevel > 1;

  return (
    <TabPanel {...other}>
      <Toolbar>
        <Typography variant='h5' id='user-management-table-heading' component='div'>
          {t('settings.userManagementTab.pageTitle')}
        </Typography>
        <Box>
          {isAtLeastTier2Support && <AddExistingUser onSuccess={fetchUsersForProvider} />}
          <Button
            variant='text'
            color='primary'
            onClick={openAddUserDialog}
            startIcon={<AddCircleIcon />}>
            {t('settings.userManagementTab.addUserButton')}
          </Button>
        </Box>
      </Toolbar>
      <TableContainer>
        <Table aria-label='user table'>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}>
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id as keyof ProviderUser)}>
                    <Typography style={{ fontWeight: 'bold' }}>{headCell.label}</Typography>
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell align='right'>{''}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort<ProviderUser>(providerUsers, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow tabIndex={-1} key={row.id}>
                  <TableCell component='th' data-heap-redact-text='true'>
                    <Typography>{row.fullName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {row.isActive ? (
                        <ActiveChip label={t('settings.userManagementTab.active')} size='small' />
                      ) : (
                        <InactiveChip
                          label={t('settings.userManagementTab.inactive')}
                          size='small'
                        />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{row.role}</Typography>
                  </TableCell>
                  <TableCell data-heap-redact-text='true'>
                    <Typography>{row.principalName}</Typography>
                  </TableCell>
                  <TableCell data-heap-redact-text='true'>
                    <Typography>{row.npi}</Typography>
                  </TableCell>
                  <TableCell data-heap-redact-text='true'>
                    <Typography>{PhoneNumberFormatter.formatForUI(row.phoneNumber)}</Typography>
                  </TableCell>
                  <TableCell>
                    <UserManagementActionButton userId={row.id} options={actionButtonOptions} />
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: emptyRowHeight * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        component='div'
        count={providerUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {userDialog.doRenderDialog ? (
        <ModifyUserDialog
          isOpen={userDialog.isOpen}
          dialogTypes={userDialog.dialogType}
          onKeepPress={closeModifyUserDialog}
          onConfirmedPress={onModifyUserConfirm}
          emailDomain={
            userDialog.dialogType === 'updateUser'
              ? getEmailParts(selectedUser?.principalName)[1] ?? ''
              : `@${configState.newUserDomain}`
          }
          errorResponse={apiResponseMessage}
          isLoading={isLoading}
          user={userDialog.dialogType === 'updateUser' ? selectedUser : undefined}
          auth0Migration={auth0Migration}
        />
      ) : (
        <Fragment />
      )}
      <InviteUserDialog
        isOpen={isInviteDialogOpen}
        onKeepPress={closeInviteUserDialog}
        userName={selectedUser?.principalName ?? ''}
        temporaryPassword={selectedUser?.temporaryPassword ?? ''}
      />
      <ConfirmPasswordResetDialog
        open={confirmPasswordRestModalOpen}
        setOpen={setConfirmPasswordRestModalOpen}
        onContinue={getPasswordResetLink}
        passwordResetLink={passwordResetLink}
        setPasswordResetLink={setPasswordResetLink}
        getPasswordResetLinkLoading={getPasswordResetLinkLoading}
        setGetPasswordError={setGetPasswordError}
        getPasswordError={getPasswordError}
      />
    </TabPanel>
  );
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof ProviderUser>(
  order: Order,
  orderBy: Key
): (a: ProviderUser, b: ProviderUser) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
