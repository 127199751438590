// Copyright 2021 Prescryptive Health, Inc.

import { IPaymentSetting } from '../../../model/pharmacy-service';
import { ILocationAction } from './location.action';

export type IPharmacyServicePaymentUpdateActionPayload = {
  serviceType: string;
  price: number;
  paymentSettings: IPaymentSetting[];
};

export type IPharmacyServicePaymentUpdateAction = ILocationAction<
  'PHARMACY_SERVICE_PAYMENT_UPDATE',
  IPharmacyServicePaymentUpdateActionPayload
>;

export const pharmacyServicePaymentUpdateAction = (
  serviceType: string,
  newPriceCents: number,
  newPaymentSettings: IPaymentSetting[]
): IPharmacyServicePaymentUpdateAction => {
  return {
    type: 'PHARMACY_SERVICE_PAYMENT_UPDATE',
    payload: { serviceType, price: newPriceCents, paymentSettings: newPaymentSettings },
  };
};
