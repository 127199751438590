// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IServiceQuestion } from '../../../../../model/pharmacy-service';
import { usePostAuthScreenContext } from '../../../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { QuestionCard } from '../../../../cards/question/question.card';
import { pharmacyServiceQuestionDeleteAsyncAction } from '../../../../../state/location/async-actions/pharmacy-service-question-delete.async-action';
import { useLocationContext } from '../../../../../providers/location/use-location-context.hook';
import { pharmacyServiceQuestionAdd } from '../../../../../state/location/actions/pharmacy-services-question-add.action';
import { Box, Button, Typography, useTheme, Avatar } from '@mui/material';
import {
  StyledAdditionalQuestionsSectionContentImageBox,
  StyledAdditionalQuestionsSectionContentBox,
  StyledAdditionalQuestionsSectionDescriptionTypography,
  StyledAdditionalQuestionsSectionEndImageBox,
} from './additional-questions.section.styled-components';
import { useTranslation } from 'react-i18next';
import { getToken } from '../../../../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface IAdditionalQuestionsListItem {
  serviceQuestion: IServiceQuestion;
  isEditMode: boolean;
}

export interface IAdditionalQuestionsSectionProps {
  questions: IServiceQuestion[];
  serviceType: string;
}

export const AdditionalQuestionsSection = (
  props: IAdditionalQuestionsSectionProps
): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const { questions, serviceType } = props;
  const [isEditModeQuestions, setIsEditModeQuestions] = useState<{
    [id: string]: boolean;
  }>(
    questions.reduce((prev, question) => {
      const questionEditModes = { ...prev };
      questionEditModes[question.id] = false;
      return questionEditModes;
    }, {} as { [questionId: string]: boolean })
  );

  const { busyDispatch, errorDispatch, telemetryService, authProvider, configState } =
    usePostAuthScreenContext({ defaultContent: {} });

  const {
    locationDispatch,
    locationState: { location },
  } = useLocationContext();

  const updateEditMode = (id: string, value: boolean) => {
    const updated = { ...isEditModeQuestions, [id]: value };
    setIsEditModeQuestions(updated);
  };

  const onAddQuestionPress = () => {
    const newServiceQuestion: IServiceQuestion = {
      id: uuidv4(),
      questionText: '',
      isRequired: false,
      type: 'text',
    };

    updateEditMode(newServiceQuestion.id, true);
    locationDispatch(pharmacyServiceQuestionAdd(serviceType, newServiceQuestion));
  };

  const onRemovePress = async (questionId: string) => {
    if (!location) {
      return;
    }
    await pharmacyServiceQuestionDeleteAsyncAction({
      busyDispatch,
      errorDispatch,
      telemetryService,
      authProvider,
      configState,
      locationDispatch,
      locationId: location.id,
      serviceType,
      questionId,
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently)
    });
  };

  const renderQuestionCards = questions.map((question) => (
    <QuestionCard
      key={question.id}
      id={question.id}
      questionText={question.questionText}
      isRequired={question.isRequired}
      selectOptions={question.selectOptions}
      type={question.type}
      serviceType={serviceType}
      isEditMode={!!isEditModeQuestions[question.id]}
      toggleEditMode={updateEditMode}
      onRemovePress={onRemovePress}
    />
  ));

  const isAddQuestionDisabled = () => {
    for (const questionId in isEditModeQuestions) {
      if (isEditModeQuestions[questionId]) {
        return true;
      }
    }
    return false;
  };

  return (
    <Box component='section'>
      <Typography variant='h3' component='h3'>
        {t('services.additionalQuestions.heading')}
      </Typography>
      <Box>
        <Box display='flex'>
          <StyledAdditionalQuestionsSectionContentBox>
            <StyledAdditionalQuestionsSectionDescriptionTypography>
              {t('services.additionalQuestions.description')}
            </StyledAdditionalQuestionsSectionDescriptionTypography>
            <StyledAdditionalQuestionsSectionDescriptionTypography>
              {t('services.additionalQuestions.additionalDescription')}
            </StyledAdditionalQuestionsSectionDescriptionTypography>
          </StyledAdditionalQuestionsSectionContentBox>
          <StyledAdditionalQuestionsSectionContentImageBox>
            <Avatar
              sx={{
                height: '100%',
                width: 'auto',
                flexShrink: 0.5,
                flexGrow: 1,
                '& .MuiSlider-img': {
                  maxHeight: '320px',
                  maxWidth: '320px',
                  minWidth: '217px',
                  minHeight: '217px',
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                },
              }}
              alt='Additional Questions'
              src={process.env.PUBLIC_URL + '/media/additional-questions.4f8e3519.png'}
              variant='square'
            />
          </StyledAdditionalQuestionsSectionContentImageBox>
        </Box>
        <Box marginBottom={theme.spacing(6)}>{renderQuestionCards}</Box>
        <Button
          onClick={onAddQuestionPress}
          color='primary'
          variant='outlined'
          disabled={isAddQuestionDisabled()}
          fullWidth={true}
        >
          {t('services.additionalQuestions.addQuestionButtonLabel')}
        </Button>
      </Box>
      <StyledAdditionalQuestionsSectionEndImageBox>
        <Avatar
          sx={{
            height: '100%',
            width: 'auto',
            flexShrink: 0.5,
            flexGrow: 1,
            '& .MuiSlider-img': {
              maxHeight: '320px',
              maxWidth: '320px',
              minWidth: '217px',
              minHeight: '217px',
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            },
          }}
          alt='Additional Questions'
          src={process.env.PUBLIC_URL + '/media/additional-questions.4f8e3519.png'}
          variant='square'
        />
      </StyledAdditionalQuestionsSectionEndImageBox>
    </Box>
  );
};
