// Copyright 2020 Prescryptive Health, Inc.

import { useContext } from 'react';
import {
  IAppointmentsContext,
  AppointmentsContext,
} from './appointments.context';

export const useAppointmentsContext = (): IAppointmentsContext =>
  useContext<IAppointmentsContext>(AppointmentsContext);
