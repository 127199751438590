// Copyright 2021 Prescryptive Health, Inc.

import { Box, Typography } from '@mui/material';
import { styled as muiStyled } from '@mui/material';

export const EnrollWidgetBox = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('tablet')]: {
    display: 'grid',
    gap: theme.spacing(6),
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.up('xl')]: {
    gap: '0px',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const HelpDynamicTitleTypography = muiStyled(Typography)(({ theme }) => ({
  fontWeight: 700,
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.up('xl')]: {
    display: 'none',
  },
}));
