// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { useProviderContext } from '../../../providers/provider/use-provider-context.hook';
import { PostAuthPrimaryScreenContainer } from '../../screen-containers/post-auth-primary/post-auth-primary.screen-container';
import { GET_PROVIDER_SERVICES_QUERY } from './get-provider-services.query';
import { ServiceTopBar } from './service-top-bar/service-top-bar';
import { StyledContentBox } from './add-service.screen.styled-components';
import { usePostAuthScreenContext } from '../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { EmptyViewMessage } from '../../text/messages/empty-view.message';
import { IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import {
  ServiceCardCarousel,
  ServiceCardCarouselItem,
} from './service-card-carousel/service-card-carousel';
import {
  Maybe,
  ProviderProgram,
  ProviderProgramIntroDynamicZone,
  ProviderService,
  ProviderServiceGroup,
  ProviderServiceIntroDynamicZone,
} from '../../../model/strapi/strapi-models';
import { authenticatedRouteConfig } from '../../../navigation/authenticated-route-config';
import { ErrorView } from '../../error-view/error-view';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const AddServiceScreen = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    authState: { services, programs },
    telemetryService,
  } = usePostAuthScreenContext({ defaultContent: {} });

  document.title = t('addService.addServiceScreen.pageTitle');

  const {
    providerState: { currentProvider, enrolledServiceTypes, enrolledPrograms },
  } = useProviderContext();

  const { loading, error, data } = useQuery<{
    providerServiceGroups: ProviderServiceGroup[];
  }>(GET_PROVIDER_SERVICES_QUERY, {
    onError: ({ networkError }) => {
      const e = networkError as Error;
      telemetryService.trackException(e);
    },
  });

  useEffect(() => {
    const telemetryPayload: IPageViewTelemetry = {
      name: 'add-services',
      properties: { provider: currentProvider?.name },
    };

    telemetryService.trackPageView(telemetryPayload);
  }, []);

  useEffect(() => {
    if (data && services) {
      const listOfServiceTypes = data.providerServiceGroups.reduce(
        // TypeScript is not a fan of the reduce method - tests can be found in add-service.screen.test
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (accumulatedServiceTypes: string[], currentProviderServiceGroup: ProviderServiceGroup) => {
          const serviceTypes = currentProviderServiceGroup?.provider_services?.map(
            (s) => s?.ServiceType
          );
          if (serviceTypes && accumulatedServiceTypes) {
            return [...accumulatedServiceTypes, ...serviceTypes];
          }
          return accumulatedServiceTypes;
        },
        [] as string[]
      );

      // TypeScript is not a fan of this short cut used to remove duplicets from an array
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const filteredServiceTypesNoDuplicates = [...new Set(listOfServiceTypes)];

      const servicesServiceTypes = services.map((s) => s.serviceType);

      const difference = filteredServiceTypesNoDuplicates
        .filter((x) => !servicesServiceTypes.includes(x))
        .concat(servicesServiceTypes.filter((x) => !filteredServiceTypesNoDuplicates.includes(x)));

      if (difference.length > 0) {
        difference.forEach((mismatchedServiceType: string) =>
          telemetryService.trackEvent('content-data-service-type-mismatch', {
            serviceType: mismatchedServiceType,
            provider: currentProvider?.name,
          })
        );
      }
    }
  }, [data, services]);

  const createServiceCardCarousels = () => {
    if (!data) {
      return null;
    }

    const sortedCarousels = data?.providerServiceGroups
      .slice()
      .sort((a: ProviderServiceGroup, b: ProviderServiceGroup) =>
        a.SortOrder && b.SortOrder ? a.SortOrder - b.SortOrder : 0
      );

    return sortedCarousels
      .map((group) => {
        const filteredAndSortedServices = group?.provider_services
          ?.filter((ps) => ps && services.some((s) => ps.ServiceType === s.serviceType))
          .sort((a, b) => {
            const aGroupOrder = a?.providerServiceGroupOrder;
            const bGroupOrder = b?.providerServiceGroupOrder;

            if (aGroupOrder === bGroupOrder) {
              return 0;
            } else if (aGroupOrder === null) {
              return 1;
            } else if (bGroupOrder === null) {
              return -1;
            } else {
              return (aGroupOrder ?? 0) - (bGroupOrder ?? 0);
            }
          }) as ProviderService[] | undefined;

        const filteredAndSortedPrograms = group?.provider_programs
          ?.filter((pp) => pp && programs.some((x) => pp.ProgramIdentifier === x.identifier))
          ?.sort(titleCompare) as ProviderProgram[] | undefined;

        const providerServiceCardItems = filteredAndSortedServices
          ? getServiceCardItems(filteredAndSortedServices)
          : [];

        const providerProgramCardItems = filteredAndSortedPrograms
          ? getProgramCardItems(filteredAndSortedPrograms)
          : [];

        const allCardItems = providerServiceCardItems?.concat(providerProgramCardItems ?? []);

        return allCardItems && allCardItems.length > 0 ? (
          <ServiceCardCarousel
            key={group.id}
            title={group.GroupName}
            serviceCardItems={allCardItems}
          />
        ) : null;
      })
      .filter((x) => x);
  };

  const getServiceCardItems = (
    providerServices?: ProviderService[]
  ): ServiceCardCarouselItem[] | undefined => {
    return providerServices
      ?.filter((ps) => ps)
      .map((providerService) => {
        const enrolledServiceType = enrolledServiceTypes.find(
          (enrolledService) => enrolledService.serviceType === providerService?.ServiceType
        );
        const navigateToServices = () => {
          void navigate(
            authenticatedRouteConfig.myServices.createUrl(providerService?.ServiceType)
          );
        };

        const navigateToServiceDetails = () => {
          void navigate(authenticatedRouteConfig.addServiceDetails.createUrl(providerService?.id));
        };

        return {
          cardType: 'ServiceCard',
          entityName: providerService?.Title,
          introText: providerService?.Description as string,
          keyBenefits: providerService?.Intro as ProviderServiceIntroDynamicZone[],
          enrolledEntity: enrolledServiceType,
          onViewDetails: navigateToServiceDetails,
          onManage: navigateToServices,
        } as ServiceCardCarouselItem;
      });
  };

  const getProgramCardItems = (
    providerPrograms?: ProviderProgram[]
  ): ServiceCardCarouselItem[] | undefined => {
    return providerPrograms
      ?.filter((pp) => pp)
      .map((providerProgram) => {
        const enrolledProgram = enrolledPrograms.find(
          (enrolledProgramItem) =>
            enrolledProgramItem.programIdentifier === providerProgram?.ProgramIdentifier
        );

        const navigateToProgramDetails = () => {
          void navigate(authenticatedRouteConfig.addProgramDetails.createUrl(providerProgram?.id));
        };

        const onManage = () => {
          return;
        };
        return {
          cardType: 'ProgramCard',
          entityName: providerProgram?.Title,
          introText: providerProgram?.Description as string,
          keyBenefits: providerProgram?.Intro as ProviderProgramIntroDynamicZone[],
          enrolledEntity: enrolledProgram,
          onViewDetails: navigateToProgramDetails,
          onManage,
        } as ServiceCardCarouselItem;
      });
  };

  if (loading) {
    return (
      <PostAuthPrimaryScreenContainer>
        <EmptyViewMessage content={t('addService.addServiceScreen.loading')} />
      </PostAuthPrimaryScreenContainer>
    );
  }

  if (error || !data) {
    return (
      <PostAuthPrimaryScreenContainer>
        <ErrorView />
      </PostAuthPrimaryScreenContainer>
    );
  }

  return (
    <PostAuthPrimaryScreenContainer style={{ padding: 0 }}>
      <ServiceTopBar />
      <StyledContentBox>
        <Typography variant='h1'>{t('addService.addServiceScreen.title')}</Typography>
        {createServiceCardCarousels()}
      </StyledContentBox>
    </PostAuthPrimaryScreenContainer>
  );
};

const titleCompare = <T extends Pick<ProviderService, 'Title'>>(
  obj1: Maybe<T>,
  obj2: Maybe<T>
): number => (obj1 && obj2 ? obj1.Title.localeCompare(obj2.Title) : 0);
