// Copyright 2020 Prescryptive Health, Inc.

import { AuthenticationProvider } from '../authentication/authentication-provider';

export enum SessionPhase {
  preSession,
  preAuth,
  postAuth,
}

export interface ISessionState {
  readonly phase: SessionPhase;
  readonly authProvider?: AuthenticationProvider;
}
