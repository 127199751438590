// Copyright 2021 Prescryptive Health, Inc.

import { getNewDate } from '../../../helpers/dates/get-new-date/get-new-date';
import { IPharmacyService } from '../../../model/pharmacy-service';
import { IStoreLocation } from '../../../model/store-location';
import { ILocationAction } from './location.action';

export type ILocationSetAction = ILocationAction<'LOCATION_SET'>;

export const locationSetAction = (
  location: IStoreLocation,
  services: IPharmacyService[],
  currentSelectedDate?: Date
): ILocationSetAction => ({
  type: 'LOCATION_SET',
  payload: {
    location,
    services,
    areServicesStale: false,
    locationSelectedDate: currentSelectedDate ?? getNewDate(),
  },
});
