// Copyright 2021 Prescryptive Health, Inc.

import { Reducer } from 'react';
import { IPaymentReportsState } from './payment-reports.state';
import { PaymentReportsAction } from './actions/payment-reports.action';

export type PaymentReportsReducer = Reducer<
  IPaymentReportsState,
  PaymentReportsAction
>;

export const paymentReportsReducer: PaymentReportsReducer = (
  state: IPaymentReportsState,
  action: PaymentReportsAction
): IPaymentReportsState => {
  const payload = action.payload;
  return { ...state, ...payload };
};
