import { styled as muiStyled } from '@mui/material';
import { topBarHeight, drawerWidth } from './styles';

export const Main = muiStyled('main')(({ theme }) => ({
  flexGrow: 1,
  maxWidth: '100%',
  overflow: 'auto',
  height: `calc(100vh - ${topBarHeight}px)`,
  position: 'relative',
  marginLeft: 0,
  [theme.breakpoints.up('desktop')]: {
    height: '100vh',
    maxWidth: `calc(100% - ${drawerWidth}px)`,
    marginLeft: 292,
  },
}));
