// Copyright 2022 Prescryptive Health, Inc.

import { Box, Typography, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PostAuthPrimaryScreenContainer } from '../../screen-containers/post-auth-primary/post-auth-primary.screen-container';

export const NotFoundScreen = (): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <PostAuthPrimaryScreenContainer>
      <Box textAlign='center' display='flex' flexDirection='column' gap={theme.spacing(8)}>
        <Typography style={{ fontSize: '6rem' }}>{t('notFoundPage.notFoundPageHeader')}</Typography>
        <Typography variant='h1'>{t('notFoundPage.notFoundPageTitle')}</Typography>
        <Typography> {t('notFoundPage.notFoundPageSubTitle')}</Typography>
        <br />
        <Link to='/'>{t('notFoundPage.notFoundPageLink')}</Link>
      </Box>
    </PostAuthPrimaryScreenContainer>
  );
};
