// Copyright 2021 Prescryptive Health, Inc.

import { ReactNode } from 'react';
import { ErrorStatus } from '../error.state';
import { IErrorSetAction } from './fatal-error-set.action';

export const nonFatalErrorSetAction = (
  nonFatalErrorTitle: string,
  nonFatalErrorContent: ReactNode
): IErrorSetAction => ({
  type: 'ERROR_SET',
  payload: {
    status: ErrorStatus.nonFatal,
    title: nonFatalErrorTitle,
    content: nonFatalErrorContent,
  },
});
