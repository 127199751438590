// Copyright 2021 Prescryptive Health, Inc.

import { IPharmacyService } from '../../../model/pharmacy-service';
import { ILocationAction } from './location.action';

export type IPharmacyServiceUpdateActionPayload = {
  serviceType: string;
  updatedPharmacyService: Partial<IPharmacyService>;
};

export type IPharmacyServicesUpdateAction = ILocationAction<
  'PHARMACY_SERVICE_UPDATE',
  IPharmacyServiceUpdateActionPayload
>;

export const pharmacyServiceUpdateActionPayload = (
  serviceType: string,
  updatedPharmacyService: Partial<IPharmacyService>
): IPharmacyServicesUpdateAction => {
  return {
    type: 'PHARMACY_SERVICE_UPDATE',
    payload: {
      serviceType,
      updatedPharmacyService,
    },
  };
};
