// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useState } from 'react';
import { Box, Card, CardActionArea, CardContent } from '@mui/material';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { v4 as uuidv4 } from 'uuid';
import { AddEditLocationModal } from '../../modals/add-edit/add-edit-location.modal';
import { useTranslation } from 'react-i18next';

interface IAddLocationCardProps {
  providerName: string;
}

export const AddLocationCard = ({ providerName }: IAddLocationCardProps): ReactElement => {
  const { t } = useTranslation();
  const [addLocationDialogOpen, setAddLocationDialogOpen] = useState(false);

  const handleAddLocationClick = () => {
    setAddLocationDialogOpen(true);
  };

  const handleCloseAddLocationDialog = () => {
    setAddLocationDialogOpen(false);
  };

  return (
    <>
      <Card variant='outlined'>
        <CardActionArea
          style={{ height: '100%', textAlign: 'center' }}
          onClick={handleAddLocationClick}
          disableRipple={true}
        >
          <CardContent>
            <Box>
              <AddLocationIcon color='primary' fontSize='large' />
            </Box>
            {t('settings.pharmacyLocationCardContent.addButtonText')}
          </CardContent>
        </CardActionArea>
      </Card>
      <AddEditLocationModal
        providerName={providerName}
        isOpen={addLocationDialogOpen}
        key={uuidv4()}
        onClosePress={handleCloseAddLocationDialog}
      />
    </>
  );
};
