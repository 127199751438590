// Copyright 2021 Prescryptive Health, Inc.

import { Box, styled, Typography } from '@mui/material';

export const StyledMultiColumnContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(12),
}));

export const StyledMultiColumnHeaderTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(8),
}));

export const StyledMultiColumnDescriptionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  maxWidth: theme.spacing(175),
  marginBottom: theme.spacing(12),
}));

export const StyledMultiColumnCardsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('tablet')]: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  [theme.breakpoints.up('desktop')]: {
    flexWrap: 'unset',
  },
}));

export const StyledMultiColumnCardContainer = styled(Box)(({ theme }) => ({
  maxWidth: theme.spacing(75),
  [theme.breakpoints.up('desktop')]: {
    maxWidth: theme.spacing(95),
  },
}));
