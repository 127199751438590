// Copyright 2021 Prescryptive Health, Inc.

import { gql } from '@apollo/client';

export const GET_PROVIDER_PROGRAM_IDENTIFIER_QUERY = gql`
  query getProviderServiceGroups {
    providerServiceGroups {
      id
      provider_programs {
        __typename
        id
        ProgramIdentifier
      }
    }
  }
`;
