// Copyright 2022 Prescryptive Health, Inc.

import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { unauthenticatedRouteConfig } from '../../navigation/unauthenticated-route-config';

export const LandingBanner = (): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { auth0Migration } = useFlags();

  return (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='center'
      width='100%'
      paddingY={theme.spacing(4)}
      bgcolor={theme.palette.purpleScale['300']}
      zIndex='2'
    >
      <Typography color={theme.palette.common.white}>
        {auth0Migration ? t('lander.banner.flaggedContent') : t('lander.banner.content')}
      </Typography>
      <Link
        to={unauthenticatedRouteConfig.learnMore.createUrl()}
        style={{ textDecorationColor: theme.palette.common.white, paddingLeft: theme.spacing(1) }}
      >
        <Typography color={theme.palette.common.white}>{t('lander.banner.learnMore')}</Typography>
      </Link>
    </Box>
  );
};
