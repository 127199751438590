// Copyright 2020 Prescryptive Health, Inc.

import { IProvider } from '../../../model/provider';
import { IProviderAction } from './provider.action';

export type ICurrentProviderSetAction = IProviderAction<'CURRENT_PROVIDER_SET'>;

export const currentProviderSetAction = (
  provider: IProvider
): ICurrentProviderSetAction => ({
  type: 'CURRENT_PROVIDER_SET',
  payload: { currentProvider: provider },
});
