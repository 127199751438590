// Copyright 2020 Prescryptive Health, Inc.

import { Box, Typography, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { SecondaryHeader } from '../../secondary-header/secondary-header';
import ENV from '../../../helpers/environment-variables';

export const ErrorScreen = (): ReactElement => {
  const theme = useTheme();

  return (
    <Box>
      <SecondaryHeader />
      <Box
        padding={theme.spacing(6)}
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        gap={theme.spacing(8)}
        textAlign='center'
      >
        <Typography variant='h1'>Unexpected Error</Typography>
        <Typography>{"We're sorry, an error occurred."}</Typography>
        <Typography>
          Please try reloading or contact{' '}
          <span style={{ fontWeight: 'bold' }}>{ENV.SUPPORT_EMAIL}</span> if the problem continues.
        </Typography>
      </Box>
    </Box>
  );
};
