// Copyright 2021 Prescryptive Health, Inc.
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import {
  isoDateStringToLocalDate,
  isoDateTimeStringToDate,
} from '../../../helpers/dates/date-helper/date.helper';
import { IQuestionAndAnswer } from '../../../model/question-and-answer';
import {
  IAppointmentDetails,
  IProcedureQuestion,
  IClaimInformation,
} from '../../../model/appointment-details';
import { IProcedureQuestionAndAnswer } from '../../../model/procedure-question';
import {
  IAppointmentGetResponse,
  IAppointmentGetResponseProcedureQuestion,
  IAppointmentGetResponseProcedureResultsQuestionAndAnswer,
  IAppointmentGetResponseQuestionAndAnswer,
} from './appointment-get.response';

function appointmentDetailsFromResponse(
  responseData: Partial<IAppointmentGetResponse>
): IAppointmentDetails {
  const scheduledStart = responseData.scheduledStart;

  const appointment: IAppointmentDetails = {
    appointmentId: responseData.appointmentId ?? '',
    date: isoDateTimeStringToDate(scheduledStart ?? ''),
    durationMinutes: responseData.duration ?? 0,
    orderNumber: responseData.orderNumber ?? '',
    serviceType: responseData.serviceType ?? '',
    productOrService: responseData.productOrService ?? '',
    firstName: responseData.firstName ?? '',
    lastName: responseData.lastName ?? '',
    phoneNumber: responseData.phoneNumber ?? '',
    birthDate: isoDateStringToLocalDate(responseData.dateOfBirth),
    useAutoEdiClaimsFlag: responseData.useAutoEdiClaimsFlag ?? false,
    email: responseData.email ?? '',
    address: {
      line1: responseData.address1 ?? '',
      line2: responseData.address2,
      city: responseData.city ?? '',
      county: responseData.county ?? '',
      state: responseData.state ?? '',
      zip: responseData.zip ?? '',
    },
    memberId: responseData.memberId ?? '',
    rxGroup: responseData.rxGroup ?? '',
    rxBin: responseData.rxBin ?? '',
    rxPcn: responseData.rxPcn ?? '',
    personCode: responseData.personCode ?? '',
    performingFacility: {
      name: responseData.performingFacilityName ?? '',
      clia: responseData.performingFacilityClia ?? '',
      address: {
        line1: responseData.performingFacilityAddress1 ?? '',
        city: responseData.performingFacilityCity ?? '',
        state: responseData.performingFacilityState ?? '',
        zip: responseData.performingFacilityZip ?? '',
      },
      phoneNumber: responseData.performingFacilityPhoneNumber ?? '',
    },
    questions: responseQuestionAndAnswerMap(responseData.questions),
    procedure: responseProcedureQuestionMap(responseData.procedure),
    procedureResults: responseProcedureResultsQuestionAndAnswerMap(responseData.procedureResults),
    claimInformation: responseClaimInformationMap(responseData),
  };

  return appointment;
}

function responseQuestionAndAnswerMap(
  responseQuestionsAndAnswers: IAppointmentGetResponseQuestionAndAnswer[] = []
): IQuestionAndAnswer[] {
  return responseQuestionsAndAnswers.map((responseQuestion) => {
    const questionAndAnswer: IQuestionAndAnswer = {
      questionId: responseQuestion.questionId ?? '',
      question: responseQuestion.questionText ?? '',
      answer: responseQuestion.answer ?? '',
    };
    return questionAndAnswer;
  });
}

function responseProcedureQuestionMap(
  responseProcedureQuestions: IAppointmentGetResponseProcedureQuestion[] = []
): IProcedureQuestion[] {
  return responseProcedureQuestions.map((responseProcedureQuestion) => {
    const procedureQuestion: IProcedureQuestion = {
      questionId: responseProcedureQuestion.questionId ?? '',
      question: responseProcedureQuestion.questionText ?? '',
      type: responseProcedureQuestion.type ?? '',
      options:
        responseProcedureQuestion.options?.map((responseProcedureQuestionOptions) => {
          return {
            optionId: responseProcedureQuestionOptions.optionId ?? '',
            option: responseProcedureQuestionOptions.optionText ?? '',
            groupId: responseProcedureQuestionOptions.groupId,
          };
        }) ?? [],
      placeholder: responseProcedureQuestion.placeholder ?? '',
    };
    return procedureQuestion;
  });
}

export function responseProcedureResultsQuestionAndAnswerMap(
  responseProcedureQuestionAndAnswer: IAppointmentGetResponseProcedureResultsQuestionAndAnswer[] = []
): IProcedureQuestionAndAnswer[] {
  return responseProcedureQuestionAndAnswer.map((responseProcedureResultsQuestionAndAnswer) => {
    const procedureQuestion: IProcedureQuestionAndAnswer = {
      questionId: responseProcedureResultsQuestionAndAnswer.questionId ?? '',
      question: responseProcedureResultsQuestionAndAnswer.questionText ?? '',
      answerId: responseProcedureResultsQuestionAndAnswer.answerId ?? '',
      answerType: responseProcedureResultsQuestionAndAnswer.type ?? 'text',
      answer: responseProcedureResultsQuestionAndAnswer.answerText ?? '',
    };
    return procedureQuestion;
  });
}

export function responseClaimInformationMap(
  responseData: Partial<IAppointmentGetResponse>
): IClaimInformation | null {
  if (responseData.claimInformation === null) return null;

  return {
    authorizationNumber: responseData.claimInformation?.authorizationNumber ?? '',
    claimId: responseData.claimInformation?.claimId ?? '',
    processedDate: responseData.claimInformation?.processedDate ?? '',
    prescriberNationalProviderId: responseData.claimInformation?.prescriberNationalProviderId ?? '',
    productOrServiceId: responseData.claimInformation?.productOrServiceId ?? '',
    providerLegalName: responseData.claimInformation?.providerLegalName ?? '',
  };
}

export { appointmentDetailsFromResponse };
