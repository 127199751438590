// Copyright 2021 Prescryptive Health, Inc.

import { HttpStatusCodesEnum } from '../../http-status-codes';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { call } from '../../call';
import { ApiErrors } from '../../errors/api-errors';
import { IWaitlistGetResponse } from './waitlist-get.response';
import { IWaitlistItem, WaitlistItemStatus } from '../../../model/waitlist-item';
import { isoDateStringToLocalDate } from '../../../helpers/dates/date-helper/date.helper';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export interface IWaitlistGetResult {
  waitlist: IWaitlistItem[];
}

export async function waitlistGet(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  locationId: string,
  pageNumber: number,
  pageSize: number,
  serviceType = ''
): Promise<IWaitlistGetResult> {
  const pageNumberAsString = pageNumber.toString();
  const pageSizeAsString = pageSize.toString();

  const url = buildUrl(apiConfig, 'waitlist', {
    ':locationId': locationId,
    ':pageNumber': pageNumberAsString,
    ':pageSize': pageSizeAsString,
    ':serviceType': serviceType,
  });

  const headers = buildBearerAuthHeaders(authToken);

  const response: Response = await call(url, undefined, 'GET', headers);
  if (!response.ok) {
    if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
      throw new PharmacyPortalError(
        ApiErrors.resourceNotFound(url),
        ApiErrorCode.WAITLIST_GET,
        PharmacyPortalErrorType.API_ERROR,
        HttpStatusCodesEnum.NOT_FOUND
      );
    }

    throw new PharmacyPortalError(
      ApiErrors.waitlistGet(response.statusText),
      ApiErrorCode.WAITLIST_GET,
      PharmacyPortalErrorType.API_ERROR,
      response.status
    );
  }

  if (response.status === HttpStatusCodesEnum.NO_CONTENT) {
    return { waitlist: [] };
  }

  return await resultFromResponse(response);
}

async function resultFromResponse(response: Response): Promise<IWaitlistGetResult> {
  const responseData = (await response.json()) as IWaitlistGetResponse[];
  if (!responseData) {
    throw new PharmacyPortalError(
      ApiErrors.waitlistGet(ApiErrors.unexpectedResponseDataFormat),
      ApiErrorCode.WAITLIST_GET,
      PharmacyPortalErrorType.API_ERROR,
      response.status
    );
  }

  const result: IWaitlistGetResult = {
    waitlist: [],
  };

  result.waitlist = responseData.map((responseWaitlistItem) => {
    const responseInvitation = responseWaitlistItem.invitation;
    const invitationEndDate = responseInvitation ? new Date(responseInvitation.end) : undefined;

    const waitlistItem: IWaitlistItem = {
      id: responseWaitlistItem.identifier ?? '',
      firstName: responseWaitlistItem.firstName ?? '',
      lastName: responseWaitlistItem.lastName ?? '',
      birthDate: isoDateStringToLocalDate(responseWaitlistItem.dateOfBirth ?? ''),
      serviceType: responseWaitlistItem.serviceType ?? '',
      phoneNumber: responseWaitlistItem.phoneNumber ?? '',
      isGlobalWaitlist: !responseWaitlistItem.location,
      status: mapWaitlistItemStatus(responseWaitlistItem.status),
      invitationEndDate,
    };
    return waitlistItem;
  });

  return result;
}

const mapWaitlistItemStatus = (responseStatus?: string): WaitlistItemStatus | undefined => {
  if (!responseStatus) {
    return undefined;
  }

  switch (responseStatus) {
    case 'invited':
      return 'invited';

    case 'fulfilled':
      return 'fulfilled';

    case 'canceled':
      return 'canceled';

    default:
      return 'unknown';
  }
};
