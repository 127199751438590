// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { Box, useTheme } from '@mui/material';
import { useTelemetryContext } from '../../../providers/telemetry/use-telemetry-context.hook';
import { Maybe, ProviderServiceIntroDynamicZone } from '../../../model/strapi/strapi-models';
import {
  KeyBenefitsTypography,
  StyledManageButton,
  EnrollableCard,
  ServiceIntroTypography,
  ServiceCardActions,
  BasicButton,
  ServiceNameTypography,
  PendingChip,
} from './enrollable.card.styled-components';
import { useProviderContext } from '../../../providers/provider/use-provider-context.hook';
import { IEnrollableCardProps } from './enrollable-card-props';
import { useTranslation } from 'react-i18next';

export interface IEnrollableServiceCardProps extends IEnrollableCardProps {
  keyBenefits: Maybe<ProviderServiceIntroDynamicZone>[] | null | undefined;
  onManage: () => void;
}

export const EnrollableServiceCard = ({
  introText,
  entityName,
  keyBenefits,
  enrolledEntity,
  onViewMore,
  onManage,
}: IEnrollableServiceCardProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { telemetryService } = useTelemetryContext();
  const {
    providerState: { currentProvider },
  } = useProviderContext();

  const isEnrolled = enrolledEntity !== undefined;
  const isPending = enrolledEntity?.enrollmentStatus === 'pending';

  const styledViewDetailsButtonVariant = isEnrolled ? 'text' : 'contained';

  const keyBenefitsComponent = keyBenefits?.map((benefit, index) => {
    if (benefit && benefit.__typename === 'ComponentProviderServiceContentTypesValueProp') {
      const key = `${benefit.id}${index}`;
      const zNotationSpot = '\u2981';
      return (
        <KeyBenefitsTypography key={key}>
          {t('enrollableCard.enrollableCard.bulletedText', {
            info: benefit.valuePropTitle ?? '',
            zNotationSpot,
          })}
        </KeyBenefitsTypography>
      );
    }
    return null;
  });

  const onViewMorePress = () => {
    telemetryService.trackEvent('click', {
      name: 'add-services-list-cardViewMoreLink',
      serviceName: entityName,
      provider: currentProvider?.name,
    });
    onViewMore();
  };

  const onManagePress = () => {
    telemetryService.trackEvent('click', {
      name: 'add-services-list-cardManageButton',
      serviceName: entityName,
      provider: currentProvider?.name,
    });
    onManage();
  };

  return (
    <EnrollableCard variant='outlined'>
      <ServiceIntroTypography>{introText ?? ''}</ServiceIntroTypography>
      <ServiceNameTypography variant='h3'>
        {isPending && (
          <PendingChip
            size='small'
            clickable={false}
            label={t('enrollableCard.enrollableCard.pendingLabel')}
          />
        )}
        {entityName}
      </ServiceNameTypography>
      <Box
        display='flex'
        flexWrap='wrap'
        flexDirection='row'
        marginBottom={theme.spacing(6)}
        height='62px'
        overflow='hidden'
      >
        {keyBenefitsComponent}
      </Box>
      <ServiceCardActions disableSpacing={true}>
        <BasicButton
          onClick={onViewMorePress}
          variant={styledViewDetailsButtonVariant}
          color='primary'
          size='small'
        >
          {t('enrollableCard.enrollableCard.viewMoreButtonLabel')}
        </BasicButton>
        {isEnrolled && (
          <StyledManageButton
            onClick={onManagePress}
            variant='outlined'
            color='primary'
            size='small'
          >
            {t('enrollableCard.enrollableCard.manageButtonLabel')}
          </StyledManageButton>
        )}
      </ServiceCardActions>
    </EnrollableCard>
  );
};
