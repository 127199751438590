// Copyright 2021 Prescryptive Health, Inc.

import { ProviderUser } from '../../../model/provider-user';
import { IProviderAction } from './provider.action';

export type IProviderUsersAddActionPayload = {
  providerName: string;
  user: ProviderUser;
};

export type IProviderUsersAddAction = IProviderAction<
  'PROVIDER_USERS_ADD',
  IProviderUsersAddActionPayload
>;

export const providerUsersAddAction = (
  providerName: string,
  user: ProviderUser
): IProviderUsersAddAction => ({
  type: 'PROVIDER_USERS_ADD',
  payload: { providerName, user },
});
