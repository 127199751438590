// Copyright 2021 Prescryptive Health, Inc.

import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { ProviderDispatch } from '../dispatch/provider.dispatch';
import { providerLocationAdd } from '../../../api/v1/provider-location-add/provider-location-add';
import { IProviderLocationAddRequest } from '../../../api/v1/provider-location-add/provider-location-add.request';
import { locationAddDispatch } from '../dispatch/store-location-add.dispatch';
import { logApiError } from '../../../api/errors/log-api-error';

export interface ILocationAddAsyncActionArgs extends IApiAsyncActionArgs {
  providerName: string;
  locationName: string;
  address1: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  providerDispatch: ProviderDispatch;
}

export const locationAddAsyncAction = async (args: ILocationAddAsyncActionArgs): Promise<void> => {
  const {
    providerName,
    locationName,
    address1,
    city,
    state,
    zipCode,
    phoneNumber,
    providerDispatch,
    telemetryService,
    configState,
    authProvider,
    getAuthToken,
  } = args;

  try {
    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    const addProviderLocationRequest: IProviderLocationAddRequest = {
      providerName,
      name: locationName,
      address1,
      city,
      state,
      zip: zipCode,
      phoneNumber,
    };

    telemetryService.trackEvent('store-location-add', addProviderLocationRequest);

    const result = await providerLocationAdd(
      configState.apis.pharmacyPortal,
      accessToken,
      addProviderLocationRequest
    );
    locationAddDispatch(providerDispatch, result);
  } catch (error) {
    logApiError(telemetryService, error);
    return Promise.reject(error);
  }
};
