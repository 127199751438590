import { gql } from '@apollo/client';

export const GET_PRICING_REPORTS_FILES_QUERY = gql`
  query getPricingReportFiles($locationId: ID!) {
    pricingReportFiles(locationId: $locationId) {
      fileName
      createdOn
      size
      downloadUri
    }
  }
`;

export interface IPricingReportFile {
  fileName: string;
  createdOn: string;
  size: string;
  downloadUri: string;
}
