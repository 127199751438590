// Copyright 2021 Prescryptive Health, Inc.

import { buildBearerAuthHeaders, buildUrl } from '../../api.helper';
import { call } from '../../call';
import { HttpStatusCodesEnum } from '../../http-status-codes';
import dateFormatter from '../../../formatters/dates/date.formatter';
import { IWaitlistItem } from '../../../model/waitlist-item';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { ApiErrors } from '../../errors/api-errors';
import { IWaitlistAddRequest } from './waitlist-add.request';
import { PhoneNumberFormatter } from '../../../formatters/phone-number/phone-number.formatter';
import { IWaitlistAdd, IWaitlistAddError } from '../../../model/waitlist-add';
import { IWaitlistAddResponse } from './waitlist-add.response';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export async function waitlistAdd(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  locationId: string,
  waitlistItems: IWaitlistItem[]
): Promise<IWaitlistAdd> {
  const url = buildUrl(apiConfig, 'waitlistAdd', { ':locationId': locationId });
  const headers = buildBearerAuthHeaders(authToken);

  const body: IWaitlistAddRequest[] = waitlistItems.map((waitlistItem) => {
    const addedCustomer: IWaitlistAddRequest = {
      identifier: waitlistItem.id,
      firstName: waitlistItem.firstName,
      lastName: waitlistItem.lastName,
      phoneNumber: PhoneNumberFormatter.formatForApi(waitlistItem.phoneNumber),
      dateOfBirth: dateFormatter.formatToYMD(waitlistItem.birthDate),
      serviceType: waitlistItem.serviceType,
    };
    return addedCustomer;
  });

  const response: Response = await call(url, body, 'PUT', headers);
  if (!response.ok) {
    if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
      throw new PharmacyPortalError(
        ApiErrors.resourceNotFound(url),
        ApiErrorCode.WAITLIST_ADD,
        PharmacyPortalErrorType.API_ERROR,
        HttpStatusCodesEnum.NOT_FOUND
      );
    }

    throw new PharmacyPortalError(
      ApiErrors.waitlistAdd(response.statusText),
      ApiErrorCode.WAITLIST_ADD,
      PharmacyPortalErrorType.API_ERROR,
      response.status
    );
  }

  return await getFailedFromResponse(response);
}

const getFailedFromResponse = async (response: Response): Promise<IWaitlistAdd> => {
  const responseData = (await response.json()) as IWaitlistAddResponse;
  if (!responseData) {
    throw new PharmacyPortalError(
      ApiErrors.waitlistAdd(ApiErrors.unexpectedResponseDataFormat),
      ApiErrorCode.WAITLIST_ADD,
      PharmacyPortalErrorType.API_ERROR,
      response.status
    );
  }

  const failedResponses = responseData.failed || [];
  const failed: IWaitlistAddError[] = failedResponses.map((failureResponse) => {
    return {
      item: failureResponse.item,
      reason: failureResponse.reason ?? '',
    };
  });

  return { failed };
};
