// Copyright 2021 Prescryptive Health, Inc.

import dateFormatter from '../../../../formatters/dates/date.formatter';
import { ReportType } from '../../../../api/v1/appointments-get/appointments-get';
import { IProvider } from '../../../../model/provider';
import { IStoreLocation } from '../../../../model/store-location';

export type ReportOptionsMap = Map<ReportType | '', string>;

function buildReportFileName(
  selectedReportType: ReportType,
  reportOptionsMap: ReportOptionsMap,
  provider: IProvider | undefined,
  selectedLocationId: string,
  locations: IStoreLocation[],
  selectedDate: Date
): string {
  const reportName = reportOptionsMap.get(selectedReportType) ?? '';
  const providerName = provider?.name ?? '';
  const address = getLocationAddress(selectedLocationId, locations);
  const formattedDate = dateFormatter.formatToYMD(selectedDate, '');
  return `${reportName}_${providerName}_${address}_${formattedDate}.csv`.replace(/\s/g, '-');
}

function getLocationAddress(locationId: string, locations: IStoreLocation[]): string {
  const foundLocation = locations.find((location) => location.id === locationId);
  return foundLocation?.address.line1 ?? '';
}

export { buildReportFileName };
