// Copyright 2021 Prescryptive Health, Inc.

export interface ILocalStorageKeys {
  isLogout: string;
  sessionNpi: string;
  sessionLotNumber: string;
  currentProvider: string;
  currentLocation: string;
  appointmentStatusFilter: string;
  pharmacyList: string;
  loginHint: string;
}

export const localStorageKeys: ILocalStorageKeys = {
  isLogout: 'isLogout',
  sessionNpi: 'sessionNpi',
  sessionLotNumber: 'sessionLotNumber',
  currentLocation: 'currentLocation',
  currentProvider: 'currentProvider',
  appointmentStatusFilter: 'appointmentStatusFilter',
  pharmacyList: 'pharmacyList',
  loginHint: 'loginHint',
};
