// Copyright 2021 Prescryptive Health, Inc.

import { Box, styled } from '@mui/material';

export const ValuePropsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('tablet')]: {
    display: 'grid',
    gap: theme.spacing(4),
    gridTemplateColumns: '1fr 1fr',
  },
}));
