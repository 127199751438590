// Copyright 2021 Prescryptive Health, Inc.

import { ProviderUser } from '../../../model/provider-user';
import { IProviderAction } from './provider.action';

export type IProviderUsersUpdateActionPayload = {
  providerName: string;
  user: ProviderUser;
};

export type IProviderUserUpdateAction = IProviderAction<
  'PROVIDER_USERS_UPDATE',
  IProviderUsersUpdateActionPayload
>;

export const providerUsersUpdateAction = (
  providerName: string,
  user: ProviderUser
): IProviderUserUpdateAction => ({
  type: 'PROVIDER_USERS_UPDATE',
  payload: { providerName, user },
});
