// Copyright 2021 Prescryptive Health, Inc.

import { IStoreLocation } from "../../../model/store-location";
import { locationAddAction } from "../actions/store-location-add.action";
import { ProviderDispatch } from "./provider.dispatch";

export const locationAddDispatch = (
  dispatch: ProviderDispatch,
  storeLocation: IStoreLocation
): void => {
  dispatch(locationAddAction(storeLocation));
};
