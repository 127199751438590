// Copyright 2021 Prescryptive Health, Inc.
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BlockedReason,
  BlockedLabel,
  BlockedSlotCard,
} from './blocked-event-card.styled-components';

export interface IBlockedEventCardProps {
  reason: string;
  onClick: () => void;
}

export const BlockedEventCard: FunctionComponent<IBlockedEventCardProps> = ({
  reason,
  onClick = () => undefined,
}) => {
  const { t } = useTranslation();

  return (
    <BlockedSlotCard onClick={onClick} elevation={0}>
      <BlockedLabel variant='body2'>{t('appointments.blockedEventCard.blocked')}</BlockedLabel>
      <BlockedReason variant='subtitle2'>{reason}</BlockedReason>
    </BlockedSlotCard>
  );
};
