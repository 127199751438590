// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useEffect, useState } from 'react';
import { waitlistClearDispatch } from '../../../state/customers/waitlist/dispatch/waitlist-clear.dispatch';
import { usePostAuthScreenContext } from '../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { formatFullName } from '../../../formatters/user-formatter/user-formatter';
import { PhoneNumberFormatter } from '../../../formatters/phone-number/phone-number.formatter';
import { calculateAge } from '../../../helpers/dates/date-helper/date.helper';
import { IPharmacyService } from '../../../model/pharmacy-service';
import { useWaitlistContext } from '../../../providers/waitlist/use-waitlist-context.hook';
import { IStoreLocation } from '../../../model/store-location';
import { WaitlistFilterPanel } from '../../panels/waitlist-filter-panel/waitlist-filter-panel';
import { useBusyContext } from '../../../hooks/use-busy-context/use-busy-context.hook';
import { IWaitlistItem } from '../../../model/waitlist-item';
import { serviceNameFromType } from '../../../selectors/service-name';
import { EmptyViewMessage } from '../../text/messages/empty-view.message';
import { getNewDate } from '../../../helpers/dates/get-new-date/get-new-date';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PublishIcon from '@mui/icons-material/Publish';
import SendIcon from '@mui/icons-material/Send';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  waitlistItemStatusContentEn,
  waitlistItemStatusToString,
} from '../../../formatters/waitlists/waitlist-item-status.formatter';
import { AddressFormatter } from '../../../formatters/address-formatter/address.formatter';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  LabelDisplayedRowsArgs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  StyledWaitlistTableToolBar,
  StyledWaitlistTableToolBarAddButton,
  StyledWaitlistTablePagination,
  StyledEmptyWaitlistContainer,
  StyledWaitlistTableRowBoldTypography,
} from './waitlist.styled-components';
import {
  IWaitlistGetAsyncActionArgs,
  waitlistGetAsyncAction,
} from '../../../state/customers/waitlist/async-actions/waitlist-get.async-action';
import { getToken } from '../../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_PAGE = 0;
const PAGE_SIZE_OPTIONS = [25, 50, 100];

interface IWaitlistProps {
  selectedLocation: IStoreLocation | undefined;
  onAddToWaitlistPress: () => void;
  onImportToWaitlistPress: () => void;
  onInvitePress: (selectedIds: string[]) => void;
  onRemoveFromWaitlistPress: (waitlistItem: IWaitlistItem) => void;
  services: IPharmacyService[];
  isModalOpen?: boolean;
}

export const Waitlist = ({
  selectedLocation,
  onAddToWaitlistPress,
  onImportToWaitlistPress,
  onInvitePress,
  onRemoveFromWaitlistPress,
  services,
  isModalOpen,
}: IWaitlistProps): ReactElement => {
  const { t } = useTranslation();
  const { busyDispatch, configState, errorDispatch, telemetryService, authProvider } =
    usePostAuthScreenContext({ defaultContent: {} });

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const {
    busyState: { busyCount },
  } = useBusyContext();

  const { waitlistState, waitlistDispatch } = useWaitlistContext();

  const { waitlist, isStale } = waitlistState;

  const [selectedItemsMap, setSelectedItemsMap] = useState<Map<string, boolean>>(new Map());

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE);
  const [selectedServiceTypeFilter, setSelectedServiceTypeFilter] = useState('');

  const isDisabled = !!busyCount;

  const isNextButtonDisabled = waitlist.length < pageSize;

  const headCells = [
    {
      id: 'name',
      label: t('waitlist.waitlistTable.tableHeaderName'),
    },
    {
      id: 'age',
      label: t('waitlist.waitlistTable.tableHeaderAge'),
    },
    {
      id: 'phoneNumber',
      label: t('waitlist.waitlistTable.tableHeaderPhoneNumber'),
    },
    {
      id: 'serviceRequested',
      label: t('waitlist.waitlistTable.tableHeaderService'),
    },
    {
      id: 'status',
      label: t('waitlist.waitlistTable.tableHeaderStatus'),
    },
    {
      id: 'delete',
      label: '',
    },
  ];

  useEffect(() => {
    if (!selectedLocation) {
      waitlistClearDispatch(waitlistDispatch);
      return;
    }
    const waitlistGet = async () => {
      const args: IWaitlistGetAsyncActionArgs = {
        busyDispatch,
        configState,
        errorDispatch,
        locationId: selectedLocation.id,
        telemetryService,
        waitlistDispatch,
        authProvider,
        pageNumber,
        pageSize,
        serviceType: selectedServiceTypeFilter,
        getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
      };
      await waitlistGetAsyncAction(args);
    };
    void waitlistGet();
  }, [pageNumber, selectedLocation, selectedServiceTypeFilter]);

  useEffect(() => {
    setPageNumber(0);
  }, [selectedLocation]);

  useEffect(() => {
    const initialSelectedItemsMap = new Map<string, boolean>();
    waitlist.forEach(({ id }) => {
      initialSelectedItemsMap.set(id, false);
    });
    setSelectedItemsMap(initialSelectedItemsMap);
  }, [waitlist]);

  useEffect(() => {
    if (!selectedLocation) {
      return;
    }

    const waitlistGet = async () => {
      const args: IWaitlistGetAsyncActionArgs = {
        authProvider,
        busyDispatch,
        configState,
        errorDispatch,
        locationId: selectedLocation.id,
        telemetryService,
        waitlistDispatch,
        pageNumber,
        pageSize,
        serviceType: selectedServiceTypeFilter,
        getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
      };
      await waitlistGetAsyncAction(args);
    };
    if (isStale) {
      void waitlistGet();
    }
  }, [isStale]);

  useEffect(() => {
    if (!selectedLocation) {
      waitlistClearDispatch(waitlistDispatch);
      return;
    }
    const waitlistGet = async () => {
      const args: IWaitlistGetAsyncActionArgs = {
        busyDispatch,
        configState,
        errorDispatch,
        locationId: selectedLocation.id,
        telemetryService,
        waitlistDispatch,
        authProvider,
        pageNumber: 0,
        pageSize,
        serviceType: selectedServiceTypeFilter,
        getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
      };
      await waitlistGetAsyncAction(args);
    };
    void waitlistGet();
    setPageNumber(0);
  }, [pageSize]);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: string }>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(0);
  };

  const onServiceTypeFilterChange = (serviceType: string) => {
    setSelectedServiceTypeFilter(serviceType);
  };

  const hasUnselectedItem = Array.from(selectedItemsMap).some(([_, isSelected]) => !isSelected);

  const onSelectAllChange = () => {
    const updatedMap = new Map(selectedItemsMap);
    const setItemsToSelected = !!hasUnselectedItem;
    waitlist.forEach(({ id }) => updatedMap.set(id, setItemsToSelected));
    setSelectedItemsMap(updatedMap);
  };

  const hasSelectedItem = Array.from(selectedItemsMap).some(([_, isSelected]) => isSelected);

  const areBulkButtonsDisabled = isModalOpen || !hasSelectedItem;

  const onInviteButtonPress = () => {
    const selectedIds = Array.from(selectedItemsMap)
      .filter(([_, isSelected]) => isSelected)
      .map(([id]) => id);

    onInvitePress(selectedIds);
  };

  const displayNoLabel = (_paginationInfo: LabelDisplayedRowsArgs) => '';

  const renderTableBodyRow = (waitlistItem: IWaitlistItem) => {
    const {
      id,
      firstName,
      lastName,
      birthDate,
      phoneNumber,
      serviceType,
      status,
      invitationEndDate,
      isGlobalWaitlist,
    } = waitlistItem;

    const isSelected = !!selectedItemsMap.get(id);

    const onSelectChange = () => {
      const updatedMap = new Map(selectedItemsMap);
      updatedMap.set(id, !isSelected);
      setSelectedItemsMap(updatedMap);
    };

    const formattedName = formatFullName(firstName, lastName);
    const formattedAge = calculateAge(birthDate);
    const formattedPhoneNumber = PhoneNumberFormatter.formatForUI(phoneNumber);
    const formattedService = serviceNameFromType(serviceType, services);
    const isExpired = !!(invitationEndDate && invitationEndDate < getNewDate());
    const formattedStatus = waitlistItemStatusToString(
      status,
      isExpired,
      waitlistItemStatusContentEn
    );

    const onRemovePress = () => {
      onRemoveFromWaitlistPress(waitlistItem);
    };

    const removeAction =
      isGlobalWaitlist || (invitationEndDate && !isExpired) || status === 'canceled' ? null : (
        <IconButton
          aria-label={t('waitlist.waitlistTable.removeButtonAccessibilityLabel', {
            customerName: formattedName,
          })}
          onClick={onRemovePress}
          size='large'
        >
          <DeleteOutlineIcon fontSize='small' color='primary' />
        </IconButton>
      );

    return (
      <TableRow key={id} selected={isSelected} tabIndex={-1}>
        <TableCell>
          <Checkbox onChange={onSelectChange} checked={isSelected} color='primary' size='small' />
        </TableCell>
        <TableCell>
          <StyledWaitlistTableRowBoldTypography data-heap-redact-text='true'>
            {formattedName}
          </StyledWaitlistTableRowBoldTypography>
        </TableCell>
        <TableCell>
          <Typography data-heap-redact-text='true'>{formattedAge}</Typography>
        </TableCell>
        <TableCell>
          <Typography data-heap-redact-text='true'>{formattedPhoneNumber}</Typography>
        </TableCell>
        <TableCell>
          <Typography data-heap-redact-text='true'>{formattedService}</Typography>
        </TableCell>
        <TableCell>
          <Typography data-heap-redact-text='true'>{formattedStatus}</Typography>
        </TableCell>
        <TableCell>{removeAction}</TableCell>
      </TableRow>
    );
  };

  if (!selectedLocation) {
    return <EmptyViewMessage />;
  }

  return (
    <>
      <WaitlistFilterPanel
        onSelectService={onServiceTypeFilterChange}
        isDisabled={isDisabled}
        services={services}
      />
      <StyledWaitlistTableToolBar>
        <Box display='flex' flexDirection='row' flexGrow={1}>
          <StyledWaitlistTableToolBarAddButton
            startIcon={<AddCircleIcon />}
            onClick={onAddToWaitlistPress}
            disabled={!!isModalOpen}
            color='primary'
          >
            {t('waitlist.waitlistTable.addCustomerButton')}
          </StyledWaitlistTableToolBarAddButton>
          <Button
            startIcon={<PublishIcon />}
            onClick={onImportToWaitlistPress}
            disabled={!!isModalOpen}
            color='primary'
            size='large'
          >
            {t('waitlist.waitlistTable.importCustomersButton')}
          </Button>
        </Box>
        <Button
          startIcon={<SendIcon />}
          onClick={onInviteButtonPress}
          disabled={areBulkButtonsDisabled}
          color='primary'
          size='large'
        >
          {t('waitlist.waitlistTable.inviteButton')}
        </Button>
      </StyledWaitlistTableToolBar>
      {waitlist.length > 0 ? (
        <>
          <Box overflow='auto'>
            <TableContainer>
              <Table aria-label='waitlist table'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        onChange={onSelectAllChange}
                        checked={!hasUnselectedItem}
                        color='primary'
                        size='small'
                      />
                    </TableCell>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id}>
                        <StyledWaitlistTableRowBoldTypography>
                          {headCell.label}
                        </StyledWaitlistTableRowBoldTypography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {waitlist.map((row: IWaitlistItem) => renderTableBodyRow(row))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <StyledWaitlistTablePagination
            rowsPerPageOptions={PAGE_SIZE_OPTIONS}
            count={waitlist.length}
            rowsPerPage={pageSize}
            page={pageNumber}
            labelDisplayedRows={displayNoLabel}
            nextIconButtonProps={{ disabled: isNextButtonDisabled }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <StyledEmptyWaitlistContainer>
          <Box fontStyle='italic'>
            <Typography>
              {t('waitlist.waitlistTable.emptyWaitlistMessage')}{' '}
              <strong>{AddressFormatter.format(selectedLocation.address)}</strong>
            </Typography>
          </Box>
        </StyledEmptyWaitlistContainer>
      )}
    </>
  );
};
