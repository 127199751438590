// Copyright 2020 Prescryptive Health, Inc.

import React, { FunctionComponent, useReducer } from 'react';
import { BusyReducer, busyReducer } from '../../state/busy/busy.reducer';
import { IBusyState } from '../../state/busy/busy.state';
import { BusyContext } from './busy.context';

const initialState: IBusyState = { busyCount: 0 };

export const BusyContextProvider: FunctionComponent = ({ children }) => {
  const [busyState, busyDispatch] = useReducer<BusyReducer>(
    busyReducer,
    initialState
  );

  return (
    <BusyContext.Provider value={{ busyState, busyDispatch }}>
      {children}
    </BusyContext.Provider>
  );
};
