import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
/**
 * Adds a bearer token in the authorization header using the token retrieved from getAccessTokenSilently for every request sent by the given client
 * @param  {ApolloClient<NormalizedCacheObject>} client The client to add the Authorizatio bearer token to
 * @param  {()=>Promise<string>} getToken The async function that retrieves a bearer token
 * @returns void
 */
export function addBearerTokenToClientContext(
  client: ApolloClient<NormalizedCacheObject>,
  getToken: () => Promise<string>
): void {
  const link = setContext(async (_, prevContext) => {
    const token = await getToken();
    return {
      ...prevContext,
      headers: {
        ...prevContext.headers,
        authorization: `Bearer ${token}`,
      },
    };
  });
  client.setLink(link.concat(client.link));
}
