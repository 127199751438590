import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IDrugResultNdcPackageDrugResultForm,
  IDrugResultPackageSearchResult,
} from '../../../../model/drug-search';
import { getDrugDetails } from '../edit-prescription/edit-prescription';

interface IEditPrescriptionDialogProps {
  prescription: IDrugResultPackageSearchResult;
  isOpen: boolean;
  handlePrescription: (updatedForm: IDrugResultNdcPackageDrugResultForm) => void;
  handleClose: () => void;
}

export const EditPrescriptionDialog = ({
  prescription,
  isOpen,
  handlePrescription,
  handleClose,
}: IEditPrescriptionDialogProps) => {
  const { t } = useTranslation();

  const isFirstFormValid = prescription.forms && prescription.forms.length > 0;

  const [forms, setForms] = useState<DrugFormOptions | null>(null);
  const [selectedForm, setSelectedForm] = useState<string | null>(null);
  const [selectedDetails, setSelectedDetails] =
    useState<IDrugResultNdcPackageDrugResultForm | null>(null);

  const isApplyDisabled =
    selectedDetails?.modeDaysSupply === null || selectedDetails?.modeQuantity === null;

  const getDefaultForm = () => {
    if (forms && Object.keys(forms).length > 0) {
      const foundForm = prescription.forms.find(
        (form) => form.representativeNdc === prescription.representativeNdc
      );

      if (foundForm) {
        setSelectedForm(foundForm.formCode);
        setSelectedDetails(foundForm);
      } else {
        const form = forms[Object.keys(forms)[0]];
        setSelectedForm(form.formCode);
        setSelectedDetails(form.drugDetails[0]);
      }
    }
  };

  useEffect(() => {
    if (isFirstFormValid) {
      setForms(createFormsAndSort(prescription.forms));
    }
  }, [prescription]);

  useEffect(() => {
    getDefaultForm();
  }, [forms]);

  const isStateValid = forms && selectedForm && selectedDetails;
  const isFormsStateValid = forms !== null && selectedForm !== null;
  const drugDetails =
    isFormsStateValid && forms[selectedForm] ? forms[selectedForm].drugDetails[0] : null;
  const isStrengthValid =
    drugDetails !== null && drugDetails.strength !== null && drugDetails.strength.length > 0;

  useEffect(() => {
    if (isFormsStateValid && forms[selectedForm] && !isStrengthValid) {
      setSelectedDetails(forms[selectedForm].drugDetails[0]);
    }
  }, [isStrengthValid]);

  const handleCancel = () => {
    getDefaultForm();
    handleClose();
  };

  const handleApply = () => {
    if (selectedDetails) {
      handlePrescription(selectedDetails);
      handleClose();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={isOpen}
      onClose={handleClose}
      data-testid='edit-prescription-dialog'
    >
      <DialogTitle>{t('drugSearch.editPrescriptionDialog.dialogTitle')}</DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={5}>
          <Box display='inherit' flexDirection='column'>
            <Typography fontWeight='bold'>{prescription.displayName}</Typography>
            {selectedDetails ? (
              <Typography data-testid='drug-details-dialog'>
                {getDrugDetails(selectedDetails)}
              </Typography>
            ) : null}
          </Box>
          <Box display='inherit' flexDirection='column' gap={4}>
            <Box display='inherit' flexDirection='column' gap={2}>
              <Typography fontWeight='bold'>
                {t('drugSearch.editPrescriptionDialog.form')}
              </Typography>
              <Box display='inherit' flexDirection='row' gap={3} flexWrap='wrap'>
                {forms &&
                  Object.keys(forms).map((formCode: string, index) => {
                    const handleOnClick = () => {
                      setSelectedForm(formCode);
                    };

                    return (
                      <Button
                        key={forms[formCode].formCode}
                        data-testid={`form-${forms[formCode].formCode}-${index}`}
                        onClick={handleOnClick}
                        variant={selectedForm === formCode ? 'contained' : 'outlined'}
                      >
                        {forms[formCode].formValue}
                      </Button>
                    );
                  })}
              </Box>
            </Box>
            {isStateValid && isStrengthValid && (
              <Box display='inherit' flexDirection='column' gap={2}>
                <Typography fontWeight='bold'>
                  {t('drugSearch.editPrescriptionDialog.dosage')}
                </Typography>
                <Box display='inherit' flexDirection='row' gap={3} flexWrap='wrap'>
                  {forms[selectedForm].drugDetails.map((drugDetail, index) => {
                    const handleOnClick = () => {
                      setSelectedDetails(drugDetail);
                    };

                    return (
                      <Button
                        key={`${drugDetail.representativeNdc}-${index}`}
                        onClick={handleOnClick}
                        data-testid={`dosage-${drugDetail.representativeNdc}-${index}`}
                        variant={
                          selectedDetails.representativeNdc === drugDetail.representativeNdc &&
                          selectedDetails.strength === drugDetail.strength
                            ? 'contained'
                            : 'outlined'
                        }
                      >
                        {[drugDetail.strength, drugDetail.strengthUnit].filter(Boolean).join(' ')}
                      </Button>
                    );
                  })}
                </Box>
              </Box>
            )}
            <Box display='inherit' flexDirection='column' gap={2}>
              <Typography fontWeight='bold'>
                {t('drugSearch.editPrescriptionDialog.daysSupply')}
              </Typography>
              <Box display='inherit' flexDirection='row' gap={3} flexWrap='wrap'>
                {isStateValid &&
                  [30, 60, 90, 120].map((daysSupply: number) => {
                    const handleOnClick = () => {
                      const updatedDetails = { ...selectedDetails };
                      updatedDetails.modeDaysSupply = daysSupply;

                      setSelectedDetails(updatedDetails);
                    };

                    return (
                      <Button
                        key={daysSupply}
                        onClick={handleOnClick}
                        data-testid={daysSupply.toString()}
                        variant={
                          selectedDetails.modeDaysSupply === daysSupply ? 'contained' : 'outlined'
                        }
                      >{`${daysSupply.toString()} days`}</Button>
                    );
                  })}
              </Box>
            </Box>
            <Box display='inherit' flexDirection='column' gap={2}>
              <Typography fontWeight='bold'>
                {t('drugSearch.editPrescriptionDialog.quantity')}
              </Typography>
              <Box display='inherit' flexDirection='row' gap={3} flexWrap='wrap'>
                {isStateValid && (
                  <TextField
                    value={selectedDetails.modeQuantity ?? 0}
                    size='small'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      if (RegExp('^[0-9]*$').test(event.target.value)) {
                        const parsedNumber = Number(event.target.value);
                        const updatedDetails = { ...selectedDetails };
                        updatedDetails.modeQuantity = parsedNumber;

                        setSelectedDetails(updatedDetails);
                      }
                    }}
                    inputProps={{
                      'data-testid': 'quantity-input',
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} data-testid='close-edit-prescription-dialog'>
          {t('drugSearch.editPrescriptionDialog.cancelButton')}
        </Button>
        <Button
          onClick={handleApply}
          data-testid='apply-edit-prescription-dialog'
          disabled={isApplyDisabled}
          variant='contained'
        >
          {t('drugSearch.editPrescriptionDialog.applyButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type DrugFormOptions = { [key: string]: IDrugForm };

interface IDrugForm {
  formCode: string | null;
  formValue: string | null;
  drugDetails: IDrugResultNdcPackageDrugResultForm[];
}

function createFormsAndSort(forms: IDrugResultNdcPackageDrugResultForm[]): DrugFormOptions {
  const reducedForms = forms.reduce<DrugFormOptions>((previousValue, currentValue) => {
    if (currentValue.formCode && currentValue.representativeNdc) {
      if (previousValue[currentValue.formCode]) {
        const updatedForm = previousValue[currentValue.formCode];

        updatedForm.drugDetails.push({
          formCode: currentValue.formCode,
          formValue: currentValue.formValue,
          strength: currentValue.strength,
          strengthUnit: currentValue.strengthUnit,
          representativeNdc: currentValue.representativeNdc,
          modeQuantity: currentValue.modeQuantity,
          modeDaysSupply: currentValue.modeDaysSupply,
        } as IDrugResultNdcPackageDrugResultForm);

        previousValue[currentValue.formCode] = updatedForm;

        return previousValue;
      } else {
        const newForm: IDrugForm = {
          formCode: currentValue.formCode,
          formValue: currentValue.formValue,
          drugDetails: [
            {
              formCode: currentValue.formCode,
              formValue: currentValue.formValue,
              strength: currentValue.strength,
              strengthUnit: currentValue.strengthUnit,
              representativeNdc: currentValue.representativeNdc,
              modeQuantity: currentValue.modeQuantity,
              modeDaysSupply: currentValue.modeDaysSupply,
            },
          ],
        };
        previousValue[currentValue.formCode] = newForm;
        return previousValue;
      }
    }
    return previousValue;
  }, {} as DrugFormOptions);

  Object.keys(reducedForms).forEach((key) => {
    const drugForm = reducedForms[key];
    const sortedDrugForm = drugForm.drugDetails.sort(function (a, b) {
      if (a.strength === b.strength) return 0;
      if (a.strength === null) return 1;
      if (b.strength === null) return -1;
      return a.strength < b.strength ? -1 : 1;
    });
    drugForm.drugDetails = sortedDrugForm;
  });

  return reducedForms;
}
