// Copyright 2021 Prescryptive Health, Inc.

import { useContext } from 'react';
import {
  ILocationAvailabilityContext,
  LocationAvailabilityContext,
} from './location-availability.context';

export const useLocationAvailabilityContext = (): ILocationAvailabilityContext =>
  useContext<ILocationAvailabilityContext>(LocationAvailabilityContext);
