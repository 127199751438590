// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useEffect, useState } from 'react';
import { usePostAuthScreenContext } from '../../../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { useProviderContext } from '../../../../../providers/provider/use-provider-context.hook';
import { useNotificationContext } from '../../../../../hooks/use-notification-context/use-notification-context.hook';
import { notificationSetDispatch } from '../../../../../state/notification/dispatch/notification-set.dispatch';
import { HttpStatusCodesEnum } from '../../../../../api/http-status-codes';
import { LoadingSpinner } from '../../../../loading/loading-spinner';
import {
  Button,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  TextField,
  DialogActions,
} from '@mui/material';
import {
  ILocationAddAsyncActionArgs,
  locationAddAsyncAction,
} from '../../../../../state/provider/async-actions/store-location-add.async-action';
import {
  ILocationUpdateAsyncActionArgs,
  locationUpdateAsyncAction,
} from '../../../../../state/provider/async-actions/store-location-update.async-action';
import { LanesSection } from './lanes.section';
import { ILaneInfo } from '../../../../../model/location-availability';
import { v4 as uuidv4 } from 'uuid';
import { ILanesUpdateRequest } from '../../../../../api/v1/provider-location-update/provider-location-update.request';
import { isFalsyOrWhiteSpace } from '../../../../../helpers/string-helper/string-helper';
import { IErrorResponse } from '../../../../../api/error-response';
import { PhoneNumberFormatter } from '../../../../../formatters/phone-number/phone-number.formatter';
import { PhoneMaskInput } from '../../../../inputs/mask-inputs/phone-mask-input/phone-mask-input';
import { getToken } from '../../../../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

interface Location {
  locationName: string;
  address1: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  lanes: ILaneInfo[];
}

type LocationWithoutLanes = Omit<Location, 'lanes'>;
export type LanesEmailAddress = Pick<ILaneInfo, 'emailAddress'>;
export interface IAddEditLocationModalProps {
  isOpen: boolean;
  isEditMode?: boolean;
  providerName?: string;
  locationId?: string;
  locationName?: string;
  address1?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phoneNumber?: string;
  lanes?: ILaneInfo[];
  onClosePress: () => void;
}

type LocationError = {
  locationName: boolean;
  address1: boolean;
  city: boolean;
  state: boolean;
  zipCode: boolean;
  phoneNumber: boolean;
  lanesErrors: LanesEmailAddress[];
};

const defaultLocationError: Readonly<LocationError> = {
  locationName: false,
  address1: false,
  city: false,
  state: false,
  zipCode: false,
  phoneNumber: false,
  lanesErrors: [],
};

export const AddEditLocationModal = (props: IAddEditLocationModalProps): ReactElement => {
  const { t } = useTranslation();

  const {
    isOpen,
    providerName = '',
    locationId = '',
    isEditMode = false,
    locationName = '',
    address1 = '',
    city = '',
    state = '',
    zipCode = '',
    phoneNumber = '',
    lanes: propsLanes = [],
    onClosePress,
  } = props;

  const { authProvider, busyDispatch, errorDispatch, configState, telemetryService } =
    usePostAuthScreenContext({ defaultContent: {} });
  const { providerDispatch } = useProviderContext();
  const { notificationDispatch } = useNotificationContext();

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const [isLoading, setIsLoading] = useState(false);
  const [guid] = useState(uuidv4());
  const [location, setLocation] = useState<Location>({
    locationName,
    address1,
    city,
    state,
    zipCode,
    phoneNumber,
    lanes: propsLanes ?? ([] as ILaneInfo[]),
  });

  const [locationError, setLocationError] = useState<LocationError>(defaultLocationError);

  const [apiResponseMessage, setApiResponseMessage] = useState('');

  useEffect(
    function refreshLanesState() {
      if (location)
        setLocation({
          ...location,
          lanes: [...propsLanes],
        });
    },
    [isOpen]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name = event.target.name;
    const value = event.target.value;

    const zipRegex = new RegExp('^[0-9]*$');
    const isValueNumber = zipRegex.test(value);

    setLocationError({
      ...locationError,
      [name]: false,
    });

    if (name === 'zipCode' && isValueNumber) {
      if (!(value.length > 5)) {
        setLocation({
          ...location,
          zipCode: value,
        });
      }
    }

    if (name !== 'zipCode') {
      setLocation({
        ...location,
        [name]: value,
      });
    }
  };

  const phoneNumberOnChange = (value: string) => {
    const phoneRegex = new RegExp('^[0-9]*$');
    if (value.length < 10 || !phoneRegex.test(value)) {
      setLocationError({
        ...locationError,
        phoneNumber: true,
      });
    } else {
      setLocationError({
        ...locationError,
        phoneNumber: false,
      });
    }
    setLocation({
      ...location,
      phoneNumber: value,
    });
  };

  const handleLaneNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    const updatedLanes = location.lanes.map((x) =>
      x.emailAddress === name ? { ...x, displayName: value } : x
    );

    if (locationError.lanesErrors.some((x) => x.emailAddress === name)) {
      setLocationError({
        ...locationError,
        lanesErrors: locationError.lanesErrors.filter((x) => x.emailAddress !== name),
      });
    }

    setLocation({
      ...location,
      lanes: updatedLanes,
    });
  };

  const addLane = (): void => {
    const newLane = { displayName: '', emailAddress: uuidv4() } as ILaneInfo;

    setLocation({
      ...location,
      lanes: [...location.lanes, newLane],
    });
  };

  const onSave = async (): Promise<void> => {
    setApiResponseMessage('');

    let isLocationError = false;
    const updatedLocationError = {
      ...locationError,
      lanesErrors: [...locationError.lanesErrors],
    } as LocationError;

    Object.keys(location)
      .filter((x) => x !== 'lanes')
      .forEach((e: string) => {
        const value = location[e as keyof LocationWithoutLanes];
        if (isFalsyOrWhiteSpace(value)) {
          updatedLocationError[e as keyof LocationWithoutLanes] = true;
          isLocationError = true;
        } else if (e === 'zipCode' && value.length !== 5) {
          updatedLocationError[e as keyof LocationWithoutLanes] = true;
          isLocationError = true;
        }
      });

    location.lanes.forEach((lane: ILaneInfo) => {
      if (isFalsyOrWhiteSpace(lane.displayName)) {
        updatedLocationError.lanesErrors.push({ ...lane });
        isLocationError = true;
      }
    });

    if (!isLocationError) {
      try {
        setIsLoading(true);
        if (isEditMode) {
          const updateLocationArgs: ILocationUpdateAsyncActionArgs = {
            locationId,
            locationName: location.locationName,
            address1: location.address1,
            city: location.city,
            state: location.state,
            zipCode: location.zipCode,
            phoneNumber: PhoneNumberFormatter.formatForApi(location.phoneNumber),
            lanes: getLanesToUpdate(location.lanes, propsLanes ?? ([] as ILaneInfo[])),
            providerDispatch,
            authProvider,
            busyDispatch,
            configState,
            errorDispatch,
            telemetryService,
            getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
          };

          await locationUpdateAsyncAction(updateLocationArgs);
          notificationSetDispatch(
            notificationDispatch,
            t('settings.addEditLocationModal.editLocationNotificationSuccessMessageText', {
              locationName,
            })
          );
        } else {
          const addLocationArgs: ILocationAddAsyncActionArgs = {
            providerName,
            locationName: location.locationName,
            address1: location.address1,
            city: location.city,
            state: location.state,
            zipCode: location.zipCode,
            phoneNumber: PhoneNumberFormatter.formatForApi(location.phoneNumber),
            providerDispatch,
            authProvider,
            busyDispatch,
            configState,
            errorDispatch,
            telemetryService,
            getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
          };
          await locationAddAsyncAction(addLocationArgs);
          notificationSetDispatch(
            notificationDispatch,
            t('settings.addEditLocationModal.addLocationNotificationSuccessMessageText', {
              locationName: location.locationName,
            })
          );
        }
        setIsLoading(false);
        onClosePress();
      } catch (error) {
        setIsLoading(false);
        const errorResponse = error as IErrorResponse;
        if (
          errorResponse.message &&
          errorResponse.status &&
          errorResponse.status === HttpStatusCodesEnum.BAD_REQUEST
        ) {
          setApiResponseMessage(errorResponse.message);
        } else {
          setApiResponseMessage(t('settings.addEditLocationModal.unknownErrorMessage'));
        }
      }
    } else {
      setLocationError(updatedLocationError);
    }
  };

  const handleOnClose = () => {
    setLocation({
      locationName,
      address1,
      city,
      state,
      zipCode,
      phoneNumber,
      lanes: propsLanes ?? ([] as ILaneInfo[]),
    });
    setLocationError({ ...defaultLocationError, lanesErrors: [] });
    onClosePress();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleOnClose}
      aria-labelledby='add-edit-location-dialog'
      maxWidth='xl'
      id={`add-edit-location-dialog-${locationId || guid}`}
    >
      <DialogTitle id={`add-edit-location-dialog-title-${locationId || guid}`}>
        <Typography component={'span'} variant='h6'>
          {isEditMode
            ? t('settings.addEditLocationModal.editLocationDialogTitle')
            : t('settings.addEditLocationModal.addLocationDialogTitle')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus={true}
          variant='filled'
          margin='dense'
          name='locationName'
          label={t('settings.addEditLocationModal.locationLabel')}
          value={location.locationName}
          type='text'
          fullWidth={true}
          onChange={handleChange}
          error={locationError.locationName}
          helperText={
            locationError.locationName ? t('settings.addEditLocationModal.locationErrorLabel') : ''
          }
          id={`add-edit-location-dialog-location-name-text-input-${locationId || guid}`}
        />
        <TextField
          variant='filled'
          margin='dense'
          name='address1'
          label={t('settings.addEditLocationModal.addressLabel')}
          type='text'
          value={location.address1}
          fullWidth={true}
          onChange={handleChange}
          error={locationError.address1}
          helperText={
            locationError.address1 ? t('settings.addEditLocationModal.addressErrorLabel') : ''
          }
          id={`add-edit-location-dialog-address-text-input-${locationId || guid}`}
        />
        <TextField
          variant='filled'
          margin='dense'
          name='city'
          label={t('settings.addEditLocationModal.cityLabel')}
          type='text'
          value={location.city}
          fullWidth={true}
          onChange={handleChange}
          error={locationError.city}
          helperText={locationError.city ? t('settings.addEditLocationModal.cityErrorLabel') : ''}
          id={`add-edit-location-dialog-city-text-input-${locationId || guid}`}
        />
        <TextField
          margin='dense'
          variant='filled'
          name='state'
          label={t('settings.addEditLocationModal.stateLabel')}
          type='text'
          value={location.state}
          fullWidth={true}
          onChange={handleChange}
          error={locationError.state}
          helperText={locationError.state ? t('settings.addEditLocationModal.stateErrorLabel') : ''}
          id={`add-edit-location-dialog-state-text-input-${locationId || guid}`}
        />
        <TextField
          margin='dense'
          variant='filled'
          name='zipCode'
          label={t('settings.addEditLocationModal.zipCodeLabel')}
          type='text'
          value={location.zipCode}
          fullWidth={true}
          onChange={handleChange}
          error={locationError.zipCode}
          helperText={locationError.zipCode ? t('settings.addEditLocationModal.zipErrorLabel') : ''}
          id={`add-edit-location-dialog-zipCode-text-input-${locationId || guid}`}
        />
        <PhoneMaskInput
          name='phoneNumber'
          label={t('settings.addEditLocationModal.phoneNumberLabel')}
          type='text'
          initialValue={location.phoneNumber}
          fullWidth={true}
          onPhoneNumberChange={phoneNumberOnChange}
          isError={locationError.phoneNumber}
          errorMessage={t('settings.addEditLocationModal.phoneNumberErrorLabel')}
          data-heap-redact-text='true'
        />
        {isEditMode ? (
          <LanesSection
            lanes={location.lanes}
            handleChange={handleLaneNameChange}
            addLane={addLane}
            lanesErrors={locationError.lanesErrors}
          />
        ) : null}
      </DialogContent>
      <DialogActions id={`add-edit-location-dialog-actions-${locationId || guid}`}>
        {apiResponseMessage ? <Typography color='error'>{apiResponseMessage}</Typography> : null}
        <Button
          onClick={onSave}
          variant='contained'
          color='primary'
          id={`add-edit-location-dialog-saveButton-${locationId || guid}`}
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            t('settings.addEditLocationModal.addEditDialogSaveButtonText')
          )}
        </Button>
        <Button
          onClick={handleOnClose}
          color='primary'
          variant='outlined'
          id={`add-edit-location-dialog-cancelButton-${locationId || guid}`}
        >
          {t('settings.addEditLocationModal.dialogCancelButtonText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const getLanesToUpdate = (
  stateLanes: ILaneInfo[],
  propsLanes: ILaneInfo[]
): ILanesUpdateRequest[] => {
  const lanesWithValidNames = stateLanes.filter((x) => x.displayName?.trim()?.length > 0);

  const newLanes = lanesWithValidNames
    .filter((lane) => propsLanes.every((propLane) => propLane.emailAddress !== lane.emailAddress))
    .map((x) => ({ ...x, emailAddress: undefined } as ILanesUpdateRequest));

  const lanesToUpdate = lanesWithValidNames.filter((x) => {
    const propsLane = propsLanes.find((propLane) => propLane.emailAddress === x.emailAddress);
    return propsLane !== undefined && propsLane.displayName !== x.displayName;
  });

  const result = [...newLanes, ...lanesToUpdate];
  return result;
};
