// Copyright 2021 Prescryptive Health, Inc.

import { LocationAvailabilityMap } from '../../../model/location-availability';
import { ILocationAvailabilityAction } from './location-availability.action';

export type ILocationAvailabilitySetAction = ILocationAvailabilityAction<'LOCATION_AVAILABILITY_SET'>;

export const locationAvailabilitySetAction = (
  locationAvailabilityMap: LocationAvailabilityMap
): ILocationAvailabilitySetAction => ({
  type: 'LOCATION_AVAILABILITY_SET',
  payload: {
    locationAvailabilityMap,
    isStale: false,
  },
});
