// Copyright 2021 Prescryptive Health, Inc.

export type RouteKey = 'root' | 'signIn' | 'drugSearch' | 'learnMore';

export const unauthenticatedRouteConfig: {
  [key in RouteKey]: {
    route: string;
    createUrl: (...args: string[]) => string;
  };
} = {
  root: {
    route: '/',
    createUrl: (): string => '/',
  },
  signIn: {
    route: '/sign-in',
    createUrl: (): string => '/sign-in',
  },
  drugSearch: {
    route: '/drug-search',
    createUrl: (): string => '/drug-search',
  },
  learnMore: {
    route: '/learn-more-about-authentication-changes',
    createUrl: (): string => '/learn-more-about-authentication-changes',
  },
};
