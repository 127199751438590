// Copyright 2020 Prescryptive Health, Inc.

export type IConfigObject<K extends string> = Record<K, string>;

export const loadConfig = <TKeys extends { [key: string]: string }>(
  config: { [key in keyof TKeys]: string },
  map: Map<string, string>
): void => {
  Object.keys(config).forEach((key) => {
    const envKey = map.get(key);
    if (envKey) {
      const value = getMetaEnvironmentVariable(envKey);
      config[key as keyof TKeys] = value;
    }
  });
};

export const getMetaEnvironmentVariable = (name: string): string => {
  const envVar = process.env[`REACT_APP_${name}`];
  if (!envVar) {
    throw Error(`Environment variable not defined (${name})`);
  }
  return envVar;
};

export const neitherNullNorEmpty = (text: string | undefined, defaultText: string): string => {
  return text && text.trim().length > 0 ? text : defaultText;
};
