// Copyright 2021 Prescryptive Health, Inc.
import React, { FunctionComponent, useReducer } from 'react';
import {
  WaitlistReducer,
  waitlistReducer,
} from '../../state/customers/waitlist/waitlist.reducer';
import { defaultWaitlistState } from '../../state/customers/waitlist/waitlist.state';
import { WaitlistContext } from './waitlist.context';

export const WaitlistContextProvider: FunctionComponent = ({
  children,
}) => {
  const [waitlistState, waitlistDispatch] = useReducer<
    WaitlistReducer
  >(waitlistReducer, defaultWaitlistState);
  return (
    <WaitlistContext.Provider
      value={{ waitlistState, waitlistDispatch }}
    >
      {children}
    </WaitlistContext.Provider>
  );
};