// Copyright 2021 Prescryptive Health, Inc.
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { getMetaEnvironmentVariable } from './config/config.helper';
import { RestLink } from 'apollo-link-rest';

const restLink = new RestLink({
  uri: `https://${getMetaEnvironmentVariable(
    'PHARMACY_PORTAL_API_HOST_URL'
  )}:${getMetaEnvironmentVariable('PHARMACY_PORTAL_API_PORT')}`,
});

export default new ApolloClient({
  link: restLink,
  cache: new InMemoryCache(),
});
