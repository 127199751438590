// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useState } from 'react';
import { Box, Button, TextField, Typography, useTheme, Avatar } from '@mui/material';
import {
  StyledButtonsGroupBox,
  StyledContentWithPicBox,
} from './edit-text.section.styled-components';
import { LabeledDetail } from '../../../../layout/labeled-detail/labeled-detail';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
export interface IEditTextProps {
  textToEdit: string;
  maxInputLength?: number;
  sectionHeading: string;
  sectionDescription: string;
  sectionAdditionalDescription?: string;
  altImageText: string;
  imageSrc: string;
  onSave: (textToSave: string) => Promise<void>;
}

export const EditTextSection = ({
  textToEdit,
  maxInputLength,
  onSave,
  sectionDescription,
  sectionAdditionalDescription,
  sectionHeading,
  altImageText,
  imageSrc,
}: IEditTextProps): ReactElement => {
  const { t } = useTranslation();

  const [isEditingModeMode, setIsEditingModeMode] = useState(false);
  const [editedText, setEditedText] = useState<string>(textToEdit);

  const theme = useTheme();

  const onEditPress = () => {
    setIsEditingModeMode(true);
    setEditedText(textToEdit);
  };

  const onCancelPress = () => {
    setIsEditingModeMode(false);
  };

  const onServiceMessageTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedText(event.target.value);
  };

  const onSavePress = async () => {
    await onSave(editedText);
    setIsEditingModeMode(false);
  };

  const renderEditSection = () => (
    <>
      <Box display='flex' flexDirection='column'>
        <TextField
          value={editedText}
          onChange={onServiceMessageTextChange}
          inputProps={{ maxLength: maxInputLength }}
          multiline={true}
          rows={4}
          variant='outlined'
          style={{ flexGrow: 1, flexShrink: 1, maxWidth: '596px' }}
        />
        {maxInputLength && (
          <Typography variant='caption'>
            {t('services.editText.showCharactersLeft', {
              charactersLeft: maxInputLength - editedText.length,
            })}
          </Typography>
        )}
      </Box>
      <StyledButtonsGroupBox>
        <Button
          variant='text'
          onClick={onSavePress}
          startIcon={<CheckCircleIcon />}
          color='primary'
          key={uuidv4()}
        >
          {t('services.editText.saveButtonLabel')}
        </Button>
        <Button
          variant='text'
          onClick={onCancelPress}
          startIcon={<CloseIcon />}
          color='primary'
          key={uuidv4()}
        >
          {t('services.editText.cancelButtonLabel')}
        </Button>
      </StyledButtonsGroupBox>
    </>
  );
  const renderViewSection = () => (
    <>
      <Typography>{textToEdit}</Typography>
      <StyledButtonsGroupBox>
        <Button variant='text' onClick={onEditPress} startIcon={<EditIcon />} color='primary'>
          {t('services.editText.editButtonLabel')}
        </Button>
      </StyledButtonsGroupBox>
    </>
  );

  return (
    <Box>
      <Typography variant='h3'>{sectionHeading}</Typography>
      <StyledContentWithPicBox>
        <Box flexGrow={2} paddingRight={theme.spacing(4)}>
          <Box marginBottom={theme.spacing(6)}>
            {isEditingModeMode ? renderEditSection() : renderViewSection()}
          </Box>
          {sectionAdditionalDescription ? (
            <LabeledDetail
              description={sectionDescription}
              additionalDescription={sectionAdditionalDescription}
            />
          ) : (
            <LabeledDetail description={sectionDescription} />
          )}
        </Box>
        <Avatar
          sx={{
            height: '100%',
            width: 'auto',
            flexShrink: 0.5,
            flexGrow: 1,
            '& .MuiSlider-img': {
              maxHeight: '320px',
              maxWidth: '320px',
              minWidth: '217px',
              minHeight: '217px',
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            },
            [theme.breakpoints.up('desktop')]: {
              marginTop: 0,
            },
          }}
          alt={altImageText}
          src={imageSrc}
          variant='square'
        />
      </StyledContentWithPicBox>
    </Box>
  );
};
