// Copyright 2020 Prescryptive Health, Inc.

import { HttpStatusCodesEnum } from '../../http-status-codes';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { call } from '../../call';
import { ApiErrors } from '../../errors/api-errors';
import {
  IPaymentSetting,
  IPharmacyService,
  IServiceQuestion,
  IServiceQuestionSelectOption,
  ServiceQuestionType,
} from '../../../model/pharmacy-service';
import {
  IPharmacyServiceResponseQuestionOption,
  IPharmacyServiceResponseQuestion,
  IPharmacyServicesResponse,
  IPharmacyServiceResponsePaymentSetting,
} from '../pharmacy-services-get/pharmacy-services-get.response';
import { serviceStatusFromResponse } from './pharmacy-services.api-helper';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';
export interface IPharmacyServicesGetResult {
  services: IPharmacyService[];
}

export async function pharmacyServicesGet(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  locationId: string
): Promise<IPharmacyServicesGetResult> {
  const endpointKey = 'pharmacyServicesGet';
  const url = buildUrl(apiConfig, endpointKey, {
    ':locationId': locationId,
  });
  const headers = buildBearerAuthHeaders(authToken);

  const response: Response = await call(url, undefined, 'GET', headers);
  if (response.ok) {
    if (response.status === HttpStatusCodesEnum.NO_CONTENT) {
      return { services: [] };
    }

    return await pharmacyServicesResultFromResponse(response);
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.PHARMACY_SERVICES_GET,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  throw new PharmacyPortalError(
    ApiErrors.pharmacyServicesGet(response.statusText),
    ApiErrorCode.PHARMACY_SERVICES_GET,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}

async function pharmacyServicesResultFromResponse(
  response: Response
): Promise<IPharmacyServicesGetResult> {
  const responseData = (await response.json()) as IPharmacyServicesResponse[];
  if (!responseData) {
    throw new Error(ApiErrors.pharmacyServicesGet(ApiErrors.unexpectedResponseDataFormat));
  }

  const result: IPharmacyServicesGetResult = {
    services: [],
  };

  result.services = responseData.map((responseService) => {
    const service: IPharmacyService = {
      name: responseService.serviceName ?? '',
      serviceType: responseService.serviceType ?? '',
      durationMinutes: responseService.appointmentDuration,
      status: serviceStatusFromResponse(responseService.status),
      price: responseService.price ?? 0,
      paymentSettings: paymentSettingsFromResponse(responseService.paymentSettings),
      remainingQuantity: undefined,
      screenDescription: responseService.screenDescription ?? '',
      confirmationAdditionalInfo: responseService.confirmationAdditionalInfo ?? '',
      additionalQuestions: serviceQuestionsFromResponse(responseService.questions),
      enrollmentStatus: responseService.enrollmentStatus,
      minLeadHours: responseService.minLeadHours,
    };
    return service;
  });

  return result;
}

const serviceQuestionsFromResponse = (
  responseQuestions: IPharmacyServiceResponseQuestion[] = []
): IServiceQuestion[] => {
  return responseQuestions.map(({ id, questionText, isRequired, type, selectOptions }) => ({
    id,
    questionText,
    isRequired,
    type: questionTypeFromResponse(type),
    selectOptions: questionSelectOptionsFromResponse(selectOptions),
  }));
};

const paymentSettingsFromResponse = (
  responsePaymentSettings: IPharmacyServiceResponsePaymentSetting[] = []
): IPaymentSetting[] => {
  return responsePaymentSettings.map(({ paymentType, displayText, accepted }) => ({
    paymentType,
    displayText,
    accepted,
  }));
};

const questionTypeFromResponse = (responseQuestionType: string): ServiceQuestionType => {
  switch (responseQuestionType) {
    case 'text':
      return 'text';

    case 'datepicker':
      return 'datepicker';

    case 'single-select':
      return 'single-select';

    case 'multi-select':
      return 'multi-select';

    default:
      return 'unknown';
  }
};

const questionSelectOptionsFromResponse = (
  responseSelectOptions?: IPharmacyServiceResponseQuestionOption[]
): IServiceQuestionSelectOption[] | undefined => {
  if (!responseSelectOptions) {
    return undefined;
  }

  return responseSelectOptions.map(({ text = '', id = '' }) => ({
    text,
    id,
  }));
};
