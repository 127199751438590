import { HttpStatusCodesEnum } from './api/http-status-codes';
import { ApiErrorCode } from './api/errors/api-error-code';
import { DrugSearchApiErrorCode } from './api/drug-search-api/errors/drug-search-api-error-code';

export enum PharmacyPortalErrorType {
  API_ERROR = 'API_ERROR',
}

export class PharmacyPortalError {
  error: Error;
  errorType: PharmacyPortalErrorType;
  errorCode: ApiErrorCode | DrugSearchApiErrorCode;
  httpStatus?: HttpStatusCodesEnum;

  constructor(
    error: string,
    errorCode: ApiErrorCode | DrugSearchApiErrorCode,
    errorType: PharmacyPortalErrorType,
    httpStatus?: HttpStatusCodesEnum
  ) {
    this.error = new Error(error);
    this.errorCode = errorCode;
    this.errorType = errorType;
    this.httpStatus = httpStatus;
  }
}
