// Copyright 2021 Prescryptive Health, Inc.

import { IServiceQuestion } from '../../../model/pharmacy-service';
import { ILocationAction } from './location.action';

export type IPharmacyServicesQuestionAddActionPayload = {
  serviceType: string;
  question: IServiceQuestion;
};

export type IPharmacyServicesQuestionAddAction = ILocationAction<
  'PHARMACY_SERVICE_QUESTION_ADD',
  IPharmacyServicesQuestionAddActionPayload
>;

export const pharmacyServiceQuestionAdd = (
  serviceType: string,
  question: IServiceQuestion
): IPharmacyServicesQuestionAddAction => {
  return {
    type: 'PHARMACY_SERVICE_QUESTION_ADD',
    payload: { serviceType, question },
  };
};
