// Copyright 2020 Prescryptive Health, Inc.

import { ReactElement, useEffect } from 'react';
import { usePreAuthScreenContext } from '../../../hooks/use-screen-context/use-pre-auth-screen-context/use-pre-auth-screen-context.hook';
import { useSessionContext } from '../../../providers/session/use-session-context.hook';
import {
  ILoginAsyncActionArgs,
  loginAsyncAction,
} from '../../../state/session/async-actions/login.async-action';
import { localStorageKeys } from '../../../constants/local-storage-keys';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, useTheme } from '@mui/material';
import { HeroSection } from './hero/hero-section';
import { useLazyQuery } from '@apollo/client';
import { GET_LANDER_PAGE_QUERY } from './get-lander-page.query';
import {
  ProviderLander,
  ProviderLanderFormOptions,
  ProviderLanderSection,
} from '../../../model/strapi/strapi-models';
import { SignUpFooter } from './footer/sign-up-footer';
import { getNewDate } from '../../../helpers/dates/get-new-date/get-new-date';
import { MultiColumn } from './multi-column/multi-column';
import { SingleColumn } from './single-column/single-column';
import { LandingSectionContainer } from './landing-section-container/landing-section-container';
import {
  StyledAppBarContainer,
  StyledDynamicSectionsContainer,
  StyledHeroSignInContainer,
  StyledLanderContainer,
} from './sign-in.screen.styled-components';
import { SignUpCarouselSection } from './carousel-section/sign-up-carousel-section';
import { SignInSection } from './sign-in-section/sign-in-section';
import { PrescryptiveProLogo } from '../../prescryptivepro-logo/prescryptivepro-logo';
import { useNavigate } from 'react-router-dom';
import { unauthenticatedRouteConfig } from '../../../navigation/unauthenticated-route-config';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { LandingBanner } from '../../landing-banner/landing-banner';

export const SignInScreen = (): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { busyDispatch, configState, errorDispatch, telemetryService } = usePreAuthScreenContext({
    defaultContent: {},
  });
  const { loginWithRedirect } = useAuth0();

  const theme = useTheme();
  const { sessionState, sessionDispatch } = useSessionContext();
  const { landingPageVariation, auth0Migration, auth0MigrationBanner } = useFlags();

  document.title = t('lander.signInScreen.pageTitle');

  const [getLandingPageContent, { loading, error, data }] = useLazyQuery<{
    providerLander: ProviderLander;
    providerLanderSections: ProviderLanderSection[];
    providerLanderFormOptions: ProviderLanderFormOptions[];
  }>(GET_LANDER_PAGE_QUERY);

  useEffect(() => {
    localStorage.removeItem(localStorageKeys.isLogout);
  }, []);

  useEffect(() => {
    void getLandingPageContent();
  }, []);

  const loginAsync = async () => {
    if (auth0Migration) {
      await loginWithRedirect({
        appState: { returnTo: window.location.href.replace(window.location.origin, '') },
      });
      return;
    }

    const args: ILoginAsyncActionArgs = {
      busyDispatch,
      configState,
      errorDispatch,
      telemetryService,
      sessionDispatch,
      authProvider: sessionState.authProvider,
      nonFatalErrorTitle: t('lander.signInScreen.signInError'),
      nonFatalErrorContent: t('lander.signInScreen.signInErrorContent'),
    };
    await loginAsyncAction(args);
  };

  if (error) {
    navigate(unauthenticatedRouteConfig.signIn.createUrl());
  }

  if (loading) {
    // TODO
    return <Box>Loading...</Box>;
  }

  const sections = data?.providerLanderSections;

  const heroSection = data?.providerLanderSections?.find((section) => section?.Layout === 'Hero');

  const footerSection = sections?.find((section) => section?.Layout === 'Footer');

  const dynamicSections =
    sections &&
    [...sections]
      .sort((a, b) => ((a.SortOrder ?? 0) > (b.SortOrder ?? 0) ? 1 : -1))
      .filter(
        (section) =>
          !(
            section?.Layout === 'Hero' ||
            section?.Layout === 'Footer' ||
            section?.Layout === 'Form'
          ) &&
          section?.VisibleGroup === landingPageVariation &&
          new Date((section?.PublishStartDateTime as string) ?? 0) < getNewDate()
      )
      .map((section) => {
        const backgroundColor = section?.BackgroundColor ?? '';

        switch (section?.Layout) {
          case 'NColumn': {
            return (
              <LandingSectionContainer backgroundColor={backgroundColor} key={section?.id}>
                <MultiColumn multiColumnData={section?.ContentZone} />
              </LandingSectionContainer>
            );
          }

          case 'SingleColumn': {
            return (
              <LandingSectionContainer backgroundColor={backgroundColor} key={section?.id}>
                <SingleColumn singleColumnData={section?.ContentZone} />
              </LandingSectionContainer>
            );
          }

          case 'Carousel': {
            return (
              <LandingSectionContainer backgroundColor={backgroundColor} key={section?.id}>
                <SignUpCarouselSection section={section} />
              </LandingSectionContainer>
            );
          }

          default:
            return null;
        }
      });

  if (!data || !heroSection || !data?.providerLanderFormOptions) {
    // TODO
    return (
      <Box>
        No Content <br /> {JSON.stringify(data)}
      </Box>
    );
  }

  return (
    <StyledLanderContainer>
      {auth0MigrationBanner && <LandingBanner />}
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        sx={{ backgroundColor: theme.palette.grey['50'] }}
      >
        <StyledAppBarContainer>
          <Box style={{ padding: theme.spacing(5) }} zIndex='2'>
            <PrescryptiveProLogo />
          </Box>
        </StyledAppBarContainer>
        <StyledHeroSignInContainer>
          <HeroSection
            contentZones={heroSection.ContentZone}
            beforeBackgroundColor={heroSection.BackgroundColor}
          />
          <SignInSection onSignInPress={loginAsync} />
        </StyledHeroSignInContainer>
      </Box>
      <StyledDynamicSectionsContainer>{dynamicSections}</StyledDynamicSectionsContainer>
      <SignUpFooter signUpFooterData={footerSection?.ContentZone} />
    </StyledLanderContainer>
  );
};
