import { gql } from '@apollo/client';

export const GET_SUMMARY_REPORTS_QUERY = gql`
  query getReports(
    $locationId: ID
    $reportType: String
    $startDate: DateTime!
    $endDate: DateTime!
    $next: String
    $pageSize: Int
  ) {
    reports(
      locationId: $locationId
      reportType: $reportType
      startDate: $startDate
      endDate: $endDate
      next: $next
      pageSize: $pageSize
    ) {
      claimReports {
        id
        pharmacyName
        claimStatus
        serviceDate
        rxNumber
        ndc
        drugName
        strength
        quantity
        usualAndCustomary
        patientPaid
        nadacIngredientCost
        profitFromProcessed
        awpUnitPrice
        patientPaidVsAwp
        brandVsGeneric
      }
      next
      totalCount
    }
  }
`;

export interface IGetSummaryReportsResponse {
  reports: IClaimSummaryReport;
}

interface IClaimSummaryReport {
  claimReports: ISummaryReport[];
  next?: string;
  totalCount?: number;
}

interface ISummaryReport {
  id: string;
  pharmacyName: string;
  claimStatus: string;
  serviceDate: string;
  rxNumber: string;
  ndc: string;
  drugName: string;
  strength: string;
  quantity: number;
  usualAndCustomary: number;
  patientPaid: number;
  nadacIngredientCost: number;
  profitFromProcessed: number;
  awpUnitPrice: number;
  patientPaidVsAwp: number;
  brandVsGeneric: boolean;
}
