// Copyright 2021 Prescryptive Health, Inc.

import { IContentMap } from '../../../providers/locale/get-content';
import { weekdays } from '../../../content/weekdays.content';

export interface IWeekdayToggleSelectorContent {
  weekdays: string[];
}

const defaultContent: IWeekdayToggleSelectorContent = {
  weekdays,
};

export const weekdayToggleSelectorContent: IContentMap<Readonly<IWeekdayToggleSelectorContent>> = {
  defaultContent,
};
