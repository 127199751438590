// Copyright 2021 Prescryptive Health, Inc.

import { styled, Toolbar } from '@mui/material';

export const StyledAppointmentDetailsToolBar = styled(Toolbar)(({ theme }) => ({
  display: 'inline-table',
  padding: `${theme.spacing(3)} ${theme.spacing(10)}`,
  background: theme.palette.grey.A100,
  [theme.breakpoints.up('tablet')]: {
    display: 'flex',
  },
}));
