// Copyright 2021 Prescryptive Health, Inc.

import { Typography } from '@mui/material';
import { styled } from '@mui/material';

export const NumberedBullet = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
  paddingLeft: theme.spacing(4.5),
  paddingRight: theme.spacing(4.5),
}));
