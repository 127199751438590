// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useState } from 'react';
import { RemoveLocationModal } from '../../modals/remove-location.modal';
import { useProviderContext } from '../../../../../providers/provider/use-provider-context.hook';
import { useNotificationContext } from '../../../../../hooks/use-notification-context/use-notification-context.hook';
import { notificationSetDispatch } from '../../../../../state/notification/dispatch/notification-set.dispatch';
import { usePostAuthScreenContext } from '../../../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { LoadingSpinner } from '../../../../loading/loading-spinner';
import {
  CardHeader,
  CardContent,
  Typography,
  Card,
  CardActions,
  Button,
  Box,
  FormControlLabel,
  Switch,
  useTheme,
} from '@mui/material';
import {
  ILocationDeleteAsyncActionArgs,
  locationDeleteAsyncAction,
} from '../../../../../state/provider/async-actions/store-location-delete.async-action';
import {
  ILocationIsTestUpdatedAsyncActionArgs,
  locationIsTestUpdateAsyncAction,
} from '../../../../../state/provider-support/async-actions/location-is-test-update.async-action';
import { getTierSupportLevel } from '../../../../../helpers/tier-support-level-helper/tier-support-level-helper';
import { ILaneInfo } from '../../../../../model/location-availability';
import { AddEditLocationModal } from '../../modals/add-edit/add-edit-location.modal';
import { PhoneNumberFormatter } from '../../../../../formatters/phone-number/phone-number.formatter';
import { getToken } from '../../../../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

export interface IPharmacyLocationCardProps {
  locationId: string;
  locationName: string;
  address1: string;
  city: string;
  state: string;
  zipCode: string;
  timezone: string;
  phoneNumber: string;
  lanes: ILaneInfo[];
  isTest: boolean;
}

export const PharmacyLocationCard = (props: IPharmacyLocationCardProps): ReactElement => {
  const { t } = useTranslation();
  const {
    locationId,
    locationName,
    address1,
    city,
    state,
    zipCode,
    timezone,
    phoneNumber,
    lanes,
    isTest,
  } = props;

  const { providerDispatch } = useProviderContext();
  const { notificationDispatch } = useNotificationContext();
  const { configState, authProvider, busyDispatch, errorDispatch, telemetryService } =
    usePostAuthScreenContext({ defaultContent: {} });

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const [isLoading, setIsLoading] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);

  const onEditButtonClick = () => {
    setIsEditDialogOpen(true);
  };

  const onRemoveButtonClick = () => {
    setIsRemoveDialogOpen(true);
  };

  const onCloseEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const [isLive, setIsLive] = useState(!isTest);

  const handleSwitchOnChange = async () => {
    const updatedIsLive = !isLive;
    setIsLive(updatedIsLive);

    const locationIsTestArgs: ILocationIsTestUpdatedAsyncActionArgs = {
      isTest: !updatedIsLive,
      providerDispatch,
      locationId,
      telemetryService,
      errorDispatch,
      authProvider,
      busyDispatch,
      configState,
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
    };

    try {
      setIsLoading(true);
      await locationIsTestUpdateAsyncAction(locationIsTestArgs);
      setIsLoading(false);
    } catch (error) {
      setIsLive(!updatedIsLive);
      setIsLoading(false);
      return;
    }
  };

  const onCloseRemoveDialog = () => {
    setIsRemoveDialogOpen(false);
  };

  const onRemoveLocation = async () => {
    const removeLocationArgs: ILocationDeleteAsyncActionArgs = {
      locationId,
      providerDispatch,
      authProvider,
      busyDispatch,
      configState,
      errorDispatch,
      telemetryService,
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
    };

    try {
      setIsLoading(true);
      await locationDeleteAsyncAction(removeLocationArgs);
      notificationSetDispatch(
        notificationDispatch,
        t('settings.pharmacyLocationCardContent.removeLocationNotificationSuccessMessageText', {
          locationName,
        })
      );
      setIsLoading(false);
      onCloseRemoveDialog();
    } catch (error) {
      setIsLoading(false);
      return;
    }
  };

  const tierLevel = getTierSupportLevel();
  const canToggleIsLiveSwitch = tierLevel ? (tierLevel >= 2 ? true : false) : false;
  const theme = useTheme();

  const viewLocationCard = () => {
    return (
      <Card variant={'outlined'}>
        <Box display='flex'>
          <CardHeader title={locationName} />
          {canToggleIsLiveSwitch && (
            <FormControlLabel
              value='start'
              control={
                <Switch
                  color='primary'
                  inputProps={{ 'aria-label': 'live switch' }}
                  checked={isLive}
                  disableRipple={true}
                  onChange={handleSwitchOnChange}
                />
              }
              label={t('settings.pharmacyLocationCardContent.liveSwitchLabel')}
              labelPlacement='start'
              style={{ display: 'flex', flex: 'auto', margin: theme.spacing(1) }}
            />
          )}
        </Box>
        <CardContent>
          <Typography>{address1}</Typography>
          <Typography>{`${city}, ${state} ${zipCode}`}</Typography>
          <Typography variant='subtitle2'>{timezone}</Typography>
          <Typography data-heap-redact-text='true'>
            {PhoneNumberFormatter.formatForUI(phoneNumber)}
          </Typography>
          <Typography variant='body2'>
            {t('settings.pharmacyLocationCardContent.laneWithCount', { count: lanes?.length ?? 0 })}
          </Typography>
        </CardContent>
        <CardActions>
          <Button onClick={onEditButtonClick} size='small' color='primary'>
            {t('settings.pharmacyLocationCardContent.editButtonText')}
          </Button>
          <Button
            size='small'
            id='pharmacy-location-card-remove-button'
            onClick={onRemoveButtonClick}
            style={{ marginLeft: 'auto' }}
          >
            {t('settings.pharmacyLocationCardContent.removeButtonText')}
          </Button>
        </CardActions>
      </Card>
    );
  };

  return (
    <>
      {viewLocationCard()}
      <AddEditLocationModal
        locationId={locationId}
        isEditMode={true}
        isOpen={isEditDialogOpen}
        key={`edit-${locationId}`}
        locationName={locationName}
        address1={address1}
        city={city}
        state={state}
        zipCode={zipCode}
        phoneNumber={phoneNumber}
        lanes={lanes.slice().sort((a, b) => a.displayName.localeCompare(b.displayName))}
        onClosePress={onCloseEditDialog}
      />
      <RemoveLocationModal
        isOpen={isRemoveDialogOpen}
        heading={t('settings.pharmacyLocationCardContent.removeLocationDialogTitle')}
        removeButtonText={
          isLoading ? (
            <LoadingSpinner />
          ) : (
            t('settings.pharmacyLocationCardContent.removeButtonText')
          )
        }
        cancelButtonText={t('settings.pharmacyLocationCardContent.dialogCancelButtonText')}
        key={`remove-${locationId}`}
        onRemovePress={onRemoveLocation}
        onCancelPress={onCloseRemoveDialog}
      />
    </>
  );
};

PharmacyLocationCard.defaultProps = {
  locationId: '',
  locationName: '',
  address1: '',
  city: '',
  state: '',
  zipCode: '',
  timezone: '',
  phoneNumber: '',
  lanes: [] as ILaneInfo[],
} as IPharmacyLocationCardProps;
