// Copyright 2020 Prescryptive Health, Inc.

export const ApiErrors = {
  appointmentGet: (appointmentId: string, errorMessage: string): string =>
    `Error getting appointment for id '${appointmentId}' -- ${errorMessage}`,
  appointmentCancel: (appointmentId: string, errorMessage: string): string =>
    `Error canceling appointment for id '${appointmentId}' -- ${errorMessage}`,
  appointmentsGet: (errorMessage: string): string =>
    `Error getting appointments -- ${errorMessage}`,
  authenticationProviderUndefined: 'No authentication provider defined!',
  blockedTimeUnblock: (blockedTimeId: string, errorMessage: string): string =>
    `Error unblocking time for id '${blockedTimeId}' -- ${errorMessage}`,
  laneAvailabilityUpdate: (availabilityId: string, errorMessage: string): string =>
    `Error updating lane availability (id: ${availabilityId}) -- ${errorMessage}`,
  locationAvailabilityGet: (errorMessage: string): string =>
    `Error getting availability for provider store locations -- ${errorMessage}`,
  locationAvailabilityUpdate: (availabilityId: string, errorMessage: string): string =>
    `Error updating location availability (id: ${availabilityId}) -- ${errorMessage}`,
  locationAdd: (errorMessage: string): string => `Error adding a location -- ${errorMessage}`,
  locationUpdate: (locationId: string, errorMessage: string): string =>
    `Error updating location (locationId:${locationId}) -- ${errorMessage}`,
  locationDelete: (locationId: string, errorMessage: string): string =>
    `Error deleting location (locationId:${locationId})  -- ${errorMessage}`,
  locationIsTestUpdate: (errorMessage: string): string =>
    `Error editing isTest for location -- ${errorMessage}`,
  login: (errorCode: string): string => `Error logging in -- ${errorCode}`,
  noAccess: (errorMessage: string): string => `User does not have any access -- ${errorMessage}`,
  noProviderAccess: (errorMessage: string): string =>
    `User does not have access to any providers -- ${errorMessage}`,
  npiResultGet: (npi: string, errorMessage: string): string =>
    `Error getting npi result count for npi '${npi}' -- ${errorMessage}`,
  paymentReportsGet: (errorMessage: string): string =>
    `Error getting payment reports -- ${errorMessage}`,
  pharmacyServicePriceUpdate: (errorMessage: string): string =>
    `Error updating service price -- ${errorMessage}`,
  pharmacyServicePaymentUpdate: (errorMessage: string): string =>
    `Error updating service payment settings -- ${errorMessage}`,
  pharmacyServiceQuestionUpsert: (
    locationId: string,
    serviceType: string,
    questionId: string,
    errorMessage: string
  ): string =>
    `Error upserting pharmacy service question (location: ${locationId}; service: ${serviceType}; questionId: ${questionId}) -- ${errorMessage}`,
  pharmacyServiceQuestionDelete: (
    locationId: string,
    serviceType: string,
    questionId: string,
    errorMessage: string
  ): string =>
    `Error deleting pharmacy service question (location: ${locationId}; service: ${serviceType}; questionId: ${questionId}) -- ${errorMessage}`,
  pharmacyServiceUpdate: (locationId: string, serviceType: string, errorMessage: string): string =>
    `Error updating pharmacy service (location: ${locationId}; service: ${serviceType}) -- ${errorMessage}`,
  pharmacyServicesGet: (errorMessage: string): string =>
    `Error getting pharmacy services -- ${errorMessage}`,
  procedureSet: (errorMessage: string): string =>
    `Error setting procedure results -- ${errorMessage}`,
  providerAdd: (errorMessage: string): string => `Error adding a provider -- ${errorMessage}`,
  providerAddResponse: (providerName: string, errorMessage: string): string =>
    `Error receiving information of ${providerName} -- ${errorMessage}`,
  providerGet: (providerId: string, errorMessage: string): string =>
    `Error getting provider for id '${providerId}' -- ${errorMessage}`,
  providerUsersUpdate: (providerName: string, userId: string, errorMessage: string): string =>
    `Error updating user with id ${userId} for provider: ${providerName} -- ${errorMessage}`,
  providerUsersAdd: (providerName: string, errorMessage: string): string =>
    `Error adding user to provider: ${providerName} -- ${errorMessage}`,
  providerServiceAdd: (errorMessage: string): string =>
    `Error adding service to provider -- ${errorMessage}`,
  providerServiceSetActive: (errorMessage: string): string =>
    `Error setting provider service to active -- ${errorMessage}`,
  providerProgramSetActive: (errorMessage: string): string =>
    `Error setting provider program to active -- ${errorMessage}`,
  providerProgramAdd: (errorMessage: string): string =>
    `Error adding program to provider -- ${errorMessage}`,
  resourceNotFound: (url: string): string => `Resource '${url}' not found!`,
  serviceDetailsGet: (errorMessage: string): string =>
    `Error getting service details -- ${errorMessage}`,
  providerDetailsUpdate: (
    currentProviderName: string,
    newProviderName: string,
    contactPhoneNumber: string,
    contactEmail: string,
    cliaNumber: string,
    errorMessage: string
  ): string =>
    `Error updating provider details (current name: ${currentProviderName}; new name: ${newProviderName}; phone number: ${contactPhoneNumber}; email: ${contactEmail}; clia: ${cliaNumber}) -- ${errorMessage}`,
  servicesGet: (errorMessage: string): string => `Error getting services -- ${errorMessage}`,
  programsGet: (errorMessage: string): string => `Error getting programs -- ${errorMessage}`,
  storeLocationsGet: (errorMessage: string): string =>
    `Error getting provider store locations -- ${errorMessage}`,
  scheduleGet: (errorMessage: string): string => `Error getting schedule -- ${errorMessage}`,
  scheduleSecondAppointment: (errorMessage: string): string =>
    `Error scheduling second appointment -- ${errorMessage}`,
  userAccessGet: (errorMessage: string): string =>
    `Error getting user access for current user -- ${errorMessage}`,
  unexpectedResponseDataFormat: 'Unexpected response data format!',
  waitlistGet: (errorMessage: string): string => `Error getting waitlist -- ${errorMessage}`,
  waitlistAdd: (errorMessage: string): string => `Error adding to waitlist -- ${errorMessage}`,
  waitlistInvite: (errorMessage: string): string =>
    `Error inviting waitlist customers -- ${errorMessage}`,
  waitlistRemove: (locationId: string, waitlistItemId: string, errorMessage: string): string =>
    `Error removing waitlist customer '${waitlistItemId}' for location '${locationId}' -- ${errorMessage}`,
  reportTimeout: (): string =>
    `We are currently working on improvements to our reporting system. In the meantime, please reduce the date range of your report to avoid the report service timing out before it's ready for download.`,
  reportTooLarge: (): string =>
    `We are currently working on improvements to our reporting system. In the meantime, please reduce the date range of your report to avoid the report being too large for download.`,
};
