// Copyright 2021 Prescryptive Health, Inc.

import { Reducer } from 'react';
import { IScreenState } from './screen.state';
import { ScreenAction } from './actions/screen.action';

export type ScreenReducer = Reducer<IScreenState, ScreenAction>;

export const screenReducer: ScreenReducer = (
  state: IScreenState,
  action: ScreenAction
): IScreenState => {
  const payload = action.payload;
  return { ...state, ...payload };
};
