// Copyright 2021 Prescryptive Health, Inc.

import { ServiceStatus } from '../../../model/pharmacy-service';

const offResponseStatus = 'off';
const inviteOnlyResponseStatus = 'inviteOnly';
const everyoneResponseStatus = 'everyone';

const serviceStatusFromResponse = (responseStatus?: string): ServiceStatus => {
  switch (responseStatus) {
    case inviteOnlyResponseStatus:
      return 'invite-only';

    case everyoneResponseStatus:
      return 'everyone';

    default:
      return 'off';
  }
};

const serviceStatusToRequest = (status: ServiceStatus): string => {
  switch (status) {
    case 'invite-only':
      return inviteOnlyResponseStatus;

    case 'everyone':
      return everyoneResponseStatus;

    default:
      return offResponseStatus;
  }
};

export { serviceStatusFromResponse, serviceStatusToRequest };
