// Copyright 2020 Prescryptive Health, Inc.

import { useContext } from 'react';
import {
  IErrorContext,
  ErrorContext,
} from '../../providers/error/error.context';

export const useErrorContext = (): IErrorContext =>
  useContext<IErrorContext>(ErrorContext);
