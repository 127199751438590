import { gql } from '@apollo/client';

export const CREATE_PRICING_REPORT_MUTATION = gql`
  mutation createPricingReport(
    $locationId: ID
    $reportType: String
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    createPricingReport(
      locationId: $locationId
      reportType: $reportType
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
