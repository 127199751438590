// Copyright 2021 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import { useLocationContext } from '../../../../../providers/location/use-location-context.hook';
import { CopyText } from '../../../../copy/copy-text';
import { Box, Typography, useTheme } from '@mui/material';
import { StyledDeepLinkDescriptionTypography } from './deep-link.section.styled-components';
import ENV from '../../../../../helpers/environment-variables';
import { useTranslation } from 'react-i18next';

export interface IDeepLinkSection {
  serviceType: string;
}

export const DeepLinkSection = ({ serviceType }: IDeepLinkSection): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    locationState: { location },
  } = useLocationContext();

  const encodedLocation = encodeURIComponent(window.btoa(location?.id || ''));
  const encodedService = encodeURIComponent(window.btoa(serviceType));
  const serviceLink = t('services.deepLink.serviceLink', {
    myrxDomain: ENV.MYRX_DOMAIN,
    encodedLocation,
    encodedService,
  });
  const copyTextComponent = <CopyText textToCopy={serviceLink} />;

  return (
    <Box component='section'>
      <Typography variant='h3'>{t('services.deepLink.heading')}</Typography>
      <Box marginTop={theme.spacing(6)}>{copyTextComponent}</Box>
      <StyledDeepLinkDescriptionTypography variant='caption'>
        {t('services.deepLink.description')}
      </StyledDeepLinkDescriptionTypography>
    </Box>
  );
};
