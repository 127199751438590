// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { PrescryptiveIcon } from '../../../../prescryptive-icons/prescryptive-icon';
import { IconName, IconSize } from '../../../../../theming/icons';

export interface IQuestionCardIconLabelProps {
  iconName: IconName;
  label: string;
}

export const QuestionCardIconLabel = (props: IQuestionCardIconLabelProps): ReactElement => {
  const theme = useTheme();
  const { iconName, label } = props;

  return (
    <Box display='flex' flexDirection='row' alignItems='center'>
      <PrescryptiveIcon
        style={{ marginRight: theme.spacing(4) }}
        icon={iconName}
        color={theme.palette.grey[600]}
        size={IconSize.smaller}
      />
      <Typography>{label}</Typography>
    </Box>
  );
};
