// Copyright 2021 Prescryptive Health, Inc.

import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { appointmentsGet, ReportType } from '../../../api/v1/appointments-get/appointments-get';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { ReportsDispatch } from '../dispatch/reports.dispatch';
import { csvVaccinesResultStrategy } from '../../../api/v1/appointments-get/result-strategies/csv-vaccines/csv-vaccines.result-strategy';
import { reportsCsvSetDispatch } from '../dispatch/reports-csv-set.dispatch';
import {
  AppointmentsContentProvider,
  IAppointmentsGetResultStrategy,
} from '../../../api/v1/appointments-get/result-strategies/appointments-get.result-strategy';
import { csvAppointmentsResultStrategy } from '../../../api/v1/appointments-get/result-strategies/csv-appointments/csv-appointments.result-strategy';
import { ICsvRows } from '../../../model/csv-rows';
import { IPharmacyService } from '../../../model/pharmacy-service';
import { serviceNameFromType } from '../../../selectors/service-name';
import { nonFatalErrorDispatch } from '../../error/dispatch/non-fatal-error.dispatch';
import { NonFatalError } from '../../../api/errors/non-fatal.error';
import { logApiError } from '../../../api/errors/log-api-error';

export interface ICsvAppointmentsGetAsyncActionArgs extends IApiAsyncActionArgs {
  reportsDispatch: ReportsDispatch;
  locationId: string;
  startDate: Date;
  endDate: Date;
  services: IPharmacyService[];
  reportType: ReportType;
  reportFileName: string;
}

export const csvAppointmentsGetAsyncAction = async (
  args: ICsvAppointmentsGetAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: ICsvAppointmentsGetAsyncActionArgs): Promise<void> => {
  const {
    reportsDispatch,
    locationId,
    startDate,
    endDate,
    reportType,
    reportFileName,
    services,
    telemetryService,
    configState,
    authProvider,
    getAuthToken,
  } = args;

  try {
    telemetryService.trackEvent('appointments-get', {
      locationId,
      startDate,
      endDate,
      reportType,
    });

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    const contentProvider: AppointmentsContentProvider = (key, value) => {
      switch (key) {
        case 'service-name':
          return serviceNameFromType(value as string, services);

        default:
          return '?';
      }
    };

    const getResultStrategy = (): IAppointmentsGetResultStrategy<ICsvRows> => {
      switch (reportType) {
        case 'vaccine':
          return { strategy: csvVaccinesResultStrategy };

        default:
          return { strategy: csvAppointmentsResultStrategy, contentProvider };
      }
    };
    const result = await appointmentsGet(
      configState.apis.pharmacyPortal,
      getResultStrategy(),
      accessToken,
      locationId,
      startDate,
      reportType,
      endDate
    );
    reportsCsvSetDispatch(reportsDispatch, result.rows, reportFileName);
    if (reportType === 'vaccine') {
      telemetryService.trackEvent('iis-report-download', 'IIS report downloaded');
    }
  } catch (error) {
    if (error instanceof NonFatalError) {
      nonFatalErrorDispatch(args.errorDispatch, error.name, error.message);
    } else {
      fatalErrorDispatch(args.errorDispatch);
    }
    logApiError(telemetryService, error);
  }
};
