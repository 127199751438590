import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLocationContext } from '../../../../providers/location/use-location-context.hook';
import { PostAuthPrimaryScreenContainer } from '../../../screen-containers/post-auth-primary/post-auth-primary.screen-container';
import dateFormatter from '../../../../formatters/dates/date.formatter';
import initPharmacyPortalServiceGraphqlClient from '../../../../init-pharmacy-portal-service-graphql-client';
import {
  GET_PRICING_REPORTS_FILES_QUERY,
  IPricingReportFile,
} from './get-pricing-reports-files.query';
import { useLazyQuery } from '@apollo/client';
import { StyledReportsCard } from '../reports.screen.styled-components';
import { useEffect } from 'react';
import { Link, Typography } from '@mui/material';
import { isoDateStringToLocalDate } from '../../../../helpers/dates/date-helper/date.helper';
import { BackButtonBreadcrumb } from '../../add-service/details-screens-sections/back-button-breadcrumb/back-button-breadcrumb';
import { authenticatedRouteConfig } from '../../../../navigation/authenticated-route-config';
import { EmptyViewMessage } from '../../../text/messages/empty-view.message';
import { ErrorView } from '../../../error-view/error-view';

export const PricingReportsDownloadScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  document.title = t('reports.downloadReportScreen.pageTitle');

  const {
    locationState: { location },
  } = useLocationContext();

  const [getPricingReportFiles, { loading, error, data }] = useLazyQuery<{
    pricingReportFiles: IPricingReportFile[];
  }>(GET_PRICING_REPORTS_FILES_QUERY, {
    client: initPharmacyPortalServiceGraphqlClient,
    fetchPolicy: 'no-cache',
    variables: { locationId: location?.id },
  });

  useEffect(() => {
    if (!location) return;
    void getPricingReportFiles();
  }, [location]);

  const reportContainer = () => {
    if (loading) {
      return <EmptyViewMessage content={t('reports.downloadReportScreen.loading')} />;
    }
    if (error || !data) {
      return <ErrorView />;
    }
    return (
      <>
        <Typography>
          {data?.pricingReportFiles.length
            ? t('reports.downloadReportScreen.description')
            : t('reports.downloadReportScreen.descriptionNoFiles')}
        </Typography>
        {sortLatestFirst(data?.pricingReportFiles ?? []).map((report) => (
          <Link
            href={report.downloadUri}
            target='_blank'
            download={true}
            style={{ textDecoration: 'none' }}
            key={report.fileName}
          >
            <StyledReportsCard>
              <Typography variant='h2' component='h2'>
                {dateFormatter.formatToLongDate(isoDateStringToLocalDate(report.createdOn))}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                {report.fileName}
              </Typography>
            </StyledReportsCard>
          </Link>
        ))}
      </>
    );
  };

  const goBack = () => {
    void navigate(authenticatedRouteConfig.reports.createUrl());
  };

  return location ? (
    <PostAuthPrimaryScreenContainer>
      <BackButtonBreadcrumb
        onGoBack={goBack}
        backButtonText={t('reports.downloadReportScreen.goBackToReports')}
      />
      <Typography variant='h1' marginBottom={6}>
        {t('reports.downloadReportScreen.title')}
      </Typography>
      {reportContainer()}
    </PostAuthPrimaryScreenContainer>
  ) : (
    <EmptyViewMessage />
  );
};

const sortLatestFirst = (reports: IPricingReportFile[]): IPricingReportFile[] => {
  const reportsCopy = [...reports];
  reportsCopy.sort((a: IPricingReportFile, b: IPricingReportFile) =>
    a.createdOn > b.createdOn ? -1 : 1
  );
  return reportsCopy;
};
