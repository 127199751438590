// Copyright 2021 Prescryptive Health, Inc.

import { Typography } from '@mui/material';
import React, { ReactElement } from 'react';

interface IShortTextProps {
  shortText: string | null | undefined;
}

export const ShortText = ({ shortText }: IShortTextProps): ReactElement => {
  return <Typography>{shortText}</Typography>;
};