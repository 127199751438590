import DownloadIcon from '@mui/icons-material/Download';
import initPharmacyPortalServiceGraphqlClient from '../../../../init-pharmacy-portal-service-graphql-client';
import { DateTime } from 'luxon';
import { CREATE_PRICING_REPORT_MUTATION } from './create-pricing-report.mutation';
import { useMutation } from '@apollo/client';
import { Tooltip, Button } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNotificationContext } from '../../../../hooks/use-notification-context/use-notification-context.hook';
import { notificationSetDispatch } from '../../../../state/notification/dispatch/notification-set.dispatch';

interface IPricingReportsDownloadButtonProps {
  reportType: string;
  locationId: string;
  startDate: DateTime;
  endDate: DateTime;
}
export const PricingReportsDownloadButton = ({
  reportType,
  locationId,
  startDate,
  endDate,
}: IPricingReportsDownloadButtonProps) => {
  const { t } = useTranslation();
  const { notificationDispatch } = useNotificationContext();

  const [CreatePricingReport] = useMutation(CREATE_PRICING_REPORT_MUTATION, {
    client: initPharmacyPortalServiceGraphqlClient,
  });

  const downloadReportForAllClaims = () => {
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    const oneMonth = 30;
    const differenceInDays = Math.round(
      Math.abs((startDate.valueOf() - endDate.valueOf()) / oneDayInMilliseconds)
    );

    if (differenceInDays > oneMonth) {
      notificationSetDispatch(
        notificationDispatch,
        t('reports.downloadPricingReportButton.downloadDateRangeErrorMessage'),
        'error'
      );
      return;
    }

    CreatePricingReport({
      variables: {
        locationId,
        reportType,
        startDate: startDate.toFormat('MM-dd-yyyy'),
        endDate: endDate.toFormat('MM-dd-yyyy'),
      },
    });
    notificationSetDispatch(
      notificationDispatch,
      t('reports.downloadPricingReportButton.downloadSubmittedMessage')
    );
  };

  return (
    <Tooltip
      title={t('reports.downloadPricingReportButton.downloadTooltip') as string}
      placement='right-end'
    >
      <Box display='flex' flexDirection='row' justifyContent='flex-end' flex={1}>
        <Button
          onClick={downloadReportForAllClaims}
          disabled={false}
          data-testid='pricing-download-button'
        >
          <DownloadIcon fontSize='small' />
          {t('reports.downloadPricingReportButton.downloadButton')}
        </Button>
      </Box>
    </Tooltip>
  );
};
