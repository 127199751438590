// Copyright 2021 Prescryptive Health, Inc.

import { HttpStatusCodesEnum } from '../../http-status-codes';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { call } from '../../call';
import { ApiErrors } from '../../errors/api-errors';
import {
  ILaneResponse,
  IServiceHoursResponse,
  ILocationAvailabilityGetResponse,
  ITimeRangeResponse,
  IServiceInfoResponse,
} from './location-availability-get.response';
import {
  ILaneInfo,
  ILocationAvailability,
  IServiceHours,
  LocationAvailabilityMap,
} from '../../../model/location-availability';
import { ITimeRangeAndTypes } from '../../../model/availability-time';
import { IPharmacyService } from '../../../model/pharmacy-service';
import { serviceStatusFromResponse } from '../pharmacy-services-get/pharmacy-services.api-helper';
import { IAddress } from '../../../model/address';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export interface ILocationAvailabilityGetResult {
  locationAvailabilityMap: LocationAvailabilityMap;
}

export async function locationAvailabilityGet(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  providerId: string
): Promise<ILocationAvailabilityGetResult> {
  const url = buildUrl(apiConfig, 'locationAvailability', {
    ':providerId': providerId,
  });
  const headers = buildBearerAuthHeaders(authToken);

  const response: Response = await call(url, undefined, 'GET', headers);
  if (response.ok) {
    if (response.status === HttpStatusCodesEnum.NO_CONTENT) {
      return {
        locationAvailabilityMap: new Map<string, ILocationAvailability>(),
      };
    }

    return await resultFromResponse(response);
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.LOCATION_AVAILABILITY_GET,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  throw new PharmacyPortalError(
    ApiErrors.locationAvailabilityGet(response.statusText),
    ApiErrorCode.LOCATION_AVAILABILITY_GET,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}

async function resultFromResponse(response: Response): Promise<ILocationAvailabilityGetResult> {
  const responseData = (await response.json()) as ILocationAvailabilityGetResponse[];
  if (!responseData) {
    throw new PharmacyPortalError(
      ApiErrors.locationAvailabilityGet(ApiErrors.unexpectedResponseDataFormat),
      ApiErrorCode.LOCATION_AVAILABILITY_GET,
      PharmacyPortalErrorType.API_ERROR
    );
  }

  const result: ILocationAvailabilityGetResult = {
    locationAvailabilityMap: new Map<string, ILocationAvailability>(),
  };

  responseData.forEach((locationAvailabilityResponse) => {
    const {
      availabilityId: locationId,
      lanes: boothsResponse = [],
      serviceList: serviceListResponse = [],
      locationAddress = '',
      locationCity = '',
      locationState = '',
      locationZipCode = '',
    } = locationAvailabilityResponse;

    if (!locationId) {
      return;
    }

    const address: IAddress = {
      line1: locationAddress,
      city: locationCity,
      state: locationState,
      zip: locationZipCode,
    };

    const serviceList: IPharmacyService[] = serviceListResponse.map(serviceListItemFromResponse);

    const locationAvailabilityResult = locationAvailabilityResultFromResponse(
      boothsResponse,
      serviceList,
      address
    );
    result.locationAvailabilityMap.set(locationId, locationAvailabilityResult);
  });

  return result;
}

const serviceListItemFromResponse = (
  serviceInfoResponse: Partial<IServiceInfoResponse>
): IPharmacyService => {
  const {
    appointmentDuration = 0,
    serviceName = '',
    serviceType = '',
    status,
    price = 0,
  } = serviceInfoResponse;

  return {
    durationMinutes: appointmentDuration,
    name: serviceName,
    serviceType,
    status: serviceStatusFromResponse(status),
    price,
    paymentSettings: [],
    additionalQuestions: [],
  };
};

function locationAvailabilityResultFromResponse(
  boothsResponse: ILaneResponse[],
  listOfServices: IPharmacyService[],
  locationAddress: IAddress
): ILocationAvailability {
  const locationAvailabilityResult: ILocationAvailability = {
    address: locationAddress,
    serviceList: listOfServices,
    lanes: Array<ILaneInfo>(boothsResponse.length),
  };

  locationAvailabilityResult.lanes = fillLanes(boothsResponse);
  return locationAvailabilityResult;
}

function fillLanes(lanesResponse: ILaneResponse[]): ILaneInfo[] {
  const lanes = Array<ILaneInfo>(lanesResponse.length);

  lanesResponse.forEach((laneResponse, index) => {
    const booth: ILaneInfo = {
      displayName: laneResponse.displayName,
      emailAddress: laneResponse.emailAddress,
      serviceHours: fillServiceHours(laneResponse.serviceHours ?? []),
    };
    lanes[index] = booth;
  });

  return lanes;
}

function fillServiceHours(serviceHoursResponse: IServiceHoursResponse[]): IServiceHours[] {
  const serviceHours = Array<IServiceHours>(7);
  serviceHours.fill({ timeRanges: [] });
  serviceHoursResponse.forEach((responseHours, dayIndex) => {
    serviceHours[dayIndex] = {
      timeRanges: timeRangesFromResponse(responseHours.timeRanges),
    };
  });
  return serviceHours;
}

function timeRangesFromResponse(responseTimeRanges: ITimeRangeResponse[]): ITimeRangeAndTypes[] {
  return responseTimeRanges.map((responseTimeRange) => ({
    startTime: {
      hour: responseTimeRange.startHour ?? 8,
      minute: responseTimeRange.startMinute ?? 0,
    },
    endTime: {
      hour: responseTimeRange.endHour ?? 5,
      minute: responseTimeRange.endMinute ?? 0,
    },
    serviceTypes: responseTimeRange.serviceTypes ?? [],
  }));
}
