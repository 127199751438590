// Copyright 2020 Prescryptive Health, Inc.

export class PhoneNumberFormatter {
  public static clean(phoneNumber = ''): string {
    return phoneNumber.replace(/[^0-9]+/g, '');
  }

  public static formatForApi(phoneNumber: string): string {
    const cleanedPhoneNumber = PhoneNumberFormatter.clean(phoneNumber).trim();
    const countryCode = cleanedPhoneNumber.length > 10 ? '+' : '+1';

    return cleanedPhoneNumber ? `${countryCode}${cleanedPhoneNumber}` : '';
  }

  public static formatForUI(phoneNumberString = ''): string {
    const phoneNumberRegex = /^(1|)?(\d{3})(\d{3})(\d{4})$/;
    const cleanedString = ('' + phoneNumberString).replace(/\D/g, '');
    const match = phoneNumberRegex.exec(cleanedString);
    if (match) {
      const intlCode = '+1 ';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return '';
  }
}
