import { useAuth0 } from '@auth0/auth0-react';
import { ReactElement, useState } from 'react';
import { EmailCollectionModal } from './email-collection/email-collection.modal';
import { EmailSentModal } from './email-sent/email-sent.modal';

export const Auth0IdentityCreationContainer = (): ReactElement => {
  const { user } = useAuth0();

  const isInternalUser = user?.email?.includes('@prescryptive.com');

  const isAADUser =
    user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}/connectionStrategy`] === 'waad' &&
    user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}/identitiesCount`] === 1;

  const hasAcceptedTOU =
    user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}/userMetadata`]?.pharmacyPortalService
      ?.termsOfUseAccepted;

  const isOpen = isInternalUser ? false : isAADUser && hasAcceptedTOU;

  const [isEmailCollectionOpen, setIsEmailCollectionOpen] = useState(isOpen);
  const [isEmailSentOpen, setIsEmailSentOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const onEmailCollectionSkip = () => {
    setIsEmailCollectionOpen(false);
  };

  const onEmailCollectionContinue = () => {
    setIsEmailCollectionOpen(false);
    setIsEmailSentOpen(true);
  };

  const onEmailSentContinue = () => {
    setIsEmailSentOpen(false);
  };

  const onSetUserEmail = (email: string) => {
    setUserEmail(email.trim());
  };

  return (
    <>
      <EmailCollectionModal
        isOpen={isEmailCollectionOpen}
        onSkip={onEmailCollectionSkip}
        onContinue={onEmailCollectionContinue}
        userEmail={userEmail}
        setUserEmail={onSetUserEmail}
      />
      <EmailSentModal
        isOpen={isEmailSentOpen}
        onContinue={onEmailSentContinue}
        userEmail={userEmail}
      />
    </>
  );
};
