// Copyright 2021 Prescryptive Health, Inc.

import { createContext } from 'react';
import { ThemeMode } from './styled-theme.context-provider';

export interface IStyledThemeContext {
  readonly themeMode: ThemeMode;
  readonly setThemeMode: (themeName: ThemeMode) => void;
}

export const StyledThemeContext = createContext<IStyledThemeContext>({
  themeMode: 'default',
  setThemeMode: (_: ThemeMode) => ({}),
});
