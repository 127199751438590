// Copyright 2021 Prescryptive Health, Inc.

import { HttpStatusCodesEnum } from '../../http-status-codes';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { call } from '../../call';
import { ApiErrors } from '../../errors/api-errors';
import { IProviderDetailsUpdateRequest } from './provider-details-update.request';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export async function providerDetailsUpdate(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  providerId: string,
  newProviderName: string,
  contactPhoneNumber: string,
  contactEmail: string,
  cliaNumber: string
): Promise<void> {
  const url = buildUrl(apiConfig, 'providerDetailsUpdate', {
    ':providerId': providerId,
  });
  const headers = buildBearerAuthHeaders(authToken);
  const body: IProviderDetailsUpdateRequest = {
    providerName: newProviderName,
    phoneNumber: contactPhoneNumber,
    email: contactEmail,
    cliaNumber,
  };
  const response: Response = await call(url, body, 'PATCH', headers);
  if (response.ok) {
    return;
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.PROVIDER_DETAILS_UPDATE,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  throw new PharmacyPortalError(
    ApiErrors.providerDetailsUpdate(
      providerId,
      newProviderName,
      contactPhoneNumber,
      contactEmail,
      cliaNumber,
      response.statusText
    ),
    ApiErrorCode.PROVIDER_DETAILS_UPDATE,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}
