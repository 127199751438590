// Copyright 2021 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import {
  StyledContentContiner,
  StyledFloatingSignInBox,
  StyledFooterContainer,
  StyledFooterContentContainer,
  StyledHighFrequencyLandingPageContiner,
} from './high-frequency-landing-page.styled-components';
import { Box, Button, Link, Typography, useTheme } from '@mui/material';
import { SignInButton } from '../sign-in-button/sign-in-button';
import {
  ILoginAsyncActionArgs,
  loginAsyncAction,
} from '../../../../state/session/async-actions/login.async-action';
import { useSessionContext } from '../../../../providers/session/use-session-context.hook';
import { PrescryptiveProLogo } from '../../../prescryptivepro-logo/prescryptivepro-logo';
import { Copyright, OpenInNew, Sms } from '@mui/icons-material';
import { getNewDate } from '../../../../helpers/dates/get-new-date/get-new-date';
import {
  StyledFooterLink,
  StyledFooterSeparator,
} from '../footer/sign-up-footer.styled-components';
import { usePreAuthScreenContext } from '../../../../hooks/use-screen-context/use-pre-auth-screen-context/use-pre-auth-screen-context.hook';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LandingBanner } from '../../../landing-banner/landing-banner';

const MYRXPRO_SIGN_UP_URL = 'https://prescryptive.com/pharmacy-myrx-pro/';
const PRESCRYPTIVE_LINK = 'https://prescryptive.com/';
const CONTACT_US_LINK = 'https://prescryptive.com/contact-2/';
const TERMS_OF_USE_LINK = 'https://prescryptive.com/terms-of-use/';
const PRIVACY_POLICY_LINK = 'https://prescryptive.com/privacy-policy/';

export const HighFrequencyLandingPage = (): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { busyDispatch, configState, errorDispatch, telemetryService, authProvider } =
    usePreAuthScreenContext({ defaultContent: {} });

  const { sessionDispatch } = useSessionContext();

  const { auth0Migration, auth0MigrationBanner } = useFlags();
  const { loginWithRedirect } = useAuth0();

  const loginAsync = async () => {
    if (auth0Migration) {
      await loginWithRedirect({
        appState: { returnTo: window.location.href.replace(window.location.origin, '') },
      });
      return;
    }
    const args: ILoginAsyncActionArgs = {
      busyDispatch,
      configState,
      errorDispatch,
      telemetryService,
      sessionDispatch,
      authProvider,
      nonFatalErrorTitle: t('lander.highFrequencyLandingPage.signInError'),
      nonFatalErrorContent: t('lander.highFrequencyLandingPage.signInErrorContent'),
    };
    await loginAsyncAction(args);
  };

  const onFeedbackPress = () => {
    window.location.assign('mailto:pharmacyrelations@prescryptive.com');
  };

  return (
    <StyledHighFrequencyLandingPageContiner>
      {auth0MigrationBanner && <LandingBanner />}
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        height='100%'
        marginBottom='112px'
      >
        <StyledContentContiner>
          <PrescryptiveProLogo />
          <StyledFloatingSignInBox>
            <Typography variant='h5'>{t('lander.highFrequencyLandingPage.boxTitle')}</Typography>
            <SignInButton
              onSignInPress={loginAsync}
              dividerBackgroundColor={theme.palette.common.white}
              data-testid='sign-in-button'
            />
            <Typography>
              {t('lander.highFrequencyLandingPage.newToPrescryptiveProText')}
              <Link href={MYRXPRO_SIGN_UP_URL} target={'_blank'} underline='hover'>
                {t('lander.highFrequencyLandingPage.createAnAccountText')}
              </Link>
            </Typography>
          </StyledFloatingSignInBox>
        </StyledContentContiner>
        <Button color='primary' startIcon={<Sms />} onClick={onFeedbackPress}>
          {t('lander.highFrequencyLandingPage.giveUsFeedbackText')}
        </Button>
      </Box>
      <StyledFooterContainer>
        <StyledFooterContentContainer>
          <Typography variant='body2'>
            <StyledFooterLink href={PRESCRYPTIVE_LINK} target={'_blank'}>
              <Copyright style={{ fontSize: '1rem' }} />
              {t('lander.highFrequencyLandingPage.copyrightLinkText', {
                fullYear: getNewDate().getFullYear(),
              })}
              <OpenInNew style={{ fontSize: '1rem', paddingLeft: '8px' }} />
            </StyledFooterLink>
          </Typography>
          <Box display='flex'>
            <Typography variant='body2'>
              <StyledFooterLink href={CONTACT_US_LINK} target={'_blank'}>
                {t('lander.highFrequencyLandingPage.contactUsText')}
              </StyledFooterLink>
            </Typography>
            <StyledFooterSeparator>{' | '}</StyledFooterSeparator>
            <Typography variant='body2'>
              <StyledFooterLink href={TERMS_OF_USE_LINK} target={'_blank'}>
                {t('lander.highFrequencyLandingPage.termsOfUseText')}
              </StyledFooterLink>
            </Typography>
            <StyledFooterSeparator>{' | '}</StyledFooterSeparator>
            <Typography variant='body2'>
              <StyledFooterLink href={PRIVACY_POLICY_LINK} target={'_blank'}>
                {t('lander.highFrequencyLandingPage.privacyPolicyText')}
              </StyledFooterLink>
            </Typography>
          </Box>
        </StyledFooterContentContainer>
      </StyledFooterContainer>
    </StyledHighFrequencyLandingPageContiner>
  );
};
