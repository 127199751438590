// Copyright 2020 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { LabelDetail } from '../../label-detail';
import { IAppointment } from '../../appointment-details.screen.query';
import { useTranslation } from 'react-i18next';

export interface IPlanInfoSectionProps {
  appointment?: IAppointment;
}

export const PlanInfoSection = ({ appointment }: IPlanInfoSectionProps): ReactElement => {
  const { t } = useTranslation();

  const memberId = appointment?.patient?.memberId ?? '';
  const personCode = appointment?.patient?.personCode ?? '';
  const rxGroup = appointment?.patient?.rx.group ?? '';
  const rxBin = appointment?.patient?.rx.bin ?? '';
  const rxPcn = appointment?.patient?.rx.pcn ?? '';
  const theme = useTheme();
  return (
    <Box component='section' display='grid' gap={theme.spacing(4)}>
      <Typography variant='h2' component='h2'>
        {t('appointmentDetails.planInfo.sectionHeading')}
      </Typography>
      <Box display='flex' flexWrap='wrap' gap={theme.spacing(2)}>
        <Box flex='1' display='flex' flexDirection='column' gap={theme.spacing(2)}>
          <LabelDetail label={t('appointmentDetails.planInfo.memberIdLabel')} detail={memberId} />
          <LabelDetail label={t('appointmentDetails.planInfo.personCode')} detail={personCode} />
        </Box>
        <Box flex='1' display='flex' flexDirection='column' gap={theme.spacing(2)}>
          <LabelDetail label={t('appointmentDetails.planInfo.rxBinLabel')} detail={rxBin} />
          <LabelDetail label={t('appointmentDetails.planInfo.rxPcnLabel')} detail={rxPcn} />
          <LabelDetail label={t('appointmentDetails.planInfo.rxGroupLabel')} detail={rxGroup} />
        </Box>
      </Box>
    </Box>
  );
};
