// Copyright 2020 Prescryptive Health, Inc.

import { createContext } from 'react';
import { TelemetryService } from './telemetry.service';

export type TelemetryEvent =
  | 'appointment-cancel'
  | 'appointment-get'
  | 'appointments-get'
  | 'authentication'
  | 'blocked-time-unblock'
  | 'blocked-times-set'
  | 'click'
  | 'iis-report-download'
  | 'lane-availability-update'
  | 'location-availability-get'
  | 'location-availability-update'
  | 'payment-report-get'
  | 'pharmacy-service-price-update'
  | 'pharmacy-service-question-delete'
  | 'pharmacy-service-question-upsert'
  | 'pharmacy-service-update'
  | 'pharmacy-services-get'
  | 'procedure-set'
  | 'procedure-set-failure'
  | 'provider-details-get'
  | 'provider-details-update'
  | 'provider-service-add'
  | 'provider-service-set-active'
  | 'provider-program-add'
  | 'provider-users-get'
  | 'provider-users-add'
  | 'provider-users-update'
  | 'service-details-get'
  | 'services-get'
  | 'programs-get'
  | 'store-locations-get'
  | 'store-location-add'
  | 'store-location-update'
  | 'store-location-delete'
  | 'schedule-get'
  | 'schedule-second-appointment'
  | 'user-access-get'
  | 'waitlist-add'
  | 'waitlist-get'
  | 'waitlist-invite'
  | 'waitlist-remove'
  | 'waitlist-template-download'
  | 'content-data-service-type-mismatch'
  | 'location-is-test-update';

export interface ITelemetryContext {
  readonly telemetryService: TelemetryService<TelemetryEvent>;
}

const defaultContext: ITelemetryContext = {
  telemetryService: new TelemetryService<TelemetryEvent>(),
};

export const TelemetryContext = createContext<ITelemetryContext>(defaultContext);
