// Copyright 2021 Prescryptive Health, Inc.

import { v4 as uuidv4 } from 'uuid';
import { ReactElement } from 'react';
import { CSVReader } from 'react-papaparse';
import { IWaitlistItem } from '../../model/waitlist-item';
import { IPharmacyService } from '../../model/pharmacy-service';
import PublishIcon from '@mui/icons-material/Publish';
import { Box, Typography, useTheme } from '@mui/material';
import { StyledWaitlistCSVFileReaderNoFileUploadedTypography } from './waitlist-csv-file-reader.styled-components';
import dateValidator from '../../validators/date.validator/date.validator';
import { useTranslation } from 'react-i18next';

interface ICSVMetaData {
  aborted: boolean;
  cursor: number;
  delimiter: string;
  linebreak: string;
  truncated: boolean;
  fields?: string[];
}

interface ICSVErrorData {
  type: string;
  code: string;
  message: string;
  row: number;
}

export interface ICSVData {
  data: string[];
  errors: ICSVErrorData[];
  meta: ICSVMetaData;
}

interface ICSVFileReaderProps {
  onImportWaitlistItems: (waitListItems: IWaitlistItem[]) => void;
  services: IPharmacyService[];
  onErrorSave?: (errorLogs: string[]) => void;
}

export const WaitlistCsvFileReader = ({
  onImportWaitlistItems,
  services,
  onErrorSave = () => null,
}: ICSVFileReaderProps): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation();

  const csvViewStyle = {
    dropArea: {
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.grey[500],
      background: theme.palette.grey[50],
    },
    dropFile: { width: 'auto', background: theme.palette.grey[100] },
    fileNameInfo: {
      fontFamily: 'inherit',
      fontWeight: '400',
      background: 'none',
    },
    fileSizeInfo: { display: 'none' },
  };

  const config = {
    skipEmptyLines: true,
  };

  const getServiceType = (serviceName: string): string | undefined => {
    const foundService = services.find((service) => service.name === serviceName);
    return foundService ? foundService.serviceType : undefined;
  };

  const handleOnRemoveFile = () => {
    onImportWaitlistItems([]);
    onErrorSave([]);
  };

  const formatError = (dataRow: string[], error: string): string => {
    return `${dataRow.join()}\n\t${error}`;
  };

  const handleOnDrop = (data: ICSVData[]) => {
    const updatedData: IWaitlistItem[] = [];
    const updatedErrorLog: string[] = [];

    data.slice(1).forEach((waitlistItem, index) => {
      const dataRow = waitlistItem.data;
      const serviceName = dataRow[4] ? dataRow[4].trim() : '';
      const serviceType = getServiceType(serviceName);

      const rowNumber = index + 1;

      if (dataRow.length !== 5)
        updatedErrorLog.push(
          formatError(dataRow, t('waitlist.CSVFileReader.fieldsLengthError', { index: rowNumber }))
        );
      else if (!dataRow[0] || dataRow[0].length === 0)
        updatedErrorLog.push(
          formatError(dataRow, t('waitlist.CSVFileReader.firstNameError', { index: rowNumber }))
        );
      else if (!dataRow[1] || dataRow[1].length === 0)
        updatedErrorLog.push(
          formatError(dataRow, t('waitlist.CSVFileReader.lastNameError', { index: rowNumber }))
        );
      else if (!dataRow[2] || dataRow[2].length === 0)
        updatedErrorLog.push(
          formatError(dataRow, t('waitlist.CSVFileReader.birthDateError', { index: rowNumber }))
        );
      else if (!dateValidator.isValidMMDDYYYYDate(dataRow[2]))
        updatedErrorLog.push(
          formatError(
            dataRow,
            t('waitlist.CSVFileReader.birthDateFormatError', { index: rowNumber })
          )
        );
      else if (!dataRow[3] || dataRow[3].length === 0)
        updatedErrorLog.push(
          formatError(dataRow, t('waitlist.CSVFileReader.phoneNumberError', { index: rowNumber }))
        );
      else if (dataRow[3].trim().length !== 10)
        updatedErrorLog.push(
          formatError(
            dataRow,
            t('waitlist.CSVFileReader.phoneNumberFormatError', { index: rowNumber })
          )
        );
      else if (!dataRow[4] || dataRow[4].length === 0)
        updatedErrorLog.push(
          formatError(dataRow, t('waitlist.CSVFileReader.serviceNameError', { index: rowNumber }))
        );
      else if (!serviceType)
        updatedErrorLog.push(
          formatError(
            dataRow,
            t('waitlist.CSVFileReader.serviceNameUnavailableError', { index: rowNumber })
          )
        );
      else {
        const newEntry: IWaitlistItem = {
          id: uuidv4(),
          firstName: waitlistItem.data[0].trim(),
          lastName: waitlistItem.data[1].trim(),
          birthDate: new Date(waitlistItem.data[2]),
          phoneNumber: waitlistItem.data[3].trim(),
          isGlobalWaitlist: false,
          serviceType,
        };
        updatedData.push(newEntry);
      }
    });

    onErrorSave(updatedErrorLog);
    if (updatedErrorLog.length === 0) onImportWaitlistItems(updatedData);
  };

  return (
    <Box marginBottom={theme.spacing(4)}>
      <CSVReader
        onDrop={handleOnDrop}
        addRemoveButton={true}
        onRemoveFile={handleOnRemoveFile}
        config={config}
        style={csvViewStyle}
      >
        <Box marginBottom={theme.spacing(5)}>
          <PublishIcon fontSize='large' color='primary' />
        </Box>
        <StyledWaitlistCSVFileReaderNoFileUploadedTypography>
          {t('waitlist.CSVFileReader.noFileUploadedLabel')}
        </StyledWaitlistCSVFileReaderNoFileUploadedTypography>
        <Typography align='center'>{t('waitlist.CSVFileReader.clickHereLabel')}</Typography>
      </CSVReader>
    </Box>
  );
};
