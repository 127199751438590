// Copyright 2021 Prescryptive Health, Inc.

import { Reducer } from 'react';
import { WaitlistAction } from './actions/waitlist.action';
import { IWaitlistState } from './waitlist.state';

export type WaitlistReducer = Reducer<IWaitlistState, WaitlistAction>;

export const waitlistReducer: WaitlistReducer = (
  state: IWaitlistState,
  action: WaitlistAction
): IWaitlistState => {
  const payload = action.payload;
  return { ...state, ...payload };
};
