// Copyright 2020 Prescryptive Health, Inc.

import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { appointmentsAreStaleDispatch } from '../../appointments/dispatch/appointments-are-stale.dispatch';
import { AppointmentsDispatch } from '../../appointments/dispatch/appointments.dispatch';
import { appointmentCancel } from '../../../api/v1/appointment-cancel/appointment-cancel';
import { logApiError } from '../../../api/errors/log-api-error';

export interface IAppointmentCancelAsyncActionArgs extends IApiAsyncActionArgs {
  appointmentId: string;
  appointmentsDispatch: AppointmentsDispatch;
}

export const appointmentCancelAsyncAction = async (
  args: IAppointmentCancelAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IAppointmentCancelAsyncActionArgs): Promise<void> => {
  const {
    appointmentId,
    telemetryService,
    appointmentsDispatch,
    configState,
    authProvider,
    getAuthToken,
  } = args;

  try {
    telemetryService.trackEvent('appointment-cancel', {
      appointmentId,
    });

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }
    const accessToken = await getAuthToken;

    await appointmentCancel(configState.apis.pharmacyPortal, accessToken, appointmentId);

    appointmentsAreStaleDispatch(appointmentsDispatch);
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
    return Promise.reject();
  }
};
