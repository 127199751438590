// Copyright 2020 Prescryptive Health, Inc.

import React, { FunctionComponent, useReducer } from 'react';
import {
  AppointmentsReducer,
  appointmentsReducer,
} from '../../state/appointments/appointments.reducer';
import { defaultAppointmentsState } from '../../state/appointments/appointments.state';
import { AppointmentsContext } from './appointments.context';

export const AppointmentsContextProvider: FunctionComponent = ({
  children,
}) => {
  const [appointmentsState, appointmentsDispatch] = useReducer<
    AppointmentsReducer
  >(appointmentsReducer, defaultAppointmentsState);
  return (
    <AppointmentsContext.Provider
      value={{ appointmentsState, appointmentsDispatch }}
    >
      {children}
    </AppointmentsContext.Provider>
  );
};
