import { ReactElement, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { StyledAppointmentsScreenMenuButton } from '../appointments.screen.styled-components';
import LockIcon from '@mui/icons-material/Lock';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { usePostAuthContext } from '../../../../providers/post-auth/use-post-auth-context.hook';
import { useProviderContext } from '../../../../providers/provider/use-provider-context.hook';
import { useTranslation } from 'react-i18next';

export interface IAppointmentCreateOptionsProps {
  areControlsDisabled: boolean;
  setIsOpenBlocktimeModal: (boolean) => void;
  setIsOpenInviteForAppointmentModal: (boolean) => void;
}

export const AppointmentCreateOptions = (props: IAppointmentCreateOptionsProps): ReactElement => {
  const { t } = useTranslation();
  const { areControlsDisabled, setIsOpenBlocktimeModal, setIsOpenInviteForAppointmentModal } =
    props;

  const {
    authState: { userAccess },
  } = usePostAuthContext();

  const {
    providerState: { currentProvider },
  } = useProviderContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const menuIsOpen = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onBlocktimePress = () => {
    handleCloseMenu();
    setIsOpenBlocktimeModal(true);
  };

  const onInviteForAppointmentPress = () => {
    handleCloseMenu();
    setIsOpenInviteForAppointmentModal(true);
  };

  const currentProviderRules = userAccess?.rules.find(
    (rule) => rule.name === currentProvider?.name
  );

  const canBlockTime = currentProviderRules && currentProviderRules.role === 'Admin';

  return (
    <>
      <StyledAppointmentsScreenMenuButton
        data-testid='create-options-button'
        onClick={handleOpenMenu}
        disabled={areControlsDisabled}
        variant='outlined'
        startIcon={<AddIcon color={areControlsDisabled ? undefined : 'primary'} />}
        endIcon={<ExpandMoreIcon color={areControlsDisabled ? undefined : 'primary'} />}
      >
        {t('appointments.appointmentsScreen.createOptionsButton')}
      </StyledAppointmentsScreenMenuButton>
      <Menu
        data-testid='create-menu'
        variant='selectedMenu'
        open={menuIsOpen}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
      >
        <MenuItem
          id='invite-for-appointment-button'
          data-testid='invite-for-appointment-button'
          onClick={onInviteForAppointmentPress}
        >
          <LocalPostOfficeIcon sx={{ color: 'gray', marginRight: 2 }} />
          {t('appointments.appointmentsScreen.inviteButtonLabel')}
        </MenuItem>
        <MenuItem
          id='block-time-button'
          data-testid='block-time-button'
          onClick={onBlocktimePress}
          disabled={!canBlockTime}
        >
          <LockIcon sx={{ color: 'gray', marginRight: 2 }} />
          {t('appointments.appointmentsScreen.blockTimeButtonLabel')}
        </MenuItem>
      </Menu>
    </>
  );
};
