// Copyright 2021 Prescryptive Health, Inc.

import { gql } from '@apollo/client';

export const GET_SERVICE_DETAILS_QUERY = gql`
  query ProviderServiceDetails($id: ID!) {
    providerService(id: $id) {
      id
      ServiceType
      Description
      Title
      Intro {
        ... on ComponentProviderServiceContentTypesImageLink {
          __typename
          id
          ImageLinkUrl {
            __typename
            id
            Target
            DisplayText
            URLAddress
          }
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesMediaFile {
          __typename
          id
          IsLink
          LinkDisplayText
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesUrl {
          __typename
          id
          DisplayText
          URLAddress
        }
        ... on ComponentProviderServiceContentTypesLongText {
          __typename
          id
          longText
        }
        ... on ComponentProviderServiceContentTypesShortText {
          __typename
          id
          shortText
        }
        ... on ComponentProviderServiceContentTypesValueProp {
          __typename
          id
          valuePropIcon {
            id
            alternativeText
            caption
            ext
            height
            mime
            name
            previewUrl
            url
            width
          }
          valuePropTitle
          valuePropText
        }
        ... on ComponentProviderServiceContentTypesTitleDescription {
          __typename
          id
          Description
          Title
          TitleDescriptionUrl {
            id
            Target
            DisplayText
            URLAddress
          }
        }
        ... on ComponentProviderServiceContentTypesSectionHeading {
          __typename
          id
          HeadingText
        }
      }
      EnrollProcess {
        ... on ComponentProviderServiceContentTypesImageLink {
          __typename
          id
          ImageLinkUrl {
            __typename
            id
            Target
            DisplayText
            URLAddress
          }
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesMediaFile {
          __typename
          id
          IsLink
          LinkDisplayText
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesUrl {
          __typename
          id
          DisplayText
          URLAddress
        }
        ... on ComponentProviderServiceContentTypesLongText {
          __typename
          id
          longText
        }
        ... on ComponentProviderServiceContentTypesShortText {
          __typename
          id
          shortText
        }
        ... on ComponentProviderServiceContentTypesValueProp {
          __typename
          id
          valuePropIcon {
            id
            alternativeText
            caption
            ext
            height
            mime
            name
            previewUrl
            url
            width
          }
          valuePropTitle
          valuePropText
        }
        ... on ComponentProviderServiceContentTypesTitleDescription {
          __typename
          id
          Description
          Title
          TitleDescriptionUrl {
            id
            Target
            DisplayText
            URLAddress
          }
        }
        ... on ComponentProviderServiceContentTypesSectionHeading {
          __typename
          id
          HeadingText
        }
      }
      Faq {
        ... on ComponentProviderServiceContentTypesImageLink {
          __typename
          id
          ImageLinkUrl {
            __typename
            id
            Target
            DisplayText
            URLAddress
          }
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesMediaFile {
          __typename
          id
          IsLink
          LinkDisplayText
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesUrl {
          __typename
          id
          DisplayText
          URLAddress
        }
        ... on ComponentProviderServiceContentTypesLongText {
          __typename
          id
          longText
        }
        ... on ComponentProviderServiceContentTypesShortText {
          __typename
          id
          shortText
        }
        ... on ComponentProviderServiceContentTypesValueProp {
          __typename
          id
          valuePropIcon {
            id
            alternativeText
            caption
            ext
            height
            mime
            name
            previewUrl
            url
            width
          }
          valuePropTitle
          valuePropText
        }
        ... on ComponentProviderServiceContentTypesTitleDescription {
          __typename
          id
          Description
          Title
          TitleDescriptionUrl {
            id
            Target
            DisplayText
            URLAddress
          }
        }
        ... on ComponentProviderServiceContentTypesSectionHeading {
          __typename
          id
          HeadingText
        }
      }
      HelpSection {
        ... on ComponentProviderServiceContentTypesImageLink {
          __typename
          id
          ImageLinkUrl {
            __typename
            id
            Target
            DisplayText
            URLAddress
          }
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesMediaFile {
          __typename
          id
          IsLink
          LinkDisplayText
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesLongText {
          __typename
          id
          longText
        }
        ... on ComponentProviderServiceContentTypesShortText {
          __typename
          id
          shortText
        }
        ... on ComponentProviderServiceContentTypesValueProp {
          __typename
          id
          valuePropIcon {
            id
            alternativeText
            caption
            ext
            height
            mime
            name
            previewUrl
            url
            width
          }
          valuePropTitle
          valuePropText
        }
        ... on ComponentProviderServiceContentTypesTitleDescription {
          __typename
          id
          Description
          Title
          TitleDescriptionUrl {
            id
            Target
            DisplayText
            URLAddress
          }
        }
        ... on ComponentProviderServiceContentTypesUrl {
          __typename
          id
          DisplayText
          URLAddress
        }
        ... on ComponentProviderServiceContentTypesSectionHeading {
          __typename
          id
          HeadingText
        }
      }
      HowItWorks {
        ... on ComponentProviderServiceContentTypesImageLink {
          __typename
          id
          ImageLinkUrl {
            __typename
            id
            Target
            DisplayText
            URLAddress
          }
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesMediaFile {
          __typename
          id
          IsLink
          LinkDisplayText
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesUrl {
          __typename
          id
          DisplayText
          URLAddress
        }
        ... on ComponentProviderServiceContentTypesLongText {
          __typename
          id
          longText
        }
        ... on ComponentProviderServiceContentTypesShortText {
          __typename
          id
          shortText
        }
        ... on ComponentProviderServiceContentTypesTitleDescription {
          __typename
          id
          Description
          Title
          TitleDescriptionUrl {
            id
            Target
            DisplayText
            URLAddress
          }
        }
        ... on ComponentProviderServiceContentTypesSectionHeading {
          __typename
          id
          HeadingText
        }
        ... on ComponentProviderServiceContentTypesValueProp {
          __typename
          id
          valuePropIcon {
            id
            alternativeText
            caption
            ext
            height
            mime
            name
            previewUrl
            url
            width
          }
          valuePropTitle
          valuePropText
        }
      }
      MarketingResources {
        ... on ComponentProviderServiceContentTypesImageLink {
          __typename
          id
          ImageLinkUrl {
            __typename
            id
            Target
            DisplayText
            URLAddress
          }
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesMediaFile {
          __typename
          id
          IsLink
          LinkDisplayText
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesLongText {
          __typename
          id
          longText
        }
        ... on ComponentProviderServiceContentTypesShortText {
          __typename
          id
          shortText
        }
        ... on ComponentProviderServiceContentTypesTitleDescription {
          __typename
          id
          Description
          Title
          TitleDescriptionUrl {
            id
            Target
            DisplayText
            URLAddress
          }
        }
        ... on ComponentProviderServiceContentTypesValueProp {
          __typename
          id
          valuePropIcon {
            id
            alternativeText
            caption
            ext
            height
            mime
            name
            previewUrl
            url
            width
          }
          valuePropTitle
          valuePropText
        }
        ... on ComponentProviderServiceContentTypesUrl {
          __typename
          id
          DisplayText
          URLAddress
        }
        ... on ComponentProviderServiceContentTypesSectionHeading {
          __typename
          id
          HeadingText
        }
      }
      PharmacyCosts {
        ... on ComponentProviderServiceContentTypesImageLink {
          __typename
          id
          ImageLinkUrl {
            __typename
            id
            Target
            DisplayText
            URLAddress
          }
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesMediaFile {
          __typename
          id
          IsLink
          LinkDisplayText
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesUrl {
          __typename
          id
          DisplayText
          URLAddress
        }
        ... on ComponentProviderServiceContentTypesLongText {
          __typename
          id
          longText
        }
        ... on ComponentProviderServiceContentTypesShortText {
          __typename
          id
          shortText
        }
        ... on ComponentProviderServiceContentTypesValueProp {
          __typename
          id
          valuePropIcon {
            id
            alternativeText
            caption
            ext
            height
            mime
            name
            previewUrl
            url
            width
          }
          valuePropTitle
          valuePropText
        }
        ... on ComponentProviderServiceContentTypesTitleDescription {
          __typename
          id
          Description
          Title
          TitleDescriptionUrl {
            id
            Target
            DisplayText
            URLAddress
          }
        }
        ... on ComponentProviderServiceContentTypesSectionHeading {
          __typename
          id
          HeadingText
        }
      }
      ThingsToConsider {
        ... on ComponentProviderServiceContentTypesImageLink {
          __typename
          id
          ImageLinkUrl {
            __typename
            id
            Target
            DisplayText
            URLAddress
          }
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesMediaFile {
          __typename
          id
          IsLink
          LinkDisplayText
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesUrl {
          __typename
          id
          DisplayText
          URLAddress
        }
        ... on ComponentProviderServiceContentTypesLongText {
          __typename
          id
          longText
        }
        ... on ComponentProviderServiceContentTypesShortText {
          __typename
          id
          shortText
        }
        ... on ComponentProviderServiceContentTypesValueProp {
          __typename
          id
          valuePropIcon {
            id
            alternativeText
            caption
            ext
            height
            mime
            name
            previewUrl
            url
            width
          }
          valuePropTitle
          valuePropText
        }
        ... on ComponentProviderServiceContentTypesTitleDescription {
          __typename
          id
          Description
          Title
          TitleDescriptionUrl {
            id
            Target
            DisplayText
            URLAddress
          }
        }
        ... on ComponentProviderServiceContentTypesSectionHeading {
          __typename
          id
          HeadingText
        }
      }
      WhatYouGet {
        ... on ComponentProviderServiceContentTypesImageLink {
          __typename
          id
          ImageLinkUrl {
            __typename
            id
            Target
            DisplayText
            URLAddress
          }
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesMediaFile {
          __typename
          id
          IsLink
          LinkDisplayText
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesUrl {
          __typename
          id
          DisplayText
          URLAddress
        }
        ... on ComponentProviderServiceContentTypesLongText {
          __typename
          id
          longText
        }
        ... on ComponentProviderServiceContentTypesShortText {
          __typename
          id
          shortText
        }
        ... on ComponentProviderServiceContentTypesValueProp {
          __typename
          id
          valuePropIcon {
            id
            alternativeText
            caption
            ext
            height
            mime
            name
            previewUrl
            url
            width
          }
          valuePropTitle
          valuePropText
        }
        ... on ComponentProviderServiceContentTypesTitleDescription {
          __typename
          id
          Description
          Title
          TitleDescriptionUrl {
            id
            Target
            DisplayText
            URLAddress
          }
        }
        ... on ComponentProviderServiceContentTypesSectionHeading {
          __typename
          id
          HeadingText
        }
      }
      RightRail {
        __typename
        ... on ComponentProviderServiceContentTypesImageLink {
          __typename
          id
          ImageLinkUrl {
            __typename
            id
            Target
            DisplayText
            URLAddress
          }
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesMediaFile {
          __typename
          id
          IsLink
          LinkDisplayText
          ImageFile {
            __typename
            id
            name
            alternativeText
            ext
            url
            mime
            height
            width
          }
        }
        ... on ComponentProviderServiceContentTypesUrl {
          __typename
          id
          DisplayText
          URLAddress
        }
        ... on ComponentProviderServiceContentTypesLongText {
          __typename
          id
          longText
        }
        ... on ComponentProviderServiceContentTypesShortText {
          __typename
          id
          shortText
        }
        ... on ComponentProviderServiceContentTypesValueProp {
          __typename
          id
          valuePropIcon {
            id
            alternativeText
            caption
            ext
            height
            mime
            name
            previewUrl
            url
            width
          }
          valuePropTitle
          valuePropText
        }
        ... on ComponentProviderServiceContentTypesTitleDescription {
          __typename
          id
          Description
          Title
          TitleDescriptionUrl {
            id
            Target
            DisplayText
            URLAddress
          }
        }
        ... on ComponentProviderServiceContentTypesSectionHeading {
          __typename
          id
          HeadingText
        }
      }
    }
  }
`;
