// Copyright 2021 Prescryptive Health, Inc.

import { Avatar } from '@mui/material';
import React, { ReactElement } from 'react';

interface IMyPrescryptiveLogoProps {
  width?: string;
  height?: string;
}

export const MyPrescryptiveLogo = ({ width, height }: IMyPrescryptiveLogoProps): ReactElement => {
  const logoWidth = width ? width : '100px';
  const logoHeight = height ? height : '47px';

  const logoURI = process.env.PUBLIC_URL + '/media/logo/myPrescryptive-2023.svg';

  return (
    <Avatar
      src={logoURI}
      variant='square'
      alt='myPrescryptive logo'
      style={{ width: logoWidth, height: logoHeight }}
    />
  );
};
