// Copyright 2021 Prescryptive Health, Inc.
import React, { ReactElement, useEffect, useState } from 'react';
import DateFormatter from '../../../formatters/dates/date.formatter';
import { DayPeriod, TimeSelect } from '../../inputs/time-select/time-select';
import { getNewDate } from '../../../helpers/dates/get-new-date/get-new-date';
import { getNewDateObject } from '../../../helpers/dates/get-new-date/get-new-date-object';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ErrorMessageTypography,
  StyledBlockTimeSectionContainer,
} from './block-time.modal.styled-components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

export interface IBlocktimeModalProps {
  isOpen: boolean;
  isLoading: boolean;
  date?: Date;
  onBlockTime: (start: Date, durationMinutes: number, reason?: string) => void;
  onCloseDialog: () => void;
}

const blockTimeGranularityMinutes = 5;

export const BlockTimeModal = ({
  date,
  onBlockTime,
  isOpen,
  onCloseDialog,
  isLoading
}: IBlocktimeModalProps): ReactElement => {
  const { t } = useTranslation();

  const theme = useTheme();

  const cloneDate = (dateToClone: Date): Date => {
    const cloned = DateTime.fromJSDate(dateToClone);
    return new Date(
      cloned.year,
      cloned.month - 1,
      cloned.day,
      cloned.hour,
      cloned.minute,
      cloned.second
    );
  };

  const roundToCeiling = (x: number, granurality: number) => {
    return Math.ceil(x / granurality) * granurality;
  };

  const setDateToGranularity = (dateToSet: Date): Date => {
    const newDate = cloneDate(dateToSet);
    const currentMinutes = newDate.getMinutes();
    const minutes = roundToCeiling(currentMinutes, blockTimeGranularityMinutes);
    newDate.setMinutes(minutes);
    return newDate;
  };

  const setDateTimeDefaultMinutes = (dateToSet: Date): Date => {
    const endDate = setDateToGranularity(dateToSet);
    endDate.setMinutes(endDate.getMinutes() + blockTimeGranularityMinutes);
    return endDate;
  };

  const initialStartTime = getNewDate();

  const [reason, setReason] = useState<string>('');
  const [startTime, setStartTime] = useState<Date>(setDateToGranularity(date ?? initialStartTime));

  const [endTime, setEndTime] = useState<Date>(setDateTimeDefaultMinutes(date ?? initialStartTime));

  const [blockDuration, setBlockDuration] = useState<number>(0);
  const [dayPeriodEndTime, setDayPeriodEndTime] = useState<DayPeriod | undefined>();

  const onHandleBlockTime = () => {
    if (isStartTimeEarlierThanCurrentTime() || blockDuration <= 0) {
      return;
    }

    onBlockTime(startTime, blockDuration, reason);
  };

  const onHandleChangeReason = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setReason(event.target.value);
  };

  const onHandleStartTimeChange = (value: Date) => {
    setStartTime(value);
    const hours = value.getHours();

    if (hours >= 12) {
      setDayPeriodEndTime('PM');
      const endHours = endTime.getHours() === 0 ? 23 : endTime.getHours();
      const endHoursAsPM = endHours >= 12 ? endHours : endHours + 12;

      const endTimeAsPM = new Date(
        endTime.getFullYear(),
        endTime.getMonth(),
        endTime.getDate(),
        endHoursAsPM,
        endTime.getMinutes()
      );
      setEndTime(endTimeAsPM);
    } else {
      setDayPeriodEndTime(undefined);
    }
  };

  const onHandleEndTimeChange = (value: Date) => {
    setEndTime(value);
  };

  const getDurationString = (duration: number) => {
    return DateFormatter.formatMinutesDuration(duration, {
      oneMinute: t('timeContent.long.oneMinute'),
      oneHour: t('timeContent.long.oneHour'),
      minutes: t('timeContent.long.minutes'),
      hours: t('timeContent.long.hours'),
    });
  };

  const setBlocktimeDuration = (diffTime: number) => {
    const durationMinutes = Math.ceil(diffTime / (1000 * 60));
    setBlockDuration(durationMinutes);
  };

  const isStartTimeEarlierThanCurrentTime = () => {
    return startTime < getNewDateObject();
  };

  const errorMessage = isStartTimeEarlierThanCurrentTime()
    ? t('appointments.blockTimeModal.errorPastTimeMessage')
    : blockDuration > 0
    ? ''
    : t('appointments.blockTimeModal.errorMessage');

  const summary = t('appointments.blockTimeModal.summaryText', {
    formattedStartTime: `${DateFormatter.formatTime(startTime)} ${DateFormatter.timeZone(
      startTime
    )}`,
    formattedEndTime: `${DateFormatter.formatTime(endTime)} ${DateFormatter.timeZone(startTime)}`,
    duration: getDurationString(blockDuration),
  });

  useEffect(() => {
    if (!date) {
      return;
    }

    setStartTime(setDateToGranularity(date));
    setEndTime(setDateTimeDefaultMinutes(date));
  }, [date]);

  useEffect(() => {
    if (isOpen) {
      setReason('');
    }
  }, [isOpen]);

  useEffect(() => {
    const diffTime = endTime.getTime() - startTime.getTime();
    setBlocktimeDuration(diffTime);
  }, [startTime, endTime]);

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseDialog}
      id='block-time-modal'
      aria-labelledby='block-time-modal-title'
      maxWidth='md'
    >
      <DialogTitle id='block-time-modal-title'>
        {t('appointments.blockTimeModal.title')}
      </DialogTitle>
      <DialogContent>
        <Typography
          style={{
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
          }}
        >
          {t('appointments.blockTimeModal.description')}
        </Typography>
        <Box component='section'>
          <Box style={{ marginBottom: theme.spacing(12) }}>
            <Typography>{t('appointments.blockTimeModal.dateLabel')}</Typography>
            <Typography variant='h5' style={{ marginBottom: theme.spacing(6) }}>
              {DateFormatter.formatToLongDate(date ?? getNewDate())}
            </Typography>
            <Typography style={{ marginBottom: theme.spacing(2) }}>
              {t('appointments.blockTimeModal.reasonLabel')}
            </Typography>
            <TextField
              variant='outlined'
              label={t('appointments.blockTimeModal.inputPlaceholder')}
              fullWidth={true}
              onChange={onHandleChangeReason}
            />
          </Box>
          <StyledBlockTimeSectionContainer>
            <Typography variant='h5' style={{ marginBottom: theme.spacing(3) }}>
              {t('appointments.blockTimeModal.timeLabel')}
            </Typography>
            <TimeSelect
              minuteGranularity={blockTimeGranularityMinutes}
              onSelectTime={onHandleStartTimeChange}
              label={t('appointments.blockTimeModal.startLabel')}
              defaultValue={startTime}
            />
            <Box height={theme.spacing(6)} />
            <TimeSelect
              minuteGranularity={blockTimeGranularityMinutes}
              onSelectTime={onHandleEndTimeChange}
              label={t('appointments.blockTimeModal.endLabel')}
              defaultValue={endTime}
              singleDayPeriodPickerOption={dayPeriodEndTime}
            />
            <ErrorMessageTypography $hasErrorMessage={errorMessage !== ''}>
              {errorMessage}
            </ErrorMessageTypography>
          </StyledBlockTimeSectionContainer>
          <StyledBlockTimeSectionContainer>
            <Typography variant='h5' style={{ marginBottom: theme.spacing(3) }}>
              {t('appointments.blockTimeModal.summaryLabel')}
            </Typography>
            <Typography>{summary}</Typography>
            <Typography>{t('appointments.blockTimeModal.noteLabel')}</Typography>
          </StyledBlockTimeSectionContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={onHandleBlockTime}
          loading={isLoading}
          color='primary'
          variant='contained'
          id='block-time-modal-apply-button'
        >
          {t('appointments.blockTimeModal.applyLabel')}
        </LoadingButton>
        <Button
          onClick={onCloseDialog}
          color='primary'
          variant='outlined'
          id='block-time-modal-cancel-button'
        >
          {t('appointments.blockTimeModal.cancelLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
