import { IPriceByPharmacy } from '../../../model/price-by-pharmacy';
import { TelemetryEvent } from '../../../providers/telemetry/telemetry.context';
import { TelemetryService } from '../../../providers/telemetry/telemetry.service';
import {
  IDrugSearchApiConfig,
  IDrugSearchAuthenticationConfig,
} from '../../../state/drug-search/drug-search.state';
import {
  getPricesByPharmacies,
  IPriceSearchBody,
} from '../get-prices-by-pharmacies/get-prices-by-pharmacies';

export async function getPriceList(
  drugSearchApiConfig: IDrugSearchApiConfig,
  authentication: IDrugSearchAuthenticationConfig,
  body: IPriceSearchBody,
  telemetryService: TelemetryService<TelemetryEvent>
): Promise<IPriceByPharmacy[]> {
  const pricesByPharamciesResponse = await getPricesByPharmacies(
    drugSearchApiConfig,
    authentication.subscriptionKey,
    body,
    telemetryService
  );

  return body.providerIds.map((npi: string) => {
    const pharmacy = pricesByPharamciesResponse.find((value) => value.providerId === npi);

    if (pharmacy) {
      return {
        providerId: pharmacy.providerId,
        priceListId: pharmacy.price.id,
        totalCost: pharmacy.price.rebatedTotalCost.toFixed(2).toString(),
      } as IPriceByPharmacy;
    }

    return {
      providerId: npi,
      priceListId: null,
      totalCost: null,
    } as IPriceByPharmacy;
  });
}
