// Copyright 2020 Prescryptive Health, Inc.

import { HttpStatusCodesEnum } from '../../http-status-codes';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { call } from '../../call';
import { ApiErrors } from '../../errors/api-errors';
import DateFormatter from '../../../formatters/dates/date.formatter';
import { IAppointmentsGetResponse } from './appointments-get.response';
import { IAppointmentsGetResultStrategy } from './result-strategies/appointments-get.result-strategy';
import { DateTime } from 'luxon';
import { NonFatalError } from '../../errors/non-fatal.error';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export type ReportType = 'all' | 'vaccine';

export async function appointmentsGet<TResult>(
  apiConfig: IPharmacyPortalApiConfig,
  resultStrategy: IAppointmentsGetResultStrategy<TResult>,
  authToken: string,
  locationId: string,
  startDate: Date,
  reportType: ReportType = 'all',
  endDate?: Date
): Promise<TResult> {
  const incrementedEndDate = endDate
    ? DateTime.fromJSDate(endDate).plus({ days: 1 }).toJSDate()
    : null;

  const url = buildUrlForAppointmentType(
    apiConfig,
    locationId,
    startDate,
    reportType,
    incrementedEndDate
  );

  const headers = buildBearerAuthHeaders(authToken);

  const response: Response = await call(url, undefined, 'GET', headers);
  if (response.ok) {
    return await appointmentsResultFromResponse<TResult>(response, resultStrategy);
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.APPOINTMENT_GET,
      PharmacyPortalErrorType.API_ERROR,

      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  if (response.status === HttpStatusCodesEnum.PAYLOAD_TOO_LARGE) {
    const nonFatalError = new NonFatalError();
    nonFatalError.message = ApiErrors.reportTooLarge();
    nonFatalError.name = 'Report Error';
    throw nonFatalError;
  }

  if (response.status === HttpStatusCodesEnum.REQUEST_TIMEOUT) {
    const nonFatalError = new NonFatalError();
    nonFatalError.message = ApiErrors.reportTimeout();
    nonFatalError.name = 'Report Error';
    throw nonFatalError;
  }

  throw new PharmacyPortalError(
    ApiErrors.appointmentsGet(response.statusText),
    ApiErrorCode.APPOINTMENT_GET,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}

function buildUrlForAppointmentType(
  apiConfig: IPharmacyPortalApiConfig,
  locationId: string,
  startDate: Date,
  reportType: ReportType,
  endDate?: Date | null
): string {
  const formattedStartDate = DateFormatter.formatToYMD(startDate);

  const formattedEndDate = endDate
    ? { ':endDate': DateFormatter.formatToYMD(endDate) }
    : { ':endDate': '' };

  return reportType === 'all'
    ? buildUrl(apiConfig, 'appointments', {
        ':locationId': locationId,
        ':startDate': formattedStartDate,
        ...formattedEndDate,
      })
    : buildUrl(apiConfig, 'appointmentsOfType', {
        ':locationId': locationId,
        ':startDate': formattedStartDate,
        ...formattedEndDate,
        ':type': reportType,
      });
}

async function appointmentsResultFromResponse<TResult>(
  response: Response,
  resultStrategy: IAppointmentsGetResultStrategy<TResult>
): Promise<TResult> {
  const responseData = (await response.json()) as IAppointmentsGetResponse;
  if (!responseData) {
    throw new PharmacyPortalError(
      ApiErrors.appointmentsGet(ApiErrors.unexpectedResponseDataFormat),
      ApiErrorCode.APPOINTMENT_GET,
      PharmacyPortalErrorType.API_ERROR
    );
  }

  return resultStrategy.strategy(responseData, resultStrategy.contentProvider);
}
