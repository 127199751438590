// Copyright 2021 Prescryptive Health, Inc.

import { Box, Typography, useTheme } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { ReactElement } from 'react';
import { TitleDescriptionBox } from '../styled-components/title-description/title-description-containers.styled-components';
import { ProviderProgramHowItWorksDynamicZone, ProviderServiceHowItWorksDynamicZone } from '../../../../../model/strapi/strapi-models';
import { SectionHeading } from '../../../../strapi/section-heading/section-heading';
import { TitleDescription } from '../../../../strapi/title-description/title-description';

interface IHowItWorksProps {
  howItWorksData:
    | Maybe<ProviderServiceHowItWorksDynamicZone | ProviderProgramHowItWorksDynamicZone>[]
    | null
    | undefined;
}

export const HowItWorks = ({
  howItWorksData,
}: IHowItWorksProps): ReactElement => {
  const theme = useTheme();

  const howItWorksSection = howItWorksData?.map((elem) => {
    switch (elem?.__typename) {
      case 'ComponentProviderServiceContentTypesSectionHeading': {
        return <SectionHeading key={elem.id} headingText={elem.HeadingText} />;
      }
      case 'ComponentProviderServiceContentTypesShortText': {
        return (
          <Typography key={elem.id}>{elem.shortText}</Typography>
        );
      }
      case 'ComponentProviderServiceContentTypesLongText': {
        return (<Typography key={elem.id}>{elem.longText}</Typography>);
      }

      default:
        return null;
    }
  });

  const titleDescriptions = howItWorksData?.map((elem) => {
    switch (elem?.__typename) {
      case 'ComponentProviderServiceContentTypesTitleDescription': {
        return (
          <TitleDescription
            key={elem.id}
            title={elem.Title}
            description={elem.Description}
            url={elem.TitleDescriptionUrl}
          />
        );
      }

      default:
        return null;
    }
  });

  return (
    <Box paddingBottom={theme.spacing(4)}>
      {howItWorksSection}
      <TitleDescriptionBox>{titleDescriptions}</TitleDescriptionBox>
    </Box>
  );
};
