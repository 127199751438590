import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { LoadingSpinner } from '../../../../../../loading/loading-spinner';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';

export type ConfirmPasswordRestModalProps = {
  open: boolean;
  setOpen: (arg: boolean) => void;
  onContinue: () => void;
  passwordResetLink: string;
  setPasswordResetLink: (arg: string) => void;
  getPasswordResetLinkLoading: boolean;
  setGetPasswordError: (arg: boolean) => void;
  getPasswordError: boolean;
};

export const ConfirmPasswordResetDialog = (props: ConfirmPasswordRestModalProps) => {
  const {
    open,
    setOpen,
    onContinue,
    setPasswordResetLink,
    passwordResetLink,
    getPasswordResetLinkLoading,
    getPasswordError,
    setGetPasswordError,
  } = props;

  const closeDialog = () => {
    setPasswordResetLink('');
    setGetPasswordError(false);
    setOpen(false);
  };

  const { t } = useTranslation();

  if (getPasswordResetLinkLoading) {
    return (
      <Dialog open={open} maxWidth='lg'>
        <DialogContent>
          <Box display='flex' justifyContent='center' alignItems='center' flex={1}>
            <LoadingSpinner />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  if (getPasswordError) {
    return (
      <Dialog open={open} maxWidth='lg'>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Typography>{t('settings.userManagementTab.getPasswordError')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='text' color='primary' onClick={closeDialog}>
            {t('settings.userManagementTab.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} maxWidth='lg'>
      {passwordResetLink === '' ? (
        <>
          <DialogTitle>{t('settings.userManagementTab.areYouSure')}</DialogTitle>
          <DialogActions>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button variant='text' color='primary' onClick={() => onContinue()}>
              {t('settings.userManagementTab.continue')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>reset link:</DialogTitle>
          <DialogContent>
            <Typography>
              {passwordResetLink}
              <Button
                onClick={() => navigator.clipboard.writeText(passwordResetLink)}
                title={t('settings.userManagementTab.toolTip')}
                sx={{ width: 20, minWidth: 20, padding: 0, marginLeft: 20 }}>
                <ContentCopyIcon />
              </Button>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant='text' color='primary' onClick={closeDialog}>
              {t('settings.userManagementTab.ok')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
