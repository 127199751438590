// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

export interface IInlineEditButtonBarProps {
  isEditMode?: boolean;
  isEditDisabled?: boolean;
  onEditPress: () => void;
  onCancelPress: () => void;
  onSavePress: () => void;
  isSaveDisabled?: boolean;
}

export const InlineEditButtonBar = ({
  isEditMode,
  isEditDisabled,
  onEditPress,
  onCancelPress,
  onSavePress,
  isSaveDisabled,
}: IInlineEditButtonBarProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();
  const editButton = isEditDisabled ? (
    <Tooltip title={t('inlineEdit.inlineEditButtonBar.editButtonTooltip')}>
      <Box>
        <Button
          variant='text'
          onClick={onEditPress}
          disabled={isEditDisabled}
          startIcon={<EditIcon />}
          color='primary'
          key={uuidv4()}
        >
          {t('inlineEdit.inlineEditButtonBar.editButtonLabel')}
        </Button>
      </Box>
    </Tooltip>
  ) : (
    <Button
      variant='text'
      onClick={onEditPress}
      disabled={isEditDisabled}
      startIcon={<EditIcon />}
      color='primary'
      key={uuidv4()}
    >
      {t('inlineEdit.inlineEditButtonBar.editButtonLabel')}
    </Button>
  );

  return isEditMode ? (
    <Box display='flex' gap={theme.spacing(4)}>
      <Button
        variant='text'
        onClick={onSavePress}
        disabled={isSaveDisabled}
        startIcon={<CheckCircleIcon />}
        color='primary'
        key={uuidv4()}
      >
        {t('inlineEdit.inlineEditButtonBar.saveButtonLabel')}
      </Button>
      <Button
        variant='text'
        onClick={onCancelPress}
        startIcon={<CloseIcon />}
        color='primary'
        key={uuidv4()}
      >
        {t('inlineEdit.inlineEditButtonBar.cancelButtonLabel')}
      </Button>
    </Box>
  ) : (
    <Box>{editButton}</Box>
  );
};
