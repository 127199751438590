// Copyright 2020 Prescryptive Health, Inc.

import { IErrorState } from './error.state';
import { ErrorAction } from './actions/error.action';
import { Reducer } from 'react';

export type ErrorReducer = Reducer<IErrorState, ErrorAction>;

export const errorReducer: ErrorReducer = (
  state: IErrorState,
  action: ErrorAction
): IErrorState => {
  const payload = action.payload;

  return {
    ...state,
    ...payload,
  };
};
