// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import { PostAuthPrimaryScreenContainer } from '../../screen-containers/post-auth-primary/post-auth-primary.screen-container';
import { useProviderContext } from '../../../providers/provider/use-provider-context.hook';
import {
  ProviderInformationTabPanel,
  IProviderInformationTabPanelProps,
} from './sections/tab-panels/provider-information/provider-information.tab-panel';
import { ITabBarItem, TabBar } from '../../tabs/tab-bar/tab-bar';
import {
  UserManagementTabPanel,
  IUserManagementTabPanelProps,
} from './sections/tab-panels/user-management/user-management.tab-panel';
import { StyledSettingsScreenContainer } from './settings.screen.styled-components';
import { useTranslation } from 'react-i18next';

export const SettingsScreen = (): ReactElement => {
  const { t } = useTranslation();

  document.title = t('settings.settingsScreen.pageTitle');

  const {
    providerState: { currentProvider, locations },
  } = useProviderContext();

  if (!currentProvider) {
    return (
      <PostAuthPrimaryScreenContainer>
        <Typography variant='h1'>{t('settings.settingsScreen.title')}</Typography>
      </PostAuthPrimaryScreenContainer>
    );
  }

  const tabItems: ITabBarItem[] = [
    {
      key: 'providerInformation',
      title: t('settings.settingsScreen.tabStoreInformationTitle'),
      panel: ProviderInformationTabPanel,
      panelProps: {
        currentProvider,
        locations,
      } as IProviderInformationTabPanelProps,
    },
    {
      key: 'userManagement',
      title: t('settings.settingsScreen.tabUserManagementTitle'),
      panel: UserManagementTabPanel,
      panelProps: {
        providerName: currentProvider.name,
      } as IUserManagementTabPanelProps,
    },
  ];

  return (
    <PostAuthPrimaryScreenContainer style={{ padding: 0 }}>
      <StyledSettingsScreenContainer>
        <Typography variant='h1'>{t('settings.settingsScreen.title')}</Typography>
        <TabBar key={currentProvider?.name} items={tabItems} />
      </StyledSettingsScreenContainer>
    </PostAuthPrimaryScreenContainer>
  );
};
