import { Box, Typography, useTheme, Link } from '@mui/material';
import React, { ReactElement } from 'react';

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

export interface ContentBoxProps {
  title: string;
  body: string;
  link: string;
  linkTo: string;
}

export const NoAppointmentContentBox = (props: ContentBoxProps): ReactElement => {
  const theme = useTheme();
  const { title, body, link, linkTo } = props;

  return (
    <Box display='flex' flexDirection='column' gap={theme.spacing(5)}>
      <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
      <Typography>{body}</Typography>
      <Box>
        <Link
          href={linkTo}
          style={{
            color: 'primary.main',
            textDecoration: 'none',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          {link}
          <ArrowForwardRoundedIcon
            fontSize='medium'
            sx={{ verticalAlign: 'text-top', paddingLeft: 1 }}
          />
        </Link>
      </Box>
    </Box>
  );
};
