// Copyright 2021 Prescryptive Health, Inc.

import {
  Box,
  FormControl,
  TextField,
  DialogActions,
  MenuItem,
  Select,
  FormHelperText,
  InputLabel,
  SelectProps,
  TextFieldProps,
  useTheme,
  DialogContent,
  Typography,
  styled,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { PhoneMaskInput } from '../../../../../../inputs/mask-inputs/phone-mask-input/phone-mask-input';

const StyledTextField = (props: TextFieldProps): ReactElement => {
  const theme = useTheme();
  return (
    <TextField
      {...props}
      variant='filled'
      FormHelperTextProps={{
        style: {
          color: theme.palette.grey[400],
        },
        variant: 'standard',
      }}
    />
  );
};

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(6),
  '& > *:not(:last-child)': { marginBottom: theme.spacing(6) },
}));

export const StyledWholeRowTextField = styled(StyledTextField)(() => ({
  display: 'flex',
}));

export const StyledInGroupTextField = styled(StyledTextField)({
  width: '47%',
});

export const StyledPhoneNumberField = styled(PhoneMaskInput)({
  width: '47%',
});

export const StyledGroupBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const StyledUserNameContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'baseline',
}));

export const StyledEmailDomainText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(4),
  color: theme.palette.grey[900],
}));

const StyledModifyUserFormControl = styled(FormControl)(() => ({
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '47%',
}));

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.grey[400],
}));

type IStyledSelectProps = {
  label: string;
  selectLabelId: string;
  helperText: string;
  options: string[];
  selectedValue: string;
  error?: boolean;
} & Pick<SelectProps, 'onChange'>;

export const StyledSelectForm = ({
  label,
  selectLabelId,
  helperText,
  options,
  selectedValue,
  onChange,
  error,
}: IStyledSelectProps): ReactElement => {
  const renderMenuItems = options.map((item, index) => (
    <MenuItem key={index} value={item}>
      {item}
    </MenuItem>
  ));
  return (
    <StyledModifyUserFormControl variant='filled'>
      <InputLabel error={error} htmlFor={selectLabelId}>
        {label}
      </InputLabel>
      <Select labelId={selectLabelId} value={selectedValue} onChange={onChange} error={error}>
        {renderMenuItems}
      </Select>
      <StyledFormHelperText error={error} variant='standard'>
        {helperText}
      </StyledFormHelperText>
    </StyledModifyUserFormControl>
  );
};

export const ModifyUserDialogErrorTypography = styled(Typography)({
  textAlign: 'unset',
});
