// Copyright 2020 Prescryptive Health, Inc.

import { createContext } from 'react';
import {
  IAppointmentsState,
  defaultAppointmentsState,
} from '../../state/appointments/appointments.state';
import { AppointmentsDispatch } from '../../state/appointments/dispatch/appointments.dispatch';

export interface IAppointmentsContext {
  readonly appointmentsState: IAppointmentsState;
  readonly appointmentsDispatch: AppointmentsDispatch;
}

export const AppointmentsContext = createContext<IAppointmentsContext>({
  appointmentsState: defaultAppointmentsState,
  appointmentsDispatch: () => {
    return;
  },
});
