import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const LoginWithAuth0Modal = (): ReactElement => {
  const { t } = useTranslation();
  const { user, logout } = useAuth0();

  const userAuth0Email = user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}/email`];

  const isLinkedAADUser =
    user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}/connectionStrategy`] === 'waad' &&
    user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}/identitiesCount`] > 1;

  const [isOpen, setIsOpen] = useState(isLinkedAADUser);

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{t('switchToAuth0Modal.title')}</DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={4}>
          <Typography>
            {t('switchToAuth0Modal.content', {
              auth0Email: userAuth0Email,
            })}
          </Typography>
          <Typography>
            {t('switchToAuth0Modal.pleaseContact')}{' '}
            <Link href='mailto:pharmacyrelations@prescryptive.com'>
              {t('switchToAuth0Modal.supportEmail')}
            </Link>{' '}
            {t('switchToAuth0Modal.wrongEmailText', { auth0Email: userAuth0Email })}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpen(false)}>{t('switchToAuth0Modal.skipButton')}</Button>
        <Button
          data-testid='continue-btn'
          variant='contained'
          onClick={() => logout({ returnTo: window.location.origin, federated: true })}
        >
          {t('switchToAuth0Modal.continueButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
