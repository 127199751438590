// Copyright 2021 Prescryptive Health, Inc.

import { AuthenticationProvider } from '../../authentication/authentication-provider';
import { sessionLoggedInAction } from '../actions/session-logged-in.action';
import { SessionDispatch } from './session.dispatch';

export const sessionLoggedInDispatch = (
  dispatch: SessionDispatch,
  authenticationProvider: AuthenticationProvider
): void => {
  dispatch(sessionLoggedInAction(authenticationProvider));
};
