// Copyright 2021 Prescryptive Health, Inc.

function toCsvSafeString(s: string): string {
  if (s.indexOf(',') === -1) {
    return s;
  }

  const escaped = s.indexOf('"') === -1 ? s : s.replace(/"/g, '\\"');
  return `"${escaped}"`;
}

export { toCsvSafeString };
