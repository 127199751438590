// Copyright 2021 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IDeleteServiceQuestionConfirmationModalProps {
  questionText: string;
  isOpen?: boolean;
  onKeepPress: () => void;
  onConfirmedPress: () => void;
}

export const DeleteServiceQuestionConfirmationModal = ({
  questionText,
  isOpen,
  onKeepPress,
  onConfirmedPress,
}: IDeleteServiceQuestionConfirmationModalProps): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Dialog
      onClose={onKeepPress}
      aria-labelledby='remove-question-dialog'
      open={!!isOpen}
      maxWidth='md'
      fullWidth={true}
    >
      <DialogTitle id='remove-question-dialog'>
        <Typography style={{ fontWeight: 'bold' }}>
          {t('services.deleteServiceQuestionModal.confirmationText')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column'>
          <Typography component='div'>
            <Typography style={{ fontWeight: 'bold' }}>
              {t('services.deleteServiceQuestionModal.questionText')}
            </Typography>
          </Typography>
          <Typography component='div'>
            <Box padding={theme.spacing(4)}>{questionText}</Box>
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirmedPress} color='primary' variant='contained'>
          {t('services.deleteServiceQuestionModal.confirmationButtonLabel')}
        </Button>
        <Button onClick={onKeepPress} color='secondary' variant='contained'>
          {t('services.deleteServiceQuestionModal.keepButtonLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
