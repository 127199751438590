// Copyright 2021 Prescryptive Health, Inc.

import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { call } from '../../call';
import { ApiErrorCode } from '../../errors/api-error-code';

import { ApiErrors } from '../../errors/api-errors';
import { HttpStatusCodesEnum } from '../../http-status-codes';

export async function providerLocationDelete(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  locationId: string
): Promise<void> {
  const url = buildUrl(apiConfig, 'locationDelete', {
    ':locationIdentifier': locationId,
  });
  const headers = buildBearerAuthHeaders(authToken);

  const response: Response = await call(url, undefined, 'DELETE', headers);
  if (response.ok) {
    return;
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.PROVIDER_LOCATION_DELETE,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  throw new PharmacyPortalError(
    ApiErrors.locationDelete(locationId, response.statusText),
    ApiErrorCode.PROVIDER_LOCATION_DELETE,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}
