// Copyright 2021 Prescryptive Health, Inc.

import { HttpStatusCodesEnum } from '../../http-status-codes';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { call } from '../../call';
import { ApiErrors } from '../../errors/api-errors';
import { IClaimOption, IService } from '../../../model/service';
import { IServicesClaimOptionResponse, IServicesResponse } from './services.response';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export interface IServicesGetResult {
  services: IService[];
}

export async function servicesGet(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string
): Promise<IServicesGetResult> {
  const url = buildUrl(apiConfig, 'servicesGet', {});
  const headers = buildBearerAuthHeaders(authToken);

  const response: Response = await call(url, undefined, 'GET', headers);
  if (response.ok) {
    if (response.status === HttpStatusCodesEnum.NO_CONTENT) {
      return { services: [] };
    }

    return await resultFromResponse(response);
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.SERVICES_GET,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  throw new PharmacyPortalError(
    ApiErrors.servicesGet(response.statusText),
    ApiErrorCode.SERVICES_GET,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}

async function resultFromResponse(response: Response): Promise<IServicesGetResult> {
  const responseData = (await response.json()) as IServicesResponse[];
  if (!responseData) {
    throw new PharmacyPortalError(
      ApiErrors.servicesGet(ApiErrors.unexpectedResponseDataFormat),
      ApiErrorCode.SERVICES_GET,
      PharmacyPortalErrorType.API_ERROR,
      response.status
    );
  }

  const result: IServicesGetResult = {
    services: [],
  };

  result.services = responseData.map((responseService) => {
    const { serviceType: responseServiceType } = responseService;
    if (!responseServiceType) {
      throw new PharmacyPortalError(
        ApiErrors.servicesGet(ApiErrors.unexpectedResponseDataFormat),
        ApiErrorCode.SERVICES_GET,
        PharmacyPortalErrorType.API_ERROR,
        response.status
      );
    }

    const service: IService = {
      serviceType: responseServiceType,
      name: responseService.serviceName ?? '',
      canUseDynamicPrice: responseService.canUseDynamicPrice,
      claimOptions: responseClaimOptionsMap(responseService.claimOptions),
      administrationMethod: responseService.administrationMethod ?? null,
      testType: responseService.testType ?? null,
    };
    return service;
  });

  return result;
}

function responseClaimOptionsMap(
  responseClaimOptions: IServicesClaimOptionResponse[]
): IClaimOption[] {
  return responseClaimOptions.map((responseClaimOption) => {
    const claimOption: IClaimOption = {
      claimId: responseClaimOption.claimOptionId,
      icd10Code: responseClaimOption.icd10Code
        ? {
            code: responseClaimOption.icd10Code.code,
            result: responseClaimOption.icd10Code.valueMyRx,
            description: responseClaimOption.icd10Code.descriptionMyRx,
          }
        : null,
    };
    return claimOption;
  });
}
