// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { NavItem, NavItemIcon } from './post-auth-drawer.styled-components';
import { ListItemButton, ListItemText, Typography } from '@mui/material';
import { useMatch, Link } from 'react-router-dom';

interface INavLinkProps {
  route: string;
  onClick: () => void;
  Icon: JSX.Element;
  linkText: string;
}

export const NavLink = ({ route, onClick, Icon, linkText }: INavLinkProps): ReactElement => {
  const match = useMatch(route);

  return (
    <NavItem
      // The component={Link} is ts ignored because typescript is just wrong when using this with syled components
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={Link}
      to={route}
      onClick={onClick}
      $isActive={!!match?.pathname}
      data-test='nav-item'
      disablePadding
    >
      <ListItemButton>
        <NavItemIcon $isActive={!!match?.pathname} data-test='nav-item-icon'>
          {Icon}
        </NavItemIcon>
        <ListItemText
          disableTypography={true}
          primary={
            <Typography variant='h3' component='span'>
              {linkText}
            </Typography>
          }
        />
      </ListItemButton>
    </NavItem>
  );
};
