import { gql } from '@apollo/client';

export const USERS_QUERY = gql`
  query Users {
    users(searchText: $searchText)
      @rest(type: "users", path: "/api/v1/provider/users?searchText={args.searchText}") {
      id
      firstName
      lastName
      fullName
      phoneNumber
      contactEmail
      email
      npi
    }
  }
`;
