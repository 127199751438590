// Copyright 2020 Prescryptive Health, Inc.

import React, { FunctionComponent, useReducer, useEffect } from 'react';
import { ProviderContext } from './provider.context';
import { providerReducer, ProviderReducer } from '../../state/provider/provider.reducer';
import { defaultProviderState } from '../../state/provider/provider.state';
import { useErrorContext } from '../../hooks/use-error-context/use-error-context.hook';
import { useConfigContext } from '../../hooks/use-config-context/use-config-context.hook';
import { useTelemetryContext } from '../../providers/telemetry/use-telemetry-context.hook';
import { useSessionContext } from '../../providers/session/use-session-context.hook';
import { usePostAuthContext } from '../post-auth/use-post-auth-context.hook';
import { currentProviderSetDispatch } from '../../state/provider/dispatch/current-provider-set.dispatch';
import {
  IProviderDetailsGetAsyncActionArgs,
  providerDetailsGetAsyncAction,
} from '../../state/provider/async-actions/provider-details-get.async-action';
import { useBusyContext } from '../../hooks/use-busy-context/use-busy-context.hook';
import { localStorageKeys } from '../../constants/local-storage-keys';
import { getToken } from '../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const ProviderContextProvider: FunctionComponent = ({ children }) => {
  const { errorDispatch } = useErrorContext();
  const { configState } = useConfigContext();
  const { busyDispatch } = useBusyContext();
  const { telemetryService } = useTelemetryContext();
  const { sessionState } = useSessionContext();
  const {
    authState: { userAccess },
  } = usePostAuthContext();

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const [providerState, providerDispatch] = useReducer<ProviderReducer>(
    providerReducer,
    defaultProviderState
  );

  const providerId = providerState.currentProvider?.name;

  useEffect(() => {
    if (!userAccess) {
      return;
    }
    if (userAccess.rules.length === 1) {
      currentProviderSetDispatch(providerDispatch, userAccess.rules[0]);
      localStorage.setItem(localStorageKeys.currentProvider, userAccess.rules[0].name);
      return;
    }

    const storedProvider = localStorage.getItem(localStorageKeys.currentProvider);
    const providerRule = userAccess.rules.find((r) => r.name === storedProvider);
    if (providerRule) {
      currentProviderSetDispatch(providerDispatch, providerRule);
    }
  }, [userAccess]);

  useEffect(() => {
    if (!providerId) {
      return;
    }

    const providerDetailsGet = async () => {
      const actionProps: IProviderDetailsGetAsyncActionArgs = {
        busyDispatch,
        configState,
        errorDispatch,
        authProvider: sessionState.authProvider,
        providerDispatch,
        telemetryService,
        providerId,
        getAuthToken: getToken(auth0Migration, getAccessTokenSilently)
      };
      await providerDetailsGetAsyncAction(actionProps);
    };

    void providerDetailsGet();
  }, [providerId]);

  useEffect(() => {
    if (window.heap) {
      window.heap.addUserProperties({ Provider: providerId });
    }
  }, [providerId]);

  return (
    <ProviderContext.Provider
      value={{
        providerState,
        providerDispatch,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};
