// Copyright 2021 Prescryptive Health, Inc.

import { Toolbar as MuiToolbar, Chip } from '@mui/material';
import { styled } from '@mui/material';

export const Toolbar = styled(MuiToolbar)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const ActiveChip = styled(Chip)(({ theme }) => ({
  color: 'white',
  backgroundColor: theme.palette.success.main,
}));
export const InactiveChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.grey['300'],
}));
