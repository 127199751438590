// Copyright 2020 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import { PhoneNumberFormatter } from '../../../../../formatters/phone-number/phone-number.formatter';
import { Box, Typography, useTheme } from '@mui/material';
import { LabelDetail } from '../../label-detail';
import { IAppointment } from '../../appointment-details.screen.query';
import { useTranslation } from 'react-i18next';

export interface IPerformingFacilitySectionProps {
  appointment?: IAppointment;
  clia?: string;
}

export const PerformingFacilitySection = ({
  appointment,
  clia,
}: IPerformingFacilitySectionProps): ReactElement => {
  const { t } = useTranslation();

  const {
    name = '',
    address: { line1 = '', city = '', state = '', zip: zipCode = '' } = {},
    phone = '',
  } = appointment?.location || {};

  const phoneNumber = PhoneNumberFormatter.formatForUI(phone) ?? '';
  const theme = useTheme();
  return (
    <Box
      component='section'
      display='grid'
      gap={theme.spacing(4)}
      className={appointment?.procedure?.results ? '' : 'page-break'}
    >
      <Typography variant='h2' component='h2'>
        {t('appointmentDetails.performingFacility.sectionHeading')}
      </Typography>
      <Box display='flex' flexWrap='wrap' gap={theme.spacing(2)}>
        <Box flex='1' display='flex' flexDirection='column' gap={theme.spacing(2)}>
          <LabelDetail label={t('appointmentDetails.performingFacility.nameLabel')} detail={name} />
          <LabelDetail
            label={t('appointmentDetails.performingFacility.phoneNumberLabel')}
            detail={phoneNumber}
          />
          <LabelDetail
            label={t('appointmentDetails.performingFacility.cliaLabel')}
            detail={clia ?? ''}
          />
        </Box>
        <Box flex='1' display='flex' flexDirection='column' gap={theme.spacing(2)}>
          <LabelDetail
            label={t('appointmentDetails.performingFacility.addressLabel')}
            detail={line1}
          />
          <LabelDetail label={t('appointmentDetails.performingFacility.cityLabel')} detail={city} />
          <LabelDetail
            label={t('appointmentDetails.performingFacility.stateLabel')}
            detail={state}
          />
          <LabelDetail
            label={t('appointmentDetails.performingFacility.zipCodeLabel')}
            detail={zipCode}
          />
        </Box>
      </Box>
    </Box>
  );
};
