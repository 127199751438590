import { gql } from '@apollo/client';

export const RECORD_PROCEDURE_MUTATION = gql`
  mutation recordProcedure($id: ID, $procedureResults: [ProcedureResultInput]) {
    recordProcedure(id: $id, procedureResults: $procedureResults) {
      id
      procedure {
        results {
          procedureResultId
          text
          answerId
          answerText
          type
        }
      }
    }
  }
`;

export interface IRecordProcedureVariables {
  id: string;
  procedureResults: IProcedureResultInput[];
}

export interface IProcedureResultInput {
  procedureResultId: string;
  text: string;
  answerId: string;
  answerText: string;
}
