// Copyright 2020 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import dateFormatter from '../../../../../formatters/dates/date.formatter';
import { serviceNameFromType } from '../../../../../selectors/service-name';
import { IPharmacyService } from '../../../../../model/pharmacy-service';
import { Box, useTheme } from '@mui/material';
import { LabelDetail } from '../../label-detail';
import { IAppointment } from '../../appointment-details.screen.query';
import { isoDateTimeStringToDate } from '../../../../../helpers/dates/date-helper/date.helper';
import { useTranslation } from 'react-i18next';

export interface ISummarySectionProps {
  appointment?: IAppointment;
  services: IPharmacyService[];
}

export const SummarySection = ({ appointment, services }: ISummarySectionProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();
  const appointmentDate = isoDateTimeStringToDate(appointment?.startDateISO ?? '');

  const formattedAppointmentDate = appointment
    ? dateFormatter.formatToLongDate(appointmentDate)
    : '';
  const formattedAppointmentTime = appointment
    ? dateFormatter.formatToTimeRange(appointmentDate, appointment.duration)
    : '';

  const orderNumber = appointment?.orderNumber ?? '';

  const serviceName = serviceNameFromType(appointment?.service.serviceType ?? '', services);

  return (
    <Box
      bgcolor={theme.palette.primary.light}
      padding={theme.spacing(10)}
      display='flex'
      flexWrap='wrap'
      gap={theme.spacing(2)}
      className='print-section'
    >
      <Box display='flex' flexDirection='column' flex='1' gap={theme.spacing(2)}>
        <LabelDetail
          label={t('appointmentDetails.summarySection.dateLabel')}
          detail={formattedAppointmentDate}
        />
        <LabelDetail
          label={t('appointmentDetails.summarySection.timeLabel')}
          detail={formattedAppointmentTime}
        />
      </Box>
      <Box display='flex' flexDirection='column' flex='1' gap={theme.spacing(2)}>
        <LabelDetail
          label={t('appointmentDetails.summarySection.serviceNameLabel')}
          detail={serviceName}
        />
        <LabelDetail
          label={t('appointmentDetails.summarySection.orderNumberLabel')}
          detail={orderNumber}
        />
      </Box>
    </Box>
  );
};
