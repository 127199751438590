import { styled, Card, Button } from '@mui/material';

export const StyledReportsCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.grey.A100,
  marginTop: theme.spacing(4),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
  borderRadius: theme.shape.borderRadius,
  boxShadow: 'none',
  border: 'none',
  '&:hover': {
    cursor: 'pointer',
  },
}));

export const PricingReportButtons = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  whiteSpace: 'nowrap',
}));
