// Copyright 2022 Prescryptive Health, Inc.

import { LogoutOptions } from '@auth0/auth0-react';
import { localStorageKeys } from '../constants/local-storage-keys';
import { AuthenticationProvider } from '../state/authentication/authentication-provider';

export type LogoutHandlerFunc = (event: Event) => void;

export const onLogoutEventHandler = (
  logout: (options?: LogoutOptions) => void,
  authProvider?: AuthenticationProvider,
  auth0Migration?: boolean
): LogoutHandlerFunc => {
  const handler: LogoutHandlerFunc = (event: Event) => {
    if (!authProvider && !auth0Migration) {
      return;
    }

    const storageEvent = event as StorageEvent;
    if (
      storageEvent &&
      storageEvent.key === localStorageKeys.isLogout &&
      storageEvent.newValue === 'true'
    ) {
      auth0Migration
        ? logout({ returnTo: window.location.origin, federated: true })
        : authProvider?.logout();
      localStorage.removeItem(localStorageKeys.isLogout);
    }
  };
  return handler;
};
