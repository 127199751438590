import { Close } from '@mui/icons-material';
import { Alert, Collapse, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { authenticatedRouteConfig } from '../../navigation/authenticated-route-config';

export const Banner = (): ReactElement => {
  const [isOpen, setIsOpen] = useState(true);

  const { auth0Migration } = useFlags();

  const { t } = useTranslation();

  const display = isOpen ? '' : 'none';

  return (
    <Box sx={{ mx: 9, mt: 6, display }}>
      <Collapse in={isOpen}>
        <Alert
          severity='info'
          action={
            <IconButton
              color='inherit'
              size='small'
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <Close />
            </IconButton>
          }
        >
          {auth0Migration ? t('banner.flaggedContent') : t('banner.content')}{' '}
          <Link to={authenticatedRouteConfig.learnMore.createUrl()}>{t('banner.learnMore')}</Link>
        </Alert>
      </Collapse>
    </Box>
  );
};
