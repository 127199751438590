// Copyright 2021 Prescryptive Health, Inc.

import { LocationAvailabilityMap } from '../../../model/location-availability';
import { locationAvailabilitySetAction } from '../actions/location-availability-set.action';
import { LocationAvailabilityDispatch } from './location-availability.dispatch';

export const locationAvailabilitySetDispatch = (
  dispatch: LocationAvailabilityDispatch,
  serviceHoursLocationMap: LocationAvailabilityMap
): void => {
  dispatch(locationAvailabilitySetAction(serviceHoursLocationMap));
};
