// Copyright 2021 Prescryptive Health, Inc.

import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { ApiErrors } from '../../errors/api-errors';
import { IProviderLocationAddRequest } from './provider-location-add.request';
import { IStoreLocation } from '../../../model/store-location';
import { IStoreLocationsResponse } from '../provider-details-get/provider-details-get.response';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { HttpStatusCodesEnum } from '../../http-status-codes';
import { call } from '../../call';
import { IErrorResponse } from '../../error-response';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export async function providerLocationAdd(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  addProviderLocationRequest: IProviderLocationAddRequest
): Promise<IStoreLocation> {
  const url = buildUrl(apiConfig, 'locationAdd', {});
  const headers = buildBearerAuthHeaders(authToken);

  const response: Response = await call(url, addProviderLocationRequest, 'POST', headers);

  if (response.ok) {
    return await resultFromResponse(response);
  }
  if (response.status === HttpStatusCodesEnum.BAD_REQUEST) {
    const errorResponse = (await response.json()) as IErrorResponse;
    errorResponse.status = response.status;
    throw errorResponse;
  }
  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.PROVIDER_LOCATION_ADD,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }
  throw new PharmacyPortalError(
    ApiErrors.locationAdd(response.statusText),
    ApiErrorCode.PROVIDER_LOCATION_ADD,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}

async function resultFromResponse(response: Response): Promise<IStoreLocation> {
  const responseLocation = (await response.json()) as IStoreLocationsResponse;
  if (!responseLocation) {
    throw new Error(ApiErrors.locationAdd(ApiErrors.unexpectedResponseDataFormat));
  }
  const location: IStoreLocation = {
    id: responseLocation.identifier ?? '',
    name: responseLocation.locationName ?? '',
    timezone: responseLocation.timezone ?? '',
    lanes: responseLocation.lanes ?? [],
    isTest: responseLocation.isTest ?? true,
    phoneNumber: responseLocation.phoneNumber ?? '',
    address: {
      line1: responseLocation.address ?? '',
      city: responseLocation.city ?? '',
      state: responseLocation.state ?? '',
      zip: responseLocation.zip ?? '',
    },
  };

  return location;
}
