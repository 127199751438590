// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { useCurrentContent } from '../../../hooks/use-current-content/use-current-content.hook';
import { IToggleSelectorItem, ToggleSelector } from '../toggle-selector';
import { weekdayToggleSelectorContent } from './weekday.toggle-selector.content';

interface IWeekdayToggleSelectorProps {
  selectedDays?: boolean[];
  onSelect: (isWeekdaySelected: boolean[]) => void;
}

export const WeekdayToggleSelector = ({
  selectedDays = [],
  onSelect,
}: IWeekdayToggleSelectorProps): ReactElement => {
  const { content } = useCurrentContent(weekdayToggleSelectorContent);

  const items: IToggleSelectorItem[] = content.weekdays.map(
    (weekdayLabel, index) => {
      const item: IToggleSelectorItem = {
        label: weekdayLabel,
        isSelected: selectedDays[index],
      };
      return item;
    }
  );

  return <ToggleSelector onChange={onSelect} items={items} />;
};
