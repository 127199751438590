// Copyright 2021 Prescryptive Health, Inc.

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material';

export const StyledSettingsScreenContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  [theme.breakpoints.up('desktop')]: {
    padding: theme.spacing(12),
  },
}));

export const StyledLocationCardsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit,minmax(333px,1fr))',
  gap: theme.spacing(3),
  marginTop: theme.spacing(4),
  overflow: 'auto',
}));

export const StyledProviderInformationLocationDescriptionTextTypography = styled(Typography)(
  ({ theme }) => ({
    paddingTop: theme.spacing(4),
  })
);

export const StyledProviderInformationLocationTitleTypography = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(4),
}));
