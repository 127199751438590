// Copyright 2020 Prescryptive Health, Inc.

import { createContext } from 'react';
import { defaultPostAuthState, IPostAuthState } from '../../state/post-auth/post-auth.state';
import { PostAuthDispatch } from '../../state/post-auth/dispatch/post-auth.dispatch';
import { IAuthContext } from '../auth/auth.context';

export type IPostAuthContext = IAuthContext<IPostAuthState, PostAuthDispatch>;

export const PostAuthContext = createContext<IPostAuthContext>({
  authState: defaultPostAuthState,
  authDispatch: () => ({}),
});
