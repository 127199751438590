// Copyright 2020 Prescryptive Health, Inc.

import { createContext } from 'react';
import {
  INotificationState,
  defaultNotificationState,
} from '../../state/notification/notification.state';
import { NotificationDispatch } from '../../state/notification/dispatch/notification.dispatch';

export interface INotificationContext {
  readonly notificationState: INotificationState;
  readonly notificationDispatch: NotificationDispatch;
}

export const NotificationContext = createContext<INotificationContext>({
  notificationState: defaultNotificationState,
  notificationDispatch: () => {
    return;
  },
});
