// Copyright 2021 Prescryptive Health, Inc.

import { providerDetailsUpdateAction } from '../actions/provider-details-update.action';
import { ProviderDispatch } from './provider.dispatch';

export const providerDetailsUpdateDispatch = (
  dispatch: ProviderDispatch,
  newProviderName: string,
  contactPhoneNumber: string,
  contactEmail: string,
  cliaNumber: string
): void => {
  dispatch(
    providerDetailsUpdateAction(
      newProviderName,
      contactPhoneNumber,
      contactEmail,
      cliaNumber
    )
  );
};
