// Copyright 2021 Prescryptive Health, Inc.

import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { logApiError } from '../../../api/errors/log-api-error';
import { providerUsersUpdate } from '../../../api/v1/provider-users-update/provider-users-update';
import { ProviderUser } from '../../../model/provider-user';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { providerUsersUpdateAction } from '../actions/provider-users-update.action';
import { ProviderDispatch } from '../dispatch/provider.dispatch';

export interface IProviderUsersUpdateAsyncActionArgs extends IApiAsyncActionArgs {
  providerDispatch: ProviderDispatch;
  providerName: string;
  providerUser: ProviderUser;
}

export const providerUsersUpdateAsyncAction = async (
  args: IProviderUsersUpdateAsyncActionArgs
): Promise<void> => {
  const {
    configState,
    authProvider,
    providerDispatch,
    providerName,
    providerUser,
    telemetryService,
    getAuthToken,
  } = args;

  try {
    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    telemetryService.trackEvent('provider-users-update', { providerName });

    await providerUsersUpdate(
      configState.apis.pharmacyPortal,
      accessToken,
      providerName,
      providerUser.id,
      providerUser
    );

    providerDispatch(providerUsersUpdateAction(providerName, providerUser));
  } catch (error) {
    logApiError(telemetryService, error);
    throw error;
  }
};
