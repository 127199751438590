// Copyright 2021 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import { usePostAuthScreenContext } from '../../../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { useLocationContext } from '../../../../../providers/location/use-location-context.hook';
import {
  ILocationServiceUpdateAsyncActionArgs,
  locationServiceUpdateAsyncAction,
} from '../../../../../state/location/async-actions/location-service-update.async-action';
import { notificationSetDispatch } from '../../../../../state/notification/dispatch/notification-set.dispatch';
import { useNotificationContext } from '../../../../../hooks/use-notification-context/use-notification-context.hook';
import { ServiceStatus } from '../../../../../model/pharmacy-service';
import { EditTextSection } from '../edit-text/edit-text.section';
import { useTranslation } from 'react-i18next';
import { getToken } from '../../../../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface IAdditionalInformationSectionProps {
  additionalInformation: string;
  serviceType: string;
  status: ServiceStatus;
}

export const AdditionalInformationSection = ({
  additionalInformation,
  serviceType,
  status,
}: IAdditionalInformationSectionProps): ReactElement => {
  const { t } = useTranslation();
  const { authProvider, busyDispatch, configState, errorDispatch, telemetryService } =
    usePostAuthScreenContext({ defaultContent: {} });

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const {
    locationDispatch,
    locationState: { location, services },
  } = useLocationContext();

  const service = services.find((s) => s.serviceType === serviceType);
  const serviceDuration = service?.durationMinutes ?? 0;
  const serviceMinLeadHours = service?.minLeadHours ?? 0;

  const { notificationDispatch } = useNotificationContext();

  const onSavePress = async (updatedConfirmationAdditionalInfo: string): Promise<void> => {
    if (!location) {
      return;
    }

    const args: ILocationServiceUpdateAsyncActionArgs = {
      authProvider,
      busyDispatch,
      configState,
      errorDispatch,
      locationDispatch,
      telemetryService,
      locationId: location.id,
      serviceType,
      durationMinutes: serviceDuration,
      status,
      quantityAdjustment: 0,
      confirmationAdditionalInfo: updatedConfirmationAdditionalInfo,
      minLeadHours: serviceMinLeadHours,
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
    };

    try {
      await locationServiceUpdateAsyncAction(args);

      notificationSetDispatch(
        notificationDispatch,
        t('services.additionalInformation.successNotificationMessage')
      );
      return;
    } catch {
      return;
    }
  };

  return (
    <EditTextSection
      textToEdit={additionalInformation}
      sectionHeading={t('services.additionalInformation.heading')}
      sectionDescription={t('services.additionalInformation.description')}
      sectionAdditionalDescription={t('services.additionalInformation.additionalDescription')}
      altImageText={t('services.additionalInformation.altImageText')}
      imageSrc={process.env.PUBLIC_URL + '/media/additional-information.2fd8ee7d.png'}
      onSave={onSavePress}
    />
  );
};
