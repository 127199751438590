// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { formatFullName } from '../../../../../formatters/user-formatter/user-formatter';
import { PhoneNumberFormatter } from '../../../../../formatters/phone-number/phone-number.formatter';
import { IWaitlistItem } from '../../../../../model/waitlist-item';
import { IPharmacyService } from '../../../../../model/pharmacy-service';
import { serviceNameFromType } from '../../../../../selectors/service-name';
import dateFormatter from '../../../../../formatters/dates/date.formatter';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IRemoveModalProps {
  waitlistItem?: IWaitlistItem;
  services: IPharmacyService[];
  isOpen?: boolean;
  onKeepPress: () => void;
  onConfirmedPress: (waitlistItem: IWaitlistItem) => Promise<void>;
}

export const RemoveModal = ({
  waitlistItem,
  services,
  isOpen = false,
  onKeepPress,
  onConfirmedPress,
}: IRemoveModalProps): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation();

  const birthDate = waitlistItem?.birthDate;
  const firstName = waitlistItem?.firstName;
  const lastName = waitlistItem?.lastName;
  const phoneNumber = waitlistItem?.phoneNumber;
  const serviceType = waitlistItem?.serviceType;

  const summaryRows = [
    {
      label: t('waitlist.waitlistRemoveModal.nameLabel'),
      formattedValue: formatFullName(firstName, lastName),
    },
    {
      label: t('waitlist.waitlistRemoveModal.birthDateLabel'),
      formattedValue: birthDate ? dateFormatter.formatToMDY(birthDate) : '',
    },
    {
      label: t('waitlist.waitlistRemoveModal.phoneNumberLabel'),
      formattedValue: PhoneNumberFormatter.formatForUI(phoneNumber),
    },
    {
      label: t('waitlist.waitlistRemoveModal.serviceNameLabel'),
      formattedValue: serviceType ? serviceNameFromType(serviceType, services) : '',
    },
  ];

  const onPress = async () => {
    if (!waitlistItem) {
      return;
    }

    await onConfirmedPress(waitlistItem);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onKeepPress}
      id='remove-waitlist-item-modal'
      aria-labelledby='remove-waitlist-item-modal-title'
    >
      <DialogTitle id='remove-waitlist-item-modal-title'>
        {t('waitlist.waitlistRemoveModal.title')}
      </DialogTitle>
      <DialogContent>
        <Box display='grid' gap={theme.spacing(8)}>
          <Box id='remove-waitlist-item-modal-subtitle'>
            <Typography>{t('waitlist.waitlistRemoveModal.subtitle')}</Typography>
          </Box>
          <Box display='flex' flexDirection='column'>
            <Box marginBottom={theme.spacing(4)}>
              <Typography variant='h3'>
                {t('waitlist.waitlistRemoveModal.summaryHeading')}
              </Typography>
            </Box>
            <Box display='flex' flexDirection='column' flex={1}>
              {summaryRows.map((summaryRow) => (
                <Box key={summaryRow.label} display='flex' flexDirection='row' flexWrap='wrap'>
                  <Box flex={1}>
                    <Typography>{summaryRow.label}</Typography>
                  </Box>
                  <Box flex={1}>
                    <Typography style={{ fontWeight: 'bold' }}>
                      {summaryRow.formattedValue}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Typography style={{ fontWeight: 'bold' }}>
            {t('waitlist.waitlistRemoveModal.confirmationText')}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onPress} color='primary' variant='contained'>
          {t('waitlist.waitlistRemoveModal.confirmedButtonLabel')}
        </Button>
        <Button onClick={onKeepPress} color='primary' variant='outlined'>
          {t('waitlist.waitlistRemoveModal.keepButtonLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
