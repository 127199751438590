import { NetworkStatus, useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import initPharmacyPortalServiceGraphqlClient from '../../../../../init-pharmacy-portal-service-graphql-client';
import Error from '../../../../error/error';
import {
  GET_REJECTION_REPORTS_QUERY,
  IGetRejectionReportsResponse,
} from './reports-rejection-table.query';
import { ReportPagination } from '../../reports.screen';

interface IReportsRejectionTableProps {
  locationId: string;
  startDate: DateTime;
  endDate: DateTime;
  paginationState: ReportPagination;
  onPaginationStateChange: (currentPage: number, nextCursor?: string, totalCount?: number) => void;
}
const DEFAULT_PAGE_SIZE = 50;

export const ReportsRejectionTable = ({
  locationId,
  startDate,
  endDate,
  paginationState,
  onPaginationStateChange,
}: IReportsRejectionTableProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [nextCursor, setNextCursor] = useState<string | undefined>();

  const { data, loading, error, networkStatus, refetch } = useQuery<IGetRejectionReportsResponse>(
    GET_REJECTION_REPORTS_QUERY,
    {
      variables: {
        locationId,
        reportType: 'rejection',
        startDate: startDate.toFormat('MM-dd-yyyy'),
        endDate: endDate.toFormat('MM-dd-yyyy'),
        pageSize: DEFAULT_PAGE_SIZE,
        next: nextCursor,
      },
      client: initPharmacyPortalServiceGraphqlClient,
    }
  );

  const isLoading = loading || networkStatus === NetworkStatus.refetch;
  const hasError = !!error || (!isLoading && !data?.reports);
  const retry = async () => {
    await refetch();
  };

  useEffect(() => {
    if (data) {
      onPaginationStateChange(currentPage, data.reports.next, data.reports.totalCount);
    }
  }, [data]);

  useEffect(() => {
    setCurrentPage(0);
    setNextCursor(undefined);
  }, [startDate, endDate]);

  const getPreviousPageCursor = (newPage: number) => {
    return paginationState.pages[newPage - 1]?.nextCursor;
  };

  const getNextPageCursor = () => {
    return paginationState.pages[currentPage]?.nextCursor;
  };
  const setCurrentPageAndNextCursor = (newPage: number) => {
    const cursor = currentPage > newPage ? getPreviousPageCursor(newPage) : getNextPageCursor();
    setNextCursor(cursor);
    setCurrentPage(newPage);
  };

  const rejectionClaimColumns: GridColumns = [
    {
      field: 'pharmacyName',
      headerName: t('reports.reportsScreen.columns.pharmacyName'),
      minWidth: 200,
      sortable: true,
    },
    {
      field: 'claimStatus',
      headerName: t('reports.reportsScreen.columns.claimStatus'),
      minWidth: 100,
    },
    {
      field: 'serviceDate',
      headerName: t('reports.reportsScreen.columns.serviceDate'),
      minWidth: 150,
    },
    { field: 'rxNumber', headerName: t('reports.reportsScreen.columns.rxNumber'), minWidth: 150 },
    { field: 'ndc', headerName: t('reports.reportsScreen.columns.ndc'), minWidth: 150 },
    { field: 'drugName', headerName: t('reports.reportsScreen.columns.drugName'), minWidth: 200 },
    { field: 'strength', headerName: t('reports.reportsScreen.columns.strength') },
    { field: 'quantity', headerName: t('reports.reportsScreen.columns.quantity') },
    {
      field: 'rejectCode',
      headerName: t('reports.reportsScreen.columns.rejectCode'),
    },
    {
      field: 'drugTier',
      headerName: t('reports.reportsScreen.columns.tier'),
    },
    {
      field: 'rejectReason',
      headerName: t('reports.reportsScreen.columns.rejectReason'),
      minWidth: 200,
    },
  ];

  return (
    <Box
      height='calc(85vh - 100px)'
      marginTop={theme.spacing(5)}
      data-testid='reports-rejection-table'
      id='reports-rejection-table'
      sx={{
        '& .MuiTablePagination-displayedRows': {
          display: 'none',
        },
        '& .MuiIconButton-root:nth-child(2)': {
          pointerEvents: data?.reports?.next ? 'auto' : 'none',
          color: data?.reports?.next ? 'inherit !important' : 'grey !important',
        },
      }}>
      {hasError ? (
        <Error onRetry={retry} />
      ) : (
        <DataGridPro
          columnBuffer={11}
          rows={data?.reports?.claimReports ?? []}
          loading={isLoading}
          columns={rejectionClaimColumns}
          getRowId={(row) => row.id}
          //TODO rework this solution when DataGridPro supports pagination through an unknown amount of rows
          rowCount={9999999999}
          rowsPerPageOptions={[]}
          pagination
          paginationMode='server'
          pageSize={DEFAULT_PAGE_SIZE}
          page={currentPage}
          initialState={{
            pagination: {
              pageSize: DEFAULT_PAGE_SIZE,
              page: 0,
            },
          }}
          onPageChange={(newPage: number) => setCurrentPageAndNextCursor(newPage)}
        />
      )}
    </Box>
  );
};
