// Copyright 2021 Prescryptive Health, Inc.

import { ProviderDispatch } from './provider.dispatch';
import { IStoreLocation } from '../../../model/store-location';
import { providerDetailsSetAction } from '../actions/provider-details-set.action';
import { IProvider } from '../../../model/provider';
import { IEnrolledServiceType } from '../../../model/enrolled-service-type';
import { IEnrolledProgram } from '../../../model/enrolled-program';

export const providerDetailsSetDispatch = (
  dispatch: ProviderDispatch,
  currentProvider: IProvider,
  locations: IStoreLocation[],
  enrolledServiceTypes: IEnrolledServiceType[],
  enrolledPrograms: IEnrolledProgram[]
): void => {
  dispatch(
    providerDetailsSetAction(
      currentProvider,
      locations,
      enrolledServiceTypes,
      enrolledPrograms
    )
  );
};
