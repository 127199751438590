// Copyright 2021 Prescryptive Health, Inc.

import { Box, Typography, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { UploadFile } from '../../../model/strapi/strapi-models';
import { buildResourceUrl } from '../helpers/build-resource-url';
import {
  IconCardMedia,
  SecondaryTextTypography,
  ValuePropBox,
  ValuePropTextBox,
} from './value-prop.styled-components';
import ENV from '../../../helpers/environment-variables';

interface IValuePropProps {
  title: string | null | undefined;
  text: string | null | undefined;
  iconData?: UploadFile | null | undefined;
  iconPosition?: 'top' | 'left';
}

export const ValueProp = ({
  title,
  text,
  iconData,
  iconPosition = 'left',
}: IValuePropProps): ReactElement => {
  const theme = useTheme();

  const direction = iconPosition === 'top' ? 'column' : 'row';
  const propTextBoxWidth = iconPosition === 'top' ? '95%' : '75%';
  const propTextBoxPadding = iconPosition === 'top' ? theme.spacing(0) : theme.spacing(4);

  const iconComponent = iconData ? (
    <Box
      width='100%'
      maxWidth={iconData?.width ?? 30}
      height={iconData?.height ?? 30}
      marginBottom={theme.spacing(5)}
      marginRight={iconData?.width && iconData?.width < 50 ? theme.spacing(5) : 0}
    >
      <IconCardMedia image={buildResourceUrl(ENV.STRAPI_HOST_URL, iconData.url)} />
    </Box>
  ) : null;

  return (
    <ValuePropBox flexDirection={direction}>
      {iconComponent}
      <ValuePropTextBox width={propTextBoxWidth} paddingLeft={propTextBoxPadding}>
        <Typography>{title}</Typography>
        <SecondaryTextTypography>{text}</SecondaryTextTypography>
      </ValuePropTextBox>
    </ValuePropBox>
  );
};
