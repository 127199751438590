// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';

export interface IToggleSelectorItem {
  label: string;
  isSelected?: boolean;
}
interface IToggleSelectorProps {
  items: IToggleSelectorItem[];
  onChange: (isSelected: boolean[]) => void;
}

export const ToggleSelector = ({
  items,
  onChange,
}: IToggleSelectorProps): ReactElement => {
  const initialState = items.map((item) => !!item.isSelected);

  const [isSelected, setIsSelected] = useState<boolean[]>(initialState);

  useEffect(() => {
    setIsSelected(initialState);
  }, [items]);

  const setIsItemSelected = (index: number) => {
    const updatedIsSelected = [...isSelected];
    updatedIsSelected[index] = !updatedIsSelected[index];
    setIsSelected(updatedIsSelected);
    onChange(updatedIsSelected);
  };

  const toggleButtons = items.map(({ label }, itemIndex) => {
    const onToggleChange = () => setIsItemSelected(itemIndex);
    const isItemSelected = isSelected[itemIndex];

    return (
      <FormControlLabel
        key={itemIndex}
        control={
          <Checkbox
            checked={isItemSelected}
            onChange={onToggleChange}
            color='primary'
          />
        }
        label={label}
      />
    );
  });

  return (
    <Box
      display='flex'
      flexDirection='column'
      flexWrap='wrap'
      justifyContent='flex-start'
      height='200px'
    >
      {toggleButtons}
    </Box>
  );
};
