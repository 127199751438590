// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import dateFormatter from '../../formatters/dates/date.formatter';
import { ICalendarAppointmentItem } from '../../model/calendar-appointment-item';
import { ICalendarBlockedTimeItem } from '../../model/calendar-blocked-time-item';
import { ICalendarEventItem } from '../../model/calendar-event-item';
import { IStoreLocation } from '../../model/store-location';
import { useLocationContext } from '../../providers/location/use-location-context.hook';
import { serviceNameFromType } from '../../selectors/service-name';
import { AppointmentEventCard } from '../appointments/appointment-event-card/appointment-event-card';
import { BlockedEventCard } from '../appointments/blocked-event-card/blocked-event-card';
import { useTelemetryContext } from '../../providers/telemetry/use-telemetry-context.hook';
import { authenticatedRouteConfig } from '../../navigation/authenticated-route-config';
import { Box, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { getNewDate } from '../../helpers/dates/get-new-date/get-new-date';
import { NoAppointmentScreen } from '../screens/noAppointments/no-appointments.screen';

export interface IAgendaDayView {
  appointments: ICalendarAppointmentItem[];
  blockedSlots: ICalendarBlockedTimeItem[];
  selectedDate: Date;
  storeLocation?: IStoreLocation;
}

export type EventCardType = 'booked' | 'blocked';

export interface ICalendarEventWithType extends ICalendarEventItem {
  type: EventCardType;
}

export const AgendaDayView = ({
  appointments,
  blockedSlots,
  selectedDate,
}: IAgendaDayView): ReactElement => {
  const { telemetryService } = useTelemetryContext();

  const theme = useTheme();

  const {
    locationState: { services },
  } = useLocationContext();

  const blockedEvents: ICalendarEventWithType[] = blockedSlots.map((slot) => {
    return {
      ...slot,
      type: 'blocked',
    };
  });

  const bookedEvents: ICalendarEventWithType[] = appointments.map((slot) => {
    return {
      ...slot,
      type: 'booked',
    };
  });

  const mixedEvents: ICalendarEventWithType[] = [...bookedEvents, ...blockedEvents];

  const hasItems = appointments.length > 0 || blockedSlots.length > 0;

  if (!hasItems) {
    return <NoAppointmentScreen />;
  }

  const GetAppointmentEventCard = (appointment: ICalendarAppointmentItem) => {
    const isRecorded =
      (appointment.procedureResults && appointment.procedureResults.length > 0) ||
      appointment.claimInformation !== null;

    const onPress = () => {
      telemetryService.trackEvent('click', {
        name: 'nav-appointment-details',
        appointmentId: appointment.appointmentId,
      });
    };

    const isPast = appointment.date < getNewDate();
    const otherProps = { isPast, isRecorded };

    return (
      <Box key={appointment.appointmentId} paddingBottom={theme.spacing(1)}>
        <Link
          to={authenticatedRouteConfig.appointmentDetails.createUrl(appointment.appointmentId)}
          style={{ textDecoration: 'none', paddingBottom: '4px' }}
        >
          <AppointmentEventCard
            firstName={appointment.firstName}
            lastName={appointment.lastName}
            serviceName={serviceNameFromType(appointment.serviceType, services)}
            onClick={onPress}
            {...otherProps}
          />
        </Link>
      </Box>
    );
  };

  const GetBlockedEventCard = (blockedEvent: ICalendarBlockedTimeItem) => {
    const onPress = () => ({});
    return (
      <Box key={blockedEvent.blockedTimeId} paddingBottom={theme.spacing(1)}>
        <Link
          to={authenticatedRouteConfig.blockedTimeDetails.createUrl(blockedEvent.blockedTimeId)}
          style={{ textDecoration: 'none' }}
        >
          <BlockedEventCard reason={blockedEvent.reason} onClick={onPress} />
        </Link>
      </Box>
    );
  };

  const allAgendaEventsSortedByTime = mixedEvents
    .filter((appt) => appt.date.getDate() === selectedDate.getDate())
    .sort((a, b) => {
      return a.date.getTime() - b.date.getTime();
    })
    .reduce((acc, appointment) => {
      const startTime = `${dateFormatter.formatDateToTime(
        appointment.date
      )} ${dateFormatter.timeZone(appointment.date)}`;

      const eventsWithSameStartTime = acc[startTime] || [];
      const updatedEventsWithSameStartTime = [...eventsWithSameStartTime, appointment];
      return {
        ...acc,
        [startTime]: updatedEventsWithSameStartTime,
      };
    }, {} as Record<string, ICalendarEventWithType[]>);

  const renderEventsForTime = (
    eventsDictionary: Record<string, ICalendarEventWithType[]>,
    time: string
  ) => {
    return (
      <Box>
        {eventsDictionary[time].map((event) => {
          if (event.type === 'booked') {
            return GetAppointmentEventCard(event as unknown as ICalendarAppointmentItem);
          }
          return GetBlockedEventCard(event as unknown as ICalendarBlockedTimeItem);
        })}
      </Box>
    );
  };

  const renderTimeSections = (eventsDictionary: Record<string, ICalendarEventWithType[]>) => {
    return Object.keys(eventsDictionary).map((startTimeUtc) => {
      return (
        <Box key={startTimeUtc} component='section' paddingBottom={theme.spacing(2)}>
          <Typography>{startTimeUtc}</Typography>
          {renderEventsForTime(eventsDictionary, startTimeUtc)}
        </Box>
      );
    });
  };

  return <Box>{renderTimeSections(allAgendaEventsSortedByTime)}</Box>;
};
