// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useState } from 'react';
import { AppBar, BoxProps, Tab, Tabs } from '@mui/material';

export interface ITabBarItem {
  key: string;
  title: string;
  panel: (
    props: BoxProps
  ) => React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>>;
  panelProps?: unknown;
}

interface ITabBarProps {
  items: (ITabBarItem | null)[];
}

export const TabBar = ({ items }: ITabBarProps): ReactElement => {
  const [value, setValue] = useState(0);

  const handleChange = (_: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar position='static' color='transparent' elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          aria-label='tab panel'
        >
          {items.map((item, index) => {
            if (item !== null) {
              return (
                <Tab
                  key={item.key}
                  label={item.title}
                  id={`tab-${item.key}-${index}`}
                  aria-controls={`tab-panel-${item.key}-${index}`}
                />
              );
            }
          })}
        </Tabs>
      </AppBar>
      {items.map((item, index) => {
        if (item !== null) {
          return (
            value === index && (
              <item.panel
                key={item.key}
                aria-labelledby={`tab-${item.key}-${index}`}
                id={`tab-${item.key}-${index}`}
                aria-controls={`tab-panel-${item.key}-${index}`}
                hidden={value !== index}
                {...(item.panelProps || {})}
              />
            )
          );
        }
      })}
    </>
  );
};
