// Copyright 2020 Prescryptive Health, Inc.

import React, { FunctionComponent, useReducer } from 'react';
import {
  NotificationReducer,
  notificationReducer,
} from '../../state/notification/notification.reducer';
import { defaultNotificationState } from '../../state/notification/notification.state';
import { NotificationContext } from './notification.context';

export const NotificationContextProvider: FunctionComponent = ({
  children,
}) => {
  const [
    notificationState,
    notificationDispatch,
  ] = useReducer<NotificationReducer>(
    notificationReducer,
    defaultNotificationState
  );
  return (
    <NotificationContext.Provider
      value={{ notificationState, notificationDispatch }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
