// Copyright 2021 Prescryptive Health, Inc.

import { ProviderUser } from '../../../model/provider-user';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { IProviderUsersAddRequest } from './provider-users-add.request';
import { ApiErrors } from '../../errors/api-errors';
import { buildBearerAuthHeaders, buildUrl } from '../../api.helper';
import { call } from '../../call';
import { HttpStatusCodesEnum } from '../../http-status-codes';
import { IErrorResponse } from '../../error-response';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export async function providerUsersAdd(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  providerName: string,
  providerUser: ProviderUser
): Promise<ProviderUser> {
  const url = buildUrl(apiConfig, 'providerUsersAdd', {
    ':providerName': providerName,
  });
  const headers = buildBearerAuthHeaders(authToken);

  const body: IProviderUsersAddRequest = {
    firstName: providerUser.firstName,
    lastName: providerUser.lastName,
    phoneNumber: providerUser.phoneNumber,
    npi: providerUser.npi,
    emailAddress: providerUser.principalName,
    contactEmail: providerUser.contactEmail,
    role: providerUser.role,
    isActive: providerUser.isActive,
  };

  const response: Response = await call(url, body, 'POST', headers);
  if (response.ok) {
    const responseData = (await response.json()) as ProviderUser;
    if (!responseData) {
      throw new PharmacyPortalError(
        ApiErrors.providerUsersAdd(providerName, ApiErrors.unexpectedResponseDataFormat),
        ApiErrorCode.PROVIDER_USERS_ADD,
        PharmacyPortalErrorType.API_ERROR,
        response.status
      );
    }
    return responseData;
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.PROVIDER_USERS_ADD,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  if (response.status === HttpStatusCodesEnum.BAD_REQUEST) {
    const errorResponse = (await response.json()) as IErrorResponse;
    errorResponse.status = response.status;
    throw errorResponse;
  }

  throw new PharmacyPortalError(
    ApiErrors.providerUsersAdd(providerName, response.statusText),
    ApiErrorCode.PROVIDER_USERS_ADD,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}
