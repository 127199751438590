// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  ProviderProgramIntroDynamicZone,
  ProviderServiceIntroDynamicZone,
} from '../../../../../model/strapi/strapi-models';
import { TitleDescription } from '../../../../strapi/title-description/title-description';
import { ValueProp } from '../../../../strapi/value-prop/value-prop';
import { SectionHeading } from '../../../../strapi/section-heading/section-heading';
import { ValuePropsBox } from '../styled-components/value-props/value-props.styled-components';

export interface IIntroProps {
  introData:
    | Maybe<ProviderServiceIntroDynamicZone | ProviderProgramIntroDynamicZone>[]
    | null
    | undefined;
}

export const Intro = ({ introData }: IIntroProps): ReactElement => {
  const theme = useTheme();

  const titleDescriptionSection = introData?.map((elem) => {
    switch (elem?.__typename) {
      case 'ComponentProviderServiceContentTypesTitleDescription': {
        return (
          <Box key={elem.id} paddingBottom={theme.spacing(6)}>
            <TitleDescription
              title={elem.Title}
              description={elem.Description}
              url={elem.TitleDescriptionUrl}
            />
          </Box>
        );
      }
      case 'ComponentProviderServiceContentTypesSectionHeading': {
        return <SectionHeading key={elem.id} headingText={elem.HeadingText} />;
      }
      case 'ComponentProviderServiceContentTypesLongText': {
        return <Typography key={elem.id}>{elem.longText}</Typography>;
      }
      case 'ComponentProviderServiceContentTypesShortText': {
        return <Typography key={elem.id}>{elem.shortText}</Typography>;
      }
      default:
        return null;
    }
  });

  const valuePropsSection = introData?.map((elem) => {
    switch (elem?.__typename) {
      case 'ComponentProviderServiceContentTypesValueProp': {
        return (
          <ValueProp
            key={elem.id}
            title={elem.valuePropTitle}
            text={elem.valuePropText}
            iconData={elem.valuePropIcon}
          />
        );
      }

      default:
        return null;
    }
  });

  return (
    <Box>
      {titleDescriptionSection}
      <ValuePropsBox>{valuePropsSection}</ValuePropsBox>
    </Box>
  );
};
