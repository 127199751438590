// Copyright 2021 Prescryptive Health, Inc.

import dateFormatter from '../../../../../formatters/dates/date.formatter';
import { toCsvSafeString } from '../../../../../helpers/csv/csv.helper';
import { isoDateStringToLocalDate } from '../../../../../helpers/dates/date-helper/date.helper';
import { phoneNumberSplit } from '../../../../../helpers/phone-numbers/phone-number.helper';
import { IAppointmentGetResponse } from '../../../appointment-get/appointment-get.response';
import { IAppointmentsGetResponse } from '../../appointments-get.response';
import { ICsvRows } from '../../../../../model/csv-rows';
import { AppointmentsGetResultStrategy } from '../appointments-get.result-strategy';

type CsvVaccinesResultStrategy = AppointmentsGetResultStrategy<ICsvRows>;

export const csvVaccinesResultStrategy: CsvVaccinesResultStrategy = (
  responseData: IAppointmentsGetResponse
) => {
  const vaccinesCsv = responseData.appointments.map(vaccinesCsvFromResponse);

  return { rows: vaccinesCsv };
};

function vaccinesCsvFromResponse(responseData: Partial<IAppointmentGetResponse>): string {
  const birthDate = responseData.dateOfBirth
    ? dateFormatter.formatToMDY(isoDateStringToLocalDate(responseData.dateOfBirth), '')
    : '';
  const [areaCode, sevenDigitPhoneNumber] = phoneNumberSplit(responseData.phoneNumber ?? '');

  const rowElements: string[] = [];

  rowElements.push(responseData.orderNumber ?? '');
  rowElements.push(toCsvSafeString(responseData.firstName || 'NO FIRST NAME'));
  rowElements.push(''); // No middle name in response
  rowElements.push(toCsvSafeString(responseData.lastName ?? ''));
  rowElements.push(''); // No suffix in response
  rowElements.push(birthDate);
  rowElements.push(''); // No mother's first name in response
  rowElements.push(''); // No mother's maiden name in response
  rowElements.push(toCsvSafeString(responseData.gender ?? ''));
  rowElements.push(toCsvSafeString(responseData.address1 ?? ''));
  rowElements.push(''); // No PO Box in response
  rowElements.push(toCsvSafeString(responseData.address2 ?? ''));
  rowElements.push(toCsvSafeString(responseData.city ?? ''));
  rowElements.push(toCsvSafeString(responseData.state ?? ''));
  rowElements.push(toCsvSafeString(responseData.zip ?? ''));
  rowElements.push(areaCode);
  rowElements.push(sevenDigitPhoneNumber);
  rowElements.push(''); // No phone type in response

  return rowElements.join(',');
}
