// Copyright 2021 Prescryptive Health, Inc.

import { Reducer } from 'react';
import { IPaymentSetting, IPharmacyService, IServiceQuestion } from '../../model/pharmacy-service';
import { LocationAction } from './actions/location.action';
import { IPharmacyServicePriceUpdateActionPayload } from './actions/pharmacy-service-price-update.action';
import { IPharmacyServicesQuestionAddActionPayload } from './actions/pharmacy-services-question-add.action';
import { IPharmacyServiceUpdateActionPayload } from './actions/pharmacy-service-update';
import { ILocationState } from './location.state';
import { IPharmacyServicePaymentUpdateActionPayload } from './actions/pharmacy-service-payment-update.action';

export type LocationReducer = Reducer<ILocationState, LocationAction>;

export const locationReducer: LocationReducer = (
  state: ILocationState,
  action: LocationAction
): ILocationState => {
  const payload = action.payload;
  switch (action.type) {
    case 'PHARMACY_SERVICE_QUESTION_ADD': {
      const otherPayload = payload as IPharmacyServicesQuestionAddActionPayload;
      return addServiceQuestion(state, otherPayload.serviceType, otherPayload.question);
    }
    case 'PHARMACY_SERVICE_QUESTION_UPDATE': {
      const otherPayload = payload as IPharmacyServicesQuestionAddActionPayload;
      return editServiceQuestion(state, otherPayload.serviceType, otherPayload.question);
    }
    case 'PHARMACY_SERVICE_PRICE_UPDATE': {
      const otherPayload = payload as IPharmacyServicePriceUpdateActionPayload;
      return editPriceInService(state, otherPayload.serviceType, otherPayload.price);
    }
    case 'PHARMACY_SERVICE_PAYMENT_UPDATE': {
      const otherPayload = payload as IPharmacyServicePaymentUpdateActionPayload;
      return editPaymentInService(
        state,
        otherPayload.serviceType,
        otherPayload.price,
        otherPayload.paymentSettings
      );
    }
    case 'PHARMACY_SERVICE_UPDATE': {
      const otherPayload = payload as IPharmacyServiceUpdateActionPayload;
      return updateIPharmacyService(state, otherPayload.updatedPharmacyService);
    }
    case 'LOCATION_SELECTED_DATE_SET': {
      const otherPayload = action.payload as Pick<ILocationState, 'locationSelectedDate'>;
      return {
        ...state,
        locationSelectedDate: otherPayload.locationSelectedDate,
      };
    }
    default:
      return { ...state, ...(payload as ILocationState) };
  }
};

const editServiceQuestion = (
  state: ILocationState,
  serviceType: string | undefined,
  question: IServiceQuestion | undefined
) => {
  if (!serviceType || !question) {
    return state;
  }
  const service = state.services.find((s) => s.serviceType === serviceType);
  if (!service) {
    return state;
  }

  const updatedService = editQuestionInService(service, question);

  const updatedServices = state.services.map((s) => {
    if (s.serviceType === updatedService.serviceType) {
      return updatedService;
    }
    return s;
  });
  return { ...state, services: updatedServices };
};

const addServiceQuestion = (
  state: ILocationState,
  serviceType: string | undefined,
  question: IServiceQuestion | undefined
) => {
  if (!serviceType || !question) {
    return state;
  }
  const service = state.services.find((s) => s.serviceType === serviceType);
  if (!service) {
    return state;
  }

  const updatedService = addQuestionToService(service, question);

  const updatedServices = state.services.map((s) => {
    if (s.serviceType === updatedService.serviceType) {
      return updatedService;
    }
    return s;
  });
  return { ...state, services: updatedServices };
};

const addQuestionToService = (service: IPharmacyService, question: IServiceQuestion) => ({
  ...service,
  additionalQuestions: [...service.additionalQuestions, question],
});

const editQuestionInService = (service: IPharmacyService, question: IServiceQuestion) => {
  const updatedAdditionalQuestions = service.additionalQuestions.map((existingQuestion) => {
    if (existingQuestion.id === question.id) {
      return { ...existingQuestion, ...question };
    }
    return existingQuestion;
  });
  return { ...service, additionalQuestions: updatedAdditionalQuestions };
};

const editPaymentInService = (
  state: ILocationState,
  serviceType: string,
  newPriceCents: number,
  newPaymentSettings: IPaymentSetting[]
) => {
  const updatedServices = state.services.map((service) => {
    if (service.serviceType === serviceType) {
      return { ...service, price: newPriceCents, paymentSettings: newPaymentSettings };
    }
    return service;
  });
  return { ...state, services: updatedServices };
};

const editPriceInService = (state: ILocationState, serviceType: string, newPriceCents: number) => {
  const updatedServices = state.services.map((service) => {
    if (service.serviceType === serviceType) {
      return { ...service, price: newPriceCents };
    }
    return service;
  });
  return { ...state, services: updatedServices };
};

const updateIPharmacyService = (
  state: ILocationState,
  updatedPharmacyService: Partial<IPharmacyService>
) => {
  const updatedServices = state.services.map((service) => {
    if (updatedPharmacyService && service.serviceType === updatedPharmacyService.serviceType) {
      const getOnlyDefinedKeys = (obj: Partial<IPharmacyService>) => {
        return Object.keys(obj).reduce((acc, key) => {
          const _acc = acc;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          if (obj[key] !== undefined) _acc[key] = obj[key];
          return _acc;
        }, {});
      };

      return { ...service, ...getOnlyDefinedKeys(updatedPharmacyService) };
    }
    return service;
  });
  return { ...state, services: updatedServices };
};
