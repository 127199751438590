import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface IPaymentTypesInformationModalProps extends DialogProps {
  onClose: () => void;
}

const StyledPaymentSettingsInfoDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
  },
}));

export const PaymentTypesInformationModal = ({
  open,
  onClose,
}: IPaymentTypesInformationModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledPaymentSettingsInfoDialog open={open} fullWidth={true} maxWidth='lg'>
      <DialogTitle sx={{ m: 0, p: theme.spacing(4) }}>
        {t('services.pricing.paymentTypesInformation.informationModalTitle')}
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
        }}
      >
        <Box display='flex' flexWrap='wrap' flexDirection='row' gap={theme.spacing(3)}>
          <Box display='flex' flexDirection='column' gap={theme.spacing(1)} flex={1}>
            <Typography variant='h6'>
              {t('services.pricing.paymentTypesInformation.commercialHealthTitle')}
            </Typography>
            <Typography variant='body1'>
              {t('services.pricing.paymentTypesInformation.commercialHealthDescription')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column' gap={theme.spacing(1)} flex={1}>
            <Typography variant='h6'>
              {t('services.pricing.paymentTypesInformation.medicaidTitle')}
            </Typography>
            <Typography variant='body1'>
              {t('services.pricing.paymentTypesInformation.medicareDescription')}
            </Typography>
          </Box>
        </Box>
        <Box display='flex' flexWrap='wrap' flexDirection='row' gap={theme.spacing(3)}>
          <Box display='flex' flexDirection='column' gap={theme.spacing(1)} flex={1}>
            <Typography variant='h6'>
              {t('services.pricing.paymentTypesInformation.medicaidTitle')}
            </Typography>
            <Typography variant='body1'>
              {t('services.pricing.paymentTypesInformation.medicaidDescription')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column' gap={theme.spacing(1)} flex={1}>
            <Typography variant='h6'>
              {t('services.pricing.paymentTypesInformation.vaTitle')}
            </Typography>
            <Typography variant='body1'>
              {t('services.pricing.paymentTypesInformation.vaDescription')}
            </Typography>
          </Box>
        </Box>
        <Box display='flex' flexWrap='wrap' flexDirection='row' gap={theme.spacing(3)}>
          <Box display='flex' flexDirection='column' gap={theme.spacing(1)} flex={1}>
            <Typography variant='h6'>
              {t('services.pricing.paymentTypesInformation.tricareTitle')}
            </Typography>
            <Typography variant='body1'>
              {t('services.pricing.paymentTypesInformation.tricareDescription')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column' gap={theme.spacing(1)} flex={1}>
            <Typography variant='h6'>
              {t('services.pricing.paymentTypesInformation.creditDebitCardTitle')}
            </Typography>
            <Typography variant='body1'>
              {t('services.pricing.paymentTypesInformation.creditDebitCardDescription')}
            </Typography>
          </Box>
        </Box>
        <Box display='flex' flexWrap='wrap' flexDirection='row' gap={theme.spacing(3)}>
          <Box display='flex' flexDirection='column' gap={theme.spacing(1)} flex={1}>
            <Typography variant='h6'>
              {t('services.pricing.paymentTypesInformation.payAtPharmacyTitle')}
            </Typography>
            <Typography variant='body1'>
              {t('services.pricing.paymentTypesInformation.payAtPharmacyDescription')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column' gap={theme.spacing(1)} flex={1}>
            <Typography variant='h6'>
              {t('services.pricing.paymentTypesInformation.noInsuranceTitle')}
            </Typography>
            <Typography variant='body1'>
              {t('services.pricing.paymentTypesInformation.noInsuranceDescription')}
            </Typography>
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' gap={theme.spacing(1)} flex={1}>
          <Typography variant='h6'>
            {t('services.pricing.paymentTypesInformation.dontAskPaymentTitle')}
          </Typography>
          <Typography variant='body1'>
            {t('services.pricing.paymentTypesInformation.dontAskPaymentDescription')}
          </Typography>
        </Box>
      </DialogContent>
    </StyledPaymentSettingsInfoDialog>
  );
};
