// Copyright 2021 Prescryptive Health, Inc.
import { IStoreLocation } from "../../../model/store-location";
import { locationUpdateAction } from "../actions/store-location-update.action";
import { ProviderDispatch } from "./provider.dispatch";

export const locationUpdateDispatch = (
  dispatch: ProviderDispatch,
  storeLocation:IStoreLocation
): void => {
  dispatch(locationUpdateAction(storeLocation));
};
