import { Box, BoxProps, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import env from '../../helpers/environment-variables';

interface ErrorProps extends BoxProps {
  message?: string;
  onRetry?: () => void;
}

export default function Error({ message, onRetry, ...boxProps }: ErrorProps) {
  const { t } = useTranslation();
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={4}
      width={1}
      height={1}
      justifyContent='center'
      alignItems='center'
      {...boxProps}
    >
      <Typography>
        {message ||
          t('error.errorModalContent.fatalErrorMessage', { supportEmail: env.SUPPORT_EMAIL })}
      </Typography>
      {onRetry && (
        <Button variant='contained' onClick={onRetry} data-testid='retry-button'>
          {t('error.retryButtonText')}
        </Button>
      )}
    </Box>
  );
}
