// Copyright 2020 Prescryptive Health, Inc.

import React, { FunctionComponent, useReducer, Reducer, useEffect } from 'react';
import { defaultPostAuthState, IPostAuthState } from '../../state/post-auth/post-auth.state';
import { PostAuthAction } from '../../state/post-auth/actions/post-auth.action';
import { postAuthReducer } from '../../state/post-auth/post-auth.reducer';
import { PostAuthContext } from './post-auth.context';
import { useErrorContext } from '../../hooks/use-error-context/use-error-context.hook';
import { useBusyContext } from '../../hooks/use-busy-context/use-busy-context.hook';
import { useConfigContext } from '../../hooks/use-config-context/use-config-context.hook';
import { useTelemetryContext } from '../../providers/telemetry/use-telemetry-context.hook';
import { useSessionContext } from '../../providers/session/use-session-context.hook';
import {
  IUserAccessGetAsyncActionArgs,
  userAccessGetAsyncAction,
} from '../../state/post-auth/async-actions/user-access-get.async-action';
import {
  IServicesGetAsyncActionArgs,
  servicesGetAsyncAction,
} from '../../state/post-auth/async-actions/services-get.async-action';
import {
  IProgramsGetAsyncActionArgs,
  programsGetAsyncAction,
} from '../../state/post-auth/async-actions/programs-get.async-action';
import { getToken } from '../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const PostAuthContextProvider: FunctionComponent = ({ children }) => {
  const { errorDispatch } = useErrorContext();
  const { configState } = useConfigContext();
  const { busyDispatch } = useBusyContext();
  const { telemetryService } = useTelemetryContext();
  const { sessionState } = useSessionContext();

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const [postAuthState, postAuthDispatch] = useReducer<Reducer<IPostAuthState, PostAuthAction>>(
    postAuthReducer,
    defaultPostAuthState
  );

  useEffect(() => {
    const userAccessGet = async () => {
      const actionArgs: IUserAccessGetAsyncActionArgs = {
        busyDispatch,
        errorDispatch,
        telemetryService,
        configState,
        postAuthDispatch,
        authProvider: sessionState.authProvider,
        getAuthToken: getToken(auth0Migration, getAccessTokenSilently)
      };
      await userAccessGetAsyncAction(actionArgs);
    };

    void userAccessGet();

    const servicesGet = async () => {
      const actionProps: IServicesGetAsyncActionArgs = {
        busyDispatch,
        configState,
        errorDispatch,
        authProvider: sessionState.authProvider,
        postAuthDispatch,
        telemetryService,
        getAuthToken: getToken(auth0Migration, getAccessTokenSilently)
      };
      await servicesGetAsyncAction(actionProps);
    };

    const programsGet = async () => {
      const actionProps: IProgramsGetAsyncActionArgs = {
        busyDispatch,
        configState,
        errorDispatch,
        authProvider: sessionState.authProvider,
        postAuthDispatch,
        telemetryService,
        getAuthToken: getToken(auth0Migration, getAccessTokenSilently)
      };
      await programsGetAsyncAction(actionProps);
    };

    void servicesGet();
    void programsGet();
  }, []);

  return (
    <PostAuthContext.Provider value={{ authState: postAuthState, authDispatch: postAuthDispatch }}>
      {children}
    </PostAuthContext.Provider>
  );
};
