// Copyright 2021 Prescryptive Health, Inc.

import { IProviderAction } from './provider.action';

export type ILocationDeleteActionPayload = {
  locationId: string;
};

export type ILocationUpdateAction = IProviderAction<
  'STORE_LOCATION_DELETE',
  ILocationDeleteActionPayload
>;

export const locationDeleteAction = (
  locationId: string
): ILocationUpdateAction => {
  return {
    type: 'STORE_LOCATION_DELETE',
    payload: { locationId },
  };
};
