// Copyright 2021 Prescryptive Health, Inc.

import { ProviderUser } from '../../../model/provider-user';
import { IProviderAction } from './provider.action';

export type IProviderUsersSetActionPayload = {
  providerName: string;
  users: ProviderUser[];
};

export type IProviderUserSetAction = IProviderAction<
  'PROVIDER_USERS_SET',
  IProviderUsersSetActionPayload
>;

export const providerUsersSetAction = (
  providerName: string,
  users: ProviderUser[]
): IProviderUserSetAction => ({
  type: 'PROVIDER_USERS_SET',
  payload: { providerName, users },
});
