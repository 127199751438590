// Copyright 2021 Prescryptive Health, Inc.
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { logApiError } from '../../../api/errors/log-api-error';
import { NonFatalError } from '../../../api/errors/non-fatal.error';
import { providerDetailsGet } from '../../../api/v1/provider-details-get/provider-details-get';
import { providerServiceSetActive } from '../../../api/v1/provider-service-set-active/provider-service-set-active';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { locationServicesAreStaleDispatch } from '../../location/dispatch/location-services-are-stale.dispatch';
import { LocationDispatch } from '../../location/dispatch/location.dispatch';
import { providerDetailsSetDispatch } from '../../provider/dispatch/provider-details-set.dispatch';
import { ProviderDispatch } from '../../provider/dispatch/provider.dispatch';

export interface IProviderServiceSetActiveAsyncActionArgs extends IApiAsyncActionArgs {
  providerDispatch: ProviderDispatch;
  locationDispatch: LocationDispatch;
  providerName: string;
  serviceType: string;
}

export const providerServiceSetActiveAsyncAction = async (
  args: IProviderServiceSetActiveAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IProviderServiceSetActiveAsyncActionArgs): Promise<void> => {
  const {
    authProvider,
    providerDispatch,
    locationDispatch,
    telemetryService,
    providerName,
    serviceType,
    configState,
    getAuthToken,
  } = args;

  try {
    telemetryService.trackEvent('provider-service-set-active', {
      providerName,
      serviceType,
    });

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    await providerServiceSetActive(
      configState.apis.pharmacyPortal,
      accessToken,
      providerName,
      serviceType
    );

    const result = await providerDetailsGet(
      configState.apis.pharmacyPortal,
      accessToken,
      providerName
    );

    const { currentProvider, locations, enrolledServiceTypes, enrolledPrograms } = result;

    providerDetailsSetDispatch(
      providerDispatch,
      currentProvider,
      locations,
      enrolledServiceTypes,
      enrolledPrograms
    );

    locationServicesAreStaleDispatch(locationDispatch);
  } catch (error) {
    if (!(error instanceof NonFatalError)) {
      fatalErrorDispatch(args.errorDispatch);
    }
    logApiError(telemetryService, error);
    return Promise.reject();
  }
};
