// Copyright 2021 Prescryptive Health, Inc.

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material';

export const StyledAdditionalQuestionsSectionDescriptionTypography = styled(Typography)(
  ({ theme }) => ({
    marginTop: theme.spacing(6),
  })
);

export const StyledAdditionalQuestionsSectionEndImageBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    display: 'none',
  },
}));

export const StyledAdditionalQuestionsSectionContentImageBox = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('desktop')]: {
    display: 'unset',
  },
}));

export const StyledAdditionalQuestionsSectionContentBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    flexGrow: 2,
    paddingRight: theme.spacing(4),
  },
}));
