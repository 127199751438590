// Copyright 2020 Prescryptive Health, Inc.

import { DateTime } from 'luxon';
import { getNewDate } from '../get-new-date/get-new-date';

const isoDateTimeStringToDate = (isoDateTimeString: string): Date => {
  return isoDateTimeString ? new Date(isoDateTimeString) : new Date(0);
};

const isoDateStringToLocalDate = (isoDateString = '', delimiter = '-'): Date => {
  if (delimiter === '-') {
    const dateParts = isoDateString.split('-');

    const year = dateParts[0] ? parseInt(dateParts[0], 10) : 0;
    const month = dateParts[1] ? parseInt(dateParts[1], 10) : 1;
    const day = dateParts[2] ? parseInt(dateParts[2], 10) : 0;

    return new Date(year, month - 1, day);
  } else {
    const dateParts = isoDateString.split(delimiter);

    const year = parseInt(dateParts[2], 10);
    const month = parseInt(dateParts[0], 10) - 1;
    const day = parseInt(dateParts[1], 10);

    return new Date(year, month, day);
  }
};

const endOfDay = (date: Date): Date => {
  return DateTime.fromJSDate(date)
    .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    .toJSDate();
};

const calculateAge = (birthDate: Date, refDate: Date = getNewDate()): number => {
  const years = refDate.getFullYear() - birthDate.getFullYear();

  const refMonth = refDate.getMonth();
  const birthMonth = birthDate.getMonth();

  if (
    refMonth < birthMonth ||
    (refMonth === birthMonth && refDate.getDate() < birthDate.getDate())
  ) {
    return years - 1;
  }

  return years;
};

const addDaysToDate = (days: number, date: Date): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

const getCountryCityTimezone = (timezone: string): string => {
  switch (timezone) {
    case 'Eastern Standard Time':
    case 'Eastern Daylight Time':
      return 'America/New_York';
    case 'Central Standard Time':
    case 'Central Daylight Time':
      return 'America/Chicago';
    case 'Mountain Standard Time':
    case 'Mountain Daylight Time':
      return 'America/Denver';
    case 'Pacific Standard Time':
    case 'Pacific Daylight Time':
      return 'America/Los_Angeles';
    case 'Alaska Standard Time':
    case 'Alaska Daylight Time':
      return 'America/Anchorage';
    case 'Hawaii–Aleutian Standard Time':
    case 'Hawaii–Aleutian Daylight Time':
      return 'Pacific/Honolulu';
    default:
      return '';
  }
};

export {
  addDaysToDate,
  isoDateStringToLocalDate,
  isoDateTimeStringToDate,
  endOfDay,
  calculateAge,
  getCountryCityTimezone,
};
