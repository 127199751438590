// Copyright 2020 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import dateFormatter from '../../../../../formatters/dates/date.formatter';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import {
  StyledCancelAppointmentModalConfirmationTypography,
  StyledCancelAppointmentModalDetailTypography,
  StyledCancelAppointmentModalLabelTypography,
} from './cancel-appointment.modal.styled-components';
import { useTranslation } from 'react-i18next';

interface ICancelAppointmentModalProps {
  durationMinutes?: number;
  firstName?: string;
  lastName?: string;
  orderNumber?: string;
  startDate?: Date;
  isOpen?: boolean;
  onKeepPress: () => void;
  onCancelPress: () => Promise<void>;
}

export const CancelAppointmentModal = ({
  durationMinutes,
  firstName,
  lastName,
  orderNumber,
  startDate,
  isOpen = false,
  onKeepPress,
  onCancelPress,
}: ICancelAppointmentModalProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  const formattedStartDate = startDate ? dateFormatter.formatToLongDate(startDate) : '';
  const formattedTimeRange =
    startDate && durationMinutes !== undefined
      ? dateFormatter.formatToTimeRange(startDate, durationMinutes)
      : '';
  const formattedDuration =
    durationMinutes !== undefined
      ? dateFormatter.formatMinutesDuration(durationMinutes, {
          oneMinute: t('timeContent.short.oneMinute'),
          minutes: t('timeContent.short.minutes'),
          oneHour: t('timeContent.short.oneHour'),
          hours: t('timeContent.short.hours'),
        })
      : '';

  const formattedOrderNumber = orderNumber ?? '';
  const formattedFirstName = firstName ?? '';
  const formattedLastName = lastName ?? '';

  const summaryRows = [
    {
      label: t('appointmentDetails.cancelAppointmentModal.dateLabel'),
      formattedValue: formattedStartDate,
    },
    {
      label: t('appointmentDetails.cancelAppointmentModal.timeLabel'),
      formattedValue: formattedTimeRange,
    },
    {
      label: t('appointmentDetails.cancelAppointmentModal.durationLabel'),
      formattedValue: formattedDuration,
    },
    {
      label: t('appointmentDetails.cancelAppointmentModal.orderNumberLabel'),
      formattedValue: formattedOrderNumber,
    },
    {
      label: t('appointmentDetails.cancelAppointmentModal.firstNameLabel'),
      formattedValue: formattedFirstName,
    },
    {
      label: t('appointmentDetails.cancelAppointmentModal.lastNameLabel'),
      formattedValue: formattedLastName,
    },
  ];

  return (
    <Dialog
      open={isOpen}
      onClose={onKeepPress}
      id='cancel-appointment-modal'
      aria-labelledby='cancel-appointment-modal-title'
      maxWidth='md'
    >
      <DialogTitle id='cancel-appointment-modal-title'>
        {t('appointmentDetails.cancelAppointmentModal.title')}
      </DialogTitle>
      <DialogContent>
        <Box display='grid' gap={theme.spacing(8)}>
          <Typography id='subtitle'>
            {t('appointmentDetails.cancelAppointmentModal.subtitle')}
          </Typography>
          <Box display='flex' flexDirection='column' flexGrow={1} flexShrink={1}>
            <Box marginBottom={theme.spacing(8)}>
              <Typography id='cancel-appointment-modal-summary-heading' variant='h3' aria-level={3}>
                {t('appointmentDetails.cancelAppointmentModal.summaryHeading')}
              </Typography>
            </Box>
            <Box display='flex' flexDirection='column' flex={1}>
              {summaryRows.map((summaryRow) => (
                <Box key={summaryRow.label} display='flex' flexDirection='row'>
                  <StyledCancelAppointmentModalLabelTypography data-heap-redact-text='true'>
                    {summaryRow.label}
                  </StyledCancelAppointmentModalLabelTypography>
                  <StyledCancelAppointmentModalDetailTypography data-heap-redact-text='true'>
                    {summaryRow.formattedValue}
                  </StyledCancelAppointmentModalDetailTypography>
                </Box>
              ))}
            </Box>
          </Box>
          <StyledCancelAppointmentModalConfirmationTypography>
            {t('appointmentDetails.cancelAppointmentModal.confirmationText')}
          </StyledCancelAppointmentModalConfirmationTypography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelPress} color='primary' variant='contained'>
          {t('appointmentDetails.cancelAppointmentModal.cancelButtonLabel')}
        </Button>
        <Button onClick={onKeepPress} color='primary' variant='outlined'>
          {t('appointmentDetails.cancelAppointmentModal.keepButtonLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
