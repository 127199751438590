// Copyright 2021 Prescryptive Health, Inc.

import React, { ChangeEvent, ReactElement, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  Link,
  SelectChangeEvent,
  Typography,
  useTheme,
  Button,
} from '@mui/material';
import {
  StyledInGroupTextField,
  StyledGroupBox,
  StyledWholeRowTextField,
  StyledDialogActions,
  StyledSelectForm,
  StyledUserNameContainer,
  StyledDialogContent,
  StyledEmailDomainText,
  StyledPhoneNumberField,
  ModifyUserDialogErrorTypography,
} from './modify-user-dialog.styled-components';
import { useState } from 'react';
import { ProviderUser } from '../../../../../../../model/provider-user';
import {
  getEmailParts,
  neitherNullNorEmpty,
} from '../../../../../../../helpers/string-helper/string-helper';
import { isValidPhoneNumber } from '../../../../../../../validators/phone-number.validator/phone-number.validator';
import { isValidEmail } from '../../../../../../../validators/email.validator/email.validator';
import { isValidNpi } from '../../../../../../../validators/npi.validator/npi.validator';
import { LoadingSpinner } from '../../../../../../loading/loading-spinner';
import { ApiResponseMessageState } from '../user-management.tab-panel';
import { useTranslation } from 'react-i18next';

export type ModifyUserDialogTypes = 'addUser' | 'updateUser';

export type ModifyUserFormState = {
  value: string;
  error?: boolean;
};

export type SubmittedUserDataForAdd = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  npi: string;
  contactEmail: string;
  userName: string;
  role: string;
  isActive: boolean;
};

export type SubmittedUserDataForUpdate = Partial<Omit<SubmittedUserDataForAdd, 'userName'>>;

export interface IModifyUserDialogProps {
  dialogTypes: ModifyUserDialogTypes;
  isOpen: boolean;
  onKeepPress: () => void;
  onConfirmedPress: (userData: SubmittedUserDataForAdd | SubmittedUserDataForUpdate) => void;
  emailDomain: string;
  errorResponse: ApiResponseMessageState;
  isLoading: boolean;
  user?: ProviderUser | undefined;
  auth0Migration?: boolean;
}
const UserRoles = {
  Admin: 'Admin',
  User: 'User',
};
const UserRoleStatuses = {
  Active: 'Active',
  Inactive: 'Inactive',
};

type dialogContentKeys = 'dialogTitle' | 'confirmButtonLabel';

export const ModifyUserDialog = ({
  dialogTypes,
  isOpen,
  onConfirmedPress,
  onKeepPress,
  emailDomain,
  user,
  errorResponse,
  isLoading,
  auth0Migration,
}: IModifyUserDialogProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();
  const defaultState: ModifyUserFormState = { value: '', error: false };

  const [firstName, setFirstName] = useState<ModifyUserFormState>(defaultState);
  const [lastName, setLastName] = useState<ModifyUserFormState>(defaultState);
  const [contactEmail, setContactEmail] = useState<ModifyUserFormState>(defaultState);
  const [contactPhone, setContactPhone] = useState<ModifyUserFormState>(defaultState);
  const [npi, setNpi] = useState<ModifyUserFormState>(defaultState);
  const [role, setRole] = useState<ModifyUserFormState>(defaultState);
  const [status, setStatus] = useState<ModifyUserFormState>(defaultState);
  const [userName, setUserName] = useState<ModifyUserFormState>(defaultState);

  const [hasBeenModified, setHasBeenModified] = useState(false);

  const onRoleChange = (event: SelectChangeEvent<unknown>) => {
    const updatedState: ModifyUserFormState = {
      value: event.target.value as string,
      error: false,
    };
    setRole(updatedState);
  };

  const onStatusChange = (event: SelectChangeEvent<unknown>) => {
    const updatedState: ModifyUserFormState = {
      value: event.target.value as string,
      error: false,
    };
    setStatus(updatedState);
  };

  const onFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedState: ModifyUserFormState = {
      value: event.target.value as string,
      error: false,
    };
    setFirstName(updatedState);
  };

  const onLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedState: ModifyUserFormState = {
      value: event.target.value as string,
      error: false,
    };
    setLastName(updatedState);
  };

  const onContactEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedState: ModifyUserFormState = {
      value: event.target.value as string,
      error: false,
    };
    setContactEmail(updatedState);
  };

  const onNpiChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedState: ModifyUserFormState = {
      value: event.target.value as string,
      error: false,
    };
    setNpi(updatedState);
  };

  const onUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedState: ModifyUserFormState = {
      value: event.target.value as string,
      error: false,
    };
    setUserName(updatedState);
  };

  const onPhoneNumberChange = (phoneNumber: string) => {
    const updatedState: ModifyUserFormState = {
      value: phoneNumber,
      error: !isValidPhoneNumber(phoneNumber),
    };
    setContactPhone(updatedState);
  };

  useEffect(() => {
    setFirstName({ ...firstName, value: user ? user.firstName : '' });
    setLastName({ ...lastName, value: user ? user.lastName : '' });
    setContactEmail({ ...contactEmail, value: user ? user.contactEmail : '' });
    setContactPhone({ ...contactPhone, value: user ? user.phoneNumber : '' });
    setNpi({ ...npi, value: user ? user.npi : '' });
    setRole({ ...role, value: user ? user.role : '' });
    setStatus({
      ...status,
      value: user ? convertBoolToUserRoleStatuses(user.isActive) : '',
    });
    setUserName({
      ...userName,
      value: user ? getEmailParts(user.principalName)[0] ?? '' : '',
    });
  }, [user]);

  useEffect(() => {
    if (user === undefined) {
      setHasBeenModified(false);
    }

    const areAllValueEqual =
      areFormValuesEqual(user?.firstName, firstName) &&
      areFormValuesEqual(user?.lastName, lastName) &&
      areFormValuesEqual(user?.phoneNumber, contactPhone) &&
      areFormValuesEqual(user?.contactEmail, contactEmail) &&
      areFormValuesEqual(user?.npi, npi) &&
      areFormValuesEqual(user?.role, role) &&
      convertBoolToUserRoleStatuses(user?.isActive) === status.value;

    setHasBeenModified(!areAllValueEqual);
  }, [user, firstName, lastName, contactPhone, contactEmail, npi, role, status]);

  const onError = (
    value: string,
    setState: React.Dispatch<React.SetStateAction<ModifyUserFormState>>
  ) => {
    const updatedState: ModifyUserFormState = { value, error: true };
    setState(updatedState);
  };

  const onConfirmButtonClick = () => {
    let isError = false;

    if (isFormStateValueNotSet(firstName)) {
      onError(firstName.value, setFirstName);
      isError = true;
    }

    if (isFormStateValueNotSet(lastName)) {
      onError(lastName.value, setLastName);
      isError = true;
    }

    if (!isValidEmail(contactEmail.value)) {
      onError(contactEmail.value, setContactEmail);
      isError = true;
    }

    if (isFormStateValueNotSet(userName) && !auth0Migration) {
      onError(userName.value, setUserName);
      isError = true;
    }

    if (!isValidPhoneNumber(contactPhone.value)) {
      onError(contactPhone.value, setContactPhone);
      isError = true;
    }
    if (npi.value && !isValidNpi(npi.value)) {
      onError(npi.value, setNpi);
      isError = true;
    }
    if (!role.value) {
      onError(role.value, setRole);
      isError = true;
    }

    if (!status.value) {
      onError(status.value, setStatus);
      isError = true;
    }

    if (isError) {
      return;
    }

    const userData = dialogTypes === 'addUser' ? getUserDataForAdd() : getUserDataForUpdate();
    if (!userData) {
      return;
    }
    onConfirmedPress(userData);
  };

  const getErrorMessage = () => {
    if (errorResponse.message.length === 0) {
      return null;
    }
    if (errorResponse.messageType && errorResponse.messageType === 'NpiError') {
      return (
        <Box display='flex' flexDirection='column' marginRight={theme.spacing(2)} maxWidth='500px'>
          <ModifyUserDialogErrorTypography color='error'>
            {t('settings.modifyUserDialog.npiErrorText')}
          </ModifyUserDialogErrorTypography>
          <ModifyUserDialogErrorTypography color='error' align='center'>
            {t('settings.modifyUserDialog.npiErrorHelpText')}
            <Link
              component='a'
              href={`mailto:${t('settings.modifyUserDialog.helpEmail')}`}
              underline='hover'
            >
              {t('settings.modifyUserDialog.npiErrorHelpEmail')}
            </Link>
          </ModifyUserDialogErrorTypography>
        </Box>
      );
    }
    return (
      <Box display='flex' flexDirection='column' marginRight={theme.spacing(2)} maxWidth='500px'>
        <Typography color='error'>{errorResponse.message}</Typography>
      </Box>
    );
  };

  const getUserDataForAdd = () =>
    ({
      firstName: firstName.value,
      lastName: lastName.value,
      phoneNumber: contactPhone.value,
      npi: npi.value,
      contactEmail: contactEmail.value,
      userName: auth0Migration ? contactEmail.value : `${userName.value}${emailDomain}`,
      role: role.value,
      isActive: status.value === UserRoleStatuses.Active,
    } as SubmittedUserDataForAdd);

  const getUserDataForUpdate = () =>
    ({
      firstName: getFormValueIfNotEquals(user?.firstName, firstName),
      lastName: getFormValueIfNotEquals(user?.lastName, lastName),
      phoneNumber: getFormValueIfNotEquals(user?.phoneNumber, contactPhone),
      npi: getFormValueIfNotEquals(user?.npi, npi),
      contactEmail: getFormValueIfNotEquals(user?.contactEmail, contactEmail),
      role: getFormValueIfNotEquals(user?.role, role),
      isActive:
        convertBoolToUserRoleStatuses(user?.isActive) !== status.value
          ? status.value === UserRoleStatuses.Active
          : undefined,
    } as SubmittedUserDataForUpdate);

  const renderFirstAndLastName = (
    <StyledGroupBox>
      <StyledInGroupTextField
        id='modify-first-name'
        label={t('settings.modifyUserDialog.firstNameDisplayText')}
        value={firstName.value}
        onChange={onFirstNameChange}
        error={firstName.error}
        data-heap-redact-text='true'
      />
      <StyledInGroupTextField
        id='modify-last-name'
        label={t('settings.modifyUserDialog.lastNameDisplayText')}
        value={lastName.value}
        onChange={onLastNameChange}
        error={lastName.error}
        data-heap-redact-text='true'
      />
    </StyledGroupBox>
  );
  const renderPhoneNumberAndContactEmail = (
    <StyledGroupBox>
      <StyledPhoneNumberField
        id='modify-phone-number'
        label={t('settings.modifyUserDialog.phoneNumberDisplayText')}
        initialValue={contactPhone.value}
        error={contactPhone.error}
        variant='filled'
        onPhoneNumberChange={onPhoneNumberChange}
        data-heap-redact-text='true'
      />
      <StyledInGroupTextField
        id='modify-contact-email'
        label={
          auth0Migration
            ? t('settings.modifyUserDialog.emailDisplayText')
            : t('settings.modifyUserDialog.contactEmailDisplayText')
        }
        value={contactEmail.value}
        onChange={onContactEmailChange}
        error={contactEmail.error}
        data-heap-redact-text='true'
      />
    </StyledGroupBox>
  );

  const renderRoleAndStatus = (
    <StyledGroupBox>
      <StyledSelectForm
        label={t('settings.modifyUserDialog.roleLabel')}
        selectLabelId={'modify-user-select-role'}
        options={[UserRoles.Admin, UserRoles.User]}
        selectedValue={role.value}
        onChange={onRoleChange}
        error={role.error}
        helperText={t('settings.modifyUserDialog.roleHelperText')}
      />
      <StyledSelectForm
        label={t('settings.modifyUserDialog.statusLabel')}
        selectLabelId={'modify-user-select-status'}
        options={[UserRoleStatuses.Active, UserRoleStatuses.Inactive]}
        selectedValue={status.value}
        onChange={onStatusChange}
        error={status.error}
        helperText={t('settings.modifyUserDialog.statusHelperText')}
      />
    </StyledGroupBox>
  );

  const renderNpi = (
    <StyledWholeRowTextField
      id='modify-npi'
      label={t('settings.modifyUserDialog.npiDisplayText')}
      value={npi.value}
      onChange={onNpiChange}
      helperText={t('settings.modifyUserDialog.npiHelperText')}
      error={npi.error}
      inputProps={{ maxLength: 10 }}
      data-heap-redact-text='true'
    />
  );

  const renderUserName = (
    <StyledUserNameContainer>
      <StyledInGroupTextField
        id='modify-user-name-text-field'
        label={t('settings.modifyUserDialog.usernameDisplayText')}
        value={userName.value}
        onChange={onUserNameChange}
        disabled={dialogTypes === 'updateUser'}
        helperText={t('settings.modifyUserDialog.usernameHelperText')}
        error={userName.error}
        data-heap-redact-text='true'
      />
      <StyledEmailDomainText variant='body2'>{emailDomain}</StyledEmailDomainText>
    </StyledUserNameContainer>
  );

  const getContentKeyValue = (key: dialogContentKeys): string => {
    const isAddUser = dialogTypes === 'addUser';
    if (key === 'dialogTitle') {
      return isAddUser
        ? t('settings.modifyUserDialog.addUserTitle')
        : t('settings.modifyUserDialog.updateUserTitle');
    }
    if (key === 'confirmButtonLabel') {
      return isAddUser
        ? t('settings.modifyUserDialog.addButtonLabel')
        : t('settings.modifyUserDialog.updateButtonLabel');
    }
    return '';
  };

  return (
    <Dialog
      onClose={onKeepPress}
      aria-labelledby='modify-user-dialog'
      open={isOpen}
      maxWidth={'xl'}
      fullWidth={false}
    >
      <DialogTitle id='modify-user-dialog-title'>{getContentKeyValue('dialogTitle')}</DialogTitle>
      <StyledDialogContent>
        {renderFirstAndLastName}
        {renderPhoneNumberAndContactEmail}
        {renderNpi}
        {renderRoleAndStatus}
        {!auth0Migration && renderUserName}
      </StyledDialogContent>
      <StyledDialogActions>
        {!isLoading ? getErrorMessage() : null}
        <Box display='flex' justifyContent='end'>
          <Button
            onClick={onConfirmButtonClick}
            color='primary'
            variant='contained'
            disabled={dialogTypes === 'updateUser' && !hasBeenModified}
          >
            {isLoading ? <LoadingSpinner /> : getContentKeyValue('confirmButtonLabel')}
          </Button>
          <Button onClick={onKeepPress} color='primary'>
            {t('settings.modifyUserDialog.cancelButtonLabel')}
          </Button>
        </Box>
      </StyledDialogActions>
    </Dialog>
  );
};

const convertBoolToUserRoleStatuses = (isActive: boolean | undefined) =>
  isActive ? UserRoleStatuses.Active : UserRoleStatuses.Inactive;

const getFormValueIfNotEquals = (value: string | undefined, formState: ModifyUserFormState) =>
  areFormValuesEqual(value, formState) ? value : formState.value;

const areFormValuesEqual = (value: string | undefined, formState: ModifyUserFormState) =>
  neitherNullNorEmpty(value) === neitherNullNorEmpty(formState.value);

const isFormStateValueNotSet = (formState: ModifyUserFormState) =>
  !neitherNullNorEmpty(formState.value);
