import { LoadingButton } from '@mui/lab';
import { Box, Card } from '@mui/material';
import { styled as muiStyled } from '@mui/material';

export const PricingBanner = muiStyled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main + '14',
  color: theme.palette.primary.dark,
  top: 0,
  position: 'relative',
  minHeight: '120px',
  display: 'flex',
  padding: theme.spacing(10),
}));

export const PricingCard = muiStyled(Card)(({ theme }) => ({
  width: '48%',
  borderColor: theme.palette.grey['100'],
}));

export const PricingHeader = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(7),
}));

export const PricingSubTitles = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(7),
}));

export const PricingButton = muiStyled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(5),
  whiteSpace: 'nowrap',
}));

export const BannerButtons = muiStyled(LoadingButton)(({ theme }) => ({
  margin: theme.spacing(1),
  whiteSpace: 'nowrap',
}));

export const BannerLabelButtonBox = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  flexDirection: 'column',
  [theme.breakpoints.up('xl')]: {
    flexDirection: 'row',
  },
}));
