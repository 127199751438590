// Copyright 2020 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { useNotificationContext } from '../../hooks/use-notification-context/use-notification-context.hook';
import { notificationClearDispatch } from '../../state/notification/dispatch/notification-clear.dispatch';
import { MessagePanel } from '../message-panel/message-panel';

export const MessagePanelContainer = (): ReactElement => {
  const {
    notificationState: { message, severity },
    notificationDispatch,
  } = useNotificationContext();

  const onFaded = () => {
    notificationClearDispatch(notificationDispatch);
  };

  if (!message) return <></>;
  return (
    <MessagePanel message={message} severity={severity} TransitionProps={{ onExited: onFaded }} />
  );
};
