// Copyright 2020 Prescryptive Health, Inc.

import { Reducer } from 'react';
import { INotificationState } from './notification.state';
import { NotificationAction } from './actions/notification.action';

export type NotificationReducer = Reducer<
  INotificationState,
  NotificationAction
>;

export const notificationReducer: NotificationReducer = (
  state: INotificationState,
  action: NotificationAction
): INotificationState => {
  const payload = action.payload;
  return { ...state, ...payload };
};
