import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Typography,
  DialogProps,
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import { IUser } from '../../../../../../../model/IUser';
import { AccessRole } from '../../../../../../../api/v1/user-access-get/user-access-get.response';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLazyQuery, useMutation } from '@apollo/client';
import { USERS_QUERY } from './users-query';
import { useProviderContext } from '../../../../../../../providers/provider/use-provider-context.hook';
import { ADD_EXISTING_USER } from './add-existing-user-mutation';
import pharmacyPortalServiceGraphQLApolloClient from '../../../../../../../init-pharmacy-portal-service-apollo-client';
import pharmacyPortalServiceGraphQLApolloClientAAD from '../../../../../../../init-pharmacy-portal-service-apollo-client-aad';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

export interface AddExistingUserDialogProps extends DialogProps {
  onCancel?: () => void;
  onSubmitSuccess?: () => void;
}

export const AddExistingUserDialog = (props: AddExistingUserDialogProps) => {
  const { t } = useTranslation();

  const { onCancel = () => undefined, onSubmitSuccess = () => undefined, ...dialogProps } = props;

  const [searchText, setSearchText] = useState('');

  const { auth0Migration } = useFlags();

  const [
    fetchUsers,
    { data: fetchUsersData, error: fetchUsersError, loading: fetchUsersIsLoading },
  ] = useLazyQuery<{ users: IUser[] }>(USERS_QUERY, {
    client: auth0Migration
      ? pharmacyPortalServiceGraphQLApolloClient
      : pharmacyPortalServiceGraphQLApolloClientAAD,
    variables: { searchText },
  });

  useEffect(() => {
    if (searchText && searchText !== selectedUser?.fullName) {
      debounce(fetchUsers, 700)();
    }
  }, [searchText]);
  const users = fetchUsersData?.users;

  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [selectedRole, setSelectedRole] = useState<AccessRole>('User' as AccessRole);
  const [selectedStatus, setSelectedStatus] = useState<boolean>(true);

  const {
    providerState: { currentProvider },
  } = useProviderContext();

  const [addExistingUser, { loading: isAddExistingUserLoading, error: addExistingUserError }] =
    useMutation(ADD_EXISTING_USER, {
      client: auth0Migration
        ? pharmacyPortalServiceGraphQLApolloClient
        : pharmacyPortalServiceGraphQLApolloClientAAD,
    });

  const onAddExistingUser = async () => {
    if (!selectedUser || !currentProvider) {
      return;
    }
    await addExistingUser({
      variables: {
        input: {
          role: selectedRole,
          isActive: selectedStatus,
        },
        providerName: encodeURIComponent(currentProvider.name),
        userId: selectedUser.id,
      },
    });
    onSubmitSuccess();
  };

  const isFormComplete =
    !!selectedUser && !!selectedRole && selectedStatus !== undefined && selectedStatus !== null;

  const filterOptions = (users: IUser[]) =>
    users.filter(
      (user: IUser) =>
        user.fullName?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
        user.email?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
    );
  return (
    <Dialog {...dialogProps} data-testid='add-existing-user-dialog'>
      <DialogTitle>{t('settings.addExistingUser.title')}</DialogTitle>
      <DialogContent>
        <Box display='grid' gap={2}>
          <Autocomplete
            options={!users ? [] : users}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('settings.addExistingUser.searchLabel')}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {fetchUsersIsLoading ? (
                        <CircularProgress
                          color='inherit'
                          size={20}
                          data-testid='add-existing-user-users-are-loading-spinner'
                        />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  inputProps: {
                    ...params.inputProps,
                    'data-testid': 'add-existing-user-search-input',
                  },
                }}
                variant='filled'
                error={!!fetchUsersError}
              />
            )}
            fullWidth
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  data-testid='add-existing-user-autocomplete-option'
                  data-value={option.id}
                >
                  <Box>
                    <Typography>{option?.fullName}</Typography>
                    <Typography variant='caption' pl={2} color='gray'>
                      {option.email}
                    </Typography>
                  </Box>
                </li>
              );
            }}
            filterOptions={filterOptions}
            getOptionLabel={(user) => user?.fullName ?? `${user?.firstName} ${user?.lastName}`}
            value={selectedUser}
            loading={fetchUsersIsLoading}
            autoComplete
            onChange={(_, newValue) => {
              setSelectedUser(newValue);
            }}
            onInputChange={(_, newInputValue) => {
              setSearchText(newInputValue);
            }}
            data-testid='add-existing-user-autocomplete'
          />
          <Box display='grid' gap={2} gridTemplateColumns={'repeat(auto-fit, minmax(200px, 1fr))'}>
            <TextField
              disabled
              label={t('settings.addExistingUser.firstNameLabel')}
              value={selectedUser?.firstName || ''}
              variant='filled'
            />
            <TextField
              disabled
              label={t('settings.addExistingUser.lastNameLabel')}
              value={selectedUser?.lastName || ''}
              variant='filled'
            />
            <TextField
              disabled
              label={t('settings.addExistingUser.phoneNumberLabel')}
              value={selectedUser?.phoneNumber || ''}
              variant='filled'
            />
            <TextField
              disabled
              label={t('settings.addExistingUser.contactEmailLabel')}
              value={selectedUser?.contactEmail || ''}
              variant='filled'
            />
          </Box>
          <TextField
            disabled
            label={t('settings.addExistingUser.npiLabel')}
            value={selectedUser?.npi || ''}
            fullWidth
            variant='filled'
          />
          <Box display='grid' gap={2} gridTemplateColumns={'repeat(auto-fit, minmax(200px, 1fr))'}>
            <FormControl fullWidth variant='filled'>
              <InputLabel id='add-existing-user-role-select-label'>
                {t('settings.addExistingUser.roleLabel')}
              </InputLabel>
              <Select
                labelId='add-existing-user-role-select-label'
                id='add-existing-user-role-select'
                value={selectedRole || ''}
                label={t('settings.addExistingUser.roleLabel')}
                onChange={(e: SelectChangeEvent) => {
                  setSelectedRole(e.target.value as AccessRole);
                }}
              >
                <MenuItem
                  value={'User' as AccessRole}
                  data-testid='add-existing-user-role-select-option-User'
                >
                  {t('settings.addExistingUser.roleUser')}
                </MenuItem>
                <MenuItem
                  value={'Admin' as AccessRole}
                  data-testid='add-existing-user-role-select-option-Admin'
                >
                  {t('settings.addExistingUser.roleAdmin')}
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant='filled'>
              <InputLabel id='add-existing-user-status-select-label'>
                {t('settings.addExistingUser.statusLabel')}
              </InputLabel>
              <Select
                labelId='add-existing-user-status-select-label'
                id='add-existing-user-status-select'
                value={selectedStatus?.toString() || ''}
                label={t('settings.addExistingUser.statusLabel')}
                onChange={(e: SelectChangeEvent) => {
                  setSelectedStatus(e.target.value === 'true');
                }}
              >
                <MenuItem value={'true'} data-testid='add-existing-user-status-select-option-true'>
                  {t('settings.addExistingUser.statusActive')}
                </MenuItem>
                <MenuItem
                  value={'false'}
                  data-testid='add-existing-user-status-select-option-false'
                >
                  {t('settings.addExistingUser.statusInactive')}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {(!!fetchUsersError || !!addExistingUserError) && (
          <Box mx={4}>
            <Typography color='error' data-testid='add-existing-user-error-message'>
              {t('settings.addExistingUser.somethingWentWrongErrorMessage')}
            </Typography>
          </Box>
        )}
        <LoadingButton
          id='add-existing-user-confirm-button'
          data-testid='add-existing-user-confirm-button'
          variant='contained'
          onClick={onAddExistingUser}
          loading={isAddExistingUserLoading && !addExistingUserError}
          disabled={!isFormComplete}
        >
          {t('settings.addExistingUser.confirmButton')}
        </LoadingButton>

        <Button onClick={onCancel} variant='outlined'>
          {t('settings.addExistingUser.cancelButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
