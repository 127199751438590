// Copyright 2021 Prescryptive Health, Inc.

import { HttpStatusCodesEnum } from '../../http-status-codes';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { call } from '../../call';
import { ApiErrors } from '../../errors/api-errors';
import { IPaymentReportResponse } from './payment-reports-get.response';
import { paymentReportsFromResponse } from './payment-reports-get.response-helper';
import { IPaymentReport } from '../../../model/payment-report';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export async function paymentReportsGet(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  locationId: string
): Promise<IPaymentReport[]> {
  const url = buildUrl(apiConfig, 'paymentReports', {
    ':locationId': locationId,
  });
  const headers = buildBearerAuthHeaders(authToken);

  const response: Response = await call(url, undefined, 'GET', headers);
  if (response.ok) {
    if (response.status === HttpStatusCodesEnum.NO_CONTENT) {
      return [];
    }

    const responseData = (await response.json()) as IPaymentReportResponse[];
    if (!responseData) {
      throw new PharmacyPortalError(
        ApiErrors.paymentReportsGet(ApiErrors.unexpectedResponseDataFormat),
        ApiErrorCode.PAYMENT_REPORTS_GET,
        PharmacyPortalErrorType.API_ERROR
      );
    }

    return paymentReportsFromResponse(responseData);
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.PAYMENT_REPORTS_GET,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  throw new PharmacyPortalError(
    ApiErrors.paymentReportsGet(response.statusText),
    ApiErrorCode.PAYMENT_REPORTS_GET,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}
