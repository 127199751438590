// Copyright 2021 Prescryptive Health, Inc.

import { IProviderAction } from './provider.action';
import { IStoreLocation } from '../../../model/store-location';
import { IProvider } from '../../../model/provider';
import { IEnrolledServiceType } from '../../../model/enrolled-service-type';
import { IEnrolledProgram } from '../../../model/enrolled-program';

export type IProviderDetailsSetAction = IProviderAction<'DETAILS_SET'>;

export const providerDetailsSetAction = (
  currentProvider: IProvider,
  locations: IStoreLocation[],
  enrolledServiceTypes: IEnrolledServiceType[],
  enrolledPrograms: IEnrolledProgram[]
): IProviderDetailsSetAction => ({
  type: 'DETAILS_SET',
  payload: {
    currentProvider,
    locations,
    enrolledServiceTypes,
    enrolledPrograms,
  },
});
