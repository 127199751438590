// Copyright 2020 Prescryptive Health, Inc.
import { ReactElement, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IWaitlistItem } from '../../../model/waitlist-item';
import { IProcedureQuestionAndAnswer } from '../../../model/procedure-question';
import { IWaitlistInvitation } from '../../../model/waitlist-invitation';
import { useNotificationContext } from '../../../hooks/use-notification-context/use-notification-context.hook';
import { useLocationContext } from '../../../providers/location/use-location-context.hook';
import { useAppointmentsContext } from '../../../providers/appointments/use-appointments-context.hook';
import { useProviderContext } from '../../../providers/provider/use-provider-context.hook';
import { IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import { localStorageKeys } from '../../../constants/local-storage-keys';
import { getNewDate } from '../../../helpers/dates/get-new-date/get-new-date';
import {
  appointmentCancelAsyncAction,
  IAppointmentCancelAsyncActionArgs,
} from '../../../state/appointment-details/async-actions/appointment-cancel.async-action';
import { serviceNameFromType } from '../../../selectors/service-name';
import {
  IScheduleSecondAppointmentAsyncActionArgs,
  scheduleSecondAppointmentAsyncAction,
} from '../../../state/appointment-details/async-actions/schedule-second-appointment.async-action';
import { notificationSetDispatch } from '../../../state/notification/dispatch/notification-set.dispatch';
import { authenticatedRouteConfig } from '../../../navigation/authenticated-route-config';
import { RecordProcedureModal } from './modals/record-procedure/record-procedure.modal';
import { CancelAppointmentModal } from './modals/cancel/cancel-appointment.modal';
import { SummarySection } from './sections/summary/summary.section';
import { PatientInfoSection } from './sections/patient-info/patient-info.section';
import { PlanInfoSection } from './sections/plan-info/plan-info.section';
import { PerformingFacilitySection } from './sections/performing-facility/performing-facility.section';
import { PatientProfileSection } from './sections/patient-profile/patient-profile.section';
import { ProcedureInfoSection } from './sections/procedure-info/procedure-info.section';
import { SecondaryScreenContainer } from '../../screen-containers/secondary/secondary.screen-container';
import { Barcode } from '../../barcode/barcode';
import { StyledAppointmentDetailsToolBar } from './appointment-details.screen.styled-components';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import CreateIcon from '@mui/icons-material/Create';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Box, Button, Divider, Tooltip, Typography, useTheme } from '@mui/material';
import { ErrorView } from '../../error-view/error-view';
import { useSessionContext } from '../../../providers/session/use-session-context.hook';
import { useBusyContext } from '../../../hooks/use-busy-context/use-busy-context.hook';
import { useConfigContext } from '../../../hooks/use-config-context/use-config-context.hook';
import { useErrorContext } from '../../../hooks/use-error-context/use-error-context.hook';
import { useTelemetryContext } from '../../../providers/telemetry/use-telemetry-context.hook';
import { AppointmentPrintOutButton } from '../../appointments/appointment-print-out/appointment-print-out.button';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  APPOINTMENT_DETAILS_QUERY,
  IAppointmentDetailsResponse,
} from './appointment-details.screen.query';
import pharmacyPortalServiceGraphQLApolloClient from '../../../init-pharmacy-portal-service-graphql-client';
import pharmacyPortalServiceGraphQLApolloClientAAD from '../../../init-pharmacy-portal-service-graphql-client-aad';
import {
  isoDateStringToLocalDate,
  isoDateTimeStringToDate,
} from '../../../helpers/dates/date-helper/date.helper';
import { useTranslation } from 'react-i18next';
import { PrintAppointmentButton } from '../../printing/print-appointment.button';
import {
  IProcedureResultInput,
  IRecordProcedureVariables,
  RECORD_PROCEDURE_MUTATION,
} from './record-procedure.mutation';
import { getToken } from '../../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const AppointmentDetailsScreen = (): ReactElement => {
  const { t } = useTranslation();
  const { appointmentId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const {
    sessionState: { authProvider },
  } = useSessionContext();

  document.title = t('appointmentDetails.appointmentDetailsScreen.pageTitle');

  const { busyDispatch } = useBusyContext();
  const { configState } = useConfigContext();
  const { errorDispatch } = useErrorContext();
  const { telemetryService } = useTelemetryContext();

  const {
    locationState: { services, location },
  } = useLocationContext();
  const {
    providerState: { currentProvider },
  } = useProviderContext();

  if (!currentProvider || !location || !appointmentId) {
    void navigate(authenticatedRouteConfig.root.createUrl());
    return <></>;
  }

  const { data, loading, error, refetch } = useQuery<IAppointmentDetailsResponse>(
    APPOINTMENT_DETAILS_QUERY,
    {
      client: auth0Migration
        ? pharmacyPortalServiceGraphQLApolloClient
        : pharmacyPortalServiceGraphQLApolloClientAAD,
      variables: { id: appointmentId, providerId: currentProvider.name },
    }
  );

  const [RecordProcedure, { loading: recordProcedureLoading }] = useMutation(
    RECORD_PROCEDURE_MUTATION,
    {
      client: auth0Migration
        ? pharmacyPortalServiceGraphQLApolloClient
        : pharmacyPortalServiceGraphQLApolloClientAAD,
    }
  );

  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false);
  const [isRecordProcedureOpen, setIsRecordProcedureOpen] = useState(false);

  const { appointmentsDispatch } = useAppointmentsContext();

  const { notificationDispatch } = useNotificationContext();

  const appointment = data?.appointment;

  const isEditingRecordedProcedure =
    appointment?.procedure.results && appointment?.procedure.results.length > 0;

  const isRecordProcedureForTests =
    appointment?.procedure.questions &&
    appointment.procedure.questions.length > 0 &&
    appointment.procedure.questions[0].procedureQuestionId === 'test-result';

  const isRecordProcedureForVaccines =
    appointment?.procedure.questions &&
    appointment.procedure.questions.length > 0 &&
    appointment.procedure.questions[0].procedureQuestionId === 'immunization';

  const canPrintTestResult = isRecordProcedureForTests && isEditingRecordedProcedure;

  const serviceType = appointment?.service.serviceType;
  const isPrintableTest = () => {
    switch (serviceType) {
      case 'abbott_antigen':
      case 'medicare_abbott_antigen':
      case 'medicaid_abbott_antigen':
      case 'abbott-antigen-30':
      case 'covid-antigen-test':
      case 'covid-antigen-binaxnow':
      case 'c19-rapid-pcr-test':
      case 'c19-antibody-test':
      case 'c19-rapid-pcr-test-state':
      case 'c19-rapid-pcr-test-medical-billing':
        return true;
      default:
        return false;
    }
  };
  const canShowPrintTestResult = isRecordProcedureForTests && isPrintableTest();

  const isRecordProcedureRegenCOV = appointment?.service.serviceType === 'regen-cov';

  useEffect(() => {
    const telemetryPayload: IPageViewTelemetry = {
      name: 'appointments-details',
      properties: {
        provider: currentProvider?.name,
      },
    };
    telemetryService.trackPageView(telemetryPayload);
  }, []);

  const orderNumber = appointment?.orderNumber ?? '';

  const serviceName = serviceNameFromType(appointment?.service.serviceType, services);

  const isClaimAlreadySubmitted = appointment?.claim !== null;

  const onCancelButtonPress = () => {
    setIsCancelConfirmationOpen(true);
  };

  const onRecordProcedurePress = () => {
    setIsRecordProcedureOpen(true);
  };

  const onCloseRecordProcedurePress = () => {
    setIsRecordProcedureOpen(false);
  };

  const onKeepPress = () => {
    setIsCancelConfirmationOpen(false);
  };

  const onCancelConfirmedPress = async () => {
    if (!appointmentId) {
      return;
    }
    const args: IAppointmentCancelAsyncActionArgs = {
      appointmentsDispatch,
      authProvider,
      appointmentId,
      busyDispatch,
      configState,
      errorDispatch,
      telemetryService,
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
    };

    const onSuccess = () => {
      notificationSetDispatch(
        notificationDispatch,
        t('appointmentDetails.appointmentDetailsScreen.cancelSuccessMessage')
      );
      void navigate(authenticatedRouteConfig.appointments.createUrl());
    };
    const onFailureReturn = () => true;

    await appointmentCancelAsyncAction(args).then(onSuccess, onFailureReturn);
  };

  const onSaveRecordProcedure = async (
    procedureResults: IProcedureQuestionAndAnswer[],
    isSecondDoseScheduled = false,
    appointmentStartDate = getNewDate(),
    appointmentEndDate = getNewDate()
  ) => {
    if (!appointmentId) {
      return;
    }
    const trimmedProcedureResults: IProcedureResultInput[] = procedureResults.map((result) => {
      return {
        procedureResultId: result.questionId,
        text: result.question,
        answerId: result.answerId ?? '',
        answerText: result.answer.trim(),
      };
    });

    try {
      await RecordProcedure({
        variables: {
          id: appointmentId,
          procedureResults: trimmedProcedureResults,
        } as IRecordProcedureVariables,
      });
      setIsRecordProcedureOpen(false);
      notificationSetDispatch(
        notificationDispatch,
        t('appointmentDetails.appointmentDetailsScreen.recordProcedureSuccessMessage')
      );
      refetch();
    } catch (error) {
      notificationSetDispatch(
        notificationDispatch,
        t('appointmentDetails.appointmentDetailsScreen.recordProcedureErrorMessage'),
        'error'
      );
      return;
    }

    const locationId = location?.id;

    if (isSecondDoseScheduled && locationId) {
      const phoneNumber = !appointment?.patient.phoneNumber
        ? ''
        : appointment?.patient.phoneNumber.includes('+1')
        ? appointment?.patient.phoneNumber.slice(2)
        : appointment?.patient.phoneNumber;

      const waitlistItem: IWaitlistItem = {
        id: uuidv4(),
        firstName: appointment?.patient.firstName || '',
        lastName: appointment?.patient.lastName || '',
        birthDate: isoDateStringToLocalDate(appointment?.patient.dateOfBirth) || new Date(),
        serviceType: 'c19-vaccine-dose2',
        phoneNumber,
        isGlobalWaitlist: false,
      };

      const invitationItem: IWaitlistInvitation = {
        waitlistItemId: waitlistItem.id,
        startDate: appointmentStartDate,
        endDate: appointmentEndDate,
      };

      const scheduleArgs: IScheduleSecondAppointmentAsyncActionArgs = {
        locationId,
        waitlistItem,
        invitationItem,
        authProvider,
        busyDispatch,
        configState,
        errorDispatch,
        telemetryService,
        getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
      };

      try {
        // TODO: This will eventually need to be switched to graphql mutation
        await scheduleSecondAppointmentAsyncAction(scheduleArgs);
        notificationSetDispatch(
          notificationDispatch,
          t('appointmentDetails.appointmentDetailsScreen.inviteSuccessMessage')
        );
      } catch (error) {
        notificationSetDispatch(
          notificationDispatch,
          t('appointmentDetails.appointmentDetailsScreen.inviteErrorMessage'),
          'error'
        );
        return;
      }
    }

    const providerNpiQuestion = procedureResults.find(
      (question) => question.questionId === 'provider-npi'
    );

    if (providerNpiQuestion) {
      localStorage.setItem(localStorageKeys.sessionNpi, providerNpiQuestion.answer);
    }

    const lotNumberQuestion = procedureResults.find(
      (question) => question.questionId === 'lot-number'
    );

    if (lotNumberQuestion) {
      localStorage.setItem(localStorageKeys.sessionLotNumber, lotNumberQuestion.answer);
    }

    if (!isEditingRecordedProcedure) {
      void navigate(authenticatedRouteConfig.appointments.createUrl());
    }
  };

  const procedureResults: IProcedureQuestionAndAnswer[] | undefined =
    appointment?.procedure?.results?.map((result) => {
      return {
        questionId: result.procedureResultId,
        question: result.text,
        answerId: result.answerId,
        answer: result.answerText,
        answerType: result.type,
      } as IProcedureQuestionAndAnswer;
    });

  const shouldRenderRecordProcedureModal =
    isRecordProcedureForVaccines || isRecordProcedureForTests || isRecordProcedureRegenCOV;

  const shouldRenderRecordProcedureButton =
    !isEditingRecordedProcedure && shouldRenderRecordProcedureModal;

  if (!appointmentId) {
    return <></>;
  }

  if (error) {
    return <ErrorView onRetry={refetch} />;
  }

  if (loading) {
    return (
      <Box height='100%' display='flex' justifyContent='center' alignItems='center'>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <SecondaryScreenContainer>
      <Box display='grid' gap={theme.spacing(8)} py={theme.spacing(6)}>
        <Box
          flex='1'
          display='flex'
          justifyContent='space-between'
          px={theme.spacing(12)}
          alignItems='center'
          flexWrap='wrap'
        >
          <Typography variant='h1' component='h1'>
            {t('appointmentDetails.appointmentDetailsScreen.title')}
          </Typography>
          <Barcode barcodeValue={orderNumber} />
        </Box>
        <StyledAppointmentDetailsToolBar>
          <Box
            display='flex'
            flexDirection='row'
            flexGrow={1}
            gap={theme.spacing(4)}
            flexWrap='wrap'
          >
            {isEditingRecordedProcedure && (
              <Button
                startIcon={<CreateIcon />}
                onClick={onRecordProcedurePress}
                color='primary'
                size='large'
                id='edit-procedure-button'
                disabled={isCancelConfirmationOpen || isRecordProcedureOpen}
              >
                {t('appointmentDetails.appointmentDetailsScreen.editRecordProcedureLabel')}
              </Button>
            )}
            {shouldRenderRecordProcedureButton && (
              <Tooltip
                open={isClaimAlreadySubmitted}
                title={t('appointmentDetails.appointmentDetailsScreen.claimAlreadySubmittedText')}
                placement='bottom'
              >
                <Button
                  startIcon={<NoteAddIcon />}
                  onClick={onRecordProcedurePress}
                  color='primary'
                  size='large'
                  disabled={
                    isCancelConfirmationOpen || isRecordProcedureOpen || isClaimAlreadySubmitted
                  }
                  id='record-procedure-button'
                >
                  {t('appointmentDetails.appointmentDetailsScreen.recordProcedureLabel')}
                </Button>
              </Tooltip>
            )}
            <PrintAppointmentButton
              appointment={appointment}
              services={services}
              isDisabled={isCancelConfirmationOpen || isRecordProcedureOpen}
              clia={data?.provider?.cliaNumber}
            />
            {canShowPrintTestResult && (
              <AppointmentPrintOutButton
                appointment={appointment}
                currentProvider={currentProvider}
                isDisabled={!canPrintTestResult}
                clia={data?.provider?.cliaNumber ?? ''}
              />
            )}
          </Box>
          {!isEditingRecordedProcedure && (
            <Button
              startIcon={<EventBusyIcon />}
              onClick={onCancelButtonPress}
              color='primary'
              size='large'
              disabled={isCancelConfirmationOpen || isRecordProcedureOpen}
            >
              {t('appointmentDetails.appointmentDetailsScreen.cancelLabel')}
            </Button>
          )}
        </StyledAppointmentDetailsToolBar>
        <SummarySection appointment={appointment || undefined} services={services} />
        <PatientInfoSection appointment={appointment || undefined} />
        <ProcedureInfoSection appointment={appointment || undefined} />
        <Divider />
        <PlanInfoSection appointment={appointment || undefined} />
        <Divider />
        <PerformingFacilitySection
          appointment={appointment || undefined}
          clia={data?.provider?.cliaNumber ?? ''}
        />
        <Divider />
        <PatientProfileSection appointment={appointment || undefined} />
      </Box>
      <CancelAppointmentModal
        isOpen={isCancelConfirmationOpen}
        durationMinutes={appointment?.duration}
        firstName={appointment?.patient.firstName}
        lastName={appointment?.patient.lastName}
        orderNumber={appointment?.orderNumber}
        startDate={isoDateTimeStringToDate(appointment?.startDateISO ?? '')}
        onKeepPress={onKeepPress}
        onCancelPress={onCancelConfirmedPress}
        key='cancel-confirmation-modal'
      />
      {shouldRenderRecordProcedureModal && (
        <RecordProcedureModal
          isOpen={isRecordProcedureOpen}
          isLoading={recordProcedureLoading}
          serviceName={serviceName}
          appointmentProcedureQuestions={appointment?.procedure.questions || []}
          questionAndAnswers={procedureResults}
          onClosePress={onCloseRecordProcedurePress}
          onSavePress={onSaveRecordProcedure}
          key='record-procedure-modal'
        />
      )}
    </SecondaryScreenContainer>
  );
};
