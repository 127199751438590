// Copyright 2021 Prescryptive Health, Inc.

import { Box } from '@mui/material';
import { styled } from '@mui/material';

export const InfoDetailContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('phone')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
  },
  [theme.breakpoints.up('tablet')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: `${theme.spacing(8)} ${theme.spacing(6)}`,
  },
  [theme.breakpoints.up('desktop')]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [theme.breakpoints.up('xl')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: theme.spacing(8),
  },
}));

export const InfoDividerContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('phone')]: {
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  [theme.breakpoints.up('tablet')]: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
}));
