// Copyright 2021 Prescryptive Health, Inc.

import { HttpStatusCodesEnum } from '../../http-status-codes';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { call } from '../../call';
import { ApiErrors } from '../../errors/api-errors';
import { IProviderUsersGetResponse } from './provider-users-get.response';
import { ProviderUser } from '../../../model/provider-user';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export async function providerUsersGet(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  providerName: string
): Promise<ProviderUser[]> {
  const url = buildUrl(apiConfig, 'providerUsersGet', {
    ':providerName': providerName,
  });
  const headers = buildBearerAuthHeaders(authToken);

  const response: Response = await call(url, undefined, 'GET', headers);
  if (response.ok) {
    return await resultFromResponse(providerName, response);
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.PROVIDER_USERS_GET,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  throw new PharmacyPortalError(
    ApiErrors.providerGet(providerName, response.statusText),
    ApiErrorCode.PROVIDER_USERS_GET,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}

async function resultFromResponse(
  providerName: string,
  response: Response
): Promise<ProviderUser[]> {
  const responseData = (await response.json()) as IProviderUsersGetResponse[];
  if (!responseData) {
    throw new PharmacyPortalError(
      ApiErrors.providerGet(providerName, ApiErrors.unexpectedResponseDataFormat),
      ApiErrorCode.PROVIDER_USERS_GET,
      PharmacyPortalErrorType.API_ERROR,
      response.status
    );
  }
  return responseData.map(
    (user) =>
      ({
        id: user?.id || '',
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        fullName: user?.fullName || '',
        principalName: user?.principalName || '',
        contactEmail: user?.contactEmail || '',
        phoneNumber: user?.phoneNumber || '',
        temporaryPassword: user?.temporaryPassword || '',
        npi: user?.npi || '',
        created: user?.created || '',
        updated: user?.updated || '',
        role: user?.role || '',
        isActive: user?.isActive || false,
      } as ProviderUser)
  );
}
