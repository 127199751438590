// Copyright 2021 Prescryptive Health, Inc.

import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { ProviderDispatch } from '../dispatch/provider.dispatch';
import { providerLocationUpdate } from '../../../api/v1/provider-location-update/provider-location-update';
import { locationUpdateDispatch } from '../dispatch/store-location-update.dispatch';
import {
  ILanesUpdateRequest,
  IProviderLocationUpdateRequest,
} from '../../../api/v1/provider-location-update/provider-location-update.request';
import { logApiError } from '../../../api/errors/log-api-error';

export interface ILocationUpdateAsyncActionArgs extends IApiAsyncActionArgs {
  locationId: string;
  locationName: string;
  address1: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  lanes: ILanesUpdateRequest[];
  providerDispatch: ProviderDispatch;
}

export const locationUpdateAsyncAction = async (
  args: ILocationUpdateAsyncActionArgs
): Promise<void> => {
  const {
    locationId,
    locationName,
    address1,
    city,
    state,
    zipCode: zip,
    phoneNumber,
    lanes,
    providerDispatch,
    telemetryService,
    getAuthToken,
  } = args;

  try {
    const updateProviderLocationRequest: IProviderLocationUpdateRequest = {
      identifier: locationId,
      name: locationName,
      address1,
      city,
      state,
      zip,
      phoneNumber,
      lanes,
    };

    telemetryService.trackEvent('store-location-update', updateProviderLocationRequest);

    const { configState, authProvider } = args;

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    const result = await providerLocationUpdate(
      configState.apis.pharmacyPortal,
      accessToken,
      updateProviderLocationRequest
    );
    locationUpdateDispatch(providerDispatch, result);
  } catch (error) {
    logApiError(telemetryService, error);
    return Promise.reject(error);
  }
};
