// Copyright 2021 Prescryptive Health, Inc.

import { showBusyWhile } from '../../../busy/dispatch/show-busy-while';
import { IWaitlistItem } from '../../../../model/waitlist-item';
import { IApiAsyncActionArgs } from '../../../api-async-action-args';
import { fatalErrorDispatch } from '../../../error/dispatch/fatal-error.dispatch';
import { AuthenticationProviderUndefinedError } from '../../../../api/errors/authentication-provider-undefined.error';
import { waitlistAdd } from '../../../../api/v1/waitlist-add/waitlist-add';
import { waitlistIsStaleDispatch } from '../dispatch/waitlist-is-stale.dispatch';
import { WaitlistDispatch } from '../dispatch/waitlist.dispatch';
import { NonFatalError } from '../../../../api/errors/non-fatal.error';
import { nonFatalErrorDispatch } from '../../../error/dispatch/non-fatal-error.dispatch';
import { IWaitlistAddError } from '../../../../model/waitlist-add';
import { ReactNode } from 'react';
import { logApiError } from '../../../../api/errors/log-api-error';

export interface IWaitlistAddAsyncActionArgs extends IApiAsyncActionArgs {
  waitlistDispatch: WaitlistDispatch;
  locationId: string;
  waitlistItems: IWaitlistItem[];
  nonFatalErrorTitle: string;
  nonFatalErrorContent: (failedItems: IWaitlistAddError[]) => ReactNode;
}

export const waitlistAddAsyncAction = async (args: IWaitlistAddAsyncActionArgs): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IWaitlistAddAsyncActionArgs): Promise<void> => {
  const {
    waitlistDispatch,
    locationId,
    waitlistItems,
    telemetryService,
    nonFatalErrorTitle,
    nonFatalErrorContent,
    getAuthToken,
  } = args;

  try {
    telemetryService.trackEvent('waitlist-add', {
      locationId,
      waitlistItems,
    });

    const { configState, authProvider } = args;
    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    const response = await waitlistAdd(
      configState.apis.pharmacyPortal,
      accessToken,
      locationId,
      waitlistItems
    );

    waitlistIsStaleDispatch(waitlistDispatch);

    if (response.failed.length > 0) {
      nonFatalErrorDispatch(
        args.errorDispatch,
        nonFatalErrorTitle,
        nonFatalErrorContent(response.failed)
      );
      throw new NonFatalError();
    }
  } catch (error) {
    if (!(error instanceof NonFatalError)) {
      fatalErrorDispatch(args.errorDispatch);
    }
    logApiError(telemetryService, error);
    return Promise.reject();
  }
};
