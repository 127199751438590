// Copyright 2021 Prescryptive Health, Inc.

import { WaitlistItemStatus } from '../../model/waitlist-item';

export interface IWaitlistItemStatusContent {
  canceledStatus: string;
  fulfilledStatus: string;
  invitedStatus: string;
  expiredStatus: string;
}

export const waitlistItemStatusContentEn: IWaitlistItemStatusContent = {
  canceledStatus: 'Canceled',
  fulfilledStatus: 'Fulfilled',
  invitedStatus: 'Invited',
  expiredStatus: 'Expired',
};

const waitlistItemStatusToString = (
  status: WaitlistItemStatus | undefined,
  isExpired: boolean,
  content: IWaitlistItemStatusContent
): string => {
  if (!status) {
    return '';
  }

  switch (status) {
    case 'invited':
      return isExpired ? content.expiredStatus : content.invitedStatus;

    case 'fulfilled':
      return content.fulfilledStatus;

    case 'canceled':
      return content.canceledStatus;

    default:
      return '?';
  }
};

export { waitlistItemStatusToString };
