import getEnvironmentVariable from './get-environment-variable';

export type EnvironmentVariable =
  | 'APP_INSIGHTS_INSTRUMENTATION_KEY'
  | 'AUTHENTICATION_CLIENT_ID'
  | 'AUTHENTICATION_TENANT_ID'
  | 'HEAP_ID'
  | 'LAUNCH_DARKLY_KEY'
  | 'MYRX_DOMAIN'
  | 'NEW_USER_DOMAIN'
  | 'PHARMACY_PORTAL_API_HOST_URL'
  | 'STRAPI_HOST_URL'
  | 'SUPPORT_EMAIL';

export type EnvironmentVariables = { [type in EnvironmentVariable]: string };

export default {
  APP_INSIGHTS_INSTRUMENTATION_KEY: getEnvironmentVariable(
    'REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY'
  ),
  AUTHENTICATION_CLIENT_ID: getEnvironmentVariable('REACT_APP_AUTHENTICATION_CLIENT_ID'),
  AUTHENTICATION_TENANT_ID: getEnvironmentVariable('REACT_APP_AUTHENTICATION_TENANT_ID'),
  HEAP_ID: getEnvironmentVariable('REACT_APP_HEAP_ID'),
  LAUNCH_DARKLY_KEY: getEnvironmentVariable('REACT_APP_LAUNCH_DARKLY_KEY'),
  MYRX_DOMAIN: getEnvironmentVariable('REACT_APP_MYRX_DOMAIN'),
  NEW_USER_DOMAIN: getEnvironmentVariable('REACT_APP_NEW_USER_DOMAIN'),
  PHARMACY_PORTAL_API_HOST_URL: getEnvironmentVariable('REACT_APP_PHARMACY_PORTAL_API_HOST_URL'),
  STRAPI_HOST_URL: getEnvironmentVariable('REACT_APP_STRAPI_HOST_URL'),
  SUPPORT_EMAIL: getEnvironmentVariable('REACT_APP_SUPPORT_EMAIL'),
} as EnvironmentVariables;
