// Copyright 2021 Prescryptive Health, Inc.

import { IProviderAction } from './provider.action';

export type ILocationIsTestActionPayload = {
  locationId: string;
  isTest: boolean;
};

export type ILocationIsTestUpdateAction = IProviderAction<
  'STORE_LOCATION_IS_TEST_UPDATE',
  ILocationIsTestActionPayload
>;

export const locationIsTestUpdateAction = (
  locationId: string,
  isTest: boolean
): ILocationIsTestUpdateAction => {
  return {
    type: 'STORE_LOCATION_IS_TEST_UPDATE',
    payload: { locationId, isTest },
  };
};
