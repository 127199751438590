import { Button, ButtonProps } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useState } from 'react';
import { AddExistingUserDialog } from './add-existing-user-dialog';
import { useNotificationContext } from '../../../../../../../hooks/use-notification-context/use-notification-context.hook';
import { notificationSetDispatch } from '../../../../../../../state/notification/dispatch/notification-set.dispatch';
import { useTranslation } from 'react-i18next';

interface IAddExistingUserProps extends ButtonProps {
  onSuccess?: () => void;
}

export const AddExistingUser = ({
  onSuccess = () => undefined,
  ...buttonProps
}: IAddExistingUserProps) => {
  const { t } = useTranslation();
  const { notificationDispatch } = useNotificationContext();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => {
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  const onSubmitSuccess = () => {
    notificationSetDispatch(notificationDispatch, t('settings.addExistingUser.onSuccessMessage'));
    closeDialog();
    onSuccess();
  };

  return (
    <>
      <Button onClick={openDialog} startIcon={<AddCircleIcon />} {...buttonProps}>
        {t('settings.addExistingUser.addExistingUserButton')}
      </Button>
      <AddExistingUserDialog
        open={isDialogOpen}
        onClose={closeDialog}
        onCancel={closeDialog}
        onSubmitSuccess={onSubmitSuccess}
        key={`add-existing-user-dialog-${isDialogOpen}`}
      />
    </>
  );
};
