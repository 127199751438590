// Copyright 2021 Prescryptive Health, Inc.
import queryString from 'query-string';

export type RouteKey =
  | 'root'
  | 'appointments'
  | 'appointmentDetails'
  | 'blockedTimeDetails'
  | 'myServices'
  | 'serviceHours'
  | 'paymentsBilling'
  | 'reports'
  | 'downloadReports'
  | 'waitlist'
  | 'addServices'
  | 'pricing'
  | 'addServiceDetails'
  | 'addProgramDetails'
  | 'settings'
  | 'swordfishOrderQ'
  | 'swordfishOrderProcessing'
  | 'signin'
  | 'learnMore'
  | 'drugSearch';

export const authenticatedRouteConfig: {
  [key in RouteKey]: {
    route: string;
    createUrl: (...args: string[]) => string;
  };
} = {
  root: {
    route: '/',
    createUrl: (): string => '/',
  },
  appointments: {
    route: '/appointments',
    createUrl: (): string => '/appointments',
  },
  appointmentDetails: {
    route: '/appointments/:appointmentId',
    createUrl: (appointmentId: string): string =>
      appointmentId ? `/appointments/${appointmentId}` : '/appointments',
  },
  blockedTimeDetails: {
    route: '/blocked-times/:blockedTimeId',
    createUrl: (blockedTimeId: string): string =>
      blockedTimeId ? `/blocked-times/${blockedTimeId}` : '/appointments',
  },
  myServices: {
    route: '/my-services',
    createUrl: (...openedServiceTypes: string[]): string =>
      openedServiceTypes.length > 0
        ? queryString.stringifyUrl(
            {
              url: '/my-services',
              query: {
                openedServices: openedServiceTypes,
              },
            },
            {
              arrayFormat: 'comma',
            }
          )
        : '/my-services',
  },
  serviceHours: {
    route: '/service-hours',
    createUrl: (): string => '/service-hours',
  },
  paymentsBilling: {
    route: '/payments-billing',
    createUrl: (): string => '/payments-billing',
  },
  reports: {
    route: '/reports',
    createUrl: (): string => '/reports',
  },
  downloadReports: {
    route: '/download-reports',
    createUrl: (): string => '/download-reports',
  },
  waitlist: {
    route: '/waitlist',
    createUrl: (): string => '/waitlist',
  },
  pricing: {
    route: '/pricing',
    createUrl: (): string => '/pricing',
  },
  addServices: {
    route: '/add-services',
    createUrl: (): string => '/add-services',
  },
  addServiceDetails: {
    route: '/add-services/:serviceId',
    createUrl: (serviceId: string): string =>
      serviceId ? `/add-services/${serviceId}` : '/add-services',
  },
  addProgramDetails: {
    route: '/add-programs/:programId',
    createUrl: (programId: string): string =>
      programId ? `/add-programs/${programId}` : '/add-services',
  },
  settings: {
    route: '/settings',
    createUrl: (): string => '/settings',
  },
  swordfishOrderQ: {
    route: '/swordfish/orders',
    createUrl: (): string => '/swordfish/orders',
  },
  swordfishOrderProcessing: {
    route: '/swordfish/orders/:orderId',
    createUrl: (orderId: string): string =>
      orderId ? `/swordfish/orders/${orderId}` : '/swordfish/orders',
  },
  signin: {
    route: '/sign-in',
    createUrl: (): string => '/sign-in',
  },
  learnMore: {
    route: '/learn-more-about-authentication-changes',
    createUrl: (): string => '/learn-more-about-authentication-changes',
  },
  drugSearch: {
    route: '/drug-search',
    createUrl: (): string => '/drug-search',
  },
};
