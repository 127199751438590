// Copyright 2021 Prescryptive Health, Inc.

import { fatalErrorDispatch } from '../../../error/dispatch/fatal-error.dispatch';
import { showBusyWhile } from '../../../busy/dispatch/show-busy-while';
import { IApiAsyncActionArgs } from '../../../api-async-action-args';
import { WaitlistDispatch } from '../dispatch/waitlist.dispatch';
import { AuthenticationProviderUndefinedError } from '../../../../api/errors/authentication-provider-undefined.error';
import { waitlistRemove } from '../../../../api/v1/waitlist-remove/waitlist-remove';
import { waitlistIsStaleDispatch } from '../dispatch/waitlist-is-stale.dispatch';
import { logApiError } from '../../../../api/errors/log-api-error';

export interface IWaitlistRemoveAsyncActionArgs extends IApiAsyncActionArgs {
  locationId: string;
  waitlistItemId: string;
  waitlistDispatch: WaitlistDispatch;
}

export const waitlistRemoveAsyncAction = async (
  args: IWaitlistRemoveAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IWaitlistRemoveAsyncActionArgs): Promise<void> => {
  const {
    locationId,
    waitlistItemId,
    telemetryService,
    waitlistDispatch,
    configState,
    authProvider,
    getAuthToken,
  } = args;

  try {
    telemetryService.trackEvent('waitlist-remove', {
      locationId,
      waitlistItemId,
    });

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    await waitlistRemove(configState.apis.pharmacyPortal, accessToken, locationId, waitlistItemId);

    waitlistIsStaleDispatch(waitlistDispatch);
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
    return Promise.reject();
  }
};
