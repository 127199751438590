// Copyright 2020 Prescryptive Health, Inc.

import { IConfigObject } from '../config/config.helper';

type ApiConfigKeys = 'host' | 'port' | 'protocol' | 'version';

export interface IApiConfig<K extends string> {
  env: IConfigObject<ApiConfigKeys>;
  paths: IConfigObject<K>;
  retryPolicy?: {
    pause: number;
    remaining: number;
  };
}

export interface IAuthHeaders {
  Authorization: string;
}

export interface ILocation {
  /**
   * Returns the Location object's URL's fragment (includes leading "#" if non-empty).
   * Can be set, to navigate to the same URL with a changed fragment (ignores leading "#").
   */
  hash: string;
  /**
   * Returns the Location object's URL's host and port (if different from the default
   * port for the scheme).
   * Can be set, to navigate to the same URL with a changed host and port.
   */
  host: string;
  /**
   * Returns the Location object's URL.
   * Can be set, to navigate to the given URL.
   */
  href: string;
  /**
   * Returns the Location object's URL's path.
   * Can be set, to navigate to the same URL with a changed path.
   */
  pathname: string;
  /**
   * Returns the Location object's URL's scheme.
   * Can be set, to navigate to the same URL with a changed scheme.
   */
  protocol: string;
  /**
   * Returns the Location object's URL's query (includes leading "?" if non-empty).
   * Can be set, to navigate to the same URL with a changed query (ignores leading "?").
   */
  search: string;
}

export function buildUrl<K extends string>(
  apiConfig: IApiConfig<K>,
  path: K,
  args: { [key: string]: string }
): string {
  let pathValue = apiConfig.paths[path];

  Object.keys(args).forEach((arg) => {
    pathValue = pathValue.replace(arg, encodeURIComponent(args[arg]));
  });

  return `${apiConfig.env.protocol}://${apiConfig.env.host}:${apiConfig.env.port}${apiConfig.env.version}${pathValue}`;
}

export function buildBearerAuthHeaders(authToken: string): IAuthHeaders {
  return {
    Authorization: `Bearer ${authToken}`,
  };
}
