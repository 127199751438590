// Copyright 2021 Prescryptive Health, Inc.

import { IService } from '../../../model/service';
import { servicesSetAction } from '../actions/services-set.action';
import { PostAuthDispatch } from './post-auth.dispatch';

export const servicesSetDispatch = (
  dispatch: PostAuthDispatch,
  services: IService[]
): void => {
  dispatch(servicesSetAction(services));
};
