import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { TelemetryEvent } from '../../../providers/telemetry/telemetry.context';
import { TelemetryService } from '../../../providers/telemetry/telemetry.service';
import { IDrugSearchApiConfig } from '../../../state/drug-search/drug-search.state';
import { call } from '../../call';
import { logApiError } from '../../errors/log-api-error';
import { buildPriceSearchUrl } from '../api.helper';
import { DrugSearchApiErrorCode } from '../errors/drug-search-api-error-code';
import { DrugSearchApiErrors } from '../errors/drug-search-api-errors';
import {
  IGetPricesByPharmaciesResponse,
  PriceByPharmacyResponse,
} from './get-prices-by-pharmacies-response';

export interface IPriceSearchBody {
  providerIds: string[];
  quantity: number;
  fillDate: string;
  daysSupply: number;
  productId: string;
  productIdType: string;
}

export async function getPricesByPharmacies(
  apiConfig: IDrugSearchApiConfig,
  subscriptionKey: string,
  body: IPriceSearchBody,
  telemetryService: TelemetryService<TelemetryEvent>
): Promise<PriceByPharmacyResponse[]> {
  const url = buildPriceSearchUrl(apiConfig, 'priceSearch');

  const response: Response = await call(url, body, 'POST', {
    ['Ocp-Apim-Subscription-Key']: subscriptionKey,
  });

  if (response.ok) {
    return await getPricesByPharmaciesFromResponse(response);
  }

  logApiError(
    telemetryService,
    new PharmacyPortalError(
      DrugSearchApiErrors.getPricesByPharmacies(body),
      DrugSearchApiErrorCode.GET_PRICES_BY_PHARMACIES,
      PharmacyPortalErrorType.API_ERROR,
      response.status
    )
  );
  return [];
}

async function getPricesByPharmaciesFromResponse(response: Response) {
  const responseData = (await response.json()) as IGetPricesByPharmaciesResponse;

  return responseData.success && responseData.payload !== null ? responseData.payload : [];
}
