// Copyright 2021 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

interface ILabelDetailProps {
  label: string;
  detail: string;
}

export const LabelDetail = ({ label, detail }: ILabelDetailProps): ReactElement => {
  const theme = useTheme();
  return (
    <Box display='flex' flexWrap='wrap' gap={theme.spacing(2)}>
      <Box minWidth='24ch'>
        <Typography>{label}</Typography>
      </Box>
      <Box>
        <Typography data-heap-redact-text='true' style={{ fontWeight: 'bold' }}>
          {detail}
        </Typography>
      </Box>
    </Box>
  );
};
