import { IApiConfig } from '../api.helper';

export function buildDrugSearchUrl<K extends string>(
  apiConfig: IApiConfig<K>,
  path: string,
  args: { [key: string]: string }
) {
  let pathValue = apiConfig.paths[path];

  Object.keys(args).forEach((arg) => {
    pathValue = pathValue.replace(arg, encodeURIComponent(args[arg]));
  });

  return `${apiConfig.env.protocol}://${apiConfig.env.host}:${apiConfig.env.port}${pathValue}`;
}

export function buildGeoLocationSearchUrl<K extends string>(
  apiConfig: IApiConfig<K>,
  path: string,
  args: { [key: string]: string }
) {
  let pathValue = apiConfig.paths[path];

  Object.keys(args).forEach((arg) => {
    pathValue = pathValue.replace(arg, args[arg]);
  });

  return `${apiConfig.env.protocol}://${apiConfig.env.host}/api${apiConfig.env.version}${pathValue}`;
}

export function buildPriceSearchUrl<K extends string>(apiConfig: IApiConfig<K>, path: string) {
  const pathValue = apiConfig.paths[path];

  return `${apiConfig.env.protocol}://${apiConfig.env.host}${pathValue}`;
}
