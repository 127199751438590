// Copyright 2021 Prescryptive Health, Inc.

import { ILocationAction } from './location.action';

export type IPharmacyServicePriceUpdateActionPayload = {
  serviceType: string;
  price: number;
};

export type IPharmacyServicePriceUpdateAction = ILocationAction<
  'PHARMACY_SERVICE_PRICE_UPDATE',
  IPharmacyServicePriceUpdateActionPayload
>;

export const pharmacyServicePriceUpdateAction = (
  serviceType: string,
  newPriceCents: number
): IPharmacyServicePriceUpdateAction => {
  return {
    type: 'PHARMACY_SERVICE_PRICE_UPDATE',
    payload: { serviceType, price: newPriceCents },
  };
};