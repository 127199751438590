// Copyright 2020 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import dateFormatter from '../../../../../formatters/dates/date.formatter';
import { PhoneNumberFormatter } from '../../../../../formatters/phone-number/phone-number.formatter';
import { Box, Typography, useTheme } from '@mui/material';
import { LabelDetail } from '../../label-detail';
import { IAppointment } from '../../appointment-details.screen.query';
import { isoDateStringToLocalDate } from '../../../../../helpers/dates/date-helper/date.helper';
import { useTranslation } from 'react-i18next';

export interface IPatientInfoSectionProps {
  appointment?: IAppointment;
}

export const PatientInfoSection = ({ appointment }: IPatientInfoSectionProps): ReactElement => {
  const { t } = useTranslation();

  const firstName = appointment?.patient?.firstName ?? '';
  const lastName = appointment?.patient?.lastName ?? '';
  const line1 = appointment?.patient?.address.line1 ?? '';
  const birthDate = isoDateStringToLocalDate(appointment?.patient?.dateOfBirth);
  const formattedBirthDate = birthDate ? dateFormatter.formatToMDY(birthDate) : '';
  const email = appointment?.patient?.email ?? '';
  const line2 = appointment?.patient?.address.line2 ?? '';
  const state = appointment?.patient?.address.state ?? '';
  const city = appointment?.patient?.address.city ?? '';
  const phoneNumber = PhoneNumberFormatter.formatForUI(appointment?.patient?.phoneNumber ?? '');
  const county = appointment?.patient?.address.county ?? '';
  const zipCode = appointment?.patient?.address.zip ?? '';

  const theme = useTheme();

  return (
    <Box component='section' display='grid' gap={theme.spacing(4)} className='print-section'>
      <Typography variant='h2' component='h2'>
        {t('appointmentDetails.patientInfo.sectionHeading')}
      </Typography>
      <Box display='flex' flexWrap='wrap' gap={theme.spacing(2)}>
        <Box flex='1' display='flex' flexDirection='column' gap={theme.spacing(2)}>
          <LabelDetail
            label={t('appointmentDetails.patientInfo.firstNameLabel')}
            detail={firstName}
          />
          <LabelDetail
            label={t('appointmentDetails.patientInfo.lastNameLabel')}
            detail={lastName}
          />
          <LabelDetail
            label={t('appointmentDetails.patientInfo.phoneNumberLabel')}
            detail={phoneNumber}
          />
          <LabelDetail
            label={t('appointmentDetails.patientInfo.birthDateLabel')}
            detail={formattedBirthDate}
          />
          <LabelDetail label={t('appointmentDetails.patientInfo.emailLabel')} detail={email} />
        </Box>
        <Box flex='1' display='flex' flexDirection='column' gap={theme.spacing(2)}>
          <LabelDetail label={t('appointmentDetails.patientInfo.address1Label')} detail={line1} />
          <LabelDetail label={t('appointmentDetails.patientInfo.address2Label')} detail={line2} />
          <LabelDetail label={t('appointmentDetails.patientInfo.cityLabel')} detail={city} />
          <LabelDetail label={t('appointmentDetails.patientInfo.countyLabel')} detail={county} />
          <LabelDetail label={t('appointmentDetails.patientInfo.stateLabel')} detail={state} />
          <LabelDetail label={t('appointmentDetails.patientInfo.zipCodeLabel')} detail={zipCode} />
        </Box>
      </Box>
    </Box>
  );
};
