// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ValueProp } from '../../../strapi/value-prop/value-prop';
import {
  ComponentProviderServiceContentTypesTitleDescription,
  ComponentProviderServiceContentTypesValueProp,
  ProviderLanderSectionContentZoneDynamicZone,
} from '../../../../model/strapi/strapi-models';
import {
  StyledMultiColumnCardContainer,
  StyledMultiColumnCardsBox,
  StyledMultiColumnContainer,
  StyledMultiColumnDescriptionTypography,
  StyledMultiColumnHeaderTypography,
} from './multi-column.styled-components';

type IMultiColumnProps = {
  multiColumnData: Maybe<Maybe<ProviderLanderSectionContentZoneDynamicZone>[]>;
};

export const MultiColumn = ({
  multiColumnData: multiBenefitsData,
}: IMultiColumnProps): ReactElement => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('tablet'));

  const renderTitleSection = () => {
    const titleDescriptionData = multiBenefitsData?.find(
      (contentZone) =>
        contentZone?.__typename &&
        contentZone?.__typename ===
          'ComponentProviderServiceContentTypesTitleDescription'
    ) as ComponentProviderServiceContentTypesTitleDescription;

    if (titleDescriptionData) {
      return (
        <Box textAlign='center' >
          <StyledMultiColumnHeaderTypography variant='h4'>
            {titleDescriptionData?.Title}
          </StyledMultiColumnHeaderTypography>
          <StyledMultiColumnDescriptionTypography>
            {titleDescriptionData?.Description}
          </StyledMultiColumnDescriptionTypography>
        </Box>
      );
    }
    return null;
  };

  const renderColumnSections = () => {
    const valuePropData = multiBenefitsData?.filter(
      (contentZone) =>
        contentZone?.__typename &&
        contentZone?.__typename ===
          'ComponentProviderServiceContentTypesValueProp'
    ) as ComponentProviderServiceContentTypesValueProp[];
    return valuePropData.map((contentZone) => (
      <StyledMultiColumnCardContainer key={contentZone.id}>
        <ValueProp
          title={contentZone?.valuePropTitle ?? ''}
          text={contentZone?.valuePropText ?? ''}
          iconData={contentZone?.valuePropIcon}
          iconPosition={
            !isTablet &&
            contentZone?.valuePropIcon?.width &&
            contentZone?.valuePropIcon?.width < 50
              ? 'left'
              : 'top'
          }
        />
      </StyledMultiColumnCardContainer>
    ));
  };

  return (
    <StyledMultiColumnContainer>
      {renderTitleSection()}
      <StyledMultiColumnCardsBox>
        {renderColumnSections()}
      </StyledMultiColumnCardsBox>
    </StyledMultiColumnContainer>
  );
};
