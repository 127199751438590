// Copyright 2020 Prescryptive Health, Inc.

import { IAppointmentsAction } from './appointments.action';
import { ICalendarAppointmentItem } from '../../../model/calendar-appointment-item';
import { ICalendarBlockedTimeItem } from '../../../model/calendar-blocked-time-item';

export type IAppointmentsSetAction = IAppointmentsAction<'APPOINTMENTS_SET'>;

export const appointmentsSetAction = (
  appointments: ICalendarAppointmentItem[],
  blockedSlots: ICalendarBlockedTimeItem[]
): IAppointmentsSetAction => ({
  type: 'APPOINTMENTS_SET',
  payload: {
    appointments,
    blockedSlots,
    appointmentsAreStale: false,
  },
});
