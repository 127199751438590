// Copyright 2021 Prescryptive Health, Inc.

import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { appointmentsSetDispatch } from '../dispatch/appointments-set.dispatch';
import { AppointmentsDispatch } from '../dispatch/appointments.dispatch';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { scheduleGet } from '../../../api/v1/schedule-get/schedule-get';
import { logApiError } from '../../../api/errors/log-api-error';

export interface ICalendarScheduleGetAsyncActionArgs extends IApiAsyncActionArgs {
  appointmentsDispatch: AppointmentsDispatch;
  locationId: string;
  date: Date;
  serviceType?: string;
  bookingStatus?: string;
}

export const calendarScheduleGetAsyncAction = async (
  args: ICalendarScheduleGetAsyncActionArgs
): Promise<void> => {
  const {
    appointmentsDispatch,
    locationId,
    date,
    serviceType,
    bookingStatus,
    telemetryService,
    configState,
    authProvider,
    getAuthToken,
  } = args;

  try {
    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }
    
    telemetryService.trackEvent('schedule-get', {
      locationId,
      date,
      serviceType,
      bookingStatus,
    });

    const accessToken = await getAuthToken;

    const result = await scheduleGet(
      configState.apis.pharmacyPortal,
      accessToken,
      locationId,
      date,
      serviceType,
      bookingStatus
    );
    appointmentsSetDispatch(appointmentsDispatch, result.appointments, result.blockedSlots);
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
  }
};
