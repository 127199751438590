// Copyright 2020 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { useErrorContext } from '../../../hooks/use-error-context/use-error-context.hook';
import { ErrorStatus } from '../../../state/error/error.state';
import { errorResetDispatch } from '../../../state/error/dispatch/error-reset.dispatch';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import env from '../../../helpers/environment-variables';
import { useTranslation } from 'react-i18next';

export const ErrorModal = (): ReactElement => {
  const { t } = useTranslation();
  const { errorState, errorDispatch } = useErrorContext();

  const { status: errorStatus, title, content: modalContent } = errorState;

  const errroContent =
    errorStatus === ErrorStatus.fatal ? (
      <DialogContentText>
        {t('error.errorModalContent.fatalErrorMessage', { supportEmail: env.SUPPORT_EMAIL })}
      </DialogContentText>
    ) : (
      modalContent
    );

  const onClose = () => {
    errorResetDispatch(errorDispatch);
  };

  const hasError = errorStatus !== ErrorStatus.none;
  const dialogTitle = title || t('error.errorModalContent.errorTitle');

  return (
    <Dialog open={hasError} maxWidth='lg'>
      <DialogTitle>
        {dialogTitle}
        <Button onClick={onClose} style={{ position: 'absolute', right: 0 }}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent>{errroContent}</DialogContent>
    </Dialog>
  );
};
