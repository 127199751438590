// Copyright 2021 Prescryptive Health, Inc.

import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { ApiErrors } from '../../errors/api-errors';
import { IProviderLocationUpdateRequest } from './provider-location-update.request';
import { IStoreLocation } from '../../../model/store-location';
import { IStoreLocationsResponse } from '../provider-details-get/provider-details-get.response';
import { HttpStatusCodesEnum } from '../../http-status-codes';
import { buildBearerAuthHeaders, buildUrl } from '../../api.helper';
import { call } from '../../call';
import { IErrorResponse } from '../../error-response';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export async function providerLocationUpdate(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  providerLocationUpdateRequest: IProviderLocationUpdateRequest
): Promise<IStoreLocation> {
  const url = buildUrl(apiConfig, 'locationUpdate', {
    ':locationIdentifier': providerLocationUpdateRequest.identifier,
  });
  const headers = buildBearerAuthHeaders(authToken);

  const response: Response = await call(url, providerLocationUpdateRequest, 'PATCH', headers);

  if (response.ok) {
    return resultFromResponse(response);
  }
  if (response.status === HttpStatusCodesEnum.BAD_REQUEST) {
    const errorResponse = (await response.json()) as IErrorResponse;
    errorResponse.status = response.status;
    throw errorResponse;
  }
  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.PROVIDER_LOCATION_UPDATE,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  throw new PharmacyPortalError(
    ApiErrors.locationUpdate(providerLocationUpdateRequest.identifier, response.statusText),
    ApiErrorCode.PROVIDER_LOCATION_UPDATE,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}

async function resultFromResponse(response: Response): Promise<IStoreLocation> {
  const responseLocation = (await response.json()) as IStoreLocationsResponse;
  if (!responseLocation) {
    throw new PharmacyPortalError(
      ApiErrors.locationAdd(ApiErrors.unexpectedResponseDataFormat),
      ApiErrorCode.PROVIDER_LOCATION_UPDATE,
      PharmacyPortalErrorType.API_ERROR,
      response.status
    );
  }

  const location: IStoreLocation = {
    id: responseLocation.identifier ?? '',
    name: responseLocation.locationName ?? '',
    timezone: responseLocation.timezone ?? '',
    phoneNumber: responseLocation.phoneNumber ?? '',
    lanes: responseLocation.lanes ?? [],
    address: {
      line1: responseLocation.address ?? '',
      city: responseLocation.city ?? '',
      state: responseLocation.state ?? '',
      zip: responseLocation.zip ?? '',
    },
  };

  return location;
}
