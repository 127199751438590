// Copyright 2021 Prescryptive Health, Inc.

import { IBatchStatus } from '../../../../model/batch-status';
import { IWaitlistAction } from './waitlist.action';

export type IWaitlistIsStaleAction = IWaitlistAction<'IS_STALE'>;

export const waitlistIsStaleAction = (
  batchStatus?: IBatchStatus
): IWaitlistIsStaleAction => ({
  type: 'IS_STALE',
  payload: { isStale: true, batchStatus },
});
