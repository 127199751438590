// Copyright 2021 Prescryptive Health, Inc.

import { Divider, Typography, styled } from '@mui/material';

export const StyledCancelAppointmentModalLabelTypography = styled(Typography)(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(2),
  lineHeight: theme.spacing(8),
}));

export const StyledCancelAppointmentModalDetailTypography = styled(Typography)(({ theme }) => ({
  flex: 2,
  fontWeight: 'bold',
  lineHeight: theme.spacing(8),
}));

export const StyledCancelAppointmentModalDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(6),
}));

export const StyledCancelAppointmentModalConfirmationTypography = styled(Typography)({
  fontWeight: 'bold',
});
