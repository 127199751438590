// Copyright 2021 Prescryptive Health, Inc.

import { Button, Box, Typography } from '@mui/material';
import { styled as muiStyled } from '@mui/material';
import { drawerWidth } from '../../../../../../navigation/drawer-navigators/post-auth/styles';

export const EnrollBox = muiStyled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  width: '100%',
  right: 0,
  bottom: 0,
  position: 'fixed',
  [theme.breakpoints.up('xl')]: {
    width: '100%',
    height: 'min-content',
    borderRadius: theme.spacing(1),
    right: 'auto',
    bottom: 'auto',
    position: 'relative',
  },
  [theme.breakpoints.only('lg')]: {
    paddingLeft: drawerWidth,
  },
}));

export const ActionButton = muiStyled(Button)(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    width: '100%',
  },
}));

export const PendingButton = muiStyled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[500],
  color: theme.palette.common.white,
  [theme.breakpoints.up('xl')]: {
    width: '100%',
  },
}));

export const ManageButton = muiStyled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  [theme.breakpoints.up('xl')]: {
    width: '100%',
  },
}));

export const EnrollContentBox = muiStyled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(8)}`,
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.up('tablet')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
  },
  [theme.breakpoints.up('xl')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
    flexDirection: 'column',
    alignItems: '',
  },
}));

export const EnrollmentTitleTypography = muiStyled(Typography)(({ theme }) => ({
  fontWeight: 700,
  [theme.breakpoints.up('xl')]: {
    display: 'none',
  },
}));

export const EnrollmentContainer = muiStyled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    paddingTop: theme.spacing(12),
    flexGrow: 1,
    flexBasis: '300px',
    alignSelf: 'start',
    position: 'sticky',
    top: 0,
    paddingRight: theme.spacing(10),
  },
}));
