// Copyright 2020 Prescryptive Health, Inc.

import { Reducer } from 'react';
import { IAppointmentsState } from './appointments.state';
import { AppointmentsAction } from './actions/appointments.action';
import { IAppointmentDetails } from '../../model/appointment-details';

export type AppointmentsReducer = Reducer<
  IAppointmentsState,
  AppointmentsAction
>;

export const appointmentsReducer: AppointmentsReducer = (
  state: IAppointmentsState,
  action: AppointmentsAction
): IAppointmentsState => {
  switch (action.type) {
    case 'APPOINTMENT_DETAILS_SET': {
      const payload = action.payload as {
        appointmentId: string;
        appointmentDetails: IAppointmentDetails | null | undefined;
      };
      return {
        ...state,
        appointmentDetailsMap: {
          ...state.appointmentDetailsMap,
          [payload.appointmentId]: payload.appointmentDetails,
        },
      };
    }
    default: {
      const payload = action.payload as Partial<IAppointmentsState>;
      return { ...state, ...payload };
    }
  }
};
