// Copyright 2021 Prescryptive Health, Inc.

export interface IReportsState {
  reportCsv: string[];
  reportFileName?: string;
  isReportReady: boolean;
}

export const defaultReportsState: IReportsState = {
  reportCsv: [],
  isReportReady: false,
};
