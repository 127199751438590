// Copyright 2020 Prescryptive Health, Inc.

import { AppointmentsDispatch } from './appointments.dispatch';
import { appointmentsClearAction } from '../actions/appointments-clear.action';

export const appointmentsClearDispatch = (
  dispatch: AppointmentsDispatch
): void => {
  dispatch(appointmentsClearAction());
};
