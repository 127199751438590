// Copyright 2020 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import {
  StyledLoadingSpinnerDiv,
  StyledCircularProgress,
  StyledLoadingText,
} from './busy.overlay.styled-component';
import { CircularProgress, Modal, Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen?: boolean;
}

export const BusyOverlay = ({ isOpen = false }: Props): ReactElement => {
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <Modal open={isOpen}>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height={1}
        style={{ opacity: 0.5 }}
      >
        <StyledLoadingSpinnerDiv>
          <StyledCircularProgress>
            <CircularProgress style={{ color: theme.palette.primary.contrastText }} />
          </StyledCircularProgress>
          <StyledLoadingText>{t('overlay.busyOverlay.loadingLabel')}</StyledLoadingText>
        </StyledLoadingSpinnerDiv>
      </Box>
    </Modal>
  );
};
