// Copyright 2020 Prescryptive Health, Inc.

import { INotificationAction } from './notification.action';
import { AlertColor } from '@mui/material/Alert';

export type INotificationSetAction = INotificationAction<'NOTIFICATION_SET'>;

export const notificationSetAction = (
  message: string,
  severity: AlertColor
): INotificationSetAction => ({
  type: 'NOTIFICATION_SET',
  payload: {
    message,
    severity,
  },
});
