// Copyright 2021 Prescryptive Health, Inc.

import { IPharmacyService } from "../../../model/pharmacy-service";
import { IStoreLocation } from "../../../model/store-location";
import { locationSetAction } from "../actions/location-set.action";
import { LocationDispatch } from "./location.dispatch";

export const locationSetDispatch = (
  dispatch: LocationDispatch,
  location: IStoreLocation,
  services: IPharmacyService[],
  currentSelectedDate?: Date
): void => {
  dispatch(locationSetAction(location, services, currentSelectedDate));
};