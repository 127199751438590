// Copyright 2021 Prescryptive Health, Inc.

import { IWaitlistItem } from '../../../../model/waitlist-item';
import { waitlistSetAction } from '../actions/waitlist-set.action';
import { WaitlistDispatch } from './waitlist.dispatch';

export const waitlistSetDispatch = (
  dispatch: WaitlistDispatch,
  waitlist: IWaitlistItem[]
): void => {
  dispatch(waitlistSetAction(waitlist));
};
