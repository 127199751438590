// Copyright 2021 Prescryptive Health, Inc.

import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material';
import React, { ReactElement, ReactNode } from 'react';

export interface IRemoveLocationModalProps {
  isOpen: boolean;
  heading: string;
  removeButtonText: ReactNode;
  cancelButtonText: string;
  onRemovePress: () => Promise<void>;
  onCancelPress: () => void;
}

export const RemoveLocationModal = ({
  isOpen,
  heading,
  removeButtonText,
  cancelButtonText,
  onRemovePress,
  onCancelPress,
}: IRemoveLocationModalProps): ReactElement => {
  const onRemoveButtonClick = async () => {
    await onRemovePress();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCancelPress}
      aria-labelledby='remove-location-dialog'
      maxWidth='md'
    >
      <DialogTitle id='remove-location-dialog-title'>{heading}</DialogTitle>
      <DialogActions>
        <Button
          variant='contained'
          onClick={onRemoveButtonClick}
          color='primary'
        >
          {removeButtonText}
        </Button>
        <Button
          variant='outlined'
          onClick={onCancelPress}
          color='primary'
          autoFocus={true}
        >
          {cancelButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
