// Copyright 2021 Prescryptive Health, Inc.

import styled from 'styled-components';

export const Main = styled.main`
  flex-grow: 1;
  overflow: auto;
  height: 100vh;
  position: relative;
`;
