import { gql } from '@apollo/client';

export const CREATE_BLOCK_TIME_MUTATION = gql`
  mutation createBlockedTime($locationId: ID, $createBlockedTimeInput: CreateBlockedTimeInput) {
    createBlockedTime(locationId: $locationId, createBlockedTimeInput: $createBlockedTimeInput) {
      id
      duration
      reason
      startTime
    }
  }
`;

export interface ICreateBlockTimeResponse {
  createBlockedTime: {
    id: string;
    duration: number;
    reason: string;
    startTime: string;
  };
}

export interface ICreateBlockTimeVariables {
  locationId: string;
  createBlockedTimeInput: {
    duration: number;
    reason: string;
    startInUtc: string;
  };
}
