// Copyright 2021 Prescryptive Health, Inc.

import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { NonFatalError } from '../../../api/errors/non-fatal.error';
import { ProviderDispatch } from '../../provider/dispatch/provider.dispatch';
import { providerDetailsGet } from '../../../api/v1/provider-details-get/provider-details-get';
import { providerDetailsSetDispatch } from '../../provider/dispatch/provider-details-set.dispatch';
import { providerProgramAdd } from '../../../api/v1/provider-program-add/provider-program-add';
import { logApiError } from '../../../api/errors/log-api-error';

export interface IProviderProgramAddAsyncActionArgs extends IApiAsyncActionArgs {
  providerDispatch: ProviderDispatch;
  programIdentifier: string;
  providerName: string;
}

export const providerProgramAddAsyncAction = async (
  args: IProviderProgramAddAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IProviderProgramAddAsyncActionArgs): Promise<void> => {
  const {
    providerDispatch,
    programIdentifier,
    providerName,
    telemetryService,
    configState,
    authProvider,
    getAuthToken,
  } = args;

  try {
    telemetryService.trackEvent('provider-program-add', {
      programIdentifier,
      providerName,
    });

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    await providerProgramAdd(
      configState.apis.pharmacyPortal,
      accessToken,
      programIdentifier,
      providerName
    );

    const result = await providerDetailsGet(
      configState.apis.pharmacyPortal,
      accessToken,
      providerName
    );
    const { currentProvider, locations, enrolledServiceTypes, enrolledPrograms } = result;

    providerDetailsSetDispatch(
      providerDispatch,
      currentProvider,
      locations,
      enrolledServiceTypes,
      enrolledPrograms
    );
  } catch (error) {
    if (!(error instanceof NonFatalError)) {
      fatalErrorDispatch(args.errorDispatch);
    }
    logApiError(telemetryService, error);
    return Promise.reject();
  }
};
