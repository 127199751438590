// Copyright 2021 Prescryptive Health, Inc.

import React, { useState } from 'react';
import { FunctionComponent, ReactElement } from 'react';
import { ILaneInfo } from '../../../model/location-availability';
import { ServiceHoursView } from '../../datetime/service-hours-view/service-hours-view';
import { IPharmacyService } from '../../../model/pharmacy-service';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface ILaneViewSwitchProps {
  lanes?: ILaneInfo[];
  serviceList?: IPharmacyService[];
}

export const LaneViewSwitch: FunctionComponent<ILaneViewSwitchProps> = ({
  lanes = [],
  serviceList = [],
}): ReactElement => {
  const { t } = useTranslation();
  const [currentSelection, setCurrentSelection] = useState(lanes[0]?.emailAddress ?? '');
  const theme = useTheme();

  const onChange = (event: SelectChangeEvent<string>) => {
    setCurrentSelection(event.target.value as string);
  };

  const getSelectedServiceHours = () => {
    return lanes?.find((lane) => lane?.emailAddress === currentSelection)?.serviceHours ?? [];
  };

  const mapLaneOptions = (): Map<string, string> => {
    const options = new Map<string, string>();

    if (lanes.length === 1) {
      return options.set(lanes[0].emailAddress, 'Lane 1');
    }

    if (lanes.length === 0) {
      return options.set('', t('serviceHours.laneViewSwitch.noLanesAvailable'));
    }

    lanes.forEach((lane, index) => {
      options.set(lane.emailAddress, `Lane ${index + 1}`);
    });

    return options;
  };

  const laneOptions = mapLaneOptions();

  const renderServiceHoursView = () => {
    if (lanes.length > 0) {
      return (
        <ServiceHoursView serviceHours={getSelectedServiceHours()} serviceList={serviceList} />
      );
    }
    return <ServiceHoursView serviceHours={[]} serviceList={serviceList} />;
  };

  return (
    <Box>
      <Box display='flex' flexDirection='row' flexWrap='wrap' marginBottom={theme.spacing(6)}>
        <FormControl fullWidth={true}>
          <InputLabel id='lane-view-label'>
            {t('serviceHours.laneViewSwitch.laneSelectorLabel')}
          </InputLabel>
          <Select defaultValue={lanes[0]?.emailAddress} id='lane-view-select' onChange={onChange}>
            {Array.from(laneOptions.keys()).map((key: string, index: number) => {
              const value = laneOptions.get(key) ?? '';
              return (
                <MenuItem key={`${index}${value}`} value={key}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      {renderServiceHoursView()}
    </Box>
  );
};
