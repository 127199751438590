// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import {
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export interface IActionButtonOption {
  name: string;
  onClick: (userId: string) => void;
}

interface IUserManagementActionButtonProps {
  userId: string;
  options: IActionButtonOption[];
}
export const UserManagementActionButton = ({
  userId,
  options,
}: IUserManagementActionButtonProps): ReactElement => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (index: number, option: IActionButtonOption) => {
    option.onClick(userId);
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant='contained' color='primary' ref={anchorRef} aria-label='actions button'>
        <Button variant='outlined' onClick={handleToggle}>
          {'ACTIONS'}
        </Button>
        <Button
          color='primary'
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select action'
          aria-haspopup='menu'
          variant='outlined'
          onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>{' '}
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition={true}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper style={{ width: 200 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu'>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.name}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index, option)}>
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
