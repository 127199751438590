// Copyright 2021 Prescryptive Health, Inc.

function phoneNumberSplit(phoneNumber: string): [string, string] {
  const normalizedPhoneNumber = phoneNumber.replace('+', '');

  const numberLength = normalizedPhoneNumber.length;
  const sevenDigitSection = normalizedPhoneNumber.slice(numberLength - 7);

  const areaCode = normalizedPhoneNumber.replace(sevenDigitSection, '');
  const areaCodeLength = areaCode.length;

  return [areaCode.slice(areaCodeLength - 3), sevenDigitSection];
}

export { phoneNumberSplit };
