// Copyright 2021 Prescryptive Health, Inc.

export const csvDownloader = (csvData: string[], fileName: string): void => {
  const csv = csvData.join('\n');
  const dataUrl = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;

  const anchor = document.createElement('a');
  anchor.setAttribute('href', dataUrl);
  anchor.setAttribute('download', fileName);
  anchor.style.display = 'none';
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};
