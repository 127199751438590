// Copyright 2021 Prescryptive Health, Inc.

import { call } from '../../call';
import { ILaneInfo, IServiceHours } from '../../../model/location-availability';
import { buildBearerAuthHeaders, buildUrl } from '../../api.helper';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { ILaneInfoRequest, IServiceHoursRequest } from './lane-availability-update.request';
import { HttpStatusCodesEnum } from '../../http-status-codes';
import { ApiErrors } from '../../errors/api-errors';
import { ITimeRangeRequest } from '../location-availability-update/location-availability-update.request';
import { ITimeRangeAndTypes } from '../../../model/availability-time';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export async function laneAvailabilityUpdate(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  availabilityId: string,
  lanes: ILaneInfo[]
): Promise<void> {
  const url = buildUrl(apiConfig, 'laneAvailabilityUpdate', {
    ':availabilityId': availabilityId,
  });

  const headers = buildBearerAuthHeaders(authToken);

  const mapTimeRangesAndTypes = (timesAndTypes: ITimeRangeAndTypes): ITimeRangeRequest => {
    return {
      startHour: timesAndTypes.startTime.hour,
      startMinute: timesAndTypes.startTime.minute,
      endHour: timesAndTypes.endTime.hour,
      endMinute: timesAndTypes.endTime.minute,
      serviceTypes: timesAndTypes.serviceTypes,
    };
  };

  const mapServiceHours = (hoursAndServices: IServiceHours): IServiceHoursRequest => {
    return {
      timeRanges: hoursAndServices.timeRanges.map(mapTimeRangesAndTypes),
    };
  };

  const mapLanesToRequest = (lane: ILaneInfo): ILaneInfoRequest => {
    return {
      displayName: lane.displayName,
      emailAddress: lane.emailAddress,
      serviceHours: lane.serviceHours.map(mapServiceHours),
    };
  };

  const body: ILaneInfoRequest[] = lanes.map(mapLanesToRequest);

  const response: Response = await call(url, body, 'PUT', headers);
  if (response.ok) {
    return;
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.LANE_AVAILABILITY_UPDATE,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  throw new PharmacyPortalError(
    ApiErrors.laneAvailabilityUpdate(availabilityId, response.statusText),
    ApiErrorCode.LANE_AVAILABILITY_UPDATE,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}
