// Copyright 2021 Prescryptive Health, Inc.

import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { LocationDispatch } from '../dispatch/location.dispatch';
import { locationServicesAreStaleDispatch } from '../dispatch/location-services-are-stale.dispatch';
import { IServiceQuestion } from '../../../model/pharmacy-service';
import { pharmacyServiceQuestionUpsert } from '../../../api/v1/pharmacy-service-question-upsert/pharmacy-service-question-upsert';
import { logApiError } from '../../../api/errors/log-api-error';

export interface IPharmacyServiceQuestionUpsertAsyncActionArgs extends IApiAsyncActionArgs {
  locationId: string;
  serviceType: string;
  question: IServiceQuestion;
  locationDispatch: LocationDispatch;
}

export const pharmacyServiceQuestionUpsertAsyncAction = async (
  args: IPharmacyServiceQuestionUpsertAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IPharmacyServiceQuestionUpsertAsyncActionArgs): Promise<void> => {
  const { locationId, serviceType, question, telemetryService, locationDispatch, getAuthToken } =
    args;

  try {
    telemetryService.trackEvent('pharmacy-service-question-upsert', {
      locationId,
      serviceType,
      question,
    });

    const { configState, authProvider } = args;

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    await pharmacyServiceQuestionUpsert(
      configState.apis.pharmacyPortal,
      accessToken,
      locationId,
      serviceType,
      question
    );

    locationServicesAreStaleDispatch(locationDispatch);
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
    return Promise.reject();
  }
};
