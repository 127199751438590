// Copyright 2020 Prescryptive Health, Inc.

export enum IconSize {
  smallest = 12,
  smaller = 16,
  small = 22,
  default = 24,
  medium = 32,
  big = 48,
  large = 160,
}

export interface ICustomIconProps {
  name: IconName;
  color?: string;
  size?: IconSize;
}

export type IconName =
  | 'account-multiple'
  | 'alert'
  | 'arrow-down'
  | 'arrow-up'
  | 'backspace'
  | 'barcode'
  | 'benefit'
  | 'brand'
  | 'brand-iso'
  | 'brand-logo'
  | 'brand-with-icon'
  | 'bullet'
  | 'calendar'
  | 'calendar-clock'
  | 'calendar-month'
  | 'calendar-week'
  | 'call'
  | 'cancel-appointment'
  | 'cart'
  | 'capsule'
  | 'cash-usd'
  | 'checkbox-off'
  | 'checkbox-on'
  | 'checkmark'
  | 'close'
  | 'credit-card'
  | 'cog'
  | 'dots-vertical'
  | 'download-file'
  | 'edit'
  | 'exit'
  | 'file-excel'
  | 'help'
  | 'idcard'
  | 'invite'
  | 'lock'
  | 'maps'
  | 'menu'
  | 'message'
  | 'money2'
  | 'no-show'
  | 'plus-circle'
  | 'print'
  | 'profile'
  | 'qrcode'
  | 'record'
  | 'remove'
  | 'report'
  | 'results'
  | 'search'
  | 'services'
  | 'support'
  | 'sync'
  | 'text'
  | 'time'
  | 'thumb'
  | 'top-menu'
  | 'trash'
  | 'user'
  | 'user-circle'
  | 'unlock'
  | 'upload-file'
  | 'upload'
  | 'vaccine'
  | 'sorting-arrow'
  | 'sorting-arrow-ascending'
  | 'sorting-arrow-descending'
  | 'science';
