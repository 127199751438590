// Copyright 2021 Prescryptive Health, Inc.

import { Box } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { StyledSectionContainer } from '../styled-components/section-container.styled-component';
import {
  StyledViewAllButton,
  StyledFlexBox,
  StyledGrayBox,
} from './what-you-will-get.styled-components';
import {
  ComponentProviderServiceContentTypesSectionHeading,
  ProviderProgramWhatYouGetDynamicZone,
  ProviderServiceWhatYouGetDynamicZone,
} from '../../../../../model/strapi/strapi-models';
import { useTelemetryContext } from '../../../../../providers/telemetry/use-telemetry-context.hook';
import { useProviderContext } from '../../../../../providers/provider/use-provider-context.hook';
import { TitleDescription } from '../../../../strapi/title-description/title-description';
import { SectionHeading } from '../../../../strapi/section-heading/section-heading';
import { useTranslation } from 'react-i18next';

export interface IWhatYouGetProps {
  whatYouGetData:
    | Maybe<ProviderServiceWhatYouGetDynamicZone | ProviderProgramWhatYouGetDynamicZone>[]
    | null
    | undefined;
}

export const WhatYouWillGet = ({ whatYouGetData }: IWhatYouGetProps): ReactElement => {
  const { t } = useTranslation();
  const { telemetryService } = useTelemetryContext();
  const {
    providerState: { currentProvider },
  } = useProviderContext();
  const [isHidden, setHidden] = useState(true);
  const onViewAllClick = () => {
    if (isHidden) {
      telemetryService.trackEvent('click', {
        name: 'add-services-detail-viewAllTextExpand',
        provider: currentProvider?.name,
      });
    }
    setHidden(!isHidden);
  };

  if (!whatYouGetData) {
    return <Box />;
  }

  const sectionHeading: Maybe<ProviderServiceWhatYouGetDynamicZone> | undefined =
    whatYouGetData.find(
      (d) => d?.__typename === 'ComponentProviderServiceContentTypesSectionHeading'
    ) as Maybe<ComponentProviderServiceContentTypesSectionHeading>;

  const descriptionsAndTitles = whatYouGetData.filter(
    (td) => td?.__typename === 'ComponentProviderServiceContentTypesTitleDescription'
  );

  const sections = descriptionsAndTitles
    .slice(0, isHidden ? MAX_POINTS_TO_SHOW : descriptionsAndTitles.length)
    .map((elem) => {
      switch (elem?.__typename) {
        case 'ComponentProviderServiceContentTypesTitleDescription': {
          return (
            <StyledFlexBox key={elem.id}>
              <TitleDescription
                title={elem.Title}
                description={elem.Description}
                url={elem.TitleDescriptionUrl}
              />
            </StyledFlexBox>
          );
        }
        default:
          return <Box />;
      }
    });

  if (!sectionHeading || descriptionsAndTitles.length === 0) {
    return <Box />;
  }

  return (
    <StyledSectionContainer>
      <SectionHeading headingText={sectionHeading.HeadingText} />
      <StyledGrayBox>
        <Box display='flex' flexWrap='wrap'>
          {sections}
        </Box>
        {descriptionsAndTitles.length > MAX_POINTS_TO_SHOW && (
          <StyledViewAllButton onClick={onViewAllClick}>
            {isHidden
              ? t('addService.whatYouWillGet.viewAllText')
              : t('addService.whatYouWillGet.viewLessText')}
          </StyledViewAllButton>
        )}
      </StyledGrayBox>
    </StyledSectionContainer>
  );
};

const MAX_POINTS_TO_SHOW = 6;
