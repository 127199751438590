// Copyright 2021 Prescryptive Health, Inc.

import { Box } from '@mui/material';
import { styled } from '@mui/material';

export const EmptyViewMessageContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '15%',
});
