// Copyright 2021 Prescryptive Health, Inc.

import { Box, Theme, Typography } from '@mui/material';
import styled from 'styled-components';

export const AppointmentPrintOutSection = styled(Box)`
  display: flex;
  margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing(6)};
`;

export const AppointmentPrintOutSectionTypography = styled(Typography)`
  margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing(4)};
  font-weight: bold;
`;

export const PharmacyInformationTypography = styled(Typography)`
  padding: ${({ theme }: { theme: Theme }) => theme.spacing(0.25)};
`;

export const AppointmentPrintOutInformationRowContainer = styled(Box)`
  display: flex;
  margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing(2)};
`;

export const AppointmentPrintOutTestInfoContainer = styled(Box)`
  display: flex;
  flex: 5;
  border-bottom-left-radius: ${({ theme }: { theme: Theme }) => `${theme.shape.borderRadius}px`};
  border-top-left-radius: ${({ theme }: { theme: Theme }) => `${theme.shape.borderRadius}px`};
  padding: ${({ theme }: { theme: Theme }) => `${theme.spacing(3)} ${theme.spacing(4)}`};
  border-color: ${({ theme }: { theme: Theme }) => theme.palette.grey['100']};
`;

export const AppointmentPrintOutTestResultContainer = styled(Box)`
  flex: 1;
  border-bottom-right-radius: ${({ theme }: { theme: Theme }) => `${theme.shape.borderRadius}px`};
  border-top-right-radius: ${({ theme }: { theme: Theme }) => `${theme.shape.borderRadius}px`};
  padding: ${({ theme }: { theme: Theme }) => `${theme.spacing(3)} ${theme.spacing(4)}`};
  background-color: ${({ theme }: { theme: Theme }) => theme.palette.grey['100']};
`;

export const AppointmentPrintOutProviderLabelTypography = styled(Typography)`
  width: 180px;
  margin-right: ${({ theme }: { theme: Theme }) => theme.spacing(8)};
`;

export const AppointmentPrintOutLabelTypography = styled(Typography)`
  width: 100px;
  margin-right: ${({ theme }: { theme: Theme }) => theme.spacing(8)};
`;

export const AppointmentPrintOutDetailTypography = styled(Typography)`
  font-weight: bold;
  margin-right: ${({ theme }: { theme: Theme }) => theme.spacing(8)};
  width: 140px;
`;
