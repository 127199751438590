// Copyright 2021 Prescryptive Health, Inc.

export const neitherNullNorEmpty = (text: string | undefined): string | undefined =>
  text && text?.trim().length > 0 ? text : undefined;

export const isFalsyOrWhiteSpace = (text: string | undefined): boolean =>
  Boolean(text) === false || text === undefined || text.trim().length < 1;

export const getEmailParts = (value: string | undefined): (string | undefined)[] => {
  const defaultParts = [undefined, undefined];
  const parts = value ? value.split('@') ?? defaultParts : defaultParts;

  if (parts?.length !== 2) {
    return [undefined, undefined];
  }
  const secondPart = parts[1] ? `@${parts[1]}` : undefined;
  return [parts[0], secondPart];
};
