// Copyright 2021 Prescryptive Health, Inc.

import { IStoreLocation } from '../../../model/store-location';
import { IProviderAction } from './provider.action';

export type ILocationAddAction = IProviderAction<
  'STORE_LOCATION_ADD',
  IStoreLocation
>;

export const locationAddAction = (
  storeLocation: IStoreLocation
): ILocationAddAction => {
  return {
    type: 'STORE_LOCATION_ADD',
    payload: storeLocation,
  };
};
