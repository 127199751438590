import { HttpStatusCodesEnum } from '../../http-status-codes';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { call } from '../../call';
import { ApiErrors } from '../../errors/api-errors';
import { IPaymentSetting } from '../../../model/pharmacy-service';
import { IPharmacyServicePaymentUpdateRequest } from './pharmacy-service-payment-update.request';
import { IErrorResponse } from '../../error-response';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export async function pharmacyServicePaymentUpdate(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  providerId: string,
  serviceType: string,
  paymentSettings: IPaymentSetting[],
  price: number | null
): Promise<void> {
  const url = buildUrl(apiConfig, 'pharmacyServicePaymentUpdate', {
    ':providerId': providerId,
    ':serviceType': serviceType,
  });

  const headers = buildBearerAuthHeaders(authToken);
  const body: IPharmacyServicePaymentUpdateRequest = {
    paymentSettings,
    price,
  };
  const response: Response = await call(url, body, 'POST', headers);
  if (response.ok) {
    return;
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.PHARMACY_SERVICE_PAYMENT_UPDATE,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  if (response.status === HttpStatusCodesEnum.BAD_REQUEST) {
    const errorResponse = (await response.json()) as IErrorResponse;
    errorResponse.status = response.status;
    throw errorResponse;
  }

  throw new PharmacyPortalError(
    ApiErrors.pharmacyServicePaymentUpdate(response.statusText),
    ApiErrorCode.PHARMACY_SERVICE_PAYMENT_UPDATE,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}
