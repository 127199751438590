import { Auth0Provider } from '@auth0/auth0-react';
import { ReactChild } from 'react';
import { useNavigate } from 'react-router-dom';
import getEnvironmentVariable from '../../helpers/environment-variables/get-environment-variable';

interface Props {
  children?: ReactChild;
}

export default function AuthProvider(props: Props) {
  const navigate = useNavigate();
  return (
    <Auth0Provider
      domain={getEnvironmentVariable('REACT_APP_AUTH0_DOMAIN')}
      audience={getEnvironmentVariable('REACT_APP_AUTH0_AUDIENCE')}
      clientId={getEnvironmentVariable('REACT_APP_AUTH0_CLIENT_ID')}
      redirectUri={window.location.origin}
      onRedirectCallback={(appState) => {
        navigate(appState?.returnTo || window.location.href.replace(window.location.origin, ''));
      }}
      useRefreshTokens
    >
      {props.children}
    </Auth0Provider>
  );
}
