// Copyright 2021 Prescryptive Health, Inc.

import { Box } from '@mui/material';
import { styled as muiStyled } from '@mui/material';

export const StyledCardContainer = muiStyled(Box)(({ theme }) => ({
  display: 'grid',
  gridGap: `${theme.spacing(4)} ${theme.spacing(4)}`,
  gridTemplateColumns: 'repeat(auto-fill, 312px)',
}));

export const StyledContentBox = muiStyled(Box)(({ theme }) => ({
  overflow: 'auto scroll',
  padding: theme.spacing(6),
  [theme.breakpoints.up('desktop')]: {
    padding: theme.spacing(12),
  },
}));
