// Copyright 2021 Prescryptive Health, Inc.

import { ChangeEvent, ReactElement, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLocationContext } from '../../../providers/location/use-location-context.hook';
import { useNotificationContext } from '../../../hooks/use-notification-context/use-notification-context.hook';
import { notificationSetDispatch } from '../../../state/notification/dispatch/notification-set.dispatch';
import { usePostAuthScreenContext } from '../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { QuestionCardEditList } from './content/edit-list/question-card-edit-list';
import { QuestionCardIconLabel } from './content/icon-label/question-card-icon-label';
import { QuestionCardList } from './content/list/question-card-list';
import { DeleteServiceQuestionConfirmationModal } from '../../screens/services/modals/delete-service-question/delete-service-question.confirmation-modal';
import { pharmacyServiceQuestionUpdate } from '../../../state/location/actions/pharmacy-services-question-update.action';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import {
  IServiceQuestion,
  ServiceQuestionType,
  IServiceQuestionSelectOption,
} from '../../../model/pharmacy-service';
import {
  IPharmacyServiceQuestionUpsertAsyncActionArgs,
  pharmacyServiceQuestionUpsertAsyncAction,
} from '../../../state/location/async-actions/pharmacy-service-question-upsert.async-action';
import ReactMarkdown from 'react-markdown';
import { getToken } from '../../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

export interface IQuestionCardProps {
  id: string;
  questionText: string;
  isRequired: boolean;
  type: ServiceQuestionType;
  selectOptions?: IServiceQuestionSelectOption[];
  serviceType: string;
  isEditMode: boolean;
  toggleEditMode: (id: string, value: boolean) => void;
  onRemovePress: (questionId: string) => void;
}

export const QuestionCard = (props: IQuestionCardProps): ReactElement => {
  const { t } = useTranslation();
  const {
    id,
    questionText,
    isRequired,
    type,
    selectOptions,
    serviceType,
    onRemovePress,
    isEditMode,
    toggleEditMode,
  } = props;

  const { busyDispatch, errorDispatch, telemetryService, authProvider, configState } =
    usePostAuthScreenContext({ defaultContent: {} });

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const theme = useTheme();

  const {
    locationDispatch,
    locationState: { location },
  } = useLocationContext();

  const { notificationDispatch } = useNotificationContext();

  const [editedIsRequired, setEditedIsRequired] = useState(isRequired);
  const [editedQuestionText, setEditedQuestionText] = useState<string>(questionText);
  const [editedQuestionType, setEditedQuestionType] = useState<ServiceQuestionType>(type);
  const [editedAnswerList, setEditedAnswerList] = useState<IServiceQuestionSelectOption[]>(
    selectOptions || []
  );

  const statusText = isRequired
    ? t('services.questionCard.requiredText')
    : t('services.questionCard.optionalText');

  const initialSingleSelectValues: IServiceQuestionSelectOption[] = [
    { id: uuidv4(), text: t('services.questionCard.singleSelectYes') },
    { id: uuidv4(), text: t('services.questionCard.singleSelectNo') },
  ];

  const initialMultiSelectValues: IServiceQuestionSelectOption[] = [
    { id: uuidv4(), text: t('services.questionCard.multiSelectChoice') },
  ];

  const isRemoveDisabled =
    (editedQuestionType === 'single-select' && editedAnswerList.length <= 2) ||
    (editedQuestionType === 'multi-select' && editedAnswerList.length === 1);

  const onEditPress = () => {
    setEditedIsRequired(isRequired);
    setEditedQuestionText(questionText);
    setEditedQuestionType(type);
    setEditedAnswerList(selectOptions || []);
    toggleEditMode(id, true);
  };

  const onCancelPress = () => {
    toggleEditMode(id, false);
  };

  const onQuestionTypeSelect = (event: SelectChangeEvent) => {
    const newQuestionType = event.target.value as ServiceQuestionType;
    setEditedQuestionType(newQuestionType);
    if (newQuestionType === 'single-select') {
      setEditedAnswerList(initialSingleSelectValues);
    }
    if (newQuestionType === 'multi-select') {
      setEditedAnswerList(initialMultiSelectValues);
    }
  };

  const onQuestionTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditedQuestionText(event.target.value as string);
  };

  const onRequiredChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditedIsRequired(event.target.checked);
  };

  const onAnswerListChange = (values: IServiceQuestionSelectOption[]) => {
    setEditedAnswerList(values);
  };

  const onSavePress = async () => {
    if (!location) {
      return;
    }

    const answerList =
      editedQuestionType === 'single-select' || editedQuestionType === 'multi-select'
        ? editedAnswerList
        : undefined;

    const editedQuestion: IServiceQuestion = {
      id,
      isRequired: editedIsRequired,
      questionText: editedQuestionText,
      type: editedQuestionType,
      selectOptions: answerList,
    };

    const args: IPharmacyServiceQuestionUpsertAsyncActionArgs = {
      locationDispatch,
      authProvider,
      locationId: location?.id,
      serviceType,
      question: editedQuestion,
      busyDispatch,
      configState,
      errorDispatch,
      telemetryService,
      getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
    };

    try {
      await pharmacyServiceQuestionUpsertAsyncAction(args);
      locationDispatch(pharmacyServiceQuestionUpdate(serviceType, editedQuestion));
      toggleEditMode(editedQuestion.id, false);
      notificationSetDispatch(
        notificationDispatch,
        t('services.questionCard.questionEditSuccessMessage')
      );
    } catch {
      return;
    }
  };

  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const closeRemoveDialog = () => {
    setIsRemoveDialogOpen(false);
  };
  const openRemoveDialog = () => {
    setIsRemoveDialogOpen(true);
  };
  const onRemoveConfirm = () => {
    closeRemoveDialog();
    onRemovePress(id);
  };

  const renderButtons = () => {
    if (isEditMode) {
      return (
        <Box display='flex' flexWrap='wrap' flexShrink={1}>
          <Button
            key='save-question-button'
            color='primary'
            variant='text'
            onClick={onSavePress}
            startIcon={<CheckCircleIcon />}
          >
            {t('services.questionCard.saveLabel')}
          </Button>
          <Button
            key='cancel-question-button'
            color='primary'
            variant='text'
            onClick={onCancelPress}
            startIcon={<CloseIcon />}
          >
            {t('services.questionCard.cancelLabel')}
          </Button>
        </Box>
      );
    }
    return (
      <Box display='flex' flexWrap='wrap' flexShrink={1}>
        <Button
          key='edit-question-button'
          color='primary'
          variant='text'
          onClick={onEditPress}
          startIcon={<EditIcon />}
        >
          {t('services.questionCard.editLabel')}
        </Button>
        <Button
          key='remove-question-button'
          color='primary'
          variant='text'
          onClick={openRemoveDialog}
          startIcon={<DeleteOutlineIcon />}
        >
          {t('services.questionCard.removeLabel')}
        </Button>
      </Box>
    );
  };

  const renderQuestionAndIsRequiredMode = isEditMode ? (
    <Box display='flex' flexWrap='wrap' alignItems='center' marginBottom={theme.spacing(6)}>
      <TextField
        value={editedQuestionText}
        onChange={onQuestionTextChange}
        variant='outlined'
        style={{ flexGrow: 1, flexShrink: 1, maxWidth: '596px' }}
      />
      <Box display='flex' alignItems='center' alignSelf='center' padding={theme.spacing(4)}>
        <FormControlLabel
          control={
            <Checkbox
              checked={editedIsRequired}
              onChange={onRequiredChange}
              name={t('services.questionCard.requiredText')}
              color='primary'
            />
          }
          label={
            <Typography style={{ fontWeight: 'bold' }}>
              {t('services.questionCard.requiredText')}
            </Typography>
          }
        />
      </Box>
    </Box>
  ) : (
    <Box
      display='flex'
      alignContent='center'
      justifyContent='space-between'
      flexWrap='wrap'
      flexGrow={1}
      marginBottom={theme.spacing(6)}
    >
      <Box marginRight={theme.spacing(4)} marginBottom={theme.spacing(4)} maxWidth={600}>
        <Typography>
          <ReactMarkdown>{questionText}</ReactMarkdown>
        </Typography>
      </Box>
      <Typography style={{ fontWeight: 'bold' }}>{statusText}</Typography>
    </Box>
  );

  const renderEditableAnswers = () => {
    if (editedQuestionType === 'single-select' || editedQuestionType === 'multi-select') {
      return (
        <QuestionCardEditList
          answerList={editedAnswerList}
          onEdit={onAnswerListChange}
          isRemoveDisabled={isRemoveDisabled}
        />
      );
    }
    return null;
  };

  const renderReadOnlyContent = () => {
    if (type === 'text') {
      return (
        <QuestionCardIconLabel iconName={'text'} label={t('services.questionCard.textTypeLabel')} />
      );
    }

    if (type === 'datepicker') {
      return (
        <QuestionCardIconLabel
          iconName={'calendar'}
          label={t('services.questionCard.dateTypeLabel')}
        />
      );
    }

    if (type === 'single-select') {
      return <QuestionCardList questionType={'numeric'} values={selectOptions ?? []} />;
    }

    if (type === 'multi-select') {
      return <QuestionCardList questionType={'checkbox'} values={selectOptions ?? []} />;
    }

    return null;
  };

  const renderEditOrReadOnly = isEditMode ? (
    <Box display='flex' flexDirection='column' gap={theme.spacing(4)}>
      <FormControl>
        <InputLabel id='question-card-label'>
          {t('services.questionCard.questionPickerLabel')}
        </InputLabel>
        <Select
          id='question-card-select'
          value={editedQuestionType.toString()}
          onChange={onQuestionTypeSelect}
        >
          <MenuItem value='text'>{t('services.questionCard.textTypeLabel')}</MenuItem>
          <MenuItem value='single-select'>{t('services.questionCard.singleTypeLabel')}</MenuItem>
          <MenuItem value='multi-select'>{t('services.questionCard.multiTypeLabel')}</MenuItem>
          <MenuItem value='datepicker'>{t('services.questionCard.dateTypeLabel')}</MenuItem>
        </Select>
      </FormControl>
      {renderEditableAnswers()}
    </Box>
  ) : (
    renderReadOnlyContent()
  );

  return (
    <Box
      display='flex'
      flexDirection='column'
      bgcolor={theme.palette.grey.A100}
      padding={`${theme.spacing(6)} ${theme.spacing(4)}`}
      marginTop={theme.spacing(4)}
      component='section'
    >
      <Box
        display='flex'
        flexWrap='wrap'
        flexGrow={1}
        justifyContent='space-between'
        alignItems='center'
        marginBottom={theme.spacing(4)}
      >
        <Typography variant='h3' component='h3'>
          {t('services.questionCard.questionText')}
        </Typography>
        {renderButtons()}
      </Box>
      {renderQuestionAndIsRequiredMode}
      {renderEditOrReadOnly}
      <DeleteServiceQuestionConfirmationModal
        isOpen={isRemoveDialogOpen}
        questionText={questionText}
        onKeepPress={closeRemoveDialog}
        onConfirmedPress={onRemoveConfirm}
      />
    </Box>
  );
};
