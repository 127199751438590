// Copyright 2021 Prescryptive Health, Inc.

import dateFormatter from '../../../../../formatters/dates/date.formatter';
import { toCsvSafeString } from '../../../../../helpers/csv/csv.helper';
import { isoDateStringToLocalDate } from '../../../../../helpers/dates/date-helper/date.helper';
import { phoneNumberSplit } from '../../../../../helpers/phone-numbers/phone-number.helper';
import {
  IAppointmentGetResponse,
  IAppointmentGetResponseProcedureResultsQuestionAndAnswer,
} from '../../../appointment-get/appointment-get.response';
import { IAppointmentsGetResponse } from '../../appointments-get.response';
import {
  AppointmentsContentProvider,
  AppointmentsGetResultStrategy,
} from '../appointments-get.result-strategy';
import { ICsvRows } from '../../../../../model/csv-rows';

type CsvAppointmentsResultStrategy = AppointmentsGetResultStrategy<ICsvRows>;

export const csvAppointmentsResultStrategy: CsvAppointmentsResultStrategy = (
  responseData: IAppointmentsGetResponse,
  contentProvider?: AppointmentsContentProvider
) => {
  const appointmentsCsvFromResponse = (
    appointmentResponse: Partial<IAppointmentGetResponse>
  ): string => {
    const {
      orderNumber,
      bookingStatus,
      firstName,
      lastName,
      dateOfBirth,
      gender,
      phoneNumber,
      address1,
      address2,
      city,
      state,
      zip,
      county,
      serviceType,
      scheduledStart,
      memberId,
      personCode,
      rxBin,
      rxPcn,
      rxGroup,
      productOrService,
      questions,
      procedureResults,
    } = appointmentResponse;

    const formattedBirthDate = dateOfBirth
      ? dateFormatter.formatToYMD(isoDateStringToLocalDate(dateOfBirth), '')
      : '';
    const [areaCode, sevenDigitPhoneNumber] = phoneNumberSplit(phoneNumber ?? '');
    const formattedPhoneNumber = `${areaCode}${sevenDigitPhoneNumber}`;

    const serviceName = contentProvider ? contentProvider('service-name', serviceType) : '';

    const scheduledStartUtc = scheduledStart ? new Date(scheduledStart) : undefined;
    const formattedStartDate = scheduledStartUtc
      ? dateFormatter.formatToYMD(scheduledStartUtc, '')
      : '';
    const formattedStartTime = scheduledStartUtc
      ? dateFormatter.formatToTime(scheduledStartUtc, '24-hour')
      : '';
    const timeZone = scheduledStartUtc ? dateFormatter.timeZone(scheduledStartUtc) : '';

    const appointmentStatus =
      bookingStatus === 'Completed'
        ? 'Adjudicated'
        : bookingStatus === 'Cancelled'
        ? 'Cancelled'
        : bookingStatus === 'Confirmed'
        ? 'Scheduled'
        : '';

    const isFlushotServiceType =
      serviceType === 'influenza-flu-shot' || serviceType === 'influenza-intranasal-flu';

    const vaccineAdministered = isFlushotServiceType
      ? getProcedureResultAnswerText(procedureResults, 'immunization')
      : '';
    const lotNumber = isFlushotServiceType
      ? getProcedureResultAnswerText(procedureResults, 'lot-number')
      : '';

    const rowElements: string[] = [];
    rowElements.push(appointmentStatus);
    rowElements.push(orderNumber ?? '');
    rowElements.push(toCsvSafeString(firstName ?? ''));
    rowElements.push(''); // No middle name in response
    rowElements.push(toCsvSafeString(lastName ?? ''));
    rowElements.push(formattedBirthDate);
    rowElements.push(toCsvSafeString(gender ?? ''));
    rowElements.push(toCsvSafeString(address1 ?? ''));
    rowElements.push(toCsvSafeString(address2 ?? ''));
    rowElements.push(toCsvSafeString(city ?? ''));
    rowElements.push(toCsvSafeString(state ?? ''));
    rowElements.push(toCsvSafeString(zip ?? ''));
    rowElements.push(toCsvSafeString(county ?? ''));
    rowElements.push(formattedPhoneNumber);
    rowElements.push(toCsvSafeString(memberId ?? ''));
    rowElements.push(toCsvSafeString(personCode ?? ''));
    rowElements.push(toCsvSafeString(rxBin ?? ''));
    rowElements.push(toCsvSafeString(rxPcn ?? ''));
    rowElements.push(toCsvSafeString(rxGroup ?? ''));
    rowElements.push(formattedStartDate);
    rowElements.push(formattedStartTime);
    rowElements.push(timeZone);
    rowElements.push(toCsvSafeString(serviceName));
    rowElements.push(toCsvSafeString(productOrService ?? ''));
    rowElements.push(toCsvSafeString(orderNumber ?? ''));
    rowElements.push(toCsvSafeString(vaccineAdministered ?? ''));
    rowElements.push(toCsvSafeString(lotNumber ?? ''));

    const numberOfPlaceholders = headers.length - rowElements.length - 1;

    for (let index = 0; index < numberOfPlaceholders; index++) {
      rowElements.push(toCsvSafeString(''));
    }

    if (questions && questions.length > 0) {
      questions.forEach((question) => {
        const headerQuestionIndex = headerIndex[question.questionId];
        if (headerQuestionIndex) {
          rowElements[headerQuestionIndex] = toCsvSafeString(question.answer);
        }
      });
    }

    return rowElements.join(',');
  };

  const headers = [
    'Appointment status',
    'Record ID',
    'First name',
    'Middle name',
    'Last name',
    'Birth date',
    'Gender',
    'Street address',
    'Street address 2',
    'City',
    'State',
    'Zip',
    'County',
    'Phone number',
    'Member ID',
    'Person code',
    'Rx BIN',
    'Rx PCN',
    'Rx group',
    'Appointment date',
    'Appointment time',
    'Time zone',
    'Service',
    'Service ID',
    'Order number',
    'Vaccine Administered',
    'Lot Number',
  ];

  const headerIndex: { [key: string]: number } = {};

  const appointments = responseData.appointments;

  if (appointments && appointments.length > 0) {
    appointments.forEach((appointment) => {
      if (appointment.questions && appointment.questions.length > 0) {
        appointment.questions.forEach((question) => {
          if (!headerIndex[question.questionId]) {
            const newIndex = headers.push(toCsvSafeString(question.questionText)) - 1;
            const questionId = question.questionId;
            headerIndex[questionId] = newIndex;
          }
        });
      }
    });
  }

  const appointmentsCsv = appointments.map(appointmentsCsvFromResponse);

  if (appointmentsCsv.length > 0) {
    appointmentsCsv.unshift(headers.join(','));
  }

  return { rows: appointmentsCsv };
};

const getProcedureResultAnswerText = (
  procedureResults: IAppointmentGetResponseProcedureResultsQuestionAndAnswer[] | undefined,
  questionId: string
): string => procedureResults?.find((x) => x.questionId == questionId)?.answerText ?? '';
