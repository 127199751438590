// Copyright 2021 Prescryptive Health, Inc.

import { IReportsAction } from './reports.action';

export type IReportsClearAction = IReportsAction<'CLEAR'>;

export const reportsClearAction = (): IReportsClearAction => ({
  type: 'CLEAR',
  payload: {
    reportCsv: [],
    reportFileName: undefined,
    isReportReady: false,
  },
});
