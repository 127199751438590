// Copyright 2021 Prescryptive Health, Inc.

import { fatalErrorDispatch } from '../../../error/dispatch/fatal-error.dispatch';
import { showBusyWhile } from '../../../busy/dispatch/show-busy-while';
import { IWaitlistInvitation } from '../../../../model/waitlist-invitation';
import { WaitlistDispatch } from '../dispatch/waitlist.dispatch';
import { IApiAsyncActionArgs } from '../../../api-async-action-args';
import { AuthenticationProviderUndefinedError } from '../../../../api/errors/authentication-provider-undefined.error';
import { waitlistInvite } from '../../../../api/v1/waitlist-invite/waitlist-invite';
import { waitlistIsStaleDispatch } from '../dispatch/waitlist-is-stale.dispatch';
import { logApiError } from '../../../../api/errors/log-api-error';

export interface IWaitlistInviteAsyncActionArgs extends IApiAsyncActionArgs {
  locationId: string;
  invitations: IWaitlistInvitation[];
  waitlistDispatch: WaitlistDispatch;
}

export const waitlistInviteAsyncAction = async (
  args: IWaitlistInviteAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IWaitlistInviteAsyncActionArgs): Promise<void> => {
  const { locationId, invitations, telemetryService, waitlistDispatch, getAuthToken } = args;

  try {
    telemetryService.trackEvent('waitlist-invite', {
      locationId,
      invitations,
    });

    const { configState, authProvider } = args;

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    const batchStatus = await waitlistInvite(
      configState.apis.pharmacyPortal,
      accessToken,
      locationId,
      invitations
    );

    waitlistIsStaleDispatch(waitlistDispatch, batchStatus);
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
    return Promise.reject();
  }
};
