// Copyright 2020 Prescryptive Health, Inc.

import React, { FunctionComponent } from 'react';
import { AppScreenContainer } from '../app/app.screen-container';
import { SecondaryScreenChildContainer } from './secondary.screen-container.styled-components';

export interface ISecondaryScreenContainerProps {
  dialogModal?: React.ReactNode;
}

export const SecondaryScreenContainer: FunctionComponent<ISecondaryScreenContainerProps> = ({
  dialogModal,
  children,
}) => {
  return (
    <AppScreenContainer display='flex' flexDirection='column' dialogModals={dialogModal}>
      <SecondaryScreenChildContainer>{children}</SecondaryScreenChildContainer>
    </AppScreenContainer>
  );
};
