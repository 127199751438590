// Copyright 2020 Prescryptive Health, Inc.

import { IAppointmentsAction } from './appointments.action';

export type IAppointmentsClearAction =
  IAppointmentsAction<'APPOINTMENTS_CLEAR'>;

export const appointmentsClearAction = (): IAppointmentsClearAction => ({
  type: 'APPOINTMENTS_CLEAR',
  payload: {
    appointments: [],
    blockedSlots: [],
    appointmentsAreStale: false,
    appointmentDetailsMap: {},
  },
});
