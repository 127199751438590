// Copyright 2020 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import { SignInScreen } from '../../../components/screens/lander/sign-in.screen';
import { Route, Routes } from 'react-router-dom';
import { unauthenticatedRouteConfig } from '../../unauthenticated-route-config';
import { Main } from './pre-auth.stack-navigator.styled-components';
import { HighFrequencyLandingPage } from '../../../components/screens/lander/high-frequency-page/high-frequency-landing-page';
import { DrugSearchScreen } from '../../../components/screens/drug-search/drug-search.screen';
import { DrugSearchConfigContextProvider } from '../../../providers/drug-search-config/drug-search-config.context-provider';
import { LearnMoreScreen } from '../../../components/screens/learn-more/learn-more.screen';
import { Box } from '@mui/material';
import { PrescryptiveProLogo } from '../../../components/prescryptivepro-logo/prescryptivepro-logo';

export const PreAuthStackNavigator = (): ReactElement => {
  return (
    <Main>
      <Routes>
        <Route path={unauthenticatedRouteConfig.root.route} element={<SignInScreen />} />
        <Route path='*' element={<SignInScreen />} />
        <Route
          path={unauthenticatedRouteConfig.signIn.route}
          element={<HighFrequencyLandingPage />}
        />
        <Route
          path={unauthenticatedRouteConfig.drugSearch.route}
          element={
            <DrugSearchConfigContextProvider>
              <DrugSearchScreen />
            </DrugSearchConfigContextProvider>
          }
        />
        <Route
          path={unauthenticatedRouteConfig.learnMore.route}
          element={
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              maxWidth='900px'
              margin='auto'
            >
              <Box px={6} pt={8}>
                <PrescryptiveProLogo />
              </Box>
              <LearnMoreScreen />
            </Box>
          }
        />
      </Routes>
    </Main>
  );
};
