// Copyright 2021 Prescryptive Health, Inc.

import { ReactNode } from 'react';
import { nonFatalErrorSetAction } from '../actions/non-fatal-error-set.action';
import { ErrorDispatch } from './error.dispatch';

export const nonFatalErrorDispatch = (
  dispatch: ErrorDispatch,
  nonFatalErrorTitle: string,
  nonFatalErrorContent: ReactNode
): void => {
  dispatch(nonFatalErrorSetAction(nonFatalErrorTitle, nonFatalErrorContent));
};
