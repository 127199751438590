// Copyright 2020 Prescryptive Health, Inc.

import { AuthenticationProvider } from '../../state/authentication/authentication-provider';

export interface IPostAuthTelemetry {
  roles?: string;
  username?: string;
}

export const postAuthTelemetry = (authProvider?: AuthenticationProvider): IPostAuthTelemetry => ({
  roles: authProvider?.getRoles(),
  username: authProvider?.getAccountName(),
});
