// Copyright 2020 Prescryptive Health, Inc.

import React, { FunctionComponent, ReactNode } from 'react';
import { useBusyContext } from '../../../hooks/use-busy-context/use-busy-context.hook';
import { ErrorModal } from '../../modals/error/error.modal';
import { BusyOverlay } from '../../overlays/busy/busy.overlay';
import { Box, BoxProps } from '@mui/material';

export interface IAppScreenContainerProps extends BoxProps {
  dialogModals?: ReactNode;
}

export const AppScreenContainer: FunctionComponent<IAppScreenContainerProps> =
  ({ children, dialogModals, ...other }) => {
    const { busyState } = useBusyContext();

    return (
      <Box {...other}>
        {children}
        {dialogModals}
        <ErrorModal />
        <BusyOverlay isOpen={busyState.busyCount > 0} />
      </Box>
    );
  };
