// Copyright 2020 Prescryptive Health, Inc.

import React, { ReactElement, useEffect, useState } from 'react';
import { Snackbar, SnackbarProps } from '@mui/material';
import { getNewDate } from '../../helpers/dates/get-new-date/get-new-date';
import { Alert, AlertColor } from '@mui/material';

interface IMessagePanelProps extends SnackbarProps {
  readonly message?: string;
  readonly severity?: AlertColor;
}

export const MessagePanel = ({
  message,
  severity = 'success',
  ...otherProps
}: IMessagePanelProps): ReactElement => {
  if (!message) {
    return <></>;
  }

  const [isOpen, setIsOpen] = useState(false);
  const [key, setKey] = useState<string | undefined>(undefined);

  useEffect(() => {
    setIsOpen(true);
    setKey(message + getNewDate().toISOString());
  }, [message]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Snackbar
      key={key}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isOpen}
      autoHideDuration={4000}
      onClose={handleClose}
      {...otherProps}
    >
      <Alert severity={severity} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};
