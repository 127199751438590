// Copyright 2021 Prescryptive Health, Inc.

import { ProviderUser } from '../../../model/provider-user';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { buildBearerAuthHeaders, buildUrl } from '../../api.helper';
import { call } from '../../call';
import { IErrorResponse } from '../../error-response';
import { ApiErrorCode } from '../../errors/api-error-code';
import { ApiErrors } from '../../errors/api-errors';
import { HttpStatusCodesEnum } from '../../http-status-codes';
import { IProviderUsersUpdateRequest } from './provider-users-update.request';

export async function providerUsersUpdate(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  providerName: string,
  userId: string,
  providerUser: ProviderUser
): Promise<void> {
  const url = buildUrl(apiConfig, 'providerUsersUpdate', {
    ':providerName': providerName,
    ':userId': userId,
  });
  const headers = buildBearerAuthHeaders(authToken);

  const body: IProviderUsersUpdateRequest = {
    firstName: providerUser.firstName,
    lastName: providerUser.lastName,
    phoneNumber: providerUser.phoneNumber,
    npi: providerUser.npi,
    contactEmail: providerUser.contactEmail,
    role: providerUser.role,
    isActive: providerUser.isActive,
  };

  const response: Response = await call(url, body, 'PATCH', headers);
  if (response.ok) {
    return;
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.PROVIDER_USERS_UPDATE,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  if (response.status === HttpStatusCodesEnum.BAD_REQUEST) {
    const errorResponse = (await response.json()) as IErrorResponse;
    errorResponse.status = response.status;
    throw errorResponse;
  }

  throw new PharmacyPortalError(
    ApiErrors.providerUsersUpdate(providerName, userId, response.statusText),
    ApiErrorCode.PROVIDER_USERS_UPDATE,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}
