// Copyright 2020 Prescryptive Health, Inc.

import { createContext } from 'react';
import { ProviderDispatch } from '../../state/provider/dispatch/provider.dispatch';
import {
  IProviderState,
  defaultProviderState,
} from '../../state/provider/provider.state';

export interface IProviderContext {
  readonly providerState: IProviderState;
  readonly providerDispatch: ProviderDispatch;
}

export const ProviderContext = createContext<IProviderContext>({
  providerState: defaultProviderState,
  providerDispatch: () => {
    return;
  },
});
