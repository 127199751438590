import { ReactElement, useState, useEffect, useCallback, SetStateAction, ReactNode } from 'react';
import { PostAuthPrimaryScreenContainer } from '../../screen-containers/post-auth-primary/post-auth-primary.screen-container';
import { appointmentsClearDispatch } from '../../../state/appointments/dispatch/appointments-clear.dispatch';
import { BlockTimeModal } from '../../modals/block-time/block-time.modal';
import { useNotificationContext } from '../../../hooks/use-notification-context/use-notification-context.hook';
import { notificationSetDispatch } from '../../../state/notification/dispatch/notification-set.dispatch';
import { appointmentsAreStaleDispatch } from '../../../state/appointments/dispatch/appointments-are-stale.dispatch';
import { useLocationContext } from '../../../providers/location/use-location-context.hook';
import { useAppointmentsContext } from '../../../providers/appointments/use-appointments-context.hook';
import { EmptyViewMessage } from '../../text/messages/empty-view.message';
import { getNewDate } from '../../../helpers/dates/get-new-date/get-new-date';
import { AgendaDayView } from '../../agenda-day-view/agenda-day-view';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import DateFormatter from '../../../formatters/dates/date.formatter';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { locationSelectedDateSetDispatch } from '../../../state/location/dispatch/location-selected-date-set.dispatch';
import { usePostAuthScreenContext } from '../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { localStorageKeys } from '../../../constants/local-storage-keys';
import {
  calendarScheduleGetAsyncAction,
  ICalendarScheduleGetAsyncActionArgs,
} from '../../../state/appointments/async-actions/schedule-get.async-action';
import {
  Box,
  IconButton,
  Typography,
  TextField,
  Skeleton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  useTheme,
} from '@mui/material';
import {
  StyledAgendaViewContainer,
  StyledSwitchContainer,
  StyledControlsContainer,
  StyledAppointmentsDivider,
  StyledLastSync,
  StyledBlockTimeContainer,
  StyledSyncButton,
  SpinnerLastSync,
  StyledMainSection,
} from './appointments.screen.styled-components';
import { AppointmentCreateOptions } from './appointment-create-options/appointment-create-options';
import { InviteForAppointmentModal } from '../../modals/invite-for-appointment/invite-for-appointment.modal';
import { useTranslation } from 'react-i18next';
import pharmacyPortalServiceGraphQLApolloClient from '../../../init-pharmacy-portal-service-graphql-client';
import pharmacyPortalServiceGraphQLApolloClientAAD from '../../../init-pharmacy-portal-service-graphql-client-aad';
import {
  CREATE_BLOCK_TIME_MUTATION,
  ICreateBlockTimeVariables,
} from './create-block-time.mutation';
import { useMutation } from '@apollo/client';
import { getToken } from '../../../helpers/http-client/get-token';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

export enum AppointmentFilterType {
  ALL_APPOINTMENTS = 'allAppointments',
  UNRECORDED = 'unrecorded',
  RECORDED = 'recorded',
}

export const AppointmentsScreen = (): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const { busyDispatch, errorDispatch, telemetryService, authProvider, configState, busyState } =
    usePostAuthScreenContext({ defaultContent: {} });

  document.title = t('appointments.appointmentsScreen.pageTitle');

  const getCurrentTime = () => {
    const now = getNewDate();
    return `${DateFormatter.formatDateToTime(now)} ${DateFormatter.timeZone(now)}`;
  };

  const fiveMinuteTimeout = 300000;

  const [CreateBlockTime, { error: createBlockTimeError, loading: createBlockTimeLoading }] =
    useMutation(CREATE_BLOCK_TIME_MUTATION, {
      client: auth0Migration
        ? pharmacyPortalServiceGraphQLApolloClient
        : pharmacyPortalServiceGraphQLApolloClientAAD,
    });

  const {
    locationState: { location, locationSelectedDate: selectedDate },
    locationDispatch,
  } = useLocationContext();

  const [isOpenBlocktimeModal, setIsOpenBlocktimeModal] = useState<boolean>(false);
  const [isOpenInviteForAppointmentModal, setIsOpenInviteForAppointmentModal] =
    useState<boolean>(false);
  const [isAppointmentLoading, isAppointmentLoadingSet] = useState<boolean>(false);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const [lastSyncedTime, setLastSyncedTime] = useState<string>(getCurrentTime());
  const [currentTimeout, setCurrentTimeout] = useState<number>(0);

  const [filterSelection, setFilterSelection] = useState<AppointmentFilterType>(
    (localStorage.getItem(localStorageKeys.appointmentStatusFilter) as AppointmentFilterType) ??
      AppointmentFilterType.ALL_APPOINTMENTS
  );

  const { appointmentsState, appointmentsDispatch } = useAppointmentsContext();
  const { notificationDispatch } = useNotificationContext();

  const { appointments, blockedSlots, appointmentsAreStale } = appointmentsState;
  const recordedAppointments = appointments.filter(
    (a) => (a.procedureResults && a.procedureResults.length > 0) || a.claimInformation !== null
  );
  const unrecordedAppointments = appointments.filter(
    (a) =>
      a.claimInformation === null &&
      (a.procedureResults === null || a.procedureResults.length === 0)
  );

  const appointmentListByFilter = () => {
    if (filterSelection === AppointmentFilterType.RECORDED) {
      return recordedAppointments;
    } else if (filterSelection === AppointmentFilterType.UNRECORDED) {
      return unrecordedAppointments;
    }
    return appointments;
  };

  const appointmentsGet = useCallback(
    async (locationId: string) => {
      const actionArgs: ICalendarScheduleGetAsyncActionArgs = {
        appointmentsDispatch,
        authProvider,
        busyDispatch,
        configState,
        errorDispatch,
        locationId,
        telemetryService,
        date: selectedDate,
        getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
      };

      isAppointmentLoadingSet(true);
      await calendarScheduleGetAsyncAction(actionArgs).finally(() => {
        isAppointmentLoadingSet(false);
        setIsInitialLoad(false);
      });

      clearTimeout(currentTimeout);

      const timeoutHandler = setTimeout(() => {
        appointmentsAreStaleDispatch(appointmentsDispatch);
      }, fiveMinuteTimeout);

      setCurrentTimeout(timeoutHandler as unknown as SetStateAction<number>);
    },
    [location, selectedDate]
  );

  useEffect(() => {
    if (!location) {
      appointmentsClearDispatch(appointmentsDispatch);
    } else {
      void appointmentsGet(location.id);
    }
    setLastSyncedTime(getCurrentTime);
  }, [location, selectedDate]);

  useEffect(() => {
    if (appointmentsAreStale && location) {
      void appointmentsGet(location.id);
      setLastSyncedTime(getCurrentTime);
    }
  }, [appointmentsAreStale]);

  const onCancelBlocktimeModal = () => setIsOpenBlocktimeModal(false);

  const onCloseInviteForAppointmentModal = () => setIsOpenInviteForAppointmentModal(false);

  const onBlockTimeApply = async (startTime: Date, durationMinutes: number, reason?: string) => {
    if (!location) {
      return;
    }

    await CreateBlockTime({
      variables: {
        locationId: location.id,
        createBlockedTimeInput: { duration: durationMinutes, startInUtc: startTime, reason },
      } as unknown as ICreateBlockTimeVariables,
    });

    if (createBlockTimeError) {
      notificationSetDispatch(
        notificationDispatch,
        t('appointments.appointmentsScreen.blockTimeFailureMessage'),
        'error'
      );
      return;
    }

    notificationSetDispatch(
      notificationDispatch,
      t('appointments.appointmentsScreen.blockTimeSuccessMessage')
    );
    setIsOpenBlocktimeModal(false);
    setLastSyncedTime(getCurrentTime);
    appointmentsAreStaleDispatch(appointmentsDispatch);
  };

  const areControlsDisabled = !!busyState.busyCount || !location;
  const appointmentsSummarySectionContent = appointmentsSummaryRender();

  const onCalendarDateChange = (date: unknown) => {
    const luxonDate = date as DateTime | undefined;
    if (luxonDate?.isValid) {
      locationSelectedDateSetDispatch(locationDispatch, luxonDate.toJSDate());
    }
    setIsInitialLoad(true);
  };

  const onCalendarLeftClick = () => {
    setIsInitialLoad(true);
    locationSelectedDateSetDispatch(
      locationDispatch,
      DateTime.fromJSDate(selectedDate).minus({ days: 1 }).toJSDate()
    );
  };

  const onCalendarRightClick = () => {
    setIsInitialLoad(true);
    locationSelectedDateSetDispatch(
      locationDispatch,
      DateTime.fromJSDate(selectedDate).plus({ days: 1 }).toJSDate()
    );
  };

  return (
    <PostAuthPrimaryScreenContainer>
      <Typography variant='h1' sx={{ marginBottom: theme.spacing(6) }}>
        {t('appointments.appointmentsScreen.title')}
      </Typography>
      <StyledBlockTimeContainer component='section'>
        <Box display='flex' flexDirection='row' flexGrow='3'>
          <Box id='appointments-screen-mobile-date-picker'>
            <MobileDatePicker
              label={t('appointments.appointmentsScreen.changeDate')}
              value={selectedDate}
              orientation='portrait'
              renderInput={(props) => <TextField {...props} variant='outlined' />}
              inputFormat='MM/dd/yyyy'
              onChange={onCalendarDateChange}
              disabled={areControlsDisabled}
            />
          </Box>
          <IconButton
            id='appointments-screen-mobile-date-picker-left-button'
            onClick={onCalendarLeftClick}
            size='large'
          >
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            id='appointments-screen-mobile-date-picker-right-button'
            onClick={onCalendarRightClick}
            size='large'
          >
            <KeyboardArrowRight />
          </IconButton>
        </Box>
        <AppointmentCreateOptions
          setIsOpenBlocktimeModal={setIsOpenBlocktimeModal}
          areControlsDisabled={areControlsDisabled}
          setIsOpenInviteForAppointmentModal={setIsOpenInviteForAppointmentModal}
        />
      </StyledBlockTimeContainer>
      <StyledMainSection component='section'>{appointmentsSummarySectionContent}</StyledMainSection>
      <BlockTimeModal
        onCloseDialog={onCancelBlocktimeModal}
        isOpen={isOpenBlocktimeModal}
        isLoading={createBlockTimeLoading}
        onBlockTime={onBlockTimeApply}
        date={selectedDate}
        key='block-time-modal'
      />
      <InviteForAppointmentModal
        key={`invite-for-appointment-modal-${isOpenInviteForAppointmentModal}`}
        isOpen={isOpenInviteForAppointmentModal}
        onCloseDialog={onCloseInviteForAppointmentModal}
        onInviteCancelPress={onCloseInviteForAppointmentModal}
      />
    </PostAuthPrimaryScreenContainer>
  );

  function appointmentsSummaryRender(): ReactNode {
    if (!location) {
      return <EmptyViewMessage />;
    }

    const onFilterChange = (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value as AppointmentFilterType;
      setFilterSelection(value);
      localStorage.setItem(localStorageKeys.appointmentStatusFilter, value);
    };

    const onSyncAppointments = () => {
      setLastSyncedTime(getCurrentTime);
      appointmentsAreStaleDispatch(appointmentsDispatch);
    };

    const lastSyncedTimeContent = isAppointmentLoading
      ? `${t('appointments.appointmentsScreen.syncing')}`
      : `${t('appointments.appointmentsScreen.lastSyncedTime')} ${lastSyncedTime}`;

    return (
      <>
        <StyledControlsContainer>
          <FormControl sx={{ flex: 1, bgcolor: theme.palette.grey[50] }} fullWidth={true}>
            <InputLabel
              id='appointment-filter-label'
              sx={{ paddingTop: theme.spacing(2), paddingLeft: theme.spacing(2) }}
            >
              {t('appointments.appointmentsScreen.appointmentFilterLabel')}
            </InputLabel>
            <Select
              id='appointment-filter-select'
              value={filterSelection}
              onChange={onFilterChange}
              sx={{ paddingTop: theme.spacing(1), paddingLeft: theme.spacing(2) }}
            >
              <MenuItem value={AppointmentFilterType.ALL_APPOINTMENTS}>
                {t('appointments.appointmentsScreen.appointmentFilterAll', {
                  value: appointments.length,
                })}
              </MenuItem>
              <MenuItem value={AppointmentFilterType.RECORDED}>
                {t('appointments.appointmentsScreen.appointmentFilterRecorded', {
                  value: recordedAppointments.length,
                })}
              </MenuItem>
              <MenuItem value={AppointmentFilterType.UNRECORDED}>
                {t('appointments.appointmentsScreen.appointmentFilterUnrecorded', {
                  value: unrecordedAppointments.length,
                })}
              </MenuItem>
            </Select>
          </FormControl>
          <StyledSwitchContainer>
            <StyledSyncButton
              onClick={onSyncAppointments}
              startIcon={
                isAppointmentLoading ? (
                  <SpinnerLastSync color='primary' />
                ) : (
                  <StyledLastSync color='primary' />
                )
              }
              disabled={areControlsDisabled}
            >
              {lastSyncedTimeContent}
            </StyledSyncButton>
          </StyledSwitchContainer>
        </StyledControlsContainer>
        <StyledAppointmentsDivider />
        <StyledAgendaViewContainer>
          {isInitialLoad ? (
            <>
              <Skeleton animation='wave' height={'2em'} />
              <Skeleton animation='wave' height={'2em'} />
              <Skeleton animation='wave' height={'2em'} />
            </>
          ) : (
            <AgendaDayView
              appointments={appointmentListByFilter()}
              blockedSlots={blockedSlots}
              selectedDate={selectedDate}
              storeLocation={location}
            />
          )}
        </StyledAgendaViewContainer>
      </>
    );
  }
};
