// Copyright 2020 Prescryptive Health, Inc.

import { NotificationDispatch } from './notification.dispatch';
import { notificationSetAction } from '../actions/notification-set.action';
import { AlertColor } from '@mui/material';

export const notificationSetDispatch = (
  dispatch: NotificationDispatch,
  message: string,
  severity: AlertColor = 'success'
): void => {
  dispatch(notificationSetAction(message, severity));
};
