// Copyright 2021 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import { ILaneInfo } from '../../../model/location-availability';
import { ServiceHoursView } from '../../datetime/service-hours-view/service-hours-view';
import { IPharmacyService } from '../../../model/pharmacy-service';
import { Box, Button, Typography, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AddressFormatter } from '../../../formatters/address-formatter/address.formatter';
import { IAddress } from '../../../model/address';
import { useTranslation } from 'react-i18next';

interface ILocationAvailabilityCardProps {
  address: IAddress;
  isDisabled?: boolean;
  onEditPress: () => void;
  lanes: ILaneInfo[];
  serviceList: IPharmacyService[];
}

export const LocationAvailabilityCard = ({
  address,
  isDisabled,
  onEditPress,
  lanes,
  serviceList,
}: ILocationAvailabilityCardProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isScreenSizeTablet = useMediaQuery(theme.breakpoints.up('tablet'));

  const formattedAddress = AddressFormatter.format(address);
  const formattedLaneCount = lanes.length
    ? lanes.length.toString()
    : t('serviceHours.locationAvailabilityCard.notSet');

  const areServiceHoursSet = lanes.every((lane) => {
    return lane.serviceHours.length > 0;
  });

  const editButtonLabel = areServiceHoursSet
    ? t('serviceHours.locationAvailabilityCard.editButtonLabel')
    : t('serviceHours.locationAvailabilityCard.setButtonLabel');

  const renderLanes = lanes.map((lane, index) => {
    const laneNameLabel = `Lane ${index + 1}`;
    return (
      <Box key={index}>
        <Typography
          style={{
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
          }}
        >
          {laneNameLabel}
        </Typography>
        <ServiceHoursView serviceHours={lane.serviceHours} serviceList={serviceList} />
      </Box>
    );
  });

  return (
    <Box padding={theme.spacing(6)} style={{ backgroundColor: theme.palette.grey.A100 }}>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        marginBottom={theme.spacing(4)}
      >
        <Typography variant='h3'>{formattedAddress}</Typography>
        {isScreenSizeTablet && (
          <Button
            startIcon={<EditIcon />}
            disabled={isDisabled}
            onClick={onEditPress}
            color={'primary'}
          >
            {editButtonLabel}
          </Button>
        )}
      </Box>
      <Box display='flex' flexDirection='row'>
        <Typography style={{ flexGrow: 1 }}>
          {t('serviceHours.locationAvailabilityCard.onCallLanesLabel')}
        </Typography>
        <Typography style={{ fontWeight: 'bold', flexGrow: 20 }}>{formattedLaneCount}</Typography>
      </Box>
      {renderLanes}
      {!isScreenSizeTablet && (
        <Button
          startIcon={<EditIcon />}
          disabled={isDisabled}
          onClick={onEditPress}
          color={'primary'}
        >
          {editButtonLabel}
        </Button>
      )}
    </Box>
  );
};
