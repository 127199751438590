// Copyright 2021 Prescryptive Health, Inc.

import { Typography } from '@mui/material';
import { styled } from '@mui/material';

export const StyledQuoteText = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(8),
  fontStyle: 'oblique',
}));

export const StyledQuotename = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  fontWeight: 'bold',
}));

export const StyledQuoteTitle = styled(Typography)(() => ({
  fontWeight: 'normal',
}));

export const StyledInnerTestimonialContainer = styled(Typography)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
}));
