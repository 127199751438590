// Copyright 2021 Prescryptive Health, Inc.

import { ISessionAction } from './session.action';
import { SessionPhase } from '../session.state';
import { AuthenticationProvider } from '../../authentication/authentication-provider';

export type ISessionLoggedInAction = ISessionAction<'SESSION_LOGGED_IN'>;

export const sessionLoggedInAction = (
  authenticationProvider: AuthenticationProvider
): ISessionLoggedInAction => ({
  type: 'SESSION_LOGGED_IN',
  payload: {
    phase: SessionPhase.postAuth,
    authProvider: authenticationProvider,
  },
});
