// Copyright 2021 Prescryptive Health, Inc.

import { styled, Box } from '@mui/material';

export const StyledSignInSectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(8),
  maxWidth: theme.spacing(121),
  width: '100%',
}));
