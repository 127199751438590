// Copyright 2021 Prescryptive Health, Inc.

import { IProgram } from '../../../model/program';
import { programsSetAction } from '../actions/programs-set.action';
import { PostAuthDispatch } from './post-auth.dispatch';

export const programsSetDispatch = (
  dispatch: PostAuthDispatch,
  programs: IProgram[]
): void => {
  dispatch(programsSetAction(programs));
};
