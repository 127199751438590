// Copyright 2021 Prescryptive Health, Inc.

import { Box } from '@mui/material';
import { styled } from '@mui/material';

export const StyledLanderContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  zIndex: -1,
  overflow: 'auto',
});

export const StyledLanderSignInButtonContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  padding: theme.spacing(6),
  [theme.breakpoints.up('desktop')]: { display: 'unset' },
}));

export const StyledDynamicSectionsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const StyledAppBarContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: '1200px',
});

export const StyledHeroSignInContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  maxWidth: '1200px',

  [theme.breakpoints.up('desktop')]: {
    flexDirection: 'row',
  },
}));
