// Copyright 2020 Prescryptive Health, Inc.

import { Reducer } from 'react';
import { IProviderState } from './provider.state';
import { ProviderAction } from './actions/provider.action';
import { IProvider } from '../../model/provider';
import { IProviderUsersSetActionPayload } from './actions/provider-users-set.action';
import { IProviderUsersAddActionPayload } from './actions/provider-users-add.action';
import { IProviderUsersUpdateActionPayload } from './actions/provider-users-update.action';
import { IStoreLocation } from '../../model/store-location';
import { ILocationDeleteActionPayload } from './actions/store-location-delete.action';
import { ILocationIsTestActionPayload } from './actions/store-location-is-test-update.action';

export type ProviderReducer = Reducer<IProviderState, ProviderAction>;

export const providerReducer: ProviderReducer = (
  state: IProviderState,
  action: ProviderAction
): IProviderState => {
  const payload = action.payload;

  switch (action.type) {
    case 'DETAILS_UPDATE': {
      const detailsUpdatePayload = {
        currentProvider: {
          ...state.currentProvider,
          ...(payload as IProvider),
        },
      } as Partial<IProviderState>;
      return { ...state, ...detailsUpdatePayload };
    }
    case 'PROVIDER_USERS_SET': {
      const p = action.payload as IProviderUsersSetActionPayload;
      return {
        ...state,
        providerUserIdsMap: {
          ...state.providerUserIdsMap,
          [p.providerName]: p.users.map((user) => user.id),
        },
        users: {
          ...state.users,
          ...p.users.reduce((acc, curr) => {
            return { ...acc, [curr.id]: curr };
          }, {}),
        },
      };
    }
    case 'PROVIDER_USERS_ADD': {
      const p = action.payload as IProviderUsersAddActionPayload;
      return {
        ...state,
        providerUserIdsMap: {
          ...state.providerUserIdsMap,
          [p.providerName]: [p.user.id, ...state.providerUserIdsMap[p.providerName]],
        },
        users: {
          ...state.users,
          [p.user.id]: p.user,
        },
      };
    }
    case 'PROVIDER_USERS_UPDATE': {
      const p = action.payload as IProviderUsersUpdateActionPayload;

      const userToUpdate = state.users[p.user.id];
      if (!userToUpdate) {
        return {
          ...state,
        };
      }

      // TODO: replace with dynamic Object.keys logic
      userToUpdate.firstName = p.user.firstName ?? userToUpdate.firstName;
      userToUpdate.lastName = p.user.lastName ?? userToUpdate.lastName;
      userToUpdate.phoneNumber = p.user.phoneNumber ?? userToUpdate.phoneNumber;
      userToUpdate.npi = p.user.npi ?? userToUpdate.npi;
      userToUpdate.contactEmail = p.user.contactEmail ?? userToUpdate.contactEmail;
      userToUpdate.role = p.user.role ?? userToUpdate.role;
      userToUpdate.isActive = p.user.isActive ?? userToUpdate.isActive;

      return {
        ...state,
        users: {
          ...state.users,
          [p.user.id]: userToUpdate,
        },
      };
    }
    case 'STORE_LOCATION_ADD': {
      const storeLocationAddActionPayload = action.payload as IStoreLocation;
      return {
        ...state,
        locations: [...state.locations, storeLocationAddActionPayload],
      };
    }
    case 'STORE_LOCATION_UPDATE': {
      const storeLocationUpdateActionPayload = action.payload as IStoreLocation;
      return {
        ...state,
        locations: state.locations.map((location: IStoreLocation) => {
          if (location.id === storeLocationUpdateActionPayload.id) {
            return storeLocationUpdateActionPayload;
          } else {
            return location;
          }
        }),
      };
    }
    case 'STORE_LOCATION_IS_TEST_UPDATE': {
      const storeLocationIsTestUpdateActionPayload = action.payload as ILocationIsTestActionPayload;
      return {
        ...state,
        locations: state.locations.map((location: IStoreLocation) => {
          if (location.id === storeLocationIsTestUpdateActionPayload.locationId) {
            return { ...location, isTest: storeLocationIsTestUpdateActionPayload.isTest };
          } else {
            return location;
          }
        }),
      };
    }
    case 'STORE_LOCATION_DELETE': {
      const storeLocationDeleteActionPayload = action.payload as ILocationDeleteActionPayload;
      return {
        ...state,
        locations: state.locations.filter((location: IStoreLocation) => {
          return location.id !== storeLocationDeleteActionPayload.locationId;
        }),
      };
    }

    default:
      return { ...state, ...(payload as IProviderState) };
  }
};
