// Copyright 2020 Prescryptive Health, Inc.

import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { postAuthTelemetry } from '../../../providers/telemetry/post-auth.telemetry';
import { ApiErrors } from '../../../api/errors/api-errors';
import { userAccessGet } from '../../../api/v1/user-access-get/user-access-get';
import { userAccessSetDispatch } from '../dispatch/user-access-set.dispatch';
import { PostAuthDispatch } from '../dispatch/post-auth.dispatch';
import { logApiError } from '../../../api/errors/log-api-error';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../../api/errors/api-error-code';

export interface IUserAccessGetAsyncActionArgs extends IApiAsyncActionArgs {
  postAuthDispatch: PostAuthDispatch;
}

export const userAccessGetAsyncAction = async (
  args: IUserAccessGetAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IUserAccessGetAsyncActionArgs): Promise<void> => {
  const { configState, authProvider, postAuthDispatch, telemetryService, getAuthToken } = args;

  try {
    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    telemetryService.trackEvent('user-access-get', postAuthTelemetry(authProvider));
    const result = await userAccessGet(configState.apis.pharmacyPortal, accessToken);

    if (authProvider && result.length < 1) {
      throw new PharmacyPortalError(
        ApiErrors.noAccess(authProvider?.getAccountName()),
        ApiErrorCode.USER_ACCESS_GET,
        PharmacyPortalErrorType.API_ERROR
      );
    }

    if (authProvider && result.filter(({ type }) => type === 'provider').length < 1) {
      throw new PharmacyPortalError(
        ApiErrors.noProviderAccess(authProvider?.getAccountName()),
        ApiErrorCode.USER_ACCESS_GET,
        PharmacyPortalErrorType.API_ERROR
      );
    }

    userAccessSetDispatch(postAuthDispatch, result);
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
  }
};
