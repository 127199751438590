// Copyright 2021 Prescryptive Health, Inc.

import { IBatchStatus } from '../../../../model/batch-status';
import { waitlistIsStaleAction } from '../actions/waitlist-is-stale.action';
import { WaitlistDispatch } from './waitlist.dispatch';

export const waitlistIsStaleDispatch = (
  dispatch: WaitlistDispatch,
  batchStatus?: IBatchStatus
): void => {
  dispatch(waitlistIsStaleAction(batchStatus));
};
