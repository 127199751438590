// Copyright 2020 Prescryptive Health, Inc.

import { IPostAuthState } from './post-auth.state';
import { PostAuthAction } from './actions/post-auth.action';
import { Reducer } from 'react';

export const postAuthReducer: Reducer<IPostAuthState, PostAuthAction> = (
  state: IPostAuthState,
  action: PostAuthAction
): IPostAuthState => {
  switch (action.type) {
    case 'SERVICE_DETAIL_SET': {
      const serviceDetailsSetPayload = {
        serviceDetails: { ...state.serviceDetails, ...action.payload },
      } as Partial<IPostAuthState>;

      return {
        ...state,
        ...serviceDetailsSetPayload,
      };
    }

    default: {
      return { ...state, ...action.payload };
    }
  }
};
