// Copyright 2021 Prescryptive Health, Inc.

import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { LocationDispatch } from '../dispatch/location.dispatch';
import { IPaymentSetting } from '../../../model/pharmacy-service';
import { pharmacyServicePaymentUpdate } from '../../../api/v1/pharmacy-service-payment-update/pharmacy-service-payment-update';
import { pharmacyServicePaymentUpdateAction } from '../actions/pharmacy-service-payment-update.action';
import { IErrorResponse } from '../../../api/error-response';
import { HttpStatusCodesEnum } from '../../../api/http-status-codes';
import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { nonFatalErrorDispatch } from '../../error/dispatch/non-fatal-error.dispatch';
import { logApiError } from '../../../api/errors/log-api-error';

export interface IPharmacyServicePaymentUpdateAsyncActionArgs extends IApiAsyncActionArgs {
  providerId: string;
  serviceType: string;
  price: number | null;
  paymentSettings: IPaymentSetting[];
  locationDispatch: LocationDispatch;
}

export const pharmacyServicePaymentUpdateAsyncAction = async (
  args: IPharmacyServicePaymentUpdateAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IPharmacyServicePaymentUpdateAsyncActionArgs): Promise<void> => {
  const {
    providerId,
    serviceType,
    price,
    paymentSettings,
    telemetryService,
    locationDispatch,
    errorDispatch,
    getAuthToken,
  } = args;

  try {
    const { configState, authProvider } = args;

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    await pharmacyServicePaymentUpdate(
      configState.apis.pharmacyPortal,
      accessToken,
      providerId,
      serviceType,
      paymentSettings,
      price
    );

    locationDispatch(pharmacyServicePaymentUpdateAction(serviceType, price ?? 0, paymentSettings));
  } catch (error) {
    const errorResponse = error as IErrorResponse;
    if (
      errorResponse.message &&
      errorResponse.status &&
      errorResponse.status === HttpStatusCodesEnum.BAD_REQUEST
    ) {
      nonFatalErrorDispatch(errorDispatch, 'Payment Settings Error', errorResponse.message);
    } else {
      fatalErrorDispatch(args.errorDispatch);
    }
    logApiError(telemetryService, error);
    throw error;
  }
};
