// Copyright 2021 Prescryptive Health, Inc.

import { Box } from '@mui/material';
import { styled as muiStyled } from '@mui/material';

export const CostsPerPharmacyContainer = muiStyled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.up('xl')]: {
    paddingBottom: theme.spacing(3),
  },
}));
