// Copyright 2021 Prescryptive Health, Inc.

import { gql } from '@apollo/client';

export const GET_PROVIDER_SERVICES_QUERY = gql`
  query getProviderServiceGroups {
    providerServiceGroups {
      id
      GroupName
      IsVisable
      SortOrder
      provider_services {
        __typename
        id
        Title
        ServiceType
        Description
        providerServiceGroupOrder
        Intro {
          ... on ComponentProviderServiceContentTypesValueProp {
            __typename
            valuePropTitle
          }
        }
      }
      provider_programs {
        __typename
        id
        Title
        ProgramIdentifier
        Description
        Intro {
          ... on ComponentProviderServiceContentTypesValueProp {
            __typename
            valuePropTitle
          }
        }
      }
    }
  }
`;
