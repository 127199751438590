import { authenticationProvider } from '../../authentication-initialize';

export const getToken = async (
  auth0Migration: boolean,
  getAccessTokenSilently: () => Promise<string>
): Promise<string> => {
  if (auth0Migration) {
    return await getAccessTokenSilently();
  }
  const { accessToken } = await authenticationProvider.acquireToken();
  return accessToken;
};
