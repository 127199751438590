// Copyright 2021 Prescryptive Health, Inc.

import { Card, CardActions, Typography, Button, Chip } from '@mui/material';
import { styled } from '@mui/material';

export const cardWidth = 286;
export const cardSpacing = 2;
// 4 comes from the theme spacing factor
export const cardMargin = cardSpacing * 4;

export const EnrollableCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: cardWidth,
  height: 'fit-content',
  padding: theme.spacing(4),
  marginLeft: theme.spacing(cardSpacing),
  marginRight: theme.spacing(cardSpacing),
  borderColor: theme.palette.grey[200],
}));

export const BasicButton = styled(Button)(({ theme }) => ({
  fontWeight: 700,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

export const ServiceCardActions = styled(CardActions)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  padding: 0,
});

export const ServiceIntroTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '0.875rem',
  marginBottom: theme.spacing(2),
  height: theme.spacing(5),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const ServiceNameTypography = styled(Typography)(({ theme }) => ({
  height: '55px',
  marginBottom: theme.spacing(4),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
}));

export const KeyBenefitsTypography = styled(Typography)(({ theme }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontSize: '0.875rem',
  display: 'inline',
  marginRight: theme.spacing(1),
}));

export const StyledManageButton = styled(BasicButton)({
  marginLeft: 'auto',
});

export const PendingChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(2),
  backgroundColor: theme.palette.blueScale['100'],
  color: 'white',
  borderRadius: theme.spacing(4),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));
