// Copyright 2022 Prescryptive Health, Inc.

import { Box, Divider, Typography } from '@mui/material';
import { PureComponent, ReactElement } from 'react';
import { IPharmacyService } from '../../model/pharmacy-service';
import { Barcode } from '../barcode/barcode';
import { IAppointment } from '../screens/appointment-details/appointment-details.screen.query';
import { PatientInfoSection } from '../screens/appointment-details/sections/patient-info/patient-info.section';
import { PatientProfileSection } from '../screens/appointment-details/sections/patient-profile/patient-profile.section';
import { PerformingFacilitySection } from '../screens/appointment-details/sections/performing-facility/performing-facility.section';
import { PlanInfoSection } from '../screens/appointment-details/sections/plan-info/plan-info.section';
import { ProcedureInfoSection } from '../screens/appointment-details/sections/procedure-info/procedure-info.section';
import { SummarySection } from '../screens/appointment-details/sections/summary/summary.section';

interface IPrintAppointmentProps {
  title: string;
  appointment?: IAppointment;
  services?: IPharmacyService[];
  clia?: string;
}

export class PrintAppointment extends PureComponent<IPrintAppointmentProps> {
  constructor(props: IPrintAppointmentProps) {
    super(props);
  }

  public render(): ReactElement {
    const { title, appointment, services, clia } = this.props;

    return (
      <Box
        margin='24px'
        sx={{
          '@media all': {
            '.page-break': { display: 'none' },
          },
          '@media print': {
            display: 'block',
            '.page-break': { display: 'block', breakAfter: 'page' },
            '.print-section': { display: 'block', marginTop: '1rem' },
            '.print-questions': { display: 'block' },
          },
          '@page': { size: 'auto', marginTop: '1rem', marginBottom: '1rem' },
        }}
      >
        <Box
          flex='1'
          display='flex'
          justifyContent='space-between'
          px='48'
          alignItems='center'
          flexWrap='wrap'
        >
          <Typography variant='h1' component='h1'>
            {title}
          </Typography>
          <Barcode barcodeValue={appointment?.orderNumber ?? ''} />
        </Box>
        <Box
          flex='1'
          display='flex'
          flexDirection='column'
          width='100%'
          gap='24px'
          className='print-section'
        >
          <SummarySection appointment={appointment || undefined} services={services ?? []} />
          <PatientInfoSection appointment={appointment || undefined} />
          <ProcedureInfoSection appointment={appointment || undefined} />
          <Divider className='print-section' />
          <PlanInfoSection appointment={appointment || undefined} />
          <Divider className='print-section' />
          <PerformingFacilitySection appointment={appointment || undefined} clia={clia} />
          <Divider className='print-section' />
          <PatientProfileSection appointment={appointment || undefined} />
        </Box>
      </Box>
    );
  }
}
