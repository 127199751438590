// Copyright 2021 Prescryptive Health, Inc.

import { Reducer } from 'react';
import { ILocationAvailabilityState } from './location-availability.state';
import { LocationAvailabilityAction } from './actions/location-availability.action';

export type LocationAvailabilityReducer = Reducer<
  ILocationAvailabilityState,
  LocationAvailabilityAction
>;

export const locationAvailabilityReducer: LocationAvailabilityReducer = (
  state: ILocationAvailabilityState,
  action: LocationAvailabilityAction
): ILocationAvailabilityState => {
  const payload = action.payload;
  return { ...state, ...payload };
};
