// Copyright 2020 Prescryptive Health, Inc.

import { createContext } from 'react';
import { ErrorStatus, IErrorState } from '../../../src/state/error/error.state';
import { ErrorDispatch } from '../../../src/state/error/dispatch/error.dispatch';
export interface IErrorContext {
  readonly errorState: IErrorState;
  readonly errorDispatch: ErrorDispatch;
}

export const ErrorContext = createContext<IErrorContext>({
  errorState: {
    status: ErrorStatus.none,
    title: undefined,
    content: undefined,
  },
  errorDispatch: () => ({}),
});
