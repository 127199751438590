// Copyright 2020 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { IStoreLocation } from '../../../model/store-location';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { AddressFormatter } from '../../../formatters/address-formatter/address.formatter';
import { useTranslation } from 'react-i18next';

export interface IStoreLocationPickerProps {
  isDisabled?: boolean;
  locations: IStoreLocation[];
  selectedLocationId?: string;
  onSelect: (locationId: string) => void;
}

export const StoreLocationPicker = ({
  isDisabled,
  locations,
  onSelect,
  selectedLocationId = '',
}: IStoreLocationPickerProps): ReactElement => {
  const { t } = useTranslation();
  const options = locationOptions(locations);

  const singleLocation =
    (locations[0]?.address && AddressFormatter.format(locations[0]?.address)) || '';

  const onChange = (event: SelectChangeEvent) => {
    onSelect(event.target.value as string);
  };

  function locationOptions(locations: IStoreLocation[]): { value: string; displayText: string }[] {
    if (locations.length === 0) {
      return [{ value: '', displayText: t('pickers.storeLocationPicker.noSitesText') }];
    }

    return [
      { value: '', displayText: t('pickers.storeLocationPicker.selectSiteText') },
      ...locations.map((location) => {
        const { address, id } = location;
        const formattedAddress = AddressFormatter.format(address);
        return { value: id, displayText: formattedAddress };
      }),
    ];
  }

  return (
    <>
      {locations.length === 1 ? (
        <Box>
          <Typography variant='body2'>{t('pickers.storeLocationPicker.pickerLabel')}</Typography>
          <Typography variant='body1'>{singleLocation}</Typography>
        </Box>
      ) : (
        <FormControl fullWidth variant='standard'>
          <InputLabel id='store-location-label'>
            {t('pickers.storeLocationPicker.pickerLabel')}
          </InputLabel>
          <Select
            id='store-location-select'
            onChange={onChange}
            value={options.find((option) => option.value === selectedLocationId)?.value || ''}
            disabled={!!isDisabled}
          >
            {options.map((option, index: number) => {
              return (
                <MenuItem key={`${index}${option.value}`} value={option.value}>
                  {option.displayText}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </>
  );
};
