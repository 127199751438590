// Copyright 2020 Prescryptive Health, Inc.

import { ISessionAction } from './session.action';
import { SessionPhase } from '../session.state';

export type ILoginCompleteAction = ISessionAction<'LOGIN_COMPLETE'>;

export const loginCompleteAction = (): ILoginCompleteAction => ({
  type: 'LOGIN_COMPLETE',
  payload: {
    phase: SessionPhase.postAuth,
  },
});
