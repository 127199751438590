// Copyright 2021 Prescryptive Health, Inc.

import { IPharmacyService } from '../model/pharmacy-service';

export function serviceNameFromType(
  serviceType: string | undefined,
  services: IPharmacyService[]
): string {
  const identifiedService = services.find(
    (service) => service.serviceType === serviceType
  );
  return identifiedService ? identifiedService.name : '?';
}
