// Copyright 2020 Prescryptive Health, Inc.

import { IContentMap } from '../../../providers/locale/get-content';

export interface ISessionLoadingScreenContent {
  loading: string;
}

const defaultContent: ISessionLoadingScreenContent = {
  loading: 'Loading session...',
};

export const sessionLoadingScreenContent: IContentMap<ISessionLoadingScreenContent> = {
  defaultContent,
};
