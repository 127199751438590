// Copyright 2021 Prescryptive Health, Inc.

import { IWaitlistAction } from './waitlist.action';

export type IWaitlistClearAction = IWaitlistAction<'CLEAR'>;

export const waitlistClearAction = (): IWaitlistClearAction => ({
  type: 'CLEAR',
  payload: {
    waitlist: [],
    isStale: false,
    batchStatus: undefined,
  },
});
