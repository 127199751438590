// Copyright 2021 Prescryptive Health, Inc.

import { Box, BoxProps, Theme } from '@mui/material';
import styled from 'styled-components';

export const StyledPortalTopBarContainer = styled(Box)<BoxProps>`
  display: flex;
  background-color: ${(props: { theme: Theme }) => props.theme.palette.grey[50]};
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: ${(props: { theme: Theme }) => props.theme.spacing(22.5)};
  width: 100vw;
  z-index: 198;
`;
