export enum ApiErrorCode {
  // Generic errors we have created over time now as codes
  AUTHENTICATION_UNDEFINED = 'AUTHENTICATION_UNDEFINED',
  NON_FATAL_ERROR = 'NON_FATAL_ERROR',
  LOGIN_CANCELLED_ERROR = 'LOGIN_CANCELLED_ERROR',
  LOGIN_IN_PROGRESS_ERROR = 'LOGIN_IN_PROGRESS_ERROR',

  // Endpoint specific codes
  APPOINTMENT_CANCEL = 'APPOINTMENT_CANCEL',
  APPOINTMENT_GET = 'APPOINTMENT_GET',
  SCHEDULE_GET = 'SCHEDULE_GET',
  BLOCK_TIME_UNBLOCK = 'BLOCK_TIME_UNBLOCK',
  BLOCKED_TIMES_SET = 'BLOCKED_TIMES_SET',
  LANE_AVAILABILITY_UPDATE = 'LANE_AVAILABILITY_UPDATE',
  LOCATION_AVAILABILITY_GET = 'LOCATION_AVAILABILITY_GET',
  LOCATION_AVAILABILITY_UPDATE = 'LOCATION_AVAILABILITY_UPDATE',
  LOCATION_IS_TEST_UPDATE = 'LOCATION_IS_TEST_UPDATE',
  LOGIN_ERROR = 'LOGIN_ERROR',
  PAYMENT_REPORTS_GET = 'PAYMENT_REPORTS_GET',
  PHARMACY_SERVICE_PAYMENT_UPDATE = 'PHARMACY_SERVICE_PAYMENT_UPDATE',
  PHARMACY_SERVICE_PRICE_UPDATE = 'PHARMACY_SERVICE_PRICE_UPDATE',
  PHARMACY_SERVICE_QUESTION_DELETE = 'PHARMACY_SERVICE_QUESTION_DELETE',
  PHARMACY_SERVICE_QUESTION_UPSERT = 'PHARMACY_SERVICE_QUESTION_UPSERT',
  PHARMACY_SERVICE_UPDATE = 'PHARMACY_SERVICE_UPDATE',
  PHARMACY_SERVICES_GET = 'PHARMACY_SERVICES_GET',
  PROCEDURE_SET = 'PROCEDURE_SET',
  PROGRAMS_GET = 'PROGRAMS_GET',
  PROVIDER_ADD = 'PROVIDER_ADD',
  PROVIDER_DETAILS_GET = 'PROVIDER_DETAILS_GET',
  PROVIDER_DETAILS_UPDATE = 'PROVIDER_DETAILS_UPDATE',
  PROVIDER_LOCATION_ADD = 'PROVIDER_LOCATION_ADD',
  PROVIDER_LOCATION_DELETE = 'PROVIDER_LOCATION_DELETE',
  PROVIDER_LOCATION_UPDATE = 'PROVIDER_LOCATION_UPDATE',
  PROVIDER_PROGRAM_ADD = 'PROVIDER_PROGRAM_ADD',
  PROVIDER_PROGRAM_SET_ACTIVE = 'PROVIDER_PROGRAM_SET_ACTIVE',
  PROVIDER_SERVICE_ADD = 'PROVIDER_SERVICE_ADD',
  PROVIDER_SERVICE_SET_ACTIVE = 'PROVIDER_SERVICE_SET_ACTIVE',
  PROVIDER_USERS_ADD = 'PROVIDER_USERS_ADD',
  PROVIDER_USERS_GET = 'PROVIDER_USERS_GET',
  PROVIDER_USERS_UPDATE = 'PROVIDER_USERS_UPDATE',
  SERVICE_DETAILS_GET = 'SERVICE_DETAILS_GET',
  SERVICES_GET = 'SERVICES_GET',
  STORE_LOCATIONS_GET = 'STORE_LOCATIONS_GET',
  USER_ACCESS_GET = 'USER_ACCESS_GET',
  VALIDATE_NPI = 'VALIDATE_NPI',
  WAITLIST_ADD = 'WAITLIST_ADD',
  WAITLIST_GET = 'WAITLIST_GET',
  WAITLIST_INVITE = 'WAITLIST_INVITE',
  WAITLIST_REMOVE = 'WAITLIST_REMOVE',
}
