import { IApiConfig } from '../../api/api.helper';
import { IConfigObject } from '../../config/config.helper';

type DrugSearchApiPathKey = 'drugSearch' | 'drugSearchNdc' | 'priceSearch' | 'npiSearch';
export type IDrugSearchApiConfig = IApiConfig<DrugSearchApiPathKey>;

type DrugSearchAuthenticationPathKey = 'subscriptionKey';
export type IDrugSearchAuthenticationConfig = IConfigObject<DrugSearchAuthenticationPathKey>;

const drugSearchApiConfig: IDrugSearchApiConfig = {
  env: {
    host: '<undefined>',
    port: '<undefined>',
    protocol: 'https',
    version: '',
  },
  paths: {
    drugSearch:
      '/drugsearch/3.0/drugs?FormularyId=99&GroupPlanCode=200P32F&Filter=:filter&MaxResults=5',
    drugSearchNdc: '/drugsearch/3.0/Drugs/NDC/:ndc?formularyId=99',
    priceSearch: '/benefitpricing/v1.0/myrxpricing/calculate?api-version=1.0',
    npiSearch: '/pharmacies/1.0/pharmacy/npi?npi=:npi',
  },
  retryPolicy: {
    pause: 2000,
    remaining: 3,
  },
};

export interface IDrugSearchConfigState {
  readonly apis: {
    readonly authentication: IDrugSearchAuthenticationConfig;
    readonly drugSearchApiConfig: IDrugSearchApiConfig;
  };
}

export const defaultDrugSearchConfigState: IDrugSearchConfigState = {
  apis: {
    authentication: {
      subscriptionKey: '<undefined>',
    },
    drugSearchApiConfig,
  },
};
