// Copyright 2021 Prescryptive Health, Inc.

import { Box } from '@mui/material';
import { styled as muiStyled } from '@mui/material';

export const ServiceDetailsScreenWrapper = muiStyled(Box)(({ theme }) => ({
  overflow: 'auto',
  [theme.breakpoints.up('xl')]: {
    display: 'flex',
  },
}));

export const ServiceTitleBox = muiStyled(Box)({
  display: 'flex',
  flex: 1,
});

export const ScrollableBox = muiStyled(Box)(({ theme }) => ({
  marginTop: theme.spacing(12),
  marginBottom: theme.spacing(14),
  paddingLeft: theme.spacing(12),
  paddingRight: theme.spacing(12),
  [theme.breakpoints.up('xl')]: {
    flexBasis: 0,
    flexGrow: 999,
    paddingRight: theme.spacing(6),
  },
}));
