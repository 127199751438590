// Copyright 2022 Prescryptive Health, Inc.

import { Box, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NoAppointmentContentBox } from './no-appointment-content-box';

export const NoAppointmentScreen = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant='h1' component='h2' sx={{ textAlign: 'center', paddingBottom: 20 }}>
        {t('noAppointments.noAppointmentScreen.noAppointmentScreenHeader')}
      </Typography>
      <Box display='grid' gridTemplateColumns={'2fr 2fr'} gap={20}>
        <NoAppointmentContentBox
          title={t('noAppointments.noAppointmentScreen.noAppointmentScreenBox1.title')}
          body={t('noAppointments.noAppointmentScreen.noAppointmentScreenBox1.body')}
          link={t('noAppointments.noAppointmentScreen.noAppointmentScreenBox1.link')}
          linkTo={t('noAppointments.noAppointmentScreen.noAppointmentScreenBox1.linkTo')}
        />
        <NoAppointmentContentBox
          title={t('noAppointments.noAppointmentScreen.noAppointmentScreenBox2.title')}
          body={t('noAppointments.noAppointmentScreen.noAppointmentScreenBox2.body')}
          link={t('noAppointments.noAppointmentScreen.noAppointmentScreenBox2.link')}
          linkTo={t('noAppointments.noAppointmentScreen.noAppointmentScreenBox2.linkTo')}
        />
        <NoAppointmentContentBox
          title={t('noAppointments.noAppointmentScreen.noAppointmentScreenBox3.title')}
          body={t('noAppointments.noAppointmentScreen.noAppointmentScreenBox3.body')}
          link={t('noAppointments.noAppointmentScreen.noAppointmentScreenBox3.link')}
          linkTo={t('noAppointments.noAppointmentScreen.noAppointmentScreenBox3.linkTo')}
        />
        <NoAppointmentContentBox
          title={t('noAppointments.noAppointmentScreen.noAppointmentScreenBox4.title')}
          body={t('noAppointments.noAppointmentScreen.noAppointmentScreenBox4.body')}
          link={t('noAppointments.noAppointmentScreen.noAppointmentScreenBox4.link')}
          linkTo={t('noAppointments.noAppointmentScreen.noAppointmentScreenBox4.linkTo')}
        />
      </Box>
    </>
  );
};
