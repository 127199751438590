// Copyright 2021 Prescryptive Health, Inc.
import { Box, styled, Typography } from '@mui/material';

export const SummarySectionColumnContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

export const SummarySectionLabelTypography = styled(Typography)(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(2),
}));

export const SummarySectionDetailTypography = styled(Typography)({
  flex: 2,
  fontWeight: 'bold',
});
