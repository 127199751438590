// Copyright 2021 Prescryptive Health, Inc.

import { showBusyWhile } from '../../../busy/dispatch/show-busy-while';
import { fatalErrorDispatch } from '../../../error/dispatch/fatal-error.dispatch';
import { AuthenticationProviderUndefinedError } from '../../../../api/errors/authentication-provider-undefined.error';
import { waitlistGet } from '../../../../api/v1/waitlist-get/waitlist-get';
import { IApiAsyncActionArgs } from '../../../api-async-action-args';
import { waitlistSetDispatch } from '../dispatch/waitlist-set.dispatch';
import { WaitlistDispatch } from '../dispatch/waitlist.dispatch';
import { logApiError } from '../../../../api/errors/log-api-error';

export interface IWaitlistGetAsyncActionArgs extends IApiAsyncActionArgs {
  waitlistDispatch: WaitlistDispatch;
  locationId: string;
  pageNumber: number;
  pageSize: number;
  serviceType?: string;
}

export const waitlistGetAsyncAction = async (args: IWaitlistGetAsyncActionArgs): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IWaitlistGetAsyncActionArgs): Promise<void> => {
  const {
    waitlistDispatch,
    locationId,
    telemetryService,
    pageNumber,
    pageSize,
    serviceType = '',
    getAuthToken,
  } = args;

  try {
    telemetryService.trackEvent('waitlist-get', {
      locationId,
    });

    const { configState, authProvider } = args;

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    const result = await waitlistGet(
      configState.apis.pharmacyPortal,
      accessToken,
      locationId,
      pageNumber,
      pageSize,
      serviceType
    );
    waitlistSetDispatch(waitlistDispatch, result.waitlist);
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
  }
};
