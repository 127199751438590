import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { ChangeEvent, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getEnvironmentVariable from '../../../../helpers/environment-variables/get-environment-variable';
import initPharmacyPortalServiceGraphqlClient from '../../../../init-pharmacy-portal-service-graphql-client';
import { isValidEmail } from '../../../../validators/email.validator/email.validator';
import { CREATE_AUTH0_IDENTITY_MUTATION } from './create-auth0-identity.mutation';

interface IEmailCollectionModalProps {
  isOpen: boolean;
  onSkip: () => void;
  onContinue: () => void;
  userEmail: string;
  setUserEmail: (email: string) => void;
}

export const EmailCollectionModal = ({
  isOpen,
  onSkip,
  onContinue,
  userEmail,
  setUserEmail,
}: IEmailCollectionModalProps): ReactElement => {
  const { t } = useTranslation();

  const { user } = useAuth0();

  const userSignedInEmail = user?.email;

  const [CreateAuth0Identity, { loading, error }] = useMutation(CREATE_AUTH0_IDENTITY_MUTATION, {
    client: initPharmacyPortalServiceGraphqlClient,
  });

  const [userEmailError, setUserEmailError] = useState(false);

  const userEmailErrorText = () => {
    if (!userEmailError) return '';

    if (!isValidEmail(userEmail)) return t('emailCollectionModal.emailFieldInvalidEmailErrorText');

    if (userEmail.includes('@provider.myrx.io'))
      return t('emailCollectionModal.emailFieldWrongDomainErrorText');

    if (userEmail === userSignedInEmail)
      return t('emailCollectionModal.emailFieldSameEmailErrorText', {
        email: userSignedInEmail,
      });
  };

  const onKeyUpDialog = (event) => {
    if (event.key === 'Enter') {
      onContinueClick();
    }
  };

  const onUserEmailChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setUserEmail(event.target.value.trim());
  };

  const onContinueClick = async () => {
    if (
      !isValidEmail(userEmail) ||
      userEmail.includes('@provider.myrx.io') ||
      userEmail === userSignedInEmail
    ) {
      setUserEmailError(true);
      return;
    }

    await CreateAuth0Identity({ variables: { input: { auth0Email: userEmail } } });

    onContinue();
  };

  const supportEmail = getEnvironmentVariable('SUPPORT_EMAIL');

  return (
    <Dialog data-testid='email-collection-modal' open={isOpen} maxWidth='lg' onKeyUp={onKeyUpDialog}>
      <DialogTitle>{t('emailCollectionModal.title')}</DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={5}>
          <Typography>{t('emailCollectionModal.contentP1')}</Typography>
          <Typography>{t('emailCollectionModal.contentP2')}</Typography>
          <TextField
            data-testid='email-field'
            label={t('emailCollectionModal.emailFieldTitle')}
            placeholder={t('emailCollectionModal.emailFieldPlaceholder')}
            sx={{ width: '400px' }}
            value={userEmail}
            onChange={onUserEmailChange}
            error={userEmailError}
            helperText={userEmailErrorText()}
            InputProps={{
              inputProps: {
                'data-testid': 'email-input',
              },
            }}
          />
        </Box>
        {error && (
          <Typography color='error' my={2}>
            {t('emailCollectionModal.submissionError')}{' '}
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button data-testid='skip-btn' onClick={onSkip}>
          {t('emailCollectionModal.skipButton')}
        </Button>
        <LoadingButton
          data-testid='continue-btn'
          loading={loading}
          variant='contained'
          onClick={onContinueClick}
        >
          {t('emailCollectionModal.continueButton')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
