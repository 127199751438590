import { gql } from '@apollo/client';

export const ADD_EXISTING_USER = gql`
  mutation AddExistingUser {
    addExistingUser(input: $input, providerName: $providerName, userId: $userId)
      @rest(method: "POST", path: "/api/v1/providers/{args.providerName}/users/{args.userId}") {
      NO_RESPONSE
    }
  }
`;
