// Copyright 2021 Prescryptive Health, Inc.

import { Avatar } from '@mui/material';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { authenticatedRouteConfig } from '../../navigation/authenticated-route-config';

interface IPrescryptiveProLogoProps {
  width?: string;
}

export const PrescryptiveProLogo = ({ width }: IPrescryptiveProLogoProps): ReactElement => {
  const logoWidth = width ? width : '200px';

  const logoURI = process.env.PUBLIC_URL + '/media/logo/PrescryptivePRO-2023.svg';

  return (
    <Link to={authenticatedRouteConfig.root.createUrl()}>
      <Avatar
        src={logoURI}
        variant='square'
        alt='prescryptive pro logo'
        style={{ width: logoWidth, height: 'auto' }}
      />
    </Link>
  );
};
