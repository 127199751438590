// Copyright 2021 Prescryptive Health, Inc.

import { createContext } from 'react';
import { LocationAvailabilityDispatch } from '../../state/location-availability/dispatch/location-availability.dispatch';
import {
  defaultLocationAvailabilityState,
  ILocationAvailabilityState,
} from '../../state/location-availability/location-availability.state';

export interface ILocationAvailabilityContext {
  readonly locationAvailabilityState: ILocationAvailabilityState;
  readonly locationAvailabilityDispatch: LocationAvailabilityDispatch;
}

export const LocationAvailabilityContext = createContext<ILocationAvailabilityContext>(
  {
    locationAvailabilityState: defaultLocationAvailabilityState,
    locationAvailabilityDispatch: () => {
      return;
    },
  }
);
