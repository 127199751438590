// Copyright 2021 Prescryptive Health, Inc.

import { Theme, Toolbar as MuiToolbar } from '@mui/material';
import styled from 'styled-components';

export const Toolbar = styled(MuiToolbar)`
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding-top: ${(props: { theme: Theme }) => props.theme.spacing(2)};
  padding-bottom: ${(props: { theme: Theme }) => props.theme.spacing(2)};
  padding-left: ${(props: { theme: Theme }) => props.theme.spacing(8)};
  padding-right: ${(props: { theme: Theme }) => props.theme.spacing(8)};
  box-shadow: inset 0px -1px 0px #bdbdbd;
  background-color: ${(props: { theme: Theme }) =>
    props.theme.palette.common.white};
`;
