// Copyright 2021 Prescryptive Health, Inc.

import { Link } from '@mui/material';
import React, { ReactElement } from 'react';

interface IUrlProps {
  target: string | null | undefined;
  displayText: string | null | undefined;
  urlAddress: string | null | undefined;
}

export const Url = ({
  target,
  displayText,
  urlAddress,
}: IUrlProps): ReactElement => {
  return (
    <Link target={target ?? ''} href={urlAddress ?? ''} underline="hover">
      {displayText}
    </Link>
  );
};
