// Copyright 2021 Prescryptive Health, Inc.

import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { providerDetailsUpdate } from '../../../api/v1/provider-details-update/provider-details-update';
import { ProviderDispatch } from '../dispatch/provider.dispatch';
import { providerDetailsUpdateDispatch } from '../dispatch/provider-details-update.dispatch';
import { userAccessGet } from '../../../api/v1/user-access-get/user-access-get';
import { ApiErrors } from '../../../api/errors/api-errors';
import { userAccessSetDispatch } from '../../post-auth/dispatch/user-access-set.dispatch';
import { PostAuthDispatch } from '../../post-auth/dispatch/post-auth.dispatch';
import { logApiError } from '../../../api/errors/log-api-error';

export interface IProviderDetailsUpdateAsyncActionArgs extends IApiAsyncActionArgs {
  currentProviderName: string;
  newProviderName: string;
  contactPhoneNumber: string;
  contactEmail: string;
  cliaNumber: string;
  providerDispatch: ProviderDispatch;
  postAuthDispatch: PostAuthDispatch;
}

export const providerDetailsUpdateAsyncAction = async (
  args: IProviderDetailsUpdateAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IProviderDetailsUpdateAsyncActionArgs): Promise<void> => {
  const {
    currentProviderName,
    newProviderName,
    contactPhoneNumber,
    contactEmail,
    cliaNumber,
    telemetryService,
    providerDispatch,
    postAuthDispatch,
    getAuthToken,
  } = args;

  try {
    telemetryService.trackEvent('provider-details-update', {
      currentProviderName,
      newProviderName,
      contactPhoneNumber,
      contactEmail,
      cliaNumber,
    });

    const { configState, authProvider } = args;

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    await providerDetailsUpdate(
      configState.apis.pharmacyPortal,
      accessToken,
      currentProviderName,
      newProviderName,
      contactPhoneNumber,
      contactEmail,
      cliaNumber
    );

    if (currentProviderName !== newProviderName) {
      const userAccessGetResult = await userAccessGet(configState.apis.pharmacyPortal, accessToken);

      if (userAccessGetResult.length < 1) {
        throw new Error(ApiErrors.noAccess(authProvider?.getAccountName() ?? ''));
      }

      if (userAccessGetResult.filter(({ type }) => type === 'provider').length < 1) {
        throw new Error(ApiErrors.noProviderAccess(authProvider?.getAccountName() ?? ''));
      }

      userAccessSetDispatch(postAuthDispatch, userAccessGetResult);
    } else {
      providerDetailsUpdateDispatch(
        providerDispatch,
        newProviderName,
        contactPhoneNumber,
        contactEmail,
        cliaNumber
      );
    }
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
    return Promise.reject();
  }
};
