// Copyright 2021 Prescryptive Health, Inc.

import { Link } from '@mui/material';
import React, { ReactElement } from 'react';
import {
  ComponentProviderServiceContentTypesUrl,
  UploadFile,
} from '../../../model/strapi/strapi-models';
import { buildResourceUrl } from '../helpers/build-resource-url';
import { ImageLoad } from './image-load';
import ENV from '../../../helpers/environment-variables';

interface IImageLinkProps {
  imageFile: UploadFile | null | undefined;
  imageLinkUrl: ComponentProviderServiceContentTypesUrl | null | undefined;
}

export const ImageLink = ({ imageFile, imageLinkUrl }: IImageLinkProps): ReactElement => {
  const aspectRatio =
    (imageFile?.width && imageFile.height && imageFile.width / imageFile.height) || 0;

  return (
    <Link
      target={imageLinkUrl?.Target ?? ''}
      href={imageLinkUrl?.URLAddress ?? ''}
      underline="hover">
      <ImageLoad
        src={buildResourceUrl(ENV.STRAPI_HOST_URL, imageFile?.url ?? '')}
        alt={imageFile?.alternativeText || undefined}
        aspectRatio={aspectRatio}
      />
    </Link>
  );
};
