// Copyright 2021 Prescryptive Health, Inc.

import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { logApiError } from '../../../api/errors/log-api-error';
import { providerAdd } from '../../../api/v1/provider-add/provider-add';
import { IAllowedAccessResource } from '../../../api/v1/user-access-get/user-access-get.response';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { PostAuthDispatch } from '../../post-auth/dispatch/post-auth.dispatch';
import { userAccessSetDispatch } from '../../post-auth/dispatch/user-access-set.dispatch';
import { currentProviderSetDispatch } from '../../provider/dispatch/current-provider-set.dispatch';
import { ProviderDispatch } from '../../provider/dispatch/provider.dispatch';
import { locationAddDispatch } from '../../provider/dispatch/store-location-add.dispatch';

export interface IProviderAddAsyncActionArgs extends IApiAsyncActionArgs {
  providerName: string;
  pharmacyNpiNumber: string;
  providerDispatch: ProviderDispatch;
  postAuthDispatch: PostAuthDispatch;
  userAccessRules: IAllowedAccessResource[];
}

export const providerAddAsyncAction = async (args: IProviderAddAsyncActionArgs): Promise<void> => {
  const {
    authProvider,
    telemetryService,
    configState,
    providerName,
    pharmacyNpiNumber,
    providerDispatch,
    postAuthDispatch,
    userAccessRules,
    getAuthToken,
  } = args;

  try {
    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    const result = await providerAdd(configState.apis.pharmacyPortal, accessToken, {
      providerName,
      pharmacyNpiNumber,
    });

    const newUserAccessRules: IAllowedAccessResource[] = [
      {
        type: 'provider',
        name: providerName,
        role: 'Admin',
      },
      ...userAccessRules,
    ];

    userAccessSetDispatch(postAuthDispatch, newUserAccessRules);
    locationAddDispatch(providerDispatch, result);
    currentProviderSetDispatch(providerDispatch, {
      name: providerName,
      npiNumber: pharmacyNpiNumber,
    });
  } catch (error) {
    logApiError(telemetryService, error);
    return Promise.reject(error);
  }
};
