// Copyright 2021 Prescryptive Health, Inc.

import { ReactElement, useState } from 'react';
import { LaneViewSwitch } from '../../../../switches/lane-view/lane-view.switch';
import { ILaneInfo } from '../../../../../model/location-availability';
import { IPharmacyService } from '../../../../../model/pharmacy-service';
import { ICheckboxItem } from '../../../../inputs/checkbox-list/checkbox-list';
import { SelectAllCheckboxList } from '../../../../inputs/select-all-checkbox-list/select-all-checkbox-list';
import { TimeSelect } from '../../../../inputs/time-select/time-select';
import { WeekdayToggleSelector } from '../../../../toggle-selectors/weekday/weekday.toggle-selector';
import {
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  StyledEditAvailabilityModalDivider,
  TimeSelectionContainer,
} from './edit-availability.modal.styled-components';
import { getNewDate } from '../../../../../helpers/dates/get-new-date/get-new-date';
import { AddressFormatter } from '../../../../../formatters/address-formatter/address.formatter';
import { IAddress } from '../../../../../model/address';
import { useTranslation } from 'react-i18next';

interface IEditAvailabilityModalProps {
  isOpen?: boolean;
  address?: IAddress;
  serviceList?: IPharmacyService[];
  lanes?: ILaneInfo[];
  onCancel: () => void;
  onSave: (updatedLanes: ILaneInfo[]) => Promise<void>;
}

export const EditAvailabilityModal = ({
  isOpen = false,
  address,
  serviceList = [],
  lanes = [],
  onCancel,
  onSave,
}: IEditAvailabilityModalProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [selectedWeekdays, setSelectedWeekdays] = useState<boolean[]>([]);

  const initialStartTime = getNewDate();
  initialStartTime.setHours(8);
  initialStartTime.setMinutes(0);
  const initialEndTime = getNewDate();
  initialEndTime.setHours(17);
  initialEndTime.setMinutes(0);

  const [startTime, setStartTime] = useState<Date>(initialStartTime);
  const [endTime, setEndTime] = useState<Date>(initialEndTime);

  const initialIsTimeUnavailable = false;
  const [isTimeUnavailable, setIsTimeUnavailable] = useState(initialIsTimeUnavailable);

  const [appliedLanes, setAppliedLanes] = useState<ILaneInfo[]>(lanes || []);

  const initialIsTimeRangeDirty = false;
  const [isTimeRangeDirty, setIsTimeRangeDirty] = useState(initialIsTimeRangeDirty);

  const initialServicesSelection = false;
  const [isServicesSelectionDirty, setIsServicesSelectionDirty] =
    useState(initialServicesSelection);

  const initialSelectedServices = serviceList.map((service) => {
    const checkboxItem: ICheckboxItem = {
      label: service.name,
      value: service.serviceType,
      isSelected: false,
    };
    return checkboxItem;
  });
  const [selectedServices, setSelectedServices] =
    useState<ICheckboxItem[]>(initialSelectedServices);

  const [isSaveDisabled, setIsSavedDisabled] = useState(true);

  const defaultLaneCheckboxStates =
    lanes?.map((lane, index) => ({
      label: `${t('serviceHours.editAvailabilityModal.laneLabel')} ${index + 1}`,
      value: lane.emailAddress,
      isSelected: false,
    })) || [];

  const [laneSelectionsStates, setLaneSelectionsStates] =
    useState<ICheckboxItem[]>(defaultLaneCheckboxStates);

  const isStartTimeEarlierThanEndTime = startTime < endTime;

  const areNoLanesSelected =
    laneSelectionsStates.filter((checkbox) => checkbox.isSelected === true).length === 0;

  const areNoWeekdaysSelected = selectedWeekdays.filter((weekday) => weekday).length === 0;

  const onServicesSelection = () => {
    setIsServicesSelectionDirty(true);
  };

  const onSavePress = async () => {
    await onSave(appliedLanes);
  };

  const formattedAddress = address ? AddressFormatter.format(address) : '';

  const timeErrorMessage =
    isStartTimeEarlierThanEndTime || isTimeUnavailable
      ? ''
      : t('serviceHours.editAvailabilityModal.timeErrorMessage');

  const onStartTimeSelect = (time: Date) => {
    if (time.getTime() === startTime.getTime()) {
      return;
    }

    setStartTime(time);
    setIsTimeRangeDirty(true);
  };

  const onEndTimeSelect = (time: Date) => {
    if (time.getTime() === endTime.getTime()) {
      return;
    }

    setEndTime(time);
    setIsTimeRangeDirty(true);
  };

  const onWeekdaySelect = (updatedSelectedWeekdayBooleans: boolean[]) => {
    setSelectedWeekdays(updatedSelectedWeekdayBooleans);
  };

  const onUnavailableCheck = () => {
    setIsTimeUnavailable(!isTimeUnavailable);
    setIsTimeRangeDirty(true);
  };

  const getSelectedServices = (): string[] => {
    return selectedServices
      .filter((selectedService) => selectedService.isSelected)
      .map((checkboxItem) => checkboxItem.value);
  };

  const onApplyPress = () => {
    const updatedLanes =
      appliedLanes.map((appliedLane, laneIndex) => {
        if (laneSelectionsStates[laneIndex].isSelected) {
          const updatedServiceHour = appliedLane.serviceHours.map((serviceHours, index) => {
            const isDaySelected = selectedWeekdays[index];
            if (!isDaySelected) {
              return serviceHours;
            }
            const updatedTimeRangeAndTypes = {
              ...serviceHours,
              timeRanges: isTimeUnavailable
                ? []
                : [
                    {
                      startTime: {
                        hour: startTime?.getHours(),
                        minute: startTime?.getMinutes(),
                      },
                      endTime: {
                        hour: endTime?.getHours(),
                        minute: endTime?.getMinutes(),
                      },
                      serviceTypes: getSelectedServices(),
                    },
                  ],
            };
            return updatedTimeRangeAndTypes;
          });
          return { ...appliedLane, serviceHours: updatedServiceHour };
        }
        return appliedLane;
      }) ?? [];

    setAppliedLanes(updatedLanes);
    setIsTimeRangeDirty(false);
    setIsServicesSelectionDirty(false);
    setIsSavedDisabled(false);
  };

  const onDiscardPress = () => {
    setStartTime(initialStartTime);
    setEndTime(initialEndTime);
    setIsTimeUnavailable(initialIsTimeUnavailable);
    setSelectedServices(initialSelectedServices);
    setIsTimeRangeDirty(false);
    setIsServicesSelectionDirty(false);
  };

  const defaultStartTime = isTimeUnavailable ? undefined : startTime;
  const defaultEndTime = isTimeUnavailable ? undefined : endTime;

  const hasTimeRangeError = !isStartTimeEarlierThanEndTime && !isTimeUnavailable;
  const isApplyButtonDisabled =
    (!isTimeRangeDirty && !isServicesSelectionDirty) ||
    areNoLanesSelected ||
    areNoWeekdaysSelected ||
    hasTimeRangeError;

  const isDiscardButtonDisabled = !isTimeRangeDirty && !isServicesSelectionDirty;

  const applyOrDiscardMessage =
    (isTimeRangeDirty || isServicesSelectionDirty) && !areNoLanesSelected ? (
      <Typography>{t('serviceHours.editAvailabilityModal.applyOrDiscardMessage')}</Typography>
    ) : null;

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <DialogTitle>{t('serviceHours.editAvailabilityModal.title')}</DialogTitle>
      <DialogContent>
        <Typography
          style={{
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
          }}
        >
          {t('serviceHours.editAvailabilityModal.description')}
        </Typography>
        <Box display='flex' flexDirection='row' style={{ gap: theme.spacing(28) }}>
          <Box display='flex' flexDirection='column'>
            <Typography
              style={{
                lineHeight: theme.spacing(8),
                marginBottom: theme.spacing(2),
              }}
            >
              {t('serviceHours.editAvailabilityModal.siteAddressLabel')}
            </Typography>
            <Typography>{t('serviceHours.editAvailabilityModal.laneLabel')}</Typography>
          </Box>
          <Box display='flex' flexDirection='column'>
            <Typography
              style={{
                lineHeight: theme.spacing(8),
                marginBottom: theme.spacing(2),
                fontWeight: 'bold',
              }}
            >
              {formattedAddress}
            </Typography>
            <SelectAllCheckboxList
              items={laneSelectionsStates}
              onSelect={setLaneSelectionsStates}
            />
          </Box>
        </Box>
        <StyledEditAvailabilityModalDivider />
        <Box component='section'>
          <Typography variant='h6' style={{ fontWeight: 'bold' }}>
            {t('serviceHours.editAvailabilityModal.serviceHoursHeading')}
          </Typography>
          <Typography
            style={{
              marginTop: theme.spacing(6),
              marginBottom: theme.spacing(6),
            }}
          >
            {t('serviceHours.editAvailabilityModal.selectDays')}
          </Typography>
          <WeekdayToggleSelector onSelect={onWeekdaySelect} selectedDays={selectedWeekdays} />
          <TimeSelectionContainer>
            <TimeSelect
              onSelectTime={onStartTimeSelect}
              label={t('serviceHours.editAvailabilityModal.startTimeLabel')}
              defaultValue={defaultStartTime}
              isDisabled={isTimeUnavailable}
              minuteGranularity={5}
            />
            <TimeSelect
              onSelectTime={onEndTimeSelect}
              label={t('serviceHours.editAvailabilityModal.endTimeLabel')}
              defaultValue={defaultEndTime}
              isDisabled={isTimeUnavailable}
              minuteGranularity={5}
            />
            {timeErrorMessage !== '' && <Typography color='error'>{timeErrorMessage}</Typography>}
            <FormControlLabel
              label={t('serviceHours.editAvailabilityModal.unavailableCheckboxLabel')}
              onChange={onUnavailableCheck}
              control={<Checkbox checked={isTimeUnavailable} color={'secondary'} size={'small'} />}
            />
          </TimeSelectionContainer>
        </Box>
        <SelectAllCheckboxList
          items={selectedServices}
          onSelect={setSelectedServices}
          onChange={onServicesSelection}
          label={t('serviceHours.editAvailabilityModal.assignServicesLabel')}
        />
        {applyOrDiscardMessage}
        <Box display='flex' justifyContent='flex-end' gap={theme.spacing(2)} my={theme.spacing(4)}>
          <Button
            size={'small'}
            startIcon={<CheckCircleIcon />}
            onClick={onApplyPress}
            disabled={isApplyButtonDisabled}
            color={'primary'}
            variant={'contained'}
          >
            {t('serviceHours.editAvailabilityModal.applyLabel')}
          </Button>
          <Button
            size={'small'}
            startIcon={<DeleteIcon />}
            onClick={onDiscardPress}
            disabled={isDiscardButtonDisabled}
            color={'primary'}
            variant={'outlined'}
          >
            {t('serviceHours.editAvailabilityModal.discardLabel')}
          </Button>
        </Box>
        <StyledEditAvailabilityModalDivider />
        <Box component='section'>
          <Typography variant='h6' style={{ marginBottom: theme.spacing(8), fontWeight: 'bold' }}>
            {t('serviceHours.editAvailabilityModal.summaryHeading')}
          </Typography>
          <LaneViewSwitch lanes={appliedLanes} serviceList={serviceList} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSavePress} disabled={isSaveDisabled} color='primary' variant='contained'>
          {t('serviceHours.editAvailabilityModal.saveLabel')}
        </Button>
        <Button onClick={onCancel} color='primary' variant='outlined'>
          {t('serviceHours.editAvailabilityModal.cancelLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
