// Copyright 2021 Prescryptive Health, Inc.

import { Typography, Theme, Paper } from '@mui/material';
import { styled as muiStyled } from '@mui/material';
import styled from 'styled-components';

export const BlockedReason = muiStyled(Typography)(({ theme }) => ({
  color: theme.palette.grey['700'],
  fontStyle: 'italic',
}));

export const BlockedLabel = muiStyled(Typography)(({ theme }) => ({
  color: theme.palette.grey['400'],
  fontStyle: 'italic',
  fontWeight: 'bold',
}));

export const BlockedSlotCard = styled(Paper)`
  background-color: ${({ theme }: { theme: Theme }) => theme.palette.grey['50']};
  position: relative;
  padding: ${({ theme }: { theme: Theme }) => theme.spacing(2)};
  padding-left: ${({ theme }: { theme: Theme }) => theme.spacing(3)};
  display: flex;
  align-items: baseline;
  gap: ${({ theme }: { theme: Theme }) => theme.spacing(3)};
`;
