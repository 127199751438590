// Copyright 2021 Prescryptive Health, Inc.

import { Typography, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { BackButtonBreadcrumb } from '../../details-screens-sections/back-button-breadcrumb/back-button-breadcrumb';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ProviderService } from '../../../../../model/strapi/strapi-models';
import { EnrollWidget } from '../../details-screens-sections/enroll-widget/enroll-widget';
import { CostsPerPharmacy } from '../../details-screens-sections/costs-per-pharmacy/costs-per-pharmacy';
import { Intro } from '../../details-screens-sections/intro/intro';
import { HowItWorks } from '../../details-screens-sections/how-it-works/how-it-works';
import { WhatYouWillGet } from '../../details-screens-sections/what-you-will-get/what-you-will-get';
import { ThingsToConsider } from '../../details-screens-sections/things-to-consider/things-to-consider';
import { MarketingResources } from '../../details-screens-sections/marketing-resources/marketing-resources';
import { Faqs } from '../../details-screens-sections/faqs/faqs';
import { ServiceEnrollmentBar } from '../service-enrollment-bar/service-enrollment-bar';
import { EnrollmentStatus } from '../../../../../model/enrolled-service-type';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  ScrollableBox,
  ServiceDetailsScreenWrapper,
  ServiceTitleBox,
} from './service-details.screen.styled-components';
import { useTranslation } from 'react-i18next';

interface ServiceDetailsScreenProps {
  onGoBack: () => void;
  onEnroll: () => void;
  onManage: () => void;
  onActivate: () => void;
  enrollmentStatus: EnrollmentStatus | undefined;
  data: Maybe<ProviderService>;
}

export const ServiceDetailsScreen = ({
  onGoBack,
  onEnroll,
  onManage,
  onActivate,
  enrollmentStatus,
  data,
}: ServiceDetailsScreenProps): ReactElement => {
  const { t } = useTranslation();

  const title = data?.Title ?? '';
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

  const enrollmentWidgetDesktop = !isDesktop && (
    <EnrollWidget
      enrollmentData={data?.EnrollProcess}
      helpData={data?.HelpSection}
      enrollmentStatus={undefined}
    />
  );

  const enrollmentWidget = (
    <EnrollWidget
      enrollmentData={data?.EnrollProcess}
      helpData={data?.HelpSection}
      enrollmentStatus={undefined}
    />
  );

  return (
    <ServiceDetailsScreenWrapper>
      <ScrollableBox>
        <BackButtonBreadcrumb
          onGoBack={onGoBack}
          backButtonText={t('addService.serviceDetailsScreen.backButtonText')}
        />
        <ServiceTitleBox>
          <Typography variant='h1'>{title}</Typography>
        </ServiceTitleBox>
        <Intro introData={data?.Intro} />
        {enrollmentWidgetDesktop}
        <HowItWorks howItWorksData={data?.HowItWorks} />
        <CostsPerPharmacy costsPerPharmacyData={data?.PharmacyCosts} />
        <WhatYouWillGet whatYouGetData={data?.WhatYouGet} />
        <ThingsToConsider thingsToConsiderData={data?.ThingsToConsider} />
        <MarketingResources marketingResourcesData={data?.MarketingResources} />
        <Faqs faqData={data?.Faq} />
      </ScrollableBox>
      <ServiceEnrollmentBar
        title={title}
        onEnroll={onEnroll}
        onManage={onManage}
        onActivate={onActivate}
        enrollmentStatus={enrollmentStatus}
        rightRailData={data?.RightRail}
        enrollWidget={enrollmentWidget}
      />
    </ServiceDetailsScreenWrapper>
  );
};
