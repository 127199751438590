import { useMutation } from '@apollo/client';
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getEnvironmentVariable from '../../../../helpers/environment-variables/get-environment-variable';
import initPharmacyPortalServiceGraphqlClient from '../../../../init-pharmacy-portal-service-graphql-client';
import { RESEND_EMAIL_MUTATION } from './resend-email.mutation';

interface IEmailSentModalProps {
  isOpen: boolean;
  onContinue: () => void;
  userEmail: string;
}

interface ModalNotification {
  message: string;
  status: AlertColor;
}

export const EmailSentModal = ({
  isOpen,
  onContinue,
  userEmail,
}: IEmailSentModalProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [notification, setNotification] = useState<ModalNotification | null>(null);

  const [ResendEmail, { error }] = useMutation(RESEND_EMAIL_MUTATION, {
    client: initPharmacyPortalServiceGraphqlClient,
  });

  const onResend = async () => {
    await ResendEmail({ variables: { input: { email: userEmail } } });

    if (error) {
      setNotification({ message: t('emailSentModal.notificationErrorText'), status: 'error' });
      return;
    }

    setNotification({ message: t('emailSentModal.notificationSuccessText'), status: 'success' });
  };

  const supportEmail = getEnvironmentVariable('SUPPORT_EMAIL');

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{t('emailSentModal.title')}</DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={4}>
          <Typography>
            {t('emailSentModal.content')}{' '}
            <Link
              data-testid='resend-btn'
              style={{
                color: theme.palette.purpleScale[300],
                textDecoration: 'none',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
              onClick={onResend}
            >
              {t('emailSentModal.resend')}
            </Link>{' '}
            {t('emailSentModal.orContact')}{' '}
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>.
          </Typography>
          {notification && <Alert severity={notification.status}>{notification.message}</Alert>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button data-testid='continue-btn' onClick={onContinue} variant='contained'>
          {t('emailSentModal.continueButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
