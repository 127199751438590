// Copyright 2021 Prescryptive Health, Inc.

import { IPaymentReport } from '../../model/payment-report';

export interface IPaymentReportsState {
  readonly paymentReports?: IPaymentReport[];
}

export const defaultPaymentReportsState: IPaymentReportsState = {
  paymentReports: [],
};
