import { Box, Button, Divider, Tooltip, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import {
  IDrugResultNdcPackageDrugResultForm,
  IDrugResultPackageSearchResult,
} from '../../../../model/drug-search';
import { EditPrescriptionDialog } from '../edit-prescription-dialog/edit-prescription-dialog';
import { useEffect, useState } from 'react';
import { IPriceSearchBody } from '../../../../api/drug-search-api/get-prices-by-pharmacies/get-prices-by-pharmacies';
import { IPriceByPharmacy } from '../../../../model/price-by-pharmacy';
import { getNewDate } from '../../../../helpers/dates/get-new-date/get-new-date';
import { useDrugSearchConfigContext } from '../../../../hooks/use-durg-search-config-context/use-drug-search-config-context.hook';
import { useTelemetryContext } from '../../../../providers/telemetry/use-telemetry-context.hook';
import { getPriceList } from '../../../../api/drug-search-api/get-price-list/get-price-list';
import { LoadingSpinner } from '../../../loading/loading-spinner';

interface IEditPrescriptionProps {
  value: IDrugResultPackageSearchResult | null;
  currentForm: IDrugResultNdcPackageDrugResultForm | null;
  npi: string;
  updatePrescription: (updatedForm: IDrugResultNdcPackageDrugResultForm) => void;
}

export const EditPrescription = ({
  value,
  currentForm,
  npi,
  updatePrescription,
}: IEditPrescriptionProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { drugSearchConfigState } = useDrugSearchConfigContext();
  const { telemetryService } = useTelemetryContext();

  const [isEditPrescriptionOpen, setIsEditPrescriptionOpen] = useState(false);
  const [priceList, setPriceList] = useState<IPriceByPharmacy[]>([]);
  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchPriceList = async () => {
      const priceSearchBody: IPriceSearchBody = {
        providerIds: [npi],
        quantity: currentForm?.modeQuantity ?? 1,
        daysSupply: currentForm?.modeDaysSupply ?? 1,
        fillDate: getNewDate().toISOString(),
        productId: currentForm?.representativeNdc ?? '',
        productIdType: 'NDC',
      };
      await getPriceList(
        drugSearchConfigState.apis.drugSearchApiConfig,
        drugSearchConfigState.apis.authentication,
        priceSearchBody,
        telemetryService
      ).then((data: IPriceByPharmacy[]) => {
        setPriceList(data);
        setLoading(false);
      });
    };

    fetchPriceList();
  }, [currentForm, npi]);

  const handleEditPrescriptionDialog = () => {
    setIsEditPrescriptionOpen(!isEditPrescriptionOpen);
  };

  const handleGroupCopy = () => {
    navigator.clipboard.writeText('200P32F');
    setIsCopied(true);
  };
  const handlePCNCopy = () => {
    navigator.clipboard.writeText('X01');
    setIsCopied(true);
  };
  const handleBINCopy = () => {
    navigator.clipboard.writeText('610749');
    setIsCopied(true);
  };
  const handleMouseLeave = () => {
    setTimeout(() => setIsCopied(false), 200);
  };

  return (
    <>
      <Box
        display='flex'
        data-testid='drug-details-box'
        flexDirection='column'
        border='1px solid rgba(0, 0, 0, 0.23)'
        borderColor={theme.palette.primary.main}
        borderRadius='4px'
        gap={1}
        width='100%'
        padding={theme.spacing(4)}
        boxSizing='border-box'
      >
        <Box
          display='inherit'
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box>
            <Typography fontWeight='bold'>{value?.displayName}</Typography>
            <Typography>
              {getDrugDetails(currentForm ?? ({} as IDrugResultNdcPackageDrugResultForm))}
            </Typography>
          </Box>
          {loading ? (
            <Box paddingRight={theme.spacing(4)}>
              <LoadingSpinner />
            </Box>
          ) : priceList.length > 0 ? (
            <Typography fontWeight='bold' variant='h6' data-testid={`price-list-subtotal`}>
              {priceList[0].totalCost
                ? `$${priceList[0].totalCost}`
                : t('drugSearch.pharmacyPriceResult.contactPharmacy')}
            </Typography>
          ) : null}
        </Box>
        <Box display='flex' flexDirection='column' marginTop={theme.spacing(4)} gap={4}>
          <Divider />
          <Box
            display='inherit'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
              <Typography variant='body1'>{t('drugSearch.screenSubheaderInformation')}</Typography>
              <Tooltip
                title={isCopied ? t('drugSearch.copiedToolTip') : t('drugSearch.copyToolTip')}
              >
                <Button
                  onClick={handleGroupCopy}
                  onMouseLeave={handleMouseLeave}
                  data-testid='group-copy'
                >
                  {t('drugSearch.group')}
                </Button>
              </Tooltip>
              <Typography variant='body1'>{'|'}</Typography>
              <Tooltip
                title={isCopied ? t('drugSearch.copiedToolTip') : t('drugSearch.copyToolTip')}
              >
                <Button
                  onClick={handlePCNCopy}
                  onMouseLeave={handleMouseLeave}
                  data-testid='pcn-copy'
                >
                  {t('drugSearch.pcn')}
                </Button>
              </Tooltip>
              <Typography variant='body1'>{'|'}</Typography>
              <Tooltip
                title={isCopied ? t('drugSearch.copiedToolTip') : t('drugSearch.copyToolTip')}
              >
                <Button
                  onClick={handleBINCopy}
                  onMouseLeave={handleMouseLeave}
                  data-testid='bin-copy'
                >
                  {t('drugSearch.bin')}
                </Button>
              </Tooltip>
            </Box>
            <Button startIcon={<EditIcon />} onClick={handleEditPrescriptionDialog}>
              {t('drugSearch.editPrescriptionButton')}
            </Button>
          </Box>
        </Box>
      </Box>
      <EditPrescriptionDialog
        prescription={value ?? ({} as IDrugResultPackageSearchResult)}
        isOpen={isEditPrescriptionOpen}
        handlePrescription={updatePrescription}
        handleClose={handleEditPrescriptionDialog}
      />
    </>
  );
};

export function getDrugDetails(form: IDrugResultNdcPackageDrugResultForm) {
  const formStrength =
    form.strength && form.strengthUnit ? `${form.strength} ${form.strengthUnit}` : null;
  const modeQuantity =
    form.modeQuantity || form.formValue
      ? [form.modeQuantity, form.formValue].filter(Boolean).join(' ')
      : null;
  const modeDaySupply = form.modeDaysSupply ? `${form.modeDaysSupply} days` : null;

  return [formStrength, modeQuantity, modeDaySupply].filter(Boolean).join(' | ');
}
