// Copyright 2021 Prescryptive Health, Inc.

import { HttpStatusCodesEnum } from '../../http-status-codes';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { call } from '../../call';
import { ApiErrors } from '../../errors/api-errors';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export async function pharmacyServiceQuestionDelete(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  locationId: string,
  serviceType: string,
  questionId: string
): Promise<void> {
  const url = buildUrl(apiConfig, 'pharmacyServiceQuestionDelete', {
    ':locationId': locationId,
    ':serviceType': serviceType,
    ':questionId': questionId,
  });
  const headers = buildBearerAuthHeaders(authToken);

  const response: Response = await call(url, null, 'DELETE', headers);
  if (response.ok) {
    return;
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.PHARMACY_SERVICE_QUESTION_DELETE,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  throw new PharmacyPortalError(
    ApiErrors.pharmacyServiceQuestionDelete(
      locationId,
      serviceType,
      questionId,
      response.statusText
    ),
    ApiErrorCode.PHARMACY_SERVICE_QUESTION_DELETE,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}
