// Copyright 2021 Prescryptive Health, Inc.

import React, { useEffect, useState } from 'react';
import { ReactElement } from 'react';
import { useLocationContext } from '../../../../../providers/location/use-location-context.hook';
import { IWaitlistItem } from '../../../../../model/waitlist-item';
import { WaitlistCsvFileReader } from '../../../../readers/waitlist-csv-file-reader';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import { StyledImportToWaitlistErrorContainer } from './import-to-waitlist.modal.styled-components';
import { DirectionBullet } from './direction-bullet';
import { csvDownloader } from '../../../../csv-downloader/csv-downloader';
import { useTranslation } from 'react-i18next';

interface IImportToWaitlistModalProps {
  isOpen?: boolean;
  onImportPress: (waitlistItems: IWaitlistItem[]) => void;
  onCancelPress: () => void;
}

export const ImportToWaitlistModal = ({
  isOpen = false,
  onImportPress: onSavePress,
  onCancelPress,
}: IImportToWaitlistModalProps): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    locationState: { services = [] },
  } = useLocationContext();

  const [waitlistImportItems, setWaitlistImportItems] = useState<IWaitlistItem[]>([]);

  const onDownloadCSVTemplate = () => {
    csvDownloader(
      [
        'First name,Last name,Birth date,Phone number,Service name',
        'John,Doe,01/20/1999,8009007000,COVID-19 Vaccination Dose 1',
      ],
      'CSVTemplate.csv'
    );
  };

  const [errorLog, setErrorLog] = useState<string[]>([]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setWaitlistImportItems([]);
  }, [isOpen]);

  const isWaitlistImportEmpty = waitlistImportItems.length === 0;

  const onImportPress = () => {
    onSavePress(waitlistImportItems);
    onCancelPress();
  };

  return (
    <Dialog open={isOpen} id='import-to-waitlist-modal'>
      <DialogTitle>{t('waitlist.importToWaitlistModal.title')}</DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={theme.spacing(6)}>
          <Typography>{t('waitlist.importToWaitlistModal.subtitle')}</Typography>
          <Typography>{t('waitlist.importToWaitlistModal.legal')}</Typography>
          <Box display='grid' gap={theme.spacing(6)} id='import-to-waitlist-direction-bullets'>
            <DirectionBullet bulletText='1'>
              <Typography>
                {t('waitlist.importToWaitlistModal.downloadLabel')}{' '}
                <Link
                  component='span'
                  href='#'
                  underline='always'
                  style={{ cursor: 'pointer' }}
                  onClick={onDownloadCSVTemplate}
                  color='primary'
                >
                  {t('waitlist.importToWaitlistModal.waitlistTemplate')}
                </Link>
              </Typography>
            </DirectionBullet>
            <DirectionBullet bulletText='2'>
              <Typography>{t('waitlist.importToWaitlistModal.fillOutLabel')}</Typography>
            </DirectionBullet>
            <DirectionBullet bulletText='3'>
              <Typography>{t('waitlist.importToWaitlistModal.uploadLabel')}</Typography>
            </DirectionBullet>
          </Box>
          <WaitlistCsvFileReader
            onImportWaitlistItems={setWaitlistImportItems}
            onErrorSave={setErrorLog}
            services={services}
          />
          {errorLog.length > 0 && (
            <Box id='waitlist-import-error-log'>
              <Typography color='error'>
                {t('waitlist.importToWaitlistModal.errorLabel')}
              </Typography>
              <Box height='150px' overflow='scroll' border={1} borderColor='black'>
                {errorLog.map((error, index) => {
                  return (
                    <StyledImportToWaitlistErrorContainer key={`${error}${index}`}>
                      <Typography variant='subtitle2'>{error}</Typography>
                    </StyledImportToWaitlistErrorContainer>
                  );
                })}
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onImportPress}
          disabled={isWaitlistImportEmpty}
          color='primary'
          variant='contained'
        >
          {t('waitlist.importToWaitlistModal.importButton')}
        </Button>

        <Button onClick={onCancelPress} color='primary' variant='outlined'>
          {t('waitlist.importToWaitlistModal.cancelButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
