// Copyright 2021 Prescryptive Health, Inc.

import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import {
  ComponentProviderServiceContentTypesSectionHeading,
  ComponentProviderServiceContentTypesShortText,
  ComponentProviderServiceContentTypesTestimonial,
  ComponentProviderServiceContentTypesImageLink,
  ComponentProviderServiceContentTypesLongText,
  ComponentProviderServiceContentTypesMediaFile,
  ComponentProviderServiceContentTypesTitleDescription,
  ComponentProviderServiceContentTypesValueProp,
  ComponentProviderServiceContentTypesUrl,
  ComponentProviderServiceContentTypesInternalLink,
} from '../../../model/strapi/strapi-models';
import { ImageLink } from '../image-link/image-link';
import { LongText } from '../long-text/long-text';
import { MediaFile } from '../media-file/media-file';
import { SectionHeading } from '../section-heading/section-heading';
import { ShortText } from '../short-text/short-text';
import { Testimonial } from '../testimonials/testimonials';
import { TitleDescription } from '../title-description/title-description';
import { Url } from '../url/url';
import { ValueProp } from '../value-prop/value-prop';

export const strapiComponentBuilder = (
  contentType: Maybe<
    | ComponentProviderServiceContentTypesSectionHeading
    | ComponentProviderServiceContentTypesShortText
    | ComponentProviderServiceContentTypesTestimonial
    | ComponentProviderServiceContentTypesLongText
    | ComponentProviderServiceContentTypesImageLink
    | ComponentProviderServiceContentTypesMediaFile
    | ComponentProviderServiceContentTypesTitleDescription
    | ComponentProviderServiceContentTypesValueProp
    | ComponentProviderServiceContentTypesUrl
    | ComponentProviderServiceContentTypesInternalLink
  >,
  mediaFileTrackEventHandler?: () => void
): JSX.Element => {
  switch (contentType?.__typename) {
    case 'ComponentProviderServiceContentTypesSectionHeading': {
      return (
        <SectionHeading
          key={contentType.id}
          headingText={contentType.HeadingText}
        />
      );
    }

    case 'ComponentProviderServiceContentTypesShortText': {
      return (
        <ShortText key={contentType.id} shortText={contentType.shortText} />
      );
    }

    case 'ComponentProviderServiceContentTypesTestimonial': {
      return <Testimonial key={contentType.id} carouselItem={contentType} />;
    }

    case 'ComponentProviderServiceContentTypesLongText': {
      return <LongText key={contentType.id} longText={contentType.longText} />;
    }

    case 'ComponentProviderServiceContentTypesImageLink': {
      return (
        <ImageLink
          key={contentType.id}
          imageFile={contentType.ImageFile}
          imageLinkUrl={contentType.ImageLinkUrl}
        />
      );
    }

    case 'ComponentProviderServiceContentTypesMediaFile': {
      return (
        <MediaFile
          key={contentType.id}
          isLink={contentType.IsLink}
          imageFile={contentType.ImageFile}
          linkDisplayText={contentType.LinkDisplayText}
          onClick={mediaFileTrackEventHandler}
        />
      );
    }

    case 'ComponentProviderServiceContentTypesTitleDescription': {
      return (
        <TitleDescription
          key={contentType.id}
          title={contentType.Title}
          description={contentType.Description}
          url={contentType.TitleDescriptionUrl}
        />
      );
    }

    case 'ComponentProviderServiceContentTypesValueProp': {
      return (
        <ValueProp
          key={contentType.id}
          title={contentType.valuePropTitle}
          text={contentType.valuePropText}
          iconData={contentType.valuePropIcon}
        />
      );
    }

    case 'ComponentProviderServiceContentTypesUrl': {
      return (
        <Url
          key={contentType.id}
          target={contentType.Target}
          urlAddress={contentType.URLAddress}
          displayText={contentType.DisplayText}
        />
      );
    }

    default: {
      return <></>;
    }
  }
};
