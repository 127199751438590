import { Autocomplete, Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { drugSearch } from '../../../api/drug-search-api/drug-search/drug-search';
import { debounce } from '@mui/material/utils';
import { unauthenticatedRouteConfig } from '../../../navigation/unauthenticated-route-config';
import { useNavigate } from 'react-router-dom';
import { useDrugSearchConfigContext } from '../../../hooks/use-durg-search-config-context/use-drug-search-config-context.hook';
import {
  IDrugResultNdcPackageDrugResultForm,
  IDrugResultPackageSearchResult,
} from '../../../model/drug-search';
import { drugSearchNdc } from '../../../api/drug-search-api/drug-search/drug-search-ndc';
import { EditPrescription } from './edit-prescription/edit-prescription';
import { DrugSearchScreenFooter } from './footer/drug-search-footer';
import { useTelemetryContext } from '../../../providers/telemetry/use-telemetry-context.hook';
import { getPharmacyByNpi } from '../../../api/drug-search-api/get-pharmacy-by-npi/get-pharamcy-by-npi';
import { IPharmacyByNpi } from '../../../model/pharmacy-by-coordinates';
import { IAddress } from '../../../model/address';
import { PhoneNumberFormatter } from '../../../formatters/phone-number/phone-number.formatter';
import { LoadingSpinner } from '../../loading/loading-spinner';
import { MyPrescryptiveLogo } from '../../myprescryptive-logo/myprescryptive-logo';

export const DrugSearchScreen = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const logoResize = <MyPrescryptiveLogo width='180px' height='65px' />;

  const navigate = useNavigate();
  const { drugSearchConfigState } = useDrugSearchConfigContext();
  const { telemetryService } = useTelemetryContext();

  document.title = t('drugSearch.title');

  const [drug, setDrug] = useState<IDrugResultPackageSearchResult | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [searchResults, setSearchResults] = useState<IDrugResultPackageSearchResult[]>([]);
  const [searchRequested, setSearchRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentForm, setCurrentForm] = useState<IDrugResultNdcPackageDrugResultForm | null>(null);
  const [npi, setNpi] = useState('');
  const [pharmacyData, setPharmacyData] = useState<IPharmacyByNpi>();
  const [isPharmacyDataLoading, setIsPharmacyDataLoading] = useState(false);

  const ndcRegex = new RegExp('^[0-9]*$');
  const isAutocompleteDisabled =
    npi.length !== 10 || pharmacyData === undefined || pharmacyData?.error;
  const isPriceSearchAvailable =
    drug && npi.length === 10 && currentForm && pharmacyData && !pharmacyData.error;

  useEffect(() => {
    if (npi.length === 10) {
      fetchPharmacyByNpi();
    }
  }, [npi]);

  useEffect(() => {
    if (ndcRegex.test(inputValue)) {
      if (inputValue.length === 11) {
        setLoading(true);
        fetchDrugByNdc();
      }
    } else if (inputValue && inputValue.length > 2) {
      setLoading(true);
      fetchDrugs();
    } else {
      setSearchRequested(false);
    }
  }, [inputValue]);

  const fetchPharmacyByNpi = async (npiToFetch = npi) => {
    setIsPharmacyDataLoading(true);
    setPharmacyData(undefined);

    await getPharmacyByNpi(
      drugSearchConfigState.apis.drugSearchApiConfig,
      drugSearchConfigState.apis.authentication.subscriptionKey,
      npiToFetch,
      telemetryService
    ).then((data) => {
      setIsPharmacyDataLoading(false);
      setPharmacyData(data);
    });
  };

  const fetchDrugs = () => {
    void debounce(async () => {
      setSearchRequested(true);

      await drugSearch(
        drugSearchConfigState.apis.drugSearchApiConfig,
        drugSearchConfigState.apis.authentication.subscriptionKey,
        inputValue,
        telemetryService
      ).then((data) => {
        setSearchResults(data);
        setLoading(false);
      });
    }, 800)();
  };

  const fetchDrugByNdc = () => {
    void debounce(async () => {
      setSearchRequested(true);

      await drugSearchNdc(
        drugSearchConfigState.apis.drugSearchApiConfig,
        drugSearchConfigState.apis.authentication.subscriptionKey,
        inputValue,
        telemetryService
      ).then((data) => {
        setSearchResults(data);
        setLoading(false);
      });
    }, 800)();
  };

  const handleNpiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 0) {
      setPharmacyData(undefined);
    }
    if (event.target.value.length <= 10) {
      setNpi(event.target.value);
    }
  };

  const handlePrescriptionUpdate = (updatedForm: IDrugResultNdcPackageDrugResultForm) => {
    setCurrentForm(updatedForm);
  };

  const handleNavigation = () => {
    void navigate(unauthenticatedRouteConfig.root.createUrl());
  };

  return (
    <Box display='flex' height='100vh' flexDirection='column'>
      <Box display='inherit' flexDirection='column' padding='40px 75px' gap={6}>
        <Button
          onClick={handleNavigation}
          sx={{ width: 'fit-content' }}
          data-testid='navigate-to-sign-in'
        >
          {logoResize}
        </Button>
        <Box display='inherit' flexDirection='column' gap={6} alignItems='baseline'>
          <Box display='flex' flexDirection='column' flex={3}>
            <Typography variant='h1' fontWeight='500' color={theme.palette.primary.main}>
              {t('drugSearch.screenHeader')}
            </Typography>
            <Typography variant='body1'>{t('drugSearch.screenSubheader')}</Typography>
          </Box>
          <Box display='inherit' flexDirection='row' gap={6} width='100%'>
            <Box flex={1}>
              <TextField
                value={npi}
                onChange={handleNpiChange}
                label={t('drugSearch.npiTextField')}
                fullWidth
                variant='outlined'
                data-testid='npi-text-field'
                color='primary'
                type='search'
                error={pharmacyData?.error}
                helperText={pharmacyData?.error ? t('drugSearch.npiHelperText') : null}
                InputProps={{
                  inputProps: {
                    'data-testid': 'npi-search-input',
                  },
                }}
              />
            </Box>
            <Box flex={3}>
              <Autocomplete
                disablePortal
                fullWidth
                data-testid='drug-search-autocomplete'
                autoComplete
                value={drug}
                loading={loading}
                noOptionsText={
                  searchRequested && searchResults.length === 0
                    ? t('drugSearch.autocompleteHelperText')
                    : 'No options'
                }
                options={searchResults}
                isOptionEqualToValue={(option, value) =>
                  option.representativeNdc === value.representativeNdc
                }
                onChange={(_, newValue: IDrugResultPackageSearchResult | null) => {
                  if (newValue && newValue.forms && newValue.forms[0] != null) {
                    const form = newValue.forms.find(
                      (form) => form.representativeNdc === newValue.representativeNdc
                    );

                    if (form) {
                      setCurrentForm(form);
                    }
                  }

                  setDrug(newValue);
                }}
                onInputChange={(_, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                getOptionLabel={(option) => `${option?.displayName ?? ''}`}
                filterOptions={(x) => x}
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      data-testid='drug-search-autocomplete-option'
                      data-value={option.representativeNdc}
                    >
                      <Box display='grid'>
                        <Typography>{option.displayName}</Typography>
                      </Box>
                    </li>
                  );
                }}
                disabled={isAutocompleteDisabled}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('drugSearch.autocompleteLabel')}
                    fullWidth
                    variant='outlined'
                    color='primary'
                    InputProps={{
                      ...params.InputProps,
                      inputProps: {
                        ...params.inputProps,
                        'data-testid': 'drug-search-input',
                      },
                    }}
                  />
                )}
              />
            </Box>
            <Box flex={1} />
          </Box>
          <Box display='inherit' flexDirection='row' gap={6} width='100%'>
            {isPharmacyDataLoading ? (
              <Box display='inherit' justifyContent='center' alignContent='center' flex={1}>
                <LoadingSpinner />
              </Box>
            ) : (
              <Box flex={1}>
                {pharmacyData && !pharmacyData.error ? (
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap={3}
                    data-testid='pharmacy-information'
                  >
                    <Box>
                      <Typography fontWeight='bold' variant='h6'>
                        {pharmacyData.brand}
                      </Typography>
                    </Box>
                    <Box display='flex' flexDirection='column'>
                      <Box>
                        <Typography variant='body2' color={theme.palette.grey[500]}>
                          {formatAddress(pharmacyData.address as unknown as IAddress)}
                        </Typography>
                      </Box>
                      <Typography variant='body2' color={theme.palette.grey[500]}>
                        {PhoneNumberFormatter.formatForUI(pharmacyData.phone ?? '')}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            )}
            <Box flex={3}>
              {isPriceSearchAvailable ? (
                <EditPrescription
                  value={drug}
                  npi={npi}
                  currentForm={currentForm}
                  updatePrescription={handlePrescriptionUpdate}
                />
              ) : null}
            </Box>
            <Box flex={1} />
          </Box>
        </Box>
      </Box>
      <DrugSearchScreenFooter />
    </Box>
  );
};

function formatAddress(address: IAddress): string {
  const formattedAddress = [address.line1, address.line2].filter(Boolean).join(' ');

  const zipCode =
    address.zip && address.zip.length === 9
      ? `${address.zip.slice(0, 5)}-${address.zip.slice(5)}`
      : address.zip;

  return `${formattedAddress}, ${address.city}, ${address.state} ${zipCode}`.trim();
}
