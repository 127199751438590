// Copyright 2021 Prescryptive Health, Inc.

import { Typography } from '@mui/material';
import { styled } from '@mui/material';

export const StyledUnblockTimeModalLabelTypography = styled(Typography)(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(2),
  lineHeight: theme.spacing(8),
}));

export const StyledUnblockTimeModalDetailTypography = styled(Typography)(({ theme }) => ({
  flex: 2,
  fontWeight: 'bold',
  lineHeight: theme.spacing(8),
}));

export const StyledUnblockModalConfirmationTypography = styled(Typography)({
  fontWeight: 'bold',
});
