// Copyright 2021 Prescryptive Health, Inc.

import { ReportsDispatch } from './reports.dispatch';
import { reportsCsvSetAction } from '../actions/reports-csv-set.action';

export const reportsCsvSetDispatch = (
  dispatch: ReportsDispatch,
  reportCsv: string[],
  reportFilename: string
): void => {
  dispatch(reportsCsvSetAction(reportCsv, reportFilename));
};
