// Copyright 2021 Prescryptive Health, Inc.

import { Box, Button, Divider } from '@mui/material';
import { styled as muiStyled } from '@mui/material';
import { Sync } from '@mui/icons-material';
import styled from 'styled-components';

export const StyledSwitchContainer = muiStyled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('tablet')]: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    alignItems: 'end',
  },
}));

export const StyledAppointmentsScreenMenuButton = muiStyled(Button)(({ theme }) => ({
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('tablet')]: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

export const StyledAppointmentsDivider = muiStyled(Divider)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.up('tablet')]: { display: 'none' },
}));

export const StyledAgendaViewContainer = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const StyledControlsContainer = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(6),
  zIndex: theme.zIndex.appBar,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const StyledMainSection = muiStyled(Box)({
  display: 'flex',
  flexGrow: 1,
  flexShrink: 1,
  flexDirection: 'column',
});

export const StyledBlockTimeContainer = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  [theme.breakpoints.up('tablet')]: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(6),
  },
}));

export const StyledSyncButton = muiStyled(Button)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('tablet')]: {
    display: 'flex',
  },
}));

export const SpinnerLastSync = styled(Sync)`
  transform: rotateY(180deg);
  animation: rotate 2s linear infinite;
  @keyframes rotate {
    100% {
      transform: rotateX(-180deg);
    }
  }
`;

export const StyledLastSync = muiStyled(Sync)({
  transform: 'rotateY(180deg)',
});
