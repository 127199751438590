// Copyright 2021 Prescryptive Health, Inc.

import { HttpStatusCodesEnum } from '../../http-status-codes';
import { buildUrl, buildBearerAuthHeaders } from '../../api.helper';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { call } from '../../call';
import { ApiErrors } from '../../errors/api-errors';
import { IPharmacyServiceUpdateRequest } from './pharmacy-service-update.request';
import { ServiceStatus } from '../../../model/pharmacy-service';
import { serviceStatusToRequest } from '../pharmacy-services-get/pharmacy-services.api-helper';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export async function pharmacyServiceUpdate(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  locationId: string,
  serviceType: string,
  status: ServiceStatus,
  duration: number,
  quantityAdjustment = 0,
  minLeadHours: number,
  screenDescription?: string,
  confirmationAdditionalInfo?: string
): Promise<void> {
  const url = buildUrl(apiConfig, 'pharmacyServiceUpdate', {
    ':locationId': locationId,
    ':serviceType': serviceType,
  });
  const headers = buildBearerAuthHeaders(authToken);
  const body: IPharmacyServiceUpdateRequest = {
    status: serviceStatusToRequest(status),
    duration,
    quantityAdjustment,
    screenDescription,
    confirmationAdditionalInfo,
    minLeadHours,
  };
  const response: Response = await call(url, body, 'PATCH', headers);
  if (response.ok) {
    return;
  }

  if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
    throw new PharmacyPortalError(
      ApiErrors.resourceNotFound(url),
      ApiErrorCode.PHARMACY_SERVICE_UPDATE,
      PharmacyPortalErrorType.API_ERROR,
      HttpStatusCodesEnum.NOT_FOUND
    );
  }

  throw new PharmacyPortalError(
    ApiErrors.pharmacyServiceUpdate(locationId, serviceType, response.statusText),
    ApiErrorCode.PHARMACY_SERVICE_UPDATE,
    PharmacyPortalErrorType.API_ERROR,
    response.status
  );
}
