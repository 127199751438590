// Copyright 2021 Prescryptive Health, Inc.

export type ScreenType = 'primary' | 'secondary';

export interface IScreenState {
  screenType: ScreenType;
}

export const defaultScreenState: IScreenState = {
  screenType: 'primary',
};
