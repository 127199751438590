import { ICustomProperties } from '@microsoft/applicationinsights-web';
import { TelemetryEvent } from '../../providers/telemetry/telemetry.context';
import { TelemetryService } from '../../providers/telemetry/telemetry.service';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../pharmacy-portal-error';
import { ApiErrorCode } from './api-error-code';
import { AuthenticationProviderUndefinedError } from './authentication-provider-undefined.error';
import { NonFatalError } from './non-fatal.error';
import { LoginCancelledError } from './login-cancelled.error';
import { LoginInProgressError } from './login-in-progress.error';

export interface ILogApiCustomProperties extends ICustomProperties {
  errorType: string;
  errorCode: string;
  httpStatusCode?: string;
}

export function logApiError(
  telemetryService: TelemetryService<TelemetryEvent>,
  apiError: unknown
): void {
  if (apiError instanceof AuthenticationProviderUndefinedError) {
    telemetryService.trackException(
      apiError as Error,
      {
        errorType: PharmacyPortalErrorType.API_ERROR,
        errorCode: ApiErrorCode.AUTHENTICATION_UNDEFINED,
      } as ILogApiCustomProperties
    );
  } else if (apiError instanceof NonFatalError) {
    telemetryService.trackException(
      apiError as NonFatalError,
      {
        errorType: PharmacyPortalErrorType.API_ERROR,
        errorCode: ApiErrorCode.NON_FATAL_ERROR,
      } as ILogApiCustomProperties
    );
  } else if (apiError instanceof LoginCancelledError) {
    telemetryService.trackException(apiError as LoginCancelledError, {
      errorType: PharmacyPortalErrorType.API_ERROR,
      errorCode: ApiErrorCode.LOGIN_CANCELLED_ERROR,
    });
  } else if (apiError instanceof LoginInProgressError) {
    telemetryService.trackException(apiError as LoginCancelledError, {
      errorType: PharmacyPortalErrorType.API_ERROR,
      errorCode: ApiErrorCode.LOGIN_IN_PROGRESS_ERROR,
    });
  } else if (apiError instanceof PharmacyPortalError) {
    telemetryService.trackException(apiError.error, {
      errorType: apiError.errorType,
      errorCode: apiError.errorCode,
      httpStatusCode: apiError.httpStatus,
    } as ILogApiCustomProperties);
  } else {
    telemetryService.trackException(apiError as Error);
  }
}
