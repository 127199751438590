// Copyright 2020 Prescryptive Health, Inc.

import { Box } from '@mui/material';
import { styled as muiStyled } from '@mui/material';

export const StyledLoadingSpinnerDiv = muiStyled(Box)(({ theme }) => ({
  borderRadius: 4,
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: theme.palette.primary.main,
  opacity: 0.9,
}));

export const GrayedOutBackground = muiStyled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.action.disabledBackground,
  width: '100%',
  height: '100%',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 0.5,
  display: 'flex',
  zIndex: theme.zIndex.modal + 1,
}));

export const StyledLoadingText = muiStyled(Box)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
}));

export const StyledCircularProgress = muiStyled(Box)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  width: '100px',
  display: 'flex',
  padding: theme.spacing(1),
}));
