// Copyright 2021 Prescryptive Health, Inc.

import { Box, Link, Typography, useTheme } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { ReactElement } from 'react';
import {
  EnrollWidgetBox,
  HelpDynamicTitleTypography,
} from './enroll-widget.styled-components';
import { EnrollmentStatus } from '../../../../../model/enrolled-service-type';
import {
  ProviderProgramEnrollProcessDynamicZone,
  ProviderProgramHelpSectionDynamicZone,
  ProviderServiceEnrollProcessDynamicZone,
  ProviderServiceHelpSectionDynamicZone,
} from '../../../../../model/strapi/strapi-models';
import { useTelemetryContext } from '../../../../../providers/telemetry/use-telemetry-context.hook';
import { useProviderContext } from '../../../../../providers/provider/use-provider-context.hook';
import { SectionHeading } from '../../../../strapi/section-heading/section-heading';
import { ValueProp } from '../../../../strapi/value-prop/value-prop';

interface IEnrollWidgetProps {
  helpData:
    | Maybe<
        | ProviderServiceHelpSectionDynamicZone
        | ProviderProgramHelpSectionDynamicZone
      >[]
    | null
    | undefined;
  enrollmentData:
    | Maybe<
        | ProviderServiceEnrollProcessDynamicZone
        | ProviderProgramEnrollProcessDynamicZone
      >[]
    | null
    | undefined;
  enrollmentStatus: EnrollmentStatus | undefined;
}

export const EnrollWidget = ({
  helpData,
  enrollmentData,
  enrollmentStatus,
}: IEnrollWidgetProps): ReactElement => {
  const { telemetryService } = useTelemetryContext();
  const {
    providerState: { currentProvider },
  } = useProviderContext();
  const theme = useTheme();

  const valueProps = enrollmentData?.map((elem) => {
    switch (elem?.__typename) {
      case 'ComponentProviderServiceContentTypesSectionHeading': {
        return <SectionHeading key={elem.id} headingText={elem.HeadingText} />;
      }
      case 'ComponentProviderServiceContentTypesValueProp': {
        return (
          <ValueProp
            key={elem.id}
            title={elem.valuePropTitle}
            text={elem.valuePropText}
            iconData={elem.valuePropIcon}
          />
        );
      }
      default:
        return null;
    }
  });

  const onLinkPress = () => {
    telemetryService.trackEvent('click', {
      name: 'add-services-detail-emailPharmacyRelations',
      provider: currentProvider?.name,
    });
  };

  const helpSection = helpData?.map((elem) => {
    switch (elem?.__typename) {
      case 'ComponentProviderServiceContentTypesSectionHeading': {
        return <SectionHeading key={elem.id} headingText={elem.HeadingText} />;
      }
      case 'ComponentProviderServiceContentTypesTitleDescription': {
        const urlSection = elem.TitleDescriptionUrl ? (
          <Link
            target={elem.TitleDescriptionUrl.Target ?? ''}
            href={elem.TitleDescriptionUrl.URLAddress}
            onClick={onLinkPress}
            underline="hover">
            {elem.TitleDescriptionUrl.DisplayText}
          </Link>
        ) : null;

        return (
          <Box key={elem.id}>
            <HelpDynamicTitleTypography>
              {elem.Title}
            </HelpDynamicTitleTypography>
            <Typography>{elem.Description}</Typography>
            <Typography>{urlSection}</Typography>
          </Box>
        );
      }
      case 'ComponentProviderServiceContentTypesLongText': {
        return <Typography key={elem.id}>{elem.longText}</Typography>;
      }
      case 'ComponentProviderServiceContentTypesValueProp': {
        return (
          <ValueProp
            key={elem.id}
            title={elem.valuePropTitle}
            text={elem.valuePropText}
            iconData={elem.valuePropIcon}
          />
        );
      }
      default:
        return null;
    }
  });

  const linkSection = helpData?.map((elem) => {
    switch (elem?.__typename) {
      case 'ComponentProviderServiceContentTypesShortText': {
        return elem.shortText;
      }
      case 'ComponentProviderServiceContentTypesUrl': {
        return (
          <Link key={elem.id} href={elem.URLAddress} underline="hover">
            {elem.DisplayText}
          </Link>
        );
      }
      default:
        return null;
    }
  });

  const enrollmentSection = !enrollmentStatus ? <Box>{valueProps}</Box> : null;

  return (
    <EnrollWidgetBox>
      {enrollmentSection}
      <Box>
        {helpSection}
        <Box
          display='flex'
          flexDirection='row'
          paddingBottom={theme.spacing(6)}
        >
          <Typography>{linkSection}</Typography>
        </Box>
      </Box>
    </EnrollWidgetBox>
  );
};
