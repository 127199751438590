// Copyright 2021 Prescryptive Health, Inc.

import { IProgram } from '../../../model/program';
import { IPostAuthAction } from './post-auth.action';

export type IProgramsActionSet = IPostAuthAction<'PROGRAMS_SET'>;

export const programsSetAction = (
  programs: IProgram[]
): IProgramsActionSet => ({
  type: 'PROGRAMS_SET',
  payload: { programs },
});
