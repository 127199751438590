// Copyright 2022 Prescryptive Health, Inc.

import { Box } from '@mui/material';
import { styled as muiStyled } from '@mui/material';

export const SecondaryScreenChildContainer = muiStyled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 0,
    paddingRight: 0,
    width: '866px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
