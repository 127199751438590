import { IDrugResultPackageSearchResult } from '../../../model/drug-search';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { TelemetryEvent } from '../../../providers/telemetry/telemetry.context';
import { TelemetryService } from '../../../providers/telemetry/telemetry.service';
import { IDrugSearchApiConfig } from '../../../state/drug-search/drug-search.state';
import { call } from '../../call';
import { logApiError } from '../../errors/log-api-error';
import { buildDrugSearchUrl } from '../api.helper';
import { DrugSearchApiErrorCode } from '../errors/drug-search-api-error-code';
import { DrugSearchApiErrors } from '../errors/drug-search-api-errors';
import { IDrugResultPackageSearchResultResponse } from './drug-search-response';
import { drugDetailsFromResponse } from './drug-search-response-helper';

export async function drugSearch(
  apiConfig: IDrugSearchApiConfig,
  subscriptionKey: string,
  filter: string,
  telemetryService: TelemetryService<TelemetryEvent>
): Promise<IDrugResultPackageSearchResult[]> {
  const url = buildDrugSearchUrl(apiConfig, 'drugSearch', {
    ':filter': filter,
  });

  const response: Response = await call(url, undefined, 'GET', {
    ['Ocp-Apim-Subscription-Key']: subscriptionKey,
  });

  if (response.ok) {
    return await drugResultsFromResponse(response);
  }

  logApiError(
    telemetryService,
    new PharmacyPortalError(
      DrugSearchApiErrors.drugSearch(filter),
      DrugSearchApiErrorCode.DRUG_SEARCH,
      PharmacyPortalErrorType.API_ERROR,
      response.status
    )
  );
  return [];
}

async function drugResultsFromResponse(
  response: Response
): Promise<IDrugResultPackageSearchResult[]> {
  const responseData = (await response.json()) as IDrugResultPackageSearchResultResponse[];

  return drugDetailsFromResponse(responseData);
}
