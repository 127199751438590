// Copyright 2021 Prescryptive Health, Inc.

import { buildBearerAuthHeaders, buildUrl } from '../../api.helper';
import { call } from '../../call';
import { HttpStatusCodesEnum } from '../../http-status-codes';
import { IPharmacyPortalApiConfig } from '../../../state/config/config.state';
import { ApiErrors } from '../../errors/api-errors';
import {
  IWaitlistInviteRequest,
  IWaitlistInviteRequestInvitation,
} from './waitlist-invite.request';
import { IWaitlistInvitation } from '../../../model/waitlist-invitation';
import { IBatchFailureStatus, IBatchStatus } from '../../../model/batch-status';
import { IWaitlistInviteResponse } from './waitlist-invite.response';
import { PharmacyPortalError, PharmacyPortalErrorType } from '../../../pharmacy-portal-error';
import { ApiErrorCode } from '../../errors/api-error-code';

export async function waitlistInvite(
  apiConfig: IPharmacyPortalApiConfig,
  authToken: string,
  locationId: string,
  invitations: IWaitlistInvitation[]
): Promise<IBatchStatus> {
  const url = buildUrl(apiConfig, 'waitlistInvite', {
    ':locationId': locationId,
  });
  const headers = buildBearerAuthHeaders(authToken);

  const requestItems: IWaitlistInviteRequestInvitation[] = invitations.map((invitation) => {
    return {
      waitlistItemIdentifier: invitation.waitlistItemId,
      start: invitation.startDate.toISOString(),
      end: invitation.endDate.toISOString(),
    };
  });
  const body: IWaitlistInviteRequest = { invitations: requestItems };

  const response: Response = await call(url, body, 'PATCH', headers);

  if (!response.ok) {
    if (response.status === HttpStatusCodesEnum.NOT_FOUND) {
      throw new PharmacyPortalError(
        ApiErrors.resourceNotFound(url),
        ApiErrorCode.WAITLIST_INVITE,
        PharmacyPortalErrorType.API_ERROR,
        HttpStatusCodesEnum.NOT_FOUND
      );
    }

    throw new PharmacyPortalError(
      ApiErrors.waitlistInvite(response.statusText),
      ApiErrorCode.WAITLIST_INVITE,
      PharmacyPortalErrorType.API_ERROR,
      response.status
    );
  }

  if (response.status !== HttpStatusCodesEnum.MULTI_STATUS) {
    throw new PharmacyPortalError(
      ApiErrors.waitlistInvite(ApiErrors.unexpectedResponseDataFormat),
      ApiErrorCode.WAITLIST_INVITE,
      PharmacyPortalErrorType.API_ERROR,
      response.status
    );
  }

  const failures = await getFailuresFromResponse(response);
  return {
    batchSize: invitations.length,
    failures,
  };
}

const getFailuresFromResponse = async (response: Response): Promise<IBatchFailureStatus[]> => {
  const responseData = (await response.json()) as IWaitlistInviteResponse;
  if (!responseData) {
    throw new Error(ApiErrors.waitlistInvite(ApiErrors.unexpectedResponseDataFormat));
  }

  const failedResponses = responseData.failed || [];

  const result: IBatchFailureStatus[] = failedResponses.map((failureResponse) => {
    return {
      id: failureResponse.waitlistItemIdentifier,
      reason: failureResponse.reason ?? '',
    };
  });

  return result;
};
