// Copyright 2021 Prescryptive Health, Inc.

import { ComponentType, ReactElement } from 'react';
import { LocaleContextProvider } from './providers/locale/locale.context-provider';
import { ThemeContextProvider } from './providers/theme/theme.context-provider';
import { SessionContextProvider } from './providers/session/session.context-provider';
import { ErrorContextProvider } from './providers/error/error.context-provider';
import { Navigator } from './navigation/navigator';
import { TelemetryContextProvider } from './providers/telemetry/telemetry.context-provider';
import { ErrorBoundary } from './components/error-boundary/error-boundary';
import { StyledThemeContextProvider } from './providers/styled-theme/styled-theme.context-provider';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { initApolloClient } from './init-apollo-client';
import { ApolloProvider } from '@apollo/client';
import { ContainerHierarchy } from './components/container-hierarchy/container-hierarchy';
import { BusyContextProvider } from './providers/busy/busy.context-provider';
import { getMetaEnvironmentVariable } from './config/config.helper';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { CustomLuxonUtils } from './theming/custom-luxon';
import { BrowserRouter as Router } from 'react-router-dom';
import { ScreenContextProvider } from './providers/screen/screen.context-provider';
import { ConfigContextProvider } from './providers/config/config.context-provider';
import AuthProvider from './components/auth-provider/auth-provider';
import { LicenseInfo } from '@mui/x-license-pro';

const client = initApolloClient();

const App = withLDProvider({
  clientSideID: getMetaEnvironmentVariable('LAUNCH_DARKLY_KEY'),
  user: {
    anonymous: true,
  },
  options: { bootstrap: 'localStorage' },
})((): ReactElement => {
  const containerList: ComponentType[] = [
    StyledThemeContextProvider,
    ConfigContextProvider,
    LocaleContextProvider,
    ThemeContextProvider,
    ScreenContextProvider,
    BusyContextProvider,
    ErrorContextProvider,
    TelemetryContextProvider,
    ErrorBoundary,
    SessionContextProvider,
  ];

  LicenseInfo.setLicenseKey(getMetaEnvironmentVariable('MUI_LICENSE_KEY'));

  return (
    <LocalizationProvider dateAdapter={CustomLuxonUtils}>
      <Router>
        <AuthProvider>
          <ContainerHierarchy containerList={containerList}>
            <ApolloProvider client={client}>
              <Navigator />
            </ApolloProvider>
          </ContainerHierarchy>
        </AuthProvider>
      </Router>
    </LocalizationProvider>
  );
});

export default App;
