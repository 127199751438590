import { ReactElement } from 'react';
import { StyledSignInButton } from './sign-in-button.styled-components';
import { Avatar, Box, Divider, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ISignInButtonProps {
  onSignInPress: () => void;
  dividerBackgroundColor: string;
}

export const SignInButton = ({
  onSignInPress,
  dividerBackgroundColor,
}: ISignInButtonProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { auth0Migration } = useFlags();

  const rebrandContent = (
    <Typography style={{ marginLeft: theme.spacing(1) }}>{t('lander.prescryptivePro')}</Typography>
  );

  return (
    <Box style={{ paddingLeft: theme.spacing(15), paddingRight: theme.spacing(15) }}>
      {auth0Migration && (
        <StyledSignInButton
          onClick={onSignInPress}
          size='large'
          variant='contained'
          style={{
            display: 'flex',
            alignItems: 'center',
            background: theme.palette.purpleScale['300'],
          }}
        >
          <Typography>{t('lander.signInButtonAuth0')}</Typography>
          {rebrandContent}
        </StyledSignInButton>
      )}

      {auth0Migration && (
        <div style={{ padding: `${theme.spacing(8.5)} 0` }}>
          <Divider style={{ backgroundColor: dividerBackgroundColor }}>
            {t('lander.orText')}
          </Divider>
        </div>
      )}

      <StyledSignInButton
        onClick={onSignInPress}
        size='large'
        variant='contained'
        style={{
          display: 'flex',
          alignItems: 'center',
          background: `linear-gradient(to right, ${theme.palette.blueScale['50']}, ${theme.palette.blueScale['200']})`,
        }}
      >
        <Typography>{t('lander.signInButton')}</Typography>
        <Avatar
          src={process.env.PUBLIC_URL + '/media/icons/microsoft-logo.svg'}
          variant='square'
          alt='microsoft logo'
          style={{
            height: theme.spacing(6),
            width: theme.spacing(6),
            paddingLeft: theme.spacing(1),
          }}
        />
      </StyledSignInButton>
    </Box>
  );
};
