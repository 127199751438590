// Copyright 2021 Prescryptive Health, Inc.

import React, { Fragment, ReactElement } from 'react';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  ComponentProviderServiceContentTypesTitleDescription,
  ProviderLanderSection,
} from '../../../../model/strapi/strapi-models';
import { strapiComponentBuilder } from '../../../strapi/helpers/strapi-component-builder';
import { Carousel } from './sign-up-carousel-section.styled-components';

interface SignUpCarouselSectionProps {
  section?: ProviderLanderSection | null;
}

export const SignUpCarouselSection = (props: SignUpCarouselSectionProps): ReactElement => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  const titleDescription = props.section?.ContentZone?.find(
    (zone) => zone?.__typename === 'ComponentProviderServiceContentTypesTitleDescription'
  ) as ComponentProviderServiceContentTypesTitleDescription | null | undefined;

  if (
    !titleDescription ||
    !titleDescription.Title ||
    !props.section?.lander_carousel_items ||
    props.section.lander_carousel_items.length === 0
  ) {
    return <Fragment />;
  }

  const carouselItems = props.section.lander_carousel_items.map(
    (item) =>
      item && (
        <Box key={item.id} display='grid' justifyContent='center' gap={theme.spacing(2)}>
          {item.CarouselItemContent?.map(
            (content) =>
              content && (
                <Box flex='1' key={`${content.id || ''}-container`} maxWidth='610px'>
                  {strapiComponentBuilder(content)}
                </Box>
              )
          )}
        </Box>
      )
  );

  return (
    <Box display='grid' gap={theme.spacing(6)} padding={theme.spacing(8)}>
      <Box display='grid' justifyItems='center' textAlign='center' gap={theme.spacing(6)}>
        <Box>
          <Typography variant='h4'>{titleDescription.Title}</Typography>
        </Box>
        <Box maxWidth='792px'>
          <Typography>{titleDescription.Description}</Typography>
        </Box>
      </Box>
      <Carousel
        navButtonsAlwaysVisible={isDesktop && carouselItems.length > 1}
        navButtonsAlwaysInvisible={carouselItems.length <= 1}
        autoPlay={false}
        indicators={carouselItems.length > 1}
        swipe={true}
        NextIcon={<ChevronRightIcon style={{ fill: theme.palette.grey.A700 }} />}
        PrevIcon={<ChevronLeftIcon style={{ fill: theme.palette.grey.A700 }} />}
        fullHeightHover={false}
        navButtonsProps={{
          style: {
            backgroundColor: theme.palette.grey.A100,
          },
        }}
      >
        {carouselItems}
      </Carousel>
    </Box>
  );
};
