// Copyright 2020 Prescryptive Health, Inc.

import { IRetryPolicy } from './retry-policies/retry-policy.helper';
import { fetchRetry } from './fetch-retry';

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export async function call(
  endpoint: string,
  data: unknown = null,
  method: HttpMethod = 'GET',
  header = {},
  retryPolicy?: IRetryPolicy
): Promise<Response> {
  const url = `${endpoint}`;

  const headerContent = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...header,
  };

  if (['GET', 'DELETE'].indexOf(method) !== -1 && data) {
    throw Error(`Body data is not supported for method ${method}!`);
  }

  const requestOptions = {
    body:
      (['POST', 'PUT', 'PATCH'].indexOf(method) !== -1 && data !== null && JSON.stringify(data)) ||
      null,

    headers: new Headers(headerContent),
    method,
  } as Partial<RequestInit>;

  return await fetchRetry(url, requestOptions, fetch, retryPolicy);
}
