// Copyright 2021 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import { ServiceStatus } from '../../../../../../model/pharmacy-service';
import { LabeledDetail } from '../../../../../layout/labeled-detail/labeled-detail';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface IServiceStatusPickerProps {
  label: string;
  initialValue?: ServiceStatus;
  onSelect: (value: ServiceStatus) => void;
  isDisabled?: boolean;
}

export const ServiceStatusPicker = ({
  label,
  initialValue,
  onSelect,
  isDisabled,
}: IServiceStatusPickerProps): ReactElement => {
  const { t } = useTranslation();
  const onChange = (event: SelectChangeEvent) => {
    onSelect(event.target.value as ServiceStatus);
  };

  return (
    <Box>
      <FormControl disabled={isDisabled} fullWidth={true}>
        <InputLabel>{label}</InputLabel>
        <Tooltip
          title={
            isDisabled ? t('services.general.serviceStatus.pendingServiceAvailabilityTooltip') : ''
          }
        >
          <Select value={initialValue} onChange={onChange}>
            <MenuItem value='everyone'>
              {t('services.general.serviceStatus.statusEveryone')}
            </MenuItem>
            <MenuItem value='invite-only'>
              {t('services.general.serviceStatus.statusInviteOnly')}
            </MenuItem>
            <MenuItem value='off'>{t('services.general.serviceStatus.statusOff')}</MenuItem>
          </Select>
        </Tooltip>
      </FormControl>
      <LabeledDetail
        description={t('services.general.serviceStatus.description')}
        additionalDescription={t('services.general.serviceStatus.additionalDescription')}
      />
    </Box>
  );
};
