// Copyright 2021 Prescryptive Health, Inc.
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { getMetaEnvironmentVariable } from './config/config.helper';
import { RestLink } from 'apollo-link-rest';
import { setContext } from '@apollo/client/link/context';
import { authenticationProvider } from './authentication-initialize';

const restLink = new RestLink({
  uri: `https://${getMetaEnvironmentVariable(
    'PHARMACY_PORTAL_API_HOST_URL'
  )}:${getMetaEnvironmentVariable('PHARMACY_PORTAL_API_PORT')}`,
});

const createAuthLink = () => {
  return setContext(async (_, { headers }) => {
    const { accessToken } = await authenticationProvider.acquireToken();
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  });
};

export default new ApolloClient({
  link: createAuthLink().concat(restLink),
  cache: new InMemoryCache(),
});
