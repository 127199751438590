// Copyright 2021 Prescryptive Health, Inc.

import { showBusyWhile } from '../busy/dispatch/show-busy-while';
import { fatalErrorDispatch } from '../error/dispatch/fatal-error.dispatch';
import { AuthenticationProviderUndefinedError } from '../../api/errors/authentication-provider-undefined.error';
import { laneAvailabilityUpdate } from '../../api/v1/lane-availability-update/lane-availability-update';
import { ILaneInfo } from '../../model/location-availability';
import { IApiAsyncActionArgs } from '../api-async-action-args';
import { locationAvailabilityIsStaleDispatch } from '../location-availability/dispatch/location-availability-is-stale.dispatch';
import { LocationAvailabilityDispatch } from '../location-availability/dispatch/location-availability.dispatch';
import { logApiError } from '../../api/errors/log-api-error';

export interface ILaneAvailabilityUpdateAsyncActionArgs extends IApiAsyncActionArgs {
  availabilityId: string;
  lanes: ILaneInfo[];
  locationAvailabilityDispatch: LocationAvailabilityDispatch;
}

export const laneAvailabilityUpdateAsyncAction = async (
  args: ILaneAvailabilityUpdateAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: ILaneAvailabilityUpdateAsyncActionArgs): Promise<void> => {
  const { availabilityId, lanes, telemetryService, locationAvailabilityDispatch, getAuthToken } =
    args;

  try {
    telemetryService.trackEvent('lane-availability-update', {
      availabilityId,
      lanes,
    });

    const { configState, authProvider } = args;

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    await laneAvailabilityUpdate(
      configState.apis.pharmacyPortal,
      accessToken,
      availabilityId,
      lanes
    );

    locationAvailabilityIsStaleDispatch(locationAvailabilityDispatch);
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
    return Promise.reject();
  }
};
