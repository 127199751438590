// Copyright 2021 Prescryptive Health, Inc.

import { IWaitlistItem } from '../../../../model/waitlist-item';
import { IWaitlistAction } from './waitlist.action';

export type IWaitlistSetAction = IWaitlistAction<'SET'>;

export const waitlistSetAction = (
  waitlist: IWaitlistItem[]
): IWaitlistSetAction => ({
  type: 'SET',
  payload: {
    waitlist,
    isStale: false,
  },
});
