// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useEffect } from 'react';
import { PharmacySummary } from '../../pharmacy-summary/pharmacy-summary';
import { PharmacyLocationCard } from '../../location-card/pharmacy-location-card';
import { IStoreLocation } from '../../../../../../model/store-location';
import { IProvider } from '../../../../../../model/provider';
import { IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import { Box, Divider } from '@mui/material';
import { AddLocationCard } from '../../location-card/add-location-card';
import { TabPanel } from '../../../../../tabs/tab-panel/tab-panel';
import { useTelemetryContext } from '../../../../../../providers/telemetry/use-telemetry-context.hook';
import { BoxProps } from '@mui/material';
import {
  StyledLocationCardsContainer,
  StyledProviderInformationLocationDescriptionTextTypography,
  StyledProviderInformationLocationTitleTypography,
} from '../../../settings.screen.styled-components';
import { v4 as uuidv4 } from 'uuid';
import { ProviderInformationDividerContainer } from './provider-information.tab-panel.styled-components';
import { useTranslation } from 'react-i18next';

export interface IProviderInformationTabPanelProps extends BoxProps {
  currentProvider?: IProvider;
  locations?: IStoreLocation[];
}

export const ProviderInformationTabPanel = ({
  currentProvider,
  locations = [],
  ...other
}: IProviderInformationTabPanelProps): ReactElement => {
  const { t } = useTranslation();
  const { telemetryService } = useTelemetryContext();

  useEffect(() => {
    const telemetryPayload: IPageViewTelemetry = {
      name: 'settings-pharmacy-information',
      properties: {
        provider: currentProvider?.name,
      },
    };
    telemetryService.trackPageView(telemetryPayload);
  }, []);

  const locationCards = locations.map((location) => {
    return (
      <PharmacyLocationCard
        key={location.id}
        locationId={location.id}
        locationName={location.name}
        address1={location.address.line1}
        city={location.address.city}
        state={location.address.state}
        zipCode={location.address.zip}
        timezone={location.timezone}
        phoneNumber={location.phoneNumber}
        lanes={location?.lanes ?? []}
        isTest={location.isTest}
      />
    );
  });

  const renderProviderInformationTabPanel = currentProvider ? (
    <>
      <Box overflow='auto'>
        <PharmacySummary
          providerName={currentProvider.name ?? ''}
          npi={currentProvider.npiNumber ?? ''}
          cliaNumber={
            currentProvider.cliaNumber && currentProvider.cliaNumber.length > 0
              ? currentProvider.cliaNumber
              : 'N/A'
          }
          taxId={currentProvider.providerTaxId ?? ''}
          deaNumber={currentProvider.deaNumber ?? ''}
          contactPhoneNumber={currentProvider.phoneNumber ?? ''}
          contactEmail={currentProvider.email ?? ''}
        />
        <ProviderInformationDividerContainer>
          <Divider />
        </ProviderInformationDividerContainer>
        <div>
          <StyledProviderInformationLocationTitleTypography variant='h5'>
            {t('settings.providerInformationTabPanel.siteAddressLabel')}
          </StyledProviderInformationLocationTitleTypography>
          <StyledProviderInformationLocationDescriptionTextTypography>
            {t('settings.providerInformationTabPanel.locationSectionDescription')}
          </StyledProviderInformationLocationDescriptionTextTypography>
          <StyledLocationCardsContainer>
            {locationCards}
            <AddLocationCard key={uuidv4()} providerName={currentProvider?.name ?? ''} />
          </StyledLocationCardsContainer>
        </div>
      </Box>
    </>
  ) : null;

  return <TabPanel {...other}>{renderProviderInformationTabPanel}</TabPanel>;
};
