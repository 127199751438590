// Copyright 2021 Prescryptive Health, Inc.

import { Box, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { ReactElement } from 'react';
import { PhoneMaskInput } from '../../../../../inputs/mask-inputs/phone-mask-input/phone-mask-input';
import { PhoneNumberFormatter } from '../../../../../../formatters/phone-number/phone-number.formatter';

export interface IProviderInfoDetailProps {
  label: string;
  value: string;
  isEditMode?: boolean;
  disabled?: boolean;
  helperText?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
  error?: boolean;
  isPhoneNumber?: boolean;
  phoneNumberOnChange?: (input: string) => void;
}
export const ProviderInfoDetail = ({
  label,
  value,
  isEditMode = false,
  disabled,
  helperText,
  onChange,
  maxLength = 100,
  error = false,
  isPhoneNumber = false,
  phoneNumberOnChange,
}: IProviderInfoDetailProps): ReactElement => {
  const theme = useTheme();
  const infoContent = isEditMode ? (
    isPhoneNumber ? (
      <PhoneMaskInput
        onPhoneNumberChange={phoneNumberOnChange}
        initialValue={value}
        isError={error}
        errorMessage={helperText}
        data-heap-redact-text='true'
        disabled={disabled}
        inputProps={{ maxLength }}
      />
    ) : (
      <TextField
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        variant='filled'
        helperText={helperText}
        inputProps={{ maxLength }}
        error={error}
      />
    )
  ) : (
    <Box display='flex' flexDirection='column' gap={theme.spacing(1)}>
      <Typography color='textSecondary' component='p'>
        {label}
      </Typography>
      <Typography data-heap-redact-text='true'>{isPhoneNumber ? PhoneNumberFormatter.formatForUI(value) : value}</Typography>
      {helperText ? (
        <Typography variant='caption'>{helperText}</Typography>
      ) : null}
    </Box>
  );

  return (
    <Box display='flex' flexDirection='column'>
      {infoContent}
    </Box>
  );
};
