// Copyright 2021 Prescryptive Health, Inc.
import { Box } from '@mui/material';
import { styled } from '@mui/styles';

export const ProviderInformationDividerContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
  },
}));
