// Copyright 2020 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import dateFormatter from '../../../../formatters/dates/date.formatter';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';
import {
  StyledUnblockTimeModalLabelTypography,
  StyledUnblockTimeModalDetailTypography,
  StyledUnblockModalConfirmationTypography,
} from './unblock-time.modal.styled-components';
import { useTranslation } from 'react-i18next';

interface IUnblockConfirmationModalProps {
  durationMinutes: number;
  reason: string;
  startDate: Date;
  isOpen?: boolean;
  onKeepPress: () => void;
  onUnblockPress: () => void;
}

export const UnblockTimeModal = ({
  durationMinutes,
  reason,
  startDate,
  isOpen = false,
  onKeepPress,
  onUnblockPress,
}: IUnblockConfirmationModalProps): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation();

  const hasStartDate = startDate !== new Date(0);
  const formattedStartDate = hasStartDate ? dateFormatter.formatToLongDate(startDate) : '';
  const formattedTimeRange = hasStartDate
    ? dateFormatter.formatToTimeRange(startDate, durationMinutes)
    : '';

  const summaryRows = [
    {
      label: t('blockedTimeDetails.unblockTimeModal.dateLabel'),
      formattedValue: formattedStartDate,
    },
    {
      label: t('blockedTimeDetails.unblockTimeModal.timeLabel'),
      formattedValue: formattedTimeRange,
    },
    { label: t('blockedTimeDetails.unblockTimeModal.reasonLabel'), formattedValue: reason },
  ];

  return (
    <Dialog
      open={isOpen}
      maxWidth='md'
      id='unblock-time-modal'
      aria-labelledby='unblock-time-modal-title'
    >
      <DialogTitle id='unblock-time-modal-title'>
        {t('blockedTimeDetails.unblockTimeModal.title')}
      </DialogTitle>
      <DialogContent>
        <Box
          display='flex'
          flexDirection='column'
          flexGrow={1}
          flexShrink={1}
          gap={theme.spacing(4)}
        >
          <Box display='flex' flexDirection='column' flex={1}>
            {summaryRows.map((summaryRow) => (
              <Box key={summaryRow.label} display='flex' flexDirection='row'>
                <StyledUnblockTimeModalLabelTypography>
                  {summaryRow.label}
                </StyledUnblockTimeModalLabelTypography>
                <StyledUnblockTimeModalDetailTypography>
                  {summaryRow.formattedValue}
                </StyledUnblockTimeModalDetailTypography>
              </Box>
            ))}
          </Box>
          <StyledUnblockModalConfirmationTypography>
            {t('blockedTimeDetails.unblockTimeModal.confirmationText')}
          </StyledUnblockModalConfirmationTypography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onUnblockPress} color='primary' variant='contained'>
          {t('blockedTimeDetails.unblockTimeModal.unblockButtonLabel')}
        </Button>
        <Button onClick={onKeepPress} color='primary' variant='outlined'>
          {t('blockedTimeDetails.unblockTimeModal.keepButtonLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
