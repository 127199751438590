// Copyright 2021 Prescryptive Health, Inc.

import { Box, Button, TableContainer, TablePagination, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material';

export const StyledWaitlistTableContainer = styled(TableContainer)({
  maxHeight: 440,
});

export const StyledWaitlistTablePagination = styled(TablePagination)({
  minHeight: 54,
  borderBottom: 'none',
});

export const StyledEmptyWaitlistContainer = styled(Box)(({ theme }) => ({
  alignSelf: 'center',
  marginTop: theme.spacing(12),
}));

export const StyledWaitlistTableToolBar = styled(Toolbar)(({ theme }) => ({
  display: 'inline-table',
  padding: `${theme.spacing(3)} ${theme.spacing(10)}`,
  background: theme.palette.grey.A100,
  [theme.breakpoints.up('tablet')]: {
    display: 'flex',
  },
}));

export const StyledWaitlistTableToolBarAddButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(6),
}));

export const StyledWaitlistTableRowBoldTypography = styled(Typography)({
  fontWeight: 'bold',
});
