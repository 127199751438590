// Copyright 2020 Prescryptive Health, Inc.

import { AppointmentsDispatch } from './appointments.dispatch';
import { ICalendarAppointmentItem } from '../../../model/calendar-appointment-item';
import { appointmentsSetAction } from '../actions/appointments-set.action';
import { ICalendarBlockedTimeItem } from '../../../model/calendar-blocked-time-item';

export const appointmentsSetDispatch = (
  dispatch: AppointmentsDispatch,
  appointments: ICalendarAppointmentItem[],
  blockedSlots: ICalendarBlockedTimeItem[]
): void => {
  dispatch(appointmentsSetAction(appointments, blockedSlots));
};
