// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IPharmacyService } from '../../../../../model/pharmacy-service';
import { IWaitlistItem } from '../../../../../model/waitlist-item';
import { isValidPhoneNumber } from '../../../../../validators/phone-number.validator/phone-number.validator';
import { isoDateStringToLocalDate } from '../../../../../helpers/dates/date-helper/date.helper';
import { PhoneMaskInput } from '../../../../inputs/mask-inputs/phone-mask-input/phone-mask-input';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { getNewDate } from '../../../../../helpers/dates/get-new-date/get-new-date';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

interface IAddToWaitlistModalProps {
  isOpen: boolean;
  onAddPress: (itemToAdd: IWaitlistItem[]) => Promise<void>;
  onCancelPress: () => void;
  services: IPharmacyService[];
}

export const AddToWaitlistModal = ({
  isOpen,
  onAddPress,
  onCancelPress,
  services,
}: IAddToWaitlistModalProps): ReactElement => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<string>(getNewDate().toISOString());
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isPhoneNumberError, setIsPhoneNumberError] = useState<boolean>(false);
  const [serviceRequested, setServiceRequested] = useState<string>('');
  const [isServiceRequestedError, setIsServiceRequestedError] = useState<boolean>(false);
  const [isValidBirthday, setIsValidBirthday] = useState(false);

  const { t } = useTranslation();

  const theme = useTheme();

  const onAdd = async () => {
    if (isPhoneNumberError || isServiceRequestedError) return;

    const newDate = isoDateStringToLocalDate(dateOfBirth);

    const itemToAdd: IWaitlistItem[] = [
      {
        id: uuidv4(),
        firstName,
        lastName,
        phoneNumber,
        birthDate: newDate,
        serviceType: serviceRequested,
        isGlobalWaitlist: false,
      },
    ];

    await onAddPress(itemToAdd);
  };

  const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFirstName(event.target.value);
  };

  const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLastName(event.target.value);
  };

  const onDateOfBirthChange = (value: unknown) => {
    const date = value as DateTime | undefined;
    if (date?.isValid) {
      setIsValidBirthday(true);
      setDateOfBirth(date.toJSDate().toISOString());
    } else {
      setIsValidBirthday(false);
    }
  };

  const onPhoneNumberChange = (value: string) => {
    setIsPhoneNumberError(!isValidPhoneNumber(value));
    setPhoneNumber(value);
  };

  const onServiceRequestedChange = (event: SelectChangeEvent<string>) => {
    setIsServiceRequestedError(!event.target.value);
    setServiceRequested(event.target.value as string);
  };

  const allFieldsAreValid =
    firstName && lastName && isValidBirthday && isValidPhoneNumber(phoneNumber) && serviceRequested;

  return (
    <Dialog
      open={isOpen}
      onClose={onCancelPress}
      id='add-to-waitlist-modal'
      aria-labelledby='add-to-waitlist-modal-title'
    >
      <DialogTitle>{t('waitlist.addToWaitlistModal.title')}</DialogTitle>
      <DialogContent>
        <Box
          display='grid'
          gridTemplateColumns='1fr 1fr'
          gridTemplateRows='auto'
          gridTemplateAreas='
            "firstName firstName"
            "lastName lastName"
            "dateOfBirth phoneNumber"
            "serviceRequested serviceRequested"
            "consent consent"
          '
          gap={theme.spacing(2)}
        >
          <Box gridArea='firstName'>
            <TextField
              label={t('waitlist.addToWaitlistModal.firstNameLabel')}
              value={firstName}
              onChange={onFirstNameChange}
              fullWidth={true}
              variant='filled'
              data-heap-redact-text='true'
            />
          </Box>
          <Box gridArea='lastName'>
            <TextField
              label={t('waitlist.addToWaitlistModal.lastNameLabel')}
              value={lastName}
              onChange={onLastNameChange}
              fullWidth={true}
              variant='filled'
              data-heap-redact-text='true'
            />
          </Box>
          <Box gridArea='dateOfBirth'>
            <DatePicker
              disableFuture={true}
              openTo='year'
              inputFormat='MM/dd/yyyy'
              label={t('waitlist.addToWaitlistModal.dateOfBirthLabel')}
              views={['year', 'month', 'day']}
              value={dateOfBirth}
              onChange={onDateOfBirthChange}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              renderInput={(props) => <TextField {...props} variant='filled' fullWidth />}
            />
          </Box>
          <Box gridArea='phoneNumber'>
            <PhoneMaskInput
              onPhoneNumberChange={onPhoneNumberChange}
              placeholder={t('waitlist.addToWaitlistModal.phoneNumberPlaceholder')}
              isError={isPhoneNumberError}
              errorMessage={t('waitlist.addToWaitlistModal.phoneNumberErrorMessage')}
              data-heap-redact-text='true'
            />
          </Box>
          <Box gridArea='serviceRequested'>
            <FormControl variant='filled' error={isServiceRequestedError} fullWidth={true}>
              <InputLabel id={`add-customer-service-requested-label`}>
                {t('waitlist.addToWaitlistModal.serviceRequestedLabel')}
              </InputLabel>
              <Select
                labelId={`add-customer-service-requested-label`}
                onChange={onServiceRequestedChange}
                value={serviceRequested}
              >
                {services.map((s) => (
                  <MenuItem value={s.serviceType} key={s.serviceType} data-heap-redact-text='true'>
                    {s.name}
                  </MenuItem>
                ))}
              </Select>
              {isServiceRequestedError && (
                <FormHelperText>
                  {t('waitlist.addToWaitlistModal.serviceRequestedErrorMessage')}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box gridArea='consent' py={theme.spacing(12)}>
            <Typography>{t('waitlist.addToWaitlistModal.consent')}</Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAdd} color='primary' variant='contained' disabled={!allFieldsAreValid}>
          {t('waitlist.addToWaitlistModal.addCustomerButton')}
        </Button>
        <Button onClick={onCancelPress} color='primary' variant='outlined'>
          {t('waitlist.addToWaitlistModal.cancelAddCustomerButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
