// Copyright 2020 Prescryptive Health, Inc.

import { AppointmentsDispatch } from './appointments.dispatch';
import { appointmentsAreStaleAction } from '../actions/appointments-are-stale.action';

export const appointmentsAreStaleDispatch = (
  dispatch: AppointmentsDispatch
): void => {
  dispatch(appointmentsAreStaleAction());
};
