// Copyright 2021 Prescryptive Health, Inc.

import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { LocationDispatch } from '../dispatch/location.dispatch';
import { locationSetDispatch } from '../dispatch/location-set.dispatch';
import { pharmacyServicesGet } from '../../../api/v1/pharmacy-services-get/pharmacy-services-get';
import { IStoreLocation } from '../../../model/store-location';
import { logApiError } from '../../../api/errors/log-api-error';

export interface IPharmacyServicesGetAsyncActionArgs extends IApiAsyncActionArgs {
  locationDispatch: LocationDispatch;
  location: IStoreLocation;
}

export const pharmacyServicesGetAsyncAction = async (
  args: IPharmacyServicesGetAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: IPharmacyServicesGetAsyncActionArgs): Promise<void> => {
  const { locationDispatch, location, telemetryService, configState, authProvider, getAuthToken } =
    args;

  try {
    telemetryService.trackEvent('pharmacy-services-get', {
      location: location.id,
    });

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    const result = await pharmacyServicesGet(
      configState.apis.pharmacyPortal,
      accessToken,
      location.id
    );
    locationSetDispatch(locationDispatch, location, result.services);
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
  }
};
