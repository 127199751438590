// Copyright 2020 Prescryptive Health, Inc.

import { ISessionAction } from './session.action';
import { SessionPhase } from '../session.state';
import { AuthenticationProvider } from '../../authentication/authentication-provider';

export type ISessionLoadedAction = ISessionAction<'SESSION_LOADED'>;

export const sessionLoadedAction = (
  authenticationProvider: AuthenticationProvider
): ISessionLoadedAction => ({
  type: 'SESSION_LOADED',
  payload: {
    phase: SessionPhase.preAuth,
    authProvider: authenticationProvider,
  },
});
