// Copyright 2021 Prescryptive Health, Inc.

import { IReportsAction } from './reports.action';

export type IReportsCsvSetAction = IReportsAction<'CSV_SET'>;

export const reportsCsvSetAction = (
  reportCsv: string[],
  fileName: string
): IReportsCsvSetAction => ({
  type: 'CSV_SET',
  payload: {
    reportCsv,
    reportFileName: fileName,
    isReportReady: true,
  },
});
