// Copyright 2020 Prescryptive Health, Inc.

import { ISessionState } from './session.state';
import { SessionAction } from './actions/session.action';
import { Reducer } from 'react';

export type SessionReducer = Reducer<ISessionState, SessionAction>;

export const sessionReducer: SessionReducer = (
  state: ISessionState,
  action: SessionAction
): ISessionState => {
  return { ...state, ...action.payload };
};
