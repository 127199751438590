// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { StyledCopyTextsContainerBox } from './invite-user-dialog.styled-components';
import { CopyText } from './copy-text';
import { useTranslation } from 'react-i18next';
export interface InviteUserDialogProps {
  isOpen: boolean;
  onKeepPress: () => void;
  userName: string;
  temporaryPassword: string;
}

export const InviteUserDialog = ({
  isOpen,
  onKeepPress,
  userName,
  temporaryPassword,
}: InviteUserDialogProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Dialog
      onClose={onKeepPress}
      aria-labelledby='invite-user-dialog'
      open={isOpen}
      maxWidth={'xl'}
    >
      <DialogTitle id='modify-user-dialog-title'>
        {t('settings.inviteUserDialog.dialogTitle')}
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>
          {t('settings.inviteUserDialog.dialogDescription')}
        </Typography>
        <StyledCopyTextsContainerBox data-heap-redact-text='true'>
          <CopyText
            textToCopy={userName}
            buttonLabelText={t('settings.inviteUserDialog.copyButtonLabel')}
          />
          <CopyText
            textToCopy={temporaryPassword}
            buttonLabelText={t('settings.inviteUserDialog.copyButtonLabel')}
          />
        </StyledCopyTextsContainerBox>
      </DialogContent>
      <DialogActions id='modify-user-dialog-actions'>
        <Button onClick={onKeepPress} color='primary'>
          {t('settings.inviteUserDialog.closeButtonLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
