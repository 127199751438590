// Copyright 2021 Prescryptive Health, Inc.

import { isoDateStringToLocalDate } from '../../../helpers/dates/date-helper/date.helper';
import { IPaymentReport } from '../../../model/payment-report';
import { IPaymentReportResponse } from './payment-reports-get.response';

function paymentReportsFromResponse(
  responseData: Partial<IPaymentReportResponse>[]
): IPaymentReport[] {
  const paymentReports: IPaymentReport[] = responseData
    .map(({ createdOn, size, fileName, downloadUri }) => ({
      createdOn: isoDateStringToLocalDate(createdOn) ?? new Date(0),
      size: size ?? '',
      fileName: fileName ?? '',
      downloadUri: downloadUri ?? '',
    }))
    .filter(
      ({ createdOn, size, fileName, downloadUri }) =>
        createdOn > new Date(0) && size && fileName && downloadUri
    );

  return paymentReports;
}

export { paymentReportsFromResponse };
