// Copyright 2021 Prescryptive Health, Inc.

import { useQuery } from '@apollo/client';
import React, { ReactElement } from 'react';
import { usePostAuthScreenContext } from '../../../../../hooks/use-screen-context/post-auth/use-post-auth-screen-context.hook';
import { ProviderProgram } from '../../../../../model/strapi/strapi-models';
import { useProviderContext } from '../../../../../providers/provider/use-provider-context.hook';
import { PostAuthPrimaryScreenContainer } from '../../../../screen-containers/post-auth-primary/post-auth-primary.screen-container';
import { Button } from '@mui/material';
import { EmptyViewMessage } from '../../../../text/messages/empty-view.message';
import { GET_PROGRAMS_DETAILS_QUERY } from '../../program-details/screen-container/get-program-details.query';
import {
  IProviderProgramAddAsyncActionArgs,
  providerProgramAddAsyncAction,
} from '../../../../../state/post-auth/async-actions/provider-program-add.async-action';
import { ProgramDetailsScreen } from '../screen/program-details.screen';
import { authenticatedRouteConfig } from '../../../../../navigation/authenticated-route-config';
import { ErrorView } from '../../../../error-view/error-view';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IProviderProgramSetActiveAsyncActionArgs,
  providerProgramSetActiveAsyncAction,
} from '../../../../../state/provider-support/async-actions/provider-program-set-active.async-action';
import { useLocationContext } from '../../../../../providers/location/use-location-context.hook';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getToken } from '../../../../../helpers/http-client/get-token';
import { useTranslation } from 'react-i18next';

export const ProgramDetailsScreenContainer = (): ReactElement => {
  const { t } = useTranslation();
  const { programId } = useParams();
  const navigate = useNavigate();
  const { locationDispatch } = useLocationContext();
  const { authProvider, busyDispatch, configState, errorDispatch, telemetryService } =
    usePostAuthScreenContext({ defaultContent: {} });

  const { getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  const {
    providerState: { currentProvider, enrolledPrograms },
    providerDispatch,
  } = useProviderContext();

  const { loading, error, data } = useQuery<{
    providerProgram: ProviderProgram;
  }>(GET_PROGRAMS_DETAILS_QUERY, {
    variables: { id: programId },
    onError: ({ networkError }) => {
      const e = networkError as Error;
      telemetryService.trackException(e);
    },
  });

  document.title = t('addService.programDetailsScreenContainer.pageTitle', {
    programName: data?.providerProgram?.Title ?? '',
  });

  const programIdentifier = data?.providerProgram?.ProgramIdentifier;

  const enrollmentStatus = enrolledPrograms.find(
    (program) => program.programIdentifier === programIdentifier
  )?.enrollmentStatus;

  const providerName = currentProvider?.name ?? '';

  const goBack = () => {
    void navigate(authenticatedRouteConfig.addServices.createUrl());
  };

  const onEnrollPress = async () => {
    if (providerName && programIdentifier) {
      const providerProgramAdd = async () => {
        const providerProgramAddArgs: IProviderProgramAddAsyncActionArgs = {
          authProvider,
          busyDispatch,
          configState,
          errorDispatch,
          telemetryService,
          providerDispatch,
          programIdentifier,
          providerName,
          getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
        };

        await providerProgramAddAsyncAction(providerProgramAddArgs);
      };

      await providerProgramAdd();
    }
    return;
  };

  const onActivatePress = async () => {
    if (providerName && programIdentifier) {
      const providerProgramActivate = async () => {
        const providerProgramActivateArgs: IProviderProgramSetActiveAsyncActionArgs = {
          authProvider,
          busyDispatch,
          configState,
          errorDispatch,
          telemetryService,
          providerDispatch,
          locationDispatch,
          programId: programIdentifier,
          providerName,
          getAuthToken: getToken(auth0Migration, getAccessTokenSilently),
        };

        await providerProgramSetActiveAsyncAction(providerProgramActivateArgs);
      };

      await providerProgramActivate();
    }
    return;
  };

  if (loading) {
    return (
      <PostAuthPrimaryScreenContainer>
        <EmptyViewMessage content={t('addService.programDetailsScreenContainer.loading')} />
      </PostAuthPrimaryScreenContainer>
    );
  }

  if (error || !data) {
    return (
      <PostAuthPrimaryScreenContainer>
        <Button onClick={goBack}>Back</Button>
        <ErrorView />
      </PostAuthPrimaryScreenContainer>
    );
  }

  return (
    <PostAuthPrimaryScreenContainer style={{ padding: 0 }}>
      <ProgramDetailsScreen
        onGoBack={goBack}
        onEnroll={onEnrollPress}
        onActivate={onActivatePress}
        enrollmentStatus={enrollmentStatus}
        data={data.providerProgram}
      />
    </PostAuthPrimaryScreenContainer>
  );
};
