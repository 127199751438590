// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { EnrollableServiceCard } from '../../../cards/enrollable-card/enrollable-service.card';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { WithEnrollmentStatus } from '../../../../model/enrolled-service-type';
import { useTelemetryContext } from '../../../../providers/telemetry/use-telemetry-context.hook';
import {
  cardMargin,
  cardWidth,
} from '../../../cards/enrollable-card/enrollable.card.styled-components';
import {
  ProviderProgramIntroDynamicZone,
  ProviderServiceIntroDynamicZone,
} from '../../../../model/strapi/strapi-models';
import {
  StyledCarouselNavigationButtonBox,
  StyledCarouselNavigationNextButton,
  StyledCarouselNavigationPreviousButton,
  StyledCarouselTitleTypography,
  StyledServiceCardGroupBox,
  StyledTitleAndCarouselButtonsTypography,
} from './service-card-carousel.styled-components';
import { useProviderContext } from '../../../../providers/provider/use-provider-context.hook';
import { EnrollableProgramCard } from '../../../cards/enrollable-card/enrollable-program.card';
import { IEnrollableCardProps } from '../../../cards/enrollable-card/enrollable-card-props';

export type CardTypes = 'ServiceCard' | 'ProgramCard';

export interface ServiceCardCarouselItem {
  cardType: CardTypes;
  entityName: string | undefined;
  introText: string | undefined;
  keyBenefits: ProviderServiceIntroDynamicZone[] | ProviderProgramIntroDynamicZone[];
  enrolledEntity?: WithEnrollmentStatus;
  onViewDetails: () => void;
  onManage: () => void;
}

export interface IServiceCardCarouselProps {
  title: string;
  serviceCardItems: ServiceCardCarouselItem[];
}

export const ServiceCardCarousel = ({
  title,
  serviceCardItems,
}: IServiceCardCarouselProps): ReactElement => {
  const theme = useTheme();
  const { telemetryService } = useTelemetryContext();
  const {
    providerState: { currentProvider },
  } = useProviderContext();

  const isTablet = useMediaQuery(theme.breakpoints.up('tablet'));
  const divRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [carouselIndex, setCarouselIndex] = useState(0);

  useEffect(() => {
    if (divRef?.current) {
      setWidth(divRef?.current?.offsetWidth);
    }

    const handleResize = () => {
      setWidth(divRef?.current?.offsetWidth || 0);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [divRef?.current]);

  const buttonProps = {
    className: 'buttonProps',
    style: {
      backgroundColor: theme.palette.grey.A100,
      borderRadius: 25,
      color: theme.palette.grey.A400,
    },
  };

  const cardCount = Math.floor(width / (cardWidth + cardWidth * 0.15 + cardMargin * 2)) || 1;

  const serviceCardGroupings = groupServiceCardsByN(serviceCardItems, cardCount);

  const increaseIndex = () => {
    if (carouselIndex + 1 >= serviceCardGroupings.length) {
      setCarouselIndex(0);
    } else {
      setCarouselIndex(carouselIndex + 1);
    }
    telemetryService.trackEvent('click', {
      name: 'add-services-list-carousel-rightControl',
      description: 'increaseIndex',
      title,
      provider: currentProvider?.name,
    });
  };

  const decreaseIndex = () => {
    if (carouselIndex - 1 < 0) {
      setCarouselIndex(serviceCardGroupings.length - 1);
    } else {
      setCarouselIndex(carouselIndex - 1);
    }
    telemetryService.trackEvent('click', {
      name: 'add-services-list-carousel-leftControl',
      description: 'decreaseIndex',
      title,
      provider: currentProvider?.name,
    });
  };

  const nextIcon = (
    <StyledCarouselNavigationNextButton onClick={increaseIndex}>
      <ArrowForwardIosOutlinedIcon fontSize='small' />
    </StyledCarouselNavigationNextButton>
  );
  const prevIcon = (
    <StyledCarouselNavigationPreviousButton onClick={decreaseIndex}>
      <ArrowBackIosOutlinedIcon fontSize='small' />
    </StyledCarouselNavigationPreviousButton>
  );

  const serviceCards = (serviceCardsItems: ServiceCardCarouselItem[]) =>
    serviceCardsItems.map((item, i) => {
      const key = `${item.entityName ?? ''}${i}`;

      const cardProps = {
        entityName: item.entityName,
        introText: item.introText,
        enrolledEntity: item.enrolledEntity,
        onViewMore: item.onViewDetails,
      } as IEnrollableCardProps;

      return item.cardType === 'ProgramCard' ? (
        <EnrollableProgramCard
          key={key}
          keyBenefits={item.keyBenefits as ProviderProgramIntroDynamicZone[]}
          {...cardProps}
        />
      ) : (
        <EnrollableServiceCard
          key={key}
          keyBenefits={item.keyBenefits as ProviderServiceIntroDynamicZone[]}
          onManage={item.onManage}
          {...cardProps}
        />
      );
    });

  const renderServiceCardGroups = () => {
    return serviceCardGroupings.map((serviceCardGroup, i) => {
      const key = `ServiceCardGroup${i}`;
      return (
        <StyledServiceCardGroupBox key={key} display='flex' alignContent='center'>
          {serviceCards(serviceCardGroup)}
        </StyledServiceCardGroupBox>
      );
    });
  };

  return (
    <Box marginBottom={theme.spacing(8)}>
      <StyledTitleAndCarouselButtonsTypography
        display='flex'
        justifyContent='space-between'
        marginBottom={theme.spacing(8)}
      >
        <StyledCarouselTitleTypography variant='h5'>{title}</StyledCarouselTitleTypography>
        {isTablet && serviceCardGroupings.length > 1 && (
          <StyledCarouselNavigationButtonBox display='flex' flexDirection='row'>
            {prevIcon}
            {nextIcon}
          </StyledCarouselNavigationButtonBox>
        )}
      </StyledTitleAndCarouselButtonsTypography>
      <div ref={divRef}>
        <Carousel
          animation='slide'
          fullHeightHover={true}
          autoPlay={false}
          navButtonsProps={buttonProps}
          index={carouselIndex}
          navButtonsAlwaysInvisible={true}
        >
          {renderServiceCardGroups()}
        </Carousel>
      </div>
    </Box>
  );
};

export function groupServiceCardsByN(
  serviceCardItems: ServiceCardCarouselItem[],
  n: number
): ServiceCardCarouselItem[][] {
  const groupedCards: ServiceCardCarouselItem[][] = [];
  for (let i = 0; i < serviceCardItems.length; i++) {
    if (i % n) {
      groupedCards[groupedCards.length - 1].push(serviceCardItems[i]);
    } else {
      groupedCards.push([serviceCardItems[i]]);
    }
  }

  return groupedCards;
}
