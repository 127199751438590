// Copyright 2021 Prescryptive Health, Inc.

import React, { ReactElement } from 'react';
import { IServiceQuestionSelectOption } from '../../../../../model/pharmacy-service';
import { IQuestionCardListContent, questionCardListContent } from './question-card-list.content';
import { useCurrentContent } from '../../../../../hooks/use-current-content/use-current-content.hook';
import { Box, Typography, useTheme } from '@mui/material';
import { PrescryptiveIcon } from '../../../../prescryptive-icons/prescryptive-icon';
import { IconSize } from '../../../../../theming/icons';

export type QuestionPanelListType = 'numeric' | 'checkbox';

interface IQuestionCardListProps {
  questionType: QuestionPanelListType;
  values: IServiceQuestionSelectOption[];
}

export const QuestionCardList = (props: IQuestionCardListProps): ReactElement => {
  const theme = useTheme();
  const { content } = useCurrentContent<IQuestionCardListContent>(questionCardListContent);
  const { questionType, values } = props;

  const renderNumericItem = (key: string, index: number, value: string, isFirstItem: boolean) => {
    return (
      <Box
        display='flex'
        alignItems='center'
        marginTop={isFirstItem ? 0 : theme.spacing(4)}
        key={key}
      >
        <Typography>{content.numericLabel(index, value)}</Typography>
      </Box>
    );
  };

  const renderCheckBoxItem = (key: string, value: string, isFirstItem: boolean) => {
    return (
      <Box
        key={key}
        display='flex'
        alignItems='center'
        marginTop={isFirstItem ? 0 : theme.spacing(4)}
      >
        <PrescryptiveIcon
          icon={'checkbox-on'}
          color={theme.palette.grey[600]}
          size={IconSize.small}
          style={{ marginRight: theme.spacing(4) }}
        />
        <Typography>{value}</Typography>
      </Box>
    );
  };

  const renderList = () => {
    if (questionType === 'numeric') {
      return (
        <Box display='flex' flexDirection='column'>
          {values.map((value, index) => {
            const key = `numeric${index}`;
            const isFirstItem = index === 0;

            return renderNumericItem(key, index, value.text, isFirstItem);
          })}
        </Box>
      );
    }

    return (
      <Box display='flex' flexDirection='column'>
        {values.map((value, index) => {
          const key = `checkbox${index}`;
          const isFirstItem = index === 0;

          return renderCheckBoxItem(key, value.text, isFirstItem);
        })}
      </Box>
    );
  };

  return <Box>{renderList()}</Box>;
};
