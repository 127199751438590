// Copyright 2021 Prescryptive Health, Inc.

import { Box } from '@mui/material';
import { styled } from '@mui/material';

export const StyledHighFrequencyLandingPageContiner = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey.A100,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledContentContiner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(12),
  gap: theme.spacing(16),
}));

export const StyledFloatingSignInBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(6),
  gap: theme.spacing(8),
  borderRadius: theme.spacing(1),
  boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2)',
}));

export const StyledFooterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  width: '100%',
  bottom: '0',
  left: '0',
  position: 'absolute',
}));

export const StyledFooterContentContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(6),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.up('tablet')]: {
    flexDirection: 'row',
  },
}));
