// Copyright 2021 Prescryptive Health, Inc.

import { Box, Divider } from '@mui/material';

import { styled } from '@mui/material';

export const TimeSelectionContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.grey.A100,
  marginTop: theme.spacing(4),
}));

export const StyledEditAvailabilityModalDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(12),
  marginBottom: theme.spacing(12),
}));
