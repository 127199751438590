// Copyright 2021 Prescryptive Health, Inc.

import { ReactElement } from 'react';
import { IPharmacyService } from '../../../../../model/pharmacy-service';
import { AdditionalInformationSection } from '../../sections/additional-information/additional-information.section';
import { AdditionalQuestionsSection } from '../../sections/additional-questions/additional-questions.section';
import { GeneralSection } from '../../sections/general/general.section';
import { PricingSection } from '../../sections/pricing/pricing.section';
import { ServiceMessageSection } from '../../sections/service-message/service-message.section';
import { DeepLinkSection } from '../../sections/deep-link/deep-link.section';
import { Box, Divider } from '@mui/material';
import { ServiceAccordionDividerContainer } from './service.accodion-panel.styled-components';

interface IServiceAccordionPanelProps {
  service: IPharmacyService;
}

export const ServiceAccordionPanel = ({ service }: IServiceAccordionPanelProps): ReactElement => {
  const {
    status,
    durationMinutes,
    remainingQuantity,
    additionalQuestions,
    serviceType,
    price,
    paymentSettings,
    enrollmentStatus,
    minLeadHours,
  } = service;
  const renderDivider = () => (
    <ServiceAccordionDividerContainer>
      <Divider />
    </ServiceAccordionDividerContainer>
  );

  return (
    <Box minWidth={0}>
      <GeneralSection
        durationMinutes={durationMinutes}
        status={status}
        availableQuantity={remainingQuantity}
        serviceType={serviceType}
        enrollmentStatus={enrollmentStatus}
        minLeadHours={minLeadHours}
      />
      {renderDivider()}
      <DeepLinkSection serviceType={serviceType} />
      {renderDivider()}
      <PricingSection
        currentPrice={price}
        paymentSettings={paymentSettings}
        serviceType={serviceType}
      />
      {renderDivider()}
      <ServiceMessageSection
        serviceMessage={service.screenDescription ?? ''}
        serviceType={serviceType}
        status={status}
      />
      {renderDivider()}
      <AdditionalQuestionsSection questions={additionalQuestions} serviceType={serviceType} />
      {renderDivider()}
      <AdditionalInformationSection
        additionalInformation={service.confirmationAdditionalInfo ?? ''}
        serviceType={serviceType}
        status={status}
      />
    </Box>
  );
};
