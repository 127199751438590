// Copyright 2021 Prescryptive Health, Inc.

import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { ProviderUser } from '../../../model/provider-user';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { providerUsersAddAction } from '../actions/provider-users-add.action';
import { ProviderDispatch } from '../dispatch/provider.dispatch';
import { providerUsersAdd } from '../../../api/v1/provider-users-add/provider-users-add';
import { logApiError } from '../../../api/errors/log-api-error';

export interface IProviderUsersAddAsyncActionArgs extends IApiAsyncActionArgs {
  providerDispatch: ProviderDispatch;
  providerName: string;
  providerUser: ProviderUser;
}

export const providerUsersAddAsyncAction = async (
  args: IProviderUsersAddAsyncActionArgs
): Promise<ProviderUser> => {
  const {
    configState,
    authProvider,
    providerDispatch,
    providerName,
    telemetryService,
    providerUser,
    getAuthToken,
  } = args;

  try {
    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    telemetryService.trackEvent('provider-users-add', { providerName });

    const result = await providerUsersAdd(
      configState.apis.pharmacyPortal,
      accessToken,
      providerName,
      providerUser
    );

    providerDispatch(providerUsersAddAction(providerName, result));
    return result;
  } catch (error) {
    logApiError(telemetryService, error);
    throw error;
  }
};
