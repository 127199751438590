// Copyright 2020 Prescryptive Health, Inc.

import React, { FunctionComponent } from 'react';
import { AppScreenContainer } from '../app/app.screen-container';
import { MessagePanelContainer } from '../../message-panel-container/message-panel-container';
import { PostAuthPrimaryScreenChildContainer } from './post-auth-primary.screen-container.styled-components';
import { BoxProps } from '@mui/material';

export interface IPostAuthPrimaryScreenContainerProps extends BoxProps {
  dialogModals?: React.ReactNode;
  isDisabled?: boolean;
}

export const PostAuthPrimaryScreenContainer: FunctionComponent<IPostAuthPrimaryScreenContainerProps> = ({
  children,
  dialogModals,
  ...childContainerProps
}) => {
  return (
    <AppScreenContainer display='flex' flexDirection='row' height={1} dialogModals={dialogModals}>
      <PostAuthPrimaryScreenChildContainer {...childContainerProps}>
        {children}
      </PostAuthPrimaryScreenChildContainer>
      <MessagePanelContainer />
    </AppScreenContainer>
  );
};
