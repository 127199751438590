// Copyright 2021 Prescryptive Health, Inc.

import { IAllowedAccessResource } from '../../../api/v1/user-access-get/user-access-get.response';
import { IPostAuthAction } from './post-auth.action';

export type IUserAccessActionSet = IPostAuthAction<'USER_ACCESS_SET'>;

export const userAccessSetAction = (
  userAccess: IAllowedAccessResource[]
): IUserAccessActionSet => ({
  type: 'USER_ACCESS_SET',
  payload: {
    userAccess: {
      rules: userAccess.map((x) => ({
        name: x.name,
        role: x.role,
        type: x.type,
      })),
    },
  },
});
