// Copyright 2020 Prescryptive Health, Inc.

import { createContext } from 'react';
import { SessionPhase, ISessionState } from '../../state/session/session.state';
import { SessionDispatch } from '../../state/session/dispatch/session.dispatch';

export interface ISessionContext {
  readonly sessionState: ISessionState;
  readonly sessionDispatch: SessionDispatch;
}

export const SessionContext = createContext<ISessionContext>({
  sessionState: { phase: SessionPhase.preSession },
  sessionDispatch: () => {
    return;
  },
});
