// Copyright 2020 Prescryptive Health, Inc.

import { ErrorStatus } from '../error.state';
import { IErrorAction } from './error.action';

export type IErrorSetAction = IErrorAction<'ERROR_SET'>;

export const fatalErrorSetAction = (): IErrorSetAction => ({
  type: 'ERROR_SET',
  payload: { status: ErrorStatus.fatal },
});
