// Copyright 2021 Prescryptive Health, Inc.

import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { showBusyWhile } from '../../busy/dispatch/show-busy-while';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { LocationDispatch } from '../dispatch/location.dispatch';
import { pharmacyServiceUpdate } from '../../../api/v1/pharmacy-service-update/pharmacy-service-update';
import { IPharmacyService, ServiceStatus } from '../../../model/pharmacy-service';
import { pharmacyServiceUpdateActionPayload } from '../actions/pharmacy-service-update';
import { logApiError } from '../../../api/errors/log-api-error';

export interface ILocationServiceUpdateAsyncActionArgs extends IApiAsyncActionArgs {
  locationId: string;
  serviceType: string;
  status: ServiceStatus;
  quantityAdjustment: number;
  durationMinutes: number;
  screenDescription?: string;
  confirmationAdditionalInfo?: string;
  minLeadHours: number;
  locationDispatch: LocationDispatch;
}

export const locationServiceUpdateAsyncAction = async (
  args: ILocationServiceUpdateAsyncActionArgs
): Promise<void> => {
  await showBusyWhile(args.busyDispatch, () => asyncAction(args));
};

const asyncAction = async (args: ILocationServiceUpdateAsyncActionArgs): Promise<void> => {
  const {
    locationId,
    serviceType,
    status,
    quantityAdjustment = 0,
    durationMinutes,
    telemetryService,
    screenDescription,
    confirmationAdditionalInfo,
    minLeadHours,
    locationDispatch,
    getAuthToken,
  } = args;

  try {
    telemetryService.trackEvent('pharmacy-service-update', {
      locationId,
      serviceType,
      status,
      durationMinutes,
      screenDescription,
      confirmationAdditionalInfo,
      minLeadHours,
    });

    const { configState, authProvider } = args;

    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    await pharmacyServiceUpdate(
      configState.apis.pharmacyPortal,
      accessToken,
      locationId,
      serviceType,
      status,
      durationMinutes,
      quantityAdjustment,
      minLeadHours,
      screenDescription,
      confirmationAdditionalInfo
    );

    const newInfo: Partial<IPharmacyService> = {
      status,
      durationMinutes,
      serviceType,
      confirmationAdditionalInfo,
      screenDescription,
      minLeadHours,
    };

    locationDispatch(pharmacyServiceUpdateActionPayload(serviceType, newInfo));
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
    return Promise.reject();
  }
};
