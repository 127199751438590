import { ReactElement } from 'react';
import { Box, Link, Typography, useTheme } from '@mui/material';
import { StyledSignInSectionContainer } from './sign-in-section.styled-components';
import { SignInButton } from '../sign-in-button/sign-in-button';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { authenticatedRouteConfig } from '../../../../navigation/authenticated-route-config';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ISignUpFormProps {
  onSignInPress: () => void;
}

const MYRXPRO_SIGN_UP_URL = 'https://prescryptive.com/pharmacy-myrx-pro/';

export const SignInSection = ({ onSignInPress }: ISignUpFormProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { auth0Migration } = useFlags();

  return (
    <StyledSignInSectionContainer>
      <Typography variant='h4' style={{ paddingBottom: theme.spacing(4) }}>
        {t('lander.signInSection.signInLabel')}
      </Typography>
      <Box display='flex' flexDirection='column' gap={6}>
        <Typography>
          {t('lander.signInSection.orText')}
          <Link href={MYRXPRO_SIGN_UP_URL} target={'_blank'} underline='hover'>
            {t('lander.signInSection.createAnAccountText')}
          </Link>
        </Typography>
        {auth0Migration && <Typography>{t('lander.signInSection.signInHelperText')}</Typography>}
        <SignInButton
          onSignInPress={onSignInPress}
          dividerBackgroundColor={theme.palette.grey['50']}
        />
        {auth0Migration && (
          <Typography>
            {t('lander.signInSection.importantMessageText')}{' '}
            <RouterLink to={authenticatedRouteConfig.learnMore.createUrl()}>
              {t('lander.signInSection.learnMoreText')}
            </RouterLink>
          </Typography>
        )}
      </Box>
    </StyledSignInSectionContainer>
  );
};
