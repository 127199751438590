// Copyright 2021 Prescryptive Health, Inc.

import { Box, Link, Typography } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { ReactElement } from 'react';
import { ComponentProviderServiceContentTypesUrl } from '../../../model/strapi/strapi-models';
import { useProviderContext } from '../../../providers/provider/use-provider-context.hook';
import { useTelemetryContext } from '../../../providers/telemetry/use-telemetry-context.hook';
import { TitleTypography } from './title-description.styled-components';

interface ITitleDescriptionProps {
  title: string | null | undefined;
  description: string | null | undefined;
  url: Maybe<ComponentProviderServiceContentTypesUrl> | null | undefined;
}

export const TitleDescription = ({
  title,
  description,
  url,
}: ITitleDescriptionProps): ReactElement => {
  const { telemetryService } = useTelemetryContext();
  const {
    providerState: { currentProvider },
  } = useProviderContext();

  const onLinkPress = () => {
    telemetryService.trackEvent('click', {
      name: 'add-services-detail-link',
      urlDisplayText: url?.DisplayText,
      provider: currentProvider?.name,
    });
  };

  const urlSection = url ? (
    <Link
      target={url.Target ?? ''}
      href={url.URLAddress}
      onClick={onLinkPress}
      underline="hover">
      {url.DisplayText}
    </Link>
  ) : null;

  return (
    <Box>
      <TitleTypography>{title}</TitleTypography>
      <Typography>{description}</Typography>
      <Typography>{urlSection}</Typography>
    </Box>
  );
};
