// Copyright 2021 Prescryptive Health, Inc.

import { Breadcrumbs, Link, Box, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { BackButtonBox } from './back-button-breadcrumb.styled-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export interface BackButtonBreadcrumbProps {
  onGoBack: () => void;
  backButtonText?: string;
}

export const BackButtonBreadcrumb = ({
  onGoBack,
  backButtonText,
}: BackButtonBreadcrumbProps): ReactElement => {
  const theme = useTheme();
  return (
    <BackButtonBox>
      <Breadcrumbs>
        <Link onClick={onGoBack} component='button' color='textPrimary' underline='hover'>
          <Box display='flex' alignItems='center'>
            <Box marginRight={`${theme.spacing(1)}`}>
              <ArrowBackIcon fontSize='small' />
            </Box>
            {backButtonText}
          </Box>
        </Link>
      </Breadcrumbs>
    </BackButtonBox>
  );
};
