// Copyright 2020 Prescryptive Health, Inc.

import { createContext } from 'react';
import {
  defaultDrugSearchConfigState,
  IDrugSearchConfigState,
} from '../../state/drug-search/drug-search.state';

export interface IDrugSearchConfigContext {
  readonly drugSearchConfigState: IDrugSearchConfigState;
}

export const DrugSearchConfigContext = createContext<IDrugSearchConfigContext>({
  drugSearchConfigState: defaultDrugSearchConfigState,
});
