import { useContext, ReactElement, useEffect } from 'react';
import { PreAuthStackNavigator } from './stack-navigators/pre-auth/pre-auth.stack-navigator';
import { SessionPhase } from '../state/session/session.state';
import { SessionContext } from '../providers/session/session.context';
import { PostAuthDrawerNavigator } from './drawer-navigators/post-auth/post-auth.drawer-navigator';
import { ProviderContextProvider } from '../providers/provider/provider.context-provider';
import { LocationAvailabilityContextProvider } from '../providers/location-availability/location-availability.context-provider';
import { LocationContextProvider } from '../providers/location/location.context-provider';
import { PostAuthContextProvider } from '../providers/post-auth/post-auth.context-provider';
import { WaitlistContextProvider } from '../providers/waitlist/waitlist.context-provider';
import { useTelemetryContext } from '../providers/telemetry/use-telemetry-context.hook';
import { ContainerHierarchy } from '../components/container-hierarchy/container-hierarchy';
import { useProviderContext } from '../providers/provider/use-provider-context.hook';
import { NotificationContextProvider } from '../providers/notification/notification.context-provider';
import { SessionLoadingScreen } from '../components/screens/session-loading/session-loading.screen';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { addBearerTokenToClientContext } from '../helpers/http-client/add-bearer-token-to-client-context';
import pharmacyPortalServiceApolloClient from '../init-pharmacy-portal-service-apollo-client';
import pharmacyPortalServiceGraphqlClient from '../init-pharmacy-portal-service-graphql-client';

export const Navigator = (): ReactElement => {
  const { sessionState } = useContext(SessionContext);
  const { telemetryService } = useTelemetryContext();
  const {
    providerState: { currentProvider },
  } = useProviderContext();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { auth0Migration } = useFlags();

  /**
   * This can be simplified to just passing `getAccessTokenSilently` from the `useAuth0` hook
   * once we have migrated to auth0 entirely
   */
  addBearerTokenToClientContext(pharmacyPortalServiceApolloClient, getAccessTokenSilently);
  addBearerTokenToClientContext(pharmacyPortalServiceGraphqlClient, getAccessTokenSilently);

  useEffect(() => {
    telemetryService.trackMetric('screen-resolution-width', window.screen.width, {
      'screen-resolution': `${window.screen.width} x ${window.screen.height}`,
      provider: currentProvider?.name,
    });
    telemetryService.trackMetric('screen-resolution-height', window.screen.height, {
      'screen-resolution': `${window.screen.width} x ${window.screen.height}`,
      provider: currentProvider?.name,
    });
    telemetryService.trackMetric('window-size-width', window.innerWidth, {
      'window-size': `${window.innerWidth} x ${window.innerHeight}`,
      provider: currentProvider?.name,
    });
    telemetryService.trackMetric('window-size-height', window.innerHeight, {
      'window-size': `${window.innerWidth} x ${window.innerHeight}`,
      provider: currentProvider?.name,
    });
  }, []);

  if (auth0Migration && isAuthenticated) {
    return (
      <ContainerHierarchy
        containerList={[
          NotificationContextProvider,
          PostAuthContextProvider,
          ProviderContextProvider,
          LocationContextProvider,
          LocationAvailabilityContextProvider,
          WaitlistContextProvider,
        ]}
      >
        <PostAuthDrawerNavigator />
      </ContainerHierarchy>
    );
  }

  // Remove after auth0 migration. Use `isAuthenticated` instead
  if (sessionState.phase === SessionPhase.postAuth) {
    return (
      <ContainerHierarchy
        containerList={[
          NotificationContextProvider,
          PostAuthContextProvider,
          ProviderContextProvider,
          LocationContextProvider,
          LocationAvailabilityContextProvider,
          WaitlistContextProvider,
        ]}
      >
        <PostAuthDrawerNavigator />
      </ContainerHierarchy>
    );
  }

  if (sessionState.phase === SessionPhase.preAuth) {
    return <PreAuthStackNavigator />;
  }

  return <SessionLoadingScreen />;
};
