// Copyright 2020 Prescryptive Health, Inc.

import React, { FunctionComponent } from 'react';
import { createBrowserHistory } from 'history';
import { useConfigContext } from '../../hooks/use-config-context/use-config-context.hook';
import { TelemetryContext, TelemetryEvent } from './telemetry.context';
import { ITelemetryServiceConfig, TelemetryService } from './telemetry.service';
import ENV from '../../helpers/environment-variables';

export const TelemetryContextProvider: FunctionComponent = ({ children }) => {
  const { configState } = useConfigContext();

  const browserHistory = createBrowserHistory();
  const serviceConfig: ITelemetryServiceConfig = {
    instrumentationKey: ENV.APP_INSIGHTS_INSTRUMENTATION_KEY,
    serviceName: 'pharmacy-portal-web',
    browserHistory,
    authenticationApiHost: configState.apis.authentication.env.host,
  };
  const telemetryService = new TelemetryService<TelemetryEvent>(serviceConfig);

  return (
    <TelemetryContext.Provider value={{ telemetryService }}>{children}</TelemetryContext.Provider>
  );
};
