// Copyright 2020 Prescryptive Health, Inc.

export function formatFullName(firstName = '', lastName = ''): string {
  if (!firstName) {
    return lastName;
  }

  if (!lastName) {
    return firstName;
  }

  return `${firstName} ${lastName}`;
}

export function formatMemberId(memberId = ''): string {
  return `${memberId.slice(0, memberId.length - 2)} ${memberId.slice(
    memberId.length - 2,
    memberId.length
  )}`;
}

export function formatInitials(firstName = '', lastName = ''): string {
  if (!firstName) {
    return `${lastName.charAt(0).toUpperCase()}`;
  }

  if (!lastName) {
    return `${firstName.charAt(0).toUpperCase()}`;
  }

  return `${firstName.charAt(0).toUpperCase()}${lastName
    .charAt(0)
    .toUpperCase()}`;
}

export default {
  formatFullName,
  formatInitials,
  formatMemberId,
};
