// Copyright 2021 Prescryptive Health, Inc.

import { DateTime } from 'luxon';

const isValidMMDDYYYYDate = (date: string): boolean => {
  return DateTime.fromFormat(date.trim(), 'MM/dd/yyyy').isValid;
};

const isValidISODate = (date: string): boolean => DateTime.fromISO(date).isValid;

export default {
  isValidMMDDYYYYDate,
  isValidISODate
};
