// Copyright 2021 Prescryptive Health, Inc.

import { fatalErrorDispatch } from '../../error/dispatch/fatal-error.dispatch';
import { ProviderDispatch } from '../dispatch/provider.dispatch';
import { IApiAsyncActionArgs } from '../../api-async-action-args';
import { AuthenticationProviderUndefinedError } from '../../../api/errors/authentication-provider-undefined.error';
import { providerUsersGet } from '../../../api/v1/provider-users-get/provider-users-get';
import { providerUsersSetAction } from '../actions/provider-users-set.action';
import { logApiError } from '../../../api/errors/log-api-error';

export interface IProviderUsersGetAsyncActionArgs extends IApiAsyncActionArgs {
  providerDispatch: ProviderDispatch;
  providerName: string;
}

export const providerUsersGetAsyncAction = async (
  args: IProviderUsersGetAsyncActionArgs
): Promise<void> => {
  const {
    configState,
    authProvider,
    providerDispatch,
    providerName,
    telemetryService,
    getAuthToken,
  } = args;

  try {
    if (!authProvider && !getAuthToken) {
      throw new AuthenticationProviderUndefinedError();
    }

    const accessToken = await getAuthToken;

    telemetryService.trackEvent('provider-users-get', { providerName });

    const result = await providerUsersGet(
      configState.apis.pharmacyPortal,
      accessToken,
      providerName
    );

    providerDispatch(providerUsersSetAction(providerName, result));
  } catch (error) {
    fatalErrorDispatch(args.errorDispatch);
    logApiError(telemetryService, error);
    throw error;
  }
};
