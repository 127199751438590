import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getNewDate } from '../../../helpers/dates/get-new-date/get-new-date';
import { authenticatedRouteConfig } from '../../../navigation/authenticated-route-config';
import { ITermsOfUseResponse, TERMS_OF_USE_MUTATION } from './terms-of-use.mutation';
import initPharmacyPortalServiceGraphqlClient from '../../../init-pharmacy-portal-service-graphql-client';

export interface ITermsOfUseModalProps {
  isOpen: boolean;
  handleClose: () => void;
  setTermsAccepted: Dispatch<SetStateAction<boolean>>;
}

export const TermsOfUseModal = (props: ITermsOfUseModalProps) => {
  const { handleClose, isOpen, setTermsAccepted } = props;
  const { user, logout } = useAuth0();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isLinkClicked, setIsLinkClicked] = useState<boolean>(false);

  const closeModal = (_: MouseEvent, reason: 'backdropClick') => {
    if (reason === 'backdropClick') {
      return;
    } else {
      handleClose();
    }
  };

  const [updateUser, { loading }] = useMutation<ITermsOfUseResponse>(TERMS_OF_USE_MUTATION, {
    client: initPharmacyPortalServiceGraphqlClient,
  });

  const handleLinkClick = () => {
    setIsLinkClicked(true);
  };

  const handleTermsOfUseAccept = async () => {
    const { data } = await updateUser({
      variables: {
        updateAuth0UserInput: {
          userMetadata: {
            termsOfUseAccepted: true,
            termsOfUseAcceptedUtc: getNewDate(),
          },
        },
      },
    });

    if (
      data?.updateAuth0User.userMetadata.pharmacyPortalService.termsOfUseAccepted &&
      isLinkClicked
    ) {
      setTermsAccepted(true);
      localStorage.setItem(user?.sub as string, 'true');
      void navigate(authenticatedRouteConfig.root.createUrl());
      handleClose();
    }
  };

  const isAcceptDisabled = !isLinkClicked;

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      disableEscapeKeyDown={true}
      onBackdropClick={undefined}
      sx={{ backdropFilter: 'blur(5px)' }}
      maxWidth='md'
      scroll='paper'
    >
      <DialogTitle>
        <Typography paddingBottom={4} variant='h5'>
          {t('termsOfUseModal.title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box paddingBottom={2}>{t('termsOfUseModal.content')}</Box>
        <Link
          href={`${process.env.REACT_APP_TERMS_OF_USE_LINK}`}
          underline='none'
          target='_blank'
          rel='noopener'
          onClick={handleLinkClick}
          data-testid='tou-link'
        >
          <Button variant='outlined' sx={{ fontWeight: 'bold' }}>
            {t('termsOfUseModal.link')}
          </Button>
        </Link>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => logout({ returnTo: window.location.origin, federated: true })}
          data-testid='tou-decline-button'
        >
          {t('termsOfUseModal.declineButton')}
        </Button>
        <Tooltip title={t('termsOfUseModal.buttonWarning')}>
          <span>
            <LoadingButton
              loading={loading}
              disabled={isAcceptDisabled}
              onClick={handleTermsOfUseAccept}
              variant='contained'
              data-testid='tou-accept-button'
              id='tou-accept-button'
            >
              {t('termsOfUseModal.acceptButton')}
            </LoadingButton>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
